import first from 'lodash/first';
import { useMemo } from 'react';
import { useDatasetClusterContext } from '../../../contexts/datasets/DatasetClusterContext';
import { Box, Card, Text } from '../../../design-system/v2';
import { useGetDatasetClusterFullQuery } from '../../../queries/datasets/clustering/cluster';
import { useDataSubsetsListQuery } from '../../../queries/datasets/clustering/subsets';
import { getFilteredData } from './util';

export const EmbeddingsInfoCard = () => {
  const {
    datasetId,
    hasSelection,
    flatLassoSelection,
    selectedClasses,
    selectedClusters,
    selectedOutliers,
    selectedSegments,
    showMislabelledData,
    selectedSubsetIds,
  } = useDatasetClusterContext();
  const { data } = useGetDatasetClusterFullQuery(datasetId);
  const { data: subsets = [] } = useDataSubsetsListQuery(datasetId, true);

  const selectedSubset = useMemo(
    () => subsets.filter(({ subsetId }) => selectedSubsetIds?.includes(subsetId)),
    [selectedSubsetIds, subsets],
  );
  const firstSelectedSubsetName = first(selectedSubset)?.name ?? '';

  const totalSelectedPoints = data?.data
    ? getFilteredData(data.data, {
        selectedPoints: flatLassoSelection,
        selectedClasses,
        selectedClusters,
        selectedOutliers,
        selectedSegments,
        showMislabelledData,
      })
    : [];

  const pointsSummaryText = hasSelection ? 'Selected data points' : 'Total data points';
  const displayedPoints = hasSelection
    ? totalSelectedPoints.length
    : data?.metadata.totalPoints ?? '--';

  const DETAILS = [
    ...(Boolean(firstSelectedSubsetName)
      ? [
          {
            title: 'Subset',
            subTitle: firstSelectedSubsetName,
          },
        ]
      : []),
    {
      title: pointsSummaryText,
      subTitle: displayedPoints,
    },
    {
      title: 'Classes',
      subTitle: data?.metadata?.labelsCount.length ?? '--',
    },
  ];

  return (
    <Card
      miw="180px"
      withBorder
      shadow="md"
      sx={{
        zIndex: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(3px)',
      }}
    >
      {DETAILS.map(({ title, subTitle }) => (
        <Box key={title} p="sm">
          <Text variant="subTitle03" color="gray.7">
            {title}
          </Text>
          <Text variant="subTitle01" color="gray.8">
            {subTitle}
          </Text>
        </Box>
      ))}
    </Card>
  );
};
