import { tableFromIPC } from 'apache-arrow';
import { AxiosResponse } from 'axios';
import first from 'lodash/first';
import isUndefined from 'lodash/isUndefined';
import { SegmentType } from '../../../generated/api';

export interface ClusterCounts {
  count: number;
  dataQuality?: number;
  name: string;
}

export interface PointMetadata {
  label: string;
  recordId: string;
  segment: SegmentType;
  cluster: string;
  topic: string;
  outlier: string;
  dataQuality: number;
  isMislabelled: boolean;
}

export interface Point {
  x: number;
  y: number;
  z: number;
  metadata: PointMetadata;
}

export interface ChartExtremes {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  minZ: number;
  maxZ: number;
}
export interface ClusterMetadata {
  labelsCount: ClusterCounts[];
  clustersCount: ClusterCounts[];
  segmentsCount: ClusterCounts[];
  topicsCount: ClusterCounts[];
  outliersCount: ClusterCounts[];
  totalPoints: number;
  segments: string[];
  overallDataQuality: number;
  mislabelledCount: number;
  extremes: ChartExtremes;
}

export interface ClusterDataResponse {
  data: Point[];
  metadata: ClusterMetadata;
}

// TODO:: See what BE can send as type for File response
export const clusterSelector = (
  response: AxiosResponse<any>,
  fullDataResponse?: ClusterDataResponse,
) => {
  const table = [...tableFromIPC(response.data as any)];
  const labelCountMap: Record<ClusterCounts['name'], ClusterCounts> = {};
  const clusterCountMap: Record<ClusterCounts['name'], ClusterCounts> = {};
  const segmentsCountMap: Record<ClusterCounts['name'], ClusterCounts> = {};
  const topicsCountMap: Record<ClusterCounts['name'], ClusterCounts> = {};
  const outliersCountMap: Record<ClusterCounts['name'], ClusterCounts> = {};

  let mislabelledCount = 0;

  let minX = 0,
    minY = 0,
    maxX = 0,
    maxY = 0,
    minZ = 0,
    maxZ = 0;
  const data = [];
  for (let i = 0; i < table.length; i++) {
    const row = table[i];
    const rowJson = row.toJSON();
    const {
      '--mkv-clustering_clusters': cluster,
      '--mkv-clustering_record_id': recordId,
      '--mkv-clustering_segment': segment,
      '--mkv-clustering_x': x,
      '--mkv-clustering_y': y,
      '--mkv-clustering_z': z,
      '--mkv-clustering_label': label,
      '--mkv-clustering_topics': topic,
      '--mkv-clustering_outliers': outlier,
    } = rowJson;

    let { is_label_issue: isMislabelled, label_quality: labelQuality } = rowJson;

    if (fullDataResponse?.data?.length) {
      const rowIdx = first(recordId.split(':')) as string;
      const parsedIdx = parseInt(rowIdx);
      const currentRowInFullData = fullDataResponse?.data[parsedIdx];
      if (currentRowInFullData) {
        const currentRowMetadata = currentRowInFullData.metadata;
        isMislabelled = currentRowMetadata['isMislabelled'];
        labelQuality = currentRowMetadata['dataQuality'];
      }
    }

    if (i === 0) {
      minX = maxX = x;
      minY = maxY = y;
      minZ = maxZ = z;
    }

    if (x < minX) {
      minX = x;
    }
    if (x > maxX) {
      maxX = x;
    }
    if (y < minY) {
      minY = y;
    }
    if (y > maxY) {
      maxY = y;
    }
    if (z < minZ) {
      minZ = z;
    }
    if (z > maxZ) {
      maxZ = z;
    }

    const { count: labelCount = 0, dataQuality = 0 } = labelCountMap[label] ?? {};
    const { count: clusterCount = 0 } = clusterCountMap[cluster] ?? {};
    const { count: segmentCount = 0 } = segmentsCountMap[segment] ?? {};
    const { count: topicCount = 0 } = topicsCountMap[topic] ?? {};
    const { count: outlierCount = 0 } = outliersCountMap[outlier] ?? {};

    if (Boolean(isMislabelled)) {
      mislabelledCount++;
    }
    const currLabelCount = labelCount + 1;
    const currClusterCount = clusterCount + 1;
    const currTopicCount = topicCount + 1;
    const currOutlierCount = outlierCount + 1;
    const currDataQualityAverage = dataQuality + (labelQuality - dataQuality) / currLabelCount;

    labelCountMap[label] = {
      count: currLabelCount,
      dataQuality: currDataQualityAverage,
      name: `${label}`,
    };

    if (!isUndefined(cluster)) {
      clusterCountMap[`${cluster}`] = {
        count: currClusterCount,
        name: `${cluster}`,
      };
    }

    segmentsCountMap[segment] = {
      count: segmentCount + 1,
      name: segment,
    };

    if (!isUndefined(topic)) {
      topicsCountMap[`${topic}`] = {
        count: currTopicCount,
        name: `${topic}`,
      };
    }

    if (!isUndefined(outlier)) {
      outliersCountMap[`${outlier}`] = {
        count: currOutlierCount,
        name: `${outlier}`,
      };
    }

    data.push({
      x,
      y,
      z,
      metadata: {
        isMislabelled,
        label: `${label}`,
        recordId,
        segment,
        cluster: !isUndefined(cluster) ? `${cluster}` : undefined,
        topic: !isUndefined(topic) ? `${topic}` : undefined,
        outlier: !isUndefined(outlier) ? `${outlier}` : undefined,
        dataQuality: labelQuality,
      },
    });
  }

  const labelsCount = Object.values(labelCountMap);
  const clustersCount = Object.values(clusterCountMap);
  const topicsCount = Object.values(topicsCountMap);
  const outliersCount = Object.values(outliersCountMap);

  const overallMetadata = {
    labelsCount,
    clustersCount,
    topicsCount,
    outliersCount,
    segmentsCount: Object.values(segmentsCountMap),
    totalPoints: data.length,
    segments: Object.keys(segmentsCountMap),
    overallDataQuality:
      labelsCount.reduce((sum, { dataQuality = 0 }) => sum + dataQuality, 0) / data.length,
    mislabelledCount,
    extremes: {
      minX,
      maxX,
      minY,
      maxY,
      minZ,
      maxZ,
    },
  };

  return { data, metadata: overallMetadata } as ClusterDataResponse;
};
