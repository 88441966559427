import { IFilterParams } from '@ag-grid-community/core';
import { Button } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useListState } from '../../../../hooks';
import { Loader } from '../../../feedback';
import { Horizontal, Vertical } from '../../../layout';
import { SetFilter } from './SetFilter';

interface FilterWithButtonsProps extends IFilterParams {
  isLoading?: boolean;
  isError?: boolean;
  options?: { value: string; label: string }[];
}

export const FilterWithButtons = forwardRef(
  (
    {
      column,
      options = [],
      api,
      filterChangedCallback,
      isLoading,
      isError,
    }: FilterWithButtonsProps,
    ref,
  ) => {
    const [selectedOptions, handlers] = useListState(
      options.map(opt => ({
        ...opt,
        checked: false,
      })),
    );

    useEffect(() => {
      handlers.setState(
        options.map(opt => ({
          ...opt,
          checked: false,
        })),
      );
    }, [options.length]);

    useImperativeHandle(ref, () => ({
      doesFilterPass() {
        return true;
      },

      isFilterActive() {
        return selectedOptions.some(opt => opt.checked);
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        if (
          selectedOptions.every(opt => opt.checked) ||
          selectedOptions.every(opt => !opt.checked)
        ) {
          return null;
        }

        return {
          filter: selectedOptions.filter(opt => opt.checked).map(opt => opt.value),
          type: 'in',
        };
      },

      setModel(model: any) {
        if (!model?.filter) {
          return handlers.setState(
            options.map(opt => ({
              ...opt,
              checked: false,
            })),
          );
        }

        handlers.apply(item => ({
          ...item,
          checked: model.filter.includes(item.value),
        }));
      },
    }));

    const handleSelectAll = (key: string, selected: boolean) => {
      handlers.apply((item, idx) => ({ ...item, checked: selected }));
    };

    const handleSelect = (index: number, selected: boolean) => {
      handlers.setItemProp(index, 'checked', selected);
    };

    const handleClose = () => {
      const filterInstance = api.getFilterInstance(column.getColId());

      filterInstance?.setModel(null);
      filterChangedCallback();
      api.hidePopupMenu();
    };

    const handleSave = () => {
      filterChangedCallback();

      api.hidePopupMenu();
    };

    return (
      <Vertical>
        {isLoading ? (
          <Loader p="sm" size="lg" />
        ) : (
          <SetFilter
            options={selectedOptions}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
          />
        )}
        <Horizontal position="right" py="sm">
          <Button size="xs" variant="subtle" onClick={handleClose}>
            Reset
          </Button>
          <Button size="xs" variant="primary" onClick={handleSave}>
            Apply
          </Button>
        </Horizontal>
      </Vertical>
    );
  },
);

FilterWithButtons.displayName = 'FilterWithButtons';
