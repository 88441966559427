import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { first } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Center,
  EllipsisText,
  Loader,
  Text,
} from '../../../../../design-system/v2';
import { Table, TableThemes } from '../../../../../design-system/v2/core/data-display/table';
import { OperatorCategory } from '../../../../../generated/api';
import { parseCSVString } from '../../../../../lib/fileparse';
import { useNodeDataPreviewQuery } from '../../../../../queries/workflows/operators';
import { HeaderWithCheckbox } from '../../workflow-builder/operator-parameter-form/operator-data-preview/node-schema-preview/NodeSchemaPreview';
import { useFilePreview } from './previews/use-file-preview';
import { useJsonPreview } from './previews/use-json-preview';

interface DataPreviewContainerProps {
  workflowId: string;
  runId: string;
  nodeId: string;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  schema: {
    name: string;
    type: string;
  }[];
  operatorCategory?: OperatorCategory;
  modifiedColumns?: string[];
  shadingColor?: string;
}

export const DataPreviewContainer = ({
  workflowId,
  runId,
  nodeId,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  schema,
  operatorCategory,
  modifiedColumns,
  shadingColor,
}: DataPreviewContainerProps) => {
  // TODO: Remove operator category from data preview,HACKY FIX TO MAKE SOURCE OPERATORS WORK, MERGE PREVIEW+DAGIOSCHEMA into one api.
  const {
    isLoading,
    isFetching,
    error,
    data: nodeDataPreview,
  } = useNodeDataPreviewQuery(workflowId, runId, nodeId, operatorCategory ?? '');
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [isParsingError, setIsParsingError] = useState(false);
  const [parsedData, setParsedData] = useState<string[][] | undefined>();

  const validSelectedColumns =
    selectedColumns?.filter(col => first(parsedData)?.includes(col)) ?? [];

  const toggleColumnSelection = (colId: string) => {
    if (setSelectedColumns && selectedColumns) {
      const index = validSelectedColumns.indexOf(colId);
      if (index > -1) {
        setSelectedColumns(validSelectedColumns.filter((id: string) => id !== colId));
      } else {
        return setSelectedColumns([...validSelectedColumns, colId]);
      }
    }
  };

  useEffect(() => {
    setIsDataParsing(true);
    setIsParsingError(false);

    if (nodeDataPreview) {
      parseCSVString(nodeDataPreview.data ?? [''], ',')
        .then(results => {
          setParsedData(results);

          setIsDataParsing(false);
          setIsParsingError(false);
        })
        .catch(() => {
          setIsParsingError(true);
        });
    }
  }, [nodeDataPreview?.data]);

  if ((isLoading || isFetching || isDataParsing) && !error) {
    return (
      <Center mih={200} w="100%">
        <Loader />
      </Center>
    );
  }

  if (error || isParsingError) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">
          {error?.response?.data.detail.msg ??
            'Error fetching data preview. Try running debug again'}
        </Alert>
      </Center>
    );
  }

  if (!parsedData) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">Data preview not found. Must be an error</Alert>
      </Center>
    );
  }

  const columns: ColDef[] =
    first(parsedData)?.map(datum => {
      const type = schema.find(schemaItem => schemaItem.name === datum)?.type;

      if (type === 'S3_FILE') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Box>
                  <Text variant="subTitle04">{datum}</Text>
                  <Text variant="small03" color="gray.6">
                    file
                  </Text>
                </Box>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value, rowIndex, data, colDef }: ICellRendererParams) => {
            data;
            const openFilePreview = useFilePreview();
            const handlePreview = () => {
              openFilePreview(workflowId, value);
            };
            return <Button onClick={handlePreview}>Preview</Button>;
          },
        };
      }

      if (type === 'JSON') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Box>
                  <Text variant="subTitle04">{datum}</Text>
                  <Text variant="small03" color="gray.6">
                    Json
                  </Text>
                </Box>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value, rowIndex, data, colDef }: ICellRendererParams) => {
            const openJsonPreview = useJsonPreview();
            const handlePreview = () => {
              openJsonPreview(value);
            };
            return (
              <Box>
                <Button onClick={handlePreview}>Preview</Button>
              </Box>
            );
          },
        };
      }

      return {
        colId: datum,
        field: datum,
        headerName: datum,
        cellStyle: modifiedColumns?.includes(datum)
          ? { backgroundColor: shadingColor ?? 'none' }
          : { backgroundColor: 'none' },
        headerComponent: allowColumnSelection
          ? () => (
              <HeaderWithCheckbox
                column={{ name: datum, type: datum }}
                toggleColumnSelection={toggleColumnSelection}
                isSelected={(selectedColumns ?? []).includes(datum)}
              />
            )
          : undefined,
        filter: false,
        sortable: false,
        minWidth: 100,
        flex: 1,
        // This should not be needed, but for some reason, values are coming undefined
        // FIXIT:: remove cell renderer
        cellRenderer: ({ value, rowIndex, data, colDef }: ICellRendererParams) => (
          <EllipsisText variant="bodyLong02" closeDelay={100} pt="lg">
            {/* TODO: Check why empty string is used to index data  */}
            {value ?? data[colDef?.colId ?? ''] ?? ''}
          </EllipsisText>
        ),
      };
    }) ?? [];

  const rowData = parsedData?.slice(1).map(parsedDataRow =>
    parsedDataRow.reduce((prev, curr, idx) => {
      const colId = columns[idx] ? columns[idx].colId : null;
      if (!colId) {
        return prev;
      }

      return {
        ...prev,
        [colId]: curr,
      };
    }, {}),
  );

  return <Table theme={TableThemes.THEME_V2} rowData={rowData} columns={columns} />;
};
