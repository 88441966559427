import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { modelAppEvents } from '../../../../../analytics/modelAppEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Badge,
  Card,
  Horizontal,
  LinkButton,
  SimpleGrid,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { ModelAppStatus, ModelWithAppMetadata } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { getModelAppStatus } from '../../../../model-apps/utils';

const badgeColor = (status?: ModelAppStatus, isBaselineModel?: boolean) => {
  if (status === ModelAppStatus.Stopped) {
    return 'gray.6';
  }
  if (isBaselineModel) {
    return 'yellow.6';
  }

  return 'purple.6';
};

interface ModelAppsPreviewProps {
  modelApps: ModelWithAppMetadata[];
}

export const ModelAppsPreview = ({ modelApps }: ModelAppsPreviewProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getModelAppDetailsRoute, getModelAppsRoute } = useAbsoluteRoutes();

  const handleNavigateModelApp = (modelId: string) => {
    sendAnalytics(
      modelAppEvents.openApp({
        pageSource: 'DASHBOARD',
        workspaceId,
        modelAppId: modelId,
      }),
    );
    navigate(getModelAppDetailsRoute(modelId));
  };

  return (
    <Vertical h="100%" spacing="lg" justify="space-between" p="lg">
      <SimpleGrid cols={2}>
        {modelApps.map(modelApp => (
          <Card
            key={modelApp.modelId}
            onClick={() => handleNavigateModelApp(modelApp.modelId)}
            bg="gray.0"
            withBorder
            sx={{ cursor: 'pointer' }}
          >
            <Vertical spacing="lg">
              <Horizontal position="apart">
                <Badge
                  variant="filled"
                  color={badgeColor(modelApp.modelAppStatus, modelApp.isBaselineModel)}
                  radius="xs"
                >
                  <Text variant="subTitle03" color="white" tt="capitalize">
                    {modelApp.isBaselineModel ? 'Baseline Model App' : 'Custom Model App'}
                  </Text>
                </Badge>
                <Badge
                  variant="dot"
                  radius="xs"
                  color={getModelAppStatus(modelApp.modelAppStatus).color}
                  px="sm"
                  sx={{ border: '0' }}
                >
                  <Text variant="subTitle04" color="gray.7">
                    {getModelAppStatus(modelApp.modelAppStatus).status}
                  </Text>
                </Badge>
              </Horizontal>
              <Vertical spacing={0} px="sm">
                <Text variant="subTitle04" color="gray.8">
                  {modelApp.modelAppName}
                </Text>
                <Text variant="subTitle03" color="gray.6">
                  {modelApp.modelClass}
                </Text>
              </Vertical>
            </Vertical>
          </Card>
        ))}
      </SimpleGrid>
      <Horizontal position="right">
        <LinkButton
          variant="subtle"
          to={getModelAppsRoute()}
          rightIcon={<IconArrowNarrowRight stroke={1.5} />}
          w="100%"
        >
          <Text variant="textLink" color="blue.7">
            View all apps
          </Text>
        </LinkButton>
      </Horizontal>
    </Vertical>
  );
};
