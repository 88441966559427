import { copyEditEvents } from '../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { Accordion, Loader } from '../../../design-system/v2';
import { WorkspaceMember } from '../../../generated/api';
import { sendAnalytics } from '../../../initializers/analytics';
import { SharedUsers } from './SharedUsers';

interface SharedUsersContainerProps {
  projectId: string;
  users: WorkspaceMember[];
  sharedWithUsers: string[];
  onRemoveUser: (userId: string) => void;
  isLoading?: boolean;
}

export const SharedUsersContainer = ({
  projectId,
  users,
  sharedWithUsers,
  onRemoveUser,
  isLoading,
}: SharedUsersContainerProps) => {
  const { userId: currentUserId, workspaceId } = useAppMetadata();
  const sharedUserDetails = users.filter(
    ({ userId }) => sharedWithUsers.includes(userId) && userId !== currentUserId,
  );
  const handleAccordionClick = () => {
    sendAnalytics(
      copyEditEvents.documents.invitedMembersListClicked({
        workspaceId: workspaceId,
        projectId: projectId,
      }),
    );
  };

  return (
    <Accordion pt="md">
      <Accordion.Item value="shared">
        <Accordion.Control
          disabled={sharedUserDetails.length === 0 || isLoading}
          onClick={handleAccordionClick}
        >
          {isLoading ? (
            <Loader variant="dots" />
          ) : (
            `Shared with ${sharedUserDetails.length} Members`
          )}
        </Accordion.Control>
        {!isLoading && sharedUserDetails.length > 0 && (
          <Accordion.Panel>
            <SharedUsers users={sharedUserDetails} onRemoveUser={onRemoveUser} />
          </Accordion.Panel>
        )}
      </Accordion.Item>
    </Accordion>
  );
};
