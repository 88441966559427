import { useFlags } from 'launchdarkly-react-client-sdk';
import { createStyles, useMarkovTheme } from '../../../design-system/v2';

const MODEL_STUDIO_SIDEBAR_COLOR = '#054F4F';
const DATA_STUDIO_SIDEBAR_COLOR = '#3A274C';

export type SidebarColorScheme = 'data-studio' | 'model-studio' | 'default';

interface SidebarColors {
  bg: string;
  fill: string;
  textFill: string;
  bgHover: string;
  fillHover: string;
  bgActive: string;
  fillActive: string;
}

export const useSidebarColors = (scheme: SidebarColorScheme = 'default') => {
  const theme = useMarkovTheme();

  const sidebarColors: Record<SidebarColorScheme, SidebarColors> = {
    'model-studio': {
      bg: theme.colors.white[0],
      fill: theme.colors.gray[6],
      textFill: theme.colors.gray[7],
      bgHover: theme.colors.blue[0],
      fillHover: theme.colors.blue[6],
      bgActive: theme.colors.blue[0],
      fillActive: theme.colors.blue[6],
    },
    'data-studio': {
      bg: theme.colors.white[0],
      fill: theme.colors.gray[6],
      textFill: theme.colors.gray[7],
      bgHover: theme.colors.blue[0],
      fillHover: theme.colors.blue[6],
      bgActive: theme.colors.blue[0],
      fillActive: theme.colors.blue[6],
    },
    default: {
      bg: theme.colors.dark[7],
      fill: theme.colors.dark[2],
      textFill: theme.colors.gray[7],
      bgHover: theme.colors.dark[5],
      fillHover: theme.colors.gray[0],
      bgActive: theme.colors.blue[8],
      fillActive: theme.colors.gray[0],
    },
  };

  return sidebarColors[scheme];
};

interface SidebarStyleParams {
  colorScheme?: SidebarColorScheme;
}

export const useSidebarStyles = createStyles((theme, params: SidebarStyleParams | void) => {
  const { featureProductRestructuring } = useFlags();
  const { bg, fill, textFill, bgHover, fillHover, bgActive, fillActive } = useSidebarColors(
    params?.colorScheme,
  );

  const iconStyles = featureProductRestructuring
    ? {
        color: fill,
      }
    : {
        path: {
          fill: fill,
        },
      };

  const iconHoverStyles = featureProductRestructuring
    ? {
        svg: {
          color: fillHover,
        },
      }
    : {
        svg: {
          path: {
            fill: fillHover,
          },
        },
      };

  const iconActiveStyles = featureProductRestructuring
    ? {
        svg: {
          color: fillActive,
        },
      }
    : {
        svg: {
          path: {
            fill: fillActive,
          },
        },
      };

  return {
    navSection: {
      borderRight: `1px solid ${theme.colors.gray[3]}`,
      transition: 'width 0.2s ease-out',
      display: 'flex',
      flexDirection: 'column',
    },
    navButton: {
      padding: '10px',
      margin: '0px 8px',
      borderRadius: theme.radius.xs,
      textDecoration: 'none',
      backgroundColor: bg,
      color: textFill,

      svg: {
        width: '24px',
        height: '24px',
        ...iconStyles,
      },

      '&:hover': {
        backgroundColor: bgHover,
        color: fillHover,
        ...iconHoverStyles,
      },
    },

    active: {
      '&, &:hover': {
        color: fillActive,
        backgroundColor: bgActive,
        ...iconActiveStyles,
      },
    },
  };
});

export const useSidebarMenuStyles = createStyles(theme => ({
  dropdown: {
    background: theme.colors.dark[7],
    borderColor: theme.colors.dark[7],
    padding: 0,
    marginLeft: '200px',
    marginTop: '56px',
  },
}));

export const useNewSidebarMenuStyles = createStyles(theme => ({
  dropdown: {
    backgroundColor: theme.colors.dark[7],
    borderColor: theme.colors.dark[7],
    padding: 0,
    marginLeft: '-8px',
  },
  itemIcon: {
    svg: {
      width: '24px',
      height: '24px',
      color: theme.colors.dark[2],
    },
  },
  item: {
    backgroundColor: theme.colors.dark[7],
    color: theme.colors.gray[0],
    '&:hover': {
      backgroundColor: theme.colors.dark[5],
      color: theme.colors.blue[0],
      svg: {
        color: theme.colors.gray[0],
      },
    },
  },
  active: {
    color: theme.colors.gray[0],
    backgroundColor: theme.colors.blue[8],
    svg: {
      color: theme.colors.gray[0],
    },
  },
}));
