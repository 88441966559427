import { ITextFilterParams } from '@ag-grid-community/core';
import dayjs from 'dayjs';

export enum FilterOptions {
  Contains = 'contains',
  Equals = 'equals',
  In = 'in',
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
}

const AgGridTextFilterOptions = [
  'contains',
  'notContains',
  'equals',
  'notEqual',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank',
  'empty',
];

const AgGridNumberFilterOptions = [
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
  'blank',
  'notBlank',
  'empty',
];

const AgGridDateFilterOptions = [
  'equals',
  'greaterThan',
  'lessThan',
  'notEqual',
  'inRange',
  'blank',
  'notBlank',
  'empty',
];

export enum TextFilterOptions {}

export const textFilterParams: ITextFilterParams = {
  filterOptions: ['contains'],
  maxNumConditions: 1,
  buttons: ['reset', 'apply'],
  closeOnApply: true,
};

export const numberFilterParams = {
  filterOptions: ['equal', 'lessThan', 'greaterThan'],
  maxNumConditions: 1,
  buttons: ['reset', 'apply'],
  closeOnApply: true,
};

export const dateFilterParams = {
  maxNumConditions: 1,
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    const date = dayjs(cellValue).startOf('day');

    if (!date) {
      return 0;
    }

    const filteredDate = dayjs(filterLocalDateAtMidnight);

    if (date.isBefore(filteredDate)) {
      return -1;
    } else if (date.isAfter(filteredDate)) {
      return 1;
    }
    return 0;
  },
  minValidYear: 2020,
  maxValidYear: new Date().getFullYear(),
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};
