import { createStyles, getStylesRef } from '@mantine/styles';

export const useGridItemStyles = createStyles(theme => ({
  item: {
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.xs,

    '&:hover': {
      [`.${getStylesRef('actions')}`]: {
        display: 'block',
      },
      zIndex: 1,
    },
  },

  itemActive: {
    border: `2px solid ${theme.colors.blue[6]}`,
  },
  itemAdded: {
    border: `2px solid ${theme.colors.green[6]}`,
  },
  itemDeleted: {
    border: `2px solid ${theme.colors.red[6]}`,
  },

  actions: {
    ref: getStylesRef('actions'),
    display: 'none',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[4]}`,
    zIndex: 2,
  },
}));
