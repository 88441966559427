import { AppHeader } from '../../components/app-header/AppHeader';
import { PageErrorScreen } from '../../components/common/ErrorState';
import { useAuthLogin } from '../../contexts/auth-provider/Auth';
import { Box, Button, Container } from '../../design-system/v2';

export const AuthError = () => {
  const { handleLoginRedirect } = useAuthLogin();

  return (
    <Container fluid px={0}>
      <AppHeader />
      <Box mt={48} w="100%">
        <PageErrorScreen
          iconSize="lg"
          iconName="broken_plate"
          title="Uh-oh! Unable to login!"
          subtitle="Please try again after some time or contact support, if the issue persists"
          button={
            <Button variant="primary" onClick={handleLoginRedirect}>
              Try logging in again
            </Button>
          }
        />
      </Box>
    </Container>
  );
};
