import { useEffect, useMemo, useState } from 'react';
import { IconRocket } from '../../../design-system';
import {
  Card,
  Center,
  Horizontal,
  Loader,
  Progress,
  Text,
  Vertical,
  useInterval,
} from '../../../design-system/v2';

interface GeneratingModelAppLoaderProps {
  modelAppLaunchDate: string;
  height?: string | number;
  withShadow?: boolean;
}

const APPROX_COMPLETION_TIME = 10 * 60 * 1000; // 10 min
const MAX_PERCENTAGE_ALLOWED = 95;

export const GeneratingModelAppLoader = ({
  modelAppLaunchDate,
  height = 500,
  withShadow = true,
}: GeneratingModelAppLoaderProps) => {
  const [currentTime, setCurrTime] = useState(Date.now());

  const interval = useInterval(() => setCurrTime(Date.now()), 1500);

  const percentageCompleted =
    ((currentTime - new Date(modelAppLaunchDate).getTime()) * 100) / APPROX_COMPLETION_TIME;
  const progressBarPercentage = Math.min(MAX_PERCENTAGE_ALLOWED, percentageCompleted);

  const loadingMessage = useMemo(() => {
    if (progressBarPercentage < 10) {
      return 'Finding optimal server to run this application...';
    }
    if (progressBarPercentage < 30) {
      return 'Configuring  cloud  with optimal configuration...';
    }
    if (progressBarPercentage < 45) {
      return 'Loading the model...';
    }
    if (progressBarPercentage < 70) {
      return 'Setting up Rest Endpoints so you can interact with model...';
    }
    if (progressBarPercentage < MAX_PERCENTAGE_ALLOWED) {
      return 'Few more minutes...';
    }
    return 'Please wait, the app is taking longer than expected...';
  }, [progressBarPercentage]);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return (
    <Center w="100%" h={height}>
      <Card shadow={withShadow ? 'lg' : undefined} py={32} px={128}>
        <Vertical spacing="lg" h="100%" align="center" justify="center">
          <IconRocket />
          <Text variant="heading03" color="gray.8" mb="lg">
            Generating your app
          </Text>
          <Progress w={550} color="blue.6" value={progressBarPercentage} animate />
          <Horizontal>
            <Loader variant="bars" size="xs" />
            <Text variant="bodyShort02" color="gray.6" w="fit-content" align="center">
              {loadingMessage}
            </Text>
          </Horizontal>
          <Text variant="bodyShort02" color="gray.6" w={400} align="center">
            {progressBarPercentage < MAX_PERCENTAGE_ALLOWED ? (
              <>
                It might take up to 5-10 minutes for the app to be generated <br />
                We will send an email when app is live
              </>
            ) : (
              <>
                The app is taking a while to load. <br />
                Our engineers have been alerted. Please contact support for further actions.
              </>
            )}
          </Text>
        </Vertical>
      </Card>
    </Center>
  );
};
