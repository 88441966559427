import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppSidePanelOptions,
  useDocumentAiAppContext,
} from '../../../../contexts/document-ai/DocumentAiAppContext';
import { Box, Grid } from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { AppRoutes, getRoute } from '../../../../router/constants';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { CopyEditInternalAppRouter } from '../copy-edit/CopyEditorInternalRouter';
import { AppDetailsHeader } from './AppDetailsHeader';
import { AppSidePanel } from './AppSidePanel';
import { PageInfo } from './PageInfo';

interface AppFrameProps {
  appId: string;
  appName: string;
}

// TODO: See if we can remove variants and use appId from URL
export const getAppVariantFromAppId = (appId: string): AppIdType | undefined => {
  switch (appId) {
    case 'copy-edit':
      return AppIdType.CopyEdit;
    case 'summarization':
      return AppIdType.Summarization;
    case 'doc-qna':
      return AppIdType.QnaWithDoc;
    default:
      return undefined;
  }
};

// Internal Routers based on appId
const getRouter = (appId: string) => {
  switch (appId) {
    case 'copy-edit':
      return CopyEditInternalAppRouter;
    default:
      return CopyEditInternalAppRouter;
  }
};

export const AppFrame = ({ appId, appName }: AppFrameProps): JSX.Element => {
  const { getAppStoreRoute } = useAbsoluteRoutes();
  const { pathname } = useLocation();

  const { setActiveTab } = useDocumentAiAppContext();
  const appVariant = getAppVariantFromAppId(appId);
  const Router = getRouter(appId);

  useEffect(() => {
    if (pathname.includes(getRoute(AppRoutes.APP_STORE_SETTINGS))) {
      setActiveTab(AppSidePanelOptions.SETTINGS);
      return;
    }
    if (pathname.includes(getRoute(AppRoutes.COPY_EDIT_ANALYTICS))) {
      setActiveTab(AppSidePanelOptions.ANALYTICS);
      return;
    }
    setActiveTab(AppSidePanelOptions.ALL_PROJECTS);
  }, [pathname, setActiveTab]);

  return (
    <Box h="100%" mb="sm" role="main">
      <AppDetailsHeader>
        <PageInfo parentTitle="All apps" title={appName} backButtonRoute={getAppStoreRoute()} />
      </AppDetailsHeader>
      <Grid h="calc(100% - 56px)" gutter={24} mb="sm" mr="sm">
        <Grid.Col span="content">
          {appVariant && <AppSidePanel variant={appVariant} isSupportProjects />}
        </Grid.Col>
        <Grid.Col span="auto">
          <Router />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
