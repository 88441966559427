import css from '@styled-system/css';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { getInterpolatedColor } from '../../lib/ui';
import Box from '../Box';
import { Loader, LoaderProps } from './Loader';

interface LoadingWrapperProps extends LoaderProps {
  isLoading: boolean;
}

const OverlayLoaderContainer = styled(Box)(
  css({
    position: 'absolute',
    bg: getInterpolatedColor('#FFFFFF', 0.8),
    boxShadow: 'loader',
    width: '100%',
    height: 'inherit',
    zIndex: 9999,
  }),
);

// TODO: Overlay should happen on the entire parent container. Doesn't happen yet
export const OverlayLoader = ({
  isLoading,
  text,
  children,
}: PropsWithChildren<LoadingWrapperProps>): JSX.Element => (
  <Box position="relative" height="100%">
    {isLoading && (
      <OverlayLoaderContainer>
        <Loader text={text} />
      </OverlayLoaderContainer>
    )}

    {children}
  </Box>
);
