import { MantineThemeComponents } from '@mantine/styles/lib/theme/types/MantineTheme';
import { accordionTheme } from '../core/data-display/accordion/Accordion.style';
import { chipTheme } from '../core/inputs/chip/Chip.style';
import { fileInputTheme, inputTheme } from '../core/inputs/input/Input.style';
import { multiSelectTheme } from '../core/inputs/multi-select/multi-select.style';
import { segmentedControlTheme } from '../core/inputs/segmented-control/SegmentedControl.style';
import { selectTheme } from '../core/inputs/select/select.style';
import { drawerTheme } from '../core/overlays/drawer/Drawer.style';
import { notificationsTheme } from '../core/overlays/notification/Notification.style';
import { tooltipTheme } from '../core/overlays/tooltip/Tooltip.style';
import { breakpoints } from './breakpoints';

export const components: MantineThemeComponents = {
  Container: {
    defaultProps: {
      // For default sizes we would use breakpoints as it is,
      // As for full width layouts, pages would inherit breakpoint widths directly
      sizes: breakpoints,
    },
  },

  Chip: chipTheme,
  Input: inputTheme,
  FileInput: fileInputTheme,
  Select: selectTheme,
  MultiSelect: multiSelectTheme,
  Autocomplete: multiSelectTheme,
  SegmentedControl: segmentedControlTheme,

  Accordion: accordionTheme,
  Drawer: drawerTheme,
  Notification: notificationsTheme,

  Tooltip: tooltipTheme,
};
