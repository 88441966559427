import {
  Alert,
  Box,
  Center,
  Divider,
  Horizontal,
  IconAlertCircle,
  Loader,
  ScrollArea,
} from '../../../design-system/v2';
import { interleaveComponent } from '../../../lib/util-react';
import { useEvalClassLevelMetricsQuery } from '../../../queries/metrics';
import { MetricHeader } from '../../evaluation-comparison/common/metrics/MetricHeader';
import { ClassMetricsRow } from './ClassMetricsRow';

const MAX_SCROLL_HEIGHT = 300;

interface ClassMetricsContainerProps {
  evaluationId: string;
}

export const ClassMetricsContainer = ({
  evaluationId,
}: ClassMetricsContainerProps): JSX.Element => {
  const { isLoading, isError, data } = useEvalClassLevelMetricsQuery(evaluationId);

  if (isLoading) {
    return (
      <Center h={300}>
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert icon={<IconAlertCircle size={20} />} color="red">
        Error retrieving class metrics for this evaluation.
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert icon={<IconAlertCircle size={20} />} color="red">
        Class metrics are not available for this evaluation.
      </Alert>
    );
  }

  const { metricsInfo, classLevelMetrics } = data;

  return (
    <Box>
      <Horizontal grow spacing="xl" px="md">
        <Box></Box>
        {metricsInfo.map(({ name, description = '' }, i) => (
          <MetricHeader key={i} name={name} description={description} />
        ))}
      </Horizontal>
      <ScrollArea.Autosize mah={MAX_SCROLL_HEIGHT}>
        {interleaveComponent(
          classLevelMetrics.map(({ className, metrics }) => (
            <ClassMetricsRow key={className} className={className} metrics={metrics} />
          )),
          Divider,
        )}
      </ScrollArea.Autosize>
    </Box>
  );
};
