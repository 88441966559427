import { WorkspaceRBAC } from '../../contexts/rbac/RBAC';
import { closeAllModals, openModal, Text } from '../../design-system/v2';
import { WorkspaceMembershipRoles } from '../../generated/api';
import { useGetWorkspaceDetailsQuery } from '../../queries/account/workspace';
import { InvitationNotAllowed } from './InvitationNotAllowed';
import { UserInvitationContainer } from './UserInvitation.container';

const InvitationTitle = () => {
  const { data } = useGetWorkspaceDetailsQuery();

  return (
    <Text variant="heading04" order={2} size="1.5rem">{`Invite users ${
      data ? `to ${data.workspaceName}` : ''
    }`}</Text>
  );
};

export const openWorkspaceInviteModal = () =>
  openModal({
    size: 'lg',
    title: <InvitationTitle />,
    children: (
      <WorkspaceRBAC
        roles={[WorkspaceMembershipRoles.Owner, WorkspaceMembershipRoles.Maintainer]}
        fallback={<InvitationNotAllowed />}
      >
        <UserInvitationContainer onClose={() => closeAllModals()} />
      </WorkspaceRBAC>
    ),
  });
