import { useNavigate } from 'react-router-dom';
import {
  Horizontal,
  Text,
  UnstyledButton,
  useMarkovTheme,
  Vertical,
} from '../../../../design-system/v2';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { TemplateContainer } from './Template.container';

interface WorkflowTemplateSectionProps {
  searchText: string;
  isHomePage?: boolean;
}

export const WorkflowTemplateSection = ({
  searchText,
  isHomePage = false,
}: WorkflowTemplateSectionProps) => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { getWorkflowsTemplatesRoute } = useAbsoluteRoutes();

  return (
    <Vertical spacing={16}>
      <Horizontal position="apart">
        <Text variant="subTitle02" color="gray.9">
          Start new workflow
        </Text>
        {isHomePage && (
          <UnstyledButton onClick={() => navigate(getWorkflowsTemplatesRoute())}>
            <Text variant="textLink" color="blue.6">
              All templates
            </Text>
          </UnstyledButton>
        )}
      </Horizontal>
      <TemplateContainer searchText={searchText} isHomePage={isHomePage} />
    </Vertical>
  );
};
