import { useMutation, useQueryClient } from 'react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { UpsertDAGRequest } from '../../generated/api';
import { workflowApi } from '../../lib/api';
import { workflowsQueryKeys } from './list/list';
import { operatorKeys } from './operators';

const builderKeys = {
  all: (workspaceId: string) => ['workflow-builder', workspaceId],
  create: (workspaceId: string) => builderKeys.all(workspaceId),
  save: (workspaceId: string, workflowId: string) => [...builderKeys.all(workspaceId), workflowId],
};

export const useCreateWorkflowMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    builderKeys.create(workspaceId),
    ({ workflowName, templateId }: { workflowName?: string; templateId?: string }) =>
      workflowApi.createWorkflowV1(workspaceId, workflowName, templateId),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all }),
    },
  );
};

export const useSaveWorkflowGraphMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpsertDAGRequest) => workflowApi.updateDAGsV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsQueryKeys.dag(workspaceId, workflowId));
        queryClient.invalidateQueries(operatorKeys.getDagIOSchema(workspaceId, workflowId));
      },
    },
  );
};
