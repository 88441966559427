import { Alert, Center, Loader } from '../../../../design-system/v2';
import { ChartData } from '../../../../generated/api';
import { useClassDistributionsQuery } from '../../../../queries/evaluations/distributions';
import { ClassDistribution } from './ClassDistribution';

const GROUND_TRUTH_COLOR = '#3070B7';

interface ClassDistributionContainerProps {
  evaluationIds: string[];
  chartColors: string[];
  expandable?: boolean;
}

export const ClassDistributionContainer = ({
  evaluationIds,
  chartColors,
  expandable = true,
}: ClassDistributionContainerProps) => {
  const { isLoading, isError, data } = useClassDistributionsQuery(evaluationIds);

  if (isLoading) {
    return (
      <Center h={380}>
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return <Alert color="red">Error fetching class distribution data</Alert>;
  }

  const chartData = data as ChartData;
  const colors = [GROUND_TRUTH_COLOR, ...chartColors];

  return (
    <ClassDistribution
      colors={colors}
      chartData={chartData}
      isLoading={isLoading}
      expandable={expandable}
    />
  );
};
