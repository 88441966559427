import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  useContext,
  useMemo,
  useReducer,
} from 'react';

interface DatasetsContextState {
  selectedDatasetIds: string[];
  selectedDatasetNames: string[];
  selectedDatasetClassLabels: string[][];
}

const initialState: DatasetsContextState = {
  selectedDatasetIds: [],
  selectedDatasetNames: [],
  selectedDatasetClassLabels: [],
};

enum ACTIONS {
  SELECT_DATASET = 'SELECT_DATASET',
  DESELECT_DATASET = 'DESELECT_DATASET',
  RESET_SELECTION = 'RESET_SELECTION',
}

type DatasetsAction =
  | {
      type: ACTIONS.SELECT_DATASET;
      datasetId: string;
      datasetName: string;
      classLabels: string[];
    }
  | {
      type: ACTIONS.DESELECT_DATASET;
      datasetId: string;
    }
  | {
      type: ACTIONS.RESET_SELECTION;
    };

const datasetSelectionReducer: Reducer<DatasetsContextState, DatasetsAction> = (state, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_DATASET: {
      const selectedDatasetIds = [...state.selectedDatasetIds, action.datasetId];
      const selectedDatasetNames = [...state.selectedDatasetNames, action.datasetName];
      const selectedDatasetClassLabels = [...state.selectedDatasetClassLabels, action.classLabels];
      return {
        ...state,
        selectedDatasetIds,
        selectedDatasetNames,
        selectedDatasetClassLabels,
      };
    }
    case ACTIONS.DESELECT_DATASET: {
      const targetDatasetIndex = state.selectedDatasetIds.indexOf(action.datasetId);
      return targetDatasetIndex < 0
        ? { ...state }
        : {
            ...state,
            selectedDatasetIds: state.selectedDatasetIds.filter(
              (_datasetId, i) => i !== targetDatasetIndex,
            ),
            selectedDatasetNames: state.selectedDatasetNames.filter(
              (_datasetName, i) => i !== targetDatasetIndex,
            ),
            selectedDatasetClassLabels: state.selectedDatasetClassLabels.filter(
              (_classLabels, i) => i !== targetDatasetIndex,
            ),
          };
    }
    case ACTIONS.RESET_SELECTION: {
      return {
        ...state,
        selectedDatasetIds: [],
        selectedDatasetNames: [],
        selectedDatasetClassLabels: [],
      };
    }
    default:
      throw new Error('Unknown action type');
  }
};

const DatasetsContext = createContext<{
  state: DatasetsContextState;
  dispatch: Dispatch<DatasetsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const DatasetsContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const [state, dispatch] = useReducer(datasetSelectionReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <DatasetsContext.Provider value={value}>{children}</DatasetsContext.Provider>;
};

export const useDatasetsSelection = () => {
  const context = useContext(DatasetsContext);
  if (!context) {
    throw new Error('useDatasetsSelection should only be used inside DatasetsContextProvider');
  }
  const { state, dispatch } = context;
  return {
    selectedDatasetIds: state.selectedDatasetIds,
    selectedDatasetNames: state.selectedDatasetNames,
    isDatasetSelected: (datasetId: string) => state.selectedDatasetIds.includes(datasetId),
    selectDataset: (datasetId: string, datasetName: string, classLabels: string[]) => {
      dispatch({
        type: ACTIONS.SELECT_DATASET,
        datasetId,
        datasetName,
        classLabels,
      });
    },
    deselectDataset: (datasetId: string) => {
      dispatch({
        type: ACTIONS.DESELECT_DATASET,
        datasetId,
      });
    },
    resetSelection: () => {
      dispatch({ type: ACTIONS.RESET_SELECTION });
    },
  };
};
