import { Textarea as MantineTextArea, TextareaProps as MantineTextAreaProps } from '@mantine/core';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

export interface TextAreaProps extends Omit<MantineTextAreaProps, 'onChange'> {
  value: string;
  ariaLabel: string;
  autoFocus?: boolean;
  onChange: (val: string, e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = forwardRef(
  (
    { onChange, ariaLabel, autoFocus, ...rest }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value, e);

    return (
      <MantineTextArea
        aria-label={ariaLabel}
        data-autofocus={autoFocus}
        autosize
        onChange={handleOnChange}
        ref={ref}
        {...rest}
      />
    );
  },
);

TextArea.displayName = 'TextArea';
