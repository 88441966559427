import { SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  Card,
  Horizontal,
  ScrollArea,
  Text,
  UnstyledButton,
  Vertical,
  useMarkovTheme,
  useScrollIntoView,
} from '../../..';
import { CommandItem } from './commandsSuggestion';

type CommandsListProps = SuggestionProps<CommandItem>;

export interface CommandsListComponent {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

export const CommandsList = forwardRef<CommandsListComponent, CommandsListProps>(
  ({ items, editor, range }, ref) => {
    const itemsRefs = useRef<Record<string, HTMLButtonElement>>({});
    const { scrollIntoView, scrollableRef, targetRef } = useScrollIntoView<HTMLButtonElement>({
      isList: true,
      offset: 30,
      duration: 0,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const theme = useMarkovTheme();

    const selectItem = (index: number) => {
      const item = items[index];

      if (item?.command) {
        item.command({ editor, range });
      }
    };
    const upHandler = () => {
      if (selectedIndex === 0) {
        return;
      }
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      if (selectedIndex === items.length - 1) {
        return;
      }
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        targetRef.current = itemsRefs.current[selectedIndex];

        if (event.key === 'ArrowUp') {
          event.stopPropagation();
          scrollIntoView({ alignment: 'start' });
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          event.stopPropagation();
          scrollIntoView({ alignment: 'end' });
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          event.stopPropagation();
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    const getHandleMouseOver = (idx: number) => () => {
      setSelectedIndex(idx);
    };

    const getHandleSelectItem = (idx: number) => () => {
      selectItem(idx);
    };

    if (items.length === 0) {
      return null;
    }

    return (
      <Card shadow="xl" withBorder p={0}>
        <ScrollArea.Autosize mah={300} miw={250} bg="white.0" viewportRef={scrollableRef}>
          <Vertical spacing="xs">
            {items.map(({ title, description, icon: Icon }, idx) => (
              <UnstyledButton
                key={idx}
                py="xs"
                px="sm"
                onClick={getHandleSelectItem(idx)}
                onMouseOver={getHandleMouseOver(idx)}
                bg={selectedIndex === idx ? 'blue.0' : ''}
                color={selectedIndex === idx ? 'white' : ''}
                ref={(node: HTMLButtonElement) => {
                  if (itemsRefs && itemsRefs.current) {
                    // eslint-disable-next-line no-param-reassign
                    itemsRefs.current[idx] = node;
                  }
                }}
              >
                <Horizontal p="xs">
                  <Icon color={theme.colors.gray[7]} />
                  <Vertical spacing={0}>
                    <Text variant="subTitle03" color={selectedIndex === idx ? 'blue.6' : 'dark'}>
                      {title}
                    </Text>
                    <Text variant="small03" color="gray.6">
                      {description}
                    </Text>
                  </Vertical>
                </Horizontal>
              </UnstyledButton>
            ))}
          </Vertical>
        </ScrollArea.Autosize>
      </Card>
    );
  },
);

CommandsList.displayName = 'CommandsList';
