/**
 * Returns a new array which is created by inserting the interleaved component between
 * each JSX element of the input array.
 *
 * @param {Array} arr - The input array of JSX elements
 * @param x - The component to interleave
 * @returns {Array} The output array with interleaved elements
 */
export const interleaveComponent = (arr: JSX.Element[], Component: React.FC<any>): JSX.Element[] =>
  arr.flatMap((elem, i) => [elem, <Component key={`interleaved-${i}`} />]).slice(0, -1);
