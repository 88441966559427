import { getColors } from '../../charts/utils/colors';
import { AnalysisData, DataCategory, SegmentType } from '../../generated/api';

export const UNLABELED_IDS = [
  '__mkv_unl__',
  'mkv_unb',
  '_mkv_unb_',
  '__markov_unlabelled__',
  'markov_unlabelled',
];

export const CONTINUOUS_TARGET_IDS = ['__mkv_cont__'];

const nameTransformMap = {
  ...Object.fromEntries(UNLABELED_IDS.map(id => [id, 'Unlabeled'])),
  ...Object.fromEntries(CONTINUOUS_TARGET_IDS.map(id => [id, 'Continuous target'])),
};

export const isTargetUnlabeled = (target: string) => UNLABELED_IDS.includes(target);

export const transformSeriesName = (name: string) => nameTransformMap[name] ?? name;

export const replaceSeriesName = (label: string) => {
  Object.entries(nameTransformMap).forEach(([key, replacement]) => {
    label = label.replace(key, replacement);
  });
  return label;
};

const chartColors = getColors(20);

export const segmentToColors = {
  [SegmentType.Test]: [chartColors[0], chartColors[1]],
  [SegmentType.Train]: [chartColors[4], chartColors[5]],
  [SegmentType.Validate]: [chartColors[10], chartColors[11]],
  [SegmentType.Unknown]: [chartColors[8], chartColors[9]],
  [SegmentType.All]: [chartColors[8], chartColors[9]],
};

// Check for a "versioned" dataset name (ending in "v2", "v3", etc.).
// If a version was found, increment it for the next version.
export const getDatasetNextVersionName = (originalName: string) => {
  const pattern = /(.+)\sv(\d+)$/;
  const match = pattern.exec(originalName);

  if (!match) {
    return `${originalName} v2`;
  }

  const baseName = match[1];
  const currVersion = +match[2];
  return `${baseName} v${currVersion + 1}`;
};

export const getIsQualityScoreSupported = (type: DataCategory) =>
  [DataCategory.Text, DataCategory.MixedOrCategorical, DataCategory.Numerical].includes(type);

export const getIsAnalysisSupported = (type: DataCategory) =>
  [DataCategory.Text, DataCategory.MixedOrCategorical].includes(type);

export const getIsImbalanced = (data: AnalysisData) => {
  if (!data.series.length) {
    return false;
  }
  const frequencies = data.series.map(series => series.data[0].y ?? 0);
  const [minority, majority] = [Math.min(...frequencies), Math.max(...frequencies)];
  // Note: This is a common rule-of-thumb to determine whether data is imbalanced
  const isImbalanced = majority !== 0 && minority / majority < 0.2;
  return isImbalanced;
};
