import { FocusTrap } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { KeyboardEventHandler, useState } from 'react';
import {
  ActionIcon,
  Horizontal,
  Skeleton,
  Text,
  TextArea,
  useInputState,
  Vertical,
} from '../../../../design-system/v2';

export type Info = {
  title: string;
  content: string;
} & (
  | {
      isEditable: true;
      onSave: (value: string) => Promise<unknown>;
    }
  | never
);

export interface MoreInfoListItemProps {
  info: Info;
}

enum States {
  VIEW = 'view',
  EDIT = 'edit',
  LOADING = 'loading',
}

export const MoreInfoListItem = ({ info }: MoreInfoListItemProps) => {
  const [state, setState] = useState<States>(States.VIEW);
  const { title, content, isEditable, onSave } = info;
  const [inputValue, setInputValue] = useInputState(content);

  const saveValue = async () => {
    try {
      setState(States.LOADING);
      await onSave(inputValue);
    } catch {
      // Discuss with the design team on showing errors
    }
    setState(States.VIEW);
  };

  const handleBlur = () => {
    saveValue();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    if (event.code === 'Enter') {
      event.preventDefault();
      saveValue();
    }

    if (event.code === 'Escape') {
      setState(States.VIEW);
    }
  };

  const handleEditButtonClick = () => {
    setState(States.EDIT);
  };

  const EditView = (
    <FocusTrap>
      <TextArea
        w="100%"
        ariaLabel={title}
        value={inputValue}
        onChange={setInputValue}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        minLength={50}
      />
    </FocusTrap>
  );

  const ReadView = <Text variant="bodyShort02">{content}</Text>;

  const Loader = <Skeleton w={100} h={20} />;

  const renderContent = () => {
    if (state === States.EDIT) {
      return EditView;
    }
    if (state === States.VIEW) {
      return ReadView;
    }
    if (state === States.LOADING) {
      return Loader;
    }
  };

  return (
    <Vertical w="100%">
      <Horizontal w="100%" position="left" align="end">
        <Text variant="bodyShort02" fw={600}>
          {title}
        </Text>
        {isEditable && state === States.VIEW && (
          <ActionIcon mih={20} miw={20} w={20} h={20} onClick={handleEditButtonClick}>
            <IconPencil size={20} />
          </ActionIcon>
        )}
      </Horizontal>
      {renderContent()}
    </Vertical>
  );
};
