import type { JsonFormsCore, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Button, Horizontal, Vertical } from '../../../design-system/v2';
import { OperatorCategory, OperatorModel } from '../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../queries/workflows/dag';
import { useGetOperatorListQuery } from '../../../queries/workflows/operators';
import { transformNodeToWorkflowNode } from '../../workflows/nodes/utils';
import { renderers } from './renderers';

export interface FormSchema {
  schema: JsonSchema;
  uischema?: UISchemaElement;
}

interface FormProps {
  schema: FormSchema;
  initialState?: any;
  // JsonForms does not export error type ;(
  onChange: (data: any) => void;

  onErrors: (errors: any[]) => void;

  isSubmitting?: boolean;
  onSubmit: (data: any) => void;

  viewOnly?: boolean;
  workflowId?: string;
  runId?: string;
  nodeId?: string;
  operator?: OperatorModel;
  prevNodeId?: string;
}

export const Form = ({
  schema,
  initialState,
  onErrors,
  isSubmitting,
  onSubmit,
  viewOnly,
  operator,
  workflowId,
  runId,
  nodeId,
  prevNodeId,
}: FormProps) => {
  const [data, setData] = useState(initialState);
  const { data: operatorsList } = useGetOperatorListQuery();
  const { data: workflowDAG } = useGetWorkflowDAGQuery(workflowId ?? '');
  const node = workflowDAG?.nodes
    .map(node => transformNodeToWorkflowNode(node, operatorsList ?? []))
    .find(node => node.id === nodeId);

  const [showErrors, setShowErrors] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    setIsFormDirty(!isEqual(data, node?.data.configuration));
  }, [data, initialState, node]);

  useEffect(() => {
    if (showErrors || isEqual(data, initialState)) {
      return;
    }

    setShowErrors(true);
  }, [data]);

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (errors && errors.length > 0) {
      onErrors(errors);
    }

    setData(data);
  };

  const handleSubmit = () => {
    onSubmit(data);
  };

  const applicableUiSchema = schema.uischema;
  const operatorCategory = operator?.category as OperatorCategory;
  const operatorId = operator?.operatorId;

  return (
    <Vertical h="100%" spacing={0} justify="space-between">
      <JsonForms
        schema={schema.schema}
        uischema={applicableUiSchema}
        data={data}
        config={{
          workflowId,
          runId,
          nodeId,
          viewOnly,
          prevNodeId,
          operatorCategory,
          operatorId,
          isFormDirty,
        }}
        renderers={renderers}
        onChange={handleFormChange}
        readonly={viewOnly || isSubmitting}
        validationMode={showErrors ? 'ValidateAndShow' : 'NoValidation'}
      />
      <Horizontal noWrap>
        {!viewOnly ? (
          <Horizontal pt="lg" noWrap>
            <Button
              w="168px"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting || !isFormDirty}
              onClick={handleSubmit}
            >
              Apply changes
            </Button>
          </Horizontal>
        ) : null}
      </Horizontal>
    </Vertical>
  );
};
