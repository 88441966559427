import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { evaluationsApi } from '../../lib/api';
import { useChartQuery } from '../charts-query-wrapper';
import { EVALUATION } from '../queryConstants';

const rocPrCurveQueryKeys = {
  ROC_CURVE: (workspaceId: string, evaluationIds: string[]) => [
    EVALUATION.GET_EVAL_ROC_CURVE,
    workspaceId,
    ...evaluationIds,
  ],
  PR_CURVE: (workspaceId: string, evaluationIds: string[]) => [
    EVALUATION.GET_EVAL_PR_CURVE,
    workspaceId,
    ...evaluationIds,
  ],
};

export const useGetRocCurveQuery = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    rocPrCurveQueryKeys.ROC_CURVE(workspaceId, evaluationIds),
    () => evaluationsApi.getEvaluationRocCurveChartV1(workspaceId, evaluationIds),
    {
      publicChartFn: res => ({ data: { response: res.data.rocData.response } }),
      select: data => data.data.response,
    },
  );
};

export const useGetPRCurveQuery = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    rocPrCurveQueryKeys.PR_CURVE(workspaceId, evaluationIds),
    () => evaluationsApi.getEvaluationPRCurveChartV1(workspaceId, evaluationIds),
    {
      publicChartFn: res => ({ data: { response: res.data.prData.response } }),
      select: data => data.data.response,
    },
  );
};
