import { CellProps } from 'react-table';
import {
  TagsColumnFilter,
  TagsColumnFilterDeprecated,
} from '../../../design-system/Table/components/data-table/filters/tags/TagsColumnFilter';
import { EvaluationModelWithTags, EvaluationStatus } from '../../../generated/api';
import { EvaluationState } from '../../recording-details/status/EvaluationStatus';
import { TagsCell } from './TagsCell';

export const TAGS_COLUMN_ID = 'tags';

// TODO: Remove this after all tables with Tags column are migrated to new context
export const TagsColumnDeprecated = {
  Header: 'Tags',
  accessor: 'tags',
  id: TAGS_COLUMN_ID,
  width: 230,
  canResize: false,
  Filter: TagsColumnFilterDeprecated,
  Cell: TagsCell,
};

export const TagsColumn = {
  Header: 'Tags',
  accessor: 'tags',
  id: TAGS_COLUMN_ID,
  width: 180,
  canResize: false,
  Filter: TagsColumnFilter,
  Cell: TagsCell,
};

export const StatusColumn = {
  Header: 'Status',
  accessor: 'status',
  id: 'status',
  width: 200,
  disableFilters: true,
  canResize: false,
  Cell: ({ value }: CellProps<EvaluationModelWithTags>) => (
    <EvaluationState status={value as EvaluationStatus} />
  ),
};

export const getActionColumn = <T extends object>(
  CellComponent: ({ row }: CellProps<T, any>) => JSX.Element,
) => ({
  Header: ' ',
  accessor: 'actions',
  id: 'actions',
  width: 56,
  canResize: false,
  disableFilters: true,
  Cell: CellComponent,
});
