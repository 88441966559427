import noop from 'lodash/noop';
import { useCallback, useMemo } from 'react';
import { CellProps, Row as ReactTableRow } from 'react-table';
import { DataTable } from '../../../../../design-system';
import { Checkbox, ScrollArea, Text } from '../../../../../design-system/v2';
import { Dataset } from '../../../../../queries/datasets/list';
import { TAGS_COLUMN_ID } from '../../../../common/table/Columns';
import { StyledClickableWrapper } from '../../../../datasets-table/DatasetsTable.style';
import { getTableCols } from '../../../../datasets-table/columns';
import { useSnippetDetail } from '../../context/SnippetContext';

interface SelectDatasetsListProps {
  datasets?: Dataset[];
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

const columnsToShow = ['name', 'dataFamilyName', TAGS_COLUMN_ID];

export const SelectDatasetsList = ({
  datasets = [],
  isLoading = false,
  isFetchingNextPage = false,
  hasNextPage = false,
  fetchNextPage,
}: SelectDatasetsListProps): JSX.Element => {
  const { selectedDatasets, toggleDatasetSelection } = useSnippetDetail();

  const isDatasetSelected = useCallback(
    (dsId: string) => selectedDatasets.some(({ datasetId }) => datasetId === dsId),
    [selectedDatasets],
  );

  const columns = useMemo(() => {
    const displayedCols = columnsToShow;
    return [
      {
        Header: ' ',
        accessor: 'datasetId',
        id: 'selected',
        width: 44,
        canResize: false,
        disableFilters: true,
        Cell: ({ value: datasetId }: CellProps<Dataset, string>) => (
          <StyledClickableWrapper>
            <Checkbox checked={isDatasetSelected(datasetId)} onChange={noop} />
          </StyledClickableWrapper>
        ),
      },
      ...getTableCols(isDatasetSelected, noop, displayedCols),
    ];
  }, [isDatasetSelected]);

  const getIsRowSelected = (row: ReactTableRow<Record<string, unknown>>) =>
    isDatasetSelected(row.original.datasetId as string);

  const getTableRowProps = (row: ReactTableRow<Record<string, unknown>>) => ({
    isClickable: true,
    onClick: () => {
      toggleDatasetSelection(row.original);
    },
  });

  return (
    <>
      <Text variant="subTitle02" p="xl">
        Select datasets to include
      </Text>
      <ScrollArea p="sm" h="calc(100vh - 180px)">
        <DataTable
          dataTestId="datasets-table"
          data={datasets}
          columns={columns}
          height="100%"
          getRowProps={getTableRowProps}
          getIsRowSelected={getIsRowSelected}
          displayConfig={{
            hideHeaders: false,
          }}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </ScrollArea>
    </>
  );
};
