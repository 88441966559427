import xor from 'lodash/xor';
import { useState } from 'react';
import { Container, Grid, Horizontal, Tabs, Text } from '../../design-system/v2';
import {
  ArtifactStateType,
  BaseAPIFilter,
  DataOperationType,
  DatasetOperationState,
  Operator,
} from '../../generated/api';
import { capitalize } from '../../lib/ui';
import { useGetWorkspaceMembersQuery } from '../../queries/account/workspace';
import { ListingTabs } from '../common/constants';
import { DataLabelingListContainer } from './DataLabelingList.container';
import { DataLabelingSidePanel } from './DataLabelingSidePanel';
import { StartLabelingMenu } from './StartLabelingMenu';

interface DataLabelingBodyWrapperProps {
  state: ArtifactStateType;
}

const DataLabelingBodyWrapper = ({ state }: DataLabelingBodyWrapperProps) => {
  const [filters, setFilters] = useState<BaseAPIFilter[]>([]);

  const { data } = useGetWorkspaceMembersQuery();
  const workspaceMembers = data?.workspaceMembers ?? [];

  const handleFiltersChange = (
    id: string,
    value: DataOperationType | DatasetOperationState | string,
  ) => {
    const existingFilter = filters.find(filter => filter.field === id);
    if (!existingFilter) {
      const newFilter = { field: id, operator: Operator.In, value: [value] };
      setFilters([...filters, newFilter]);
    } else {
      const nextValue = xor(existingFilter.value, [value]);
      // Unset filter if no values remain
      if (nextValue.length === 0) {
        setFilters(filters.filter(filter => filter.field !== id));
      } else {
        const nextFilter = { ...existingFilter, value: nextValue };
        setFilters(filters.map(filter => (filter.field === id ? nextFilter : filter)));
      }
    }
  };

  return (
    <Grid h="100%" gutter={24} my="sm">
      <Grid.Col span="content" h="100%">
        <DataLabelingSidePanel
          workspaceMembers={workspaceMembers}
          handleFiltersChange={handleFiltersChange}
        />
      </Grid.Col>
      <Grid.Col span="auto" h="100%">
        <DataLabelingListContainer
          state={state}
          workspaceMembers={workspaceMembers}
          filters={filters}
        />
      </Grid.Col>
    </Grid>
  );
};

export const DataLabelingListWrapper = () => (
  <Container size="xl">
    <Horizontal position="apart" mt="xl" h="48px">
      <Text variant="heading02">Data Labeling</Text>
      <StartLabelingMenu />
    </Horizontal>
    <Tabs keepMounted={false} defaultValue={ListingTabs.ACTIVE}>
      <Tabs.List mb="xl">
        <Horizontal position="left" w="100%">
          <Tabs.Tab value={ListingTabs.ACTIVE}> {capitalize(ListingTabs.ACTIVE)}</Tabs.Tab>
          <Tabs.Tab value={ListingTabs.ARCHIVED}>{capitalize(ListingTabs.ARCHIVED)}</Tabs.Tab>
        </Horizontal>
      </Tabs.List>
      <Tabs.Panel value={ListingTabs.ACTIVE}>
        <DataLabelingBodyWrapper state={ArtifactStateType.Active} />
      </Tabs.Panel>
      <Tabs.Panel value={ListingTabs.ARCHIVED}>
        <DataLabelingBodyWrapper state={ArtifactStateType.Archived} />
      </Tabs.Panel>
    </Tabs>
  </Container>
);
