import { createStyles } from '../../../design-system/v2';

export const useSectionWrapperStyles = createStyles(theme => {
  const bgColor = theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white[0];
  const borderColor = theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3];

  return {
    item: {
      backgroundColor: bgColor,
      borderRadius: theme.radius.xs,
      border: `1px solid ${borderColor}`,
      boxShadow: theme.shadows.sm,
    },
    control: {
      borderBottom: `1px solid ${borderColor}`,
    },
    content: {
      padding: 0,
    },
  };
});
