import css from '@styled-system/css';
import styled from 'styled-components';
import { Box, ErrorIcon, Text } from '../../design-system';

interface ErrorBarProps {
  errorMessage?: string;
}

const StyledErrorBarContainer = styled(Box)(
  css({
    height: 64,
    width: '100%',
    border: 'thinSolid',
    borderColor: 'error.base',
    bg: 'background.error',
    p: '16px',
    display: 'flex',
    alignItems: 'center',
  }),
);

export const ErrorBar = ({
  errorMessage = 'Something went wrong! Please try again',
}: ErrorBarProps): JSX.Element => (
  <StyledErrorBarContainer>
    <ErrorIcon />
    <Text variant="standard2" color="text.shade1" ml="12px">
      {errorMessage}
    </Text>
  </StyledErrorBarContainer>
);
