import { BarChartIllustration } from '../../../../../../design-system';
import { VisualizationType } from '../../../../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { useAnalysersGroupByMetricQuery } from '../../../../../../queries/datasets/analysis/analysis';
import { getAnalysersFromGroupedMetrics } from '../../../../../analyser/analyser.util';
import { getFlatAnalysisList } from '../../../../../dataset-comparison/analysis-comparison/analysis-comparator/dataset-analysis-column/AnalysisColumn';
import { getChartsData } from '../util';

export const useGetAnalysis = (datasetId: string, selectedChartName: string) => {
  const { data, isLoading } = useAnalysersGroupByMetricQuery(datasetId);

  if (!data) {
    return { analysersTypes: [], analysersChartList: [], isLoading };
  }

  const analysersGroupData = data.response.map(item => item);
  const analysersTypes = getAnalysersFromGroupedMetrics(analysersGroupData).map(
    ({ metricName }) => metricName,
  );

  const analysersChartList = getFlatAnalysisList(analysersGroupData, selectedChartName).map(
    analysis => {
      const { title = '', segmentType, metricName, analysisId } = analysis;
      return getChartsData({
        visualizationType: VisualizationType.DATASET_ANALYSER,
        title: title ?? metricName,
        subTitle: segmentType,
        chart: <BarChartIllustration />,
        visualizationParameters: { datasetId, analysisId, segmentType, title },
      });
    },
  );

  return { analysersTypes, analysersChartList, isLoading };
};
