import { CellProps } from 'react-table';
import { Box, Text, TextVariants } from '../../../design-system/v2';
import { EvaluationWithTags } from '../../../generated/api';
import { TableRowName } from '../../common/TableRowName';

interface EvaluationsNameCellProps extends CellProps<EvaluationWithTags> {
  textVariant: TextVariants;
}

export const EvaluationsNameCell: React.FC<EvaluationsNameCellProps> = ({
  value: recordingName,
  row,
  textVariant,
}) => (
  <Box>
    <TableRowName isSampleRecord={row.original.demoArtifact}>
      <Text
        data-testid="evaluations-table-evaluationName"
        variant={textVariant}
        color="dark.8"
        lineClamp={1}
        title={recordingName}
      >
        {recordingName}
      </Text>
    </TableRowName>

    {!row.original.isActive ? (
      <Text variant="subTitle03" color="red.8">
        Deleted
      </Text>
    ) : null}
  </Box>
);
