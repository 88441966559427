import { IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon, Box, Menu } from '../../../../design-system/v2';
import { TemplateAction } from './utils';

const MENU_WIDTH = 130;

interface TemplatesActionsProps {
  actions: TemplateAction[];
}

export const TemplateActions = ({ actions }: TemplatesActionsProps): JSX.Element => (
  <Box
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Menu shadow="md" position="bottom-end" width={MENU_WIDTH} withinPortal>
      <Menu.Target>
        <ActionIcon bg="white.0">
          <IconDotsVertical size={20} color="#777" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(action => (
          <Menu.Item key={action.id} py="md" onClick={action.handler}>
            {action.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  </Box>
);
