import { Tabs, Vertical } from '../../../../../design-system/v2';
import { useAppProjectStyle } from '../../commons/app-project/AppProjects.style';
import { DictionarySetWrapper } from './Dictionary/DictionarySet.wrapper';
import { RuleSetWrapper } from './RulesSet/RulesSet.wrapper';

enum TabsOptions {
  CUSTOM_RULES = 'CUSTOM_RULES',
  CUSTOM_DICTIONARY = 'CUSTOM_DICTIONARY',
}

export const SettingsTabs = () => {
  const { classes } = useAppProjectStyle();

  return (
    <Vertical className={classes.mainPanel}>
      <Tabs defaultValue={TabsOptions.CUSTOM_RULES}>
        <Tabs.List>
          <Tabs.Tab value={TabsOptions.CUSTOM_RULES}>Custom Rules</Tabs.Tab>
          <Tabs.Tab value={TabsOptions.CUSTOM_DICTIONARY}>Custom Dictionary</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TabsOptions.CUSTOM_RULES}>
          <RuleSetWrapper />
        </Tabs.Panel>
        <Tabs.Panel value={TabsOptions.CUSTOM_DICTIONARY}>
          <DictionarySetWrapper />
        </Tabs.Panel>
      </Tabs>
    </Vertical>
  );
};
