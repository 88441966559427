import { Box, Button, Horizontal, Text } from '../../../design-system/v2';
import { useGetConnectorsListQuery } from '../../../queries/connectors';
import { InfoTooltip } from '../../common/InfoTooltip';
import { useCreateConnectorModal } from '../../connectors/modals/CreateConnectorModal';
import { SettingsSection } from '../SettingsSection';
import { ConnectorListContainer } from './ConnectorsList.container';

const ConnectorsHeader = (): JSX.Element => {
  const connectorsQuery = useGetConnectorsListQuery();
  const { open: openCreateConnectorModal } = useCreateConnectorModal();

  const numConnectors = connectorsQuery.data?.length ?? 0;
  const title = 'Connectors' + (numConnectors > 0 ? ` (${numConnectors})` : '');

  return (
    <Horizontal m={0} w="100%" position="apart">
      <Horizontal spacing="xs">
        <Text variant="subTitle02">{title}</Text>
        <InfoTooltip
          text="Connectors let you securely access data hosted on external services"
          maw={265}
        />
      </Horizontal>
      <Button variant="outline" onClick={openCreateConnectorModal}>
        Add connector
      </Button>
    </Horizontal>
  );
};

export const ConnectorsContainer = (): JSX.Element => (
  <SettingsSection title={<ConnectorsHeader />}>
    <Box mt={24}>
      <ConnectorListContainer />
    </Box>
  </SettingsSection>
);
