import { TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipItemRow } from '../ChartTooltip.style';

interface PRCurveTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const PRCurveTooltip = ({ formatterContext }: PRCurveTooltipProps) => (
  <TooltipContainer>
    <TooltipItemRow>Precision: {formatterContext.y}</TooltipItemRow>
    <TooltipItemRow>Recall: {formatterContext.x}</TooltipItemRow>
    <TooltipItemRow>Threshold: {formatterContext.total ?? 0}</TooltipItemRow>
  </TooltipContainer>
);
