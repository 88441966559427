import { Info } from '../../../../design-system';
import { ActionIcon, Horizontal, Text, Tooltip, Vertical } from '../../../../design-system/v2';

const OVERALL_QUALITY_SCORE_DESCRIPTION =
  'This score reflects the probable accuracy of the labels assigned across all classes within the dataset.';

interface ClassQualityScoreHeaderProps {
  dataQualityScore: number;
}

export const ClassQualityScoreHeader = ({ dataQualityScore }: ClassQualityScoreHeaderProps) => (
  <Vertical
    spacing="8px"
    h="100px"
    w="100%"
    pt="lg"
    pl="lg"
    sx={theme => ({ borderBottom: `2px solid ${theme.fn.themeColor('gray.2')}` })}
  >
    <Text variant="heading03" color="blue.6">
      {dataQualityScore}%
    </Text>
    <Horizontal spacing="xs">
      <Text
        variant="overline"
        color="gray.6"
        tt="uppercase"
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        Overall quality score
      </Text>
      <Tooltip label={OVERALL_QUALITY_SCORE_DESCRIPTION} withArrow withinPortal multiline maw={325}>
        <ActionIcon variant="transparent">
          <Info />
        </ActionIcon>
      </Tooltip>
    </Horizontal>
  </Vertical>
);
