import { IconGripHorizontal, IconGripVertical } from '@tabler/icons-react';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import get from 'lodash/get';
import { ActionIcon, Menu } from '../../../';
import { useSnippetDetail } from '../../../../../components/snippets/detail/context/SnippetContext';
import { useParagraphForTableStyles } from './paragraphForTable.style';

export const ParagraphForTableView = ({ editor, getPos }: NodeViewProps) => {
  const { classes, cx } = useParagraphForTableStyles();
  const { editing } = useSnippetDetail();

  const nodePos = getPos();
  const resolvedPos = nodePos ? editor.state.doc.resolve(nodePos) : undefined;

  const isTableHeaderCell = resolvedPos && resolvedPos.parent.type.name === 'tableHeader';
  const isTableCellFirst =
    resolvedPos &&
    get(resolvedPos, 'parent.attrs.data-block-id') ===
      get(resolvedPos.node(-1)?.child(0), 'attrs.data-block-id');

  return (
    <NodeViewWrapper>
      {editing && isTableHeaderCell && (
        <Menu>
          <Menu.Target>
            <ActionIcon className={cx(classes.colMenu, 'table-col-options')}>
              <IconGripHorizontal />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown sx={{ backgroundColor: '#fff', zIndex: 99 }}>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).addColumnBefore().run()}>
              Add column before
            </Menu.Item>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).addColumnAfter().run()}>
              Add column after
            </Menu.Item>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).deleteColumn().run()}>
              Delete column
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
      {editing && isTableCellFirst && (
        <Menu>
          <Menu.Target>
            <ActionIcon className={cx(classes.rowMenu, 'table-row-options')}>
              <IconGripVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown sx={{ backgroundColor: '#fff', zIndex: 99 }}>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).addRowBefore().run()}>
              Add row before
            </Menu.Item>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).addRowAfter().run()}>
              Add row after
            </Menu.Item>
            <Menu.Item onClick={() => editor.chain().focus(nodePos).deleteRow().run()}>
              Delete row
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}

      <NodeViewContent
        className={cx('content', {
          ['not-editable']: !editing,
        })}
      />
    </NodeViewWrapper>
  );
};
