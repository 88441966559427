import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconPhotoX,
  IconTrash,
} from '@tabler/icons-react';
import { NodeViewProps } from '@tiptap/react';
import { ActionIcon, Box } from '../../../';
import { useIframeViewStyles } from './IframeView.style';

interface ActionsMenuProps {
  updateAttributes: NodeViewProps['updateAttributes'];
  deleteNode: NodeViewProps['deleteNode'];
  hideIframe: () => void;
}

export const IframeActions = ({ updateAttributes, deleteNode, hideIframe }: ActionsMenuProps) => {
  const { classes } = useIframeViewStyles({ align: 'start' });

  const alignItem = (align: 'start' | 'center' | 'end') => () => {
    updateAttributes({
      dataAlign: align,
    });
  };

  return (
    <Box className={classes.mediaActions}>
      <ActionIcon title="Align Left" onClick={alignItem('start')}>
        <IconAlignLeft />
      </ActionIcon>
      <ActionIcon title="Align Center" onClick={alignItem('center')}>
        <IconAlignCenter />
      </ActionIcon>
      <ActionIcon title="Align Right" onClick={alignItem('end')}>
        <IconAlignRight />
      </ActionIcon>
      <ActionIcon title="Hide Embed" onClick={hideIframe}>
        <IconPhotoX />
      </ActionIcon>
      <ActionIcon title="Delete" onClick={deleteNode}>
        <IconTrash />
      </ActionIcon>
    </Box>
  );
};
