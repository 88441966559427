import { Grid as MantineGrid, GridProps as MantineGridProps } from '@mantine/core';

type GridProps = MantineGridProps;

const Grid = ({ children, ...restProps }: GridProps) => (
  <MantineGrid columns={20} {...restProps}>
    {children}
  </MantineGrid>
);

Grid.Col = MantineGrid.Col;

export { Grid };
