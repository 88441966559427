import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { DataTable } from '../../../../../design-system';
import { Horizontal, LinkButton, Text, Vertical } from '../../../../../design-system/v2';
import { EvaluationStatus, EvaluationWithTags } from '../../../../../generated/api';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { StatusColumn } from '../../../../common/table/Columns';
import { getEvaluationsDateColumn } from '../../../../recording/recordings-table-columns/getEvaluationsDateColumn';
import { getEvaluationsNameColumn } from '../../../../recording/recordings-table-columns/getEvaluationsNameColumn';

interface EvaluationPreviewProps {
  evaluations: EvaluationWithTags[];
}

const getTableColumns = () => {
  const NameColumn = getEvaluationsNameColumn(150, 'subTitle03');
  const DateColumn = getEvaluationsDateColumn(100, 'bodyShort03');
  return [NameColumn, DateColumn, StatusColumn];
};

export const EvaluationsPreview = ({ evaluations }: EvaluationPreviewProps): JSX.Element => {
  const navigate = useNavigate();
  const { getEvaluationDetailsRoute, getEvaluationsRoute } = useAbsoluteRoutes();

  const columns = useMemo(() => {
    const tableColumns = getTableColumns();
    return tableColumns;
  }, []);

  const handleNavigateEvaluation = (recordingId: string) => {
    const route = getEvaluationDetailsRoute(recordingId);
    navigate(route);
  };

  const getIsRowDisabled = (row: Row<Record<string, unknown>>) =>
    row.original.status !== EvaluationStatus.Success;

  const getTableRowProps = (row: Row<Record<string, unknown>>) => ({
    isClickable: true,
    onClick: () => {
      if (getIsRowDisabled(row)) {
        return;
      }
      handleNavigateEvaluation(row.original.recordingId as string);
    },
  });

  return (
    <Vertical h="100%" spacing={0} justify="space-between" p="lg">
      <DataTable
        dataTestId="evaluation-list-table"
        data={evaluations?.map(item => ({ ...item }))}
        columns={columns}
        getRowProps={getTableRowProps}
        displayConfig={{ hideHeaders: true }}
      />
      <Horizontal position="right" px="sm" pt="lg">
        <LinkButton
          variant="subtle"
          to={getEvaluationsRoute()}
          rightIcon={<IconArrowNarrowRight stroke={1.5} />}
          w="100%"
        >
          <Text variant="textLink" color="blue.7">
            View all evaluations
          </Text>
        </LinkButton>
      </Horizontal>
    </Vertical>
  );
};
