import { IconCloudUpload, IconFile } from '@tabler/icons-react';
import { useState } from 'react';
import { Grid, Tabs, Text, Vertical } from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { BlankDocument } from './DocumentTextInput.container';
import { DocumentUploadInput } from './DocumentUploadInput';
import { DocumentUploadInputContainer } from './DocumentUploadInput.container';
import { DocumentUploadInputContainerV2 } from './DocumentUploadInputV2.container';

export enum DocumentTabType {
  TEXT = 'TEXT',
  UPLOAD = 'UPLOAD',
  LMS = 'LMS',
}

interface TabDetails {
  id: DocumentTabType;
  label: string;
  icon: JSX.Element;
  allowedApps?: AppIdType[];
}

const TABS: TabDetails[] = [
  {
    id: DocumentTabType.UPLOAD,
    label: 'Upload Document',
    icon: <IconCloudUpload />,
  },
  {
    id: DocumentTabType.TEXT,
    label: 'Blank Document',
    icon: <IconFile />,
    allowedApps: [AppIdType.CopyEdit],
  },
  // TODO: Expose Import Data option once supported
  // {
  //   id: DocumentTabType.LMS,
  //   label: 'Import Data',
  //   icon: <IconLink />,
  // },
];

interface DocumentUploadModalContainerProps {
  variant: AppIdType;
  projectId?: string;
}

export const DocumentUploadModalContainer = ({
  variant,
  projectId,
}: DocumentUploadModalContainerProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(DocumentTabType.UPLOAD);
  const [inputFile, setInputFile] = useState<File>();

  return (
    <Grid gutter="xl">
      <Grid.Col span={10}>
        <Vertical>
          <Tabs
            value={activeTab}
            onTabChange={(tab: DocumentTabType) => setActiveTab(tab)}
            role="tablist"
          >
            <Tabs.List>
              {TABS.filter(({ allowedApps }) => !allowedApps || allowedApps.includes(variant)).map(
                ({ id, label, icon }) => (
                  <Tabs.Tab
                    key={id}
                    size="sm"
                    value={id}
                    icon={icon}
                    role="tab"
                    aria-selected={id === activeTab}
                    id={`${id}-tab`}
                  >
                    {label}
                  </Tabs.Tab>
                ),
              )}
            </Tabs.List>
          </Tabs>

          <div
            role="tabpanel"
            id={DocumentTabType.TEXT}
            aria-labelledby={DocumentTabType.TEXT + '-tab'}
            hidden={activeTab !== DocumentTabType.TEXT}
          >
            {activeTab === DocumentTabType.TEXT && <BlankDocument />}
          </div>
          <div
            role="tabpanel"
            id={DocumentTabType.UPLOAD}
            aria-labelledby={DocumentTabType.UPLOAD + '-tab'}
            hidden={activeTab !== DocumentTabType.UPLOAD}
          >
            {activeTab === DocumentTabType.UPLOAD && (
              <>
                <DocumentUploadInput inputFile={inputFile} setInputFile={setInputFile} />

                {inputFile?.type.includes('pdf') && variant == AppIdType.CopyEdit && (
                  <Text p="md" variant="small03" color="red.4">
                    *Please note that certain elements or formatting may not appear as intended.
                  </Text>
                )}
              </>
            )}
          </div>
          <div
            role="tabpanel"
            id={DocumentTabType.LMS}
            aria-labelledby={DocumentTabType.LMS + '-tab'}
            hidden={activeTab !== DocumentTabType.LMS}
          >
            {activeTab === DocumentTabType.LMS && (
              <Vertical align="center" mt="lg" spacing="xs">
                <Text variant="heading03" color="gray.6">
                  Coming Soon
                </Text>
                <Text variant="subTitle03" color="gray.6">
                  Import your data from LMS
                </Text>
              </Vertical>
            )}
          </div>
        </Vertical>
      </Grid.Col>
      <Grid.Col span={10}>
        {projectId ? (
          <DocumentUploadInputContainerV2
            activeTab={activeTab}
            inputFile={inputFile}
            variant={variant}
            projectId={projectId}
          />
        ) : (
          <DocumentUploadInputContainer
            activeTab={activeTab}
            inputFile={inputFile}
            variant={variant}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
