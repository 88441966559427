import { IconDotsVertical } from '@tabler/icons-react';
import { FullPageLoader } from '../../../../../../design-system';
import {
  ActionIcon,
  Center,
  Menu,
  Text,
  notifications,
  openModal,
} from '../../../../../../design-system/v2';
import { AddUpdateStyleGuideRuleSetRequest } from '../../../../../../generated/api';
import { useDeleteStyleGuideRulesetMutation } from '../../../../../../queries/document-ai/copyEdit';
import { AddRuleSetModal } from './AddRuleSet';

interface RulesSetActionsProps {
  ruleSetId: string;
  data: AddUpdateStyleGuideRuleSetRequest;
}

export const RulesSetActions = ({ ruleSetId, data }: RulesSetActionsProps) => {
  const { mutate: deleteRuleSet, isLoading } = useDeleteStyleGuideRulesetMutation();

  const handleDeleteRuleSet = () => {
    deleteRuleSet(ruleSetId, {
      onSuccess: () => {
        notifications.success('Ruleset Deleted Successfully!');
      },
      onError: () => {
        notifications.error('Not able to delete ruleset');
      },
    });
  };

  const handleEditRuleSet = () => {
    openModal({
      size: 'xl',
      title: <Text variant="heading03">Edit Rule Set</Text>,
      children: <AddRuleSetModal ruleSetId={ruleSetId} data={data} />,
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}
      <Menu shadow="md" withinPortal>
        <Menu.Target>
          <Center h="100%">
            <ActionIcon>
              <IconDotsVertical />
            </ActionIcon>
          </Center>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={handleDeleteRuleSet}>
            <Text variant="bodyShort03" align="center">
              Delete
            </Text>
          </Menu.Item>
          <Menu.Item onClick={handleEditRuleSet}>
            <Text variant="bodyShort03" align="center">
              Edit
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
