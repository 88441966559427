import { RowClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useMemo, useRef, useState } from 'react';
import { List } from '../../../../../../design-system/v2/core/data-display/table';
import { ResourceModel, StorageType } from '../../../../../../generated/api';
import { useGetConnectorResourcesListQueryV3 } from '../../../../../../queries/data-sources';
import { ErrorState } from '../../../../../common/ErrorState';
import { getDataSourceColumns } from '../../../../../data-sources/DataSourcesTable';

const PAGE_SIZE = 20;

interface ConnectorSourceTableContainerProps {
  connectorType: StorageType;
  onSelectResource: (resourceId: string) => void;
  isDataSink: boolean;
}

export const ConnectorSourceTableContainer = ({
  connectorType,
  onSelectResource,
  isDataSink,
}: ConnectorSourceTableContainerProps): JSX.Element => {
  const gridRef = useRef<AgGridReact<ResourceModel>>(null);
  const [page, setPage] = useState(1);

  const dataSourcesQuery = useGetConnectorResourcesListQueryV3(
    [connectorType],
    isDataSink,
    page,
    PAGE_SIZE,
  );

  const dataSourceColumns = useMemo(() => getDataSourceColumns(isDataSink), [isDataSink]);

  if (dataSourcesQuery.error) {
    return (
      <ErrorState
        title="Error fetching available data sources"
        subtitle="Try refreshing the page or contact support if the problem persists"
      />
    );
  }

  const handleRowClick = (event: RowClickedEvent<ResourceModel>) => {
    const resourceId = event.data?.resourceId ?? '';
    onSelectResource(resourceId);
  };

  const handlePageNumberChange = (pageNumber: number) => {
    gridRef.current?.api.showLoadingOverlay();
    setPage(pageNumber);
  };

  const dataSources = dataSourcesQuery.data?.response ?? [];
  const dataSourcesCount = dataSourcesQuery.data?.count ?? 0;

  return (
    <List<ResourceModel>
      gridRef={gridRef}
      rowData={dataSources}
      columns={dataSourceColumns}
      pagination
      currentPage={page}
      paginationPageSize={PAGE_SIZE}
      onPageNumberChange={handlePageNumberChange}
      totalRows={dataSourcesCount}
      onRowClicked={handleRowClick}
      leftActionsContent={<></>}
    />
  );
};
