import { useUserPendingInvitesQuery } from '../../../../queries/account/invite';
import { useGetWorkspaceDetailsQuery } from '../../../../queries/account/workspace';
import { useUserInfo } from '../../../../queries/user';
import { LogoutButton } from '../LogoutButton';
import { AccountMenu } from './AccountMenu';

export const AccountMenuContainer = (): JSX.Element => {
  const userInfoQuery = useUserInfo();
  const workspaceDetailsQuery = useGetWorkspaceDetailsQuery();
  const pendingInvitesQuery = useUserPendingInvitesQuery();

  if (!userInfoQuery.data) {
    return <LogoutButton />;
  }

  const userData = userInfoQuery.data.user;
  const workspaceName = workspaceDetailsQuery.data?.workspaceName ?? '';
  const showIndicator = !!pendingInvitesQuery.data?.length;

  return (
    <AccountMenu userData={userData} workspaceName={workspaceName} showIndicator={showIndicator} />
  );
};
