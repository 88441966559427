import { TagMetadata } from '../../../../../../generated/api';
import { toPlural } from '../../../../../../lib/ui';
import { Box, Button, Card, Horizontal, Text } from '../../../../../v2';
import { TagsGroupContainer } from './TagsGroup.container';

interface TagsFilterMenuProps {
  selectedTagIds: string[];
  onClose: () => void;
  onSave: () => void;
  onClear: () => void;
  onClickTag: (tag: TagMetadata) => void;
}

export const TagsFilterMenu = ({
  selectedTagIds,
  onClose,
  onSave,
  onClear,
  onClickTag,
}: TagsFilterMenuProps) => {
  const tagsCount = selectedTagIds.length;
  const summaryText = `${toPlural(tagsCount, 'tag')} selected`;

  return (
    <Card>
      <Horizontal position="apart">
        <Text variant="subTitle04" color="dark.6">
          {summaryText}
        </Text>
        <Button size="xs" variant="subtle" onClick={onClear} disabled={!selectedTagIds.length}>
          Clear
        </Button>
      </Horizontal>
      <Box my={8}>
        <TagsGroupContainer selectedTagIds={selectedTagIds} onClickTag={onClickTag} />
      </Box>
      <Horizontal position="right">
        <Button size="xs" variant="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button size="xs" variant="primary" onClick={onSave}>
          Apply
        </Button>
      </Horizontal>
    </Card>
  );
};
