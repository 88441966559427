import noop from 'lodash/noop';
import {
  Alert,
  Loader,
  Tabs,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../../../../../design-system/v2';
import {
  DataProperties,
  IOSchema,
  OperatorCategory,
  WorkflowRunOperatorStatus,
} from '../../../../../../../generated/api';
import {
  useDebugRunStatusQuery,
  useInitDebugMutation,
} from '../../../../../../../queries/workflows/debug';
import { useGetWorkflowDetailsQuery } from '../../../../../../../queries/workflows/detail/detail';
import { useGetWorkflowDagNodesSchemaV2Query } from '../../../../../../../queries/workflows/operators';
import { DataPreviewContainer } from '../../../../debug-panel/logs-modal/DataPreview.container';
import { MissingDebugRun } from './MissingDebugRun';
import { WorkflowDagNodeSchema } from './WorkflowDagNodeSchema';

enum NodeTabs {
  Input = 'Input',
  Output = 'Output',
}

interface WorkflowDagNodeSchemaContainerProps {
  workflowId: string;
  nodeId: string;
  runId?: string;
  prevNodeId?: string;
  operatorCategory?: OperatorCategory;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  isFormDirty?: boolean;
}

const renderSchemaContent = (
  schemas: IOSchema[] | IOSchema[][],
  renderContent: (schema: any) => JSX.Element,
  type: 'Input' | 'Output',
  hideSection?: boolean,
) => {
  if (hideSection) {
    return <></>;
  }

  if (schemas.length > 1) {
    return (
      <Tabs defaultValue={`${type}1`} h="100%">
        <Tabs.List mb="sm">
          {schemas.map((_, index) => (
            <Tabs.Tab value={`${type}${index + 1}`} key={`${type}${index + 1}`}>
              {`${type} ${index + 1}`}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {schemas.map((schema, index) => (
          <Tabs.Panel
            value={`${type}${index + 1}`}
            key={`${type}Panel${index + 1}`}
            h="calc(100% - 46px)"
          >
            {renderContent(schema)}
          </Tabs.Panel>
        ))}
      </Tabs>
    );
  }

  return (
    <Vertical h="100%">
      <Text variant="subTitle03" color="gray.8">
        {`${type}`}
      </Text>
      {schemas.map(schema => renderContent(schema))}
    </Vertical>
  );
};

export const WorkflowDagNodeSchemaContainer = ({
  workflowId,
  nodeId,
  runId,
  prevNodeId,
  operatorCategory,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  isFormDirty,
}: WorkflowDagNodeSchemaContainerProps) => {
  const theme = useMarkovTheme();

  const { isLoading, isFetching, isError, data } = useGetWorkflowDagNodesSchemaV2Query(workflowId);
  const {
    isLoading: runStatusLoading,
    isError: runStatusError,
    data: runStatus,
  } = useDebugRunStatusQuery(workflowId, runId);
  const {
    isLoading: loadingWFDetails,
    isError: wfDetailsError,
    data: wfDetails,
  } = useGetWorkflowDetailsQuery(workflowId);
  const { mutateAsync: initDebugWorkflow } = useInitDebugMutation(workflowId);

  const handleDebug = () => {
    initDebugWorkflow({});
  };

  if (isFormDirty && operatorCategory === OperatorCategory.Source) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Alert>There are some unsaved changes in the form. Please apply them</Alert>
      </Vertical>
    );
  }

  if (isLoading || isFetching) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Loader variant="dots" size="xl" />
        <Alert>Loading schema of node</Alert>
      </Vertical>
    );
  }

  const ioSchema = data?.nodeSchemas;
  const operatorStatus = runStatus?.operatorsStatus?.find(opStatus => opStatus.nodeId === nodeId);
  const nodeStatus = operatorStatus?.statusDetails.status;

  if (!ioSchema || !ioSchema[nodeId] || isError) {
    const isDebugging =
      runStatusLoading ||
      nodeStatus === WorkflowRunOperatorStatus.Running ||
      nodeStatus === WorkflowRunOperatorStatus.NotStarted;
    return (
      <MissingDebugRun
        showDebugButton={!!wfDetails?.dagId}
        onRunDebug={handleDebug}
        isLoading={isDebugging}
      />
    );
  }

  const inputSchemas = Object.values(ioSchema[nodeId]?.inputs ?? {});
  const outputSchemas = (ioSchema[nodeId]?.outputs ?? []).filter(
    (output: IOSchema) => output.type !== 'MODEL',
  );
  const showDataPreview = nodeStatus === WorkflowRunOperatorStatus.Success && runId;

  //UX for multiple inputs coming from a single previous node needs to be thought of.
  const renderInputContent = (inputSchema: IOSchema[]) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={prevNodeId ?? ''}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
        />
      ) : (
        <WorkflowDagNodeSchema
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          data={(inputSchema[0]?.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
        />
      )}
    </Vertical>
  );

  const renderOutputContent = (outputSchema: IOSchema) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={nodeId}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
        />
      ) : (
        <WorkflowDagNodeSchema
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          data={(outputSchema.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
        />
      )}
    </Vertical>
  );

  return (
    <Vertical h="100%">
      {renderSchemaContent(
        inputSchemas,
        renderInputContent,
        'Input',
        operatorCategory === OperatorCategory.Source,
      )}
      {renderSchemaContent(
        outputSchemas,
        renderOutputContent,
        'Output',
        operatorCategory === OperatorCategory.Sink,
      )}
    </Vertical>
  );
};
