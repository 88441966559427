import { createStyles } from '../../../../design-system/v2';

export const useActivityStyle = createStyles(theme => ({
  activityItem: {
    '&': {
      padding: '12px 16px',
    },
    '& + &': {
      borderTop: `1px solid ${theme.colors.white[1]}`,
    },
    ':hover': {
      backgroundColor: theme.colors.blue[0],

      a: {
        textDecoration: 'none',
        color: theme.colors.blue,
      },
    },
    a: {
      color: 'inherit',
    },
  },
}));
