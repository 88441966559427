import { IconHome } from '@tabler/icons-react';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { RightArrow } from '../../../../../design-system';
import { Button, Chip, Flex, useMarkovTheme } from '../../../../../design-system/v2';
import { DatasetChartSelectionStep, useSnippetDetail } from '../../context/SnippetContext';

export const SelectedDatasetsHeader = () => {
  const theme = useMarkovTheme();
  const { currSelectedDataset, selectCurrDataset } = useAddChartsContext();
  const { selectedDatasets, addChartStep, setAddChartStep } = useSnippetDetail();

  const handleHomeClick = () => {
    setAddChartStep(DatasetChartSelectionStep.DatasetSelection);
  };

  const handleSelectDatasets = () => {
    setAddChartStep(DatasetChartSelectionStep.AnalyserSelection);
  };

  return (
    <Flex
      mt="md"
      w="100%"
      justify={
        addChartStep === DatasetChartSelectionStep.DatasetSelection ? 'space-between' : 'flex-start'
      }
      wrap="wrap"
      align="center"
      gap={8}
    >
      <Button
        onClick={handleHomeClick}
        px={10}
        bg="white"
        sx={theme => ({ border: `1px solid ${theme.colors.gray[4]}`, borderRadius: '4px' })}
      >
        <IconHome color={theme.colors.gray[6]} size={18} />
      </Button>
      {addChartStep === DatasetChartSelectionStep.AnalyserSelection && (
        <Chip.Group value={currSelectedDataset} onChange={selectCurrDataset}>
          {selectedDatasets.map(datasetDetails => (
            <Chip
              value={datasetDetails.datasetId}
              key={datasetDetails.datasetId}
              variant="filled"
              radius="xs"
              size="md"
            >
              {datasetDetails?.name}
            </Chip>
          ))}
        </Chip.Group>
      )}
      {addChartStep === DatasetChartSelectionStep.DatasetSelection && selectedDatasets.length > 0 && (
        <Button
          variant="primary"
          color="green.6"
          onClick={handleSelectDatasets}
          shape="rounded"
          rightIcon={<RightArrow />}
        >
          Select {selectedDatasets.length} datasets
        </Button>
      )}
    </Flex>
  );
};
