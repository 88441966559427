import { HorizontalLayout, LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Box, Horizontal } from '../../../../../design-system/v2';

export const HorizontalLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  direction,
  label,
}: LayoutProps) => {
  if (!visible) {
    return null;
  }

  const horizontalLayout = uischema as HorizontalLayout;

  return (
    <Horizontal>
      {horizontalLayout.elements.map((element, idx) => (
        <Box key={`${path}_${idx}`} sx={{ flex: 1 }}>
          <JsonFormsDispatch
            uischema={element}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Box>
      ))}
    </Horizontal>
  );
};

export const HorizontalLayoutControl = withJsonFormsLayoutProps(HorizontalLayoutComponent);

export const horizontalControlTester: RankedTester = rankWith(1, uiTypeIs('HorizontalLayout'));
