import {
  Accordion as MantineAccordion,
  AccordionProps as MantineAccordionProps,
} from '@mantine/core';

type AccordionProps<T extends boolean = false> = MantineAccordionProps<T>;

export function Accordion<T extends boolean = false>({ ...props }: AccordionProps<T>) {
  return <MantineAccordion variant="default" chevronPosition="left" {...props} />;
}

Accordion.Item = MantineAccordion.Item;
Accordion.Control = MantineAccordion.Control;
Accordion.Panel = MantineAccordion.Panel;
