import { Badge, Text } from '../../../../design-system/v2';
import { WorkflowRunStatus } from '../../../../generated/api';

export interface WorkflowRunStatusBadgeProps {
  status: WorkflowRunStatus;
}

export const getRunStatusBadgeInfo = (status: WorkflowRunStatus) => {
  switch (status) {
    case WorkflowRunStatus.Success:
      return {
        text: 'Completed',
        color: 'green.7',
        bgColor: 'green.0',
      };
    case WorkflowRunStatus.CreationFailed:
    case WorkflowRunStatus.ExecutionFailed:
      return {
        text: 'Error Occurred',
        color: 'red.7',
        bgColor: 'red.0',
      };

    case WorkflowRunStatus.Running:
      return {
        text: 'Running',
        color: 'orange.7',
        bgColor: 'orange.0',
      };
    case WorkflowRunStatus.Scheduled:
      return {
        text: 'Scheduled',
        color: 'gray.7',
        bgColor: 'gray.0',
      };
    case WorkflowRunStatus.Terminated:
      return {
        text: 'Stopped',
        color: 'gray.7',
        bgColor: 'gray.0',
      };
    default:
      return {
        text: 'No Run',
        color: 'gray.6',
        bgColor: 'gray.0',
      };
  }
};

export const WorkflowRunStatusBadge = ({ status }: WorkflowRunStatusBadgeProps) => {
  const { bgColor, text, color } = getRunStatusBadgeInfo(status);
  return (
    <Badge variant="outline" bg={bgColor} color={color}>
      <Text variant="small03" tt="uppercase" color={color}>
        {text}
      </Text>
    </Badge>
  );
};
