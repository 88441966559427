import { Navigate, RouteProps, Routes } from 'react-router-dom';
import DataLabeling from '../pages/DataLabeling/DataLabeling';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';

const dataLabelingRoutes: RouteProps[] = [
  {
    path: '/',
    element: <DataLabeling />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const DataLabelingRouter = (): JSX.Element => (
  <Routes>{dataLabelingRoutes.map(renderAuthRoute)}</Routes>
);
