import { WorkflowRunContextProvider } from '../../../../contexts/workflows/WorkflowRunContext';
import { Box, Button, Center, Text, Vertical, openModal } from '../../../../design-system/v2';
import { OperatorModel, TemplateModel, UpsertDAGRequest } from '../../../../generated/api';
import { formatDateTime } from '../../../../lib/ui';
import { GRADIENT_BACKGROUND } from '../../../workspace/studio-home/workflow/WorkflowHomepage';
import { WorkflowViewer } from '../../detail/viewer/WorkflowViewer';

export const useTemplateFlowModal = () => {
  const open = (
    templateData: TemplateModel,
    operatorList: OperatorModel[],
    handleAddWorkflow?: (templateId?: string, workflowName?: string) => void,
  ) =>
    openModal({
      title: (
        <Vertical>
          <Text variant="subTitle01">{templateData.name}</Text>
          <Text variant="small01">{templateData.description}</Text>
        </Vertical>
      ),
      size: '60vw',
      styles: { header: { background: GRADIENT_BACKGROUND } },
      children: (
        <Vertical py="lg">
          <Box bg="gray.1" h={400}>
            <WorkflowRunContextProvider workflowId="">
              <WorkflowViewer
                dag={templateData.dag as UpsertDAGRequest}
                operatorsList={operatorList}
                renderNodeActions
              />
            </WorkflowRunContextProvider>
          </Box>
          <Center>
            {handleAddWorkflow && (
              <Button
                variant="primary"
                onClick={() => {
                  handleAddWorkflow(
                    templateData.templateId,
                    `${templateData.name}-${formatDateTime(new Date())}`,
                  );
                }}
              >
                Use this template
              </Button>
            )}
          </Center>
        </Vertical>
      ),
    });
  return { open };
};
