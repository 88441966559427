import { useFlags } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren } from 'react';
import { UnavailablePage } from '../Unavailable';

export const AppStoreFlagProvider = ({ children }: PropsWithChildren<any>) => {
  const { featureAppStore } = useFlags();
  if (!featureAppStore) {
    return <UnavailablePage />;
  }

  return <>{children}</>;
};
