import { PropsWithChildren, createContext, useContext, useState } from 'react';

interface ChartViewState {
  target?: number[];
  rotationX?: number;
  rotationOrbit?: number;
  latitude?: number;
  longitude?: number;
  minZoom?: number;
  maxZoom?: number;
  zoom?: number;
}

interface EmbeddingsViewState {
  viewState?: ChartViewState;
  setViewState: (v: EmbeddingsViewState['viewState']) => void;
}

const ViewStateContext = createContext<EmbeddingsViewState | undefined>(undefined);

export const useViewStateContext = () => {
  const context = useContext(ViewStateContext);
  if (!context) {
    throw new Error('useViewStateContext should only be used inside a ViewStateProvider');
  }
  return context;
};

export const ViewStateProvider = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const [viewState, setViewState] = useState<EmbeddingsViewState['viewState']>(undefined);

  return (
    <ViewStateContext.Provider value={{ viewState, setViewState }}>
      {children}
    </ViewStateContext.Provider>
  );
};
