import {
  Center,
  CloseButton,
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
} from '@mantine/core';

export const Drawer = (props: MantineDrawerProps) => (
  <MantineDrawer
    withCloseButton={false}
    title={
      <Center h="40px">
        <CloseButton onClick={props.onClose} />
      </Center>
    }
    {...props}
  />
);

Drawer.Root = MantineDrawer.Root;
Drawer.Overlay = MantineDrawer.Overlay;
Drawer.Content = MantineDrawer.Content;
Drawer.Header = MantineDrawer.Header;
Drawer.Body = MantineDrawer.Body;
Drawer.Title = MantineDrawer.Title;
Drawer.CloseButton = MantineDrawer.CloseButton;
