import { Breadcrumbs } from '@mantine/core';
import { IconArrowLeft, IconSearch } from '@tabler/icons-react';
import { useMemo } from 'react';
import {
  Box,
  Button,
  Horizontal,
  Skeleton,
  Text,
  TextInput,
  Vertical,
  useDebouncedValue,
  useInputState,
} from '../../../../../../../design-system/v2';
import { useGetCustomDictionaryWordsListQuery } from '../../../../../../../queries/document-ai/copyEdit';
import { AddWord } from './AddWord';
import { DictionaryListContainer } from './DictionaryList.container';

interface DictionaryListWrapperProps {
  dictionaryId: string;
  onParentBreadcrumbClick: () => void;
}

export const DictionaryListWrapper = ({
  dictionaryId,
  onParentBreadcrumbClick,
}: DictionaryListWrapperProps) => {
  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 300);
  const { isLoading, data } = useGetCustomDictionaryWordsListQuery(dictionaryId);

  const items = useMemo(
    () =>
      [
        { title: 'All Dictionaries', onClick: onParentBreadcrumbClick },
        { title: data?.dictionaryName ?? <Skeleton h={50} width={150} /> },
      ].map((item, i) => (
        <Button
          key={i}
          onClick={item.onClick}
          compact
          leftIcon={item.onClick ? <IconArrowLeft width={24} height={14} /> : ''}
        >
          {item.title}
        </Button>
      )),
    [data, onParentBreadcrumbClick],
  );

  return (
    <Box p="xl">
      <Horizontal noWrap align="center" spacing="xs" pb="lg">
        <Breadcrumbs>{items}</Breadcrumbs>
      </Horizontal>
      {isLoading ? (
        <Skeleton width={260} h={40} />
      ) : (
        <Text variant="heading03">{data?.dictionaryName}</Text>
      )}

      <Vertical mt="xl" spacing="xl">
        <Horizontal position="apart">
          <TextInput
            w={260}
            value={searchFilter}
            onChange={setSearchFilter}
            ariaLabel="Search words"
            placeholder="Search words..."
            icon={<IconSearch size={18} />}
          />
          <AddWord
            isFetchingWordList={isLoading}
            dictionaryId={dictionaryId}
            existingWordList={data?.customWords}
          />
        </Horizontal>

        <DictionaryListContainer
          searchFilter={debouncedSearchFilter.toLowerCase()}
          dictionaryId={dictionaryId}
        />
      </Vertical>
    </Box>
  );
};
