import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Box,
  Button,
  Horizontal,
  Image,
  Text,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { MarkovArtifacts } from '../../../../../generated/api';
import { S3_STORAGE_BASE } from '../../../../../lib/env.util';
import { useGetArtifactCountQuery } from '../../../../../queries/account/workspace';
import { useGetRecordingsList } from '../../../../../queries/recordings';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { TabStates, WorkspaceCard } from '../../../home/WorkspaceCard';
import { useDashboardSnapshotContext } from '../../../home/snapshot/DashboardSnapshotContext';
import { SnapshotLoading } from '../../../home/snapshot/SnapshotLoading';
import { EmptyEvaluationPreview } from '../../../home/snapshot/evaluations/EmptyEvaluationPreview';
import { ErrorEvaluationPreview } from '../../../home/snapshot/evaluations/ErrorEvaluationPreview';
import { EmptyPreviewHorizontal } from '../EmptyPreviewHorizontal';
import { EvaluationsPreview } from './EvaluationsPreview';

const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/illustrations/dashboard/evaluations-illustration.svg`;

const evaluationsTitle = 'Evaluate your model';
const evaluationsDescription =
  "Objectively assess your model's performance, compare multiple models effortlessly, and choose the best fit for your use case.";
const evaluationsIllustration = <Image maw={300} src={ILLUSTRATION_URL} />;

export const EvaluationsPreviewContainer = (): JSX.Element => {
  const theme = useMarkovTheme();
  const { userId } = useAppMetadata();

  const { selectedTab, handleTabChange } = useDashboardSnapshotContext();
  const navigate = useNavigate();
  const { getEvaluationDetailsRoute } = useAbsoluteRoutes();

  const { isLoading, isError, data } = useGetRecordingsList(
    selectedTab === TabStates.MINE ? userId : undefined,
  );
  const {
    isLoading: isLoadingCount,
    isError: isErrorCount,
    data: dataCount,
  } = useGetArtifactCountQuery(MarkovArtifacts.Evaluations);

  const handleNavigateEvaluation = (recordingId: string) => {
    const route = getEvaluationDetailsRoute(recordingId);
    navigate(route);
  };

  if (isLoading || isLoadingCount) {
    return (
      <WorkspaceCard
        title="Evaluations"
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        isSample
      >
        <SnapshotLoading />
      </WorkspaceCard>
    );
  }

  if (isError || isErrorCount) {
    return (
      <WorkspaceCard title="Evaluations" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <ErrorEvaluationPreview />
      </WorkspaceCard>
    );
  }

  if (!dataCount || !data || !data.length) {
    return (
      <WorkspaceCard title="Evaluations" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <EmptyEvaluationPreview />
      </WorkspaceCard>
    );
  }

  if (dataCount.demoArtifacts === dataCount.totalArtifacts) {
    return (
      <WorkspaceCard title="Evaluations" isSample>
        <EmptyPreviewHorizontal
          title={evaluationsTitle}
          description={evaluationsDescription}
          illustration={evaluationsIllustration}
        >
          <Horizontal py="48px">
            {data.map((evaluation, idx) => (
              <Button
                key={idx}
                variant="secondary"
                onClick={() => handleNavigateEvaluation(evaluation.evaluationId)}
                rightIcon={<IconArrowNarrowRight color={theme.colors.blue[6]} stroke={1.5} />}
              >
                <Text variant="subTitle03" color="blue.6">
                  Sample evaluation 0{idx + 1}
                </Text>
              </Button>
            ))}
          </Horizontal>
        </EmptyPreviewHorizontal>
      </WorkspaceCard>
    );
  }

  return (
    <WorkspaceCard title="Evaluations" selectedTab={selectedTab} onChangeTab={handleTabChange}>
      <Box>
        <EvaluationsPreview evaluations={data} />
      </Box>
    </WorkspaceCard>
  );
};
