import { Box, Text } from '../../../../design-system/v2';
import { UsageContainer } from './Usages.container';

export const UsagesWrapper = (): JSX.Element => (
  <Box p={16}>
    <Text variant="subTitle03" mb={14}>
      Current usage
    </Text>
    <UsageContainer />
  </Box>
);
