import { IFilterParams } from '@ag-grid-community/core';
import { Center } from '@mantine/core';
import { forwardRef } from 'react';
import { Alert, Loader, Text } from '../../../../../design-system/v2';
import { FilterWithButtons } from '../../../../../design-system/v2/core/data-display/table/filters/FilterWithButtons';
import { useDatafamilyListQuery } from '../../../../../queries/datasets/registration';

export const DataFamilyFilter = forwardRef((props: IFilterParams, ref) => {
  const { isLoading, isError, data } = useDatafamilyListQuery();

  if (isLoading) {
    return (
      <Center>
        <Loader />
        <Text>Loading Data Families</Text>
      </Center>
    );
  }

  if (isError) {
    return <Alert color="red">Issue fetching datafamily</Alert>;
  }

  if (!data) {
    return <Alert>No data family found</Alert>;
  }

  return (
    <FilterWithButtons
      {...props}
      options={data?.map(d => ({
        value: d.dataFamilyId,
        label: d.name,
      }))}
      ref={ref}
    />
  );
});

DataFamilyFilter.displayName = 'DataFamilyFilter';
