import { useQueryClient } from 'react-query';
import { openModal, Text } from '../../design-system/v2';
import { ResourceType, TagMetadata } from '../../generated/api';
import { AddEditTagsModalContent } from '../common/table/AddEditTagsModalContent';

export const useAddEditTags = (
  resourceId: string,
  resourceType: ResourceType,
  tags?: TagMetadata[],
  invalidationQueryKeys?: (readonly (string | object)[])[],
) => {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    if (invalidationQueryKeys?.length) {
      await Promise.all(
        invalidationQueryKeys?.map(querykey => queryClient.invalidateQueries(querykey)),
      );
    }
  };

  const openAddEditTagsModal = () =>
    openModal({
      size: 'lg',
      title: <Text variant="heading03">Edit tags</Text>,
      children: (
        <AddEditTagsModalContent
          resourceId={resourceId}
          resourceType={resourceType}
          tags={tags}
          onSuccess={onSuccess}
        />
      ),
    });

  return {
    onSuccess,
    openAddEditTagsModal,
  };
};
