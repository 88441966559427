import { IconDownload, IconInfoCircle } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FullPageLoader } from '../../../../../../../design-system';
import {
  Alert,
  Box,
  Button,
  FileWithPath,
  Flex,
  Text,
  Vertical,
  closeAllModals,
  openModal,
} from '../../../../../../../design-system/v2';
import { HTTPError } from '../../../../../../../lib/api/api';
import { useAddBulkRuleUploadMutation } from '../../../../../../../queries/document-ai/copyEdit';
import { FileUploadInput } from '../../../../../../common/file-uploader/FileUploadInput';
import { IllustrationCopyEditBulk } from './images';

interface AddBulkCustomRulesProps {
  ruleSetId: string;
}

const bulkRulesetTemplate = `${
  import.meta.env.VITE_APP_CDN_URL
}/copy-edit/sample/copyEdit_ruleset_template.csv`;

export const AddBulkCustomRulesModal = ({ ruleSetId }: AddBulkCustomRulesProps) => {
  const [file, setFile] = useState<FileWithPath>();
  // replace this with api
  const { isLoading, error, mutateAsync } = useAddBulkRuleUploadMutation(ruleSetId);

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleAddRules = () => {
    mutateAsync(file).then(() => {
      handleCloseModal();
    });
  };

  const handleFileDrop = (file: FileWithPath) => {
    setFile(file);
  };

  const handleFileDelete = () => {
    setFile(undefined);
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}

      <Vertical spacing="xl">
        {!isLoading && Boolean(error) && (
          <Alert icon={<IconInfoCircle size={14} />} title="Unable to add the rules">
            {(error as AxiosError<HTTPError>)?.response?.data.detail ?? 'Please try again'}
          </Alert>
        )}
        <FileUploadInput
          file={file}
          supportedFilesTypes={['CSV', 'Excel']}
          onFileDrop={handleFileDrop}
          onFileDelete={handleFileDelete}
        />

        <Text variant="small02" color="gray.7">
          You can use the provided template or create your own. Your file must contain these columns
          as shown in the example: <strong>Original text, Replace by text, Rule description</strong>
        </Text>

        <Text variant="small02" color="gray.7">
          Example style rules format:
        </Text>
        <Box w="100%" style={{ overflowX: 'auto' }}>
          <IllustrationCopyEditBulk />
        </Box>

        <a href={bulkRulesetTemplate} download="ruleset_template.csv">
          <Button variant="subtle" leftIcon={<IconDownload />}>
            Download rule set template
          </Button>
        </a>

        <Flex justify="right" gap="sm">
          <Button variant="outline" color="gray.6" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!file} onClick={handleAddRules}>
            Add Rules
          </Button>
        </Flex>
      </Vertical>
    </>
  );
};

export const AddBulkCustomRules = ({ ruleSetId }: { ruleSetId: string }) => {
  const handleAddNewRule = () => {
    openModal({
      size: 'lg',
      title: <Text variant="subTitle01">Import multiple rules</Text>,
      children: <AddBulkCustomRulesModal ruleSetId={ruleSetId} />,
    });
  };

  return (
    <Button onClick={handleAddNewRule} variant="outline">
      Import multiple rules
    </Button>
  );
};
