import { Layout } from 'react-grid-layout';
import { VisualizationType } from '../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { SelectedAnalysisModel } from '../../snippets/detail/context/SnippetContext';

export const MAX_COLS = 24;
export const ROW_HEIGHT = 24;

export const MIN_ROW_HEIGHT = ROW_HEIGHT * 30;
export const MAX_ROW_HEIGHT = ROW_HEIGHT * 70;
export const MARGIN = 12;

export const vizSizes = {
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
};

export const layoutStaticProps: Partial<Layout> = {
  static: false,
  isDraggable: true,
  isResizable: true,
  minW: vizSizes.xs,
  maxW: vizSizes.xl,
  minH: vizSizes.xs,
  maxH: vizSizes.xl,
};

export const getLayoutForItem = (id: string, x = 0, y = 0, w = vizSizes.xs, h = vizSizes.sm) => ({
  i: id,
  x,
  y,
  w,
  h,
});

const xsWidthViz = [VisualizationType.DATASET_ANALYSER];
const smWidthViz = [VisualizationType.DATASET_CLASS_DISTRIBUTION];
const mdWidthViz = [
  VisualizationType.DATASET_DATA_PROFILE_VARIABLE,
  VisualizationType.DATASET_CLASS_QUALITY_SCORE,
  VisualizationType.DATASET_BASELINE_MODEL_STATS,
  VisualizationType.DATASET_TOP_K_FEATURES,
];
const xlWidthViz = [
  VisualizationType.DATASET_DATA_PROFILE_SUMMARY,
  VisualizationType.DATASET_DATA_PROFILE_INSIGHTS,
  VisualizationType.DATASET_DATA_PROFILE_CORRELATION,
  VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE,
  VisualizationType.EXPERIMENT_CHART,
  VisualizationType.EVALUATION_ROC_PR_CURVE,
  VisualizationType.EVALUATION_CLASS_LEVEL_METRICS,
  VisualizationType.EVALUATION_CLASS_DISTRIBUTION,
  VisualizationType.EVALUATION_CUSTOM_METRICS,
  VisualizationType.EVALUATION_CONFUSION_MATRIX,
  VisualizationType.EVALUATION_MODEL_DISAGREEMENTS,
  VisualizationType.DATASET_OVERLAPPING_CLASS_METRICS,
];
const xsHeightViz = [VisualizationType.EVALUATION_CLASS_LEVEL_METRICS];
const mdHeightViz = [
  VisualizationType.EVALUATION_ROC_PR_CURVE,
  VisualizationType.EVALUATION_CONFUSION_MATRIX,
  VisualizationType.DATASET_DATA_PROFILE_CORRELATION,
  VisualizationType.DATASET_CLASS_QUALITY_SCORE,
  VisualizationType.DATASET_BASELINE_MODEL_STATS,
  VisualizationType.DATASET_TOP_K_FEATURES,
];

const getLayoutForGivenViz = (vizType: VisualizationType) => {
  let width = vizSizes.xs;
  let height = vizSizes.sm;

  if (xsWidthViz.includes(vizType)) {
    width = vizSizes.xs;
  } else if (smWidthViz.includes(vizType)) {
    width = vizSizes.sm;
  } else if (mdWidthViz.includes(vizType)) {
    width = vizSizes.md;
  } else if (xlWidthViz.includes(vizType)) {
    width = vizSizes.xl;
  }

  if (xsHeightViz.includes(vizType)) {
    height = vizSizes.xs;
  } else if (mdHeightViz.includes(vizType)) {
    height = vizSizes.md;
  }

  return {
    w: width,
    h: height,
  };
};

// TODO: Need to think of some better approach for charts layout
export const getLayoutForItems = (items: SelectedAnalysisModel[]) => {
  const sortedItems = items
    .map(item => ({
      ...getLayoutForItem(item.id),
      ...getLayoutForGivenViz(item.metadata.visualizationType),
    }))
    .sort((a, b) => a.w - b.w);

  const rowWiseLayout = [];
  const rowWiseHeight = [];

  for (let i = 0; i < sortedItems.length; i++) {
    if (sortedItems[i].w > MAX_COLS) continue;

    const rowLayout = [sortedItems[i]];
    let currRowWidth = sortedItems[i].w;
    let maxHeight = sortedItems[i].h;

    for (let j = sortedItems.length - 1; j > i; j--) {
      if (sortedItems[j].w + currRowWidth <= MAX_COLS) {
        rowLayout.push({ ...sortedItems[j] });
        currRowWidth += sortedItems[j].w;
        maxHeight = Math.max(maxHeight, sortedItems[j].h);
        sortedItems[j].w = Infinity;
      }
    }

    rowWiseLayout.push(rowLayout);
    rowWiseHeight.push(maxHeight);
  }

  const finalLayout = [];

  for (let i = 0; i < rowWiseLayout.length; i++) {
    let x = 0;

    for (let j = 0; j < rowWiseLayout[i].length; j++) {
      rowWiseLayout[i][j].h = rowWiseHeight[i];
      rowWiseLayout[i][j].x = x;
      rowWiseLayout[i][j].y = i === 0 ? 0 : rowWiseHeight[i - 1];

      x += rowWiseLayout[i][j].w;

      finalLayout.push({ ...rowWiseLayout[i][j] });
    }

    if (i > 0) {
      rowWiseHeight[i] += rowWiseHeight[i - 1];
    }
  }

  const itemsSequence = items.map(({ id }) => id);

  return finalLayout.sort((a, b) => itemsSequence.indexOf(a.i) - itemsSequence.indexOf(b.i));
};

export const getItemTopPositionInPixel = (layout: Layout) =>
  ROW_HEIGHT * layout.y + MARGIN * (layout.y - 1);
