import { PropsWithChildren } from 'react';
import { Card, CardProps } from '../../../design-system/v2';

interface ChartInfoProps extends CardProps {
  float?: boolean;
}

export const ChartInfo = ({
  float = true,
  children,
  ...cardProps
}: PropsWithChildren<ChartInfoProps>) => (
  <Card
    pos={float ? 'absolute' : 'inherit'}
    top={0}
    right={0}
    bg="white"
    withBorder
    shadow="md"
    p="sm"
    opacity="70%"
    sx={theme => ({
      userSelect: 'none',
      ...theme.fn.hover({
        opacity: 100,
      }),
    })}
    {...cardProps}
  >
    {children}
  </Card>
);
