import { Stack, StackProps } from '@mantine/core';
import { forwardRef } from 'react';

type VerticalProps = StackProps & React.RefAttributes<HTMLDivElement>;

export const Vertical = forwardRef<HTMLDivElement, VerticalProps>((props: VerticalProps, ref) => (
  <Stack ref={ref} {...props} />
));

Vertical.displayName = 'Vertical';
