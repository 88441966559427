import {
  BarChartIllustration,
  ClassDistributionIllustrationV2,
  TableIllustration,
} from '../../../../../../design-system';
import { VisualizationType } from '../../../../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { SegmentType } from '../../../../../../generated/api';
import { useAnalyserClassDistribution } from '../../../../../../queries/datasets/analysis/analysis';
import {
  useBaselineModelMetricsQuery,
  useClassDataQualityQuery,
  useOverlappingClassMetricsQuery,
  useTopKFeaturesQuery,
} from '../../../../../../queries/datasets/statistics/data-quality';
import { VIZ_NAMES, getChartsData } from '../util';

// TODO: Refactor these hooks
export const useGetBasicInfoAnalysers = (
  datasetId: string,
  selectedChartName: string,
  selectedSegment: SegmentType,
  isUnlabeled: boolean,
  dataQualityScore = 0,
) => {
  let basicInfoAnalysersCharts;

  // Class Distribution
  const { data: classDistributions, isLoading: classDistributionLoading } =
    useAnalyserClassDistribution(datasetId);
  const classDistributionType = Boolean(classDistributions?.response?.length)
    ? VIZ_NAMES.DATASET_CLASS_DISTRIBUTION
    : '';
  const classDistributionCharts = classDistributions?.response
    ?.map(({ metadata: mD }) =>
      getChartsData({
        visualizationType: VisualizationType.DATASET_CLASS_DISTRIBUTION,
        title: mD?.title ?? mD.metricName,
        subTitle: mD?.segmentType,
        chart: <ClassDistributionIllustrationV2 />,
        visualizationParameters: {
          datasetId: mD.datasetId,
          analysisId: mD.analysisId,
          segmentType: mD?.segmentType,
        },
      }),
    )
    .filter(({ subTitle: segment }) => segment === selectedSegment);

  if (selectedChartName === classDistributionType) {
    basicInfoAnalysersCharts = classDistributionCharts;
  }

  // Class Quality Score
  const { data: dataQualityScoreData, isLoading: dataQualityScoreLoading } =
    useClassDataQualityQuery(datasetId);

  const dataQualityScoreType =
    (dataQualityScoreData?.response?.length ?? 0) > 0 ? VIZ_NAMES.DATASET_CLASS_QUALITY_SCORE : '';
  const dataQualityScoreCharts = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_CLASS_QUALITY_SCORE,
      title: VIZ_NAMES.DATASET_CLASS_QUALITY_SCORE,
      chart: <TableIllustration />,
      visualizationParameters: { datasetId, dataQualityScore },
    }),
  ];

  if (selectedChartName === dataQualityScoreType) {
    basicInfoAnalysersCharts = dataQualityScoreCharts;
  }

  // Top K Features
  const { data: topKFeaturesData, isLoading: topKFeaturesLoading } =
    useTopKFeaturesQuery(datasetId);

  const topKFeaturesType =
    (topKFeaturesData?.response?.series?.length ?? 0) > 0 ? VIZ_NAMES.DATASET_TOP_K_FEATURES : '';
  const topKFeaturesCharts = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_TOP_K_FEATURES,
      title: VIZ_NAMES.DATASET_TOP_K_FEATURES,
      chart: <BarChartIllustration />,
      visualizationParameters: { datasetId },
    }),
  ];

  if (selectedChartName === topKFeaturesType) {
    basicInfoAnalysersCharts = topKFeaturesCharts;
  }

  // Baseline Model Stats
  const { data: baselineModelStatsData, isLoading: baselineModelStatsLoading } =
    useBaselineModelMetricsQuery(datasetId);

  // Don't show model stats chart for unlabeled dataset
  const baselineModelStatsType =
    !isUnlabeled && Boolean(baselineModelStatsData?.response)
      ? VIZ_NAMES.DATASET_BASELINE_MODEL_STATS
      : '';
  const baselineModelStatsCharts = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_BASELINE_MODEL_STATS,
      title: VIZ_NAMES.DATASET_BASELINE_MODEL_STATS,
      chart: <ClassDistributionIllustrationV2 />,
      visualizationParameters: { datasetId },
    }),
  ];

  if (selectedChartName === baselineModelStatsType) {
    basicInfoAnalysersCharts = baselineModelStatsCharts;
  }

  // Overlapping Class Metrics
  const { data: overlappingClassMetricsData, isLoading: overlappingClassMetricsLoading } =
    useOverlappingClassMetricsQuery(datasetId);

  const overlappingClassMetricsType =
    (overlappingClassMetricsData?.response?.length ?? 0) > 0
      ? VIZ_NAMES.DATASET_OVERLAPPING_CLASS_METRICS
      : '';
  const overlappingClassMetricsCharts = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_OVERLAPPING_CLASS_METRICS,
      title: VIZ_NAMES.DATASET_OVERLAPPING_CLASS_METRICS,
      chart: <TableIllustration />,
      visualizationParameters: { datasetId },
    }),
  ];

  if (selectedChartName === overlappingClassMetricsType) {
    basicInfoAnalysersCharts = overlappingClassMetricsCharts;
  }

  const basicInfoAnalysersTypes = [
    classDistributionType,
    dataQualityScoreType,
    topKFeaturesType,
    baselineModelStatsType,
    overlappingClassMetricsType,
  ].filter(i => !!i);

  return {
    basicInfoAnalysersTypes,
    basicInfoAnalysersCharts,
    isLoading:
      classDistributionLoading ||
      dataQualityScoreLoading ||
      topKFeaturesLoading ||
      baselineModelStatsLoading ||
      overlappingClassMetricsLoading,
  };
};
