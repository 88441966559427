import { Tabs } from '../../../design-system/v2';
import { useWorkspaceInviteQuery } from '../../../queries/account/invite';
import { NumberBadge } from '../../common/NumberBadge';
import { MemberTabs } from './Members';

interface InvitesTabProps {
  activeTab: string | null;
}

export const InvitesTab = ({ activeTab }: InvitesTabProps) => {
  const { data: inviteData } = useWorkspaceInviteQuery();

  return (
    <Tabs.Tab
      size="sm"
      value={MemberTabs.INVITES}
      rightSection={
        <NumberBadge active={activeTab === MemberTabs.INVITES} number={inviteData?.length ?? 0} />
      }
    >
      Invites
    </Tabs.Tab>
  );
};
