import { createStyles } from '../../../../../design-system/v2';

export const useSkeletonStyles = createStyles({
  root: {
    '::after': {
      background: 'linear-gradient(90deg, #FFDBEF 0%, #FFFFFF 119.41%)',
    },
  },
});

export const usePrismStyles = createStyles({
  prismCode: {
    '& .mantine-Prism-code': {
      backgroundColor: 'transparent !important',
    },
  },
});
