import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

import { FullPageLoader } from '../../../../../design-system';
import {
  ActionIcon,
  Center,
  Horizontal,
  Menu,
  notifications,
  openConfirmModal,
  Text,
} from '../../../../../design-system/v2';
import { DocumentResponse } from '../../../../../generated/api';
import { useDeleteDocumentMutation } from '../../../../../queries/document-ai/document';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { copyEditEvents } from '../../../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';

interface AppTableActionsProps {
  appId: string;
  projectId: string;
  documentId: string;
  data?: DocumentResponse;
}

export const AppTableActions = ({ appId, projectId, documentId, data }: AppTableActionsProps) => {
  const { mutate: deleteDocument, isLoading } = useDeleteDocumentMutation(
    appId,
    projectId,
    documentId,
  );
  const { workspaceId } = useAppMetadata();

  const handleConfirmDelete = () => {
    sendAnalytics(
      copyEditEvents.documents.deleted({
        workspaceId: workspaceId,
        projectId: projectId,
        documentId: documentId,
      }),
    );
    deleteDocument(undefined, {
      onSuccess: () => {
        notifications.success('Document Deleted Successfully!');
      },
      onError: () => {
        notifications.error('Not able to delete document');
      },
    });
  };

  const handleDeleteDocument = () => {
    openConfirmModal({
      title: <Text variant="heading03">Delete document?</Text>,
      children: (
        <Text variant="bodyLong03" mb="xl">
          Are you sure you want to delete <strong>{data?.documentName}</strong>? This action cannot
          be undone.
        </Text>
      ),
      onConfirm: handleConfirmDelete,
      onCancel: () => {
        sendAnalytics(
          copyEditEvents.documents.deleteCancel({
            workspaceId: workspaceId,
            projectId: projectId,
            documentId: documentId,
          }),
        );
      },
      labels: { confirm: 'Delete document', cancel: `No, don't delete` },
      confirmProps: { color: 'red' },
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}
      <Menu shadow="md" withinPortal position="bottom-end" offset={0}>
        <Menu.Target>
          <Center h="100%">
            <ActionIcon>
              <IconDotsVertical />
            </ActionIcon>
          </Center>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={handleDeleteDocument}>
            <Horizontal noWrap spacing="xs">
              <IconTrash size={14} />
              <Text variant="bodyShort03" align="center">
                Delete
              </Text>
            </Horizontal>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
