import { IconInfoCircle } from '@tabler/icons-react';
import { useState } from 'react';
import { ChartTypes } from '../../../../../charts';
import { ChartData } from '../../../../../charts/config/build';
import { ChartsProvider } from '../../../../../charts/context/Context';
import { Expand } from '../../../../../design-system';
import {
  ActionIcon,
  Box,
  Card,
  Horizontal,
  Modal,
  Text,
  Tooltip,
} from '../../../../../design-system/v2';
import { GenericChartDataModel } from '../../../../../generated/api';
import { VisualizationComponent } from '../../../../analyser/visualization/Visualization';

interface AnalyticsTabchartProps {
  data: GenericChartDataModel;
  title: string;
  info: string;
}

export const AnalyticsTabChart = ({ data, title, info }: AnalyticsTabchartProps): JSX.Element => {
  const [expanded, showExpanded] = useState(false);

  const handleExpandViz = () => {
    showExpanded(true);
  };

  const handleCarouselClose = () => {
    showExpanded(false);
  };

  const header = (
    <VisualizationComponent.Header
      actions={
        <Horizontal>
          <ActionIcon color="gray.6" onClick={handleExpandViz}>
            <Expand />
          </ActionIcon>
        </Horizontal>
      }
    >
      <Horizontal spacing="xs">
        <VisualizationComponent.Header.Title
          title={title}
          titleProps={{ variant: 'subTitle02', color: 'gray.8' }}
        />
        <Tooltip label={info}>
          <IconInfoCircle size={14} />
        </Tooltip>
      </Horizontal>
    </VisualizationComponent.Header>
  );

  const content = (
    <VisualizationComponent.Chart
      type={ChartTypes.LINE}
      enableLegends
      data={data as ChartData}
      colors={['#1665E4', '#52B75E', '#CED4DA', '#DB4659', '#954CD5']}
      colorByPoint={false}
      dataLabelStyle={{
        color: '#fff',
        fontWeight: '600',
        fontSize: '14px',
      }}
    />
  );

  const vizContent = (
    <Card w="100%" withBorder hoverEffect={false}>
      <VisualizationComponent
        cardProps={{
          shadow: '',
          sx: { height: '100%' },
        }}
      >
        {expanded ? null : header}
        {content}
      </VisualizationComponent>
    </Card>
  );
  return (
    <Box w="100%">
      <ChartsProvider>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleCarouselClose}
        title={<Text variant="subTitle02">{title}</Text>}
        size="90vw"
        centered
      >
        {vizContent}
      </Modal>
    </Box>
  );
};
