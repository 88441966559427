import ReactFlow, { Background, Position, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import 'reactflow/dist/style.css';

export type { Edge, Node } from 'reactflow';

const Flow = {
  Container: ReactFlow,
  Background,
  Position,
};

const utils = {
  applyEdgeChanges,
  applyNodeChanges,
};

export { Flow, utils as flowUtils };
