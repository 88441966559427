import { useMemo } from 'react';
import { Checkbox, TextInput, useInputState } from '../../../inputs';
import { Vertical } from '../../../layout';
import { ScrollArea } from '../../../misc';
import { Menu } from '../../../overlays';

interface ItemProps {
  label: string;
  value: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (key: string, checked: boolean) => void;
}

const Item = ({ value, label, checked, indeterminate, onChange }: ItemProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value, e.target.checked);
  };

  return (
    <Menu.Item key={value} value={value} px={0}>
      <Checkbox
        label={label}
        checked={checked}
        indeterminate={indeterminate}
        onChange={handleOnChange}
      />
    </Menu.Item>
  );
};

const SELECT_ALL = 'SELECT_ALL';

interface SetFilterProps {
  options: { value: string; label: string; checked: boolean }[];
  onSelect: (index: number, selected: boolean) => void;
  onSelectAll: (key: string, checked: boolean) => void;
}

export const SetFilter = ({ options, onSelect, onSelectAll }: SetFilterProps) => {
  const [searchText, setSearchText] = useInputState('');

  const filteredOptions = useMemo(
    () => options.filter(option => option.label.toLowerCase().includes(searchText)),
    [searchText, options],
  );

  const handleSelectAll = (val: string, selected: boolean) => {
    onSelectAll(val, selected);
  };

  const handleOnChange = (value: string, selected: boolean) => {
    onSelect(
      options.findIndex(opt => opt.value === value),
      selected,
    );
  };

  const areAllSelected = options.every(val => val.checked);
  const areSomeSelected = options.some(val => val.checked);

  return (
    <Vertical p="md">
      <TextInput
        ariaLabel="set search"
        value={searchText}
        onChange={setSearchText}
        placeholder="Enter to search here..."
      />

      <ScrollArea.Autosize mah={240}>
        <Menu>
          <Item
            value={SELECT_ALL}
            label="Select All"
            checked={areAllSelected}
            indeterminate={!areAllSelected && areSomeSelected}
            onChange={handleSelectAll}
          />
          {filteredOptions.map(opt => (
            <Item
              key={opt.value}
              value={opt.value}
              label={opt.label}
              checked={opt.checked}
              onChange={handleOnChange}
            />
          ))}
        </Menu>
      </ScrollArea.Autosize>
    </Vertical>
  );
};
