import { useMemo } from 'react';
import { Center, Image, Loader } from '../../../../../../design-system/v2';
import { useGetCustomDictionaryListQuery } from '../../../../../../queries/document-ai/copyEdit';
import { EmptyState } from '../../../../../common/EmptyState';
import { ErrorState } from '../../../../../common/ErrorState';
import { DictionarySetList } from './DictionarySetList';

interface DictionarySetContainerProps {
  searchFilter: string;
  onRowClicked: (dictionaryId: string, dictionaryName: string) => void;
}

export const DictionarySetContainer = ({
  searchFilter,
  onRowClicked,
}: DictionarySetContainerProps): JSX.Element => {
  const { isLoading, isError, data = [] } = useGetCustomDictionaryListQuery();

  const filteredData = useMemo(
    () =>
      data.filter(
        ({ dictionaryName, dictionaryDescription = '' }) =>
          dictionaryName.toLowerCase().includes(searchFilter.toLowerCase()) ||
          dictionaryDescription.toLowerCase().includes(searchFilter.toLowerCase()),
      ),
    [data, searchFilter],
  );

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading dictionaries..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={400}>
        <ErrorState title="Error fetching dictionary list" />
      </Center>
    );
  }

  if (!data.length) {
    return (
      <Center h={400}>
        <EmptyState
          title="No custom dictionaries added yet"
          subtitle="Add custom dictionaries to define preferred spellings and avoid misspellings."
          icon={
            <Image src="https://ik.imagekit.io/markovml/app-store/copy-edit/no-dictionaries_p4Mgkix-v.svg" />
          }
          iconWrapperSize={120}
        />
      </Center>
    );
  }

  return <DictionarySetList dictionarySet={filteredData} onRowClicked={onRowClicked} />;
};
