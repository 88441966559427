import { Text } from '../../typography';

export interface PaginationLabelProps {
  /**
   * Label displayed before the range of rows.
   * @default "Total Rows"
   */
  labelRowsPerPage?: string;

  /**
   * The current page number. 1 indexed
   */
  currentPage: number;

  /**
   * The total number of rows available.
   */
  totalRows: number;

  /**
   * The number of rows displayed per page.
   */
  rowsPerPage: number;
}

export const PaginationLabel = ({
  labelRowsPerPage = 'Total Rows',
  currentPage,
  totalRows,
  rowsPerPage,
}: PaginationLabelProps) => {
  const startIndex = ((currentPage ?? 1) - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRows);

  if (!totalRows) {
    return null;
  }

  return (
    <Text variant="small03" color="gray.6">
      {labelRowsPerPage} {startIndex} - {endIndex} of {totalRows}
    </Text>
  );
};
