import { MantineSize } from '@mantine/styles';
import { IconDotsVertical } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { ActionIcon, Menu } from '../../design-system/v2';
import { StyledClickableWrapper } from '../datasets-table/DatasetsTable.style';

interface TableAction {
  id: string;
  label: ReactNode;
  handler: () => void;
}

interface TableActionMenuProps {
  actions: TableAction[];
  width?: MantineSize | number;
}

export const TableActionMenu = ({ actions, width }: TableActionMenuProps) => (
  <StyledClickableWrapper
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Menu shadow="md" position="bottom-end" width={width ?? 110} withinPortal>
      <Menu.Target>
        <ActionIcon bg="white.0">
          <IconDotsVertical size={20} color="#777" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(action => (
          <Menu.Item key={action.id} py={12} onClick={action.handler}>
            {action.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  </StyledClickableWrapper>
);
