import isEmpty from 'lodash/isEmpty';

export const experimentKeys = {
  all: ['experiment'] as const,
  lists: () => [...experimentKeys.all, 'lists'] as const,
  list: (workspaceId: string, userId = '', filters = {}) =>
    [
      ...experimentKeys.lists(),
      'default',
      workspaceId,
      userId,
      ...(!isEmpty(filters) ? [{ filters }] : []),
    ] as const,
  listPreview: (workspaceId: string, userId = '') =>
    [...experimentKeys.lists(), 'preview', workspaceId, userId] as const,
  listProjectExperiments: (workspaceId: string, userId: string, projectId: string, filters = {}) =>
    [
      ...experimentKeys.lists(),
      'project-experiments',
      workspaceId,
      userId,
      projectId,
      ...(!isEmpty(filters) ? [{ filters }] : []),
    ] as const,
  listMetrics: (workspaceId: string, userId = '', experimentId: string) =>
    [...experimentKeys.all, 'list-metrics', workspaceId, userId, experimentId] as const,
  listPackageRequirements: (
    workspaceId: string,
    userId = '',
    projectId: string,
    experimentId: string,
  ) =>
    [
      ...experimentKeys.all,
      'list-package-reqs',
      workspaceId,
      userId,
      projectId,
      experimentId,
    ] as const,
  detail: (workspaceId: string, userId = '', projectId: string, experimentId: string) =>
    [...experimentKeys.all, 'detail', workspaceId, userId, projectId, experimentId] as const,
  fullDetail: (workspaceId: string, experimentId: string) =>
    [...experimentKeys.all, 'full-detail', workspaceId, experimentId] as const,
  metricDetail: (workspaceId: string, userId = '', experimentId: string, metricKey = '') =>
    [...experimentKeys.all, 'metric-data', workspaceId, userId, experimentId, metricKey] as const,
};

export const experimentComparisonKeys = {
  all: ['experiment-comparison'] as const,
  canCompare: (workspaceId: string, experimentIds: string[]) =>
    [...experimentComparisonKeys.all, 'can-compare', workspaceId, { experimentIds }] as const,
  experimentSummary: (workspaceId: string, experimentIds: string[]) =>
    [
      ...experimentComparisonKeys.all,
      'experiment-summary',
      workspaceId,
      { experimentIds },
    ] as const,
  experimentsKeys: (workspaceId: string, experimentIds: string[]) =>
    [...experimentComparisonKeys.all, 'experiments-keys', workspaceId, { experimentIds }] as const,
  keyMetricChartData: (workspaceId: string, metricKey: string, experimentIds: string[]) =>
    [
      ...experimentComparisonKeys.all,
      'experiments-keys',
      workspaceId,
      metricKey,
      { experimentIds },
    ] as const,
};
