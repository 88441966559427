import { ChartTypes } from '../../../../charts';
import { ChartInfo } from '../../../../charts/components/chart-info/ChartInfo';
import { PRCurveTooltip } from '../../../../charts/components/tooltip/line/pr-curve';
import { ROCCurveTooltip } from '../../../../charts/components/tooltip/line/roc-curve';
import { COMPARISON_COLORS } from '../../../../charts/config/colors';
import { ChartsProvider } from '../../../../charts/context/Context';
import { Expand } from '../../../../design-system';
import {
  ActionIcon,
  Box,
  Center,
  Divider,
  Flex,
  Horizontal,
  Loader,
  Modal,
  Text,
  Vertical,
} from '../../../../design-system/v2';
import { useDisclosure } from '../../../../design-system/v2/hooks';
import {
  ChartValues,
  EvaluationsPRCurveChartMetadata,
  EvaluationsRocCurveChartMetadata,
  GenericChartDataModel,
  GenericChartSeriesDataModel,
} from '../../../../generated/api';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../../analyser/visualization/actions/DownloadVisualization';
import { InfoMessage } from '../../../common/InfoMessage';
import { InfoTooltip } from '../../../common/InfoTooltip';

export enum EvaluationVizType {
  ROC = 'ROC',
  PR = 'PR',
}

interface ROCAndPRCurveProps {
  chartData?: GenericChartDataModel;
  metadata?: Partial<EvaluationsRocCurveChartMetadata & EvaluationsPRCurveChartMetadata>;
  isLoading: boolean;
  isError: boolean;
  chartType: EvaluationVizType;
  showBorders?: boolean;
  expandable?: boolean;
}

interface CustomChartInfoProps {
  text: string;
  listValues: (string | number)[];
  colors: string[];
  tooltipInfo?: string;
}

const CHART_DETAILS = {
  [EvaluationVizType.ROC]: {
    title: 'ROC Curve',
    yLabel: 'True Positive Rate (for 1)',
    xLabel: 'False Positive Rate',
    customToolTip: ROCCurveTooltip,
    inferencesTooltipText: 'Area under the curve',
  },
  [EvaluationVizType.PR]: {
    title: 'PR Curve',
    yLabel: 'Precision',
    xLabel: 'Recall',
    customToolTip: PRCurveTooltip,
    inferencesTooltipText: 'Best F1 score is calculated at the optimal threshold',
  },
};

const CustomChartInfo = ({ text, listValues, colors, tooltipInfo }: CustomChartInfoProps) => (
  <ChartInfo float={false} w="fit-content" mb={4} mx="auto" opacity="100%" px="md">
    <Box>
      <Center>
        <Text align="center" variant="small01" color="gray.7" mb={4}>
          {text}
        </Text>
        {tooltipInfo && <InfoTooltip text={tooltipInfo} maw={250} />}
      </Center>
      <Flex gap={12} direction="row" justify="space-around" wrap="wrap">
        {listValues.map((val, idx) => (
          <Horizontal key={val} align="center" position="left" spacing="xs">
            <Box bg={colors[idx]} w={12} h={3} />
            <Text variant="small01" color="gray.7">
              {val}
            </Text>
          </Horizontal>
        ))}
      </Flex>
    </Box>
  </ChartInfo>
);

export const ROCAndPRCurve = ({
  chartData,
  metadata,
  isLoading,
  isError,
  chartType,
  showBorders = true,
  expandable = true,
}: ROCAndPRCurveProps) => {
  const [expanded, handlers] = useDisclosure();

  const handleCarouselOpen = () => handlers.open();
  const handleCarouselClose = () => handlers.close();

  const { customToolTip, title, inferencesTooltipText } = CHART_DETAILS[chartType];

  const { xLabel, yLabel, bestThresholds = [], aucScores = [], bestF1Scores = [] } = metadata ?? {};

  const parsedSeriesData = chartData?.series?.map((s, index) => ({
    ...s,
    data: s?.data?.map(({ x, y, value }: GenericChartSeriesDataModel) => ({
      x,
      y,
      total: value,
      // This to mark the optimal value of threshold in the curve
      marker: {
        enabled: bestThresholds[index] === value,
        radius: 4,
      },
    })),
  })) as ChartValues[];

  const parsedChartData = {
    series: parsedSeriesData,
  };

  const header = (
    <VisualizationComponent.Header
      actions={
        <Horizontal>
          <DownloadVisualization fileName="ROC_And_PR_Curve" isDownloadDisabled={isLoading} />
          {expandable && (
            <ActionIcon color="gray.6" onClick={handleCarouselOpen} disabled={isLoading}>
              <Expand />
            </ActionIcon>
          )}
        </Horizontal>
      }
    >
      <VisualizationComponent.Header.Title
        title={title}
        titleProps={{ variant: 'subTitle02', color: 'gray.8' }}
      />
    </VisualizationComponent.Header>
  );

  let content = null;
  if (isLoading) {
    content = (
      <Center h={360}>
        <Loader />
      </Center>
    );
  } else if (isError) {
    content = <InfoMessage height="360px" text="Curve not available!" />;
  } else if (!!chartData) {
    content = (
      <Box pt="sm" pr="lg">
        {chartType === EvaluationVizType.PR && parsedSeriesData.length === 1 ? (
          <ChartInfo float={false} mb={4} w="fit-content" mx="auto" opacity="100%">
            <Center>
              <Box bg={COMPARISON_COLORS[0]} w={8} h={8} sx={{ borderRadius: '100%' }} />
              <Vertical spacing={0} pl="sm">
                <Text variant="small01" color="gray.7">
                  Optimal threshold: {bestThresholds[0]}
                </Text>
                <Text variant="small01" color="gray.7">
                  Best F1 Score: {bestF1Scores[0]}
                </Text>
              </Vertical>
            </Center>
          </ChartInfo>
        ) : (
          <CustomChartInfo
            text={chartType === EvaluationVizType.ROC ? 'AUC' : 'Best F1 Scores'}
            listValues={chartType === EvaluationVizType.ROC ? aucScores : bestF1Scores}
            colors={COMPARISON_COLORS}
            tooltipInfo={inferencesTooltipText}
          />
        )}
        <VisualizationComponent.Chart
          type={ChartTypes.LINE}
          data={parsedChartData}
          xAxisOptions={{
            title: xLabel,
            max: 1.01,
            tickPositions: [0, 0.25, 0.5, 0.75, 1],
            endOnTick: false,
          }}
          yAxisOptions={{
            title: yLabel,
            max: 1.01,
            gridLine: true,
            tickPositions: [0, 0.25, 0.5, 0.75, 1],
            endOnTick: false,
          }}
          customTooltip={customToolTip}
          colors={COMPARISON_COLORS}
          enableLegends={parsedSeriesData.length > 1}
        />
      </Box>
    );
  }

  const vizContent = (
    <VisualizationComponent cardProps={{ shadow: '', withBorder: !expanded && showBorders }}>
      {!expanded && header}
      {expanded && <Divider />}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleCarouselClose}
        size="80vw"
        centered
        title={<Text variant="subTitle02">{title}</Text>}
        dataTestId="roc-pr-curve"
      >
        {vizContent}
      </Modal>
    </>
  );
};
