import { ColDef, ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { Box, Text, Tooltip, Vertical } from '../../../../../../design-system/v2';
import { Table } from '../../../../../../design-system/v2/core/data-display/table';
import {
  CreateOrUpdateCustomDictionaryRequest,
  GetCustomDictionaryResponse,
} from '../../../../../../generated/api';
import { DictionarySetAction } from './DictionarySetAction';

const columns: ColDef<GetCustomDictionaryResponse>[] = [
  {
    colId: 'dictionaryName',
    field: 'dictionaryName',
    headerName: 'Dictionary name',
    sortable: false,
    cellRenderer: ({ value: dictionaryName }: ICellRendererParams<GetCustomDictionaryResponse>) => (
      <Vertical h="100%" justify="center">
        <Text truncate variant="bodyShort02">
          {dictionaryName}
        </Text>
      </Vertical>
    ),
    minWidth: 300,
    flex: 1,
  },
  {
    colId: 'dictionaryDescription',
    field: 'dictionaryDescription',
    headerName: 'Description',
    sortable: false,
    cellRenderer: ({ value: description }: ICellRendererParams<GetCustomDictionaryResponse>) => (
      <Vertical h="100%" justify="center">
        <Tooltip label={description} maw={400} withinPortal multiline>
          <Text truncate variant="bodyShort03">
            {description}
          </Text>
        </Tooltip>
      </Vertical>
    ),
    minWidth: 400,
    flex: 1,
  },
  {
    colId: 'wordCount',
    field: 'wordCount',
    headerName: 'Count',
    sortable: false,
    cellRenderer: ({ value: count }: ICellRendererParams<GetCustomDictionaryResponse>) => (
      <Vertical h="100%" justify="center">
        <Text variant="bodyShort02">{count}</Text>
      </Vertical>
    ),
    minWidth: 100,
    flex: 1,
  },
  {
    colId: 'dictionaryId',
    field: 'dictionaryId',
    headerName: ' ',
    sortable: false,
    suppressMenu: false,
    cellRenderer: ({
      value: dictionaryId,
      data,
    }: ICellRendererParams<GetCustomDictionaryResponse>) => {
      const initialData: CreateOrUpdateCustomDictionaryRequest = {
        dictionaryId: data?.dictionaryId,
        dictionaryName: data?.dictionaryName,
        dictionaryDescription: data?.dictionaryDescription,
      };
      return (
        <Box h="100%" onClick={(e: React.MouseEvent) => e.preventDefault()}>
          <DictionarySetAction dictionaryId={dictionaryId} data={initialData} />
        </Box>
      );
    },
    width: 100,
  },
];

interface DictionarySetListProps {
  dictionarySet: GetCustomDictionaryResponse[];
  onRowClicked?: (dictionaryId: string, dictionaryName: string) => void;
}

const ROW_HEIGHT = 55;

export const DictionarySetList = ({ dictionarySet, onRowClicked }: DictionarySetListProps) => {
  const handleRowClicked = ({ data, event }: RowClickedEvent<GetCustomDictionaryResponse>) => {
    if (event?.defaultPrevented) {
      return null;
    }

    if (onRowClicked && data?.dictionaryId) {
      onRowClicked(data.dictionaryId, data?.dictionaryName);
    }
  };

  return (
    <Box mah={700} h={(dictionarySet.length + 1) * ROW_HEIGHT} w="100%">
      <Table
        columns={columns}
        getRowClass={() => 'row-pointer'}
        rowData={dictionarySet}
        onRowClicked={handleRowClicked}
      />
    </Box>
  );
};
