/**
 * Utils related to environment
 */

/**
 * Checks wether app is running in development
 * @returns boolean. True if development env is used
 */
export const isDevelopment = () => import.meta.env.VITE_APP_ENV === 'development';

export const isLocal = () => import.meta.env.VITE_APP_ENV === 'local';

export const isStaging = () => import.meta.env.VITE_APP_ENV === 'staging';

export const isProduction = () => import.meta.env.VITE_APP_ENV === 'production';

// TODO: Read this from env
export const S3_STORAGE_BASE = 'https://markovml-web-assets.s3.us-west-2.amazonaws.com';

export const getAPIBaseUrl = () => import.meta.env.VITE_APP_API_BASE;

export const getCollaborationBaseUrl = () => {
  const wsUrl = getAPIBaseUrl()?.replace('http', 'ws');

  return `${wsUrl}/collaboration`;
};
