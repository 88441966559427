import React, { RefObject, Suspense } from 'react';
import { Loader } from '../../feedback';
import { Center } from '../../layout';
import { Box } from '../../misc';
import { ActionsHeader } from './ActionsHeader';
import { TableProps, TypedTable } from './Table';
import { AgGridReact } from './ag-grid';

const LazyTable = React.lazy(() => import('./Table')) as TypedTable;

const getRowClass = () => 'row-pointer';

export interface ListProps<TData> extends Omit<TableProps<TData>, 'ref'> {
  leftActionsContent?: JSX.Element;
  totalRows?: number;
  currentPage?: number;
  gridRef?: RefObject<AgGridReact>;
  onPageNumberChange?: (pageNumber: number) => void;
}

export const List = <T,>({
  leftActionsContent,
  totalRows,
  currentPage,
  gridRef,
  onPageNumberChange,
  ...restProps
}: ListProps<T>) => {
  const showHeader = Boolean(leftActionsContent || totalRows || currentPage || onPageNumberChange);
  const tableHeight = showHeader ? 'calc(100% - 60px)' : '100%';

  return (
    <Box h="100%">
      {showHeader && (
        <ActionsHeader
          leftActionContent={leftActionsContent}
          totalRows={totalRows}
          currentPage={currentPage}
          onPageNumberChange={onPageNumberChange}
        />
      )}
      <Box h={tableHeight}>
        <Suspense
          fallback={
            <Center h="100%" w="100%">
              <Loader />
            </Center>
          }
        >
          <LazyTable<T> ref={gridRef} getRowClass={getRowClass} {...restProps} />
        </Suspense>
      </Box>
    </Box>
  );
};
