import { PropsWithChildren } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Box from '../../../Box';
import { Draggable } from '../Draggable';

interface SortableListProps {
  droppableId: string;
  onDragEnd: (from: number, to: number) => void;
}

export const SortableList = ({
  children,
  droppableId,
  onDragEnd,
}: PropsWithChildren<SortableListProps>) => (
  <DragDropContext
    onDragEnd={({ destination, source }) => onDragEnd(source.index, destination?.index || 0)}
  >
    <Droppable droppableId={droppableId} direction="vertical">
      {provided => (
        <Box {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  </DragDropContext>
);

SortableList.Draggable = Draggable;
