import { ampli } from '../../../ampli';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  Button,
  Horizontal,
  ScrollArea,
  Text,
  closeAllModals,
  notifications,
  openModal,
} from '../../../design-system/v2';
import { AnalysisStateNames } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { getIsTaskDisabled } from '../../../lib/analysis';
import {
  useTaskletsWithStatusQuery,
  useTriggerAnalysisMutation,
} from '../../../queries/datasets/analysis/analysis';
import { GroupedAnalyzerSelectionContainer } from '../../common/grouped-analyzers/GroupedAnalyzerSelection.container';
import { useAnalyzerSelection } from '../../common/grouped-analyzers/util';

const SCROLL_AREA_MAX_HEIGHT = 400;

interface RunAnalyzersModalProps {
  datasetId: string;
}

const RunAnalyzersModal = ({ datasetId }: RunAnalyzersModalProps) => {
  const { workspaceId } = useAppMetadata();
  const { mutateAsync: triggerAnalysis, isLoading } = useTriggerAnalysisMutation(datasetId);
  const { selectedTaskletIds, getIsSelected, selectTasklet } = useAnalyzerSelection();
  const taskletsWithStatusQuery = useTaskletsWithStatusQuery(datasetId);

  const tasklets = taskletsWithStatusQuery.data ?? [];

  const getIsDisabled = (taskletId: string) => {
    const tasklet = tasklets.find(t => t.taskletId === taskletId);
    if (!tasklet) {
      return false;
    }
    // There is some discrepency between how we handle retrying tasks for regular and for
    // full analysis. For regular analysis, in case of error users should re-trigger instead.
    return tasklet.status === AnalysisStateNames.Error || getIsTaskDisabled(tasklet.status);
  };

  const getDisabledLabel = (taskletId: string) => {
    const existingTask = tasklets?.find(task => task.taskletId === taskletId);
    return existingTask?.status === AnalysisStateNames.Completed
      ? 'Completed'
      : existingTask?.status === AnalysisStateNames.Error
      ? 'Error encountered'
      : 'Currently running';
  };

  const onCancel = () => {
    closeAllModals();
  };

  const onConfirm = async () => {
    ampli.datasetAnalysisTriggerAnalyzers({
      workspaceId,
      datasetId,
      numAnalyzers: selectedTaskletIds.length,
    });

    try {
      await triggerAnalysis(selectedTaskletIds);
      notifications.show({
        variant: 'success',
        message: 'Analysis triggered successfully',
      });
      closeAllModals();
    } catch (error) {
      notifications.show({
        variant: 'error',
        message: 'Unable to trigger analysis',
      });
      logger.error(error);
    }
  };

  return (
    <>
      <ScrollArea.Autosize mah={SCROLL_AREA_MAX_HEIGHT}>
        <GroupedAnalyzerSelectionContainer
          datasetId={datasetId}
          getIsChecked={getIsSelected}
          onClickCheckbox={selectTasklet}
          getIsDisabled={getIsDisabled}
          getDisabledLabel={getDisabledLabel}
        />
      </ScrollArea.Autosize>
      <Horizontal position="right" mt="sm">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={!selectedTaskletIds.length}
          loading={isLoading}
        >
          Start
        </Button>
      </Horizontal>
    </>
  );
};

export const useAnalyzersModal = (datasetId: string) => {
  const open = () =>
    openModal({
      title: (
        <Text component="div" variant="heading03">
          Run Analysis
        </Text>
      ),
      size: 'xl',
      shadow: 'xl',
      children: <RunAnalyzersModal datasetId={datasetId} />,
    });

  return {
    open,
  };
};
