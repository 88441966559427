import {
  Layout,
  LayoutProps,
  RankedTester,
  Scoped,
  UISchemaElement,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';
import { CSSObject } from '@mantine/styles';
import { Grid, ScrollArea, createStyles } from '../../../../../design-system/v2';

// ScrollArea root needs static (not relative) positioning in order to position the table view in
// ResourceLookupWithListComponent relative to the top-level form modal. The styles are not getting
// applied correctly though, and TypeScript complains when using !important (doesn't recognize as
// valid position value), so forcing type assertion as a workaround for now.
const useStyles = createStyles(theme => ({
  root: {
    position: 'static!important',
  } as unknown as CSSObject,
}));

interface GridLayout extends Layout {
  type: 'GridLayout';
  elements: (UISchemaElement & Scoped)[];
}

export const GridLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  data,
  cells,
  direction,
  label,
}: LayoutProps) => {
  const ctx = useJsonForms();
  const { classes } = useStyles();

  if (!visible) {
    return null;
  }

  const gridLayout = uischema as GridLayout;

  return (
    <Grid columns={12}>
      {gridLayout.elements.map((element, idx) => (
        <Grid.Col key={`${path}_${idx}`} span={element.options?.width} sx={{ flex: 1 }}>
          <ScrollArea.Autosize
            h="100%"
            mah="69vh"
            offsetScrollbars
            classNames={{ root: classes.root }}
          >
            <JsonFormsDispatch
              uischema={element}
              schema={schema}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          </ScrollArea.Autosize>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export const GridLayoutControl = withJsonFormsLayoutProps(GridLayoutComponent);

export const gridControlTester: RankedTester = rankWith(1, uiTypeIs('GridLayout'));
