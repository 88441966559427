import { EmptyState } from '../components/common/EmptyState';
import { Center, LinkButton } from '../design-system/v2';
import { AppRoutes, getRoute } from '../router/constants';

const accessDeniedMessage =
  "Oops! The page may have been moved, deleted, or you don't have permission to view it. Please check the URL or contact the workspace owner for assistance.";

export const AccessDeniedPage = () => (
  <Center h="100%">
    <EmptyState
      iconName="404"
      iconSize="lg"
      title="Something went wrong"
      subtitle={accessDeniedMessage}
      button={
        <LinkButton to={getRoute(AppRoutes.BASE_ROUTE)} variant="primary">
          Go to Home page
        </LinkButton>
      }
    />
  </Center>
);
