import { IconEdit } from '@tabler/icons-react';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { DataTable, Delete, FullPageLoader } from '../../../design-system';
import { ActionIcon, Box, Horizontal, Text } from '../../../design-system/v2';
import { WorkspaceMember, WorkspaceMembershipRoles } from '../../../generated/api';
import { capitalize } from '../../../lib/ui';
import { UserDetail } from '../UserDetail';
import { useDeleteMemberFromWorkspace } from './DeleteMember';
import { useUpdateMembershipInWorkspace } from './UpdateMembershipModal';

interface MembersTableProps {
  members: WorkspaceMember[];
}

export interface WorkspaceMemberDetail {
  userId: string;
  avatar: string;
  name: string;
  email: string;
  role: string;
  status: string;
}

const commonTableColumnData = [
  {
    Header: 'Workspace Members',
    accessor: 'name',
    width: 200,
    Cell: ({ value, row }: CellProps<WorkspaceMemberDetail>) => (
      <UserDetail avatar={row.original.avatar} name={value} email={row.original.email} />
    ),
  },
  {
    Header: 'Role',
    accessor: 'role',
    width: 100,
    Cell: ({ value }: { value: string }) => <Text variant="bodyShort03">{capitalize(value)}</Text>,
  },
];
const getMemberTableColumns = () => commonTableColumnData;

const getAdminTableColumns = (
  onDelete: (member: WorkspaceMemberDetail) => void,
  onUpdate: (member: WorkspaceMemberDetail) => void,
) => {
  const { userId } = useAppMetadata();
  return [
    ...commonTableColumnData,
    {
      Header: 'Actions',
      accessor: '',
      width: 80,
      disableFilters: true,
      Cell: ({ row }: CellProps<WorkspaceMemberDetail>) =>
        /**
         * If the user is an owner, we don't want to allow them to get deleted. So not showing delete icon for them
         */
        row.original.userId === userId ? (
          <></>
        ) : (
          <Horizontal>
            <ActionIcon
              variant="transparent"
              size="sm"
              color="gray.6"
              onClick={() => onUpdate(row.original)}
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              size="sm"
              color="gray.6"
              onClick={() => onDelete(row.original)}
            >
              <Delete />
            </ActionIcon>
          </Horizontal>
        ),
    },
  ];
};

export const MembersTable = ({ members }: MembersTableProps): JSX.Element => {
  const workspaceMembers = members.map(member => ({
    userId: member.userDetails?.userId ?? '',
    avatar: member.userDetails?.avatar ?? '',
    name: member.userDetails?.name ?? '',
    email: member.userDetails?.email ?? '',
    role: member.workspaceMembershipRole,
    status: member.workspaceMembershipState,
  }));

  const { userId } = useAppMetadata();

  const { isLoading: isRemovingMemberFromWorkspace, openDeleteModal } =
    useDeleteMemberFromWorkspace();

  const { openUpdateMemberShipModal } = useUpdateMembershipInWorkspace();

  const columns = useMemo(() => {
    const isCurrentUserAdmin =
      members.find(member => member.userId === userId)?.workspaceMembershipRole ===
      WorkspaceMembershipRoles.Owner;
    return isCurrentUserAdmin
      ? getAdminTableColumns(openDeleteModal, openUpdateMemberShipModal)
      : getMemberTableColumns();
  }, [userId, members]);

  return (
    <>
      {isRemovingMemberFromWorkspace && <FullPageLoader text="Removing the member..." />}
      <Box
        h="calc(100vh - 320px)"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <DataTable
          dataTestId="table-workspace_members_list"
          data={workspaceMembers}
          columns={columns}
          height="100%"
        />
      </Box>
    </>
  );
};
