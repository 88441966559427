import { MantineNumberSize, Variants } from '@mantine/core';

type MantineButtonVariant = Variants<
  'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient'
>;

export type ButtonVariant = 'primary' | 'secondary' | 'light' | 'outline' | 'subtle';

interface Properties {
  mantineVariant: MantineButtonVariant;
}

export const variantProperties: { [key in ButtonVariant]: Properties } = {
  primary: {
    mantineVariant: 'filled',
  },
  secondary: {
    mantineVariant: 'default',
  },
  light: {
    mantineVariant: 'light',
  },
  outline: {
    mantineVariant: 'outline',
  },
  subtle: {
    mantineVariant: 'subtle',
  },
};

export type ButtonType = 'sharp' | 'rounded';

export const typeRadius: { [key in ButtonType]: MantineNumberSize } = {
  sharp: 'sm',
  rounded: 'xl',
};
