import { Checkbox as MantineCheckbox, CheckboxProps as MantineCheckboxProps } from '@mantine/core';

interface CheckboxProps extends MantineCheckboxProps {
  dataTestId?: string;
}

export const Checkbox = ({ dataTestId, ...rest }: CheckboxProps) => (
  <MantineCheckbox data-testid={dataTestId} {...rest} />
);

Checkbox.Group = MantineCheckbox.Group;
Checkbox.displayName = 'Checkbox';
