import css from '@styled-system/css';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import Box from '../Box';

interface VariantProp {
  variant?: 'secondary' | 'tertiary';
}

interface LinkChildContainerProps extends VariantProp {
  backgroundColor?: string;
}

const StyledLink = styled.a(
  css({
    outline: 'none',
    textDecoration: 'none',
  }),
);

const StyledLinkChildContainer = styled(Box)<LinkChildContainerProps>(
  props =>
    css({
      borderRadius: '4px',
      py: '14px',
      display: 'inline-block',
      bg: props.backgroundColor ?? 'initial',
    }),
  props =>
    variant({
      variants: {
        secondary: {
          border: 'thickSolid',
          borderColor: 'muted.shade1',
          bg: props.backgroundColor ?? 'background.white',
        },
        tertiary: {
          border: 'none',
        },
      },
    }),
);

interface LinkButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement>, VariantProp {
  dataTestId?: string;
  backgroundColor?: string;
}

export const LinkButton = ({
  variant = 'secondary',
  dataTestId,
  backgroundColor,
  children,
  ...rest
}: PropsWithChildren<LinkButtonProps>): JSX.Element => (
  <StyledLink {...rest} data-testid={dataTestId}>
    <StyledLinkChildContainer variant={variant} backgroundColor={backgroundColor}>
      {children}
    </StyledLinkChildContainer>
  </StyledLink>
);
