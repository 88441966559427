import styled, { css } from 'styled-components';
import { Table, TableCell, TableHeader } from '../..';
import { Box } from '../../../';

export const StyledTableWrapper = styled(Box)`
  overflow: auto;
  max-height: ${props => props.maxHeight};
`;

export const StyledTable = styled(Table)`
  display: table;
  width: 100%;
  min-width: 400px;
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.33333;
`;

const TableCellBaseStyles = css`
  height: 56px;
  min-width: 100px;
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.muted.shade1};
  color: ${props => props.theme.colors.text.shade3};
`;

// Value of 'double' needed for border of 'selected' cells to override default cell border
// see discussion: https://stackoverflow.com/questions/4096390/table-border-color-in-css-with-border-collapse
export const StyledTableHeader = styled(TableHeader)`
  ${TableCellBaseStyles}

  ${({ theme, backgroundColor }) =>
    backgroundColor &&
    `
    background: ${backgroundColor};
    color: ${theme.colors.text.shade1};
  `};
  ${({ borderColor }) =>
    borderColor &&
    `
    border: 1px double;
    border-color: ${borderColor};
  `};
`;

// Value of 'double' needed for border of 'selected' cells to override default cell border
// see discussion: https://stackoverflow.com/questions/4096390/table-border-color-in-css-with-border-collapse
export const StyledTableCell = styled(TableCell)`
  ${TableCellBaseStyles}
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 16px;

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background: ${backgroundColor};
    `};
  ${({ borderColor }) =>
    borderColor &&
    `
    border: 1px double;
    border-color: ${borderColor};
   `}
`;
