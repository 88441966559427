import { ColDef } from '../../../design-system/v2/core/data-display/table/ag-grid';
import { ArtifactStateType, Workflow } from '../../../generated/api';
import { formatDateTime } from '../../../lib/ui';
import { OwnerRenderer } from '../../common/table/cell-renderers/OwnerRenderer';
import { TextRenderer } from './cell-renderers/TextRenderer';
import { WorkflowActionsRenderer } from './cell-renderers/WorkflowActionsRenderer';
import { WorkflowDescriptionRenderer } from './cell-renderers/WorkflowDescriptionRenderer';
import { WorkflowLastRunStatusRenderer } from './cell-renderers/WorkflowLastRunStatusRenderer';
import { WorkflowNameRenderer } from './cell-renderers/WorkflowNameRenderer';
import { WorkflowRunTypeRenderer } from './cell-renderers/WorkflowRunTypeRenderer';

export const getAllWorkflowColumns = (state: ArtifactStateType): ColDef<Workflow>[] => [
  {
    colId: 'select',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    width: 48,
  },
  {
    colId: 'scheduleStatus',
    field: 'scheduleStatus',
    headerName: 'Run Type',
    sortable: false,
    width: 140,
    cellRenderer: WorkflowRunTypeRenderer,
  },
  {
    colId: 'name',
    field: 'name',
    headerName: state === ArtifactStateType.Draft ? 'Draft Name' : 'Workflow Name',
    flex: 1,
    minWidth: 180,
    cellRenderer: WorkflowNameRenderer,
  },
  {
    colId: 'description',
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 280,
    cellRenderer: WorkflowDescriptionRenderer,
  },
  {
    colId: 'lastRun',
    headerName: 'Last Run',
    sortable: true,
    width: 180,
    valueGetter: ({ data }) => {
      const startDate = data?.lastRunDetails?.startDate;
      if (startDate) {
        return formatDateTime(startDate);
      }
      return '--';
    },
    cellRenderer: TextRenderer,
  },
  {
    colId: 'lastRunDetails',
    field: 'lastRunDetails',
    headerName: state === ArtifactStateType.Draft ? 'Debug Status' : 'Last Run Status',
    sortable: false,
    width: 180,
    cellRenderer: WorkflowLastRunStatusRenderer,
  },
  {
    colId: 'updateDate',
    field: 'updateDate',
    headerName: 'Updated on',
    width: 180,
    valueGetter: ({ data }) => {
      const updatedDate = data?.updateDate;
      if (updatedDate) {
        return formatDateTime(updatedDate);
      }
      return '--';
    },
    cellRenderer: TextRenderer,
  },
  {
    colId: 'createdBy',
    headerName: 'Owner',
    field: 'createdBy',
    width: 180,
    sortable: false,
    cellRenderer: OwnerRenderer,
  },
  {
    colId: 'actions',
    sortable: false,
    filter: false,
    resizable: false,
    cellRenderer: WorkflowActionsRenderer,
    cellRendererParams: { state },
    width: 64,
  },
];

export const getWorkflowTableColumns = (state: ArtifactStateType) =>
  getAllWorkflowColumns(state).filter(col => {
    if (state === ArtifactStateType.Active) {
      return col.colId !== 'updateDate';
    }
    if (state === ArtifactStateType.Draft) {
      return col.colId !== 'scheduleStatus' && col.colId !== 'lastRun';
    } else if (state === ArtifactStateType.Archived) {
      return col.colId !== 'scheduleStatus' && col.colId !== 'updateDate';
    }
    return false;
  });
