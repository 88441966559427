import { useMemo } from 'react';
import { Center, Image, Loader } from '../../../../../../design-system/v2';
import { useFetchRulesSetListQuery } from '../../../../../../queries/document-ai/copyEdit';
import { EmptyState } from '../../../../../common/EmptyState';
import { ErrorState } from '../../../../../common/ErrorState';
import { RulesSetList } from './RulesSetList';

interface RulesSetListContainerProps {
  searchFilter: string;
  onRowClicked: (ruleSetId: string, ruleName: string) => void;
}

export const RulesSetListContainer = ({
  searchFilter,
  onRowClicked,
}: RulesSetListContainerProps) => {
  const { data = [], isLoading, isError } = useFetchRulesSetListQuery();

  const filteredData = useMemo(
    () =>
      data.filter(
        ({ ruleSetName, ruleSetDescription = '' }) =>
          ruleSetName.toLowerCase().includes(searchFilter.toLowerCase()) ||
          ruleSetDescription.toLowerCase().includes(searchFilter.toLowerCase()),
      ),
    [data, searchFilter],
  );

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading Rules Set..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={400}>
        <ErrorState title="Error fetching rules set" />
      </Center>
    );
  }

  if (!data.length) {
    return (
      <Center h={400}>
        <EmptyState
          title="No rule sets added yet"
          subtitle="Add custom rules to replace words and phrases to ensure consistency in your writing."
          icon={
            <Image src="https://ik.imagekit.io/markovml/app-store/copy-edit/no-rulesets_GgaPHWJFn.svg" />
          }
          iconWrapperSize={120}
        />
      </Center>
    );
  }

  return <RulesSetList rulesSet={filteredData} onRowClicked={onRowClicked} />;
};
