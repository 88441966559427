import { useMemo } from 'react';
import { useInfiniteGetWorkspaceActivitiesQuery } from '../../../../queries/account/activity';
import { ErrorBar } from '../../../common/ErrorBar';
import { InfoMessage } from '../../../common/InfoMessage';
import { Activities } from './Activities';
import { ActivityLoading } from './ActivityLoading';

export const ActivitiesContainer = () => {
  const {
    data: activities,
    isError: activitiesError,
    isLoading: activitiesLoading,
    isFetching: activitiesFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetWorkspaceActivitiesQuery();

  const allActivities = useMemo(() => activities?.pages.flatMap(page => page) || [], [activities]);

  if (activitiesLoading) {
    return <ActivityLoading />;
  }

  if (activitiesError) {
    return <ErrorBar errorMessage="Error loading activities" />;
  }

  if (!allActivities || !allActivities.length) {
    return <InfoMessage text="No activities to show!" />;
  }

  return (
    <Activities
      activities={allActivities}
      isFetching={activitiesFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage ?? false}
    />
  );
};
