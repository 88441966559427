import { FullPageLoader, Loader } from '../../../design-system';
import { notifications } from '../../../design-system/v2';
import {
  useWorkspaceInviteQuery,
  useWorkspaceInviteResendMutation,
  useWorkspaceInviteRevokeMutation,
} from '../../../queries/account/invite';
import { PageEmptyState } from '../../common/EmptyState';
import { PageErrorScreen } from '../../common/ErrorState';
import { InvitationTable } from './InvitationTable';

export const InvitationContainer = (): JSX.Element => {
  const { isLoading, error, data } = useWorkspaceInviteQuery();
  const {
    isLoading: isResendingInvite,
    mutate: resendInvite,
    isSuccess: isResendSuccess,
    error: resendMessageError,
    reset: resendMessageReset,
  } = useWorkspaceInviteResendMutation();
  const {
    isLoading: isRevokingInvite,
    mutate: revokeInvite,
    isSuccess: isRevokeSuccess,
    error: revokeMessageError,
    reset: revokeMessageReset,
  } = useWorkspaceInviteRevokeMutation();

  if (isLoading) {
    return <Loader text="Loading invite list" />;
  }

  if (error || !data) {
    return <PageErrorScreen title="" subtitle="Unable to load invite list" />;
  }

  if (!data.length) {
    return (
      <PageEmptyState
        iconName="member"
        iconSize="lg"
        title="No pending invitations for this workspace"
        subtitle="Click on the Invite member button to invite someone"
      />
    );
  }

  if (isResendSuccess) {
    notifications.show({
      variant: 'success',
      message: 'Invite resent successfully.',
    });
    resendMessageReset();
  }

  if (isRevokeSuccess) {
    notifications.show({
      variant: 'success',
      message: 'Invite revoked successfully.',
    });
    revokeMessageReset();
  }

  if (resendMessageError) {
    notifications.show({
      variant: 'error',
      message: resendMessageError.response?.data.detail ?? 'Unable to resend invite',
    });
    resendMessageReset();
  }

  if (revokeMessageError) {
    notifications.show({
      variant: 'error',
      message: revokeMessageError.response?.data.detail ?? 'Unable to revoke invite',
    });
    revokeMessageReset();
  }

  return (
    <>
      {isResendingInvite && <FullPageLoader text="Resending selected invite..." />}
      {isRevokingInvite && <FullPageLoader text="Revoking selected invite..." />}
      <InvitationTable invitations={data ?? []} onResend={resendInvite} onRevoke={revokeInvite} />
    </>
  );
};
