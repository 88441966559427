import { Alert, Center, IconAlertCircle, Loader } from '../../../../design-system/v2';
import { logger } from '../../../../initializers/logging';
import { useUserInfo } from '../../../../queries/user';
import { DashboardWidgets } from './DashboardWidgets';

export const DashboardWidgetsContainer = () => {
  const { isLoading, isFetching, error, data } = useUserInfo();

  if (isLoading || isFetching) {
    return (
      <Center h="400px">
        <Loader size="lg" color="yellow.6" text="Loading dashboard..." />
      </Center>
    );
  }

  if (!data?.user || error) {
    logger.error(error);
    return (
      <Center>
        <Alert icon={<IconAlertCircle size={20} />} color="red">
          Unable to load data! Try refreshing the page.
        </Alert>
      </Center>
    );
  }

  const userDetails = data.user;

  return <DashboardWidgets userDetails={userDetails} />;
};
