import { PropsWithChildren } from 'react';
import { TableCellProps as RTTableCellProps } from 'react-table';

interface TableCellProps extends RTTableCellProps {
  backgroundColor?: string;
  borderColor?: string;
}

export const TableCell = ({ children, ...rest }: PropsWithChildren<TableCellProps>) => (
  <td {...rest}>{children}</td>
);
