import { PropsWithChildren } from 'react';
import { Popover, Text } from '../../../../design-system/v2';

export const MoreInfoPopover = ({ children }: PropsWithChildren) => {
  if (!children) return null;
  return (
    <Popover
      width={400}
      trapFocus
      position="bottom-start"
      shadow="xl"
      closeOnEscape={false}
      closeOnClickOutside={true}
    >
      <Popover.Target>
        <Text variant="small02" color="gray.6">
          More Info
        </Text>
      </Popover.Target>
      <Popover.Dropdown>{children}</Popover.Dropdown>
    </Popover>
  );
};
