import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

export type BoxProps = SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps &
  FlexboxProps &
  TextAlignProps &
  PositionProps &
  BackgroundProps;

const styleProps = compose(space, layout, color, border, flexbox, textAlign, position, background);

const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  styleProps,
);

export default Box;
