import { PropsWithChildren } from 'react';
import { WorkspaceMembershipRoles } from '../../generated/api';
import { useWorkspaceList } from '../../queries/userHome';
import { useAppMetadata } from '../app-metadata/AppMetadata';

interface WorkspaceRBACProps {
  fallback?: React.ReactNode;
  roles: WorkspaceMembershipRoles[];
}

/**
 * Currently this checks if the current logged-in user has the roles supplied in the props.
 * If yes, it renders the children, else it does not.
 */
export const WorkspaceRBAC = ({
  children,
  fallback,
  roles,
}: PropsWithChildren<WorkspaceRBACProps>) => {
  const { workspaceId } = useAppMetadata();
  const { data } = useWorkspaceList();

  // When data is not available, it will return undefined and RBAC would return empty.
  const currentWorkspace = data?.find(workspace => workspace.workspaceId === workspaceId);
  const currentWorkspaceRole = currentWorkspace?.membershipDetails?.workspaceMembershipRole;

  if (roles.find(role => role === currentWorkspaceRole)) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return <></>;
};
