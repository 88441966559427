import first from 'lodash/first';
import { useCreateWorkflow } from '../../../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../../../contexts/workflows/WorkflowRunContext';
import { Alert, Horizontal, Text, notifications, openModal } from '../../../../../design-system/v2';
import { OperatorModel } from '../../../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../../../queries/workflows/dag';
import { transformEdgeToWorkflowEdge } from '../../../edges/util';
import { WorkflowNode, transformNodeToWorkflowNode } from '../../../nodes/utils';
import { findSourceNodes } from '../../utils';
import { NodeFormView } from './NodeFormView';
import { OperatorExampleModal } from './OperatorExampleModal';
import { FormDataTypes } from './util';

export const useFormModal = (
  id: string,
  operatorModel?: OperatorModel,
  configuration?: Record<string, FormDataTypes>,
) => {
  const { workflowId, nodes, setNodes, edges } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const handleFormSubmit = async (formState: Record<string, FormDataTypes>) => {
    const newNodes = nodes.map<WorkflowNode>(node => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            configuration: formState ?? {},
          },
        };
      }
      return node;
    });

    setNodes(newNodes);
    notifications.success('Changes saved successfully');
  };

  const prevNodeId = first(findSourceNodes({ nodes, edges }, id));
  const isExampleAvailable = !!operatorModel?.example;

  return {
    open: () =>
      openModal({
        title: (
          <Horizontal spacing={0} px="24px" pt="lg" pb="sm">
            <Text variant="heading04">{operatorModel?.name}</Text>
            {isExampleAvailable && <OperatorExampleModal operator={operatorModel} />}
          </Horizontal>
        ),
        size: '1480px',
        padding: 0,
        withinPortal: false,
        closeButtonProps: {
          mr: '24px',
        },
        children: operatorModel ? (
          <NodeFormView
            workflowId={workflowId}
            runId={runId}
            nodeId={id}
            operatorModel={operatorModel}
            initialState={configuration}
            onFormSubmit={handleFormSubmit}
            prevNodeId={prevNodeId}
          />
        ) : (
          <Alert>Could not find operator</Alert>
        ),
      }),
  };
};

export const useReadOnlyForm = (
  id: string,
  workflowId: string,
  operatorModel?: OperatorModel,
  configuration?: Record<string, FormDataTypes>,
) => {
  const { data } = useGetWorkflowDAGQuery(workflowId);

  return {
    open: () =>
      openModal({
        title: (
          <Text variant="heading04" px="24px" pt="lg" pb="xs">
            {operatorModel?.name}
          </Text>
        ),
        size: '1480px',
        padding: 0,
        closeButtonProps: {
          mr: '24px',
        },
        children:
          operatorModel && data ? (
            <NodeFormView
              workflowId={workflowId}
              nodeId={id}
              operatorModel={operatorModel}
              initialState={configuration}
              viewOnly
              prevNodeId={first(
                findSourceNodes(
                  {
                    nodes: data.nodes.map(n => transformNodeToWorkflowNode(n, [operatorModel])),
                    edges: data.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
                  },
                  id,
                ),
              )}
            />
          ) : (
            <Alert>Could not find operator</Alert>
          ),
      }),
  };
};
