import { Modal as MantineModal, ModalProps as MantineModalProps } from '@mantine/core';

interface ModalProps extends MantineModalProps {
  dataTestId?: string;
}

export const Modal = ({ children, dataTestId, ...rest }: ModalProps): JSX.Element => (
  <MantineModal padding={24} data-testid={dataTestId} {...rest}>
    {children}
  </MantineModal>
);
