import React, { RefObject, Suspense } from 'react';
import { Loader } from '../../feedback';
import { Center } from '../../layout';
import { Box } from '../../misc';
import { DEFAULT_OVERLAY_Z_INDEX } from '../../overlays';
import { LoadingOverlay } from '../../overlays/loading-overlay';
import { ActionsHeader } from './ActionsHeader';
import { TableProps, TableThemes, TypedTable } from './Table';
import { AgGridReact } from './ag-grid';

const LazyTable = React.lazy(() => import('./Table')) as TypedTable;

const getRowClass = () => 'row-pointer';

interface PaginationProps {
  totalRows: number;
  currentPage: number;
  onPageNumberChange: (page: number) => void;
  labelRowsPerPage: string;
  showPaginationLabel: boolean;
}

export interface ListV2Props<TData> extends Omit<TableProps<TData>, 'ref'> {
  paginationProps?: PaginationProps;
  leftActionsContent?: JSX.Element;
  gridRef?: RefObject<AgGridReact>;
  isLoading?: boolean;
}

export const ListV2 = <T,>({
  leftActionsContent,
  gridRef,
  isLoading = false,
  paginationProps,
  ...restProps
}: ListV2Props<T>) => {
  const showHeader = Boolean(leftActionsContent || paginationProps);
  const tableHeight = showHeader ? 'calc(100% - 60px)' : '100%';

  return (
    <Box h="100%">
      {showHeader && (
        <ActionsHeader
          leftActionContent={leftActionsContent}
          {...(paginationProps ?? {})}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        />
      )}
      <Box h={tableHeight} pos="relative">
        <LoadingOverlay zIndex={DEFAULT_OVERLAY_Z_INDEX - 1} visible={isLoading} />
        <Suspense
          fallback={
            <Center h="100%" w="100%">
              <Loader />
            </Center>
          }
        >
          <LazyTable<T>
            ref={gridRef}
            getRowClass={getRowClass}
            theme={TableThemes.THEME_V2}
            {...restProps}
          />
        </Suspense>
      </Box>
    </Box>
  );
};
