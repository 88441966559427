import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Box,
  Button,
  Center,
  Horizontal,
  Image,
  Text,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { MarkovArtifacts } from '../../../../../generated/api';
import { S3_STORAGE_BASE } from '../../../../../lib/env.util';
import { useGetArtifactCountQuery } from '../../../../../queries/account/workspace';
import { useExperimentsListSnapshot } from '../../../../../queries/experiments/experiments';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { ErrorState } from '../../../../common/ErrorState';
import { TabStates, WorkspaceCard } from '../../../home/WorkspaceCard';
import { useDashboardSnapshotContext } from '../../../home/snapshot/DashboardSnapshotContext';
import { SnapshotLoading } from '../../../home/snapshot/SnapshotLoading';
import { EmptyExperimentsPreview } from '../../../home/snapshot/experiments/EmptyExperimentsPreview';
import { EmptyPreviewHorizontal } from '../EmptyPreviewHorizontal';
import { ExperimentsPreview } from './ExperimentsPreview';

const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/illustrations/dashboard/experiment-illustration.svg`;

const experimentsTitle = 'Track model training';
const experimentsDescription =
  "Get insights into your model's training. Record and analyze hyper-parameters to optimize model development.";
const experimentsIllustration = <Image maw={200} src={ILLUSTRATION_URL} />;

export const ExperimentsPreviewContainer = () => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { getExperimentDetailsRoute } = useAbsoluteRoutes();
  const { userId } = useAppMetadata();

  const { selectedTab, handleTabChange } = useDashboardSnapshotContext();

  const { isError, isLoading, data } = useExperimentsListSnapshot(
    selectedTab === TabStates.MINE ? userId : undefined,
  );
  const {
    isLoading: isLoadingCount,
    isError: isErrorCount,
    data: dataCount,
  } = useGetArtifactCountQuery(MarkovArtifacts.Experiments);

  const handleNavigateExperiment = (projectId: string, recordId: string) => {
    const route = getExperimentDetailsRoute(projectId, recordId);
    navigate(route);
  };

  if (isLoading || isLoadingCount) {
    return (
      <WorkspaceCard
        title="Experiments"
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        isSample
      >
        <SnapshotLoading compact />
      </WorkspaceCard>
    );
  }

  if (isError || isErrorCount) {
    return (
      <WorkspaceCard title="Experiments" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <Center h={280} bg="white.0">
          <ErrorState
            compact
            iconName="experiment"
            title="Error loading experiments"
            subtitle="Please try refreshing the page or contact support"
          />
        </Center>
      </WorkspaceCard>
    );
  }

  if (!dataCount || !data || !data.length) {
    return (
      <WorkspaceCard title="Experiments" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <EmptyExperimentsPreview />
      </WorkspaceCard>
    );
  }

  if (dataCount.demoArtifacts === dataCount.totalArtifacts) {
    return (
      <WorkspaceCard title="Experiments" isSample>
        <EmptyPreviewHorizontal
          title={experimentsTitle}
          description={experimentsDescription}
          illustration={experimentsIllustration}
        >
          <Horizontal py="48px">
            {data.map((experiment, idx) => (
              <Button
                key={idx}
                variant="secondary"
                onClick={() =>
                  handleNavigateExperiment(experiment.projectId, experiment.experimentId ?? '')
                }
                rightIcon={<IconArrowNarrowRight color={theme.colors.blue[6]} stroke={1.5} />}
              >
                <Text variant="subTitle03" color="blue.6">
                  Sample experiment 0{idx + 1}
                </Text>
              </Button>
            ))}
          </Horizontal>
        </EmptyPreviewHorizontal>
      </WorkspaceCard>
    );
  }

  return (
    <WorkspaceCard title="Experiments" selectedTab={selectedTab} onChangeTab={handleTabChange}>
      <Box>
        <ExperimentsPreview experiments={data} />
      </Box>
    </WorkspaceCard>
  );
};
