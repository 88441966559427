import { Center } from '../../../../../design-system/v2';
import { ErrorState } from '../../../../common/ErrorState';

export const ErrorEvaluationPreview = (): JSX.Element => (
  <Center h={280}>
    <ErrorState
      compact
      iconName="evaluation"
      title="Error in loading model evaluations"
      subtitle="Please refresh the page and try again after some time"
    />
  </Center>
);
