import { PointOptionsObject, TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface WordCloudTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const WordCloudTooltip = ({ formatterContext }: WordCloudTooltipProps) => (
  <TooltipContainer>
    <TooltipHeader>{formatterContext.point.name}</TooltipHeader>
    <TooltipItemRow>{(formatterContext.point as PointOptionsObject).weight}</TooltipItemRow>
  </TooltipContainer>
);
