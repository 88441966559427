import { MantineThemeOverride } from '@mantine/core';
import { headingTheme } from '../core/typography/headings/Headings.theme';
import { breakpoints } from './breakpoints';
import { darks, grays, whites } from './colors/colorList';
import { colors } from './colors/colors';
import { components } from './components';
import { BASE_PX_VALUE } from './constants';
import { fontFamily, lineHeight } from './font';
import { shadows } from './shadows';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',

  fontFamily: fontFamily,
  lineHeight: lineHeight,
  fontSizes: {
    xxs: `${12 / BASE_PX_VALUE}rem`,
    xs: `${13 / BASE_PX_VALUE}rem`,
    sm: `${14 / BASE_PX_VALUE}rem`,
    md: `${15 / BASE_PX_VALUE}rem`,
    lg: `${17 / BASE_PX_VALUE}rem`,
    xl: `${19 / BASE_PX_VALUE}rem`,
  },

  colors: colors,
  white: whites.white0,
  black: grays.gray9,
  primaryColor: 'blue',
  primaryShade: 6,

  spacing: {
    xs: `${4 / BASE_PX_VALUE}rem`,
    sm: `${8 / BASE_PX_VALUE}rem`,
    md: `${12 / BASE_PX_VALUE}rem`,
    lg: `${16 / BASE_PX_VALUE}rem`,
    xl: `${20 / BASE_PX_VALUE}rem`,
    xxl: `${24 / BASE_PX_VALUE}rem`,
  },
  radius: {
    xs: `${4 / BASE_PX_VALUE}rem`,
    sm: `${8 / BASE_PX_VALUE}rem`,
    md: `${12 / BASE_PX_VALUE}rem`,
    lg: `${16 / BASE_PX_VALUE}rem`,
    xl: `${20 / BASE_PX_VALUE}rem`,
    xxl: `${24 / BASE_PX_VALUE}rem`,
    xxxl: `${28 / BASE_PX_VALUE}rem`,
  },
  defaultRadius: 'xs',
  breakpoints: breakpoints,

  headings: headingTheme.headings,

  components: components,

  shadows: shadows,
};

export const lightTheme: MantineThemeOverride = {
  ...theme,
  colorScheme: 'light',
};

export const darkTheme: MantineThemeOverride = {
  ...theme,
  colorScheme: 'dark',

  white: grays.gray0,
  black: darks.dark7,
  primaryShade: 8,
};
