import { dashboardEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { Center, LinkButton } from '../../../../../design-system/v2';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { EmptyState } from '../../../../common/EmptyState';

export const EmptyExperimentsPreview = (): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const { getExperimentsRoute } = useAbsoluteRoutes();

  const handleClickViewSampleExperiments = () => {
    sendAnalytics(
      dashboardEvents.experimentSampleClicked({
        workspaceId,
      }),
    );
  };

  return (
    <Center h={360}>
      <EmptyState
        compact
        iconName="experiment"
        title="No experiments recorded yet"
        subtitle="When building your model, track all your model experiments here"
        button={
          <LinkButton
            variant="outline"
            shape="rounded"
            to={getExperimentsRoute()}
            onClick={handleClickViewSampleExperiments}
          >
            View sample experiments
          </LinkButton>
        }
      />
    </Center>
  );
};
