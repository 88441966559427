import css from '@styled-system/css';
import styled from 'styled-components';
import Box from '../Box';
import Text from '../Text';
import { LoadingRing, LoadingRingProps } from './LoadingRing';

export interface LoaderProps extends LoadingRingProps {
  text?: string;
}

const LoaderWrapper = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  }),
);

export const Loader = ({ text, ...rest }: LoaderProps): JSX.Element => (
  <LoaderWrapper>
    <LoadingRing {...rest} />
    {text && (
      <Text variant="standard1" color="text.shade1" textAlign="center" pt={12}>
        {text}
      </Text>
    )}
  </LoaderWrapper>
);
