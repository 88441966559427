import { Text, openModal } from '../../../../design-system/v2';
import { TemplatesModalContainer } from './TemplateModal.container';

export const useWorkflowTemplateModal = () => {
  const open = () =>
    openModal({
      title: (
        <Text variant="subTitle01" p="sm">
          Select a Template
        </Text>
      ),
      size: '60vw',
      padding: '24px',
      children: <TemplatesModalContainer />,
    });

  return { open };
};
