import { IconArrowNarrowRight, IconPlus, IconWorld } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { snippetEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Button,
  Card,
  Horizontal,
  LinkButton,
  Text,
  Vertical,
  useMarkovTheme,
  useToggle,
} from '../../../../../design-system/v2';
import { SnippetModel } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { getHumanizedTimeFromNow } from '../../../../../lib/time-format';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { TableRowName } from '../../../../common/TableRowName';

interface SnippetsPreviewProps {
  snippets: SnippetModel[];
  handleAddSnippet: () => Promise<void>;
}

export const SnippetsPreview = ({
  snippets,
  handleAddSnippet,
}: SnippetsPreviewProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { getSnippetDetailRoute, getSnippetsRoute } = useAbsoluteRoutes();

  const [isCreatingSnippet] = useToggle();

  const handleNavigateSnippet = (snippetId: string) => {
    navigate(getSnippetDetailRoute(snippetId));
  };

  const handleClickViewAllSnippets = () => {
    sendAnalytics(
      snippetEvents.list.listViewed({
        workspaceId,
        pageSource: 'DASHBOARD',
      }),
    );
  };

  return (
    <Vertical h="100%" spacing={0} justify="space-between" p="lg">
      <Vertical>
        {snippets.slice(0, 4).map(snippet => (
          <Card
            key={snippet.snippetId}
            onClick={() => handleNavigateSnippet(snippet.snippetId)}
            withBorder
            p={0}
            sx={{ cursor: 'pointer' }}
          >
            <Vertical
              spacing="xs"
              h="56px"
              sx={{
                borderLeft: '4px solid',
                borderColor: theme.fn.themeColor(snippet.state === 'draft' ? 'gray.5' : 'green.5'),
                borderTopLeftRadius: '4px',
              }}
              px="md"
              py="xs"
            >
              <TableRowName isSampleRecord={snippet.demoArtifact}>
                <Text
                  variant="bodyShort02"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {snippet.name?.length === 0 ? 'Untitled' : snippet.name}
                </Text>
              </TableRowName>
              <Horizontal position="apart">
                <Text variant="small03" color="gray.6">
                  Edited {getHumanizedTimeFromNow(snippet.updateDate ?? '')} ago
                </Text>
                {snippet.isPublic && <IconWorld color={theme.colors.green[6]} />}
              </Horizontal>
            </Vertical>
          </Card>
        ))}
      </Vertical>
      <Horizontal position="apart" pt="lg">
        <Button
          variant="primary"
          shape="sharp"
          size="sm"
          leftIcon={<IconPlus size={20} stroke={1.5} />}
          onClick={handleAddSnippet}
          disabled={isCreatingSnippet}
        >
          <Text variant="subTitle03">New snippet</Text>
        </Button>
        <LinkButton
          variant="subtle"
          to={getSnippetsRoute()}
          rightIcon={<IconArrowNarrowRight stroke={1.5} />}
          w="100%"
          onClick={handleClickViewAllSnippets}
        >
          <Text variant="textLink" color="blue.7">
            View all snippets
          </Text>
        </LinkButton>
      </Horizontal>
    </Vertical>
  );
};
