import { Button, ButtonProps, notifications } from '../../../../design-system/v2';
import { ResourceRequest } from '../../../../generated/api';
import { useCreateDataSourceFromResourceMutation } from '../../../../queries/connectors';

const DEFAULT_BUTTON_WIDTH = 160;

interface AddResourceButtonContainerProps extends ButtonProps {
  connectorId: string;
  resourcePath: ResourceRequest[];
  onResourceAdd?: (resourcePath: string) => void;
  isDataSink?: boolean;
}

export const AddResourceButtonContainer = ({
  connectorId,
  resourcePath,
  onResourceAdd,
  isDataSink,
  w = DEFAULT_BUTTON_WIDTH,
  ...restProps
}: AddResourceButtonContainerProps): JSX.Element => {
  const { mutate: createDataSource, isLoading: isCreatingSource } =
    useCreateDataSourceFromResourceMutation();

  const handleAddResourceClick = () => {
    createDataSource(
      { connectorId, isDataSink, resourceList: resourcePath },
      {
        onError: () => {
          notifications.error('Error saving resource');
        },
        onSuccess: res => {
          notifications.success('Resource added successfully');

          if (onResourceAdd) {
            onResourceAdd(res.data.response?.resourceId ?? '');
          }
        },
      },
    );
  };

  return (
    <Button
      variant="outline"
      shape="rounded"
      color="green"
      onClick={handleAddResourceClick}
      loading={isCreatingSource}
      w={w}
      {...restProps}
    />
  );
};
