import { useEffect, useMemo, useState } from 'react';
import { Text, TextProps, useInterval } from '../../design-system/v2';
import { calculateTimeStampsInterval, getHumanizedTimeFromNow } from '../../lib/time-format';

interface TimerFromNowProps {
  date: string;
  suffixText?: string;
  textProps?: TextProps;
  callbackOnEnd?: () => void;
}

export const TimerFromNow = ({ date, textProps, suffixText, callbackOnEnd }: TimerFromNowProps) => {
  const [currentTime, setCurrTime] = useState(Date.now());

  const currTime = new Date(currentTime);
  const deltaInSecs = calculateTimeStampsInterval(currTime.toISOString(), date);

  const interval = useInterval(() => setCurrTime(Date.now()), 1000);

  const timeToShow = useMemo(() => {
    if (deltaInSecs <= 0) {
      return `0s`;
    }
    if (deltaInSecs < 60) {
      return `${Math.round(deltaInSecs)}s`;
    }
    return getHumanizedTimeFromNow(date);
  }, [date, deltaInSecs]);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  useEffect(() => {
    if (deltaInSecs <= 0 && callbackOnEnd) {
      callbackOnEnd();
      interval.stop();
    }
  }, [deltaInSecs, callbackOnEnd]);

  return (
    <Text variant="small03" color="gray.5" {...textProps}>
      {timeToShow} {suffixText}
    </Text>
  );
};
