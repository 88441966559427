import { Skeleton } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import range from 'lodash/range';
import { Fragment, useRef } from 'react';
import {
  Box,
  Horizontal,
  Loader,
  ScrollArea,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { useIntersectionObserver } from '../../../../../hooks/useIntersectionObserver';
import { useChartTypesStyles } from './ChartTypesSideBar.styles';

interface ChartsTypesSideBarProps {
  charts: {
    [chartType: string]: { name: string; id: string }[];
  };
  selectedChartId: string;
  onChartClick: (chartId: string) => void;
  isLoading?: boolean;
  isFetchingMore?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  width?: string | number;
}

export const ChartsTypesSideBar = ({
  charts,
  selectedChartId,
  onChartClick,
  isLoading = false,
  isFetchingMore = false,
  hasNextPage = false,
  fetchNextPage = () => undefined,
  width = '28%',
}: ChartsTypesSideBarProps): JSX.Element => {
  const { classes, cx } = useChartTypesStyles();

  const listEndRef = useRef(null);

  useIntersectionObserver({
    enabled: hasNextPage,
    target: listEndRef,
    onIntersect: fetchNextPage,
  });

  return (
    <ScrollArea scrollbarSize={4} p="md" h="calc(100vh - 120px)" w={width}>
      {isLoading && range(10).map(i => <Skeleton key={i} w="100%" h={28} radius="xl" my="xl" />)}
      {!isLoading && (
        <Vertical w="inherit">
          {Object.entries(charts ?? {}).map(
            ([chartType, chartsList]) =>
              chartsList?.length > 0 && (
                <Fragment key={chartType}>
                  <Text variant="small01" color="gray.6" px="md">
                    {chartType}
                  </Text>
                  {chartsList.map(({ name, id }) => (
                    <Horizontal
                      data-testid="snippets-add-chart-sidebar-type"
                      key={id}
                      w="inherit"
                      onClick={() => onChartClick(id)}
                      className={cx(classes.item, {
                        [classes.selectedItem]: id === selectedChartId,
                      })}
                      spacing={6}
                      noWrap
                    >
                      {id === selectedChartId && (
                        <Horizontal noWrap>
                          <IconCheck size={16} color="#2E74E4" />
                        </Horizontal>
                      )}
                      <Text
                        variant="small01"
                        lineClamp={2}
                        color={id === selectedChartId ? 'blue.6' : 'gray.7'}
                      >
                        {name}
                      </Text>
                    </Horizontal>
                  ))}
                </Fragment>
              ),
          )}
          {hasNextPage && (
            <Box ref={listEndRef} style={{ height: '50px', position: 'relative' }}>
              {isFetchingMore && <Loader />}
            </Box>
          )}
        </Vertical>
      )}
    </ScrollArea>
  );
};
