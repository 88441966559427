import { NodeViewProps } from '@tiptap/react';
import { useCallback, useEffect, useState } from 'react';
import { Text, Vertical } from '../../../../v2';

interface HeadingProps {
  level: string;
  text: string;
  id: string;
}

const INDENT_SIZE = 12;

export const TableOfContents = ({ editor }: Pick<NodeViewProps, 'editor'>) => {
  const [items, setItems] = useState<HeadingProps[]>([]);

  const handleUpdate = useCallback(() => {
    const headings: HeadingProps[] = [];

    editor.state.doc.descendants(node => {
      if (node.type.name === 'heading') {
        headings.push({
          level: node.attrs.level,
          text: node.textContent,
          id: node.attrs['data-block-id'],
        });
      }
    });

    setItems(headings);
  }, [editor]);

  useEffect(handleUpdate, []);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.on('update', handleUpdate);

    return () => {
      editor.off('update', handleUpdate);
    };
  }, [editor]);

  const onItemClick = (id: string) => () => {
    const el = document.querySelector(`[data-data-block-id='${id}']`);
    el?.scrollIntoView({ behavior: 'smooth' });
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <Vertical opacity={0.75} p="xl">
      <Text variant="subTitle04" color="gray.6">
        TABLE OF CONTENTS
      </Text>
      <Vertical spacing="xs" pl="xs">
        {items.map((item, index) => (
          <Text
            key={index}
            variant="small01"
            onClick={onItemClick(item.id)}
            ml={INDENT_SIZE * (Number(item.level) - 1)}
            sx={theme => ({
              cursor: 'pointer',
              ...theme.fn.hover({
                color: theme.colors.blue[6],
              }),
            })}
          >
            {item.text}
          </Text>
        ))}
      </Vertical>
    </Vertical>
  );
};
