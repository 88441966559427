import { Grid, Horizontal, Skeleton } from '../../../../design-system/v2';

const UsageItemLoading = (): JSX.Element => (
  <Horizontal>
    {/* Icon */}
    <Skeleton circle height={32} mr={8} />

    {/* Text */}
    <Skeleton height={18} width={120} />
  </Horizontal>
);

export const UsageLoading = (): JSX.Element => (
  <Grid>
    <Grid.Col xs={10}>
      <UsageItemLoading />
    </Grid.Col>
    <Grid.Col xs={10}>
      <UsageItemLoading />
    </Grid.Col>
    <Grid.Col xs={10}>
      <UsageItemLoading />
    </Grid.Col>
    <Grid.Col xs={10}>
      <UsageItemLoading />
    </Grid.Col>
  </Grid>
);
