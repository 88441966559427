import { PropsWithChildren } from 'react';
import { Horizontal, SimpleGrid, Vertical } from '../../../../../layout';
import { SelectAllItem } from '../../Select/DefaultItem/SelectAllItem';

import { useMarkovTheme } from '../../../../../../theme';
import useStyles from './ActionsDropdown.styles';

interface ActionsDropdownProps {
  allSelected?: boolean;
  onClickSelectAll: () => void;
  alignment?: string;
}

export const ActionsDropdown = ({
  allSelected,
  onClickSelectAll,
  alignment,
  children,
}: PropsWithChildren<ActionsDropdownProps>) => {
  const { classes } = useStyles();
  const theme = useMarkovTheme();

  const renderContent = () => {
    if (alignment === 'vertical') {
      return <Vertical className={classes.verticalDropdown}>{children}</Vertical>;
    } else {
      return (
        <SimpleGrid cols={3} className={classes.dropdownContainer}>
          {children}
        </SimpleGrid>
      );
    }
  };

  return (
    <>
      <Horizontal
        sx={{
          borderBottom: alignment === 'vertical' ? `1px solid ${theme.colors.gray[4]}` : 'none',
        }}
      >
        <SelectAllItem onClickSelectAll={onClickSelectAll} allSelected={allSelected} />
      </Horizontal>
      {renderContent()}
    </>
  );
};
