import React, { Suspense } from 'react';
import { ChartTypes } from '../../../charts';
import { ChartData } from '../../../charts/config/build';
import { Loader } from '../../../design-system/v2';
import { CustomMetricSummaryStats } from '../../../generated/api';

const LazyChart = React.lazy(() => import('../../../charts/Chart'));

const CHART_COLOR = '#E4B345';
const CHART_MEDIAN_COLOR = '#B87A00';

interface MetricBoxPlotProps {
  name: string;
  data: CustomMetricSummaryStats;
}

export const MetricBoxPlot = ({ name, data }: MetricBoxPlotProps) => {
  const transformedData = {
    series: [
      {
        data: [
          [
            name,
            data.min || 0,
            data['25%'] || 0,
            data['50%'] || 0,
            data['75%'] || 0,
            data.max || 0,
          ],
        ],
      },
    ],
  } as ChartData;

  return (
    <Suspense fallback={<Loader />}>
      <LazyChart
        type={ChartTypes.BOX_PLOT}
        data={transformedData}
        colors={[CHART_COLOR, CHART_MEDIAN_COLOR]}
      />
    </Suspense>
  );
};
