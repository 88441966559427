import { clsx } from '@mantine/styles';
import {
  Icon3dRotate,
  IconArrowBackUp,
  IconArrowBackUpDouble,
  IconBoxAlignTopLeft,
  IconLasso,
  IconZoomPan,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from 'lodash';
import { useCallback, useEffect } from 'react';
import { ChartMode, ScatterChartType } from '../../../../charts/providers/deck-gl/layers.util';
import { useDatasetClusterContext } from '../../../../contexts/datasets/DatasetClusterContext';
import { useClusterPanelContext } from '../../../../contexts/datasets/embeddings/ClusterPanelContext';
import { ActionIcon, Box, Button, Horizontal, Slider, Tooltip } from '../../../../design-system/v2';
import { PanelMode } from '../util';
import { ACTIONS_OFFSET_BOTTOM, ACTIONS_OFFSET_X, useChartActions } from './ChartActions.style';
import { ViewModeSwitch } from './ViewModeSwitch';
import { ZoomControls } from './ZoomControls';

interface ChartActionsProps {
  hideSelectionBtns?: boolean;
}

export const ChartActions = ({ hideSelectionBtns = false }: ChartActionsProps) => {
  const { featureEmneddingsConfiguration } = useFlags();
  const {
    resetSelection,
    undoSelection,
    chartMode,
    setChartMode,
    scatterViewMode,
    pointSize,
    setPointSize,
  } = useDatasetClusterContext();
  const { panelMode } = useClusterPanelContext();
  const { classes } = useChartActions();

  const handleReset = () => {
    resetSelection();
  };

  const handleUndo = () => {
    undoSelection();
  };

  const handlePan = useCallback(() => {
    setChartMode(ChartMode.PAN);
  }, [setChartMode]);

  const handleSelectMode = () => {
    setChartMode(ChartMode.SELECT_RECTANGLE);
  };

  const handleLassoSelectMode = () => {
    setChartMode(ChartMode.SELECT_LASSO);
  };

  const handle3DOrbitRotate = () => {
    setChartMode(ChartMode.ROTATE_ORBIT);
  };

  const handleSlideChange = (val?: number) => {
    if (isUndefined(val)) {
      return;
    }

    setPointSize(val);
  };

  const isActionMode = panelMode === PanelMode.ACTIONS;
  const is3DChart = scatterViewMode === ScatterChartType.SCATTER_3D;

  const showZoomBtns = !is3DChart;
  const showRotationBtns = is3DChart;
  const showSelectionBtns = isActionMode && !hideSelectionBtns;

  useEffect(() => {
    if (hideSelectionBtns) {
      handlePan();
    }
  }, [handlePan, hideSelectionBtns]);

  return (
    <>
      {featureEmneddingsConfiguration && (
        <Box
          p="md"
          bg="white.0"
          pos="absolute"
          left={ACTIONS_OFFSET_X}
          bottom={ACTIONS_OFFSET_BOTTOM}
          sx={theme => ({
            border: `2px solid ${theme.colors.gray[3]}`,
            borderRadius: 4,
          })}
        >
          <Slider w="150px" onChange={handleSlideChange} value={pointSize} />
        </Box>
      )}
      <Horizontal className={classes.actions} spacing="xs">
        <ViewModeSwitch />
        {showZoomBtns && <ZoomControls />}
        <Tooltip label="Pan" withArrow withinPortal>
          <ActionIcon
            onClick={handlePan}
            title="Pan"
            color="white.0"
            className={clsx(classes.actionIcon, {
              [classes.selectedActionIcon]: chartMode === ChartMode.PAN,
            })}
          >
            <IconZoomPan />
          </ActionIcon>
        </Tooltip>
        {showRotationBtns && (
          <Button.Group
            bg="dark.8"
            sx={theme => ({
              borderRadius: theme.radius.sm,
              border: '1px solid',
              borderColor: theme.fn.themeColor('white.3'),
              gap: '4px',
            })}
          >
            <Tooltip label="Orbit rotate" withArrow withinPortal>
              <ActionIcon
                onClick={handle3DOrbitRotate}
                color="white.0"
                className={clsx(classes.actionIcon, {
                  [classes.selectedActionIcon]: chartMode === ChartMode.ROTATE_ORBIT,
                })}
              >
                <Icon3dRotate />
              </ActionIcon>
            </Tooltip>
          </Button.Group>
        )}
        {showSelectionBtns && (
          <Button.Group
            bg="dark.8"
            sx={theme => ({
              borderRadius: theme.radius.sm,
              border: '1px solid',
              borderColor: theme.fn.themeColor('white.3'),
              gap: '4px',
            })}
          >
            <Tooltip label="Reset all" withArrow withinPortal>
              <ActionIcon onClick={handleReset} color="white.0" className={classes.actionIcon}>
                <IconArrowBackUpDouble />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Undo selection" withArrow withinPortal>
              <ActionIcon onClick={handleUndo} color="white.0" className={classes.actionIcon}>
                <IconArrowBackUp />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Rectangle Select" withArrow withinPortal>
              <ActionIcon
                onClick={handleSelectMode}
                title="Rectangle Select"
                color="white.0"
                className={clsx(classes.actionIcon, {
                  [classes.selectedActionIcon]: chartMode === ChartMode.SELECT_RECTANGLE,
                })}
              >
                <IconBoxAlignTopLeft />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Lasso Select" withArrow withinPortal>
              <ActionIcon
                onClick={handleLassoSelectMode}
                title="Lasso Select"
                color="white.0"
                className={clsx(classes.actionIcon, {
                  [classes.selectedActionIcon]: chartMode === ChartMode.SELECT_LASSO,
                })}
              >
                <IconLasso />
              </ActionIcon>
            </Tooltip>
          </Button.Group>
        )}
      </Horizontal>
    </>
  );
};
