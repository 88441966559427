import { mergeAttributes } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';
import { v4 as uuid } from 'uuid';
import { sanitizeStyleAttr, SPLITTING_MARK_META } from '../text-style/extention';
import { splitBlock } from './splitBlock';

export interface ParagraphOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    HTMLParagraph: {
      /**
       * Toggle a paragraph
       */
      setParagraph: () => ReturnType;
      splitBlockModified: (options?: { keepMarks?: boolean }) => ReturnType;
    };
  }
}

export const HTMLParagraph = Paragraph.extend<ParagraphOptions>({
  name: 'paragraph',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      'data-id': {
        default: uuid(),
      },
      class: {
        default: null,
      },
      style: {
        default: '',
        parseHTML: element => element.getAttribute('style'),
        renderHTML: attributes => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: sanitizeStyleAttr(attributes.style),
          };
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) =>
          commands.setNode(this.name),

      splitBlockModified: splitBlock,
    };
  },

  addKeyboardShortcuts() {
    const handleEnter = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.newlineInCode(),
        () => commands.createParagraphNear(),
        () => {
          // Set this meta as this whole operation can split marks.
          commands.setMeta(SPLITTING_MARK_META, true);
          // Next command run after the other and stops at the first which returns true.
          return false;
        },
        () => commands.liftEmptyBlock(),
        () => commands.splitBlockModified({ keepMarks: false }),
      ]);

    return {
      Enter: handleEnter,
      'Shift-Enter': handleEnter,
      'Mod-Enter': handleEnter,
    };
  },
});
