import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { snippetEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Box,
  Button,
  Center,
  Horizontal,
  Image,
  Text,
  Vertical,
  useToggle,
} from '../../../../../design-system/v2';
import { ArtifactFilterState, SnippetModel } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { S3_STORAGE_BASE } from '../../../../../lib/env.util';
import {
  useCreateSnippetMutation,
  useGetSnippetsQuery,
} from '../../../../../queries/snippets/snippets';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { ErrorState } from '../../../../common/ErrorState';
import { WorkspaceCard } from '../../../home/WorkspaceCard';
import { useDashboardSnapshotContext } from '../../../home/snapshot/DashboardSnapshotContext';
import { SnapshotLoading } from '../../../home/snapshot/SnapshotLoading';
import { EmptyPreviewVertical } from '../EmptyPreviewVertical';
import { SnippetsPreview } from './SnippetsPreview';

const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/illustrations/dashboard/snippets-illustration.svg`;

const snippetsTitle = 'Create reports with Snippets';
const snippetsDescription =
  'With Snippets you can collaborate and structure reports for your ML projects seamlessly.';
const snippetsIllustration = <Image maw={250} src={ILLUSTRATION_URL} />;

export const SnippetsPreviewContainer = () => {
  const { userId, workspaceId } = useAppMetadata();

  const { selectedTab, handleTabChange } = useDashboardSnapshotContext();
  const { isError, isLoading, data } = useGetSnippetsQuery(ArtifactFilterState.Active);
  const { mutateAsync: createSnippet } = useCreateSnippetMutation();

  const navigate = useNavigate();
  const { getSnippetDetailRoute } = useAbsoluteRoutes();
  const [isCreatingSnippet, toggleCreatingSnippet] = useToggle();

  const handleCreateSnippet = async () => {
    const response = await createSnippet({
      name: '',
      description: 'desc',
    });

    return response.data.response as SnippetModel;
  };

  const handleNavigateSnippet = (snippetId: string) => {
    sendAnalytics(
      snippetEvents.list.viewSample({
        workspaceId,
      }),
    );
    navigate(getSnippetDetailRoute(snippetId));
  };

  const handleAddSnippet = async () => {
    sendAnalytics(
      snippetEvents.list.addNewClicked({
        workspaceId,
        pageSource: 'DASHBOARD',
      }),
    );
    toggleCreatingSnippet();

    const snippet = await handleCreateSnippet();
    if (snippet.snippetId) {
      toggleCreatingSnippet();
      handleNavigateSnippet(snippet.snippetId);
    }
  };

  if (isLoading) {
    return (
      <WorkspaceCard
        title="Snippets"
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        isSample
      >
        <SnapshotLoading compact />
      </WorkspaceCard>
    );
  }

  if (isError || !data) {
    return (
      <WorkspaceCard title="Snippets" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <Center h={280} bg="white.0">
          <ErrorState
            compact
            iconName="dataset"
            title="Error loading snippets"
            subtitle="Please try refreshing the page or contact support"
          />
        </Center>
      </WorkspaceCard>
    );
  }

  const demoSnippetsCount = data.filter((snippet: SnippetModel) => snippet.demoArtifact).length;
  const totalSnippetsCount = data.length;

  if (demoSnippetsCount === totalSnippetsCount) {
    return (
      <WorkspaceCard title="Snippets" isSample>
        <EmptyPreviewVertical
          title={snippetsTitle}
          description={snippetsDescription}
          illustration={snippetsIllustration}
        >
          <Vertical spacing="xs">
            {data.slice(0, 2).map((snippet, idx) => (
              <Box
                pt="sm"
                key={idx}
                w="100%"
                h="44px"
                onClick={() => handleNavigateSnippet(snippet.snippetId)}
                sx={theme => ({
                  borderBottom: `1px solid ${theme.fn.themeColor('gray.2')}`,
                  cursor: 'pointer',
                })}
              >
                <Text variant="subTitle03">
                  {snippet.name?.length === 0 ? 'Untitled' : snippet.name}
                </Text>
              </Box>
            ))}
            <Horizontal position="right" px="sm" pt="lg">
              <Button
                variant="primary"
                shape="sharp"
                size="sm"
                leftIcon={<IconPlus size={20} stroke={1.5} />}
                onClick={handleAddSnippet}
                disabled={isCreatingSnippet}
              >
                <Text variant="subTitle03">New snippet</Text>
              </Button>
            </Horizontal>
          </Vertical>
        </EmptyPreviewVertical>
      </WorkspaceCard>
    );
  }
  return (
    //TODO: need to remove hideTabs from here
    <WorkspaceCard
      title="Snippets"
      selectedTab={selectedTab}
      onChangeTab={handleTabChange}
      hideTabs
    >
      <Box>
        <SnippetsPreview snippets={data} handleAddSnippet={handleAddSnippet} />
      </Box>
    </WorkspaceCard>
  );
};
