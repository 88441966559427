import get from 'lodash/get';
import { Horizontal, Text, Vertical } from '../../../design-system/v2';
import { ModelWithAppMetadata } from '../../../generated/api';
import { capitalize } from '../../../lib/ui';
import { TimerFromNow } from '../../atoms/TimerFromNow';
import { GeneratingModelAppLoader } from '../../model-apps/model-app-details/GeneratingAppLoader';
import { ModelInputPrompt } from '../../model-apps/model-app-details/ModelInput/ModelInputPrompt';
import { ModelOutputBox } from '../../model-apps/model-app-details/ModelOutput/ModelOutputBox';
import { useViewFeaturesModal } from '../../model-apps/model-app-details/ModelOutput/ViewFeaturesModal';
import { StoppedModelApp } from '../../model-apps/model-app-details/StoppedModelApp';
import {
  ModelAppOutput,
  useModelAppStatus,
} from '../../model-apps/model-app-details/use-model-app';

interface ModelAppsBlockProps {
  modelAppMetadata: ModelWithAppMetadata;
  getInferenceResult: (userInput: string) => void;
  modelOutputList: ModelAppOutput[];
  hideRestartBtn?: boolean;
  invalidateModelApps?: () => void;
}

export const OUTPUT_BOX_HEIGHT = 200;

export const ModelAppsBlock = ({
  modelAppMetadata,
  getInferenceResult,
  modelOutputList,
  invalidateModelApps,
  hideRestartBtn = false,
}: ModelAppsBlockProps) => {
  const { open: openFeaturesModal } = useViewFeaturesModal({
    modelInputSchema: modelAppMetadata.modelInputSchema,
  });
  const { isAppReady, isAppStopped, isAppLoading } = useModelAppStatus({
    modelAppStatus: modelAppMetadata.modelAppStatus,
  });

  const { modelId, modelAppEndTime, modelAppLaunchDate } = modelAppMetadata;

  const {
    precision = '',
    recall = '',
    'f1-score': f1Score = '',
  } = get(modelAppMetadata, 'info.ds_baseline_metrics.weighted_avg', {} as any);

  let content = null;

  if (isAppLoading) {
    content = (
      <GeneratingModelAppLoader
        modelAppLaunchDate={modelAppLaunchDate}
        height={2 * OUTPUT_BOX_HEIGHT}
        withShadow={false}
      />
    );
  } else if (isAppStopped) {
    content = (
      <StoppedModelApp
        modelId={modelId}
        height={1.5 * OUTPUT_BOX_HEIGHT}
        hideRestartBtn={hideRestartBtn}
      />
    );
  } else if (isAppReady) {
    content = (
      <>
        <ModelInputPrompt
          modelAppDetails={modelAppMetadata}
          submitInput={getInferenceResult}
          compact
          hideFileInput
        />
        <ModelOutputBox
          hideExplainability
          modelOutputList={modelOutputList}
          openFeaturesModal={openFeaturesModal}
          height={OUTPUT_BOX_HEIGHT}
        />
        {modelAppEndTime && (
          <Horizontal px="md" position="right">
            <TimerFromNow
              date={modelAppEndTime}
              suffixText="left"
              callbackOnEnd={invalidateModelApps}
              textProps={{ color: 'gray.7', variant: 'small01' }}
            />
          </Horizontal>
        )}
      </>
    );
  }

  return (
    <Vertical>
      <Horizontal px="md" noWrap position="apart" align="flex-start">
        <Text variant="heading04">{capitalize(modelAppMetadata.modelAppName ?? '')}</Text>
        <Vertical spacing={0}>
          {precision && (
            <Text variant="small01" color="gray.6">
              Precision: <u>{precision.toFixed(2)}</u>
            </Text>
          )}
          {recall && (
            <Text variant="small01" color="gray.6">
              Recall: <u>{recall.toFixed(2)}</u>
            </Text>
          )}
          {f1Score && (
            <Text variant="small01" color="gray.6">
              F1-Score: <u>{f1Score.toFixed(2)}</u>
            </Text>
          )}
        </Vertical>
      </Horizontal>
      {content}
    </Vertical>
  );
};
