import { PointOptionsObject, TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface PackedBubbleTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const PackedBubbleTooltip = ({ formatterContext }: PackedBubbleTooltipProps) => (
  <TooltipContainer>
    <TooltipHeader>{(formatterContext.point as PointOptionsObject).name}</TooltipHeader>
    <TooltipItemRow>{(formatterContext.point as PointOptionsObject).value}</TooltipItemRow>
  </TooltipContainer>
);
