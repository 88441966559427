import { useQuery } from 'react-query';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import { userManagementApi } from '../lib/api';
import { WORKSPACE } from './queryConstants';

export interface Subscription {
  id: string;
  name: string;
}

export const useWorkspaceList = () => {
  const { userId } = useAppMetadata();

  return useQuery(
    [WORKSPACE.LIST_USER_WORKSPACE, userId],
    () => userManagementApi.listUserWorkspacesV1(userId),
    {
      staleTime: 0,
      enabled: !!userId,
      select: data => data.data.response,
      refetchOnWindowFocus: true,
    },
  );
};
