import { openModal, Text } from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { DocumentUploadModalContainer } from './DocumentUploadModal.container';

export const useDocumentUploadModal = (variant: AppIdType, projectId?: string) => ({
  open: () =>
    openModal({
      title: (
        <Text variant="subTitle01" tabIndex={0}>
          Get started: Upload or paste your document
        </Text>
      ),
      size: '100%',
      withinPortal: false,
      styles: {
        content: {
          maxWidth: '1200px !important',
        },
        close: {
          ariaLabel: 'Close Document Upload Modal',
        },
      },

      children: <DocumentUploadModalContainer variant={variant} projectId={projectId} />,
    }),
});
