export const COPY_EDIT_APP_NAME = 'Copy Edit';

export const SUMMARIZE_APP_NAME = 'Summarize';

export const CHAT_WITH_MARKOV_APP_NAME = 'Markov AI Companion';

export const QNA_WITH_PDF_APP_NAME = 'Learner AI Companion';

export const AUTO_GRADER_APP_NAME = 'Auto Grader';

export const SUPPLEMENT_GENERATOR_APP_NAME = 'Supplement Generator';

export const TEACHER_ASSISTANT_APP_NAME = 'Instructor AI Companion';

export const LESSON_PLANNER = 'Lesson Planner';
