import { IconTrashFilled } from '@tabler/icons-react';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Horizontal,
  Select,
  Text,
  notifications,
  useListState,
  useMarkovTheme,
} from '../../../design-system/v2';
import {
  Condition,
  ConditionType,
  ExpressionType,
  LogicalOperator,
  Rule,
  RuleBasedRelabelingDetailsModel,
  StringColumnExpression,
} from '../../../generated/api';
import { interleaveComponent } from '../../../lib/util-react';
import {
  useDataWorkflowDetailsQuery,
  useSaveRuleMutation,
} from '../../../queries/datasets/analysis/data-workflows';
import { CompoundCondition } from './rules/CompoundCondition';
import { LOGICAL_OPERATORS } from './rules/constants';

const OPERATOR_SELECT_WIDTH = 90;

const DEFAULT_EXPRESSION: StringColumnExpression = {
  type: ExpressionType.Equal,
  column: '',
  value: '',
};

export const DEFAULT_CONDITION: Condition = {
  type: ConditionType.ColumnCondition,
  operator: LogicalOperator.And,
  expressions: [{ ...DEFAULT_EXPRESSION }],
};

interface EditLabelRuleProps {
  datasetId: string;
  workflowId: string;
  rule: Rule;
  editable: boolean;
}

export const EditLabelRule = ({
  datasetId,
  workflowId,
  rule,
  editable,
}: EditLabelRuleProps): JSX.Element => {
  const theme = useMarkovTheme();
  const workflowQuery = useDataWorkflowDetailsQuery(datasetId, workflowId);
  const { mutate, isLoading } = useSaveRuleMutation(datasetId, workflowId, rule.ruleId);

  const [operator, setOperator] = useState<LogicalOperator>(rule.ruleBody.operator);
  const [conditions, { append: addCondition, setItem: setCondition, setState: setConditions }] =
    useListState<Condition>(rule.ruleBody.conditions);

  const handleSaveRule = () => {
    const ruleDetails = {
      operator,
      conditions,
      targetName: rule.ruleBody.targetName,
      targetValue: rule.ruleBody.targetValue,
    };

    mutate(ruleDetails, {
      onError: () => {
        notifications.error('Error while updating rule');
      },
      onSuccess: () => {
        notifications.success('Rule updates saved');
      },
    });
  };

  const handleChangeOperator = (value: LogicalOperator) => {
    setOperator(value);
  };

  const handleAddCondition = () => {
    const newCondition = cloneDeep(DEFAULT_CONDITION);
    addCondition(newCondition);
  };

  const getDeleteConditionHandler = (conditionIndex: number) => () => {
    const nextConditions = cloneDeep(conditions);
    nextConditions.splice(conditionIndex, 1);
    setConditions(nextConditions);
  };

  const getAddExpression = (conditionIndex: number) => () => {
    const nextCondition = cloneDeep(conditions[conditionIndex]);
    nextCondition.expressions.push({ ...DEFAULT_EXPRESSION });
    setCondition(conditionIndex, nextCondition);
  };

  const getChangeConditionOperator = (conditionIndex: number) => (operator: LogicalOperator) => {
    const nextCondition = cloneDeep(conditions[conditionIndex]);
    nextCondition.operator = operator;
    setCondition(conditionIndex, nextCondition);
  };

  const getChangeColumnHandler =
    (conditionIndex: number, expressionIndex: number) => (column: string) => {
      const nextCondition = cloneDeep(conditions[conditionIndex]);
      nextCondition.expressions[expressionIndex].column = column;
      setCondition(conditionIndex, nextCondition);
    };

  const getChangeExpressionTypeHandler =
    (conditionIndex: number, expressionIndex: number) => (expressionType: ExpressionType) => {
      const nextCondition = cloneDeep(conditions[conditionIndex]);
      nextCondition.expressions[expressionIndex].type = expressionType;
      setCondition(conditionIndex, nextCondition);
    };

  const getChangeValueHandler =
    (conditionIndex: number, expressionIndex: number) => (value: string) => {
      const nextCondition = cloneDeep(conditions[conditionIndex]);
      nextCondition.expressions[expressionIndex].value = value;
      setCondition(conditionIndex, nextCondition);
    };

  const getDeleteExpressionHandler = (conditionIndex: number, expressionIndex: number) => () => {
    const nextCondition = cloneDeep(conditions[conditionIndex]);
    nextCondition.expressions.splice(expressionIndex, 1);
    setCondition(conditionIndex, nextCondition);
  };

  const featureNames =
    (workflowQuery.data as RuleBasedRelabelingDetailsModel)?.featureColumns ?? [];
  const label = rule.ruleBody.targetValue;

  return (
    <Box>
      <Text variant="subTitle03">Rule for Label &quot;{label}&quot;</Text>
      <Horizontal mb="md" mt="lg">
        <Text variant="subTitle04" color="gray.8" mb="xs" mr={32}>
          Set
        </Text>
        <Box
          bg="gray.1"
          px="lg"
          py="sm"
          sx={theme => ({ border: `1px solid ${theme.colors.gray[4]}` })}
        >
          <Text variant="subTitle04" color="gray.8">
            {label}
          </Text>
        </Box>
      </Horizontal>
      <Box>
        <Text variant="subTitle04" color="gray.8" mb="xs">
          Where
        </Text>
        {interleaveComponent(
          conditions.map((condition, i) => (
            <Box key={i} sx={{ position: 'relative' }}>
              <Box
                py="sm"
                ml={44}
                sx={{
                  position: 'relative',
                  // vertical line alongside each condition
                  '::after': {
                    position: 'absolute',
                    top: 0,
                    left: -24,
                    content: '""',
                    width: 1,
                    height: '100%',
                    background: theme.colors.gray[3],
                  },
                }}
              >
                <CompoundCondition
                  editable={editable}
                  condition={condition}
                  conditionIndex={i}
                  columnNames={featureNames}
                  getChangeColumnHandler={getChangeColumnHandler}
                  getChangeExpressionTypeHandler={getChangeExpressionTypeHandler}
                  getChangeValueHandler={getChangeValueHandler}
                  getDeleteExpressionHandler={getDeleteExpressionHandler}
                  getAddExpression={getAddExpression}
                  getChangeConditionOperator={getChangeConditionOperator}
                />
              </Box>

              {i === conditions.length - 1 && (
                <Box mt="lg">
                  <Button variant="outline" onClick={handleAddCondition} disabled={!editable}>
                    +Add condition
                  </Button>
                </Box>
              )}

              {editable && conditions.length > 1 && (
                <Box bg="gray.0" p="xs" sx={{ position: 'absolute', right: 0, top: -24 }}>
                  <ActionIcon onClick={getDeleteConditionHandler(i)}>
                    <IconTrashFilled size={20} />
                  </ActionIcon>
                </Box>
              )}
            </Box>
          )),
          () => (
            <Select
              disabled={!editable}
              ariaLabel="Logical operator"
              hideLabel
              w={OPERATOR_SELECT_WIDTH}
              options={LOGICAL_OPERATORS}
              value={operator}
              onChange={handleChangeOperator}
            />
          ),
        )}
      </Box>

      <Box mt="xl">
        <Button
          variant="primary"
          onClick={handleSaveRule}
          miw={132}
          loading={isLoading}
          disabled={!editable}
        >
          Save rule
        </Button>
      </Box>
    </Box>
  );
};
