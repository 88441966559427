import { Box, Center, Text, Vertical } from '../../../../design-system/v2';

export const BlankDocument = (): JSX.Element => (
  <Center
    h={240}
    bg="gray.0"
    sx={theme => ({
      border: `2px dashed ${theme.colors.gray[3]}`,
      borderRadius: theme.radius.sm,
    })}
  >
    <Vertical align="center">
      <Box
        w={80}
        h={120}
        bg="white.0"
        sx={theme => ({
          border: `1px solid ${theme.colors.blue[6]}`,
          borderRadius: theme.radius.xs,
        })}
      />
      <Text variant="bodyShort03">Blank Document</Text>
    </Vertical>
  </Center>
);
