import { Loader as MantineLoader, LoaderProps as MantineLoaderProps } from '@mantine/core';
import { Vertical } from '../../layout';
import { Text, TextVariants } from '../../typography';

interface LoaderProps extends MantineLoaderProps {
  text?: string;
  textVariant?: TextVariants;
}

export const Loader = ({ text, textVariant, ...rest }: LoaderProps): JSX.Element => (
  <Vertical justify="center" align="center" spacing="xs">
    <MantineLoader {...rest} />
    {text && (
      <Text variant={textVariant ?? 'bodyShort03'} color="gray.7">
        {text}
      </Text>
    )}
  </Vertical>
);
