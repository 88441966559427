import { IconSwitchHorizontal } from '@tabler/icons-react';
import {
  ActionIcon,
  Horizontal,
  Indicator,
  Text,
  Tooltip,
  Vertical,
} from '../../../../design-system/v2';
import { useWorkspaceSwitcherModal } from '../../workspace-switcher/WorkspaceSwitcherModal';

interface WorkspaceSwitcherWrapperProps {
  workspaceName: string;
  userEmail: string;
  showIndicator: boolean;
}

export const WorkspaceSwitcherWrapper = ({
  workspaceName,
  userEmail,
  showIndicator,
}: WorkspaceSwitcherWrapperProps): JSX.Element => {
  const { open: openWorkspaceSwitcherModal } = useWorkspaceSwitcherModal();

  return (
    <Horizontal noWrap px="xl" py="lg" bg="blue.0" position="apart" aria-label="Workspace switcher">
      <Vertical spacing={0}>
        <Text variant="subTitle02" lineClamp={1} title={workspaceName}>
          {workspaceName}
        </Text>
        <Text
          variant="subTitle04"
          color="gray.6"
          lineClamp={1}
          title={userEmail}
          sx={{ wordBreak: 'break-all' }}
        >
          {userEmail}
        </Text>
      </Vertical>
      <Indicator color="yellow.7" processing disabled={!showIndicator}>
        <Tooltip label="Switch workspace">
          <ActionIcon size="lg" color="gray.6" onClick={openWorkspaceSwitcherModal}>
            <IconSwitchHorizontal />
          </ActionIcon>
        </Tooltip>
      </Indicator>
    </Horizontal>
  );
};
