import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';
import { notifications } from '../../../../design-system/v2';
import { AnalysisRetriggerResponse } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { AnalyzerTaskletEnum } from '../../../../queries/constants';

export const handleReRunDataQualityScore = (
  triggerDataQualityAnalysis: UseMutateFunction<
    AxiosResponse<AnalysisRetriggerResponse, any>,
    unknown,
    string,
    unknown
  >,
) => {
  triggerDataQualityAnalysis(AnalyzerTaskletEnum.DATA_QUALITY_SCORE, {
    onSuccess: () => {
      notifications.show({
        variant: 'success',
        message: 'Data quality score re-triggered successfully',
      });
    },
    onError: err => {
      logger.error(err);
      notifications.show({
        variant: 'error',
        message: 'Error encountered while re-triggering data quality score',
      });
    },
  });
};
