import { CSSObject, MantineTheme } from '@mantine/styles';

export const drawerTheme = {
  styles: (theme: MantineTheme): Record<string, CSSObject> => ({
    header: {
      padding: '1rem',
    },
    title: {
      backgroundColor: theme.white,
      margin: 0,
      height: '40px',
      borderRadius: '20px',
    },
    body: {
      height: 'calc(100% )',
    },
  }),
};
