import { IconAlignCenter, IconAlignLeft, IconAlignRight, IconTrash } from '@tabler/icons-react';
import { NodeViewProps } from '@tiptap/react';
import { ActionIcon, Box } from '../../../';
import { useResizableMediaStyles } from './ResizableMedia.style';

interface ActionsMenuProps {
  updateAttributes: NodeViewProps['updateAttributes'];
  deleteNode: NodeViewProps['deleteNode'];
}

export const ActionsMenu = ({ updateAttributes, deleteNode }: ActionsMenuProps) => {
  const { classes } = useResizableMediaStyles({ align: 'start' });

  const alignItem = (align: 'start' | 'center' | 'end') => () => {
    updateAttributes({
      dataAlign: align,
    });
  };

  return (
    <Box className={classes.mediaActions}>
      <ActionIcon title="Align Left" onClick={alignItem('start')}>
        <IconAlignLeft />
      </ActionIcon>
      <ActionIcon title="Align Center" onClick={alignItem('center')}>
        <IconAlignCenter />
      </ActionIcon>
      <ActionIcon title="Align Right" onClick={alignItem('end')}>
        <IconAlignRight />
      </ActionIcon>
      <ActionIcon title="Delete" onClick={deleteNode}>
        <IconTrash />
      </ActionIcon>
    </Box>
  );
};
