import { createStyles } from '../../../design-system/v2';

export const useAboutStyles = createStyles(theme => ({
  accountManagerList: {
    paddingInline: theme.spacing.xs,
    ':first-of-type:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    ':not(:last-child)': {
      paddingBottom: theme.spacing.md,
    },
  },
}));
