import { useQuery } from 'react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { UpsertDAGRequest } from '../../generated/api';
import { workflowApi } from '../../lib/api';
import { workflowsQueryKeys } from './list/list';

export const useGetWorkflowDAGQuery = (
  workflowId: string,
  onSuccess?: (data: UpsertDAGRequest) => void,
  opts?: {
    enabled?: boolean;
  },
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.dag(workspaceId, workflowId),
    () => workflowApi.fetchDAGsV1(workspaceId, workflowId),
    {
      enabled: !!workflowId && opts?.enabled,
      select: data => data.data,
      onSuccess,
    },
  );
};
