import { Box, Text } from '../../../design-system/v2';
import { UserSelectContainer } from './UserSelect.container';
import { WorkspaceShareProps } from './WorkspaceShare';

export const Share = ({
  entityName,
  onShare,
  sharedWithUsers,
  onRemoveUser,
  projectId,
}: WorkspaceShareProps) => (
  <Box p="xs" w={400}>
    <Text variant="subTitle02">Share {entityName}</Text>
    <Box pt="lg">
      <UserSelectContainer
        projectId={projectId ?? ''}
        onShare={onShare}
        sharedWithUsers={sharedWithUsers}
        onRemoveUser={onRemoveUser}
      />
    </Box>
  </Box>
);
