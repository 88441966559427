import { Point, TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

export interface PieTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

interface OthersDataProps extends Point {
  metaData: [string, number][];
}

// Rethink on using design-system for chart components.
export const PieTooltip = ({ formatterContext }: PieTooltipProps) => {
  const othersData = (formatterContext.point as OthersDataProps).metaData;
  const displayLimit = 20;

  return (
    <TooltipContainer>
      <TooltipHeader>{formatterContext.point.category}</TooltipHeader>
      {othersData ? (
        <>
          {othersData.slice(0, displayLimit).map(([name, y]) => (
            <TooltipItemRow key={name}>
              {name} - {y}
            </TooltipItemRow>
          ))}
          {othersData.length > displayLimit && (
            <TooltipItemRow>+ {othersData.length - displayLimit} more</TooltipItemRow>
          )}
        </>
      ) : (
        <>
          <TooltipItemRow>{formatterContext.point.name}</TooltipItemRow>
          <TooltipItemRow>{formatterContext.point.y}</TooltipItemRow>
        </>
      )}
    </TooltipContainer>
  );
};
