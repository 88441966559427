import { createStyles, rem } from '@mantine/styles';

export const useEditorTitleStyles = createStyles(theme => ({
  input: {
    backgroundColor: 'transparent',
    border: 'none',
    height: rem(72),
    fontWeight: 500,
    fontSize: rem(48),
    lineHeight: rem(56),
    color: theme.colors.gray[9],

    '&[data-disabled]': {
      backgroundColor: 'transparent',
      color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
      opacity: '80%',
      cursor: 'inherit',
    },
  },
}));
