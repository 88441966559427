import {
  Alert,
  closeAllModals,
  IconAlertCircle,
  openConfirmModal,
  Text,
} from '../../../design-system/v2';
import { useRemoveMemberFromWorkspaceMutation } from '../../../queries/account/workspace';
import { WorkspaceMemberDetail } from './MembersTable';

export const useDeleteMemberFromWorkspace = () => {
  const {
    mutateAsync: removeMember,
    isLoading,
    isSuccess,
    reset,
  } = useRemoveMemberFromWorkspaceMutation();

  if (isSuccess) {
    reset();
    closeAllModals();
  }

  const onConfirmDeletion = async (userId: string) => {
    removeMember({ userIds: [userId] });
  };

  const openDeleteModal = (workspaceMember: WorkspaceMemberDetail) => {
    openConfirmModal({
      title: <Text variant="heading03">Remove member</Text>,
      children: (
        <>
          <Alert my="24px" icon={<IconAlertCircle size={16} />} color="red" radius="lg">
            Note: This workspace will lose access to notebook servers and files shared by{' '}
            {workspaceMember.name}
          </Alert>
          <Text>
            Are you sure you want to remove <strong>{workspaceMember.name}</strong> from this
            workspace?
          </Text>
        </>
      ),
      labels: { confirm: 'Remove member', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => closeAllModals(),
      onConfirm: () => onConfirmDeletion(workspaceMember.userId),
    });
  };

  return { isLoading, openDeleteModal };
};
