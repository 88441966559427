import { PropsWithChildren } from 'react';
import { TableHeaderProps as RTTableHeaderProps } from 'react-table';

interface TableHeaderProps extends RTTableHeaderProps {
  backgroundColor?: string;
  borderColor?: string;
}

export const TableHeader = ({ children, ...rest }: PropsWithChildren<TableHeaderProps>) => (
  <th {...rest}>{children}</th>
);
