import { ChartTypes } from '../types';

export const colors = ['#ffa18f', '#ff826a', '#ff7055', '#ff6244'];

// TODO: Get additional colors from design once we support comparing >4 evaluations
export const COMPARISON_COLORS = ['#C95C2E', '#E4B345', '#753489', '#83AB45'];

export const datalabelColor = '#464646';

// These colors are temporary, until design settles on chart color palette
export const chartColors = {
  [ChartTypes.COLUMN]: [
    '#077AB0',
    '#62B3ED',
    '#66BC64',
    '#FD9000',
    '#E6DF00',
    '#6AAFCF',
    '#A0D1F4',
    '#A3D6A2',
    '#FDBC66',
    '#F0EB66',
  ],
  [ChartTypes.GAUGE]: {
    red: '#E77383',
    yellow: '#FFC95C',
    green: '#8CCF94',
  },
};
