import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAppVariantFromAppId } from '../../components/app-store/document-ai/commons/AppFrame';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AppIdType,
  BodyRegisterAppDocumentV3,
  CreateGenAIProjectRequest,
  DocumentDownloadFormats,
  InviteGenAIProjectRequest,
  RemoveInviteGenAIProjectRequest,
} from '../../generated/api';
import { appStoreApi } from '../../lib/api/reno';

export const documentAIKeys = {
  all: ['app-store-apps'] as const,
  appProjectList: (workspaceId: string, appId: string) =>
    [...documentAIKeys.all, workspaceId, 'project', appId] as const,
  appProjectDocsList: (workspaceId: string, appId: string, projectId: string) =>
    [...documentAIKeys.appProjectList(workspaceId, appId), projectId] as const,
};

export const useDocumentDownloadMutation = (appId: AppIdType, documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((downloadFormat: DocumentDownloadFormats) =>
    appStoreApi.getDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, {
      // TODO: Create a background task to download docx
      timeout: 2 * 60 * 1000, // 2 Min
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    }),
  );
};

export const useGetAppProjectsListQuery = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useQuery(
    documentAIKeys.appProjectList(workspaceId, appId),
    () => appStoreApi.fetchGenAIProjectsV1(workspaceId, appIdType),
    {
      select: data => data.data.projects,
      enabled: Boolean(workspaceId),
    },
  );
};

export const useCreateAppProjectMutation = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: CreateGenAIProjectRequest) =>
      appStoreApi.createGenAIProjectV1(workspaceId, appIdType, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
      },
    },
  );
};

export const useGetAppProjectDocumentListQuery = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useQuery(
    documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
    () => appStoreApi.getAppDocumentsListV2(workspaceId, appIdType, projectId),
    {
      select: data => data.data,
    },
  );
};

export interface DocumentRegistrationMutationProps {
  documentId: string;
  requestBody?: BodyRegisterAppDocumentV3;
}

export const useAppProjectRegisterDocMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: DocumentRegistrationMutationProps) =>
      appStoreApi.registerAppDocumentV3(
        workspaceId,
        req.documentId,
        projectId,
        appIdType,
        req.requestBody,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
        );
      },
    },
  );
};

export const useAppProjectInviteUserMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: InviteGenAIProjectRequest) =>
      appStoreApi.inviteGenAIProjectV1(workspaceId, appIdType, projectId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appIdType, projectId),
        );
      },
    },
  );
};

export const useAppProjectRevokeUserMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: RemoveInviteGenAIProjectRequest) =>
      appStoreApi.removeUserFromGenAIProjectV1(workspaceId, appIdType, projectId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appIdType, projectId),
        );
      },
    },
  );
};

export const useDeleteDocumentMutation = (appId: string, projectId: string, documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => appStoreApi.deleteDocumentV1(workspaceId, documentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
      queryClient.invalidateQueries(
        documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
      );
    },
  });
};
