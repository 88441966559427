import { CellProps } from 'react-table';
import { ExperimentWithTags } from '../../../generated/api';
import { ExperimentStatus } from '../ExperimentStatus';

export const getExperimentStatusColumn = (width: number) => ({
  Header: 'Status',
  accessor: 'runStatus',
  width: width,
  canResize: false,
  disableFilters: true,
  Cell: ({ value: runStatus }: CellProps<ExperimentWithTags>) => (
    <ExperimentStatus runStatus={runStatus} />
  ),
});
