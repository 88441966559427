import { AddCredentialRequest, StorageType } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { DEFAULT_ERROR_MESSAGE } from '../../../queries/constants';
import { useAddDataSourceCredential } from '../../../queries/datasets/registration';
import { AddEditCredentialModal, CredentialRequest } from './AddEditCredentials';

interface AddCredentialContainerProps {
  initialName?: string;
  onClose: (selectedCredential?: string, datastore?: string) => void;
}

// TODO: Replace with openConfirmModal or openModal from Mantine
export const AddCredentialsContainer = ({
  initialName,
  onClose,
}: AddCredentialContainerProps): JSX.Element => {
  const {
    mutateAsync: onAddNewCredential,
    isLoading,
    data: dataOnSuccess,
    isSuccess,
    error,
    reset,
  } = useAddDataSourceCredential();

  const handleCloseModal = () => {
    const credentialId = dataOnSuccess?.data.response.credentialId;
    const datastore = dataOnSuccess?.data.response.provider;
    reset();
    onClose(credentialId, datastore);
  };

  const handleSaveNewCredential = ({ data, name, description, provider }: CredentialRequest) => {
    if (name && data) {
      const request: AddCredentialRequest = {
        data: data ?? {},
        name: name ?? '',
        description: description ?? '',
        provider: (provider as StorageType) ?? StorageType.S3,
      };
      onAddNewCredential(request);
      return;
    }
    logger.error(
      `Save new credentials called without a name, access key/access secret: ${name}, ${data}`,
    );
  };

  const errorMessage = error
    ? error.response?.data.message?.description ?? DEFAULT_ERROR_MESSAGE
    : '';

  return (
    <AddEditCredentialModal
      isSavingCredentials={isLoading}
      isSavingCredentialsSuccess={isSuccess}
      errorMessage={errorMessage}
      onSaveCredentials={handleSaveNewCredential}
      onCloseModal={handleCloseModal}
      initialName={initialName}
    />
  );
};
