import { useMemo } from 'react';
import { Alert, Center, IconAlertCircle, Image, Loader } from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { useGetAppProjectDocumentListQuery } from '../../../../queries/document-ai/document';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { EmptyState } from '../../../common/EmptyState';
import { AppTable } from '../commons/AppTable';

interface CopyEditAppContainerProps {
  projectId: string;
  searchFilter?: string;
}

export const CopyEditAppContainer = ({ projectId, searchFilter }: CopyEditAppContainerProps) => {
  const { getCopyEditDetailRoute } = useAbsoluteRoutes();
  const { isLoading, error, data } = useGetAppProjectDocumentListQuery(
    AppIdType.CopyEdit,
    projectId,
  );

  const handleNavigation = (documentId: string, projectId?: string) =>
    getCopyEditDetailRoute(documentId, projectId ?? '');

  const filteredDocumentList = useMemo(
    () =>
      (data?.documentList ?? []).filter(({ documentName }) =>
        documentName.toLowerCase().includes(searchFilter ?? ''),
      ),
    [data?.documentList, searchFilter],
  );

  if (isLoading) {
    return (
      <Center h={400} aria-live="polite">
        <Loader text="Loading documents..." />
      </Center>
    );
  }

  if (error || !data) {
    return (
      <Alert
        icon={<IconAlertCircle size={14} />}
        color="red"
        title="Unable to fetch the list of documents"
        mt={48}
        role="alert"
      >
        Something seems wrong. Please contact support or refresh page!
      </Alert>
    );
  }

  if (data.documentList.length === 0) {
    return (
      <Center h={400}>
        <EmptyState
          title="No documents created yet"
          subtitle="Get started by importing an existing document or creating one from scratch."
          icon={
            <Image src="https://ik.imagekit.io/markovml/app-store/copy-edit/no-documents_uSNKgG7HE.svg?updatedAt=1726084322119" />
          }
          iconWrapperSize={120}
        />
      </Center>
    );
  }

  return (
    <AppTable
      variant={AppIdType.CopyEdit}
      documentsList={filteredDocumentList}
      projectId={projectId}
      navigateRoute={handleNavigation}
    />
  );
};
