import { useMutation, useQueryClient } from 'react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { UpdateDataOperationRequest } from '../../../generated/api';
import { datasetOperationApi } from '../../../lib/api';
import { dataLabelingQueryKeys } from '../list/list';

export const useUpdateDataLabelingMutation = (datasetId: string, dataLabelingId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateDataOperationRequest) =>
      datasetOperationApi.updateDataOperationV1(workspaceId, datasetId, dataLabelingId, req),
    {
      onSuccess: () => queryClient.invalidateQueries(dataLabelingQueryKeys.all),
    },
  );
};
