import { IconInfoCircle } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { copyEditEvents } from '../../../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Alert,
  Card,
  Center,
  Horizontal,
  Image,
  Loader,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { toPlural } from '../../../../../lib/ui';
import { useGetWorkspaceMembersQuery } from '../../../../../queries/account/workspace';
import { useGetAppProjectsListQuery } from '../../../../../queries/document-ai/document';
import { EmptyState } from '../../../../common/EmptyState';
import { SharedWithUsers } from '../../../../common/user-avatar-group/SharedWithUsers';
import { useAppProjectStyle } from './AppProjects.style';

export interface AppProjectsProps {
  appId: string;
  searchFilter?: string;
}

export const AppProjects = ({ appId, searchFilter }: AppProjectsProps): JSX.Element => {
  const navigate = useNavigate();
  const { isLoading, data } = useGetWorkspaceMembersQuery();
  const { classes } = useAppProjectStyle();
  const { workspaceId } = useAppMetadata();

  const {
    isLoading: isLoadingProjects,
    error: errorLoadingProjects,
    data: projects = [],
  } = useGetAppProjectsListQuery(appId);

  const handleNavigateProjectDetails = (projectId: string) => () => {
    sendAnalytics(
      copyEditEvents.project.click({
        workspaceId: workspaceId,
        projectId,
      }),
    );
    navigate(projectId);
  };

  const filteredProjects = useMemo(
    () =>
      projects.filter(({ projectName }) =>
        projectName.toLocaleLowerCase().includes(searchFilter ?? ''),
      ),
    [projects, searchFilter],
  );

  if (isLoadingProjects) {
    return <Loader text="Loading your projects..." />;
  }

  if (errorLoadingProjects) {
    return (
      <Center>
        <Alert
          p={24}
          icon={<IconInfoCircle size={14} />}
          color="red"
          title="Unable to load projects"
        >
          Looks like there is some issue with loading projects. Please refresh or contact Support!
        </Alert>
      </Center>
    );
  }

  if (!projects || !projects.length) {
    return (
      <Center h={400}>
        <EmptyState
          title="No projects created yet"
          subtitle="Get started by creating a new project to organize your documents."
          icon={
            <Image src="https://ik.imagekit.io/markovml/app-store/copy-edit/no-projects_KRQvaBQDkh.svg" />
          }
          iconWrapperSize={120}
        />
      </Center>
    );
  }

  return (
    <Horizontal>
      {filteredProjects.map(project => (
        <Card
          key={project.projectId}
          data-testid={`copy-edit-projectName-${project.projectName}`}
          p={16}
          w={260}
          mih={135}
          className={classes.card}
          onClick={handleNavigateProjectDetails(project.projectId)}
        >
          <Vertical spacing="apart">
            <Vertical spacing={0} mb={28}>
              <Text variant="subTitle02" truncate title={project.projectName}>
                {project.projectName}
              </Text>

              <Text variant="subTitle05" color="gray.6">
                {project.countOfDocuments
                  ? toPlural(project.countOfDocuments, 'file')
                  : 'No files yet'}
              </Text>
            </Vertical>
            <SharedWithUsers
              isLoading={isLoading}
              allUsers={data?.workspaceMembers ?? []}
              userIdsWithAccess={project.invitedUsers?.map(user => user.userId) ?? []}
              currentActiveUserIds={project.invitedUsers?.map(user => user.userId) ?? []}
              size={28}
            />
          </Vertical>
        </Card>
      ))}
    </Horizontal>
  );
};
