import { Text, Vertical } from '../../../../../design-system/v2';
import { AppIdType } from '../../../../../generated/api';
import { useAppProjectStyle } from '../../commons/app-project/AppProjects.style';
import { AnalyticsTabContainer } from './AnalyticsTab.container';

export const AnalyticsTabWrapper = (): JSX.Element => {
  const { classes } = useAppProjectStyle();

  return (
    <Vertical className={classes.mainPanel}>
      <Text variant="subTitle01" color="gray.7">
        Analytics
      </Text>
      <Text variant="bodyShort03" color="gray.8">
        Statistics will be based on all documents edited by members across all your
        organization&#39;s teams.
      </Text>
      <AnalyticsTabContainer appVariant={AppIdType.CopyEdit} />
    </Vertical>
  );
};
