import { AutocompleteItem } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { ActionIcon, Autocomplete } from '../../design-system/v2';

interface ColumnAutocompleteProps {
  columns: string[];
  onSelectColumn: (columnId: string) => void;
}

export const ColumnAutocomplete = ({
  columns,
  onSelectColumn,
}: ColumnAutocompleteProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleClearQuery = () => {
    setSearchQuery('');
  };

  const handleAutocompleteItemClick = (item: AutocompleteItem) => {
    const column = item.value;
    onSelectColumn(column);
  };

  const columnOptions = columns.map(col => ({ value: col, label: col }));

  return (
    <Autocomplete
      value={searchQuery}
      onChange={setSearchQuery}
      onItemSubmit={handleAutocompleteItemClick}
      size="sm"
      w={240}
      placeholder="Search columns"
      options={columnOptions}
      rightSection={
        searchQuery && (
          <ActionIcon size="xs" onClick={handleClearQuery}>
            <IconX />
          </ActionIcon>
        )
      }
      styles={{
        input: {
          fontSize: 14,
        },
      }}
    />
  );
};
