import { ValueGetterParams } from '@ag-grid-community/core';
import { ColDef } from '../../../design-system/v2/core/data-display/table/ag-grid';
import {
  dateFilterParams,
  numberFilterParams,
  textFilterParams,
} from '../../../design-system/v2/core/data-display/table/filters/util';
import { Dataset } from '../../../queries/datasets/list';
import { CreateDateRenderer } from '../../common/table/cell-renderers/CreateDateRenderer';
import { DataFamilyRenderer } from '../../datasets-table/v2/cell-renderers/DataFamilyRenderer';
import { DataQualityRenderer } from '../../datasets-table/v2/cell-renderers/DataQualityRenderer';
import { TagsRenderer } from '../../datasets-table/v2/cell-renderers/TagsRenderer';
import { TitleRenderer } from '../../datasets-table/v2/cell-renderers/TitleRenderer';
import { TypeRenderer } from '../../datasets-table/v2/cell-renderers/TypeRenderer';
import { DataFamilyFilter } from '../../datasets-table/v2/filters/data-family/DataFamilyFilter';
import { TagsFilter } from '../../datasets-table/v2/filters/tags/TagsFilter';
import { TypeFilter } from '../../datasets-table/v2/filters/type/TypeFilter';

export const allDatasetSelectionColumns: ColDef<Dataset>[] = [
  {
    colId: 'name',
    field: 'name',
    filter: false,
    sortable: false,
    filterParams: textFilterParams,
    cellRenderer: TitleRenderer,
    flex: 1,
    minWidth: 160,
  },
  {
    colId: 'dataType',
    field: 'dataType',
    headerName: 'Type',
    sortable: false,
    cellRenderer: TypeRenderer,
    width: 216,
    filter: TypeFilter,
  },
  {
    colId: 'dataQualityScore',
    field: 'dataQualityScore',
    headerName: 'Data quality',
    filter: false,
    sortable: false,
    cellRenderer: DataQualityRenderer,
    filterParams: numberFilterParams,
    width: 160,
    filterValueGetter: (p: ValueGetterParams<Dataset>) => {
      if (!p.data?.dataQualityScore || p.data.dataQualityScore === -1) {
        return -1;
      }

      return p.data.dataQualityScore * 100;
    },
  },
  {
    colId: 'createDate',
    field: 'createDate',
    headerName: 'Registered',
    cellRenderer: CreateDateRenderer,
    filter: false,
    sortable: false,
    filterParams: dateFilterParams,
    width: 160,
  },
  {
    colId: 'dataFamily',
    field: 'dataFamily',
    headerName: 'Family',
    sortable: false,
    cellRenderer: DataFamilyRenderer,
    filter: DataFamilyFilter,
    width: 140,
  },
  {
    colId: 'tags',
    field: 'tags',
    headerName: 'Tags',
    filter: TagsFilter,
    sortable: false,
    cellRenderer: TagsRenderer,
    flex: 1,
    minWidth: 180,
  },
];
