import { IconPlus, IconSearch, IconUserPlus } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { copyEditEvents } from '../../../../analytics/copyEditEvents';
import {
  Box,
  Button,
  Flex,
  Horizontal,
  TextInput,
  Vertical,
  useDebouncedValue,
  useInputState,
} from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { sendAnalytics } from '../../../../initializers/analytics';
import { useGetWorkspaceMembersQuery } from '../../../../queries/account/workspace';
import {
  useAppProjectInviteUserMutation,
  useAppProjectRevokeUserMutation,
  useGetAppProjectDocumentListQuery,
} from '../../../../queries/document-ai/document';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { UserAvatarGroup } from '../../../common/UserAvatarGroup';
import { WorkspaceShare } from '../../../common/share/WorkspaceShare';
import { AppDetailsHeaderLayout } from '../commons/AppDetailsHeaderLayout';
import { PageInfo } from '../commons/PageInfo';
import { useAppProjectStyle } from '../commons/app-project/AppProjects.style';
import { useDocumentUploadModal } from '../commons/useDocumentUploadModal';
import { CopyEditAppContainer } from './CopyEditApp.container';

export const CopyEditAppWrapper = (): JSX.Element => {
  const { appId = '', projectId = '' } = useParams();
  const { getCopyEditRoute } = useAbsoluteRoutes();

  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 200);

  const { data: workspaceMembers } = useGetWorkspaceMembersQuery();

  const { open: openDocumentUploadModal } = useDocumentUploadModal(AppIdType.CopyEdit, projectId);
  const { isLoading, data } = useGetAppProjectDocumentListQuery(AppIdType.CopyEdit, projectId);

  const { mutateAsync: inviteUser } = useAppProjectInviteUserMutation(appId, projectId);
  const { mutateAsync: revokeUser } = useAppProjectRevokeUserMutation(appId, projectId);

  const { classes } = useAppProjectStyle();

  const inviteMember = async (userIds: string[]) => await inviteUser({ inviteUsers: userIds });
  const removeInvitedMember = async (userId: string) => await revokeUser({ removeUsers: [userId] });

  const sharedWithUsersId = (data?.projectMetadata?.invitedUsers ?? []).map(({ userId }) => userId);
  const shareWithUsers = (workspaceMembers?.workspaceMembers ?? []).filter(({ userId }) =>
    sharedWithUsersId.includes(userId),
  );
  const handleInviteUserClick = () => {
    sendAnalytics(
      copyEditEvents.documents.inviteClicked({
        workspaceId: data?.projectMetadata?.workspaceId ?? '',
        projectId,
      }),
    );
  };

  return (
    <Vertical className={classes.mainPanel}>
      <AppDetailsHeaderLayout>
        <PageInfo
          parentTitle="Projects"
          title={data?.projectMetadata?.projectName ?? ''}
          backButtonRoute={getCopyEditRoute()}
          isMetadataLoading={isLoading}
        />
      </AppDetailsHeaderLayout>

      <Horizontal position="apart">
        <TextInput
          value={searchFilter}
          onChange={setSearchFilter}
          ariaLabel="Search projects"
          placeholder="Search..."
          icon={<IconSearch size={18} />}
        />
        <Flex justify="center" gap="sm">
          <Box pt="xs">
            <UserAvatarGroup users={shareWithUsers} showActive size={28} />
          </Box>
          <WorkspaceShare
            sharedWithUsers={sharedWithUsersId}
            onShare={inviteMember}
            onRemoveUser={removeInvitedMember}
            entityName={`${data?.projectMetadata?.projectName} project`}
            projectId={projectId}
            targetElement={
              <Button
                aria-label="Invite user"
                leftIcon={<IconUserPlus />}
                variant="outline"
                color="gray"
                onClick={handleInviteUserClick}
              >
                Invite
              </Button>
            }
          >
            {/* TODO: Add once Share with workspace API is ready */}
            {/* <Vertical p="md">
              <ShareProjectWithWorkspaceContainer projectId={projectId} />
            </Vertical> */}
          </WorkspaceShare>
          <Button
            variant="outline"
            color="blue"
            leftIcon={<IconPlus />}
            aria-label="Add document"
            onClick={() => {
              openDocumentUploadModal();
              sendAnalytics(
                copyEditEvents.documents.addDocument({
                  workspaceId: data?.projectMetadata?.workspaceId ?? '',
                  projectId,
                }),
              );
            }}
          >
            Add Document
          </Button>
        </Flex>
      </Horizontal>

      <CopyEditAppContainer
        projectId={projectId}
        searchFilter={debouncedSearchFilter.toLocaleLowerCase()}
      />
    </Vertical>
  );
};
