import { createContext, PropsWithChildren, useContext, useRef } from 'react';
import { HighchartsReact } from '../providers/highcharts';

interface ChartContext {
  chartRef: React.RefObject<HighchartsReact.RefObject>;
}

const defaultValue = {
  chartRef: {
    current: null,
  },
};

const ChartsContext = createContext<ChartContext>(defaultValue);

export const useChartsContext = () => useContext(ChartsContext);

export const ChartsProvider = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <ChartsContext.Provider
      value={{
        chartRef: chartRef,
      }}
    >
      {children}
    </ChartsContext.Provider>
  );
};
