import { IconTrash } from '@tabler/icons-react';
import { Comment as IconComment } from '../../../../../Icons';
import { ActionIcon, Card, Horizontal } from '../../../../core';
import { useGridItemStyles } from './ChartGrid.styles';

interface ItemActionsProps {
  // TODO:: fix item type
  item: any;
  onCommentClick: (id: string, conversationId?: string) => void;
  onDelete: (id: string, conversationId?: string) => void;
  readOnlyMode?: boolean;
}

export const ItemActions = ({
  onCommentClick,
  onDelete,
  item,
  readOnlyMode = false,
}: ItemActionsProps) => {
  const { classes } = useGridItemStyles();

  const handleCommentClick = () => {
    onCommentClick(item.layout.i, item.layout.conversationId);
  };

  const handleDelete = () => {
    onDelete(item.layout.i, item.layout.conversationId);
  };

  return (
    <Card pos="absolute" top="-37px" right="0" p="xs" className={classes.actions}>
      <Horizontal spacing="xs">
        <ActionIcon onClick={handleCommentClick}>
          <IconComment />
        </ActionIcon>
        {!readOnlyMode && (
          <ActionIcon onClick={handleDelete}>
            <IconTrash />
          </ActionIcon>
        )}
      </Horizontal>
    </Card>
  );
};
