import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ErrorBoundary, ErrorFallback } from '../../ErrorBoundary';
import { globalEvents } from '../../analytics';
import { DashboardWidgetsContainer } from '../../components/workspace/dashboard/snapshot/DashboardWidgets.container';
import { DashboardSnapshotContextProvider } from '../../components/workspace/home/snapshot/DashboardSnapshotContext';
import { Container } from '../../design-system/v2';
import { sendAnalytics } from '../../initializers/analytics';
import { cleanErrorsInSearchParams } from '../../lib/router';
import { AppRoutes, getRoute } from '../../router/constants';
import { useShowSideBar } from '../home/WorkspaceShell';

export const WorkspaceHomePage = () => {
  const { workspaceId } = useParams();

  useShowSideBar(false);

  useEffect(() => {
    if (workspaceId) {
      sendAnalytics(
        globalEvents.dashboardViewed({
          workspaceId,
        }),
      );
    }
  }, [workspaceId]);

  if (!workspaceId) {
    return (
      <Navigate
        to={{
          pathname: getRoute(AppRoutes.BASE_ROUTE),
          search: cleanErrorsInSearchParams(window.location.search),
        }}
      />
    );
  }

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <Container pt={32} pb={56} size="xl">
        <DashboardSnapshotContextProvider>
          <DashboardWidgetsContainer />
        </DashboardSnapshotContextProvider>
      </Container>
    </ErrorBoundary>
  );
};
