import { useMarkovTheme } from '../../../design-system/v2';
import { useGetWorkspaceDetailsQuery } from '../../../queries/account/workspace';
import { NotificationsDrawerContainer } from './NotificationsDrawer.container';

export interface NotificationDrawerWrapperProps {
  onClosePopover: (opened: boolean) => void;
}

export const NotificationDrawerWrapper = ({
  onClosePopover,
}: NotificationDrawerWrapperProps): JSX.Element => {
  const { data } = useGetWorkspaceDetailsQuery();
  const theme = useMarkovTheme();

  return <NotificationsDrawerContainer onClosePopover={onClosePopover} />;
};
