import { useState } from 'react';
import { Alert, Center, IconAlertCircle, Loader, Vertical } from '../../design-system/v2';
import { DEFAULT_PAGE_SIZE } from '../../design-system/v2/core/data-display/table/util';
import { ArtifactStateType, BaseAPIFilter, WorkspaceMember } from '../../generated/api';
import {
  getDefaultLabelingFilters,
  useGetDataLabelingListQuery,
} from '../../queries/data-labeling/list/list';
import { DataLabelingList } from './DataLabelingList';
import { EmptyDataLabelingList } from './EmptyDataLabelingList';

interface DataLabelingListContainerProps {
  state: ArtifactStateType;
  workspaceMembers: WorkspaceMember[];
  filters: BaseAPIFilter[];
}

export const DataLabelingListContainer = ({
  state,
  workspaceMembers,
  filters,
}: DataLabelingListContainerProps): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1);

  const { isLoading, error, data } = useGetDataLabelingListQuery({
    filters: [...getDefaultLabelingFilters(state), ...filters],
    start: (pageNumber - 1) * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
  });

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  if (isLoading) {
    return (
      <Center h={400}>
        <Vertical>
          <Loader text="Loading data labeling operations..." />
        </Vertical>
      </Center>
    );
  }

  if (error || !data) {
    return (
      <Alert
        icon={<IconAlertCircle size={14} />}
        color="red"
        title="Unable to fetch the list of documents"
        mt={48}
      >
        {error?.response?.data.detail ??
          'Something seems wrong. Please contact support or refresh page!'}
      </Alert>
    );
  }

  if (data.numRecords === 0) {
    return <EmptyDataLabelingList />;
  }

  return (
    <DataLabelingList
      state={state}
      data={data.response}
      totalRecords={data.numRecords}
      workspaceMembers={workspaceMembers}
      pageNumber={pageNumber}
      handlePageNumberChange={handlePageNumberChange}
    />
  );
};
