import { useEffect, useState } from 'react';
import { useResizeObserver } from '../../../hooks';
import { HoverCard, HoverCardProps } from '../../overlays/hover-card';
import { Text, TextProps } from '../../typography';
import { ScrollArea } from '../scroll-area';

export const DEFAULT_OPEN_DELAY = 500;
export const DEFAULT_CLOSE_DELAY = 0;
export const HOVER_CARD_DROPDOWN_MAX_WIDTH = 550;
export const HOVER_CARD_DROPDOWN_MAX_HEIGHT = 400;

export interface EllipsisTextProps extends TextProps {
  children: string;
  openDelay?: HoverCardProps['openDelay'];
  closeDelay?: HoverCardProps['closeDelay'];
}

export const EllipsisText = ({
  children: text,
  openDelay = DEFAULT_OPEN_DELAY,
  closeDelay = DEFAULT_CLOSE_DELAY,
  ...rest
}: EllipsisTextProps) => {
  const [ref, rect] = useResizeObserver();
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsOverflowed(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [rect.width]);

  return (
    <HoverCard
      disabled={!isOverflowed}
      withinPortal
      width="fit-content"
      openDelay={openDelay}
      closeDelay={closeDelay}
    >
      <HoverCard.Target>
        <Text ref={ref} truncate {...rest}>
          {text}
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown bg="dark.7" sx={{ border: 'none' }}>
        <ScrollArea.Autosize
          maw={HOVER_CARD_DROPDOWN_MAX_WIDTH}
          mah={HOVER_CARD_DROPDOWN_MAX_HEIGHT}
          type="auto"
        >
          <Text variant="bodyLong02" color="white.0">
            {text}
          </Text>
        </ScrollArea.Autosize>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
