import { DataSegment, RecordMetaData, SegmentType } from '../generated/api';

// This is needed to load images hosted on Google drive from an external site.
// Eventually we'll move this logic to the backend.
const transformUrl = (url: string): string => {
  const driveFilePattern = /drive\.google\.com\/file\/d\/(?<id>[^\/]+)/;
  const match = url.match(driveFilePattern);
  const id = match?.groups?.id;
  return id ? `https://drive.google.com/uc?export=view&id=${id}` : url;
};

export interface MetadataSummary {
  imageUrl?: string[];
  text?: string[];
  uri?: string[];
  label?: string[];
}

/**
 * Transforms an array of metadata properties into a POJO
 * @param {Object[]} metadata - array of metadata properities
 * @returns {Object} object with metadata keys and values
 */
export const getMetadataSummary = (metadata: RecordMetaData[] = []): MetadataSummary =>
  metadata.reduce((obj, item) => {
    switch (item.metaType) {
      case 'imageUrl':
        return {
          ...obj,
          imageUrl: transformUrl(item.value),
          label: item.label,
        };
      case 'text':
        return { ...obj, text: item.value };
      case 'uri':
        return { ...obj, uri: item.value };
      default:
        return obj;
    }
  }, {});

const orderedSegmentTypes: SegmentType[] = [
  SegmentType.All,
  SegmentType.Train,
  SegmentType.Test,
  SegmentType.Validate,
  SegmentType.Unknown,
];

export const getSortedSegments = (segments: DataSegment[]) =>
  [...segments].sort(
    (segmentA, segmentB) =>
      orderedSegmentTypes.indexOf(segmentA.segmentType) -
      orderedSegmentTypes.indexOf(segmentB.segmentType),
  );

export const getSortedSegmentTypes = (segmentTypes: SegmentType[]) =>
  [...segmentTypes].sort(
    (segmentTypeA, segmentTypeB) =>
      orderedSegmentTypes.indexOf(segmentTypeA) - orderedSegmentTypes.indexOf(segmentTypeB),
  );
