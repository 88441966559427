import { CSSObject, MantineTheme } from '@mantine/core';

export const dropdown = {
  border: '1px solid #EAEDF2',
};

export const itemsWrapper = {
  padding: 0,
};

export const getItemStyles = (theme: MantineTheme) =>
  ({
    borderRadius: 0,

    '&[data-hovered]': {
      backgroundColor: theme.fn.themeColor('white.1'),
    },
    '&[data-selected]': {
      backgroundColor: theme.fn.primaryColor(),
    },
  } as CSSObject);
