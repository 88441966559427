import {
  ControlProps,
  OwnPropsOfEnum,
  RankedTester,
  and,
  isOneOfControl,
  optionIs,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsOneOfEnumProps } from '@jsonforms/react';
import { Radio, Text, Vertical } from '../../../../../design-system/v2';

const RadioControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  schema,
  uischema,
  data,
  enabled,
  options = [],
  config,
}: ControlProps & OwnPropsOfEnum) => {
  const onChange = (selectedValue: string) => {
    handleChange(path, selectedValue);
  };

  const descriptions = schema.oneOf?.map(item => item.description) ?? [];

  const isViewOnlyForm = config.viewOnly;

  return (
    <Radio.Group
      aria-label={label}
      label={
        <Text span variant="subTitle04">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      }
      value={data}
      onChange={onChange}
      required={required}
    >
      <Vertical spacing="lg" pt="sm">
        {options.map((item, idx) => (
          <Radio
            key={idx}
            value={item.value}
            label={
              <Text span variant="subTitle04" color="gray.7">
                {item.label}
              </Text>
            }
            description={
              <Text span variant="small02" color="gray.7" pb="sm">
                {descriptions[idx]}
              </Text>
            }
            disabled={!isViewOnlyForm && !enabled}
            readOnly={isViewOnlyForm}
          />
        ))}
      </Vertical>
    </Radio.Group>
  );
};

export const radioControlTester: RankedTester = rankWith(
  9,
  and(isOneOfControl, optionIs('format', 'radio')),
);

export const RadioControl = withJsonFormsOneOfEnumProps(RadioControlComponent);
