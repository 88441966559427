import { useState } from 'react';
import { WorkspaceRBAC } from '../../../contexts/rbac/RBAC';
import { Button, Horizontal, Tabs, Text } from '../../../design-system/v2';
import { WorkspaceMembershipRoles } from '../../../generated/api';
import { useGetWorkspaceMembersQuery } from '../../../queries/account/workspace';
import { openWorkspaceInviteModal } from '../../user-invitation/useOpenInviteModal';
import { SettingsSection } from '../SettingsSection';
import { InvitationContainer } from './Invitation.container';
import { InvitesTab } from './InvitesTab';
import { MembersContainer } from './Members.container';

export enum MemberTabs {
  ACTIVE = 'active',
  INVITES = 'invites',
}

const MembersHeader = (): JSX.Element => {
  const { data } = useGetWorkspaceMembersQuery();

  return (
    <Horizontal m={0} w="100%" position="apart">
      <Text variant="subTitle02">Members {data ? `(${data.membersCount})` : ''}</Text>

      <WorkspaceRBAC roles={[WorkspaceMembershipRoles.Owner, WorkspaceMembershipRoles.Maintainer]}>
        <Button variant="outline" onClick={openWorkspaceInviteModal}>
          Invite member
        </Button>
      </WorkspaceRBAC>
    </Horizontal>
  );
};

export const Members = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string | null>(MemberTabs.ACTIVE);

  return (
    <SettingsSection title={<MembersHeader />}>
      <Tabs mt="md" value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab size="sm" value={MemberTabs.ACTIVE}>
            Active
          </Tabs.Tab>
          <WorkspaceRBAC
            roles={[WorkspaceMembershipRoles.Owner, WorkspaceMembershipRoles.Maintainer]}
          >
            <InvitesTab activeTab={activeTab} />
          </WorkspaceRBAC>
        </Tabs.List>
        <Tabs.Panel value={MemberTabs.ACTIVE} pt="md">
          <MembersContainer />
        </Tabs.Panel>
        <WorkspaceRBAC
          roles={[WorkspaceMembershipRoles.Owner, WorkspaceMembershipRoles.Maintainer]}
        >
          <Tabs.Panel value={MemberTabs.INVITES} pt="md">
            <InvitationContainer />
          </Tabs.Panel>
        </WorkspaceRBAC>
      </Tabs>
    </SettingsSection>
  );
};
