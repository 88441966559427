import { IconInfoCircle } from '@tabler/icons-react';
import { Navigate } from 'react-router-dom';
import { Loader } from '../../../../design-system';
import { Alert, closeAllModals, notifications } from '../../../../design-system/v2';
import { ModelWithAppMetadata } from '../../../../generated/api';
import {
  useGenerateModelAppFromModel,
  useModelAppDetailsQuery,
} from '../../../../queries/model-apps/model-apps';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { GenerateAppModal } from './GenerateAppModal';

interface GenerateAppModalContainerProps {
  modelId: string;
  isRestart: boolean;
  projectId?: string;
}

export const GenerateAppFromModelContainer = ({
  modelId,
  isRestart,
  projectId = '',
}: GenerateAppModalContainerProps) => {
  const { getModelAppDetailsRoute } = useAbsoluteRoutes();
  const {
    data: modelAppDetails,
    isLoading: isDetailsLoading,
    error: modelAppDetailsError,
  } = useModelAppDetailsQuery({
    modelId,
  });

  const {
    mutateAsync: generateApp,
    isLoading: isGeneratingApp,
    isSuccess: isAppGeneratedSuccess,
  } = useGenerateModelAppFromModel(modelId, projectId);

  if (isAppGeneratedSuccess) {
    closeAllModals();
    return <Navigate to={getModelAppDetailsRoute(modelId)} />;
  }

  if (isDetailsLoading) {
    return <Loader text="Loading model details..." />;
  }

  if (modelAppDetailsError || !modelAppDetails) {
    return (
      <Alert
        color="red"
        icon={<IconInfoCircle size={14} />}
        title="Unable to load model details to be able to create an app."
      >
        Please try after sometime. If the issue persists, please contact Support
      </Alert>
    );
  }

  const generateModelApp = ({ appName, duration }: { appName: string; duration: number }) => {
    generateApp({ appName, durationInMins: duration, modelId, isRestart }).catch(error => {
      const errorMsg = error.response.data.detail ?? 'Not able to generate the app';
      notifications.show({ variant: 'error', message: errorMsg });
    });
  };

  const appDetails: ModelWithAppMetadata = { ...modelAppDetails, modelId };

  return (
    <GenerateAppModal
      modelAppDetails={appDetails}
      generateModelApp={generateModelApp}
      isAppGenerating={isGeneratingApp}
    />
  );
};
