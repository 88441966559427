import { ColDef } from '@ag-grid-community/core';
import { useMemo } from 'react';
import { Table } from '../../../../../design-system/v2/core/data-display/table';
import { AnalysisDataPoint, AnalysisMetadata } from '../../../../../generated/api';
import { useAnalyserDataQuery } from '../../../../../queries/datasets/analysis/analysis';

const columns: ColDef<any>[] = [
  {
    colId: 'topic',
    field: 'topic',
    filter: false,
    sortable: false,
    resizable: false,
    tooltipField: 'topic',
  },
  {
    colId: 'explanation',
    field: 'explanation',
    filter: false,
    sortable: false,
    flex: 2,
    resizable: false,
    tooltipField: 'explanation',
  },
];

interface TopicModalExplanationProps {
  analysis: AnalysisMetadata;
}

export const TopicModalExplanation = ({ analysis }: TopicModalExplanationProps) => {
  const { data, isLoading, isError } = useAnalyserDataQuery(
    analysis.datasetId ?? '',
    analysis.analysisId ?? '',
  );

  const tableData = useMemo(
    () =>
      data?.data.series.flatMap(serie => {
        if (serie.data.length > 0) {
          return serie.data.map((datum, idx) => ({
            topic: data?.data?.categories && data?.data?.categories[idx],
            explanation: (datum as AnalysisDataPoint)?.metadata?.info,
          }));
        }

        return [];
      }),
    [data?.data],
  );

  return <Table columns={columns} rowData={tableData} />;
};
