import { useFlags } from 'launchdarkly-react-client-sdk';
import { Container } from '../../design-system/v2';
import { VerticalSettingsTab } from './VerticalSettingsTab';
import { AboutContainer } from './about/About.container';
import { ConnectorsContainer } from './connectors/Connectors.container';
import { CredentialContainer } from './credentials/Credential.container';
import { Integrations } from './integrations/Integrations';
import { Members } from './members/Members';
import { TokenWrapper } from './token/Token.wrapper';

export enum TabComponentsType {
  ABOUT = 'about',
  MEMBERS = 'members',
  CREDENTIALS = 'credentials',
  CONNECTORS = 'connectors',
  TOKENS = 'token',
  INTEGRATIONS = 'integrations',
}

export interface TabComponents {
  id: TabComponentsType;
  name: string;
  component: JSX.Element;
}

export const Settings = (): JSX.Element => {
  const { featureConnectors } = useFlags();

  const tabComponents: TabComponents[] = [
    {
      id: TabComponentsType.ABOUT,
      name: 'About',
      component: <AboutContainer />,
    },
    {
      id: TabComponentsType.MEMBERS,
      name: 'Members',
      component: <Members />,
    },
    featureConnectors
      ? {
          id: TabComponentsType.CONNECTORS,
          name: 'Connectors',
          component: <ConnectorsContainer />,
        }
      : {
          id: TabComponentsType.CREDENTIALS,
          name: 'Credentials',
          component: <CredentialContainer />,
        },
    {
      id: TabComponentsType.TOKENS,
      name: 'Tokens',
      component: <TokenWrapper />,
    },
    {
      id: TabComponentsType.INTEGRATIONS,
      name: 'Integrations',
      component: <Integrations />,
    },
  ];

  return (
    <Container size="sm" mb="sm">
      <VerticalSettingsTab tabComponents={tabComponents} />
    </Container>
  );
};
