import { ActionIcon } from '@mantine/core';
import { MantineSize } from '@mantine/styles';
import { IconX } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { Badge } from '../../..';
import { getButtonSize, useTagStyles } from './Tag.style';

interface TagProps {
  name: string;
  size?: MantineSize;
  color?: string;
  bgColor?: string;
  borderColor?: string;
  maw?: number;
  selected?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

export const Tag = ({
  name,
  size = 'md',
  color,
  bgColor,
  borderColor,
  selected = false,
  maw,
  onClick,
  onRemove,
}: TagProps) => {
  const { classes } = useTagStyles({
    size,
    color,
    backgroundColor: bgColor,
    borderColor,
    maw,
    selected,
    clickable: !!onClick,
  });

  return (
    <Badge
      variant={selected ? 'filled' : 'outline'}
      size={size}
      onClick={onClick ?? noop}
      classNames={classes}
      title={name}
      rightSection={
        onRemove ? (
          <ActionIcon
            size={size}
            radius="xl"
            variant="transparent"
            mr={-8}
            onMouseDown={onRemove}
            sx={theme => ({ color: selected ? theme.colors.white[0] : theme.colors.dark[4] })}
          >
            <IconX size={getButtonSize(size)} />
          </ActionIcon>
        ) : null
      }
    >
      {name}
    </Badge>
  );
};
