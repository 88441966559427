import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import { ConfusionMatrixChartData } from '../generated/api';
import { recordingsApi } from '../lib/api';
import { useChartQuery } from './charts-query-wrapper';
import { EVALUATION } from './queryConstants';

export const useConfusionMatrixQuery = (recordingId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    [EVALUATION.GET_EVAL_CONFUSION_MATRIX, workspaceId, recordingId],
    () => recordingsApi.workspaceGetConfusionMatrixForRecordingV1(workspaceId, recordingId),
    {
      select: data => data.data.response as ConfusionMatrixChartData,
    },
  );
};
