import noop from 'lodash/noop';
import { MarkovStarsPink } from '../../../../../design-system';
import { Box, Horizontal, Skeleton, Vertical } from '../../../../../design-system/v2';
import { DataSourceConversationModel } from '../../../../../generated/api';
import { getAnswerContent, getAnswerContentToCopy } from '../answers/factory';
import { usePrismStyles, useSkeletonStyles } from './AnswerView.style';
import { AnswerViewFooter } from './AnswerViewFooter';

interface AnswerViewProps {
  conversation: DataSourceConversationModel & {
    isLoading?: boolean;
    isError?: boolean;
    errorMessage?: string;
  };
  onExecuteSql?: (sqlQuery: string) => void;
  isExecuteDisabled?: boolean;
}

export const AnswerView = ({
  conversation,
  onExecuteSql = noop,
  isExecuteDisabled,
}: AnswerViewProps) => {
  const { classes: skeletonClasses } = useSkeletonStyles();
  const { classes: prismClasses } = usePrismStyles();

  const answer = getAnswerContent(
    conversation.isError ? undefined : conversation,
    onExecuteSql,
    isExecuteDisabled,
    prismClasses,
  );

  const answerToCopy = !conversation.isError ? getAnswerContentToCopy(conversation) : null;

  return (
    <Horizontal maw="100%" align="start" noWrap>
      <Box>
        <MarkovStarsPink />
      </Box>
      {!conversation.isLoading ? (
        <Vertical pt="sm" pb="md" px="md" w="100%" sx={{ borderRadius: '8px' }}>
          {answer}
          <AnswerViewFooter answerToCopy={answerToCopy} />
        </Vertical>
      ) : (
        <Vertical h="100%" w="100%" spacing={12}>
          <Skeleton height={8} w="90%" radius="sm" className={skeletonClasses.root} />
          <Skeleton height={8} w="90%" radius="sm" className={skeletonClasses.root} />
          <Skeleton height={8} w="60%" radius="sm" className={skeletonClasses.root} />
        </Vertical>
      )}
    </Horizontal>
  );
};
