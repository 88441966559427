import { closeAllModals, openModal } from '@mantine/modals';
import { Button, Horizontal, Text, Vertical, notifications } from '../../../../design-system/v2';
import { logger } from '../../../../initializers/logging';
import { useExportTemplateMutation } from '../../../../queries/workflows/operators';

export interface ExportWorkflowInputModalProps {
  workflowId: string;
}
const HEADING = 'Export as template';
const SUBHEADING = `You can save the workflow as template, including the source file, operator configurations, and sink destination.`;

const ExportWorkflowInputModal = ({ workflowId }: ExportWorkflowInputModalProps): JSX.Element => {
  const { mutateAsync: exportWorkflow, isLoading: isExporting } = useExportTemplateMutation();
  const displayState = 'Export';
  const displayName = 'workflow';

  const handleConfirmExporting = async () => {
    try {
      const data = await exportWorkflow({ workflowIds: [workflowId] });
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };
  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>
      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="light" color="gray.7" bg="gray.1">
          Cancel
        </Button>
        <Button
          onClick={handleConfirmExporting}
          loading={isExporting}
          variant="primary"
          color="blue"
        >
          Export
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useWorkflowExport = (workflowId: string) => {
  const openExportModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      children: <ExportWorkflowInputModal workflowId={workflowId} />,
    });

  return { openExportModal };
};
