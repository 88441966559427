import { CitationStyleGuideType } from '../../../../../generated/api';

export const REFERENCE_PAGE_PARAM = 'refs';

export const MISSING_DATE_TEXT = 'n.d';

export enum DocumentCitationPosition {
  INTEXT = 'INTEXT',
  LIST = 'LIST',
}

export const DEFAULT_CITATION_STYLE = CitationStyleGuideType.Cmos;
