import { v4 as uuid } from 'uuid';
import { SnippetAddCommentProperties } from '../../../../../ampli';
import { snippetEvents } from '../../../../../analytics';
import { useSnippetDetail } from '../../../../../components/snippets/detail/context/SnippetContext';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { sendAnalytics } from '../../../../../initializers/analytics';

const sendEvents = (
  snippetId: string,
  workspaceId: string,
  snippetCommand: SnippetAddCommentProperties['snippetCommand'] = 'DATASETS',
) => {
  const command = ['DATASETS', 'EXPERIMENTS', 'EVALUATIONS', 'MODEL_APPS'].find(command =>
    snippetCommand?.startsWith(command),
  ) as SnippetAddCommentProperties['snippetCommand'];

  sendAnalytics(
    snippetEvents.snippet.addComment({
      snippetId: snippetId,
      workspaceId,
      snippetCommand: command,
    }),
  );
};

export const useAddComment = () => {
  const { snippetId, setActiveConversation } = useSnippetDetail();
  const { userId: currentUserId, workspaceId } = useAppMetadata();

  const handleSetActiveConversation = (conversationId?: string, chartId?: string) => {
    if (conversationId) {
      setActiveConversation({ conversationId, userId: currentUserId });
      return;
    }

    const newConversationId = uuid();
    setActiveConversation({ conversationId: newConversationId, userId: currentUserId });
    sendEvents(snippetId, workspaceId, chartId as SnippetAddCommentProperties['snippetCommand']);
    return newConversationId;
  };

  return { handleSetActiveConversation };
};
