import {
  Card,
  Checkbox,
  Popover,
  ScrollArea,
  Text,
  UnstyledButton,
  Vertical,
} from '../../design-system/v2';
import { DataOperationType, DatasetOperationState, WorkspaceMember } from '../../generated/api';

interface DataLabelingSidePanelProps {
  workspaceMembers: WorkspaceMember[];
  handleFiltersChange: (
    id: string,
    value: DataOperationType | DatasetOperationState | string,
  ) => void;
}

const DISPLAY_FILTERS_LENGTH = 6;

const dataLabelingFilters = [
  {
    id: 'type',
    title: 'Type',
    items: [
      {
        id: DataOperationType.ManualRelabeling,
        label: 'Manual Labeling',
      },
      {
        id: DataOperationType.RuleBasedRelabeling,
        label: 'Rule-based Labeling',
      },
      {
        id: DataOperationType.SubsetRelabeling,
        label: 'Auto Labeling',
      },
    ],
  },
  {
    id: 'state',
    title: 'Status',
    items: [
      {
        id: DatasetOperationState.Completed,
        label: 'Finished',
      },
      {
        id: DatasetOperationState.Failed,
        label: 'Error',
      },
      {
        id: DatasetOperationState.Ongoing,
        label: 'Ongoing',
      },
      {
        id: DatasetOperationState.Processing,
        label: 'Processing',
      },
    ],
  },
];

export const DataLabelingSidePanel = ({
  workspaceMembers,
  handleFiltersChange,
}: DataLabelingSidePanelProps): JSX.Element => (
  // const allFilters = [
  //   ...DataLabelingFilters,
  //   {
  //     id: 'createdBy',
  //     title: 'By',
  //     description: 'List of user who',
  //     items: workspaceMembers.map(member => ({
  //       id: member.userId,
  //       label: <UserInfoContainer userId={member.userId} />,
  //     })),
  //   },
  // ];
  <Card w={208} h="100%" px="lg">
    <ScrollArea h="100%" offsetScrollbars>
      <Vertical spacing="xl" pt="xl">
        {dataLabelingFilters.map(filter => (
          <Vertical key={filter.id} spacing="md">
            <Text variant="subTitle04" color="gray.7">
              {filter.title}
            </Text>
            {filter.items.slice(0, DISPLAY_FILTERS_LENGTH).map(filterItem => (
              <Checkbox
                key={filterItem.id}
                size="sm"
                label={filterItem.label}
                onChange={() => handleFiltersChange(filter.id, filterItem.id)}
              />
            ))}
            {filter.items.length > DISPLAY_FILTERS_LENGTH && (
              <Popover width="fit-content" position="right" withArrow shadow="md" withinPortal>
                <Popover.Target>
                  <UnstyledButton>
                    <Text variant="textLink" color="blue.6">
                      +{filter.items.length - DISPLAY_FILTERS_LENGTH} more
                    </Text>
                  </UnstyledButton>
                </Popover.Target>
                <Popover.Dropdown>
                  <ScrollArea h="400px" offsetScrollbars>
                    <Vertical spacing="sm">
                      {filter.items.slice(DISPLAY_FILTERS_LENGTH).map(filterItem => (
                        <Checkbox
                          key={filterItem.id}
                          size="sm"
                          label={filterItem.label}
                          onChange={() => handleFiltersChange(filter.id, filterItem.id)}
                        />
                      ))}
                    </Vertical>
                  </ScrollArea>
                </Popover.Dropdown>
              </Popover>
            )}
          </Vertical>
        ))}
      </Vertical>
    </ScrollArea>
  </Card>
);
