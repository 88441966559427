import { memo } from 'react';
import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { AppIdType } from '../../../../generated/api';
import { UnavailablePage } from '../../../../pages/Unavailable';
import { renderAuthRoute } from '../../../../router/AppRouter';
import { AppRoutes, getRoute } from '../../../../router/constants';
import { AppProjectsWrapper } from '../commons/app-project/AppProjects.wrapper';
import { CopyEditAppWrapper } from './CopyEditApp.wrapper';
import { AnalyticsTabWrapper } from './analytics/AnalyticsTab.wrapper';
import { SettingsTabs } from './settings/SettingsTabs';

const copyEditInternalRoutes: RouteProps[] = [
  {
    path: '/',
    element: <AppProjectsWrapper appId={AppIdType.CopyEdit} />,
  },
  {
    path: getRoute(AppRoutes.APP_STORE_SETTINGS),
    element: <SettingsTabs />,
  },
  {
    path: getRoute(AppRoutes.COPY_EDIT_ANALYTICS),
    element: <AnalyticsTabWrapper />,
  },
  {
    path: getRoute(AppRoutes.APP_STORE_PROJECT),
    element: <CopyEditAppWrapper />,
  },

  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

const AppStoreInternalRouter = (): JSX.Element => (
  <Routes>{copyEditInternalRoutes.map(renderAuthRoute)}</Routes>
);

export const CopyEditInternalAppRouter = memo(AppStoreInternalRouter);
