import { IconTarget } from '@tabler/icons-react';
import { AnalyzingSpinner } from '../../design-system';
import { useMarkovTheme } from '../../design-system/v2';
import { DataOperationType, DatasetOperationState } from '../../generated/api';
import { StyledBubbles } from '../common/TableWrapper';
import { StyledWarning } from '../datasets-table/DatasetsTable.style';

export type RelabelingOperationType =
  | DataOperationType.ManualRelabeling
  | DataOperationType.RuleBasedRelabeling
  | DataOperationType.SubsetRelabeling;

export const useDetailsFromDataLabelingType = (type: DataOperationType) => {
  const theme = useMarkovTheme();

  switch (type) {
    case DataOperationType.ManualRelabeling:
      return {
        title: 'Manual Labeling',
        color: theme.colors.purple[5],
      };
    case DataOperationType.RuleBasedRelabeling:
      return {
        title: 'Rule Based Labeling',
        color: theme.colors.orange[5],
      };
    case DataOperationType.SubsetRelabeling:
    default:
      return {
        title: 'Auto-Labeling',
        color: theme.colors.seaGreen[8],
      };
  }
};

export const useStatusComponentInfo = (status: DatasetOperationState) => {
  const theme = useMarkovTheme();

  switch (status) {
    case DatasetOperationState.Completed:
      return {
        icon: <StyledBubbles width={24} />,
        text: 'Finished',
      };
    case DatasetOperationState.Processing:
      return {
        icon: <AnalyzingSpinner width={24} />,
        text: 'Processing',
      };
    case DatasetOperationState.Ongoing:
      return {
        icon: <IconTarget width={24} color={theme.colors.green[7]} />,
        text: 'Ongoing',
      };
    case DatasetOperationState.Failed:
      return {
        icon: <StyledWarning height={24} width={24} />,
        text: 'Error',
      };
    default:
      return null;
  }
};
