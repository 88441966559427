import { PropsWithChildren } from 'react';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { Box, Button, Horizontal, Tabs, useMarkovTheme } from '../../../../../design-system/v2';
import { useSnippetDetail } from '../../context/SnippetContext';
import { toPlural } from '../../../../../lib/ui';

interface HeaderProps {
  tabs: string[];
}

export const Header = ({ tabs, children }: PropsWithChildren<HeaderProps>): JSX.Element => {
  const { onAddCharts } = useSnippetDetail();
  const { selectedCharts } = useAddChartsContext();
  const theme = useMarkovTheme();

  const handleAddCharts = () => {
    onAddCharts(selectedCharts);
  };

  return (
    <Box
      bg={theme.colors.gray[0]}
      p={12}
      sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}
    >
      <Horizontal position="apart">
        <Tabs.List mb={5}>
          {tabs.map(tab => (
            <Tabs.Tab key={`tab-${tab}`} size="sm" value={tab}>
              {tab}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {selectedCharts.length > 0 && (
          <Button
            onClick={handleAddCharts}
            variant="primary"
            color="green.6"
            shape="rounded"
            sx={theme => ({ boxShadow: theme.shadows.md })}
          >
            + Add {toPlural(selectedCharts.length, 'item')}
          </Button>
        )}
      </Horizontal>
      {children}
    </Box>
  );
};
