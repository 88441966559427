import { IconArrowNarrowRight, IconPlus } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps, Row } from 'react-table';
import { datasetEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { DataTable } from '../../../../../design-system';
import { Horizontal, LinkButton, Text, Vertical } from '../../../../../design-system/v2';
import { DatasetWithTags } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { formatDateLong, formatDateTime } from '../../../../../lib/ui';
import { AnalysisStatusData, Dataset } from '../../../../../queries/datasets/list';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { TableRowName } from '../../../../common/TableRowName';
import { DatasetState } from '../../../../datasets-table/components/DatasetState';

const getTableCols = () => [
  {
    Header: 'Dataset Name',
    accessor: 'name',
    width: 150,
    Cell: ({ value: name, row }: CellProps<DatasetWithTags>) => (
      <Vertical spacing="xs">
        <TableRowName isSampleRecord={row.original.demoArtifact}>
          <Text
            variant="subTitle03"
            color="dark.4"
            lineClamp={1}
            data-testid="datasets-table-datasetName"
            title={name}
          >
            {name}
          </Text>
        </TableRowName>
        <Text variant="small02" color="gray.6">
          {row.original.dataType}
        </Text>
      </Vertical>
    ),
  },
  {
    Header: 'Registered on',
    accessor: 'createDate',
    width: 110,
    disableFilters: true,
    Cell: ({ value: createDate }: CellProps<Dataset>) => (
      <Text variant="bodyShort03" color="gray.7" title={formatDateTime(createDate)}>
        {createDate ? formatDateLong(createDate) : ''}
      </Text>
    ),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: 'analysis',
    width: 190,
    canResize: false,
    disableFilters: true,
    Cell: ({ value }: CellProps<DatasetWithTags>) => (
      <DatasetState analysisStatus={value as AnalysisStatusData} />
    ),
  },
];

interface DatasetsPreviewProps {
  datasets: Dataset[];
}

export const DatasetsPreview = ({ datasets }: DatasetsPreviewProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getDatasetDetailsRoute, getDatasetsRoute, getDatasetRegistrationRoute } =
    useAbsoluteRoutes();

  const columns = useMemo(() => getTableCols(), []);

  const handleNavigateDataset = (datasetId: string) => {
    const route = getDatasetDetailsRoute(datasetId);
    navigate(route);
  };

  const getTableRowProps = (row: Row<Record<string, unknown>>) => ({
    isClickable: true,
    onClick: () => {
      if ((row.original as Dataset).analysis?.isUploading) {
        // Must be a workflow (when dataset is uploading)
        return;
      }
      handleNavigateDataset(row.original.datasetId as string);
    },
  });

  const handleClickNewDataset = () => {
    sendAnalytics(
      datasetEvents.register.addClicked({
        workspaceId,
        sourcePage: 'DASHBOARD_DATASET_PREVIEW_LIST',
      }),
    );
  };

  const handleClickViewAllDatasets = () => {
    sendAnalytics(
      datasetEvents.list.listViewed({
        workspaceId,
      }),
    );
  };

  return (
    <Vertical h="100%" spacing={0} justify="space-between" p="lg">
      <DataTable
        dataTestId="datasets-table"
        data={datasets}
        columns={columns}
        getRowProps={getTableRowProps}
        displayConfig={{ hideHeaders: true }}
      />
      <Horizontal position="apart" px="sm" pt="lg">
        <LinkButton
          variant="primary"
          shape="sharp"
          to={getDatasetRegistrationRoute()}
          w="100%"
          size="sm"
          leftIcon={<IconPlus size={20} stroke={1.5} />}
          onClick={handleClickNewDataset}
        >
          <Text variant="subTitle03">New dataset</Text>
        </LinkButton>
        <LinkButton
          variant="subtle"
          shape="sharp"
          size="sm"
          to={getDatasetsRoute()}
          rightIcon={<IconArrowNarrowRight stroke={1.5} />}
          w="100%"
          onClick={handleClickViewAllDatasets}
        >
          <Text variant="textLink" color="blue.7">
            View all datasets
          </Text>
        </LinkButton>
      </Horizontal>
    </Vertical>
  );
};
