import { PointOptionsObject, TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface HeatmapTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const HeatmapTooltip = ({ formatterContext }: HeatmapTooltipProps) => {
  const point = formatterContext.point as PointOptionsObject;
  // Note: heatmap displays inverted axes, so points along a column share an x-value
  const [colLabel, rowLabel] = ([point.x, point.y] as number[]).map(
    index => formatterContext.point.series.xAxis.categories[index] ?? '',
  );

  return (
    <TooltipContainer>
      <TooltipHeader>{formatterContext.series.name}</TooltipHeader>
      <TooltipItemRow>{point.value}</TooltipItemRow>
      <TooltipItemRow>
        <span>Row:</span> {rowLabel}
      </TooltipItemRow>
      <TooltipItemRow>
        <span>Column:</span> {colLabel}
      </TooltipItemRow>
    </TooltipContainer>
  );
};
