import first from 'lodash/first';
import Papa from 'papaparse';

const MAX_PREVIEW_LINES = 10;
const CHUNK_SIZE = 60000000; //60MB

interface ParseCSVOptions {
  maxPreviewLines: number;
}

export const parseCSV = (
  file: File,
  delimiter: string,
  opts?: ParseCSVOptions,
): Promise<string[][]> =>
  new Promise((resolve, reject) => {
    const slicedFile = new File([file.slice(0, CHUNK_SIZE)], file.name);
    Papa.parse(slicedFile, {
      preview: opts?.maxPreviewLines ?? MAX_PREVIEW_LINES,
      delimiter: delimiter,
      escapeChar: '\\',
      complete: (results: Papa.ParseResult<string[]>) => {
        resolve(results.data);
      },
      error: (err: Error) => {
        reject(err);
      },
    });
  });

export const parseCSVString = (csvString: string[], delimiter: string): Promise<string[][]> =>
  Promise.all(
    csvString.map(
      csvStringRow =>
        new Promise((resolve, reject) => {
          Papa.parse<string[]>(csvStringRow, {
            escapeChar: '\\',
            delimiter,
            complete: (results: Papa.ParseResult<string[]>) => {
              resolve(first(results.data) ?? []);
            },
            error: (err: Error) => {
              reject(err);
            },
          });
        }) as Promise<string[]>,
    ),
  );
