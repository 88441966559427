import { Text, UnstyledButton, closeAllModals, openModal } from '../../../../design-system/v2';
import { SlackConfiguration } from './SlackConfiguration';

interface SlackConfigureModalProps {
  slackChannelId: string;
}

export const SlackConfigureModal = ({ slackChannelId }: SlackConfigureModalProps): JSX.Element => {
  const handleCloseModal = () => {
    closeAllModals();
  };

  const openSlackConfigureModal = () => {
    openModal({
      title: <Text variant="subTitle02">Configure slack </Text>,
      size: 'lg',
      children: <SlackConfiguration slackChannelId={slackChannelId} onClose={handleCloseModal} />,
    });
  };

  return (
    <UnstyledButton onClick={openSlackConfigureModal}>
      <Text variant="textLink" color="gray.6">
        Configure
      </Text>
    </UnstyledButton>
  );
};
