import { AxiosResponse } from 'axios';
import { useQueries } from 'react-query';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  ComputedCustomMetricsForRecording,
  EvaluatedCustomMetrics,
  GetComputedCustomMetricsForRecordingResponse,
} from '../generated/api';
import { recordingsApi } from '../lib/api';
import { useChartQuery } from './charts-query-wrapper';
import { EVALUATION } from './queryConstants';

const customMetricsSelector = (
  data: AxiosResponse<GetComputedCustomMetricsForRecordingResponse>,
): EvaluatedCustomMetrics[] => {
  const response = data.data.response as ComputedCustomMetricsForRecording;
  return response.customMetrics ?? [];
};

export const useCustomMetricsQuery = (recordingId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    [EVALUATION.GET_EVAL_CUSTOM_METRICS, workspaceId, recordingId],
    () => recordingsApi.workspaceGetComputedCustomMetricsForRecordingV1(workspaceId, recordingId),
    {
      select: customMetricsSelector,
    },
  );
};

export const useCustomMetricsQueries = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useQueries(
    evaluationIds.map(evaluationId => ({
      queryKey: [EVALUATION.GET_EVAL_CUSTOM_METRICS, workspaceId, evaluationId],
      queryFn: () =>
        recordingsApi.workspaceGetComputedCustomMetricsForRecordingV1(workspaceId, evaluationId),
      select: customMetricsSelector,
      enabled: Boolean(workspaceId && evaluationId),
    })),
  );
};
