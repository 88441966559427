import upperFirst from 'lodash/upperFirst';
import { Box, Horizontal, Progress, Text, Tooltip } from '../../../design-system/v2';
import { IMetricDetail } from '../../../queries/metrics';

interface ClassMetricsRowProps {
  className: string;
  metrics: IMetricDetail[];
}

export const ClassMetricsRow = ({ className, metrics }: ClassMetricsRowProps): JSX.Element => (
  <Horizontal key={className} grow align="flex-start" spacing="xl" p="md">
    <Box>
      <Text variant="subTitle03" color="gray.7" lineClamp={1} title={className}>
        {className}
      </Text>
    </Box>
    {metrics.map(({ name: metricName, value }) => {
      const percentageValue = 100 * value;
      const displayedValue = value.toFixed(3);
      const tooltipText = `${upperFirst(
        metricName,
      )} for class "${className}" was ${displayedValue} `;

      return (
        <Tooltip key={metricName} label={tooltipText} withArrow withinPortal multiline maw={375}>
          <Progress size="xl" value={percentageValue} label={displayedValue} sx={{ flexGrow: 1 }} />
        </Tooltip>
      );
    })}
  </Horizontal>
);
