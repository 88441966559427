import { Text, closeAllModals, openModal } from '../../../../design-system/v2';
import GeneratePromptModalContainer from './GeneratePromptModal.container';

export const useGeneratePromptModal = () => {
  const open = (appId: string, onGeneratedPromptSelect: (response: string) => void) =>
    openModal({
      title: (
        <Text variant="heading04" p="sm">
          Generate prompt
        </Text>
      ),
      size: 'xl',
      children: (
        <GeneratePromptModalContainer
          onGeneratedPromptSelect={onGeneratedPromptSelect}
          closeModal={closeAllModals}
          appId={appId}
        />
      ),
    });

  return { open };
};
