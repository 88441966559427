import { Button, Horizontal, MultiSelect } from '../../../design-system/v2';
import { WorkspaceMember } from '../../../generated/api';

interface UserSelectProps {
  currentUserId: string;
  users: WorkspaceMember[];
  sharedWithUsers?: string[];
  onShare: () => void;
  onUserSelect: (userIds: string[]) => void;
  selectedUsers: string[];
  isLoading: boolean;
}

export const UserSelect = ({
  currentUserId,
  users,
  sharedWithUsers,
  onShare,
  onUserSelect,
  selectedUsers,
  isLoading,
}: UserSelectProps) => {
  const usersToSelect = users
    .filter(user => {
      if (user.userId === currentUserId || sharedWithUsers?.includes(user.userId)) {
        return false;
      }

      return true;
    })
    .map(user => ({
      label: user.userDetails?.name,
      value: user.userId,
    }));

  return (
    <Horizontal position="apart" noWrap>
      <MultiSelect
        value={selectedUsers}
        onChange={onUserSelect}
        data={usersToSelect}
        searchable
        placeholder="Select members"
        w={`calc(100% - ${!isLoading ? '88px' : '108px'})`}
      />
      <Button
        variant="primary"
        shape="sharp"
        onClick={onShare}
        loading={isLoading}
        disabled={(selectedUsers?.length ?? 0) === 0}
      >
        Share
      </Button>
    </Horizontal>
  );
};
