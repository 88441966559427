import {
  IconAtom,
  IconBooks,
  IconBuilding,
  IconFile3d,
  IconInfinity,
  IconStack,
} from '@tabler/icons-react';
import {
  Box,
  Horizontal,
  LinkAnchor,
  Text,
  ThemeIcon,
  Vertical,
} from '../../../../design-system/v2';
import { BasicUserDetails, WorkspaceActivityType } from '../../../../generated/api';
import { MarkovResource, markovResourceColorMapping } from '../../../../lib/resource-map';
import { getHumanizedTimeFromNow } from '../../../../lib/time-format';
import { WorkspaceActivity } from '../../../../queries/account/activity';
import { getUserProfilePath } from '../../../../router/constants';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { useActivityStyle } from './Activity.style';

interface ActivityTextProps {
  userInfo: BasicUserDetails;
  activityContent: string;
  resourceContent?: React.ReactNode;
  resourceContentRoute?: string;
}

const ActivityText = ({
  userInfo,
  activityContent,
  resourceContent,
  resourceContentRoute,
}: ActivityTextProps): JSX.Element => (
  <Text variant="subTitle04" color="dark.6" lineClamp={2}>
    <LinkAnchor variant="subTitle04" target="_blank" to={getUserProfilePath(userInfo.userId ?? '')}>
      {userInfo.username}
    </LinkAnchor>
    <Text span variant="bodyShort03">
      {activityContent}
    </Text>
    {resourceContentRoute ? (
      <LinkAnchor variant="subTitle04" target="_blank" to={resourceContentRoute}>
        {resourceContent}
      </LinkAnchor>
    ) : (
      resourceContent
    )}
  </Text>
);

interface ActivityProps {
  activity: WorkspaceActivity;
}

export const ActivityItem = ({ activity }: ActivityProps) => {
  const { classes } = useActivityStyle();
  const {
    getEvaluationDetailsRoute,
    getExperimentDetailsRoute,
    getDatasetDetailsRoute,
    getModelDetailsRoute,
    getProjectDetailsRoute,
  } = useAbsoluteRoutes();

  let activityContent = <></>;
  let resourceType = MarkovResource.OTHERS;
  let icon = <IconBuilding size={20} />;

  if (activity.activityType === WorkspaceActivityType.UserJoinedWorkspace) {
    activityContent = (
      <ActivityText userInfo={activity.userInfo} activityContent="&#32;joined this workspace 🎉" />
    );
  }

  if (activity.activityType === WorkspaceActivityType.ModelCreated) {
    icon = <IconFile3d stroke={1.5} />;
    resourceType = MarkovResource.PROJECTS;
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;created the model&#32;"
        resourceContentRoute={getModelDetailsRoute(
          activity.activityDetails.modelInfo?.projectId ?? '',
          activity.activityDetails.modelInfo?.modelId ?? '',
        )}
        resourceContent={activity.activityDetails.modelInfo?.name}
      />
    );
  }

  if (activity.activityType === WorkspaceActivityType.ProjectCreated) {
    icon = <IconBooks stroke={1.25} />;
    resourceType = MarkovResource.PROJECTS;
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;created the project&#32;"
        resourceContentRoute={getProjectDetailsRoute(
          activity.activityDetails.projectInfo?.projectId ?? '',
        )}
        resourceContent={activity.activityDetails.projectInfo?.name}
      />
    );
  }

  if (activity.activityType === WorkspaceActivityType.EvaluationRecordingCreated) {
    icon = <IconInfinity stroke={1.5} />;
    resourceType = MarkovResource.EVALUATIONS;
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;ran the evaluation&#32;"
        resourceContentRoute={getEvaluationDetailsRoute(
          activity.activityDetails.evaluationRecordingInfo?.evaluationId ?? '',
        )}
        resourceContent={activity.activityDetails.evaluationRecordingInfo?.name}
      />
    );
  }

  if (activity.activityType === WorkspaceActivityType.DatasetRegistered) {
    icon = <IconStack stroke={1.5} />;
    resourceType = MarkovResource.DATASETS;
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;registered the dataset&#32;"
        resourceContentRoute={getDatasetDetailsRoute(
          activity.activityDetails.datasetInfo?.datasetId ?? '',
        )}
        resourceContent={activity.activityDetails.datasetInfo?.name}
      />
    );
  }

  if (activity.activityType === WorkspaceActivityType.ExperimentRecordingCreated) {
    icon = <IconAtom stroke={1.5} />;
    resourceType = MarkovResource.EXPERIMENTS;
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;started the experiment&#32;"
        resourceContentRoute={getExperimentDetailsRoute(
          activity.activityDetails.experimentRecordingInfo?.projectId ?? '',
          activity.activityDetails.experimentRecordingInfo?.experimentId ?? '',
        )}
        resourceContent={activity.activityDetails.experimentRecordingInfo?.name}
      />
    );
  }

  if (activity.activityType === WorkspaceActivityType.TeamCreated) {
    activityContent = (
      <ActivityText
        userInfo={activity.userInfo}
        activityContent="&#32;created the team&#32;"
        resourceContent={activity.activityDetails.teamInfo?.teamName}
      />
    );
  }

  return (
    <Horizontal noWrap className={classes.activityItem}>
      <ThemeIcon size={32} color={markovResourceColorMapping[resourceType]} radius="xl">
        {icon}
      </ThemeIcon>
      <Vertical spacing={0}>
        <Text variant="small02" color="dark.2">
          {getHumanizedTimeFromNow(activity.createDate)} ago
        </Text>
        <Box>{activityContent}</Box>
      </Vertical>
    </Horizontal>
  );
};
