import { useMediaQuery } from '@mantine/hooks';
import {
  IconApps,
  IconArrowsSplit2,
  IconBox,
  IconCompass,
  IconPresentation,
  IconSettingsBolt,
  IconSpaces,
  IconSparkles,
  IconUserPlus,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { ampli } from '../../../ampli';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../../contexts/layout/LayoutContext';
import { IconWhatsNew } from '../../../design-system';
import { ActionIcon, Navbar, Vertical, useMarkovTheme } from '../../../design-system/v2';
import { purples } from '../../../design-system/v2/theme/colors/colorList';
import { WHATS_NEW_FLOW, startFlow } from '../../../initializers/userflow';
import { FeatureFamily } from '../../../router/constants';
import { useAbsoluteRoutes, useGetHomeRoute } from '../../../router/hooks';
import { openWorkspaceInviteModal } from '../../user-invitation/useOpenInviteModal';
import { SideBarOptions } from '../sidebar-v2/SidebarLinks';
import { PrimaryNavbarLink } from './PrimaryNavbarLink';
import { NAV_BAR_COLLAPSED_SIZE } from './Sidebar';
import { SidebarMenu } from './SidebarMenu';

export const PrimaryNavbar = (): JSX.Element => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const getHomeRoute = useGetHomeRoute();
  const { getSettingsRoute } = useAbsoluteRoutes();
  const { activeSection, setActiveSection, workspaceId } = useAppMetadata();
  const {
    featureSnippets,
    featureGlobalHomepage,
    featureModelStudio,
    featureWorkflows,
    featureAppStore,
    featureGenaiAppBuilder,
    featureWhatsNew,
  } = useFlags();
  const { setSidebarExpanded } = useLayoutContext();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const navigateTo = (link: string) => {
    if (isMobileScreen) {
      setSidebarExpanded(false);
    }

    navigate(link);
  };

  const handleChange = (nextValue: string) => {
    if (nextValue !== activeSection) {
      setActiveSection(nextValue as FeatureFamily);
      navigate(getHomeRoute(nextValue as FeatureFamily));
    }
  };

  const studioElements = [
    {
      id: FeatureFamily.WORKFLOWS,
      label: 'Workflows',
      icon: <IconArrowsSplit2 />,
      isVisible: !!featureWorkflows,
    },
    {
      id: FeatureFamily.APP_STORE,
      label: 'App Store',
      icon: <IconApps />,
      isVisible: !!featureAppStore,
    },
    {
      id: FeatureFamily.APP_BUILDER,
      label: 'App Builder',
      icon: <IconSparkles />,
      isVisible: !!featureGenaiAppBuilder,
    },
    {
      id: FeatureFamily.DATA_STUDIO,
      label: 'Data Studio',
      icon: <IconBox />,
    },
    {
      id: FeatureFamily.SNIPPETS,
      label: 'Snippets',
      icon: <IconPresentation />,
      isVisible: !!featureSnippets,
    },
  ]
    .filter(({ isVisible = true }) => isVisible)
    .map(item => ({ ...item, onClick: () => handleChange(item.id) }));

  const groups = [
    {
      properties: { grow: true },
      elements: studioElements,
    },
  ];

  const viewMoreMenuItems = [
    ...(featureModelStudio
      ? [
          {
            id: FeatureFamily.MODEL_STUDIO,
            label: 'Model Studio',
            icon: <IconSpaces />,
          },
        ]
      : []),
    {
      id: FeatureFamily.SNIPPETS,
      label: 'Snippets',
      icon: <IconPresentation />,
    },
    ...(featureGenaiAppBuilder
      ? [
          {
            id: FeatureFamily.APP_BUILDER,
            label: 'App Builder',
            icon: <IconSparkles />,
          },
        ]
      : []),
  ].map(item => ({ ...item, onClick: () => handleChange(item.id) }));

  const docsAndSupportMenu = [
    ...(featureWhatsNew
      ? [
          {
            id: SideBarOptions.WHATS_NEW,
            label: "What's new",
            onClick: () => {
              ampli.navbarWhatsnewClicked({ workspaceId });
              startFlow(WHATS_NEW_FLOW);
            },
            icon: <IconWhatsNew />,
          },
        ]
      : []),
    {
      id: SideBarOptions.SETTINGS,
      label: 'Settings',
      onClick: () => {
        ampli.navbarSettingsClicked();
        navigateTo(getSettingsRoute());
      },
      icon: <IconSettingsBolt />,
    },
  ];

  return (
    <Navbar p={0} withBorder={false} w={NAV_BAR_COLLAPSED_SIZE} pos="static">
      <Vertical spacing={0} h="100%" bg="dark.7">
        {groups.map(({ properties, elements }, index) => (
          <Navbar.Section key={`navGroup-${index}`} grow={properties.grow}>
            {elements.map((element, idx) => (
              <PrimaryNavbarLink key={`element-${idx}`} {...element} />
            ))}
          </Navbar.Section>
        ))}
        {/* <Navbar.Section grow={true}>
          <SidebarMenu
            id={SideBarOptions.VIEW_MORE}
            label="View More"
            icon={<IconDots />}
            menuItems={viewMoreMenuItems}
          />
        </Navbar.Section> */}
        {featureGlobalHomepage && (
          <Navbar.Section grow={false}>
            <PrimaryNavbarLink
              id={FeatureFamily.GLOBAL_HOME}
              label="MarkovML Home"
              onClick={() => handleChange(FeatureFamily.GLOBAL_HOME)}
              icon={<IconCompass />}
            />
          </Navbar.Section>
        )}
        <Navbar.Section grow={false}>
          <PrimaryNavbarLink
            id={SideBarOptions.INVITE_MEMBERS}
            label="Invite members"
            onClick={() => {
              ampli.navbarInviteMembersClicked({ workspaceId });
              openWorkspaceInviteModal();
            }}
            icon={
              <ActionIcon radius="xl" size="md" color="purple.6" variant="filled" p={4}>
                <IconUserPlus color={purples.purple0} />
              </ActionIcon>
            }
          />
        </Navbar.Section>
        <Navbar.Section grow={false}>
          <SidebarMenu
            id={SideBarOptions.DOCS_AND_SUPPORT}
            label="Docs & Settings"
            icon={<IconSettingsBolt />}
            menuItems={docsAndSupportMenu}
          />
        </Navbar.Section>
      </Vertical>
    </Navbar>
  );
};
