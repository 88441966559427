import { Grid, Horizontal, Text, Vertical } from '../../../../design-system/v2';
import { BaseUser } from '../../../../generated/api';
import { WorkspaceCard } from '../../home/WorkspaceCard';
import { ActivitiesContainer } from '../../home/activity/Activities.container';
import { WidgetType, defaultDashboardOrder } from '../util';
import { DatasetsPreviewContainer } from './datasets/DatasetsPreview.container';
import { EvaluationsPreviewContainer } from './evaluations/EvaluationsPreview.container';
import { ExperimentsPreviewContainer } from './experiments/ExperimentsPreview.container';
import { ModelAppsPreviewContainer } from './model-apps/ModelAppsPreview.container';
import { SnippetsPreviewContainer } from './snippets/SnippetsPreview.container';

export interface UserDetailsInfo {
  dashboardOrder?: WidgetType[];
}

const dashboardWidgets: Record<string, () => JSX.Element> = {
  DATASETS: DatasetsPreviewContainer,
  MODELAPPS: ModelAppsPreviewContainer,
  EVALUATIONS: EvaluationsPreviewContainer,
  EXPERIMENTS: ExperimentsPreviewContainer,
  SNIPPETS: SnippetsPreviewContainer,
};

const getDashboardWidgets = (dashboardOrder = defaultDashboardOrder) =>
  dashboardOrder.map(widgetKey => {
    const WidgetComponent = dashboardWidgets[widgetKey];
    return WidgetComponent ? <WidgetComponent key={widgetKey} /> : null;
  });

interface DashboardWidgetsProps {
  userDetails: BaseUser;
}

export const DashboardWidgets = ({ userDetails }: DashboardWidgetsProps): JSX.Element => {
  const info = userDetails.info as UserDetailsInfo;
  const dashboardWidgets = getDashboardWidgets(info.dashboardOrder);

  return (
    <>
      <Horizontal position="apart" pb="lg">
        <Text variant="heading03" color="gray.8">
          Welcome {userDetails.name}
        </Text>
      </Horizontal>
      <Grid>
        <Grid.Col xs={14} sm={14} md={14} lg={15} xl={16}>
          <Vertical spacing="lg">{dashboardWidgets}</Vertical>
        </Grid.Col>
        <Grid.Col xs={6} lg={5} xl={4}>
          <Vertical>
            <SnippetsPreviewContainer />
            <WorkspaceCard title="Activity">
              <ActivitiesContainer />
            </WorkspaceCard>
          </Vertical>
        </Grid.Col>
      </Grid>
    </>
  );
};
