import css from '@styled-system/css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, IconOrangeWarning } from '../../design-system';

export const CenteredRow = styled(Box)((props: { gap?: number }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    gap: props.gap || '8px',
  }),
);

export const StyledTableHeader = styled(Box)`
  display: flex;
  align-items: center;

  svg path {
    fill: ${props => props.theme.colors.graphic.shade3};
  }
`;

export const StyledAnalysisStatus = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
  }),
);

export const StyledWarning = styled(IconOrangeWarning)`
  width: 20px;
  margin: 0 2px;
  path {
    fill: ${({ theme }) => theme.colors.error.base};
  }
`;

export const StyledClickableWrapper = styled(Box)(
  css({
    // Need to overlap the table cell padding to prevent unintended navigation
    cursor: 'auto',
    m: '-12px',
    p: '12px',
    flex: '1 0 auto',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
  }),
);

export const StyledAddDatasetButton = styled(Link)(
  css({
    border: 'thickSolid',
    borderColor: 'muted.shade1',
    borderRadius: 'small',
    backgroundColor: 'background.white',
    padding: '10px 16px',
  }),
);

export const StyledPageHeader = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '48px 0px',
  }),
);
