import { ColDef, ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { Box, Text, Tooltip, Vertical } from '../../../../../../design-system/v2';
import { Table } from '../../../../../../design-system/v2/core/data-display/table';
import { StyleGuideRuleSetResponse } from '../../../../../../generated/api';
import { RulesSetActions } from './RulesSetActions';

const columns: ColDef<StyleGuideRuleSetResponse>[] = [
  {
    colId: 'ruleSetName',
    field: 'ruleSetName',
    headerName: 'Set of rules',
    sortable: false,
    cellRenderer: ({ value: ruleSetName }: ICellRendererParams<StyleGuideRuleSetResponse>) => (
      <Vertical h="100%" justify="center">
        <Text truncate variant="bodyShort02">
          {ruleSetName}
        </Text>
      </Vertical>
    ),
    minWidth: 300,
    flex: 1,
  },
  {
    colId: 'ruleSetDescription',
    field: 'ruleSetDescription',
    headerName: 'Description',
    sortable: false,
    cellRenderer: ({ value: description }: ICellRendererParams<StyleGuideRuleSetResponse>) => (
      <Vertical h="100%" justify="center">
        <Tooltip label={description} maw={400} withinPortal multiline>
          <Text truncate variant="bodyShort03">
            {description}
          </Text>
        </Tooltip>
      </Vertical>
    ),
    minWidth: 400,
    flex: 1,
  },
  {
    colId: 'ruleCount',
    field: 'ruleCount',
    headerName: 'Count',
    sortable: false,
    cellRenderer: ({ value: count }: ICellRendererParams<StyleGuideRuleSetResponse>) => (
      <Vertical h="100%" justify="center">
        <Text variant="bodyShort02">{count}</Text>
      </Vertical>
    ),
    minWidth: 100,
    flex: 1,
  },
  {
    colId: 'ruleSetId',
    field: 'ruleSetId',
    headerName: ' ',
    sortable: false,
    suppressMenu: false,
    cellRenderer: ({ value: ruleSetId, data }: ICellRendererParams<StyleGuideRuleSetResponse>) => (
      <Box h="100%" onClick={(e: React.MouseEvent) => e.preventDefault()}>
        <RulesSetActions
          ruleSetId={ruleSetId}
          data={{
            ruleSetName: data?.ruleSetName ?? '',
            ruleSetDescription: data?.ruleSetDescription ?? '',
          }}
        />
      </Box>
    ),
    width: 100,
  },
];

interface RulesSetListProps {
  rulesSet: StyleGuideRuleSetResponse[];
  onRowClicked?: (ruleSetId: string, ruleName: string) => void;
}

const ROW_HEIGHT = 55;

export const RulesSetList = ({ rulesSet, onRowClicked }: RulesSetListProps) => {
  const handleRowClicked = ({ data, event }: RowClickedEvent<StyleGuideRuleSetResponse>) => {
    if (event?.defaultPrevented) {
      return null;
    }

    onRowClicked && data?.ruleSetId && onRowClicked(data.ruleSetId, data?.ruleSetName);
  };

  return (
    <Box mah={700} h={(rulesSet.length + 1) * ROW_HEIGHT} w="100%">
      <Table
        columns={columns}
        getRowClass={() => 'row-pointer'}
        rowData={rulesSet}
        onRowClicked={handleRowClicked}
      />
    </Box>
  );
};
