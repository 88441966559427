import { TagMetadata } from '../../../../../../generated/api';
import { getSortedTags } from '../../../../../../lib/tags';
import { Flex, ScrollArea, Tag } from '../../../../../v2';

const TAGS_SCROLL_AREA_HEIGHT = 180;
const TAG_MAX_WIDTH = 88;

interface TagsGroupProps {
  tags: TagMetadata[];
  selectedTagIds: string[];
  onClickTag: (tag: TagMetadata) => void;
}

export const TagsGroup = ({ tags, selectedTagIds, onClickTag }: TagsGroupProps) => (
  <ScrollArea h={TAGS_SCROLL_AREA_HEIGHT}>
    <Flex gap="sm" wrap="wrap">
      {getSortedTags(tags).map(tag => (
        <Tag
          key={tag.tagId}
          size="lg"
          name={tag.name}
          maw={TAG_MAX_WIDTH}
          selected={selectedTagIds.includes(tag.tagId)}
          onClick={() => onClickTag(tag)}
        />
      ))}
    </Flex>
  </ScrollArea>
);
