import { IconBuilding } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { IconModelApp, IconSnippets, IconStorage } from '../../../design-system';
import { Box, Horizontal, LinkAnchor, Text, ThemeIcon, Vertical } from '../../../design-system/v2';
import {
  AnalyzersCompletionNotificationPayload,
  InAppNotificationModel,
  ModelAppsReadyNotificationPayload,
  NotificationTypes,
  SnippetCommentNotificationPayload,
  SnippetInvitationPayload,
  SnippetMentionCommentPayload,
  UserInfo,
  UserMentionsOnSnippetContentRequestPayload,
} from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { getHumanizedTimeFromNow } from '../../../lib/time-format';
import { toPlural } from '../../../lib/ui';
import { AnalyzerTaskletEnum } from '../../../queries/constants';
import { getUserProfilePath } from '../../../router/constants';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { DatasetDetailsTab } from '../../dataset-details/header/DatasetDetailsTabs';
import { useNotificationStyle } from './Notification.style';

const DATASET_OVERVIEW_TASKLET_IDS: string[] = [
  AnalyzerTaskletEnum.BASELINE_MODEL,
  AnalyzerTaskletEnum.DATA_QUALITY_SCORE,
  AnalyzerTaskletEnum.CLASS_DISTRIBUTION,
  AnalyzerTaskletEnum.SEGMENT_SIMILARITY,
];

const VECTOR_ANALYSIS_TASKLETS: string[] = [
  AnalyzerTaskletEnum.VECTOR_ANALYSIS_TEXT,
  AnalyzerTaskletEnum.VECTOR_ANALYSIS_MIXED_CAT,
];

interface NotificationTextProps {
  userFrom?: UserInfo;
  analyserContent?: string;
  multipleAnalyserContent?: string;
  notificationContent: string;
  resourceName?: string;
  resourceContent?: string;
  resourceContentRoute?: string;
}

const NotificationText = ({
  userFrom,
  notificationContent,
  resourceName,
  resourceContent,
  resourceContentRoute,
}: NotificationTextProps): JSX.Element => (
  <Text variant="subTitle04" color="dark.6" lineClamp={2}>
    {resourceContentRoute ? (
      <LinkAnchor
        variant="subTitle04"
        target="_blank"
        to={resourceContentRoute}
        style={{ textDecoration: 'none' }}
      >
        {resourceName}
      </LinkAnchor>
    ) : (
      resourceName
    )}
    <LinkAnchor
      variant="subTitle04"
      target="_blank"
      to={getUserProfilePath(userFrom?.id ?? '')}
      onClick={event => event.stopPropagation()}
      style={{ textDecoration: 'none' }}
    >
      {userFrom?.name}
    </LinkAnchor>
    <Text span variant="bodyShort03">
      {notificationContent}
    </Text>
    {resourceContentRoute ? (
      <LinkAnchor
        variant="subTitle04"
        target="_blank"
        to={resourceContentRoute}
        style={{ textDecoration: 'none' }}
      >
        {resourceContent}
      </LinkAnchor>
    ) : (
      resourceContent
    )}
  </Text>
);

interface NotificationProps {
  notification: InAppNotificationModel;
  onMarkRead: (notifIds: string[]) => void;
}

export const NotificationItem = ({ notification, onMarkRead }: NotificationProps) => {
  const { classes, cx } = useNotificationStyle();
  const { getDatasetDetailsRoute, getSnippetDetailRoute, getModelAppDetailsRoute } =
    useAbsoluteRoutes();

  let resourceContentRoute = '';
  let notificationContent = null;
  let icon = <IconBuilding size={10} />;
  const navigate = useNavigate();

  const handleMarkRead = () => {
    onMarkRead([notification?.recordId ?? '']);
  };

  const handleNotificationClick = () => {
    handleMarkRead();
    navigate(resourceContentRoute);
  };

  switch (notification.notificationType) {
    case NotificationTypes.AnalyzersCompletionResultNotification:
      icon = <IconStorage fill="black" color="black" enableBackground={'black'} />;
      const { tasks = [], datasetInfo } =
        notification?.payload as AnalyzersCompletionNotificationPayload;
      const [firstTask, ...others] = tasks;
      // This case shouldn't arise in theory; asserting 1+ tasks to be sure
      if (!firstTask) {
        logger.error(
          'Unexpected empty list of analyzer tasks in notification payload: ' +
            JSON.stringify(notification),
        );
        notificationContent = null;
        break;
      }
      const analyserText =
        firstTask.taskName +
        (others.length > 0
          ? ` and ${toPlural(others.length, 'more analysis', 'more analyses')}`
          : ' analysis');
      // Results for certain analyzers are displayed outside the analyzer dahsboard, e.g. Data Segment
      // Similarity, Data Profiling, etc. Need to route links for a given tasklet accordingly.
      const datasetId = datasetInfo.id;
      const taskletId = firstTask.taskletId;
      if (DATASET_OVERVIEW_TASKLET_IDS.includes(taskletId)) {
        resourceContentRoute = getDatasetDetailsRoute(datasetId, DatasetDetailsTab.DETAILS);
      } else if (taskletId === AnalyzerTaskletEnum.DATA_PROFILING) {
        resourceContentRoute = getDatasetDetailsRoute(datasetId, DatasetDetailsTab.DATA_PROFILE);
      } else if (VECTOR_ANALYSIS_TASKLETS.includes(taskletId)) {
        resourceContentRoute = getDatasetDetailsRoute(datasetId, DatasetDetailsTab.EMBEDDINGS);
      } else {
        resourceContentRoute = getDatasetDetailsRoute(
          datasetId,
          DatasetDetailsTab.ANALYSIS,
          taskletId,
        );
      }
      notificationContent = (
        <NotificationText
          notificationContent={` ${analyserText} available for `}
          resourceContentRoute={resourceContentRoute}
          resourceContent={datasetInfo?.datasetName ?? ''}
        />
      );
      break;

    case NotificationTypes.SnippetCommentNotification:
      icon = <IconSnippets fill="black" color="black" enableBackground={'black'} />;
      const snippetType = notification?.payload;
      const snippetInfo = (snippetType as SnippetCommentNotificationPayload).snippetInfo ?? '';
      resourceContentRoute = getSnippetDetailRoute(snippetInfo?.snippetId ?? '');
      notificationContent = (
        <NotificationText
          userFrom={notification.payload?.userFrom ?? undefined}
          notificationContent="&#32;commented on&#32;"
          resourceContentRoute={resourceContentRoute}
          resourceContent={snippetInfo?.snippetName ?? ''}
        />
      );
      break;

    case NotificationTypes.SnippetContentUserMentionNotification:
      icon = <IconSnippets color="black" />;
      const mentionType = notification?.payload;
      const mentionSnippetInfo =
        (mentionType as UserMentionsOnSnippetContentRequestPayload).snippetInfo ?? '';
      resourceContentRoute = getSnippetDetailRoute(mentionSnippetInfo?.snippetId ?? '');
      notificationContent = (
        <NotificationText
          notificationContent="&#32;You have been mentioned on &#32;"
          resourceContentRoute={resourceContentRoute}
          resourceContent={mentionSnippetInfo?.snippetName ?? ''}
        />
      );
      break;

    case NotificationTypes.SnippetCommentUserMentionNotification:
      icon = <IconSnippets color="black" />;
      const commentMentionType = notification?.payload;
      const commentSnippetInfo =
        (commentMentionType as SnippetMentionCommentPayload).snippetInfo ?? '';
      resourceContentRoute = getSnippetDetailRoute(commentSnippetInfo?.snippetId ?? '');
      notificationContent = (
        <NotificationText
          notificationContent="&#32;You have been mentioned in a comment on &#32;"
          resourceContentRoute={resourceContentRoute}
          resourceContent={commentSnippetInfo?.snippetName ?? ''}
        />
      );
      break;

    case NotificationTypes.SnippetInviteEmail:
      icon = <IconSnippets color="black" />;
      const inviteType = notification?.payload;
      const inviteSnippetInfo = (inviteType as SnippetInvitationPayload).snippetInfo ?? '';
      resourceContentRoute = getSnippetDetailRoute(inviteSnippetInfo?.snippetId ?? '');
      notificationContent = (
        <NotificationText
          userFrom={notification.payload?.userFrom ?? undefined}
          notificationContent="&#32;shared the snippet&#32;"
          resourceContentRoute={resourceContentRoute}
          resourceContent={inviteSnippetInfo?.snippetName ?? ''}
        />
      );
      break;

    case NotificationTypes.ModelAppsReadyNotification:
      icon = <IconModelApp color="black" />;
      const modelAppsType = notification.payload as ModelAppsReadyNotificationPayload;
      const modelAppsName = modelAppsType.modelAppInfo.modelName;
      resourceContentRoute = getModelAppDetailsRoute(modelAppsType.modelAppInfo.modelId ?? '');
      notificationContent = (
        <NotificationText
          notificationContent=": model app is ready"
          resourceName={modelAppsName}
          resourceContentRoute={resourceContentRoute}
        />
      );
      break;

    default:
      notificationContent = (
        <NotificationText notificationContent=" You have got a new notification " />
      );
      break;
  }

  return (
    <Box onClick={handleNotificationClick}>
      <Horizontal
        noWrap
        className={cx(classes.notificationItem, {
          [classes.unread]: Boolean(!notification.read),
        })}
      >
        <ThemeIcon size={17} color="inherit" radius="l">
          {icon}
        </ThemeIcon>
        <Vertical spacing={0}>
          <Text variant="small03" color="dark.2" align="left">
            {getHumanizedTimeFromNow(notification?.createDate ?? '')} ago
          </Text>
          <Text variant="bodyShort03" onClick={handleMarkRead} align="left">
            {notificationContent}
          </Text>
        </Vertical>
      </Horizontal>
    </Box>
  );
};
