import { useThreadsItemContext } from '../../../contexts/chat-with-data/ThreadsItemContext';
import { useGetAppBuilderDetailsQuery } from '../../../queries/app-builder/app-builder';
import { ChatResourceTypes } from '../../../queries/chat-with-data/chat';
import { useUserInfo } from '../../../queries/user';
import { ConversationViewWrapper } from '../../common/chat-with-data/chat/conversation/ConversationView.wrapper';
import { CustomAppInputPromptContainer } from './CustomAppInputPromptContainer';

interface CustomAppChatProps {
  appId: string;
}

export const CustomAppChat = ({ appId }: CustomAppChatProps): JSX.Element => {
  const { activeThread } = useThreadsItemContext();
  const { isLoading, data } = useGetAppBuilderDetailsQuery(appId);
  const { data: userData, isLoading: isUserInfoLoading } = useUserInfo(data?.userId);

  // useUserInfo query uses the current userId if the passed userId is empty.
  // This implementation doesn't suit our case.
  // Either we have to control the behavior by a flag or create a hook. For now, we are going with this workaround
  // Input user id should be equal to the output user id, only then will the author name be picked
  const author = data?.userId === userData?.user?.userId ? userData?.user?.name : '';

  const titleText = isLoading ? '...' : data?.appName;
  const subHeadingText = isUserInfoLoading ? '...' : author ? `by ${author}` : '';
  const descriptionText = data?.appDescription;

  return (
    <ConversationViewWrapper
      activeThread={activeThread}
      resourceId={appId}
      resourceType={ChatResourceTypes.CUSTOM_APP}
      titleText={titleText}
      subHeadingText={subHeadingText}
      descriptionText={descriptionText}
    >
      <CustomAppInputPromptContainer resourceId={appId} />
    </ConversationViewWrapper>
  );
};
