import { IFilterParams } from '@ag-grid-community/core';
import { forwardRef } from 'react';
import { FilterWithButtons } from '../../../../../design-system/v2/core/data-display/table/filters/FilterWithButtons';
import { useGetWorkspaceTagsQuery } from '../../../../../queries/tagging';

export const TagsFilter = forwardRef((props: IFilterParams, ref) => {
  const { isLoading, isError, data } = useGetWorkspaceTagsQuery();

  return (
    <FilterWithButtons
      {...props}
      isError={isError}
      isLoading={isLoading}
      options={data?.map(d => ({
        value: d.tagId,
        label: d.name,
      }))}
      ref={ref}
    />
  );
});

TagsFilter.displayName = 'TagsFilter';
