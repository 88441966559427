import { PropsWithChildren } from 'react';
import { TableProps as RTTableProps } from 'react-table';

interface TableProps extends RTTableProps {
  dataTestId?: string;
}

export const Table = ({ children, dataTestId = '', ...rest }: PropsWithChildren<TableProps>) => (
  <table data-testid={dataTestId} {...rest}>
    {children}
  </table>
);
