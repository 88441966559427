import {
  Avatar as MantineAvatar,
  AvatarProps as MantineAvatarProps,
  createPolymorphicComponent,
} from '@mantine/core';
import { forwardRef } from 'react';

const _Avatar = forwardRef<HTMLDivElement, MantineAvatarProps>(({ children, ...rest }, ref) => (
  <MantineAvatar
    ref={ref}
    variant="filled"
    radius="xl"
    color="blue"
    // referrerPolicy needed to prevent requests to googleusercontent.com from being blocked
    imageProps={{ referrerPolicy: 'no-referrer' }}
    {...rest}
  >
    {children}
  </MantineAvatar>
)) as any;

_Avatar.displayName = 'Avatar';

_Avatar.Group = MantineAvatar.Group;

export const Avatar = createPolymorphicComponent<
  'div',
  MantineAvatarProps,
  { Group: typeof _Avatar.Group }
>(_Avatar);
