import { ArtifactFilterState } from '../../generated/api';

export const MARKOVML_HOME_PAGE = '//www.markovml.com';

export const DEFAULT_DELIMITER = ',';

export const TAB_PARAM = 'tab';

export enum ListingTabs {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
export interface ArtifactListingProps {
  state: ArtifactFilterState;
}

export const DIFF_TYPE = {
  ADD: 1,
  EQUAL: 0,
  DELETE: -1,
};
