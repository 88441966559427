import { mergeAttributes, Node } from '@tiptap/core';
import { JSONContent, ReactNodeViewRenderer } from '@tiptap/react';
import { DraggableBlockItem } from './DraggableBlock';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    draggableBlock: {
      setTextBlock: (options?: { position?: number; scrollIntoView?: boolean }) => ReturnType;

      // FIXME:: Fix layout type
      setChartGridBlock: (options?: {
        position?: number;
        grid?: string;
        scrollIntoView?: boolean;
      }) => ReturnType;

      setEmbeddingChartBlock: (options?: {
        position?: number;
        datasetId: string;
        embeddingId: string;
        scrollIntoView?: boolean;
        subsetId?: string;
      }) => ReturnType;
    };
  }
}

export const DraggableBlock = Node.create({
  name: 'draggableBlock',

  group: 'draggableBlock',

  content: '(block | chartBlock)',

  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => ({
          'data-block-id': (el as HTMLElement).getAttribute('data-block-id'),
          'data-type': 'd-block',
          'data-blocktype': 'text',
        }),
      },
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => ({
          'data-block-id': (el as HTMLElement).getAttribute('data-block-id'),
          'data-type': 'd-block',
          'data-blocktype': 'chart-grid',
        }),
      },
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => ({
          'data-block-id': (el as HTMLElement).getAttribute('data-block-id'),
          'data-type': 'd-block',
          'data-blocktype': 'embedding-chart',
        }),
      },
    ];
  },

  addAttributes() {
    return {
      'data-type': {
        default: 'd-block',
      },
      'data-blocktype': {
        default: 'text',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        'data-type': 'd-block',
      }),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DraggableBlockItem);
  },

  addCommands() {
    return {
      setTextBlock: opts => {
        const position = opts?.position;
        const scrollIntoView = opts?.scrollIntoView ?? true;

        return ({ state, chain }) => {
          const {
            selection: { from },
          } = state;

          const pos = position ?? from;

          return chain()
            .insertContentAt(pos, {
              type: this.name,
              attrs: {
                'data-blocktype': 'text',
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            })
            .focus(pos + 2, { scrollIntoView })
            .run();
        };
      },

      setChartGridBlock: opts => {
        const position = opts?.position;
        const grid = opts?.grid;
        const scrollIntoView = opts?.scrollIntoView ?? true;

        return ({ state, chain, view }) => {
          const {
            selection: { from },
          } = state;

          const pos = position ?? from;

          return chain()
            .insertContentAt(pos, {
              type: this.name,
              attrs: {
                'data-type': 'd-block',
                'data-blocktype': 'chart-grid',
              },
              content: [
                {
                  type: 'chartGridBlock',
                  attrs: {
                    'data-grid': grid,
                  },
                },
              ],
            })
            .focus(pos + 3, { scrollIntoView })
            .setTextBlock()
            .run();
        };
      },

      setEmbeddingChartBlock: opts => {
        const { position, datasetId, subsetId, embeddingId, scrollIntoView = true } = opts ?? {};

        return ({ state, chain }) => {
          const {
            selection: { from },
          } = state;

          const pos = position ?? from;

          return chain()
            .insertContentAt(pos, {
              type: this.name,
              attrs: {
                'data-type': 'd-block',
                'data-blocktype': 'embedding-chart',
              },
              content: [
                {
                  type: 'embeddingChartBlock',
                  attrs: {
                    'data-dataset-id': datasetId,
                    'data-embedding-id': embeddingId,
                    'data-embedding-subset-id': subsetId,
                  },
                },
              ],
            })
            .focus(pos + 3, { scrollIntoView })
            .setTextBlock()
            .run();
        };
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setTextBlock(),
      Backspace: ({ editor }) => {
        const {
          selection: { $head, from, to },
          doc,
        } = editor.state;

        const parent = $head.parent;
        const isTextBlock = parent.isText || parent.isTextblock;

        if (isTextBlock && !parent.textContent) {
          return editor
            .chain()
            .deleteNode(this.name)
            .focus(from - 4)
            .run();
        }

        return false;
      },
      Enter: ({ editor }) => {
        const {
          selection: { $head, from, to },
          doc,
        } = editor.state;

        const parent = $head.node($head.depth - 1);

        if (parent.type.name !== 'draggableBlock') return false;

        let currentActiveNodeTo = -1;

        doc.descendants((node, pos) => {
          if (currentActiveNodeTo !== -1) return false;
          // eslint-disable-next-line consistent-return
          if (node.type.name === this.name) return;

          const [nodeFrom, nodeTo] = [pos, pos + node.nodeSize];

          if (nodeFrom <= from && to <= nodeTo) currentActiveNodeTo = nodeTo;

          return false;
        });

        const content = doc.slice(from, currentActiveNodeTo)?.toJSON().content;

        return editor
          .chain()
          .insertContentAt(
            { from, to: currentActiveNodeTo },
            {
              type: this.name,
              attrs: {
                'data-blocktype': 'text',
              },
              content: content.map((ct: JSONContent) => ({
                ...ct,
                type: 'paragraph',
              })),
            },
          )
          .focus(from + 4)
          .run();
      },
    };
  },
});
