import { useGetWorkspaceSettingsDetailsQuery } from '../../../../queries/account/workspace';
import { InfoMessage } from '../../../common/InfoMessage';
import { UsageLoading } from './UsageLoading';
import { Usages } from './Usages';

export const UsageContainer = (): JSX.Element => {
  const { isLoading, error, data } = useGetWorkspaceSettingsDetailsQuery();

  if (isLoading) {
    return <UsageLoading />;
  }

  if (error || !data) {
    return <InfoMessage text="Unable to show workspace usage!" />;
  }

  return <Usages subscription={data.subscription} />;
};
