import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { PanelMode, PanelState, PanelTab } from '../../../components/dataset-details/clusters/util';
import { useQueryParams } from '../../../hooks/useQueryParams';

interface EmbeddingsClusterPanel {
  panelState: PanelState;
  setPanelState: (val: PanelState) => void;

  panelMode: PanelMode;
  setPanelMode: (p: PanelMode) => void;

  activeTab: PanelTab;
  setActiveTab: (tab: PanelTab) => void;
}

const ClusterPanelContext = createContext<EmbeddingsClusterPanel | undefined>(undefined);

export const useClusterPanelContext = () => {
  const context = useContext(ClusterPanelContext);
  if (!context) {
    throw new Error('useClusterPanelContext should only be used inside a ClusterPanelProvider');
  }
  return context;
};

export const ClusterPanelProvider = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const queryParams = useQueryParams();
  const [panelState, setPanelState] = useState<PanelState>(PanelState.OPEN);

  // Active panel tab impacts the values of PanelMode and ChartMode
  const defaultPanelTab = (queryParams.get('panelTab') as PanelTab | null) ?? PanelTab.EXPLORE;
  const [activeTab, setActiveTab] = useState(defaultPanelTab);
  const [panelMode, setPanelMode] = useState(
    defaultPanelTab === PanelTab.VIEW_SUBSETS ? PanelMode.SUBSETS_LIST : PanelMode.ACTIONS,
  );

  const value = {
    panelState,
    setPanelState,
    panelMode,
    setPanelMode,
    activeTab,
    setActiveTab,
  };

  return <ClusterPanelContext.Provider value={value}>{children}</ClusterPanelContext.Provider>;
};
