import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from '..';
import { TabProps } from './Tab';

export const StyledTab = styled(Box)<TabProps>(
  css({
    display: 'inline-block',
    padding: '20px 12px',
    userSelect: 'none',
  }),
  ({ selected, onClick }: TabProps) =>
    css({
      borderBottom: selected ? 'extraThickSolid' : 'none',
      borderColor: 'primary.base',
      color: selected ? 'primary.base' : 'text.shade3',
      cursor: onClick ? 'pointer' : 'default',
    }),
);
