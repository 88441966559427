import css from '@styled-system/css';
import styled, { keyframes } from 'styled-components';
import { Box, IconOrangeWarning, ProgressCircle } from '../../design-system';

interface StyledPanelProps {
  visible: boolean;
}

export const StyledPanel = styled(Box)((props: StyledPanelProps) =>
  css({
    width: '392px',
    height: '100%',
    bg: 'white',
    border: 'thinSolid',
    borderColor: 'muted.shade1',
    zIndex: 10,
    position: 'absolute',
    top: '0',
    right: props.visible ? '0' : '-400px',
    transition: 'right 0.6s ease-in-out',
    overflow: 'auto',
    boxShadow: 'popover',
  }),
);

export const StyledCloseButton = styled(Box)(
  css({
    position: 'absolute',
    top: '16px',
    right: '16px',
    bg: 'muted.shade1',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1,
  }),
);

export const StyledActionHeader = styled(Box)(
  css({
    height: '64px',
    bg: 'background.tinted',
    p: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
);

export const StyledActionTrigger = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ml: 'auto',
  }),
);

export const StyledWarning = styled(IconOrangeWarning)(
  css({
    path: {
      fill: 'error.base',
    },
  }),
);

const rotationAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledProgressCircle = styled(ProgressCircle)`
  animation: ${rotationAnimation} 0.75s linear infinite;
`;
