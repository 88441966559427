// TODO: Remove this once fully migrated to separate studio home pages
export enum WidgetType {
  DATASETS = 'DATASETS',
  SNIPPETS = 'SNIPPETS',
  MODELAPPS = 'MODELAPPS',
  EXPERIMENTS = 'EXPERIMENTS',
  EVALUATIONS = 'EVALUATIONS',
}

export const defaultDashboardOrder = [
  WidgetType.DATASETS,
  WidgetType.MODELAPPS,
  WidgetType.EVALUATIONS,
  WidgetType.EXPERIMENTS,
];

export const painpointMappingToDashboardOrder = {
  TEXT_ANALYSIS: defaultDashboardOrder,
  MODEL_APPS: [
    WidgetType.MODELAPPS,
    WidgetType.DATASETS,
    WidgetType.EVALUATIONS,
    WidgetType.EXPERIMENTS,
  ],
  DATA_RELABELING: defaultDashboardOrder,
  DATASET_ANALYSIS: defaultDashboardOrder,
};

export enum TableVariant {
  MAIN = 'MAIN',
  PREVIEW = 'PREVIEW',
}
