import { createStyles } from '@mantine/core';
import { FontWeight, LineHeight, fontWeights, lineHeight } from '../../typography/constants';
import { TabSize } from './Tab';

export const useStyles = createStyles<any, { size: TabSize }>((theme, { size }) => ({
  tab: {
    padding: `${size === 'sm' ? theme.spacing.sm : theme.spacing.md} ${theme.spacing.lg}`,
    fontSize: theme.fontSizes.md,
    fontWeight: fontWeights[FontWeight.REGULAR],
    lineHeight: lineHeight[LineHeight.SM],
    // TODO: Connect w design on dark theme bg/font color for tabs
    color: theme.colors.gray[6],
    '&[data-active]': {
      color: theme.colors.gray[8],
    },
    '&:hover': {
      backgroundColor: theme.colors.blue[0],
    },
  },
}));
