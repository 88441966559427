import { get } from 'styled-system';

const colors = {
  black: '#000000',
  white: '#ffffff',
  softBlack: '#1E1F21',
  lightWhite: 'rgba(255, 255, 255, 0.58)',
  offWhite: '#EAF0F4',
  darkBlue: '#192638',
  brightBlue: '#0C6EFC',
  textBlue: '#2B3942',
  linkWaterBlue: '#F8FAFD',
  textBlack: '#2D3133',
  textDark: '#3C505C',
  textLight: '#A8B4BC',

  // Names taken from https://chir.ag/projects/name-that-color/
  aquaHaze: '#F0F4F6',
  aliceBlue: '#FAFDFF',
  nevada: '#66717B',
  porcelain: '#F1F3F4',
  dangerRed: '#F00E0E',
  regentGray: '#808EA1',
  catskillWhite: '#E8EEF3',
  phantomGray: '#6E787D',
  jade: '#06BA63',

  // Names as derived from Design system Figma link
  // https://www.figma.com/file/HFAwDkUB3ZmTBLvqDURHY7/Design-System_31st-Jan-2022?node-id=3%3A4
  background: {
    tinted: '#F8FAFD',
    white: '#FFFFFF',
    error: '#FFFAFA',
  },
  loader: '#F9C846',
  hover: '#F0F4F9',
  muted: {
    shade1: '#EDEFF1',
  },
  primary: {
    base: '#077AB0',
  },
  secondary: {
    shade1: '#FFBA49',
  },
  text: {
    shade1: '#313638',
    shade2: '#42484F',
    shade3: '#6E787D',
    shade4: '#6D92A8',
  },
  graphic: {
    shade1: '#758085',
    shade2: '#89969C',
    shade3: '#94A2A8',
    disabled: '#D5D8D9',
  },
  success: {
    base: '#1E8258',
  },
  alert: {
    base: '#FFB100',
  },
  error: {
    base: '#D7263D',
  },
  // TODO: Designs currently use the colors, derived from illustration.shade2 but not defined. We need to
  // incorporate them into the design system in a way that makes sense.
  primarySelection: {
    base: '#FFDCA4',
    light: '#FEF9ED',
  },
  illustration: {
    shade2: '#FFBA49',
    // illustration.shade2 at 10% alpha
    shade2_10a: '#FFBA491A',
    shade3: '#80306D',
    shade4: '#F87666',
    shade5: '#13C4A3',
  },
  // These colors are currently only in analyser dashboard. Discuss and include these in chart pallete?
  analyser: {
    test: '#643173',
    train: '#005E43',
    unknown: '#89969C',
  },
};

export const colorGetter = function (path: string) {
  return (props: Record<string, any>) => get(props.theme.colors, path);
};

const radii = {
  xs: 2,
  small: 4,
  medium: 8,
  large: 12,
  xl: 16,
  circle: '100%',
};

const fontWeights = {
  normal: 400,
  semiBold: 500,
  bold: 700,
};

const borders = {
  thinSolid: '1px solid',
  thickSolid: '2px solid',
  extraThickSolid: '3px solid',
};

const shadows = {
  s: '0px 3px 3px rgba(0, 0, 0, 0.25)',
  box: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  column: '2px 0px 4px 0px #0000000D',
  bar: '0px 2px 0px #EDEFF1',
  popover: '0 1px 20px rgba(0, 0, 0, 0.1)',
  loader: '0px 0px 28px rgba(0, 0, 0, 0.12)',
};

export const theme = {
  colors,
  radii,
  fontWeights,
  borders,
  shadows,
};

// Note: open question: Does it makes sense to define our theme type?
export type Theme = typeof theme;

export default theme;
