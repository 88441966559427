import { Center, Loader } from '../../../design-system/v2';
import { VisualizationType } from '../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import {
  useGetModelAppMetadata,
  useInvalidatePublicModelApps,
  useSubmitPublicModelInput,
} from '../../../queries/snippets/snippets';
import { ErrorState } from '../../common/ErrorState';
import { useModelAppInfer } from '../../model-apps/model-app-details/use-model-app';
import { getVizIdFromVizModel } from '../detail/add-charts/hooks/util';
import { useSnippetDetail } from '../detail/context/SnippetContext';
import { ModelAppsBlock, OUTPUT_BOX_HEIGHT } from './ModelAppsBlock';

interface ModelAppsPublicBlockContainerProps {
  modelId: string;
}

export const ModelAppsPublicBlockContainer = ({ modelId }: ModelAppsPublicBlockContainerProps) => {
  const { snippetId } = useSnippetDetail();

  const appId = getVizIdFromVizModel({
    visualizationType: VisualizationType.MODEL_APPS,
    visualizationParameters: {
      modelId,
    },
  });

  const { data, isLoading, isError } = useGetModelAppMetadata(snippetId, appId);
  const invalidateModelApps = useInvalidatePublicModelApps(snippetId, appId);

  const { mutateAsync: getInference } = useSubmitPublicModelInput(snippetId, appId);
  const { modelOutputList = [], submitInput } = useModelAppInfer({ modelId, getInference });

  if (isLoading) {
    return (
      <Center h={2 * OUTPUT_BOX_HEIGHT}>
        <Loader variant="bars" text="Fetching Model App..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={2 * OUTPUT_BOX_HEIGHT}>
        <ErrorState title="" subtitle="Something went wrong, not able to fetch model app" />
      </Center>
    );
  }

  return (
    <ModelAppsBlock
      modelAppMetadata={data}
      getInferenceResult={submitInput}
      modelOutputList={modelOutputList}
      invalidateModelApps={invalidateModelApps}
      hideRestartBtn
    />
  );
};
