import { AppRoutes, getRoute } from './constants';

export enum RedirectionRoutes {
  DATA_STUDIO = 'data-studio',
  WORKFLOWS = 'workflows',
  APP_STORE = 'store',
  COPY_EDIT = 'editor',
}

export const redirectionRouteDetails: { [key in RedirectionRoutes]: string } = {
  [RedirectionRoutes.DATA_STUDIO]: getRoute(AppRoutes.DATA_STUDIO_HOME),
  [RedirectionRoutes.WORKFLOWS]: getRoute(AppRoutes.WORKFLOWS_HOME),
  [RedirectionRoutes.APP_STORE]: getRoute(AppRoutes.APP_STORE),
  [RedirectionRoutes.COPY_EDIT]: `${getRoute(AppRoutes.APP_STORE)}/${getRoute(
    AppRoutes.COPY_EDIT,
  )}`,
};
