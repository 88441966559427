export const getParsedUrl = (url: string): string => {
  let finalUrl = url;

  // Convert youtube links
  if (finalUrl.includes('youtube') || finalUrl.includes('youtu.be')) {
    /*
      This regex will extract the video id from different types of youtube links
      Ex: 
        https://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
        https://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
        https://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
        https://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
        https://www.youtube.com/embed/0zM3nApSvMg?rel=0
        https://www.youtube.com/watch?v=0zM3nApSvMg
        https://youtu.be/0zM3nApSvMg

      See This: https://stackoverflow.com/a/21607897
      */
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const videoId = match && match[2].length === 11 ? match[2] : null;

    if (videoId) {
      finalUrl = `//www.youtube.com/embed/${videoId}`;
    }
  }

  // Convert vimeo links
  finalUrl = finalUrl.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');

  // Convert google drive links
  if (finalUrl.includes('drive.google.com')) {
    finalUrl = finalUrl.replace('/view', '/preview');
  }

  // Convert loom links
  if (finalUrl.includes('www.loom.com/')) {
    finalUrl = finalUrl.replace('/share', '/embed');
    finalUrl += '?hide_owner=true&hide_share=true';
  }

  return finalUrl;
};
