import { ReactNode } from 'react';
import {
  Badge,
  Button,
  Center,
  Horizontal,
  Loader,
  Skeleton,
  Text,
} from '../../../design-system/v2';
import { AppBuilder } from '../../../generated/api';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { AppDetailsHeader } from '../../app-store/document-ai/commons/AppDetailsHeader';
import { MoreInfoList } from '../../app-store/document-ai/commons/MoreInfoList';
import { PageInfo } from '../../app-store/document-ai/commons/PageInfo';
import { EditableText } from '../../common/EditableText';
import { useAppStatusDetails } from '../useAppStatusDetails';
import { AppBuilderViews, AppBuilderViewsControl } from './AppBuilderViewsControl';

const withSkeleton = (isLoading: boolean, Component: ReactNode) =>
  isLoading ? <Skeleton h={25} w={300} /> : Component;

export interface AppBuilderDetailsHeaderProps {
  appBuilderDetails: AppBuilder;
  isLoading: boolean;
  isPublishDisabled: boolean;
  isSavingChanges: boolean;
  activeView: AppBuilderViews;
  onActiveViewChange: (view: AppBuilderViews) => void;
  onPublish: () => void;
  onAppNameUpdate: (name: string) => Promise<unknown>;
  onAppDescriptionUpdate: (description: string) => Promise<unknown>;
}

export const APP_BUILDER_DETAILS_HEADER_HEIGHT = 77;
const PAGE_TITLE_INPUT_MAX_WIDTH = 225;

// TODO: This component needs to be replaced with the new reusable component

export const AppBuilderDetailsHeader = ({
  appBuilderDetails,
  isLoading,
  isPublishDisabled,
  isSavingChanges,
  activeView,
  onActiveViewChange,
  onPublish,
  onAppNameUpdate,
  onAppDescriptionUpdate,
}: AppBuilderDetailsHeaderProps) => {
  const { getAppBuilderRoute } = useAbsoluteRoutes();
  const details = useAppStatusDetails(appBuilderDetails?.appStatus);

  // const modelList =
  //   availableModels?.map(model => ({
  //     value: model.modelId,
  //     label: model.modelId,
  //   })) ?? [];

  const MoreInfoWithSkeleton = withSkeleton(
    isLoading,
    <MoreInfoList
      infoList={[
        {
          title: 'Description',
          content: appBuilderDetails.appDescription,
          isEditable: true,
          onSave: onAppDescriptionUpdate,
        },
      ]}
    />,
  );

  const AppNameWithSkeleton = withSkeleton(
    isLoading,
    <EditableText
      maw={PAGE_TITLE_INPUT_MAX_WIDTH}
      variant="subTitle02"
      ariaLabel="App Name"
      value={appBuilderDetails?.appName ?? ''}
      onSave={onAppNameUpdate}
    />,
  );

  return (
    <AppDetailsHeader cols={3}>
      <PageInfo
        parentTitle="Gen AI App Builder"
        title={AppNameWithSkeleton}
        isMetadataLoading={isLoading}
        additionalTitleElement={
          details ? (
            <Badge ml="sm" variant="filled" bg={details.color[0]} sx={{ color: details.color[6] }}>
              {details.title}
            </Badge>
          ) : null
        }
        backButtonRoute={getAppBuilderRoute()}
        moreInfo={MoreInfoWithSkeleton}
      />
      <Center>
        <AppBuilderViewsControl
          disabled={isSavingChanges}
          isTestDisabled={isPublishDisabled}
          activeView={activeView}
          onActiveViewChange={onActiveViewChange}
        />
      </Center>
      <Horizontal position="right" spacing="xl">
        {/* TODO: Add after ODSC demo. */}
        {/* <Select
              hideLabel
              ariaLabel="Select model"
              loading={isLoadingModels}
              options={modelList}
              value={selectedModel?.modelId}
              onChange={handleSelectedModelChange}
              withinPortal
            /> */}
        {isSavingChanges ? (
          <Horizontal>
            <Loader size="sm" />
            <Text variant="small03" color="gray.7">
              Saving Changes
            </Text>
          </Horizontal>
        ) : (
          <Button disabled={isPublishDisabled} color="green" variant="primary" onClick={onPublish}>
            Publish app
          </Button>
        )}
      </Horizontal>
    </AppDetailsHeader>
  );
};
