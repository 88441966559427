import { createStyles } from '@mantine/styles';

export const useParagraphForTableStyles = createStyles({
  colMenu: {
    position: 'absolute',
    top: '-15px',
    left: 'calc(50% - 15px)',
    visibility: 'hidden',
  },
  rowMenu: {
    position: 'absolute',
    left: '-15px',
    visibility: 'hidden',
  },
});
