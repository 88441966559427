import { Center, Loader, Text, openModal } from '../../../design-system/v2';
import { useGetDataSourcePreviewQuery } from '../../../queries/data-sources';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { DEFAULT_DELIMITER } from '../../common/constants';
import { ResourcePreviewParser } from '../../connectors/modals/ResourcePreviewModal';

interface DataSourcePreviewModalProps {
  resourceId: string;
  height?: string;
}

export const DataSourcePreviewModal = ({
  resourceId,
  height,
}: DataSourcePreviewModalProps): JSX.Element => {
  const previewQuery = useGetDataSourcePreviewQuery(resourceId);

  if (previewQuery.isLoading || previewQuery.isIdle) {
    return (
      <Center h={400}>
        <Loader text="Loading data preview..." />
      </Center>
    );
  }

  if (previewQuery.isError) {
    return <ErrorState title="Error loading preview" />;
  }

  const { preview, delimiter = DEFAULT_DELIMITER } = previewQuery.data;

  if (!preview.data.length) {
    return <EmptyState title="No data preview available" />;
  }

  return <ResourcePreviewParser preview={preview} delimiter={delimiter} height={height} />;
};

export const useDataSourcePreviewModal = (resourceId: string) => {
  const open = () =>
    openModal({
      size: '90vw',
      title: <Text variant="subTitle01">Resource details</Text>,
      children: <DataSourcePreviewModal resourceId={resourceId} />,
    });

  return { open };
};
