import { PropsWithChildren } from 'react';
import { TableRowProps as RTTableRowProps } from 'react-table';

// Note: Styles for disabled/selected states can be specified for
// individual table instances, e.g. DataTable, Spreadsheet, etc.
export interface TableRowProps extends RTTableRowProps {
  dataTestId?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  isClickable?: boolean;
}

export const TableRow = ({
  children,
  dataTestId = '',
  isSelected = false,
  isDisabled = false,
  isClickable = false,
  ...rest
}: PropsWithChildren<TableRowProps>) => (
  <tr data-testid={isDisabled ? `disabled-${dataTestId}` : dataTestId} {...rest}>
    {children}
  </tr>
);
