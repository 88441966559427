import { ArtifactFilterState, ArtifactStateType, ResourceType } from '../../generated/api';
import { useDataLabelingStateUpdateMutation } from '../../queries/data-labeling/list/list';
import { useArchiveUnarchive } from '../archive/use-archive-unarchive';

export const useDataLabelingArchiveUnarchive = (
  dataLabelingIds: string[],
  artifactState: ArtifactStateType,
) => {
  const count = dataLabelingIds.length;

  const {
    mutateAsync: archiveUnarchiveDataLabeling,
    isError,
    isLoading,
  } = useDataLabelingStateUpdateMutation({
    dataOperationIds: dataLabelingIds,
    artifactState:
      artifactState === ArtifactStateType.Active
        ? ArtifactStateType.Archived
        : ArtifactStateType.Active,
  });

  return useArchiveUnarchive(
    count,
    ResourceType.DataOperation,
    artifactState === ArtifactStateType.Active
      ? ArtifactFilterState.Active
      : ArtifactFilterState.Archived,
    archiveUnarchiveDataLabeling,
    isError,
    isLoading,
  );
};
