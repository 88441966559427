import { createStyles } from '@mantine/core';
import { CSSProperties } from 'react';
import {
  FontWeight,
  fontWeights,
  LetterSpacing,
  letterSpacing,
  lineHeight,
  LineHeight,
  TextVariants,
  textVariantToStyle,
} from './constants';

interface TextParams {
  variant: TextVariants;
}

const getLineHeightForVariant = (variant: TextVariants): CSSProperties['lineHeight'] => {
  const variantStyles = textVariantToStyle[variant];
  return lineHeight[variantStyles?.lineHeight ?? LineHeight.MD];
};

const getLetterSpacingForVariant = (variant: TextVariants): CSSProperties['lineHeight'] => {
  const variantStyles = textVariantToStyle[variant];
  return letterSpacing[variantStyles?.letterSpacing ?? LetterSpacing.SPACED];
};

export const useTextStyles = createStyles<'root', TextParams>((_theme, params) => {
  const variant = params.variant;
  const variantStyles = textVariantToStyle[variant];
  return {
    root: {
      lineHeight: getLineHeightForVariant(variant),
      letterSpacing: getLetterSpacingForVariant(variant),
      fontSize: _theme.fontSizes[variantStyles?.fontSize],
      fontWeight: fontWeights[variantStyles?.fontWeight ?? FontWeight.REGULAR],
    },
  };
});
