import { AnalyzingSpinner } from '../../../design-system';
import { Horizontal, Text, Vertical } from '../../../design-system/v2';
import { EvaluationStatus } from '../../../generated/api';
import { StyledBubbles } from '../../common/TableWrapper';
import { StyledWarning } from '../../datasets-table/DatasetsTable.style';

interface EvaluationStatusProps {
  status: EvaluationStatus;
}

export const EvaluationState = ({ status }: EvaluationStatusProps): JSX.Element => {
  if (status === EvaluationStatus.Running) {
    return (
      <Horizontal>
        <AnalyzingSpinner />
        <Text variant="small01" color="dark.5">
          Running...
        </Text>
      </Horizontal>
    );
  }

  if (status === EvaluationStatus.Success) {
    return (
      <Horizontal>
        <StyledBubbles />
        <Text variant="small01" color="green.9">
          Finished
        </Text>
      </Horizontal>
    );
  }

  if (status === EvaluationStatus.Failure) {
    return (
      <Horizontal>
        <StyledWarning />
        <Vertical spacing={0}>
          <Text variant="small01" color="red.9">
            Error
          </Text>
          <Text variant="small03" color="dark.5">
            Task failed, Try again
          </Text>
        </Vertical>
      </Horizontal>
    );
  }

  return (
    <Horizontal>
      <AnalyzingSpinner />
      <Text variant="small01" color="dark.5">
        Pending...
      </Text>
    </Horizontal>
  );
};
