import { AxiosError, AxiosResponse } from 'axios';
import mapValues from 'lodash/mapValues';
import sortedLastIndex from 'lodash/sortedLastIndex';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  AnalysisChartTypes,
  AnalysisDataPoint,
  AnalysisStateNames,
  AvailableDatasetTaskletsResponse,
  DataAnalysesStatus,
  DataAnalysisSummaryResponse,
  DataCategory,
  DatasetClassDistributionResponse,
  DatasetPreviewData,
  DatasetTasklet,
  DatasetTaskletGroup,
  FilePreview,
  GetAnalysisResponse,
  GetDataAnalysesStatusResponse,
  GetDataQualityStatusResponse,
  ListSavedTaskletSelectionsResponse,
  PredictDataCategoryResponse,
  SavedTaskletSelection,
  SegmentType,
  TriggerAnalysisResponse,
} from '../../../generated/api';
import { datasetAnalysisApi, datasetApi } from '../../../lib/api';
import { useChartQuery } from '../../charts-query-wrapper';
import { AnalyzerTaskletEnum, getVectorAnalysisTaskletId } from '../../constants';
import {
  dataAnalysisTaskKeys,
  dataAnalysisTaskletKeys,
  datasetKeys,
  modelAppsKeys,
} from '../../queryConstants';
import { responseSelector, segmentOrder } from '../../util';
import { useDatasetDetailsQuery } from '../list';
import { relabelingKeys } from '../relabeling/queryKeys';

// Makes request for inferred data category, including transformation for req body
export const useInferDataCategoryQuery = (
  dataBySegment: Record<SegmentType, string[][]> | null,
  delimiter: string,
  features: string[],
  target: string,
) => {
  const { workspaceId } = useAppMetadata();

  const segments = Object.keys(dataBySegment ?? {}) as SegmentType[];
  // Req body requires data to be a flat array of strings
  const preview: Record<SegmentType, FilePreview> = mapValues(
    dataBySegment,
    (data: string[][]) => ({
      // Need to surround each token with '\"' to parse string values that contain the delimiter
      data: data.map(row => row.map(token => `\"${token}\"`).join(delimiter)),
      metadata: { lineSeparator: '\n' },
    }),
  );

  const previewData: DatasetPreviewData = {
    segments,
    delimiter,
    preview,
  };

  return useQuery<AxiosResponse<PredictDataCategoryResponse>, AxiosError, DataCategory>(
    dataAnalysisTaskletKeys.dataCategory(workspaceId, features, previewData),
    () =>
      datasetAnalysisApi.predictDataCategoryV1(workspaceId, {
        features,
        dataset_preview: previewData,
        target,
      }),
    {
      enabled: Boolean(workspaceId && dataBySegment && delimiter && features.length),
      select: responseSelector,
    },
  );
};

// Transformed type for consumption
export interface TaskletGroup extends Omit<DatasetTaskletGroup, 'taskletIds'> {
  tasklets: DatasetTasklet[];
}

const selectTaskletGroups = (
  data: AxiosResponse<AvailableDatasetTaskletsResponse>,
): TaskletGroup[] => {
  // NOTE: The field name 'taskletIds' from the API is misleading, they are really tasklet objects
  const { taskletIds: tasklets, groups: origGroups } = data.data.response;

  const groups = origGroups.map(group => ({
    ...group,
    tasklets: group.taskletIds.map(taskletId => {
      const tasklet = tasklets.find(tasklet => tasklet.taskletId === taskletId);
      // This should only arise if response from BE is not self-consistent
      if (tasklet === undefined) {
        throw new Error(`No corresponding tasklet was found for the tasklet ID ${taskletId}`);
      }
      return tasklet;
    }),
  }));

  return groups;
};

export const useAvailableTaskletsListQuery = (
  dataCategory?: DataCategory,
  datasetId?: string,
  isDataUnlabeled?: boolean,
) => {
  const { workspaceId } = useAppMetadata();

  // API requires client to specify either a data category or a datasetId (to derive data category)
  const hasRequiredParam = Boolean(dataCategory || datasetId);
  if (!hasRequiredParam) {
    throw new Error('The tasklets list query requires either a dataCategory or a datasetId');
  }

  return useQuery<AxiosResponse<AvailableDatasetTaskletsResponse>, AxiosError, TaskletGroup[]>(
    dataAnalysisTaskletKeys.listAvailable(workspaceId, dataCategory, datasetId, isDataUnlabeled),
    () =>
      datasetAnalysisApi.getAvailableTaskletsV1(
        workspaceId,
        dataCategory,
        datasetId,
        isDataUnlabeled ?? false,
      ),
    {
      enabled: Boolean(workspaceId && hasRequiredParam),
      select: selectTaskletGroups,
      staleTime: 0,
    },
  );
};

export const useSavedTaskletSelectionListQuery = (dataCategory: DataCategory) => {
  const { workspaceId } = useAppMetadata();

  // TODO: Paginated results don't work well as options for Mantine Select component.
  // Requesting a large page size as a workaround for now, but eventually we should
  // remove pagination for this endpoint.
  return useQuery<
    AxiosResponse<ListSavedTaskletSelectionsResponse>,
    AxiosError,
    SavedTaskletSelection[]
  >(
    dataAnalysisTaskletKeys.savedSelections(workspaceId, dataCategory),
    () =>
      datasetAnalysisApi.getSavedTaskletSelectionsV1(workspaceId, 0, 100, undefined, dataCategory),
    {
      enabled: Boolean(workspaceId && dataCategory),
      select: responseSelector,
    },
  );
};

interface SaveTaskletSelectionRequest {
  selectionName: string;
  taskletIds: string[];
}

export const useSaveTaskletSelectionMutation = (dataCategory: DataCategory) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    ({ selectionName, taskletIds }: SaveTaskletSelectionRequest) =>
      datasetAnalysisApi.saveTaskletSelectionsV1(workspaceId, {
        selection_name: selectionName,
        tasklet_ids: taskletIds,
        dataset_category: dataCategory,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          dataAnalysisTaskletKeys.savedSelections(workspaceId, dataCategory),
        );
      },
    },
  );
};

export const useTaskletsWithStatusQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetDataAnalysesStatusResponse>, AxiosError, DataAnalysesStatus[]>(
    dataAnalysisTaskletKeys.listWithStatus(workspaceId, datasetId),
    () => datasetAnalysisApi.getDataAnalysesStatusV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: res => res.data.response,
    },
  );
};

export const useTriggerAnalysisMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError, string[]>(
    (taskletIds: string[]) =>
      datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // TODO: Remove invalidate call for v1 query key once migrated to v2 view/query
        queryClient.invalidateQueries(['list-analysis-groups-v1', workspaceId, datasetId]);
        queryClient.invalidateQueries(['list-analysis-groups-v2', workspaceId, datasetId]);
        // Update status on dataset list, dataset details and analysis tasks list
        queryClient.invalidateQueries(datasetKeys.lists());
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
        queryClient.invalidateQueries(dataAnalysisTaskKeys.list(workspaceId, datasetId));
        queryClient.invalidateQueries(
          modelAppsKeys.baselineTaskletStatus(workspaceId, datasetId ?? ''),
        );
      },
    },
  );
};

export const useTriggerVectorAnalysisMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  // Tasklet ID for vector analysis depends on dataset type
  const dsDetailsQuery = useDatasetDetailsQuery(datasetId);
  const dataCategory = dsDetailsQuery?.data?.dataType;

  const taskletId = dataCategory ? getVectorAnalysisTaskletId(dataCategory) : null;
  const taskletIds = taskletId ? [taskletId] : [];

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError>(
    () => datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // Update clustering state
        queryClient.invalidateQueries(['clustering-state-v1', workspaceId, datasetId]);
        // Update analyzing status on dataset
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
      },
    },
  );
};

export const useTriggerDataQualityScoreAnalysisMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const taskletIds = [AnalyzerTaskletEnum.DATA_QUALITY_SCORE];

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError>(
    () => datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // Update data quality analysis status
        queryClient.invalidateQueries(
          dataAnalysisTaskKeys.dataQualityStatus(workspaceId, datasetId),
        );
        // Update relabeling workbench status
        queryClient.invalidateQueries(relabelingKeys.status(workspaceId, datasetId));
        // Update analyzing status on dataset
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
      },
    },
  );
};

export const useTriggerSegmentSimilarityAnalysisMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const taskletIds = [AnalyzerTaskletEnum.SEGMENT_SIMILARITY];

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError>(
    () => datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // Update analyzing status on dataset
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
      },
    },
  );
};

export const useStopRunningAnalysisTaskMutation = (datasetId: string, taskId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const taskListQueryKey = dataAnalysisTaskKeys.list(workspaceId, datasetId);

  return useMutation(
    () => datasetAnalysisApi.triggerStopAnalysesV1(workspaceId, datasetId, taskId),
    {
      onSuccess: () => {
        // Update status on dataset list, dataset details and analysis tasks list
        queryClient.invalidateQueries(datasetKeys.lists());
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
        queryClient.invalidateQueries(taskListQueryKey);
      },
    },
  );
};

export const useAnalysersGroupByMetricQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    ['list-analysis-groups-v1', workspaceId, datasetId],
    () => datasetApi.workspaceListAnalysisGroupedByMetricsV1(workspaceId, datasetId),
    {
      enabled: !!datasetId,
      select: data => data.data,
    },
  );
};

export interface Analyser {
  taskletId: string;
  taskletName: string;
  taskletMetricId: string;
  taskletDescription: string | undefined;
  taskletMetric: string;
  taskletMetricName: string;
  taskletMetricDescription: string | undefined;
}

interface DataAnalysisSummaryFlattenedResponse
  extends Omit<DataAnalysisSummaryResponse, 'response'> {
  response: Analyser[];
}

export const useAnalysersQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DataAnalysisSummaryResponse>,
    AxiosError,
    DataAnalysisSummaryFlattenedResponse
  >(
    ['analysis-summary-groups-v1', workspaceId, datasetId],

    () => datasetAnalysisApi.listDataAnalysisResultsSummaryV1(workspaceId, datasetId),
    {
      enabled: !!datasetId,
      select: res => ({
        ...res.data,
        response: res.data.response.flatMap(tasklet =>
          tasklet.taskletMetrics.map(taskletMetric => ({
            taskletId: tasklet.taskletId,
            taskletName: tasklet.taskletName,
            taskletDescription: tasklet.description,
            taskletMetricId: taskletMetric.taskletMetricId,
            taskletMetricName: taskletMetric.taskletMetricName,
            taskletMetric: taskletMetric.taskletMetric,
            taskletMetricDescription: taskletMetric.description,
          })),
        ),
      }),
    },
  );
};

export const useAnalysersMetadataQuery = (datasetId: string, analyserMetadata?: Analyser) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [
      'analysis-summary-groups-metadata-v1',
      workspaceId,
      datasetId,
      analyserMetadata?.taskletId,
      analyserMetadata?.taskletMetricId,
    ],
    () =>
      datasetAnalysisApi.listDataAnalysisResultsMetadataV1(
        workspaceId,
        datasetId,
        analyserMetadata?.taskletId ? analyserMetadata?.taskletId : '',
        analyserMetadata?.taskletMetricId ? analyserMetadata?.taskletMetricId : '',
      ),
    {
      enabled: !!datasetId && !!analyserMetadata,
      select: data => {
        const tdata = data.data.response.flatMap(item =>
          item.analyses.map(analysis => ({
            analysisId: analysis.analysisId,
            datasetId: datasetId,
            analysisGroupId: item.taskletId,
            metricName: analyserMetadata?.taskletMetricName
              ? analyserMetadata?.taskletMetricName
              : '',
            segmentType: analysis.segmentType as SegmentType,
            preferredChartType: analysis.preferredChartType as AnalysisChartTypes,
            title: analysis.title,
            description: '',
            classes: analysis.classes,
            subsetId: '',
          })),
        );
        // Sort the analyses by segment type using the segmentOrder mapping
        tdata.sort((a, b) => segmentOrder[a.segmentType] - segmentOrder[b.segmentType]);
        return tdata;
      },
    },
  );
};

// https://tkdodo.eu/blog/react-query-data-transformations
// TODO: BE should perform the sorting/alignment of categories and data.
// The getSortedSeriesData fn is a temporary fix for current UI issues.
export const selectChartData = (data: AxiosResponse<GetAnalysisResponse>) => {
  if (data.data.response?.metadata.preferredChartType === AnalysisChartTypes.TreeMap) {
    return data.data.response.data;
  }

  const categories = data.data.response?.data.categories;
  const series = data.data.response?.data.series;
  const orderedCategories: string[] = [];

  if (
    data.data.response?.metadata.preferredChartType === AnalysisChartTypes.HorizontalHistogram &&
    categories
  ) {
    return {
      xCategories: undefined,
      categories: undefined,
      series: series ?? [],
    };
  }

  // Ranks the data values and set order of categories accordingly.
  // Note: Should go away once API is updated.
  const getSortedSeriesData = (origData: AnalysisDataPoint[]) => {
    const orderedValues: number[] = [];
    origData.forEach((datum, i) => {
      const value = datum.y ?? 0;
      const insertionIndex = sortedLastIndex(orderedValues, value);
      orderedValues.splice(insertionIndex, 0, value);
      // only needed on the first series data pass
      if (categories && orderedCategories.length !== categories.length) {
        const category = categories[i];
        // casting to string type needed for chart, since category is possibly a number
        orderedCategories.splice(insertionIndex, 0, category.toString());
      }
    });

    return orderedValues.map(value => [value]);
  };

  return {
    categories: orderedCategories,
    series:
      series?.map(serie => ({
        name: serie.name,
        data: getSortedSeriesData(serie.data),
      })) ?? [],
  };
};

export const useAnalyserDataQuery = (datasetId: string, analysisId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    ['get-analyser-data', datasetId, analysisId],
    () => datasetApi.workspaceGetAnalysisDataV1(workspaceId, datasetId, analysisId),
    {
      enabled: !!workspaceId,
      select: (data: AxiosResponse<GetAnalysisResponse>) => ({
        data: selectChartData(data),
        metadata: data.data.response?.metadata,
      }),
    },
  );
};

export const useAnalyserClassDistribution = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DatasetClassDistributionResponse>,
    AxiosError,
    DatasetClassDistributionResponse
  >(
    ['get-class-distribution', workspaceId, datasetId],
    () => datasetApi.workspaceGetDatasetClassDistributionV1(datasetId, workspaceId),
    {
      enabled: Boolean(workspaceId && datasetId),
      publicChartFn: res => ({ data: res.data.classDistribution }),
      select: data => data.data,
    },
  );
};

export const useDataQualityAnalysisStatusQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetDataQualityStatusResponse>, AxiosError, AnalysisStateNames>(
    dataAnalysisTaskKeys.dataQualityStatus(workspaceId, datasetId),
    () => datasetApi.workspaceGetDataQualityStatusV1(datasetId, workspaceId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: res => res.data.analysisState,
    },
  );
};
