import { IconAlertCircle, IconInfoCircle } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { Alert, Box, Center, Divider, Loader, Vertical } from '../../../design-system/v2';
import { DataCategory } from '../../../generated/api';
import { interleaveComponent } from '../../../lib/util-react';
import {
  TaskletGroup,
  useAvailableTaskletsListQuery,
} from '../../../queries/datasets/analysis/analysis';
import { TaskletGroupAccordion } from './TaskletGroupAccordion';

// There is a union type for this component's props, since query for analyzers requires
// EITHER a datasetId (if already registered) or a dataCategory (if not yet registered)
interface GroupedAnalyzerSelectionContainerBaseProps {
  getIsChecked: (taskletId: string, isDefault?: boolean) => boolean;
  onClickCheckbox: (checked: boolean, taskletId: string) => void;
  getIsDisabled?: (taskletId: string) => boolean;
  getDisabledLabel?: (taskletId: string) => string;
  onDataLoaded?: (groups: TaskletGroup[]) => void;
}

interface GroupedAnalyzerSelectionContainerPropsWithDatasetId
  extends GroupedAnalyzerSelectionContainerBaseProps {
  datasetId: string;
  dataCategory?: never;
  isUnlabeledDataset?: boolean;
}

interface GroupedAnalyzerSelectionContainerPropsWithDataCategory
  extends GroupedAnalyzerSelectionContainerBaseProps {
  dataCategory: DataCategory;
  isUnlabeledDataset?: boolean;
  datasetId?: never;
}

export const GroupedAnalyzerSelectionContainer = ({
  dataCategory,
  datasetId,
  isUnlabeledDataset = false,
  getIsChecked,
  onClickCheckbox,
  getIsDisabled,
  getDisabledLabel,
  onDataLoaded,
}:
  | GroupedAnalyzerSelectionContainerPropsWithDatasetId
  | GroupedAnalyzerSelectionContainerPropsWithDataCategory): JSX.Element => {
  const availableTaskletsListQuery = useAvailableTaskletsListQuery(
    dataCategory,
    datasetId,
    isUnlabeledDataset,
  );

  const groups = availableTaskletsListQuery.data ?? [];

  useEffect(() => {
    if (!isEmpty(groups)) {
      onDataLoaded?.(groups);
    }
  }, [groups]);

  if (availableTaskletsListQuery.isLoading || availableTaskletsListQuery.isIdle) {
    return (
      <Center h={250}>
        <Loader text="Loading available analyzers..." />
      </Center>
    );
  }

  if (availableTaskletsListQuery.isError) {
    return (
      <Alert icon={<IconAlertCircle size={20} />} color="red">
        Error encountered fetching available analyzers. Try refreshing or contact support if the
        problem persists.
      </Alert>
    );
  }

  if (isEmpty(groups)) {
    return (
      <Alert icon={<IconInfoCircle />}>
        No analyzers were found for this dataset type. Please reach out to support@markovml.com if
        you need assistance.
      </Alert>
    );
  }

  return (
    <Vertical>
      {interleaveComponent(
        groups.map((group, i) => {
          const groupId = `${i}-${group.name}`;
          return (
            <Box key={groupId} py="xs">
              <TaskletGroupAccordion
                taskletGroupId={groupId}
                taskletGroupName={group.name}
                description={group.description}
                tasklets={group.tasklets}
                getIsChecked={getIsChecked}
                onClickCheckbox={onClickCheckbox}
                getIsDisabled={getIsDisabled}
                getDisabledLabel={getDisabledLabel}
              />
            </Box>
          );
        }),
        Divider,
      )}
    </Vertical>
  );
};
