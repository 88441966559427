import { SegmentedControl } from '../../../design-system/v2';

export enum AppBuilderViews {
  BUILD = 'Build',
  TEST = 'Test',
}

export interface AppBuilderViewsControlProps {
  activeView: AppBuilderViews;
  onActiveViewChange: (view: AppBuilderViews) => void;
  isTestDisabled: boolean;
  disabled: boolean;
}

export const AppBuilderViewsControl = ({
  activeView,
  onActiveViewChange,
  isTestDisabled,
  disabled,
}: AppBuilderViewsControlProps) => (
  <SegmentedControl
    disabled={disabled}
    value={activeView}
    onChange={(tab: AppBuilderViews) => onActiveViewChange(tab)}
    data={[
      {
        value: AppBuilderViews.BUILD,
        label: AppBuilderViews.BUILD,
      },
      {
        value: AppBuilderViews.TEST,
        label: AppBuilderViews.TEST,
        disabled: isTestDisabled,
      },
    ]}
  />
);
