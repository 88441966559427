import { RowClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { isString } from 'lodash';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Horizontal, Text, useForceUpdate } from '../../../design-system/v2';
import { ArtifactStateType, Workflow } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { getWorkflowTableColumns } from './columns';

import { List, ListVersions } from '../../../design-system/v2/core/data-display/table';
import { ActionsToolbar } from './toolbar/ActionsToolbar';
import { useWorkflowArchiveUnarchive } from './workflow-actions/useWorkflowArchiveUnarchive';
import { useWorkflowDelete } from './workflow-actions/useWorkflowDelete';

const getTitleFromState = (state: ArtifactStateType) => {
  switch (state) {
    case ArtifactStateType.Active:
      return 'Published Workflows';
    case ArtifactStateType.Draft:
      return 'Draft Workflows';
    case ArtifactStateType.Archived:
      return 'Archived Workflows';
    default:
      return '';
  }
};

interface WorkflowsListProps {
  state: ArtifactStateType;
  currentPage: number;
  handlePageChange: (page: number) => void;
  totalRows: number;
  isLoading: boolean;
  hasError?: boolean;
  workflows?: Workflow[];
  searchText: string;
  onSearchTextChange: (value: string) => void;
  showOnlyScheduled: boolean;
  onShowOnlyScheduledCheckboxToggle: () => void;
  onReload: () => void;
}

export const WorkflowsList = ({
  state,
  currentPage,
  handlePageChange,
  totalRows,
  searchText,
  onSearchTextChange,
  showOnlyScheduled,
  onShowOnlyScheduledCheckboxToggle,
  isLoading,
  onReload,
  workflows = [],
}: WorkflowsListProps) => {
  const { getWorkflowRunsRoute, getWorkflowDetailsRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();
  const force = useForceUpdate();
  const gridRef = useRef<AgGridReact<Workflow>>(null);

  const handleRowSelection = (params: RowSelectedEvent<Workflow>) => {
    const workflow = params.data;
    if (!workflow?.workflowId) {
      let stringifiedParams;
      try {
        stringifiedParams = JSON.stringify(params);
      } catch (e) {
        logger.error('WorkflowsTable: Error stringifying params');
      }
      logger.warn(`Workflow ID not found while selection/deselection: ${stringifiedParams}`);
      return;
    }

    force();
  };

  const handleRowClick = ({ data, event }: RowClickedEvent<Workflow>) => {
    if (!data?.workflowId || event?.defaultPrevented) {
      return;
    }

    const route =
      state === ArtifactStateType.Draft
        ? getWorkflowDetailsRoute(data.workflowId)
        : getWorkflowRunsRoute(data.workflowId);
    navigate(route);
  };

  const resetSelection = () => {
    gridRef.current?.api.deselectAll();
  };

  const columns = useMemo(() => getWorkflowTableColumns(state), []);
  const selectedRowNodes = gridRef.current?.api?.getSelectedNodes();
  const selectedRowsLength = gridRef.current?.api?.getSelectedRows()?.length ?? 0;
  const selectedWorkflowIds =
    selectedRowNodes?.map(rowNode => rowNode.data?.workflowId ?? '').filter(n => isString(n)) ?? [];
  const selectionFeedback = selectedRowsLength === 0 ? '' : `${selectedRowsLength} selected`;

  const { openArchiveUnarchiveModal } = useWorkflowArchiveUnarchive(
    selectedWorkflowIds,
    state ?? ArtifactStateType.Active,
    resetSelection,
  );
  const { openDeleteModal } = useWorkflowDelete(selectedWorkflowIds, resetSelection);

  const bulkActions = useMemo(() => {
    if (state === ArtifactStateType.Draft) {
      return [
        {
          label: 'Delete',
          onClick: openDeleteModal,
          disabled: selectedRowsLength === 0,
        },
      ];
    }
    return [
      {
        label: state === ArtifactStateType.Active ? 'Archive' : 'Unarchive',
        onClick: openArchiveUnarchiveModal,
        disabled: selectedRowsLength === 0,
      },
    ];
  }, [selectedRowsLength]);

  const leftActionsContent = (
    <Horizontal>
      <ActionsToolbar
        isLoading={isLoading}
        artifactState={state}
        searchText={searchText}
        onSearchTextChange={onSearchTextChange}
        showOnlyScheduled={showOnlyScheduled}
        onShowOnlyScheduledCheckboxToggle={onShowOnlyScheduledCheckboxToggle}
        onReload={onReload}
      />
      {bulkActions &&
        bulkActions.map((action, index) => (
          <Button key={index} variant="primary" onClick={action.onClick} disabled={action.disabled}>
            {action.label}
          </Button>
        ))}
      {selectionFeedback && (
        <Horizontal spacing={16}>
          <Text variant="subTitle03" color="dark.3">
            {selectionFeedback}
          </Text>
          <Button variant="subtle" onClick={resetSelection} px="md">
            Clear
          </Button>
        </Horizontal>
      )}
    </Horizontal>
  );

  return (
    <Box h="100%">
      <List<Workflow>
        version={ListVersions.v2}
        isLoading={isLoading}
        gridRef={gridRef}
        columns={columns}
        rowData={workflows}
        onRowClicked={handleRowClick}
        onRowSelection={handleRowSelection}
        paginationProps={{
          totalRows,
          currentPage: currentPage,
          onPageNumberChange: handlePageChange,
          labelRowsPerPage: 'Total Workflows',
          showPaginationLabel: true,
        }}
        leftActionsContent={leftActionsContent}
      />
    </Box>
  );
};
