import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { modelAppEvents } from '../../../../../analytics/modelAppEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Box,
  Button,
  Center,
  Horizontal,
  Image,
  Text,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { ModelWithAppMetadata } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { S3_STORAGE_BASE } from '../../../../../lib/env.util';
import { useModelAppsPreviewQuery } from '../../../../../queries/model-apps/model-apps';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { ErrorState } from '../../../../common/ErrorState';
import { TabStates, WorkspaceCard } from '../../../home/WorkspaceCard';
import { useDashboardSnapshotContext } from '../../../home/snapshot/DashboardSnapshotContext';
import { SnapshotLoading } from '../../../home/snapshot/SnapshotLoading';
import { EmptyPreviewHorizontal } from '../EmptyPreviewHorizontal';
import { ModelAppsPreview } from './ModelAppsPreview';

const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/illustrations/dashboard/model-apps-illustration.svg`;

const modelAppsTitle = 'Create model apps';
const modelAppsDescription =
  'Leverage baseline model to easily engage with your data and observe its performance.';
const modelAppsIllustration = <Image maw={200} src={ILLUSTRATION_URL} />;

export const ModelAppsPreviewContainer = () => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { userId, workspaceId } = useAppMetadata();
  const { getModelAppDetailsRoute } = useAbsoluteRoutes();

  const { selectedTab, handleTabChange } = useDashboardSnapshotContext();

  const { isLoading, isError, data } = useModelAppsPreviewQuery(
    selectedTab === TabStates.MINE ? userId : undefined,
  );

  const handleNavigateModelApp = (modelId: string) => {
    sendAnalytics(
      modelAppEvents.viewSample({
        workspaceId,
      }),
    );
    navigate(getModelAppDetailsRoute(modelId));
  };

  if (isLoading) {
    return (
      <WorkspaceCard title="Apps" selectedTab={selectedTab} onChangeTab={handleTabChange} isSample>
        <SnapshotLoading compact />
      </WorkspaceCard>
    );
  }

  if (isError || !data) {
    return (
      <WorkspaceCard title="Apps" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <Center h={280} bg="white.0">
          <ErrorState
            compact
            iconName="model"
            title="Error loading model apps"
            subtitle="Please try refreshing the page or contact support"
          />
        </Center>
      </WorkspaceCard>
    );
  }
  const demoModelAppsCount = data.filter(
    (modelApp: ModelWithAppMetadata) => modelApp.demoArtifact,
  ).length;
  const totalModelAppsCount = data.length;

  if (demoModelAppsCount === totalModelAppsCount) {
    return (
      <WorkspaceCard title="Apps" isSample>
        <EmptyPreviewHorizontal
          title={modelAppsTitle}
          description={modelAppsDescription}
          illustration={modelAppsIllustration}
        >
          <Horizontal py="48px">
            {data.map((modelApp, idx) => (
              <Button
                key={idx}
                variant="secondary"
                onClick={() => handleNavigateModelApp(modelApp.modelId)}
                rightIcon={<IconArrowNarrowRight color={theme.colors.blue[6]} stroke={1.5} />}
              >
                <Text variant="subTitle03" color="blue.6">
                  Sample model app 0{idx + 1}
                </Text>
              </Button>
            ))}
          </Horizontal>
        </EmptyPreviewHorizontal>
      </WorkspaceCard>
    );
  }
  return (
    <WorkspaceCard title="Apps" selectedTab={selectedTab} onChangeTab={handleTabChange}>
      <Box>
        <ModelAppsPreview modelApps={data} />
      </Box>
    </WorkspaceCard>
  );
};
