import { IconChartBar, IconChartHistogram, IconTable, IconTableDown } from '@tabler/icons-react';
import first from 'lodash/first';
import { useState } from 'react';
import { ChartTypes } from '../../../../../../charts';
import { Box, Button, Horizontal, Tabs, Text, Vertical } from '../../../../../../design-system/v2';
import { useCsvDownload } from '../../../../../../hooks/useDownloadData';
import { TableView } from '../TableView';
import { useChartSelectionModal } from './ChartSelectionModal';
import { ChartView } from './ChartView';
import { ViewModeTabs } from './ViewModeTabs';

const MENU_ICON_SIZE = 20;
const DEFAULT_CHART_TYPE = ChartTypes.COLUMN;
const MENU_LEFT_ICON_SIZE = 20;

// Prevents showing chart when data is not valid, even if BE suggests a chart
const isDataChartable = (data: Record<string, unknown>[]) => {
  const datum = first(data);
  return datum !== undefined && Object.keys(datum).length === 2;
};

export enum MultiChartViewMode {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

interface MultiChartVizProps {
  title: string;
  rawData: Record<string, unknown>[];
  xColName: string;
  yColName: string;
  xAxisTitle: string;
  yAxisTitle: string;
  defaultViewMode?: MultiChartViewMode;
  defaultChartType?: ChartTypes;
}

export const MultiChartViz = ({
  title,
  rawData,
  xColName,
  yColName,
  xAxisTitle,
  yAxisTitle,
  defaultChartType = DEFAULT_CHART_TYPE,
  defaultViewMode = MultiChartViewMode.TABLE,
}: MultiChartVizProps) => {
  const canShowChart = isDataChartable(rawData);
  const [viewMode, setViewMode] = useState<MultiChartViewMode>(
    canShowChart ? MultiChartViewMode.TABLE : defaultViewMode,
  );
  const [chartType, setChartType] = useState<ChartTypes>(defaultChartType);
  const { handleDownload, isDownloading } = useCsvDownload();

  const { open: openChartSelectionModal } = useChartSelectionModal({
    onSelect: setChartType,
    activeValue: chartType,
  });

  const viewModeTabs = [
    {
      value: MultiChartViewMode.TABLE,
      label: 'Table view',
      icon: <IconTable size={MENU_ICON_SIZE} />,
      content: <TableView content={rawData} />,
    },
    {
      value: MultiChartViewMode.CHART,
      label: 'Chart view',
      icon: <IconChartBar size={MENU_ICON_SIZE} />,
      content: (
        <ChartView
          title={title}
          chartType={chartType}
          rawData={rawData}
          xColName={xColName}
          yColName={yColName}
          xAxisTitle={xAxisTitle}
          yAxisTitle={yAxisTitle}
        />
      ),
    },
  ];

  const handleTabChange = (val: MultiChartViewMode) => {
    setViewMode(val);
  };

  const dataToCsvFormat = (data: Record<string, unknown>[]): string[][] => {
    if (data.length === 0) return [];

    const headers = Object.keys(data[0]);
    const csvData = data.map(row => headers.map(header => String(row[header] ?? '')));

    return [headers, ...csvData];
  };

  const handleCSVDownload = () => {
    const csvData = dataToCsvFormat(rawData);
    handleDownload({
      data: csvData,
      fileName: title,
    });
  };

  return (
    <ViewModeTabs keepMounted value={viewMode} onTabChange={handleTabChange}>
      <Vertical spacing="sm">
        <Horizontal position="apart">
          {canShowChart ? (
            <Tabs.List>
              {viewModeTabs.map(({ value, label, icon }) => (
                <Tabs.Tab key={value} value={value} icon={icon}>
                  <Text span variant="subTitle03">
                    {label}
                  </Text>
                </Tabs.Tab>
              ))}
            </Tabs.List>
          ) : (
            // Box needed since parent uses position: apart
            <Box />
          )}
          {viewMode === MultiChartViewMode.CHART && (
            <Button
              leftIcon={<IconChartHistogram size={MENU_LEFT_ICON_SIZE} />}
              onClick={openChartSelectionModal}
            >
              Change chart
            </Button>
          )}
          {viewMode === MultiChartViewMode.TABLE && (
            <Button
              loading={isDownloading}
              leftIcon={<IconTableDown size={MENU_LEFT_ICON_SIZE} />}
              onClick={handleCSVDownload}
            >
              Download
            </Button>
          )}
        </Horizontal>
        {viewModeTabs.map(({ value, content }) => (
          <Tabs.Panel key={value} value={value}>
            {content}
          </Tabs.Panel>
        ))}
      </Vertical>
    </ViewModeTabs>
  );
};
