import { Skeleton } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import {
  DEFAULT_CITATION_STYLE,
  getReferenceStyleDetails,
} from '../../../../../components/app-store/document-ai/commons/citations';
import { tryParseJSONObject } from '../../../../../components/app-store/document-ai/commons/utils';
import {
  useGetAllReferencesQuery,
  useGetDocumentMetadataQuery,
} from '../../../../../queries/document-ai/copyEdit';

export function getReferencesIdsArray(stringifiedReferences: string) {
  if (tryParseJSONObject(stringifiedReferences)) {
    return JSON.parse(stringifiedReferences);
  }
}

export const MarkovReferenceComponentContainer: React.FC<NodeViewProps> = ({ node }) => {
  /**
   * node.attrs is set when doing addCitationToEditor. Please check implementation there
   * Currently node.attrs = {
   *  documentId: string;
   *  referenceIds: string[]
   * }
   */
  const referenceIds: string[] = getReferencesIdsArray(node.attrs.referenceIds) || [];
  const documentId: string = node.attrs.documentId ?? '';

  const { data: documentMetadata } = useGetDocumentMetadataQuery(documentId);

  const { data: documentReferences, isLoading: isReferencesLoading } = useGetAllReferencesQuery(
    documentId,
    referenceIds,
  );

  const { getInlineReferenceComponent } = getReferenceStyleDetails(
    documentMetadata?.citationStyle ?? DEFAULT_CITATION_STYLE,
  );

  if (isReferencesLoading) {
    return (
      <NodeViewWrapper as="span" className="mkvReference">
        <Skeleton h={10} w={10} />
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="span" className="mkvReference">
      {getInlineReferenceComponent(documentReferences?.references ?? [])}
    </NodeViewWrapper>
  );
};
