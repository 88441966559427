import { MantineNumberSize, MantineSize } from '@mantine/core';

export type HeadingVariants = 'heading01' | 'heading02' | 'heading03' | 'heading04' | 'heading05';

export type TextVariants =
  | 'subTitle01'
  | 'subTitle02'
  | 'subTitle03'
  | 'subTitle04'
  | 'subTitle05'
  | 'bodyShort01'
  | 'bodyShort02'
  | 'bodyShort03'
  | 'bodyLong01'
  | 'bodyLong02'
  | 'bodyLong03'
  | 'small01'
  | 'small02'
  | 'small03'
  | 'overline'
  | 'textLink';

export enum FontWeight {
  LIGHT = 'LIGHT',
  BOLD = 'BOLD',
  REGULAR = 'REGULAR',
  SEMI_BOLD = 'SEMI_BOLD',
}

export const fontWeights: { [key in FontWeight]: number } = {
  [FontWeight.LIGHT]: 300,
  [FontWeight.REGULAR]: 400,
  [FontWeight.SEMI_BOLD]: 600,
  [FontWeight.BOLD]: 700,
};

export enum LineHeight {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  XXL = 'XXL',
  XXXL = 'XXXL',
}

export const lineHeight = {
  [LineHeight.XS]: '16px',
  [LineHeight.SM]: '20px',
  [LineHeight.MD]: '24px',
  [LineHeight.LG]: '28px',
  [LineHeight.XL]: '32px',
  [LineHeight.XXL]: '36px',
  [LineHeight.XXXL]: '56px',
};

export enum LetterSpacing {
  REGULAR = 'REGULAR',
  SPACED = 'SPACED',
  WIDE = 'WIDE',
}

export const letterSpacing = {
  [LetterSpacing.REGULAR]: '0em',
  [LetterSpacing.SPACED]: '0.002em',
  [LetterSpacing.WIDE]: '.05em',
};

export interface VariantStyle {
  fontSize: MantineSize;
  fontWeight: FontWeight;
  lineHeight: LineHeight;
  letterSpacing?: LetterSpacing;
}

export const textVariantToStyle: { [key in TextVariants]: VariantStyle } = {
  subTitle01: {
    fontSize: 'xl',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.LG,
  },
  subTitle02: {
    fontSize: 'lg',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.MD,
  },
  subTitle03: {
    fontSize: 'md',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.MD,
  },
  subTitle04: {
    fontSize: 'sm',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.SM,
  },
  subTitle05: {
    fontSize: 'xs',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.SM,
  },
  bodyShort01: {
    fontSize: 'lg',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.MD,
  },
  bodyShort02: {
    fontSize: 'md',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.SM,
  },
  bodyShort03: {
    fontSize: 'sm',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.SM,
  },
  bodyLong01: {
    fontSize: 'lg',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.LG,
  },
  bodyLong02: {
    fontSize: 'md',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.MD,
  },
  bodyLong03: {
    fontSize: 'sm',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.MD,
  },
  small01: {
    fontSize: 'xs',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.SM,
  },
  small02: {
    fontSize: 'xs',
    fontWeight: FontWeight.LIGHT,
    lineHeight: LineHeight.SM,
  },
  small03: {
    fontSize: 'xxs',
    fontWeight: FontWeight.REGULAR,
    lineHeight: LineHeight.SM,
  },
  overline: {
    fontSize: 'xxs',
    fontWeight: FontWeight.BOLD,
    lineHeight: LineHeight.XS,
    letterSpacing: LetterSpacing.WIDE,
  },
  textLink: {
    fontSize: 'xs',
    fontWeight: FontWeight.SEMI_BOLD,
    lineHeight: LineHeight.SM,
  },
};

interface HeadingVariantStyle {
  order: 1 | 2 | 3 | 4 | 5;
  fontWeight: FontWeight;
  fontSize?: MantineNumberSize;
  lineHeight?: MantineNumberSize;
  letterSpacing?: string;
}

export const headingVariantToStyle: { [key in HeadingVariants]?: HeadingVariantStyle } = {
  heading01: {
    order: 1,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: 48,
    lineHeight: 56,
    letterSpacing: '-0.015rem',
  },
  heading02: {
    order: 2,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: 28,
    lineHeight: 36,
    letterSpacing: '-0.008rem',
  },
  heading03: {
    order: 3,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: '-0.007rem',
  },
  heading04: {
    order: 4,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: 20,
    lineHeight: 32,
    letterSpacing: '-0.006rem',
  },
  heading05: {
    order: 5,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: '-0.006rem',
  },
};

const allHeadings = ['heading01', 'heading02', 'heading03', 'heading04', 'heading05'];

export const isHeading = (variant: HeadingVariants | TextVariants): variant is HeadingVariants =>
  allHeadings.includes(variant);

export const isTextLink = (variant: TextVariants) => variant === 'textLink';
