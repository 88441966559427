import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';
import { InputVariant, MantineInputVariant } from './constants';

export interface TextInputProps extends Omit<MantineTextInputProps, 'type' | 'variant'> {
  variant?: InputVariant;

  /**
   * value: to be set in the Input
   * This is mandatory prop to make the input controlled
   */
  value: string;

  /**
   * This flag controls the validation of field when
   */
  required?: boolean;

  disabled?: boolean;

  autoFocus?: boolean;

  ariaLabel: string;
}

export const TextInput = forwardRef(
  (
    { variant = 'default', ariaLabel, required, autoFocus, onChange, ...rest }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (!ariaLabel) {
      throw 'ariaLabel prop is a mandatory prop for input';
    }

    return (
      <MantineTextInput
        ref={ref}
        variant={variant as MantineInputVariant}
        aria-label={ariaLabel}
        data-autofocus={autoFocus}
        onChange={onChange}
        withAsterisk={required}
        {...rest}
      />
    );
  },
);

TextInput.displayName = 'TextInput';
