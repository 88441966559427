import { Button, Horizontal, Skeleton, Text, Vertical } from '../../../design-system/v2';
import { useDataSourceDetailsQuery } from '../../../queries/data-sources';
import { StorageMetadataModel, storageConfigMap } from '../../data-sources/cell-renderers/config';

interface ResourceSummaryProps {
  label: string;
  isDataSink: boolean;
  value: string;
  onChange: (value: string) => void;
  isEditable?: boolean;
}

export const ResourceSummary = ({
  value,
  isDataSink,
  label,
  onChange,
  isEditable = true,
}: ResourceSummaryProps): JSX.Element => {
  const query = useDataSourceDetailsQuery(value);

  const isLoading = query.isLoading || query.isIdle;
  const connectorType = query.data?.connectorType;
  const resourceMetadata = query.data?.resourceMetadata as StorageMetadataModel;

  const name =
    connectorType && resourceMetadata
      ? isDataSink
        ? storageConfigMap[connectorType]?.getResourceLocation(resourceMetadata)
        : storageConfigMap[connectorType]?.getResourceName(resourceMetadata) ?? ''
      : '';

  return (
    <Vertical spacing="xs">
      <Text variant="subTitle03">{label}</Text>
      {isLoading ? (
        <Skeleton h={32} w="65%" />
      ) : (
        <Horizontal noWrap>
          <Text variant="bodyShort02" lineClamp={1} title={name} sx={{ wordBreak: 'break-all' }}>
            {name}
          </Text>
          {isEditable && <Button onClick={() => onChange('')}>Edit</Button>}
        </Horizontal>
      )}
    </Vertical>
  );
};
