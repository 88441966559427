import { PointOptionsObject, TooltipFormatterContextObject } from 'highcharts';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface BoxPlotTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const BoxPlotTooltip = ({ formatterContext }: BoxPlotTooltipProps) => (
  <TooltipContainer>
    <TooltipHeader>{formatterContext.point.name}</TooltipHeader>
    <TooltipItemRow>
      <span>Minimum: </span>
      {(formatterContext.point as PointOptionsObject).low}
    </TooltipItemRow>
    <TooltipItemRow>
      <span>25%: </span>
      {(formatterContext.point as PointOptionsObject).q1}
    </TooltipItemRow>
    <TooltipItemRow>
      <span>50%: </span>
      {(formatterContext.point as PointOptionsObject).median}
    </TooltipItemRow>
    <TooltipItemRow>
      <span>75%: </span>
      {(formatterContext.point as PointOptionsObject).q3}
    </TooltipItemRow>
    <TooltipItemRow>
      <span>Maximum: </span>
      {(formatterContext.point as PointOptionsObject).high}
    </TooltipItemRow>
  </TooltipContainer>
);
