import { useMediaQuery } from '@mantine/hooks';
import noop from 'lodash/noop';
import { createContext, PropsWithChildren, useContext, useLayoutEffect, useState } from 'react';
import { notifications, useMarkovTheme } from '../../design-system/v2';

interface LayoutMetaData {
  sidebarExpanded: boolean;
  setSidebarExpanded: (isVisible: boolean) => void;
  sidebarMouseEnter: boolean;
  setSidebarMouseEnter: (isMouseEntered: boolean) => void;
}

const initialContext: LayoutMetaData = {
  sidebarExpanded: false,
  setSidebarExpanded: noop,
  sidebarMouseEnter: false,
  setSidebarMouseEnter: noop,
};

const LayoutMetadataContext = createContext<LayoutMetaData>(initialContext);

export const useLayoutContext = () => useContext(LayoutMetadataContext);

export const LayoutContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [sidebarMouseEnter, setSidebarMouseEnter] = useState(false);

  const theme = useMarkovTheme();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const value: LayoutMetaData = {
    sidebarExpanded,
    setSidebarExpanded,
    sidebarMouseEnter,
    setSidebarMouseEnter,
  };

  // Show a warning notification for mobile view
  // Hide the notification for larger screens
  useLayoutEffect(() => {
    const NOTIFICATION_ID = 'mobile-view-banner-notification';

    if (isMobileScreen) {
      notifications.show({
        id: NOTIFICATION_ID,
        styles: { root: { width: '100%' } },
        variant: 'warning',
        autoClose: false,
        title: 'Unsupported screen resolution',
        message:
          'The app is not optimized for mobile. Please switch to desktop for a better user experience',
      });

      return;
    }

    notifications.hide(NOTIFICATION_ID);
  }, [isMobileScreen]);

  return <LayoutMetadataContext.Provider value={value}>{children}</LayoutMetadataContext.Provider>;
};
