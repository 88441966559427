import {
  useGeneratePromptMutation,
  usePromptSuggestionsQuery,
} from '../../../../queries/app-builder/app-builder';
import GeneratePromptModal from './GeneratePromptModal';

export interface GeneratePromptModalContainerProps {
  // TODO: Pick appId from useParams to avoid prop drilling
  // DEBUG: useParams returns {} inside this component
  appId: string;
  onGeneratedPromptSelect: (response: string) => void;
  closeModal: () => void;
}

const GeneratePromptModalContainer = ({
  appId,
  onGeneratedPromptSelect,
  closeModal,
}: GeneratePromptModalContainerProps) => {
  const { isLoading: isLoadingSuggestions, data: suggestionsList } =
    usePromptSuggestionsQuery(appId);
  const {
    isLoading: isGeneratingPrompt,
    data: generatedPrompt,
    mutate: generatePrompt,
    isError: isGeneratePromptError,
  } = useGeneratePromptMutation(appId ?? '');

  const handleResponseSelect: GeneratePromptModalContainerProps['onGeneratedPromptSelect'] = (
    ...args
  ) => {
    onGeneratedPromptSelect(...args);
    closeModal();
  };

  return (
    <GeneratePromptModal
      isLoadingSuggestions={isLoadingSuggestions}
      suggestionsList={suggestionsList ?? []}
      onGeneratedPromptSelect={handleResponseSelect}
      isGeneratingPrompt={isGeneratingPrompt}
      generatedPrompt={generatedPrompt?.data?.modelResponse ?? ''}
      onGenerateRequest={question => generatePrompt({ question })}
      isGeneratePromptError={isGeneratePromptError}
    />
  );
};

export default GeneratePromptModalContainer;
