import { IconDots } from '@tabler/icons-react';
import { ActionIcon, Box, Button, Horizontal, Menu, ScrollArea } from '../../../design-system/v2';
import { WorkspaceMember } from '../../../generated/api';
import { UserDetail } from '../../settings/UserDetail';

interface SharedUsersProps {
  users: WorkspaceMember[];
  onRemoveUser: (userId: string) => void;
}

interface UserRowProps {
  user: WorkspaceMember;
  onRemoveUser: SharedUsersProps['onRemoveUser'];
}

const UserRow = ({ user, onRemoveUser }: UserRowProps) => {
  const handleRemoveUser = () => {
    onRemoveUser(user.userId);
  };

  return (
    <Horizontal
      key={user.userId}
      position="apart"
      my="xs"
      p="xs"
      sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}
    >
      <UserDetail avatar={user.userDetails?.avatar} name={user.userDetails?.name ?? ''} email="" />
      <Menu withArrow position="left">
        <Menu.Target>
          <ActionIcon>
            <IconDots />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Button onClick={handleRemoveUser}>Remove</Button>
        </Menu.Dropdown>
      </Menu>
    </Horizontal>
  );
};

export const SharedUsers = ({ users, onRemoveUser }: SharedUsersProps) => (
  <ScrollArea.Autosize scrollbarSize={6} mah={200}>
    <Box mah={150}>
      {users.map(user => (
        <UserRow key={user.userId} user={user} onRemoveUser={onRemoveUser} />
      ))}
    </Box>
  </ScrollArea.Autosize>
);
