import { createContext, PropsWithChildren, useContext } from 'react';
import { useSnippetDetail } from './SnippetContext';

export interface PublicSnippetContext {
  chartId: string;
  snippetId: string;
}

const defaultDetails = {
  chartId: '',
  snippetId: '',
};

const SnippetContext = createContext<PublicSnippetContext>(defaultDetails);

export const usePublicSnippetDetail = () => {
  const context = useContext(SnippetContext);
  if (!context) {
    return defaultDetails;
  }
  return context;
};

interface SnippetContextProviderProps {
  chartId: string;
}

export const PublicSnippetContextProvider = ({
  children,
  chartId,
}: PropsWithChildren<SnippetContextProviderProps>) => {
  const { snippetId } = useSnippetDetail();

  return (
    <SnippetContext.Provider value={{ chartId, snippetId }}>{children}</SnippetContext.Provider>
  );
};
