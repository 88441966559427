import { createStyles } from '../../../design-system/v2';

export const useWorkspaceSwitchItem = createStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid`,
    borderBottomColor: theme.colors.white[2],
    padding: 16,

    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.blue[0],
    },
  },
  viewingContainer: {
    border: '1px solid',
    borderColor: theme.colors.pink[8],
    borderRadius: '12px',
    padding: '4px 8px',
    backgroundColor: theme.colors.white[0],
  },
}));
