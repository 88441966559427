import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, getRoute } from '../../router/constants';

export enum AppSidePanelOptions {
  ALL_PROJECTS,
  RECENT,
  SHARED_WITH_ME,
  TRASH,
  SETTINGS,
  ANALYTICS,
}

interface DocumentAiAppState {
  activeTab: AppSidePanelOptions;
  setActiveTab: (s: AppSidePanelOptions) => void;
}

export const DocumentAiAppContext = createContext<DocumentAiAppState | undefined>(undefined);

export const useDocumentAiAppContext = () => {
  const context = useContext(DocumentAiAppContext);
  if (!context) {
    throw new Error('Hook must be used within a DocumentAiAppContextProvider');
  }
  return context;
};

export const DocumentAiAppContextProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(
    pathname.includes(getRoute(AppRoutes.COPY_EDIT_SETTINGS))
      ? AppSidePanelOptions.SETTINGS
      : pathname.includes(getRoute(AppRoutes.COPY_EDIT_ANALYTICS))
      ? AppSidePanelOptions.ANALYTICS
      : AppSidePanelOptions.ALL_PROJECTS,
  );

  const value = {
    activeTab,
    setActiveTab,
  };

  return <DocumentAiAppContext.Provider value={value}>{children}</DocumentAiAppContext.Provider>;
};
