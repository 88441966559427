import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ResourceModel,
  S3StorageResourceModel,
  SnowflakeStorageResourceModel,
} from '../../../generated/api';
import { storageConfigMap } from './config';

export const NameRenderer = ({
  value: metadata,
  data,
}: ICellRendererParams<ResourceModel, SnowflakeStorageResourceModel | S3StorageResourceModel>) => {
  const connectorType = data?.connectorType;
  const config =
    connectorType !== undefined && connectorType in storageConfigMap
      ? storageConfigMap[connectorType]
      : undefined;

  return config?.getResourceName(metadata) ?? '--';
};
