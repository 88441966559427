import { ColDef, ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import noop from 'lodash/noop';
import { Horizontal } from '../../design-system/v2';
import { List } from '../../design-system/v2/core/data-display/table';
import { ResourceModel } from '../../generated/api';
import { formatDateTime } from '../../lib/ui';
import { UserInfoContainer } from '../model-apps/UserInfo.container';
import { LocationRenderer } from './cell-renderers/LocationRenderer';
import { NameRenderer } from './cell-renderers/NameRenderer';
import { PreviewRenderer } from './cell-renderers/PreviewRenderer';
import { TypeRenderer } from './cell-renderers/TypeRenderer';

export const getDataSourceColumns = (isDataSink: boolean): ColDef<ResourceModel>[] => {
  const columns: ColDef<ResourceModel>[] = [
    {
      colId: 'name',
      field: 'resourceMetadata',
      headerName: 'Data Source',
      sortable: false,
      cellRenderer: NameRenderer,
      minWidth: 300,
      flex: 1,
    },
    {
      colId: 'location',
      field: 'resourceMetadata',
      headerName: 'Location',
      sortable: false,
      cellRenderer: LocationRenderer,
      flex: 1,
      minWidth: 320,
    },
    {
      colId: 'connectorType',
      field: 'connectorType',
      headerName: 'Type',
      sortable: false,
      cellRenderer: TypeRenderer,
      width: 180,
    },
    {
      colId: 'createdBy',
      field: 'userId',
      headerName: 'Created by',
      sortable: false,
      cellRenderer: ({ value: userId }: ICellRendererParams<ResourceModel, string>) => (
        <Horizontal h="100%" align="center">
          <UserInfoContainer userId={userId} />
        </Horizontal>
      ),
      width: 220,
    },
    {
      colId: 'createDate',
      field: 'createDate',
      headerName: 'Created on',
      sortable: false,
      cellRenderer: ({ value: createDate }: ICellRendererParams<ResourceModel, string>) =>
        formatDateTime(createDate),
      width: 190,
    },
  ];

  if (!isDataSink) {
    columns.push({
      colId: 'preview',
      field: 'resourceId',
      headerName: '',
      sortable: false,
      resizable: false,
      cellRenderer: PreviewRenderer,
      width: 150,
    });
  }

  return columns;
};

interface DataSourcesTableProps {
  gridRef?: React.RefObject<AgGridReact<ResourceModel>>;
  rows: ResourceModel[];
  onRowClicked?: (e: RowClickedEvent<ResourceModel>) => void;
  pagination?: boolean;
  paginationPageSize?: number;
  totalRows?: number;
  currentPage?: number;
  onPageNumberChange?: (page: number) => void;
  leftActionsContent?: JSX.Element;
}

export const DataSourcesTable = ({
  gridRef,
  rows,
  onRowClicked = noop,
  pagination,
  paginationPageSize,
  totalRows,
  currentPage,
  onPageNumberChange,
  leftActionsContent,
}: DataSourcesTableProps): JSX.Element => (
  <List<ResourceModel>
    gridRef={gridRef}
    columns={getDataSourceColumns(false)}
    rowData={rows}
    onRowClicked={onRowClicked}
    pagination={pagination}
    paginationPageSize={paginationPageSize}
    totalRows={totalRows}
    currentPage={currentPage}
    onPageNumberChange={onPageNumberChange}
    leftActionsContent={leftActionsContent}
  />
);
