import { marked } from 'marked';
import { useMemo } from 'react';
import { HTMLPreview, HTMLPreviewProps } from './HTMLPreview';

export interface MarkdownPreviewProps extends Omit<HTMLPreviewProps, 'children'> {
  /**
   * The markdown content to be rendered.
   */
  children: string;
}

/**
 * MarkdownPreview Component
 *
 * This component converts markdown content to HTML
 * and then renders the HTML content using the {@link HTMLPreview} component.
 *
 */
export const MarkdownPreview = ({ children, ...rest }: MarkdownPreviewProps): JSX.Element => {
  const htmlContent = useMemo(() => marked(children, { async: false }), [children]);

  return <HTMLPreview {...rest}>{htmlContent}</HTMLPreview>;
};
