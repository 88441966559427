import { createStyles } from '@mantine/styles';

export const useChartTypesStyles = createStyles(theme => ({
  item: {
    padding: theme.spacing.sm,
    cursor: 'pointer',
  },

  selectedItem: {
    background: theme.colors.blue[0],
    borderRadius: '32px',
  },
}));
