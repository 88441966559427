import noop from 'lodash/noop';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { QuestionTypes } from '../../generated/api';

export interface QuestionTypeContextProps {
  currentQuestionType: QuestionTypes;
  setCurrentQuestionType: Dispatch<SetStateAction<QuestionTypes>>;
}

const defaultValues: QuestionTypeContextProps = {
  currentQuestionType: QuestionTypes.Text2sql,
  setCurrentQuestionType: noop,
};

export interface QuestionTypeProviderProps {
  questionType?: QuestionTypes;
}

const QuestionTypeContext = createContext<QuestionTypeContextProps>(defaultValues);

export const useQuestionTypeContext = () => {
  const context = useContext(QuestionTypeContext);
  if (!context) {
    throw new Error('useQuestionTypeContext should only be used inside the QuestionTypeProvider');
  }
  return context;
};

export const QuestionTypeProvider = ({
  questionType,
  children,
}: PropsWithChildren<QuestionTypeProviderProps>) => {
  const [currentQuestionType, setCurrentQuestionType] = useState<QuestionTypes>(
    questionType ?? defaultValues.currentQuestionType,
  );

  return (
    <QuestionTypeContext.Provider value={{ currentQuestionType, setCurrentQuestionType }}>
      {children}
    </QuestionTypeContext.Provider>
  );
};
