import first from 'lodash/first';
import { Box, Text } from '../../../../../design-system/v2';
import { Table } from '../../../../../design-system/v2/core/data-display/table';

interface TableViewProps {
  content: Record<string, unknown>[];
}

export const TableView = ({ content }: TableViewProps) => {
  const firstRow = first(content);

  if (!firstRow) {
    return (
      <Text>
        Sorry, I am unable to read the data for this query. Can you try again with a different query
      </Text>
    );
  }

  const columnKeys = Object.keys(firstRow);

  const columns = columnKeys.map(cl => ({
    colId: cl,
    field: cl,
    filter: false,
    sortable: false,
    minWidth: 100,
    flex: Number(columnKeys.length < 5),
  }));

  return (
    <Box mah={550} h={content.length * 55 + 60}>
      <Table columns={columns} rowData={content} paginationPageSize={content.length} />
    </Box>
  );
};
