import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { MarkovReferenceItem, ReferenceText } from '../../../../../design-system/v2';
import {
  AuthorInformationModel,
  CitationStyleGuideType,
  CopyEditReference,
  JournalInformationModel,
  Reference,
  ReferenceType,
} from '../../../../../generated/api';
import { getYearFromDate, toCommaSeparatedList } from '../../../../../lib/ui';
import { interleaveComponent } from '../../../../../lib/util-react';
import { DocumentCitationPosition, MISSING_DATE_TEXT } from './constants';

export const dateFromCustomDateString = (dateString: string): Date => {
  try {
    if (dateString === '') {
      return new Date();
    }
    if (dateString.split('-').length === 1) {
      return new Date(dateString);
    }
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  } catch (e) {
    return new Date();
  }
};

// Converts date in DD-MM-YYYY to "Month Date, Year" (eg. August 20, 2024)
export const formatDateCMOS = (dateString: string): string => {
  const dateObject = dateFromCustomDateString(dateString);
  return dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const MAX_DISPLAYED_AUTHORS_CMOS = 3;

// Output of this function:
// If authors.length === 1: INTEXT: Author1.lastName | LIST: Author1.firstName Author1.lastName
// If authors.length === 2: INTEXT: Author1.lastName and Author2.lastName | LIST: Author1.firstName Author1.lastName and Author2.firstName Author2.lastName
// If authors.length === 3: INTEXT: Author1.lastName, Author2.lastName and Author3.lastName | LIST: Author1.firstName Author1.lastName, Author2.firstName Author2.lastName and Author3.firstName Author3.lastName
// If authors.length > 3 : INTEXT: Author1.lastName et al. | LIST: Author1.firstName Author1.lastName et al.
export const getAuthorsTextCMOS = (
  authors: AuthorInformationModel[],
  citationPosition?: DocumentCitationPosition,
): string => {
  if (authors.length === 0) {
    return '';
  }
  const isFullForm = citationPosition === DocumentCitationPosition.LIST;
  const authorNames = authors.map(({ firstName, lastName }, index) => {
    // Handle missing author name
    if (index === 0 && (lastName === 'N/A' || lastName === '')) {
      return 'Unknown';
    }
    // Reverse order of names for first author
    const fullName = index === 0 ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`;
    return isFullForm ? fullName : lastName;
  });
  if (authors.length > MAX_DISPLAYED_AUTHORS_CMOS) {
    return `${first(authorNames)} et al.`;
  }
  return toCommaSeparatedList(authorNames);
};

export const getInlineReferenceComponentCMOS = (references: CopyEditReference[]): JSX.Element => {
  const prefix = '(';
  const suffix = ')';
  const delimiter = '; ';

  const referenceJSX = references.map(reference => (
    <MarkovReferenceItem
      key={reference.referenceId}
      reference={reference}
      referenceStyles={CitationStyleGuideType.Cmos}
    />
  ));

  return (
    <span>
      <ReferenceText value={prefix ?? ' '} />
      {references &&
        interleaveComponent(referenceJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
      <ReferenceText value={suffix ?? ' '} />
    </span>
  );
};

export const getInlineReferenceTextCMOS = (reference: Reference): string => {
  const { referenceType, authors } = reference;

  let text = '';

  const referenceDetails =
    referenceType === ReferenceType.JournalArticle
      ? reference.journalReference
      : reference.referenceType === ReferenceType.Website
      ? reference.websiteReference
      : reference.othersReference;

  const authorsText = !isEmpty(authors)
    ? getAuthorsTextCMOS(authors, DocumentCitationPosition.INTEXT)
    : referenceDetails?.title ?? 'Unknown';
  const hasDate =
    !!referenceDetails?.publishedDate && referenceDetails?.publishedDate !== MISSING_DATE_TEXT;
  const dateText = hasDate
    ? getYearFromDate(referenceDetails.publishedDate ?? '')
    : MISSING_DATE_TEXT;

  text += `${authorsText} ${dateText}.`;

  if (reference.referenceType === ReferenceType.JournalArticle) {
    const { totalPages } = reference.journalReference as JournalInformationModel;
    if (totalPages) {
      text += `, ${totalPages}`;
    }
  }

  return text;
};

export const getListReferenceTextCMOS = (reference: Reference): string => {
  const citationPosition = DocumentCitationPosition.LIST;

  if (reference.referenceType === ReferenceType.JournalArticle) {
    const { title, authors, journalReference } = reference;
    // Article author(s)
    const authorsText = getAuthorsTextCMOS(authors, citationPosition);
    const formattedAuthors = authorsText === '' ? 'Unknown' : authorsText;
    let text = `${formattedAuthors}.`;
    // Year
    if (journalReference?.publishedDate && journalReference?.publishedDate !== MISSING_DATE_TEXT) {
      text += ` ${getYearFromDate(journalReference.publishedDate)}.`;
    } else {
      text += MISSING_DATE_TEXT;
    }
    // Article title
    text += ` "${title}"`;
    // Journal name, volume, and issue
    // TODO: These should be required fields
    text +=
      ` ${journalReference?.title ?? ''}` +
      ` ${journalReference?.publicationVolume ?? ''}` +
      ` (${journalReference?.publicationIssue ?? ''})`;
    // Page range
    if (journalReference?.totalPages) {
      text += `: ${journalReference.totalPages}.`;
    }
    return text;
  } else if (reference.referenceType === ReferenceType.Website) {
    const { title, authors, websiteReference, accessedDate } = reference;
    const hasAuthors = !isEmpty(authors);
    // Author(s) if available, else website name
    let text = hasAuthors
      ? `${getAuthorsTextCMOS(authors, citationPosition)}.`
      : `${reference.websiteReference?.title ?? ''}.`;
    // Year published, else 'n.d.' (no date)
    if (websiteReference?.publishedDate && websiteReference?.publishedDate !== MISSING_DATE_TEXT) {
      text += ` ${getYearFromDate(websiteReference.publishedDate)}.`;
    } else {
      text += MISSING_DATE_TEXT;
    }
    // Page title
    text += ` "${title}"`;
    // Website name, unless already shown initially
    if (hasAuthors && websiteReference?.title) {
      text += ` ${websiteReference.title}.`;
    }
    // Published date if available, else accessed date
    if (websiteReference?.publishedDate && websiteReference?.publishedDate !== MISSING_DATE_TEXT) {
      text += ` ${formatDateCMOS(websiteReference.publishedDate)}.`;
    } else if (accessedDate) {
      text += ` Accessed ${formatDateCMOS(accessedDate)}.`;
    }
    return text;
  } else {
    const { title, authors, othersReference, accessedDate } = reference;
    const hasAuthors = !isEmpty(authors);
    // Author(s) if available, else website name
    let text = hasAuthors
      ? `${getAuthorsTextCMOS(authors, citationPosition)}.`
      : `${othersReference?.title ?? ''}.`;
    // Year published, else 'n.d.' (no date)
    if (othersReference?.publishedDate && othersReference?.publishedDate !== MISSING_DATE_TEXT) {
      text += ` ${getYearFromDate(othersReference.publishedDate)}.`;
    } else {
      text += MISSING_DATE_TEXT;
    }
    // Page title
    text += ` "${title}."`;
    // Website name, unless already shown initially
    if (hasAuthors && othersReference?.title) {
      text += ` ${othersReference.title}.`;
    }
    // Published date if available, else accessed date
    if (othersReference?.publishedDate && othersReference?.publishedDate !== MISSING_DATE_TEXT) {
      text += ` ${formatDateCMOS(othersReference.publishedDate)}.`;
    } else if (accessedDate) {
      text += ` Accessed ${formatDateCMOS(accessedDate)}.`;
    }
    return text;
  }
};
