import { Breadcrumbs } from '@mantine/core';
import { IconArrowLeft, IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Box,
  Button,
  Horizontal,
  Text,
  TextInput,
  Vertical,
  useDebouncedValue,
  useInputState,
} from '../../../../../../design-system/v2';
import { AddRuleSet } from './AddRuleSet';
import { CustomRulesWrapper } from './CustomRules/CustomRules.wrapper';
import { RulesSetListContainer } from './RulesSet.container';

export const RuleSetWrapper = () => {
  const [selectedRuleSet, setRuleSet] = useState({
    id: '',
    name: '',
  });

  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 200);

  const resetSelectedRuleSet = () => {
    setRuleSet({ id: '', name: '' });
  };

  const items = [
    { title: 'Rules Set', onClick: resetSelectedRuleSet },
    { title: selectedRuleSet.name },
  ].map((item, i) => (
    <Button
      key={i}
      onClick={item.onClick}
      compact
      leftIcon={item.onClick ? <IconArrowLeft width={24} height={14} /> : ''}
    >
      {item.title}
    </Button>
  ));

  if (!!selectedRuleSet.id) {
    return (
      <Box mt="lg">
        <Horizontal noWrap align="center" spacing="xs">
          <Breadcrumbs>{items}</Breadcrumbs>
        </Horizontal>

        <CustomRulesWrapper ruleSetId={selectedRuleSet.id} ruleSetName={selectedRuleSet.name} />
      </Box>
    );
  }

  const handleRowClick = (id: string, ruleName: string) => {
    if (id) {
      setRuleSet({ id, name: ruleName });
    }
  };

  return (
    <Box p="xl">
      <Text variant="subTitle01">Custom Style Rules</Text>
      <Vertical mt="xl" spacing="lg">
        <Horizontal position="apart">
          <TextInput
            value={searchFilter}
            onChange={setSearchFilter}
            ariaLabel="Search Rules set"
            placeholder="Search rule set..."
            icon={<IconSearch size={18} />}
          />
          <AddRuleSet />
        </Horizontal>
        <RulesSetListContainer searchFilter={debouncedSearchFilter} onRowClicked={handleRowClick} />
      </Vertical>
    </Box>
  );
};
