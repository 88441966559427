import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { toLower } from 'lodash';
import { Text, notifications } from '../../../../design-system/v2';
import { logger } from '../../../../initializers/logging';
import { useWorkflowScheduleDeleteMutation } from '../../../../queries/workflows/list/list';

export const useWorkflowScheduleDelete = (
  workflowId: string,
  resetWorkflowSelection?: () => void,
) => {
  const displayName = 'scheduled runs';
  const displayState = 'Stop';
  const headingText = `${displayState} ${displayName}`;
  const subheading = `Are you sure you would like to permanently ${toLower(
    displayState,
  )} the  ${displayName} for this workflow?`;

  const handleSelectionReset = () => {
    resetWorkflowSelection?.();
  };

  const { mutateAsync: deleteWorkflowSchedule } = useWorkflowScheduleDeleteMutation(workflowId);

  const onConfirmDeletion = async () => {
    try {
      await deleteWorkflowSchedule();
      handleSelectionReset();
      notifications.success(`${displayState}d scheduling ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${toLower(displayState)} scheduling ${displayName}`);
      logger.error(error);
    }
  };

  const openDeleteScheduleModal = () =>
    openConfirmModal({
      title: <Text variant="heading03">{headingText}</Text>,
      size: 'lg',
      labels: { confirm: 'Delete Schedule', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => closeAllModals(),
      onConfirm: () => onConfirmDeletion(),
      children: <Text variant="bodyShort01">{subheading}</Text>,
    });

  return { openDeleteScheduleModal };
};
