import { useMemo } from 'react';
import { useAddChartsContext } from '../../../../../../contexts/snippets/add-charts/AddChartsContext';
import { SegmentType } from '../../../../../../generated/api';
import { ChartTypes } from '../../analysers/SelectAnalysis.container';
import { useGetAnalysis } from './use-get-analysis';
import { useGetBasicInfoAnalysers } from './use-get-basic-info';
import { useGetDataProfileAnalysers } from './use-get-data-profile';

export const useGetAllAnalysers = (
  selectedChart: string,
  selectedSegment: SegmentType,
  selectedVariable = '',
  isUnlabeled: boolean,
  dataQualityScore?: number,
) => {
  const { currSelectedDataset: datasetId } = useAddChartsContext();

  const {
    analysersTypes,
    analysersChartList = [],
    isLoading: analysisLoading,
  } = useGetAnalysis(datasetId, selectedChart);

  const {
    basicInfoAnalysersTypes,
    basicInfoAnalysersCharts = [],
    isLoading: basicInfoLoading,
  } = useGetBasicInfoAnalysers(
    datasetId,
    selectedChart,
    selectedSegment,
    isUnlabeled,
    dataQualityScore,
  );

  const {
    dataProfileAnalysersTypes,
    dataProfileAnalysersCharts = [],
    isLoading: dataProfileLoading,
  } = useGetDataProfileAnalysers(datasetId, selectedChart, selectedSegment, selectedVariable);

  const allChartsTypes = useMemo(
    () => ({
      [ChartTypes.BASIC_INFO]: basicInfoAnalysersTypes.map(type => ({ name: type, id: type })),
      [ChartTypes.DATA_PROFILE]: dataProfileAnalysersTypes.map(type => ({ name: type, id: type })),
      [ChartTypes.ANALYSIS]: analysersTypes.map(type => ({ name: type, id: type })),
    }),
    [analysersTypes, basicInfoAnalysersTypes, dataProfileAnalysersTypes],
  );

  const chartsList = [
    ...basicInfoAnalysersCharts,
    ...dataProfileAnalysersCharts,
    ...analysersChartList,
  ];

  return {
    allChartsTypes,
    chartsList,
    isLoading: analysisLoading || basicInfoLoading || dataProfileLoading,
  };
};
