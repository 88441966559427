import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { IconFileText } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import first from 'lodash/first';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { copyEditEvents } from '../../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { Badge, Box, Card, Horizontal, Text } from '../../../../design-system/v2';
import { Table } from '../../../../design-system/v2/core/data-display/table';
import {
  AppIdType,
  DocumentResponse,
  DocumentStatusType,
  StyleGuideType,
  WorkspaceMember,
} from '../../../../generated/api';
import { sendAnalytics } from '../../../../initializers/analytics';
import { formatDateTime } from '../../../../lib/ui';
import { useGetWorkspaceMembersQuery } from '../../../../queries/account/workspace';
import { UserAvatarGroup } from '../../../common/UserAvatarGroup';
import { UserInfoContainer } from '../../../model-apps/UserInfo.container';
import { AppTableActions } from './app-project/AppTableActions';
import { getColorFromStatus, getLLMDisplayName } from './utils';

interface AppTableProps {
  variant: AppIdType;
  documentsList: DocumentResponse[];
  projectId?: string;
  navigateRoute: (documentId: string, projectId?: string) => string;
}

const getTableColumns = (
  appId: string,
  projectId: string,
  showLlmType: boolean,
  showExtraRowsForDemo: boolean,
  showStyleGuide: boolean,
  workspaceMembers: WorkspaceMember[],
) => [
  {
    colId: 'documentName',
    headerName: 'Document Name',
    field: 'documentName',
    flex: 1,
    minWidth: 250,
    sortable: true,
    cellRenderer: ({ value: documentName }: ICellRendererParams<DocumentResponse, string>) => (
      <Horizontal noWrap pt="lg">
        <IconFileText style={{ flexShrink: 0 }} />
        <Text variant="subTitle04">{documentName}</Text>
      </Horizontal>
    ),
  },
  {
    colId: 'uploadedBy',
    headerName: 'Owner',
    field: 'uploadedBy',
    width: 200,
    sortable: false,
    cellRenderer: ({ value: uploadedBy }: ICellRendererParams<DocumentResponse, string>) => (
      <Box pt="lg">
        <UserInfoContainer userId={uploadedBy} avatarSize={22} />
      </Box>
    ),
  },
  ...(showExtraRowsForDemo
    ? [
        {
          colId: '',
          headerName: 'Co-editor',
          field: 'editor',
          width: 200,
          sortable: false,
          cellRenderer: ({ value: uploadedBy }: ICellRendererParams<DocumentResponse, string>) => (
            <Box pt="lg">
              <UserAvatarGroup users={workspaceMembers.slice(0, 2)} showActive size={28} />
            </Box>
          ),
        },
        {
          colId: '',
          headerName: 'Reviewer',
          field: 'reviewer',
          width: 200,
          sortable: false,
          cellRenderer: ({ value: uploadedBy }: ICellRendererParams<DocumentResponse, string>) => (
            <Box pt="lg">
              <UserInfoContainer
                userId={first(workspaceMembers)?.userId ?? uploadedBy}
                avatarSize={22}
              />
            </Box>
          ),
        },
      ]
    : []),
  ...(showLlmType
    ? [
        {
          colId: 'llmType',
          headerName: 'LLM Type',
          field: 'llmType',
          width: 200,
          sortable: false,
          cellRenderer: ({ value: llmType }: ICellRendererParams<DocumentResponse, string>) => (
            <Badge variant="gradient" radius="xs">
              {getLLMDisplayName(llmType)}
            </Badge>
          ),
        },
      ]
    : []),

  ...(showStyleGuide
    ? [
        {
          colId: 'styleGuides',
          headerName: 'Style Guide',
          field: 'styleGuides',
          width: 150,
          sortable: false,
          cellRenderer: ({
            value: styleGuides,
          }: ICellRendererParams<DocumentResponse, Array<StyleGuideType>>) => {
            if (styleGuides.length === 0) {
              return <Badge color="grape">NONE</Badge>;
            }
            return (
              <Badge color={styleGuides[0] === StyleGuideType.None ? 'gray' : 'grape'}>
                {styleGuides[0]}
              </Badge>
            );
          },
        },
      ]
    : []),
  {
    colId: 'status',
    headerName: 'Status',
    field: 'status',
    width: 150,
    sortable: false,
    cellRenderer: ({
      value: status,
    }: ICellRendererParams<DocumentResponse, DocumentStatusType>) => (
      <Badge color={getColorFromStatus(status)}>{status}</Badge>
    ),
  },

  {
    colId: 'createDate',
    headerName: 'Create Date',
    field: 'createDate',
    width: 200,
    filter: false,
    cellRenderer: ({ value: createDate }: ICellRendererParams<DocumentResponse, string>) => {
      const date = formatDateTime(createDate);
      return (
        <Text variant="small01" pt="lg">
          {date}
        </Text>
      );
    },
  },
  {
    colId: 'documentId',
    field: 'documentId',
    headerName: ' ',
    sortable: false,
    suppressMenu: false,
    filter: false,
    cellRenderer: ({ value: documentId, data }: ICellRendererParams<DocumentResponse, string>) => (
      <Box h="100%" onClick={(e: React.MouseEvent) => e.preventDefault()}>
        <AppTableActions appId={appId} projectId={projectId} documentId={documentId} data={data} />
      </Box>
    ),
    width: 100,
  },
];

export const AppTable = ({
  variant,
  documentsList,
  projectId,
  navigateRoute,
}: AppTableProps): JSX.Element => {
  const navigate = useNavigate();
  const { featureStoreModelSelection, featureCopyEditDemoUsers } = useFlags();

  const { data: workspaceMembers } = useGetWorkspaceMembersQuery();
  const { workspaceId } = useAppMetadata();
  const isCopyEdit = variant === AppIdType.CopyEdit;
  const columns = useMemo(
    () =>
      getTableColumns(
        variant,
        projectId ?? '',
        isCopyEdit && featureStoreModelSelection,
        isCopyEdit && featureCopyEditDemoUsers,
        isCopyEdit,
        workspaceMembers?.workspaceMembers ?? [],
      ),
    [featureStoreModelSelection, featureCopyEditDemoUsers, workspaceMembers],
  );

  const handleRowClick =
    (projectId?: string) =>
    ({ data, event }: RowClickedEvent<DocumentResponse, any>) => {
      if (event?.defaultPrevented) {
        return null;
      }

      const documentId = data?.documentId;
      if (documentId) {
        if (projectId) {
          sendAnalytics(
            copyEditEvents.documents.opened({
              workspaceId: workspaceId,
              projectId: projectId,
              documentId: documentId,
            }),
          );
          navigate(navigateRoute(documentId, projectId));
          return;
        }
        navigate(navigateRoute(documentId));
      }
    };

  return (
    <Card h="100%" aria-label="Documents Table">
      <Table
        columns={columns}
        getRowClass={() => 'row-pointer'}
        rowData={documentsList}
        onRowClicked={handleRowClick(projectId)}
        aria-rowcount={documentsList.length}
        aria-colcount={columns.length}
        aria-multiselectable="false"
      />
    </Card>
  );
};
