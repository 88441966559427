import {
  createPolymorphicComponent,
  createStyles,
  Card as MantineCard,
  CardProps as MantineCardProps,
  MantineShadow,
} from '@mantine/core';
import { forwardRef } from 'react';

export interface CardProps extends MantineCardProps {
  hoverEffect?: boolean;
}

interface CardParams {
  hoverEffect?: boolean;
  shadow?: MantineShadow;
}

const useStyles = createStyles<string, CardParams>((theme, { hoverEffect, shadow = 'md' }) => ({
  container: {
    cursor: hoverEffect ? 'pointer' : 'auto',

    '&:hover': hoverEffect
      ? {
          boxShadow: theme.shadows[shadow],
          borderColor: theme.colors.blue[6],
        }
      : null,
  },
}));

// NOTE: Mantine derives card border from theme colors:
// gray.3 for light theme / gray.4 for dark theme
export const _Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, shadow, hoverEffect = true, ...rest }, ref): JSX.Element => {
    const { classes, cx } = useStyles({ hoverEffect, shadow: shadow });

    return (
      <MantineCard radius="xs" ref={ref} className={cx(classes.container, className)} {...rest}>
        {children}
      </MantineCard>
    );
  },
) as any;

_Card.Section = MantineCard.Section;
_Card.displayName = 'Card';

export const Card = createPolymorphicComponent<'div', CardProps, { Section: typeof _Card.Section }>(
  _Card,
);
