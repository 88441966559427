import { IconInfoCircle } from '@tabler/icons-react';
import { useState } from 'react';
import { ActionIcon, Box, Button, Horizontal, Text, Tooltip } from '../../../design-system/v2';
import { useDataSourceCredentialList } from '../../../queries/datasets/registration';
import { AddCredentialsContainer } from '../../dataset-registration/add-credentials/AddCredentials.container';
import { DatasetRegistrationContextProvider } from '../../dataset-registration/context/DatasetRegistrationContext';
import { SettingsSection } from '../SettingsSection';
import { CredentialListContainer } from './CredentialsList.container';

const CredentialsHeader = (): JSX.Element => {
  const [isAddCredentialModalVisible, setIsAddCredentialModalVisible] = useState(false);
  const { data } = useDataSourceCredentialList();

  const showAddCredentialModal = () => {
    setIsAddCredentialModalVisible(true);
  };

  const closeAddCredentialModal = () => {
    setIsAddCredentialModalVisible(false);
  };

  return (
    <>
      <Horizontal m={0} w="100%" position="apart">
        <Horizontal spacing="xs">
          <Text variant="subTitle02">
            Credentials {data && data.length ? `(${data.length})` : ''}
          </Text>
          <Tooltip
            multiline
            arrowSize={8}
            events={{ hover: true, focus: true, touch: true }}
            maw={240}
            withArrow
            position="right"
            label="Credentials are a set of identifying information that allow MarkovML to access your secure cloud storage services."
          >
            <ActionIcon variant="transparent" color="dark.4">
              <IconInfoCircle size={20} />
            </ActionIcon>
          </Tooltip>
        </Horizontal>
        <Button variant="outline" onClick={showAddCredentialModal}>
          Add credential
        </Button>
      </Horizontal>
      {/* TODO: Refactor credential modal  */}
      {isAddCredentialModalVisible && <AddCredentialsContainer onClose={closeAddCredentialModal} />}
    </>
  );
};

export const CredentialContainer = (): JSX.Element => (
  // TODO: Temp fix, to get workflow ID. Need to add a proper flow
  <DatasetRegistrationContextProvider>
    <SettingsSection title={<CredentialsHeader />}>
      <Box mt={24}>
        <CredentialListContainer />
      </Box>
    </SettingsSection>
  </DatasetRegistrationContextProvider>
);
