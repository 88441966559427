import { useListState } from '../../../design-system/v2';
import { ResourceRequest } from '../../../generated/api';

export const useResourceExplorer = () => {
  const [resourcesHierarchy, { append, pop, setState: setResourcesHierarchy }] =
    useListState<ResourceRequest>([]);

  return {
    currentPath: resourcesHierarchy,
    pushResource: (resource: ResourceRequest) => {
      append(resource);
    },
    popResource: () => {
      pop();
    },
    setDepth: (index: number) => {
      setResourcesHierarchy(resourcesHierarchy.slice(0, index));
    },
  };
};
