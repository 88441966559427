import { toLower } from 'lodash';
import { Text, Vertical, closeAllModals, notifications, openModal } from '../../design-system/v2';
import { ArtifactFilterState, ResourceType } from '../../generated/api';
import { logger } from '../../initializers/logging';
import { toPlural } from '../../lib/ui';
import { ArchiveUnarchiveModal } from '../common/table/ArchiveUnarchiveModal';

const getResourceTypeLabel = (resourceType: ResourceType) => {
  if (resourceType === ResourceType.DataOperation) return 'data labeling operation';
  else if (resourceType == ResourceType.DataAnalytics) return 'data_analytics';
  else return resourceType;
};
export const useArchiveUnarchive = (
  count: number,
  resourceType: ResourceType,
  artifactState: ArtifactFilterState,
  archiveHandler: any,
  isError: boolean,
  isLoading: boolean,
) => {
  const resourceTypeLabel = getResourceTypeLabel(resourceType);
  const displayName = count === 1 ? resourceTypeLabel : toPlural(count, resourceTypeLabel);
  const displayState = `${artifactState === ArtifactFilterState.Active ? 'Archive' : 'Unarchive'}`;
  const headingText = `${displayState} ${displayName}`;
  const subheading = `Would you like to ${toLower(displayState)} the selected ${displayName}?`;

  const onClick = async () => {
    try {
      await archiveHandler();
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };

  const openArchiveUnarchiveModal = () =>
    openModal({
      size: 'lg',
      title: <Text variant="heading03">{headingText}</Text>,
      children: (
        <Vertical spacing="lg">
          <Text variant="bodyShort01">{subheading}</Text>
          <ArchiveUnarchiveModal onClick={onClick} isError={isError} isLoading={isLoading} />
        </Vertical>
      ),
    });

  return {
    onClick,
    openArchiveUnarchiveModal,
  };
};
