import { CellProps } from 'react-table';
import { Box, Text, TextVariants } from '../../../design-system/v2';
import { ExperimentWithTags } from '../../../generated/api';
import { TableRowName } from '../../common/TableRowName';

interface ExperimentsNameCellProps extends CellProps<ExperimentWithTags> {
  textVariant: TextVariants;
}

export const ExperimentsNameCell: React.FC<ExperimentsNameCellProps> = ({
  value: name,
  row,
  textVariant,
}) => (
  <Box w="100%">
    <TableRowName isSampleRecord={row.original.demoArtifact}>
      <Text
        data-testid="experiments-table-experimentName"
        variant={textVariant}
        lineClamp={1}
        title={name}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {name}
      </Text>
    </TableRowName>
  </Box>
);
