import { createStyles } from '../../../../design-system/v2';

export const useStyles = createStyles(theme => ({
  icon: {
    borderRadius: 4,
    width: 36,
    height: 36,
    backgroundColor: theme.colors.gray[1],
    color: theme.colors.gray[7],
  },
}));
