import { MantineThemeOverride } from '@mantine/core';

export const accordionTheme = {
  styles: (theme: MantineThemeOverride, params: Record<string, any>) => ({
    root: {
      panel: { background: 'transparent' },
      control: { background: 'transparent', ':hover': { background: 'transparent' } },
    },
    item: {
      borderBottom: 'none',
    },
    control: {
      padding: '4px 8px',
    },
  }),
};
