import { useEffect } from 'react';
import { useLogout } from '../components/app-header/account/LogoutButton';
import { FullPageLoader } from '../design-system';

/**
 * Creating a separate logout page to allow user to logout of the application
 * by using URL. Helps in force logging out a user
 */
export const LogoutPage = () => {
  const handleLogout = useLogout();

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <FullPageLoader text="Logging you out..." />;
};
