import { IconSparkles } from '@tabler/icons-react';
import { Horizontal, Text } from '../../../../../design-system/v2';

export interface SuggestedQuestionProps {
  color?: string;
  question: string;
  onClick: (q: string) => void;
}

export const SuggestedQuestion = ({
  color = 'purple',
  question,
  onClick,
}: SuggestedQuestionProps) => (
  <Horizontal
    py="md"
    px="lg"
    bg="gray.1"
    w="450px"
    align="start"
    sx={{
      borderRadius: '5px',
      cursor: 'pointer',
      '&:hover': {
        background: 'white',
      },
    }}
    onClick={() => onClick(question)}
    noWrap
  >
    <IconSparkles color={color} />
    <Text variant="subTitle03">{question}</Text>
  </Horizontal>
);
