import { Avatar, Card, Center, Horizontal, Text, Vertical } from '../../../../design-system/v2';
import { TemplateModel } from '../../../../generated/api';
import { UserInfoContainer } from '../../../model-apps/UserInfo.container';
import { TemplateActions } from '../../../workflows/home/overview/TemplatesActions';
import { getTemplateIcon } from '../../../workflows/home/overview/utils';
import { useTemplateDelete } from '../../../workflows/home/templates/useTemplateDelete';

interface TemplateCardProps {
  templateData: TemplateModel;
  handleFlowButtonClick: (templateData: TemplateModel) => void;
}
const MARKOV_DB = 'markov';
const DEFAULT_AUTHOR = 'MarkovML';
export const TemplateCard = ({
  templateData,
  handleFlowButtonClick,
}: TemplateCardProps): JSX.Element => {
  const { name, author, description, workspaceId } = templateData;

  const { openDeletTemplateeModal } = useTemplateDelete(templateData.templateId);
  const deleteAction = {
    id: 'delete-user-template',
    label: 'Delete',
    handler: openDeletTemplateeModal,
  };

  const actions = [deleteAction];
  /*
  User templates are stored in common db and markov templates are stored in markov
  */
  const showTemplateActions = workspaceId !== MARKOV_DB;
  return (
    <Card
      data-testid={`workflow-template-${templateData.templateId}`}
      onClick={() => handleFlowButtonClick(templateData)}
      radius="sm"
      withBorder
      sx={{ cursor: 'pointer' }}
    >
      <Vertical justify="space-between" align="apart" h="100%">
        <Vertical>
          <Horizontal w="100%" position="apart">
            <Center h={44} w={44} bg="gray.1" sx={{ borderRadius: '4px' }}>
              {getTemplateIcon(templateData.iconUrl, 44)}
            </Center>
            {showTemplateActions && <TemplateActions actions={actions} />}
          </Horizontal>
          <Text variant="subTitle04" color="gray.9" truncate>
            {name}
          </Text>
          <Text variant="subTitle04" color="gray.6" pt="sm" lineClamp={3}>
            {description}
          </Text>
        </Vertical>

        <Horizontal>
          {author === DEFAULT_AUTHOR ? (
            <>
              <Avatar size={24} />
              <Text variant="small01" color="gray.6">
                By {author}
              </Text>
            </>
          ) : (
            <UserInfoContainer userId={author} />
          )}
        </Horizontal>
      </Vertical>
    </Card>
  );
};
