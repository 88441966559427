import { ReactNode } from 'react';
import { ChatBubble } from '../../../../../design-system';
import { ScrollArea, Text, Vertical } from '../../../../../design-system/v2';
import { SuggestedQuestion, SuggestedQuestionProps } from './SuggestedQuestion';

export interface EmptyChatContentProps {
  // Derive prop types from VerticalProps
  height?: string | number;
  titleText?: ReactNode;
  subHeadingText?: ReactNode;
  descriptionText?: ReactNode;
  suggestedQuestions?: SuggestedQuestionProps[];
}

export const EmptyChatContent = ({
  suggestedQuestions,
  titleText = '',
  subHeadingText = '',
  descriptionText = '',
  height = '100%',
}: EmptyChatContentProps) => (
  <ScrollArea.Autosize h={height}>
    <Vertical w="100%" align="center" pt={suggestedQuestions ? '25px' : '150px'}>
      <ChatBubble width={96} />
      <Text variant="heading04" ta="center" maw="75%">
        {titleText}
      </Text>
      <Text variant="subTitle03" ta="center" color="gray.6" maw="75%">
        {subHeadingText}
      </Text>
      <Text variant="bodyShort03" ta="center" color="gray.7" maw="75%">
        {descriptionText}
      </Text>
      {suggestedQuestions?.map((suggestedQuestion, index) => (
        <SuggestedQuestion
          key={index}
          question={suggestedQuestion.question}
          onClick={suggestedQuestion.onClick}
          color={suggestedQuestion.color}
        />
      ))}
    </Vertical>
  </ScrollArea.Autosize>
);
