import { useState } from 'react';
import { Loader } from '../../../design-system';
import { Box, Card, Horizontal, SimpleGrid, Text, Tooltip } from '../../../design-system/v2';
import { SegmentType } from '../../../generated/api';
import { getIsTaskPending } from '../../../lib/analysis';
import { useAnalyserClassDistribution } from '../../../queries/datasets/analysis/analysis';
import { useDatasetDetailsQuery } from '../../../queries/datasets/list';
import { Visualization } from '../../analyser/visualization/Visualization.util';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { getIsImbalanced, segmentToColors } from '../../dataset-details/util';
import { ClassDistribution } from './ClassDistribution';
import { ClassDistributionExpandModal } from './ClassDistributionExpandModal';

const IMBALANCED_TOOLTIP_TEXT =
  'A distribution qualifies as imbalanced when the most frequent class occurs over 5 times as often as the least frequent class';

interface ClassDistributionContainerProps {
  datasetId: string;
  filteredSegment?: SegmentType;
  showBorders?: boolean;
  expandable?: boolean;
}

export const ClassDistributionContainer = ({
  datasetId,
  filteredSegment,
  showBorders = true,
  expandable = true,
}: ClassDistributionContainerProps): JSX.Element => {
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | undefined>(undefined);
  const {
    data: classDistributionsData,
    isLoading,
    isError,
  } = useAnalyserClassDistribution(datasetId);
  const {
    isLoading: isDatasetInfoLoading,
    isError: isDatasetInfoError,
    data: datasetDetails,
  } = useDatasetDetailsQuery(datasetId);

  const allSegmentsClassDistributions = classDistributionsData?.response;
  const classDistributionsStatus = classDistributionsData?.analysisState;

  const classDistributions = filteredSegment
    ? allSegmentsClassDistributions?.filter(
        ({ metadata }) => metadata.segmentType === filteredSegment,
      )
    : allSegmentsClassDistributions ?? [];

  if (isLoading || isDatasetInfoLoading) {
    return (
      <Box h={300}>
        <Loader text="Loading class distribution" />
      </Box>
    );
  }

  if (isError || isDatasetInfoError) {
    return (
      <Card py={64} px={16}>
        <ErrorState
          compact
          iconName="class_distribution"
          title="Error loading class distribution"
          subtitle="Please refresh the page after sometime or contact support"
        />
      </Card>
    );
  }

  if (classDistributionsStatus && getIsTaskPending(classDistributionsStatus)) {
    return (
      <Card py={64} px={16}>
        <EmptyState
          compact
          title="Class distribution analysis in progress"
          subtitle="Results will become available when analysis is complete"
          iconName="class_distribution"
        />
      </Card>
    );
  }

  if (!classDistributions || !classDistributions?.length) {
    return (
      <Card py={64} px={16}>
        <EmptyState compact title="No class distribution available" iconName="class_distribution" />
      </Card>
    );
  }

  const handleExpand = (idx: number) => (viz: Visualization) => {
    setSelectedItemIdx(idx);
  };

  const handleCarouselClose = () => {
    setSelectedItemIdx(undefined);
  };

  const datasetName = datasetDetails?.name ?? '';

  return (
    <>
      <SimpleGrid
        cols={classDistributions.length}
        breakpoints={[
          { maxWidth: 'sm', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
      >
        {classDistributions.map((classDistribution, idx) => {
          const showImbalancedLabel = getIsImbalanced(classDistribution.data);
          const pieColors = segmentToColors[classDistribution.metadata.segmentType];

          return (
            <Box key={classDistribution.metadata.analysisId} h={380} pos="relative">
              <ClassDistribution
                analysis={classDistribution}
                segment={datasetDetails?.segments?.find(
                  segment => segment.segmentType === classDistribution.metadata.segmentType,
                )}
                onClickExpand={handleExpand(idx)}
                numRecords={datasetDetails?.numRecords as number}
                colors={pieColors}
                legendsTitle="Classes"
                showBorders={showBorders}
                expandable={expandable}
              />
              {showImbalancedLabel && (
                <Box pos="absolute" top={60} left={52}>
                  <Tooltip
                    label={IMBALANCED_TOOLTIP_TEXT}
                    withArrow
                    withinPortal
                    multiline
                    maw={320}
                  >
                    <Horizontal spacing="xs" px="lg" py="xs" bg="red.2" sx={{ borderRadius: 16 }}>
                      <Text variant="bodyShort03">Imbalanced</Text>
                    </Horizontal>
                  </Tooltip>
                </Box>
              )}
            </Box>
          );
        })}
      </SimpleGrid>
      <ClassDistributionExpandModal
        datasetName={datasetName}
        list={classDistributions}
        currentIndex={selectedItemIdx}
        onCloseCarousel={handleCarouselClose}
        datasetDetails={datasetDetails}
      />
    </>
  );
};
