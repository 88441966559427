import styled from 'styled-components';
import { Box } from '../../design-system';

interface CircleProps {
  radius: number;
  background: string;
  // TODO:: Add some way to identify if these are valid css properties
  setAdditionalProperties?: () => string;
}

export const Circle = styled(Box)<CircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: ${props => props.radius}px;
  width: ${props => props.radius}px;
  background: ${props => props.background};
  ${props => (props.setAdditionalProperties ? props.setAdditionalProperties() : '')}
`;
