import constant from 'lodash/constant';
import { useState } from 'react';
import {
  Accordion,
  Badge,
  Box,
  Checkbox,
  Flex,
  Horizontal,
  Text,
  Tooltip,
  Vertical,
} from '../../../design-system/v2';
import { DatasetTasklet } from '../../../generated/api';
import { InfoTooltip } from '../InfoTooltip';

interface TaskletGroupAccordionProps {
  taskletGroupId: string;
  taskletGroupName: string;
  description?: string;
  tasklets: DatasetTasklet[];
  getIsDisabled?: (taskletId: string) => boolean;
  getDisabledLabel?: (taskletId: string) => string;
  getIsChecked: (taskletId: string, isDefault?: boolean) => boolean;
  onClickCheckbox: (checked: boolean, taskletId: string) => void;
}

export const TaskletGroupAccordion = ({
  taskletGroupId,
  taskletGroupName,
  description,
  tasklets,
  getIsChecked,
  onClickCheckbox,
  getIsDisabled = constant(false),
  getDisabledLabel = constant(''),
}: TaskletGroupAccordionProps): JSX.Element => {
  const [value, setValue] = useState<string | null>(null);

  const getCheckboxChangeHandler =
    (tasketId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onClickCheckbox(e.target.checked, tasketId);
    };

  const handleChangeGroupCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    for (const { taskletId } of tasklets) {
      // Skip tasklet if it's disabled
      if (getIsDisabled(taskletId)) {
        continue;
      }
      // Only update the tasklet checked state if needed
      if (checked !== getIsChecked(taskletId)) {
        onClickCheckbox(checked, taskletId);
      }
    }
  };

  // This state is derived from the checked state of the associated (enabled) tasklets
  const activeTasklets = tasklets.filter(tasklet => !getIsDisabled(tasklet.taskletId));
  const isGroupChecked =
    activeTasklets.length > 0 && activeTasklets.every(tasklet => getIsChecked(tasklet.taskletId));
  const isGroupIndeterminate =
    isGroupChecked || activeTasklets.length === 0
      ? undefined
      : activeTasklets.some(tasklet => getIsChecked(tasklet.taskletId));
  const isGroupDisabled =
    tasklets.length > 0 && tasklets.every(tasklet => getIsDisabled(tasklet.taskletId));
  const isGroupDefault = tasklets.length > 0 && tasklets.every(tasklet => tasklet.isDefault);

  const showDescriptionPreview = !!description && value === null;

  return (
    <Accordion value={value} onChange={setValue}>
      <Accordion.Item value={taskletGroupId}>
        <Accordion.Control px="sm">
          <Vertical spacing="xs">
            <Horizontal position="apart" noWrap spacing={0}>
              <Horizontal noWrap>
                <Text variant="subTitle03" color="gray.8">
                  {taskletGroupName}
                </Text>
                {isGroupDefault && <Badge color="gray">Default</Badge>}
              </Horizontal>
              {!isGroupDefault && (
                <Box
                  mr="sm"
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
                >
                  <Checkbox
                    label="Select all"
                    indeterminate={isGroupIndeterminate}
                    checked={isGroupChecked}
                    onChange={handleChangeGroupCheckbox}
                    disabled={isGroupDisabled}
                  />
                </Box>
              )}
            </Horizontal>
            {showDescriptionPreview && (
              <Text variant="bodyShort03" color="gray.5" lineClamp={1}>
                {description}
              </Text>
            )}
          </Vertical>
        </Accordion.Control>
        <Accordion.Panel>
          {description && (
            <Text variant="bodyShort03" color="gray.7" mt="xs" mb="lg">
              {description}
            </Text>
          )}
          {isGroupDefault && (
            <Text variant="subTitle04" color="gray.7" mt="xs" mb="lg">
              Note: These analyzers run by default on every dataset registered with MarkovML.
            </Text>
          )}
          <Flex gap={40} rowGap={20} wrap="wrap" py="md">
            {tasklets.map(({ taskletId, displayName, description, isDefault }) => {
              const disabled = isDefault || getIsDisabled(taskletId);

              const checkbox = disabled ? (
                <Tooltip
                  key={taskletId}
                  withArrow
                  label={isDefault ? 'Default analyzer' : getDisabledLabel(taskletId)}
                  position="top-start"
                >
                  <Box>
                    <Checkbox
                      label={displayName}
                      disabled={disabled}
                      checked={getIsChecked(taskletId, isDefault)}
                      onChange={getCheckboxChangeHandler(taskletId)}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Checkbox
                  label={displayName}
                  disabled={disabled}
                  checked={getIsChecked(taskletId, isDefault)}
                  onChange={getCheckboxChangeHandler(taskletId)}
                />
              );

              return (
                <Horizontal key={taskletId} spacing={0}>
                  {checkbox}
                  {description && <InfoTooltip text={description} maw={380} />}
                </Horizontal>
              );
            })}
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
