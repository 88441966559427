import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { ChartData, EvaluationsDistributionsChartResponse } from '../../generated/api';
import { evaluationsApi } from '../../lib/api';
import { useChartQuery } from '../charts-query-wrapper';
import { EVALUATION } from '../queryConstants';

export const useClassDistributionsQuery = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<AxiosResponse<EvaluationsDistributionsChartResponse>, AxiosError, ChartData>(
    [EVALUATION.GET_EVAL_CLASS_DISTRIBUTIONS, workspaceId, evaluationIds],
    () => evaluationsApi.getDistributionsForEvaluationsV1(workspaceId, evaluationIds),
    {
      select: res => res.data.response,
    },
  );
};
