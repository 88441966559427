import { Box, Container, Text } from '../../../../design-system/v2';
import { GRADIENT_BACKGROUND } from '../../studio-home/workflow/WorkflowHomepage';

export const Header = () => (
  <Box py={16} sx={{ background: GRADIENT_BACKGROUND }}>
    <Container size="xl" px={32}>
      <Text variant="heading02" color="gray.9">
        Explore MarkovML
      </Text>
      <Text variant="bodyShort02" color="gray.7">
        Analyze data, automate repeated tasks with workflows and more...
      </Text>
    </Container>
  </Box>
);
