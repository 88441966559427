import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Box, Text, Vertical } from '../../../../../../../design-system/v2';
import { Table } from '../../../../../../../design-system/v2/core/data-display/table';
import { ListAction } from './ListAction';

const getColumns = (dictionaryId: string): ColDef<string[]>[] => [
  {
    colId: '',
    field: '',
    headerName: 'Words',
    sortable: true,
    cellRenderer: ({ data }: ICellRendererParams<string>) => (
      <Vertical h="100%" justify="center">
        <Text variant="bodyShort02">{data}</Text>
      </Vertical>
    ),
    minWidth: 300,
    flex: 1,
  },

  {
    colId: '',
    field: '',
    headerName: ' ',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<string>) => (
      <ListAction dictionaryId={dictionaryId} wordToRemove={data ?? ''} />
    ),
    width: 200,
  },
];

interface DictionaryListProps {
  dictionaryId: string;
  dictionaryWords: string[];
}

const ROW_HEIGHT = 55;

export const DictionaryList = ({ dictionaryId, dictionaryWords }: DictionaryListProps) => {
  const columns = getColumns(dictionaryId);

  return (
    <Box mah={700} h={(dictionaryWords.length + 1) * ROW_HEIGHT} w="100%">
      <Table suppressScrollOnNewData columns={columns} rowData={dictionaryWords} />
    </Box>
  );
};
