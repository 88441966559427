import awsRegions from 'aws-regions';
import { Select, SelectProps } from '../../../design-system/v2';

// Note: AWS does not provide a public API to list available S3 regions. For now, this
// component reads available regions from an npm package, which may not be up-to-date.
const options = awsRegions.list().map(region => ({
  value: region.code,
  label: `${region.full_name} [${region.code}]`,
}));

export const AwsRegionSelect = (props: Omit<SelectProps, 'options'>): JSX.Element => (
  <Select options={options} {...props} />
);
