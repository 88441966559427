import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { App } from './App';
import { theme } from './design-system';
import { Notifications, ThemeProvider } from './design-system/v2';
import './index.css';
import { loadAnalyticsModule } from './initializers/analytics';
import { initChecksum } from './initializers/checksum';
import { initializeSentry } from './initializers/sentry';
import { initUserflow } from './initializers/userflow';
import { isDevelopment } from './lib/env.util';

// As of now, we don't want to use checksum in other envs
if (isDevelopment()) {
  initChecksum();
}

initializeSentry();

loadAnalyticsModule();

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <StyledThemeProvider theme={theme}>
        <Notifications />
        <App />
      </StyledThemeProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

initUserflow();
