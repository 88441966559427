import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ModelAppNodeView } from './ModelAppBlock';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    modelAppBlock: {
      /**
       * Set media
       */
      setModelAppBlock: (options: {
        modelId: string;
        scrollIntoView?: boolean;
        position?: number;
      }) => ReturnType;
    };
  }
}

export const ModelAppBlock = Node.create({
  name: 'modelAppBlock',

  inline: false,

  group: 'block',

  draggable: true,

  selectable: true,

  addAttributes() {
    return {
      modelId: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => ({
          modelId: (el as HTMLElement).getAttribute('modelId'),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ModelAppNodeView);
  },

  addCommands() {
    return {
      setModelAppBlock:
        ({ modelId, position, scrollIntoView = true }) =>
        ({ state, chain }) => {
          const {
            selection: { from },
          } = state;

          const pos = position ?? from;

          return chain()
            .insertContentAt(pos, {
              type: this.name,
              attrs: {
                modelId,
              },
            })
            .focus(pos + 3, { scrollIntoView })
            .setTextBlock()
            .run();
        },
    };
  },
});
