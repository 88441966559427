import { CellProps } from 'react-table';
import { TextColumnFilter } from '../../../design-system/Table/components/data-table/filters/TextColumnFilter';
import { TextVariants } from '../../../design-system/v2';
import { ExperimentWithTags } from '../../../generated/api';
import { ExperimentsNameCell } from './ExperimentsNameCell';

export const getExperimentsNameColumn = (width: number, textVariant: TextVariants) => ({
  Header: 'Experiment Name',
  accessor: 'name',
  width: width,
  Filter: () => <TextColumnFilter columnId="name" />,
  Cell: (props: CellProps<ExperimentWithTags>) => (
    <ExperimentsNameCell {...props} textVariant={textVariant} />
  ),
});
