import { IconRoute } from '@tabler/icons-react';
import { Button, Center, Text, Vertical } from '../../../../../../../design-system/v2';

interface MissingDebugRunProps {
  onRunDebug: () => void;
  showDebugButton?: boolean;
  isLoading?: boolean;
}

export const MissingDebugRun = ({
  onRunDebug,
  showDebugButton = true,
  isLoading,
}: MissingDebugRunProps): JSX.Element => (
  <Center w="100%" h="100%">
    <Vertical spacing="sx" align="center">
      <IconRoute color="gray" size={32} />
      <Text variant="subTitle02" color="gray.7">
        Please trigger a debug run to preview data
      </Text>
      {showDebugButton && (
        <Button variant="outline" onClick={onRunDebug} size="xs" mt="md" loading={isLoading}>
          Debug
        </Button>
      )}
    </Vertical>
  </Center>
);
