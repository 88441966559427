import {
  IconBook,
  IconFiles,
  IconInfoCircle,
  IconTextGrammar,
  IconUsers,
  IconWriting,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { noop } from 'lodash';
import {
  Center,
  Horizontal,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  Vertical,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { AnalyticsCopyEditResponse, AnalyticsType } from '../../../../../generated/api';
import { useGetWorkspaceMembersQuery } from '../../../../../queries/account/workspace';
import { AnalyticsTabChart } from './AnalyticsTabChart';

const numberDaysOptions = [
  { label: 'Past 30 days', value: '30' },
  { label: 'Past 15 days', value: '15' },
  { label: 'Past 7 days', value: '7' },
];

interface AnalyticsTabProps {
  data: AnalyticsCopyEditResponse;
}

export const AnalyticsTab = ({ data }: AnalyticsTabProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { data: workspaceMemberData } = useGetWorkspaceMembersQuery();

  const { featureCopyEditDemoUsers } = useFlags();

  const analyticsGeneralInfo = [
    {
      type: AnalyticsType.TotalUsers,
      main: 'seaGreen.8',
      background: 'seaGreen.1',
      icon: <IconUsers color="#64CFCF" size={36} />,
    },
    {
      type: AnalyticsType.DocumentsImproved,
      main: 'orange.5',
      background: 'orange.0',
      icon: <IconFiles color={theme.colors.orange[2]} size={36} />,
    },
    {
      type: AnalyticsType.WritingSessions,
      main: 'purple.6',
      background: 'purple.0',
      icon: <IconWriting color="#C3AED5" size={36} />,
    },
    {
      type: AnalyticsType.AvgWordsPerDocument,
      main: 'green.7',
      background: 'green.0',
      icon: <IconTextGrammar color={theme.colors.green[3]} size={36} />,
    },
    {
      type: AnalyticsType.DocumentTopics,
      main: 'blue.7',
      background: 'blue.0',
      icon: <IconBook color={theme.colors.blue[3]} size={36} />,
    },
  ];

  const cards = data.generalInfo.map(item => ({
    ...analyticsGeneralInfo.find(element => element?.type === item.type),
    ...item,
  }));

  const membersList =
    workspaceMemberData?.workspaceMembers.map(member => ({
      label: member.userDetails?.name,
      value: member.userId,
    })) ?? [];

  return (
    <Center>
      <Vertical align="center" w="80%">
        {featureCopyEditDemoUsers && (
          <Horizontal w="100%" position="left" align="left">
            <Select
              withinPortal
              ariaLabel="Filter by timeline"
              hideLabel
              options={numberDaysOptions}
              value={numberDaysOptions[0].value}
              onChange={noop}
              role="listbox"
            />
            <Select
              withinPortal
              ariaLabel="Filter by members"
              hideLabel
              options={[{ label: 'All members', value: 'all' }, ...membersList]}
              value="all"
              onChange={noop}
              role="listbox"
            />
          </Horizontal>
        )}
        <SimpleGrid w="100%" cols={3} pt="lg">
          {cards.map(info => (
            <Vertical
              key={info.label}
              sx={{ borderRadius: '8px' }}
              px="lg"
              bg={info.background}
              py="md"
            >
              <Horizontal spacing="xs">
                <Text variant="small01" color="gray.7">
                  {info.label}
                </Text>
                <Tooltip label={info.info}>
                  <IconInfoCircle size={14} />
                </Tooltip>
              </Horizontal>
              <Horizontal position="apart">
                <Text variant="heading02" color={info.main}>
                  {info.value}
                </Text>
                {info.icon && info.icon}
              </Horizontal>
            </Vertical>
          ))}
        </SimpleGrid>
        {data.charts.map(chart => (
          <AnalyticsTabChart
            key={chart.label}
            data={chart.data}
            title={chart.label}
            info={chart.info}
          />
        ))}
      </Vertical>
    </Center>
  );
};
