import { transformSeriesName } from '../../../components/dataset-details/util';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { AnalysisStateNames, SegmentType } from '../../../generated/api';
import { datasetApi } from '../../../lib/api';
import { useChartQuery } from '../../charts-query-wrapper';

const queryKeys = {
  getMissingValuesChartData: (
    datasetId: string,
    workspaceId: string,
    userId: string,
    segment: SegmentType,
  ) => ['get-missing-values-data', segment, datasetId, workspaceId, userId],
};

export const useGetMissingValuesChartDataQuery = (
  datasetId: string,
  segment: SegmentType,
  noCache = false,
) => {
  const { workspaceId, userId = '' } = useAppMetadata();

  return useChartQuery(
    queryKeys.getMissingValuesChartData(datasetId, workspaceId, userId, segment),
    () => datasetApi.workspaceGetDatasetMissingValuesV1(workspaceId, datasetId, segment),
    {
      // FIXME:: There is an issue with the query cache as it is overriding the cache for different variables
      // After switching segments multiple times, both the segment data becomes same.
      cacheTime: noCache ? 0 : undefined,
      enabled: Boolean(datasetId && segment),
      select: res => {
        if (
          res.data.analysisState === AnalysisStateNames.Error ||
          res.data.analysisState === AnalysisStateNames.NotStarted ||
          res.data.analysisState === AnalysisStateNames.Stopped ||
          !res.data.response
        ) {
          return res.data;
        }
        return {
          ...res.data,
          response: {
            ...res.data.response,
            data: {
              ...res.data.response?.data,
              xCategories: res.data.response?.data?.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
            },
          },
        };
      },
    },
  );
};
