import { createStyles } from '@mantine/styles';

export const useAppProjectStyle = createStyles(theme => ({
  mainPanel: {
    background: theme.white,
    borderRadius: theme.radius.xs,
    padding: 30,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '100%',
  },
  card: {
    cursor: 'pointer',
    border: `1.5px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
  },
}));
