import { clsx } from '@mantine/styles';
import { PropsWithChildren } from 'react';
import { Draggable as RBDDraggable } from 'react-beautiful-dnd';
import { Box } from '../';
import { useDraggableStyles } from './Draggable.style';

interface DraggableProps {
  index: number;
  id: string;
}

export const Draggable = ({ children, index, id }: PropsWithChildren<DraggableProps>) => {
  const { classes } = useDraggableStyles();

  return (
    <RBDDraggable index={index} draggableId={id}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          className={clsx({ [classes.itemDragging]: snapshot.isDragging })}
          mb="md"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </Box>
      )}
    </RBDDraggable>
  );
};
