import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FullPageLoader } from '../../../../design-system';
import {
  Loader,
  Skeleton,
  Vertical,
  closeAllModals,
  notifications,
  useInputState,
} from '../../../../design-system/v2';
import { AppIdType, StyleGuideType } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { useSimpleUploadToS3 } from '../../../../queries/data-upload';
import {
  useFetchRulesSetListQuery,
  useGetCustomDictionaryListQuery,
  useManualTextUploadMutation,
} from '../../../../queries/document-ai/copyEdit';
import {
  useAppProjectRegisterDocMutation,
  useGetAppProjectDocumentListQuery,
} from '../../../../queries/document-ai/document';
import { useDocumentUploadUrlMutation } from '../../../../queries/document-ai/summarization';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { DocumentTabType } from './DocumentUploadModal.container';
import { DocumentUserInputs } from './DocumentUserInputs';
import { getButtonTextFromAppId, getFileTypeFromMimeType, getLLMDisplayName } from './utils';

interface DocumentUploadInputContainerProps {
  activeTab: DocumentTabType;
  variant: AppIdType;
  projectId: string;
  inputFile?: File;
}

export const DocumentUploadInputContainerV2 = ({
  activeTab,
  variant,
  projectId,
  inputFile,
}: DocumentUploadInputContainerProps): JSX.Element => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedLlm, setSelectedLlm] = useInputState('');
  const [documentName, setDocumentName] = useInputState('');
  const [selectedCustomStyleRules, setSelectedCustomStyleRules] = useState<string[]>([]);
  const [selectedCustomDictionaries, setSelectedCustomDictionaries] = useState<string[]>([]);
  const [selectedStyleGuide, setSelectedStyleGuide] = useState<StyleGuideType>(StyleGuideType.Cmos);

  const { isLoading, data: ruleSetList } = useFetchRulesSetListQuery(variant);
  const { isLoading: isLoadingDictionaries, data: dictionaryList } =
    useGetCustomDictionaryListQuery();

  const { data } = useGetAppProjectDocumentListQuery(variant, projectId);
  const supportedLlmTypes = data?.supportedLlmTypes;
  const selectSupportedLlmTypesOptions = supportedLlmTypes
    ? supportedLlmTypes.map(llm => ({
        value: llm,
        label: getLLMDisplayName(llm),
      }))
    : [];
  const navigate = useNavigate();
  const { getCopyEditDetailRoute, getSummarizationDetailRoute, getDocQnADetailRoute } =
    useAbsoluteRoutes();

  const { mutateAsync: createUploadUrl } = useDocumentUploadUrlMutation(variant);
  const { mutateAsync: uploadManualText } = useManualTextUploadMutation(variant, projectId);
  const { mutateAsync: registerAppDocument } = useAppProjectRegisterDocMutation(variant, projectId);
  const uploadToS3 = useSimpleUploadToS3();

  const handleNavigate = (documentId: string) => {
    const route = {
      [AppIdType.CopyEdit]: getCopyEditDetailRoute(documentId, projectId),
      [AppIdType.Summarization]: getSummarizationDetailRoute(documentId),
      [AppIdType.QnaWithDoc]: getDocQnADetailRoute(documentId),
    }[variant];

    navigate(route);
    closeAllModals();
  };

  const handleUploadFile = async () => {
    try {
      setIsUploading(true);
      const fileType = getFileTypeFromMimeType(inputFile?.type ?? '');
      createUploadUrl({
        documentName,
        llmType: selectedLlm.length === 0 ? undefined : selectedLlm,
        docType: fileType,
      })
        .then(data => {
          const metadata = data.data;
          if (!inputFile) {
            throw new Error('File url not found!');
          }

          uploadToS3({ file: inputFile, signedUri: metadata.signedUrl }).then(() => {
            registerAppDocument({
              documentId: metadata.documentId,
              requestBody: {
                style_guides: [selectedStyleGuide],
                custom_style_rules: selectedCustomStyleRules,
                custom_dictionary_ids: selectedCustomDictionaries,
              },
            })
              .then(() => {
                setIsUploading(false);
                handleNavigate(metadata.documentId);
                notifications.success('Successfully uploaded file');
              })
              .catch(err => {
                setIsUploading(false);
                notifications.error('Unable to upload your file. Please try again');
              });
          });
        })
        .catch(err => {
          setIsUploading(false);
          notifications.error('Unable to upload your file. Please try again');
        });
    } catch {
      setIsUploading(false);
      notifications.error('Wrong file type uploaded. Please try again');
    }
  };

  const handleUploadManualText = async () => {
    try {
      setIsUploading(true);
      const response = await uploadManualText({
        documentName,
        text: ' ',
        llmType: selectedLlm.length === 0 ? undefined : selectedLlm,
        styleGuides: [selectedStyleGuide],
        customStyleRules: selectedCustomStyleRules,
      });
      setIsUploading(false);
      handleNavigate(response.data.documentId);
      notifications.success('Successfully registered text');
    } catch (error) {
      const errorMsg = 'Unable to process your text. Please try again';
      notifications.error(errorMsg);
      logger.error(error);
    }
  };

  const disableUpload = !documentName;

  useEffect(() => {
    setDocumentName(inputFile?.name ?? '');
  }, [inputFile]);

  if (isUploading) {
    return (
      <>
        <FullPageLoader text="Uploading your document..." />
        <Skeleton h={200} />
      </>
    );
  }

  if (isLoading || isLoadingDictionaries) {
    return <Loader text="Loading..." />;
  }

  return (
    <Vertical>
      <DocumentUserInputs
        variant={variant}
        customStyleRules={ruleSetList ?? []}
        selectedCustomStyleRule={selectedCustomStyleRules}
        setSelectedCustomStyleRule={setSelectedCustomStyleRules}
        dictionaryRules={dictionaryList ?? []}
        selectedDictionaryRules={selectedCustomDictionaries}
        setSelectedDictionaryRules={setSelectedCustomDictionaries}
        documentName={documentName}
        setDocumentName={setDocumentName}
        selectSupportedLlmTypesOptions={selectSupportedLlmTypesOptions}
        selectedLlm={selectedLlm}
        setSelectedLlm={setSelectedLlm}
        selectedStyleGuide={selectedStyleGuide}
        setSelectedStyleGuide={setSelectedStyleGuide}
        handleButtonClick={
          activeTab === DocumentTabType.UPLOAD ? handleUploadFile : handleUploadManualText
        }
        disableButtonClick={disableUpload}
        buttonText={getButtonTextFromAppId(variant)}
      />
    </Vertical>
  );
};
