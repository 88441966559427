import css from '@styled-system/css';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../..';
import { Box } from '../../..';

export const StyledTableCell = styled(TableCell)`
  min-height: 52px;
  padding: 12px;
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.colors.text.shade2};
`;

export const StyledTableHeader = styled(TableHeader)`
  padding: 16px 12px;
  background: ${props => props.theme.colors.white};
  text-align: left;
  box-shadow: inset 0 1px 0 ${props => props.theme.colors.muted.shade1},
    inset 0 -2px 0 ${props => props.theme.colors.muted.shade1},
    inset -1px 0 0 ${props => props.theme.colors.muted.shade1};
  white-space: nowrap;
  user-select: none;
  font-size: 0.875rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  color: ${props => props.theme.colors.text.shade2};

  :last-of-type {
    box-shadow: inset 0 1px 0 ${props => props.theme.colors.muted.shade1},
      inset 0 -2px 0 ${props => props.theme.colors.muted.shade1};
  }
`;

const disabled = css`
  pointer-events: none;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const StyledTableRow = styled(TableRow)`
  background: ${({ isSelected }) => (isSelected ? '#f3f8ff' : '#fff')};

  ${({ isDisabled }) => isDisabled && disabled};
  ${({ isClickable, isDisabled, theme }) =>
    isClickable &&
    !isDisabled &&
    `
      cursor: pointer;

      &:hover {
        background: ${theme.colors.hover};
      }
    `};
`;

export const StyledTableBody = styled(TableBody)`
  width: 100%;

  ${StyledTableRow} {
    border-bottom: 1px solid ${props => props.theme.colors.muted.shade1};
    position: relative;
  }
`;

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const StyledTable = styled(Table)`
  display: table;
  width: 100%;
  background: ${props => props.theme.colors.white};
  min-width: 400px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.75rem;
  line-height: 1.33333;
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const StyledDataTableWrapper = styled(Box)`
  display: block;
  overflow: auto;
  height: ${props => props.height ?? 'auto'};
`;

// TODO: Refactor class name once Loading component is refactored
export const StyledLoadingWrapper = styled(Box)`
  position: relative;
  height: ${props => props.height};
`;
