import { useState } from 'react';
import {
  Button,
  Horizontal,
  Loader,
  Select,
  Text,
  Vertical,
  notifications,
} from '../../../design-system/v2';
import { WorkspaceMembershipRoles } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { useUpdateWorkspaceMembershipMutation } from '../../../queries/account/workspace';

interface UpdateMembershipProps {
  currentRole: string;
  userId: string;
  onClose: () => void;
}

const MEMBERSHIP_LABEL = 'Select membership type';
const MEMBERSHIP_PLACEHOLDER = 'Click to select the membership type';

const membershipOptions = [
  {
    value: WorkspaceMembershipRoles.Owner,
    label: 'Owner',
  },
  {
    value: WorkspaceMembershipRoles.Member,
    label: 'Member',
  },
];

export const UpdateMembership = ({
  currentRole,
  userId,
  onClose,
}: UpdateMembershipProps): JSX.Element => {
  const [role, setRole] = useState(currentRole);
  const { mutateAsync: updateMembership, isLoading: isSaving } =
    useUpdateWorkspaceMembershipMutation();

  const chosenRole = membershipOptions.find(({ value }) => value === role);

  const handleSlackChannelSelection = (role: string) => {
    setRole(role);
  };

  const handleConfirm = () => {
    if (!chosenRole) {
      notifications.show({
        variant: 'error',
        message: 'Please select a valid membership type.',
      });
      logger.error('This should never happen. chosenRole is empty while updating membership');
      return;
    }
    const roleValue = chosenRole.value;
    updateMembership(
      { selectedUserId: userId, role: roleValue },
      {
        onSuccess: () => {
          onClose();
        },
        onError: err => {
          notifications.show({
            variant: 'error',
            message: 'Unable to update membership. Please try again.',
          });
          logger.error(err);
        },
      },
    );
  };

  if (isSaving) {
    return (
      <Vertical align="center" justify="center">
        <Loader />
        <Text variant="bodyShort03">Saving membership type</Text>
      </Vertical>
    );
  }

  return (
    <Vertical>
      <Select
        ariaLabel={MEMBERSHIP_LABEL}
        label={MEMBERSHIP_LABEL}
        placeholder={MEMBERSHIP_PLACEHOLDER}
        options={membershipOptions}
        value={chosenRole?.value}
        onChange={handleSlackChannelSelection}
        searchable
        withinPortal
      />
      <Horizontal mt="xl" position="right">
        <Button onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Horizontal>
    </Vertical>
  );
};
