import {
  Autocomplete as MantineAutoComplete,
  AutocompleteProps as MantineAutocompleteProps,
  SelectItem,
} from '@mantine/core';

type DefinedAutocompleteProps = MantineAutocompleteProps & React.RefAttributes<HTMLInputElement>;

interface AutoCompleteProps extends Omit<DefinedAutocompleteProps, 'data'> {
  options: SelectItem[];
}

export const Autocomplete = ({ options, ...restProps }: AutoCompleteProps) => (
  <MantineAutoComplete {...restProps} data={options} />
);
