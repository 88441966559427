import { IconChevronLeft, IconChevronRight, IconDots } from '@tabler/icons-react';
import { Button, Flex, Horizontal, Indicator, Text, usePagination } from '../../design-system/v2';
import { formatNumberLocaleString, toPlural } from '../../lib/ui';

const getPageRange = (totalItems: number, pageSize: number, pageIndex: number) => {
  const start = pageSize * pageIndex + 1;
  const end = Math.min(totalItems, start + pageSize - 1);

  return {
    start,
    end,
    label:
      start === end
        ? `${formatNumberLocaleString(start)}`
        : `${formatNumberLocaleString(start)}-${formatNumberLocaleString(end)}`,
  };
};

interface ColumnPaginationProps {
  numColumns: number;
  pageSize: number;
  currentPage: number;
  onSetPage: (page: number) => void;
  getIsPageHighlighted: (pageStart: number, pageEnd: number) => boolean;
}

export const ColumnPagination = ({
  currentPage,
  numColumns,
  pageSize,
  onSetPage,
  getIsPageHighlighted,
}: ColumnPaginationProps): JSX.Element => {
  // usePagination hook used here for 'dots' logic (limits number of displayed controls)
  const numPages = Math.ceil(numColumns / pageSize);
  const pagination = usePagination({
    total: numPages,
    page: currentPage + 1, // Mantine pagination items are 1-indexed
    onChange: onSetPage,
  });

  const paginationControls: JSX.Element[] = pagination.range.map((item, i) => {
    if (item === 'dots') {
      return (
        <Flex key={`${item}-${i}`}>
          <IconDots />
        </Flex>
      );
    }

    // Mantine pagination items are 1-indexed
    const pageIndex = item - 1;
    const { start, end, label } = getPageRange(numColumns, pageSize, pageIndex);
    const showIndicator = getIsPageHighlighted(start, end);

    return (
      <Indicator
        key={`${item}-${i}`}
        withBorder
        inline
        disabled={!showIndicator}
        size={12}
        color="seaGreen.6"
      >
        <Button
          size="xs"
          variant={pageIndex === currentPage ? 'primary' : 'outline'}
          onClick={() => onSetPage(pageIndex)}
        >
          {label}
        </Button>
      </Indicator>
    );
  });

  const { label: currentRange } = getPageRange(numColumns, pageSize, currentPage);

  return (
    <Horizontal>
      <Text variant="small01" color="gray.6">
        Showing{' '}
        <Text span variant="subTitle05" color="gray.6">
          {currentRange}
        </Text>{' '}
        of {toPlural(numColumns, 'column')}
      </Text>
      <Horizontal spacing="sm">
        <Button
          size="xs"
          variant="outline"
          disabled={currentPage === 0}
          onClick={() => onSetPage(currentPage - 1)}
          py={0}
          px="xs"
        >
          <IconChevronLeft size={16} />
        </Button>
        {paginationControls}
        <Button
          size="xs"
          variant="outline"
          disabled={currentPage === numPages - 1}
          onClick={() => onSetPage(currentPage + 1)}
          py={0}
          px="xs"
        >
          <IconChevronRight size={16} />
        </Button>
      </Horizontal>
    </Horizontal>
  );
};
