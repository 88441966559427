import { Badge, Box, Card, Divider, Horizontal, Text, Vertical } from '../../design-system/v2';
import { ArtifactStateType, DatasetOperationModel, WorkspaceMember } from '../../generated/api';
import { formatDateTime } from '../../lib/ui';
import { UserAvatarGroup } from '../common/UserAvatarGroup';
import { DataLabelingActions } from './DataLabelingActions';
import { DataLabelingActionsMenu } from './DataLabelingActionsMenu';
import { useDetailsFromDataLabelingType, useStatusComponentInfo } from './util';

interface DataLabelingCardProps {
  state: ArtifactStateType;
  data: DatasetOperationModel;
  workspaceMembers: WorkspaceMember[];
}

export const DataLabelingCard = ({
  state,
  data,
  workspaceMembers,
}: DataLabelingCardProps): JSX.Element => {
  const {
    datasetId,
    dataOperationId: dataLabelingId,
    type,
    name: title,
    updateDate,
    createdBy,
    state: status,
  } = data;
  const users = [createdBy];
  const details = useDetailsFromDataLabelingType(type);
  const usersList = workspaceMembers?.filter(({ userId }) => users?.includes(userId));
  const info = useStatusComponentInfo(status);
  const displayedTitle = title || 'Untitled';

  return (
    <Card withBorder p="lg" sx={{ position: 'relative' }}>
      <Vertical spacing={0} h={220} justify="space-between">
        <Box>
          <Vertical spacing="sm">
            <Horizontal position="apart">
              <Badge variant="filled" bg={details.color} size="sm" sx={{ alignSelf: 'start' }}>
                {details.title}
              </Badge>
              <DataLabelingActionsMenu
                datasetId={datasetId}
                dataLabelingId={dataLabelingId}
                state={state}
                currentName={title}
              />
            </Horizontal>
            <Text variant="subTitle02" color="gray.8" lineClamp={1} title={title}>
              {displayedTitle}
            </Text>
            <Horizontal noWrap position="apart" pt={60}>
              <Horizontal noWrap spacing="xs" h={32}>
                <Text variant="small01" color="gray.5">
                  Last updated
                </Text>
                <Text variant="small01" color="gray.7">
                  {formatDateTime(updateDate)}
                </Text>
              </Horizontal>
              <UserAvatarGroup users={usersList} />
            </Horizontal>
          </Vertical>
        </Box>
        <Box>
          <Divider mx={-16} />
          <Horizontal position="apart" pt="lg" align="center">
            {info && (
              <Horizontal spacing="sm">
                {info?.icon}
                <Text variant="small01" color="dark.5">
                  {info?.text}
                </Text>
              </Horizontal>
            )}
            <DataLabelingActions operation={data} />
          </Horizontal>
        </Box>
      </Vertical>
    </Card>
  );
};
