import { MantineTheme } from '@mantine/styles';
import { LetterSpacing, LineHeight } from '../../typography/constants';

export const notificationsTheme = {
  styles: (theme: MantineTheme) => ({
    root: {
      width: '480px',
      height: '72px',
      border: '1px solid',
      borderColor: theme.fn.themeColor('gray.3'),
      boxShadow: '3px',
    },
    title: {
      fontSize: theme.fontSizes.md,
      fontWeight: 500,
      lineHeight: LineHeight.SM,
      letterSpacing: LetterSpacing.SPACED,
      color: theme.fn.themeColor('gray.8'),
    },
    description: {
      fontSize: theme.fontSizes.xs,
      fontWeight: 400,
      lineHeight: LineHeight.XS,
      letterSpacing: LetterSpacing.SPACED,
      color: theme.fn.themeColor('gray.6'),
    },
    loader: {
      marginLeft: '16px',
    },
    icon: {
      marginLeft: '16px',
    },
  }),
};
