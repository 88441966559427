import {
  Button,
  Horizontal,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useInputState,
} from '../../design-system/v2';
import { useUpdateDataLabelingMutation } from '../../queries/data-labeling/detail/detail';

interface DataLabelingEditNameModalContainerProps {
  datasetId: string;
  dataLabelingId: string;
  currentName: string;
}

export const DataLabelingEditNameModalContainer = ({
  datasetId,
  dataLabelingId,
  currentName,
}: DataLabelingEditNameModalContainerProps) => {
  const [name, setName] = useInputState(currentName);
  const { mutateAsync: saveName, isLoading: isSaving } = useUpdateDataLabelingMutation(
    datasetId,
    dataLabelingId,
  );

  const handleClick = async () => {
    try {
      await saveName({ name });
      closeAllModals();
    } catch {
      notifications.error('There was an error saving your name. Please try again.');
    }
  };

  return (
    <Vertical>
      <TextInput
        label="Enter new name"
        ariaLabel="Enter new name"
        value={name}
        onChange={setName}
        disabled={isSaving}
      />
      <Horizontal position="right">
        <Button variant="primary" onClick={handleClick} loading={isSaving}>
          Confirm
        </Button>
      </Horizontal>
    </Vertical>
  );
};
