import { TextInput, TextInputProps } from '../../../../../design-system/v2';
import { useEditorTitleStyles } from './EditorInputs.style';

type TitleProps = TextInputProps;

export const Title = (props: TitleProps) => {
  const { classes, cx } = useEditorTitleStyles();

  return <TextInput classNames={classes} {...props} />;
};
