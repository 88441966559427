import { InAppNotificationModel } from '../../../generated/api';
import { isSameDay, isYesterday } from '../../../lib/time-format';

export const segregateNotifications = (notifications: InAppNotificationModel[]) => {
  const todayDate = new Date();

  const notificationsToday = notifications.filter(item => {
    const itemDate = new Date(item.createDate as string);
    return isSameDay(itemDate, todayDate);
  });

  const notificationsYesterday = notifications.filter(item => {
    const itemDate = new Date(item.createDate as string);
    return isYesterday(itemDate, todayDate);
  });

  const notificationsOlder = notifications.filter(item => {
    const itemDate = new Date(item.createDate as string);
    return !isSameDay(itemDate, todayDate) && !isYesterday(itemDate, todayDate);
  });

  const notificationsCollection = [
    { id: 0, title: 'Today', notifications: notificationsToday },
    { id: 1, title: 'Yesterday', notifications: notificationsYesterday },
    { id: 2, title: 'Older', notifications: notificationsOlder },
  ];

  return notificationsCollection;
};
