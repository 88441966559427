import { Alert, Center, IconAlertCircle, Loader } from '../../../../../design-system/v2';
import { AppIdType } from '../../../../../generated/api';
import { useGetAnalyticsCopyEditQuery } from '../../../../../queries/document-ai/copyEdit';
import { AnalyticsTab } from './AnalyticsTab';

interface AnalyticsTabContainerProps {
  appVariant: AppIdType;
}

export const AnalyticsTabContainer = ({ appVariant }: AnalyticsTabContainerProps): JSX.Element => {
  const { isLoading, isError, data } = useGetAnalyticsCopyEditQuery(appVariant, 19);

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" text="Loading app analytics..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Alert
        icon={<IconAlertCircle size={14} />}
        color="red"
        title="Unable to fetch analytics for the app"
        mt={48}
        role="alert"
      >
        Something seems wrong. Please try after sometime or contact support!
      </Alert>
    );
  }

  return <AnalyticsTab data={data} />;
};
