import { Center, Horizontal, Text, Vertical } from '../../../design-system/v2';
import { SegmentType } from '../../../generated/api';
import { isSplitSegmentType } from '../../dataset-registration/util';

interface ModalVizHeaderProps {
  title: string;
  datasetName: string;
  segmentType: SegmentType;
}

export const ModalVizHeader = ({
  title,
  datasetName,
  segmentType,
}: ModalVizHeaderProps): JSX.Element => (
  <Center>
    <Vertical spacing="xs" align="center">
      <Text variant="subTitle01" truncate>
        {title}
      </Text>
      <Horizontal noWrap spacing="lg">
        <Text variant="subTitle03" color="gray.6" truncate>
          Dataset name: {datasetName}
        </Text>
        {isSplitSegmentType(segmentType) && (
          <Text variant="subTitle03" color="gray.6">
            Segment: {segmentType}
          </Text>
        )}
      </Horizontal>
    </Vertical>
  </Center>
);
