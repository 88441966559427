import { Button, ButtonProps } from '@mantine/core';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonType, ButtonVariant } from '../../buttons';
import { typeRadius, variantProperties } from '../../buttons/button/constants';

type LinkButtonProps = LinkProps &
  Omit<ButtonProps, 'variant' | 'color' | 'radius' | 'gradient' | 'compact'> & {
    variant?: ButtonVariant;
    shape?: ButtonType;
    external?: boolean;
    href?: string;
  };

export const LinkButton = forwardRef<HTMLDivElement, LinkButtonProps>(
  (
    { variant = 'outline', shape = 'sharp', external = false, children, ...rest }: LinkButtonProps,
    ref,
  ): JSX.Element => {
    const variantProperty = variantProperties[variant];
    const radius = typeRadius[shape];

    return (
      <div ref={ref}>
        <Button<HTMLAnchorElement>
          component={external ? 'a' : Link}
          variant={variantProperty.mantineVariant}
          radius={radius}
          {...rest}
        >
          {children}
        </Button>
      </div>
    );
  },
);

LinkButton.displayName = 'LinkButton';
