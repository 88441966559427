import { SelectItem } from '@mantine/core';
import {
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
} from './mantine/multi-select/MultiSelect';

type DefinedMultiSelectProps = MantineMultiSelectProps & React.RefAttributes<HTMLInputElement>;

type MultiSelectProps = Omit<DefinedMultiSelectProps, 'data'> & {
  options: SelectItem[];
  alignment?: string;
  enableSelectAll?: boolean;
};

export const MultiSelect = ({ options, alignment, ...restProps }: MultiSelectProps) => (
  <MantineMultiSelect {...restProps} alignment={alignment} data={options} />
);
