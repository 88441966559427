import { MantineTheme } from '@mantine/core';

export const segmentedControlTheme = {
  styles: (theme: MantineTheme) => ({
    root: {
      border: `1px solid ${theme.fn.themeColor('white.2')}`,
    },
    label: {
      color: theme.fn.themeColor('dark.2'),
    },
  }),
};
