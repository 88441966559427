import { IconX } from '@tabler/icons-react';
import { AxiosResponse } from 'axios';
import { PropsWithChildren, ReactNode } from 'react';
import {
  ActionIcon,
  Box,
  BoxProps,
  Popover,
  useDisclosure,
  useMarkovTheme,
} from '../../../design-system/v2';
import { Share } from './Share';

export interface WorkspaceShareProps {
  entityName: string;
  onShare: (userId: string[]) => Promise<AxiosResponse<unknown>>;
  sharedWithUsers: string[];
  onRemoveUser?: (userId: string) => Promise<AxiosResponse<unknown>>;
  targetElement?: ReactNode;
  projectId?: string;
  dropdownContainerProps?: BoxProps;
}

export const WorkspaceShare = ({
  entityName,
  onShare,
  sharedWithUsers,
  onRemoveUser,
  targetElement,
  projectId,
  dropdownContainerProps,
  children,
}: PropsWithChildren<WorkspaceShareProps>) => {
  const theme = useMarkovTheme();
  const [opened, { open, close }] = useDisclosure();

  return (
    <Popover
      opened={opened}
      onOpen={open}
      onClose={close}
      styles={{ dropdown: { border: 0, padding: 0 } }}
    >
      <Popover.Target>
        <Box onClick={open}>{targetElement}</Box>
      </Popover.Target>
      <Popover.Dropdown bg="transparent">
        <Box
          pos="relative"
          p="md"
          bg={theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.white[0]}
          sx={theme => ({
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: '2px',
            boxShadow: theme.shadows.xl,
          })}
          {...dropdownContainerProps}
        >
          <ActionIcon pos="absolute" right={8} top={8} onClick={close}>
            <IconX />
          </ActionIcon>
          <Share
            projectId={projectId}
            entityName={entityName}
            onShare={onShare}
            sharedWithUsers={sharedWithUsers}
            onRemoveUser={onRemoveUser}
          />
          {children}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
