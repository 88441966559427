import { IconArrowFork } from '@tabler/icons-react';
import first from 'lodash/first';
import {
  ActionIcon,
  Badge,
  Horizontal,
  Skeleton,
  Text,
  Vertical,
  openModal,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { Dataset } from '../../../../queries/datasets/list';
import { transformSeriesName } from '../../../dataset-details/util';
import { DatasetRelationContainer } from '../../components/DatasetRelation.container';
import { DEFAULT_ROW_HEIGHT } from './util';

export const TitleRenderer = ({ value, data }: ICellRendererParams<Dataset, string>) => {
  const theme = useMarkovTheme();

  if (!data) {
    return (
      <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={140} height={30} />
      </Vertical>
    );
  }

  const datasetId = data.datasetId;
  const parentDatasetId = data.parentDatasetId;
  const childrenDatasetIds = data.childrenDatasetIds;
  const childDatasetId = first(childrenDatasetIds);
  const showRelation = Boolean(parentDatasetId || childDatasetId);

  const openRelationModal = () => {
    if (datasetId) {
      openModal({
        size: 'lg',
        title: <Text variant="subTitle01">Related Datasets</Text>,
        children: (
          <DatasetRelationContainer
            datasetId={datasetId}
            parentDatasetId={parentDatasetId ?? ''}
            childDatasetId={childDatasetId ?? ''}
          />
        ),
      });
    }
  };

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    openRelationModal();
  };

  return (
    <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
      <Horizontal noWrap>
        <Text
          data-testid="datasets-table-datasetName"
          variant="subTitle04"
          color="gray.7"
          lineClamp={1}
          title={value ?? ''}
          style={{ wordBreak: 'break-all' }}
        >
          {value}
        </Text>
        {data.demoArtifact && (
          <Badge variant="filled" color="green.6">
            Sample
          </Badge>
        )}
        {showRelation && (
          <ActionIcon onClick={handleIconClick} sx={{ flexShrink: 0 }} h="20px">
            <IconArrowFork size={16} color={theme.colors.blue[6]} />
          </ActionIcon>
        )}
      </Horizontal>
      <Text variant="small03" color="gray.6">
        {transformSeriesName(data.target ?? '')}
      </Text>
    </Vertical>
  );
};
