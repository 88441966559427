import { AxiosResponse } from 'axios';
import { useQueries, useQuery } from 'react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { GetExperimentWithModelAndDatasetResponse } from '../../generated/api';
import { experimentsApi, mumbaiApi } from '../../lib/api';
import { useChartQuery } from '../charts-query-wrapper';
import { experimentComparisonKeys, experimentKeys } from './queryKeys';

export const useCanCompareExperimentsQuery = (experimentIds: string[], enabled = false) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    experimentComparisonKeys.canCompare(workspaceId, experimentIds),
    () => experimentsApi.canCompareExperimentsV1(workspaceId, experimentIds),
    {
      enabled: Boolean(enabled && workspaceId && experimentIds.length),
      select: res => res.data.response.returnCode,
    },
  );
};

export const useExperimentsFullDetailsQueries = (experimentIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useQueries(
    experimentIds.map(experimentId => ({
      queryKey: experimentKeys.fullDetail(workspaceId, experimentId),
      queryFn: () => mumbaiApi.getExperimentWithModelAndDatasetInfoV1(workspaceId, experimentId),
      enabled: Boolean(workspaceId && experimentId),
      select: (res: AxiosResponse<GetExperimentWithModelAndDatasetResponse>) => res.data.response,
    })),
  );
};

export const useExperimentSummaryQuery = (experimentIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    experimentComparisonKeys.experimentSummary(workspaceId, experimentIds),
    () => experimentsApi.getExperimentsSummaryV1(workspaceId, experimentIds),
    {
      enabled: Boolean(workspaceId && experimentIds.length),
      select: res => res.data.response,
    },
  );
};

export const useExperimentsKeysQuery = (experimentIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    experimentComparisonKeys.experimentsKeys(workspaceId, experimentIds),
    () => experimentsApi.getExperimentsChartKeysV1(workspaceId, experimentIds),
    {
      enabled: Boolean(workspaceId && experimentIds.length),
      select: res => res.data.response,
    },
  );
};

export const useExperimentsMetricData = (metricKey: string, experimentIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    experimentComparisonKeys.keyMetricChartData(workspaceId, metricKey, experimentIds),
    () =>
      experimentsApi.getExperimentsKeyMetricsChartResponseV1(workspaceId, metricKey, experimentIds),
    {
      enabled: Boolean(workspaceId && experimentIds.length > 0),
      select: res => res.data.chartResponse,
    },
  );
};
