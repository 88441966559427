import { Info } from '../../design-system';
import { ActionIcon, Box, Card, Horizontal, Text, Tooltip } from '../../design-system/v2';
import { CustomMetricAllStats } from '../../generated/api';
import { formatNumber } from '../../lib/ui';
import { MetricBoxPlot } from './metric-box-plot/MetricBoxPlot';

interface CustomMetricCardProps {
  name: string;
  stats: CustomMetricAllStats;
}

export const CustomMetricCard = ({ name, stats }: CustomMetricCardProps): JSX.Element => {
  const { skewness, fisherKurtosis } = stats.symmetry;
  const symmetryStats = [
    {
      name: 'Skewness',
      description: skewness.info ?? '',
      value: skewness.value,
    },
    {
      name: 'Kurtosis',
      description: fisherKurtosis.info ?? '',
      value: fisherKurtosis.value,
    },
  ];

  return (
    <Card withBorder radius="xs" p={24}>
      <Horizontal position="apart" align="start" spacing={0} mb="lg">
        <Text variant="subTitle04" color="gray.8">
          {name}
        </Text>
        <Box>
          {symmetryStats.map((stat, i) => (
            <Horizontal key={i} spacing="xs" align="center">
              <Horizontal noWrap spacing={2} w={85}>
                <Text variant="small01" color="gray.7">
                  {stat.name}
                </Text>
                <Tooltip label={stat.description} withArrow withinPortal multiline maw={235}>
                  <ActionIcon variant="transparent" size={20}>
                    <Info />
                  </ActionIcon>
                </Tooltip>
              </Horizontal>
              <Text variant="subTitle04" color="gray.8">
                {stat.value ? formatNumber(stat.value) : '--'}
              </Text>
            </Horizontal>
          ))}
        </Box>
      </Horizontal>
      <Box mt="8px" h={80}>
        <MetricBoxPlot data={stats.summary} name={name} />
      </Box>
    </Card>
  );
};
