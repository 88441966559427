import { IconSearch } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import {
  ActionIcon,
  CloseButton,
  TextInput,
  TextInputProps,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { useStyles } from './ToolbarActionIcon.styles';

const DEBOUNCE_TIMEOUT = 500;

export interface CollapsibleSearchProps extends Omit<TextInputProps, 'onChange'> {
  onChange: (value: string) => void;
}

const CollapsibleSearch = ({ value, onChange, ...rest }: CollapsibleSearchProps) => {
  const { classes } = useStyles();
  const [isCollapsed, setCollapsed] = useState(true);
  const [searchQuery, setSearchQuery] = useState(value);
  const theme = useMarkovTheme();

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  const debouncedOnChange = useCallback(debounce(onChange, DEBOUNCE_TIMEOUT), []);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    setSearchQuery(value);
    debouncedOnChange(value);
  };

  const handleCollapse = () => {
    setCollapsed(true);
    onChange('');
  };

  const handleOpen = () => {
    setCollapsed(false);
  };

  return isCollapsed ? (
    <ActionIcon className={classes.icon} onClick={handleOpen}>
      <IconSearch width={16} height={16} />
    </ActionIcon>
  ) : (
    <TextInput
      ref={ref => ref?.focus()}
      autoFocus
      placeholder="search"
      onChange={handleInputChange}
      value={searchQuery}
      icon={<IconSearch color={theme.colors.gray[7]} width={16} height={16} />}
      rightSection={<CloseButton onClick={handleCollapse} />}
      sx={theme => ({
        input: {
          backgroundColor: theme.colors.gray[1],
        },
      })}
      {...rest}
    />
  );
};

export default CollapsibleSearch;
