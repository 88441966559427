import { MantineTheme } from '@mantine/styles';
import { IconInfoCircle } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { FullPageLoader } from '../../../design-system';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Horizontal,
  LinkAnchor,
  Modal,
  Text,
  TextArea,
  TextInput,
  notifications,
  openConfirmModal,
  useMarkovTheme,
} from '../../../design-system/v2';
import { CredentialModel, EditCredentialRequest, StorageType } from '../../../generated/api';
import { ErrorBar } from '../../common/ErrorBar';
import { DataStoreSelect } from '../data-source/DataStoreSelect.container';
import { ConfirmCredentials } from './ConfirmCredentials';

export type CredentialRequest = Partial<CredentialModel>;

interface AddEditCredentialModalProps {
  initialName?: string;
  isSavingCredentials: boolean;
  isSavingCredentialsSuccess: boolean;
  errorMessage: string;
  data?: CredentialModel;
  onSaveCredentials: (credentialData: CredentialRequest) => void;
  onCloseModal: () => void;
}

enum ModalType {
  ADD,
  EDIT,
}

const HIDDEN_VALUE = 'xxxxxxxxxx';

export const AddEditCredentialModal = ({
  initialName,
  isSavingCredentials,
  isSavingCredentialsSuccess,
  errorMessage,
  data,
  onSaveCredentials,
  onCloseModal,
}: AddEditCredentialModalProps): JSX.Element => {
  const theme = useMarkovTheme();

  // Adding null for case when only confirm modal should show up
  const [modalToShow, setModalToShow] = useState<ModalType | null>(
    data ? ModalType.EDIT : ModalType.ADD,
  );

  const [accessKey, setAccessKey] = useState<string>(data ? HIDDEN_VALUE : '');
  const handleChangeAccessKey = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAccessKey(event.target.value);
  };

  const [accessSecret, setAccessSecret] = useState<string>(data ? HIDDEN_VALUE : '');
  const handleChangeAccessSecret = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAccessSecret(event.target.value);
  };

  const [credentialName, setCredentialName] = useState<string>(data?.name ?? initialName ?? '');
  const handleChangeCredentialName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCredentialName(event.target.value);
  };

  const [credentialDescription, setCredentialDescription] = useState<string>(
    data?.description ?? '',
  );
  const handleChangeCredentialDescription = (val: string): void => {
    setCredentialDescription(val);
  };

  const [selectedDataStore, setSelectedDataStore] = useState<StorageType>(
    (data?.provider as StorageType) ?? StorageType.S3,
  );
  const handleSelectedDataStore = (dataStoreId: string): void => {
    setSelectedDataStore(dataStoreId as StorageType);
  };

  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  const handleSaveNewCredentials = () => {
    const requestObject: CredentialRequest = {
      name: credentialName.trim(),
      description: credentialDescription.trim(),
      provider: selectedDataStore,
      data: {
        access_key: accessKey.trim(),
        access_secret: accessSecret.trim(),
      },
    };

    onSaveCredentials(requestObject);
  };

  const handleSaveCredentials = () => {
    if (data && onSaveCredentials) {
      const requestObject: EditCredentialRequest = {
        ...data,
        name: credentialName,
        description: credentialDescription,
        provider: selectedDataStore,
      };

      onSaveCredentials(requestObject);
    }
  };

  const openConfirmCredentialsModal = () =>
    openConfirmModal({
      title: <Text variant="subTitle01">Confirm credentials</Text>,
      size: 'lg',
      children: (
        <ConfirmCredentials
          accessKey={accessKey}
          accessSecret={accessSecret}
          credentialName={credentialName}
          credentialDescription={credentialDescription}
          datastore={selectedDataStore}
        />
      ),
      labels: { confirm: 'Confirm', cancel: 'Edit' },
      onConfirm: data ? handleSaveCredentials : handleSaveNewCredentials,
      onCancel: () => {
        setModalToShow(data ? ModalType.EDIT : ModalType.ADD);
      },
    });

  const handleOpenConfirmCredentials = () => {
    if (!selectedDataStore || accessKey == '' || accessSecret == '' || credentialName == '') {
      setFormErrorMessage('Fill out all the required fields before saving');
      return;
    }
    setFormErrorMessage('');
    openConfirmCredentialsModal();
    setModalToShow(null);
  };

  // If credential was saved successfully, show toast message and close modal
  useEffect(() => {
    if (isSavingCredentialsSuccess) {
      const message = data ? 'Credential updated successfully' : 'Credential added successfully';
      notifications.show({
        variant: 'success',
        message: message,
      });
      onCloseModal();
    }
  }, [isSavingCredentialsSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setModalToShow(data ? ModalType.EDIT : ModalType.ADD);
      setFormErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  const isConfirmCredentialsDisabled = !(
    accessKey.trim() &&
    accessSecret.trim() &&
    credentialName.trim()
  );

  return (
    <>
      {isSavingCredentials && <FullPageLoader text="Saving credentials..." />}
      {(modalToShow === ModalType.ADD || modalToShow === ModalType.EDIT) && (
        <Modal
          opened
          onClose={onCloseModal}
          onSubmit={handleOpenConfirmCredentials}
          title={
            <>
              <Text variant="subTitle01">
                {modalToShow === ModalType.EDIT ? 'Edit credential' : 'Add new credential'}
              </Text>
              <Text variant="bodyShort02">
                Credentials allow MarkovML to access your secure cloud storage services.
              </Text>
            </>
          }
          withCloseButton
          closeButtonProps={{ 'aria-label': 'Cancel' }}
          centered
          size="xl"
        >
          {formErrorMessage && (
            <Box mb={16}>
              <ErrorBar errorMessage={formErrorMessage} />
            </Box>
          )}
          <Alert
            mb="md"
            icon={<IconInfoCircle />}
            title="Currently we only support AWS S3 cloud storage"
          >
            To request more, please send a mail to{' '}
            <LinkAnchor
              external
              href="mailto:hi@markovml.com"
              sx={(theme: MantineTheme) => ({
                textDecoration: 'underline',
                color: theme.colors.blue[6],
              })}
            >
              hi@markovml.com
            </LinkAnchor>
          </Alert>
          <Grid>
            <Grid.Col sm={10}>
              <TextInput
                ariaLabel="Credential name"
                placeholder="Give a unique identifier for this credential"
                required
                label="Credential name"
                id="credential_name"
                value={credentialName}
                onChange={handleChangeCredentialName}
              />
            </Grid.Col>
            <Grid.Col sm={10}>
              <DataStoreSelect
                formLoading={false}
                selectedDataStore={selectedDataStore}
                onSelectDataStore={handleSelectedDataStore}
              />
            </Grid.Col>
          </Grid>
          <Grid py={16}>
            <Grid.Col sm={10}>
              <TextInput
                ariaLabel="Access key"
                placeholder="Enter access key"
                required
                label="Access key"
                id="access_key"
                value={accessKey}
                onChange={handleChangeAccessKey}
                disabled={modalToShow === ModalType.EDIT}
              />
            </Grid.Col>
            <Grid.Col sm={10}>
              <TextInput
                ariaLabel="Secret key"
                placeholder="Enter secret key"
                required
                label="Secret key"
                id="secret_key"
                value={accessSecret}
                onChange={handleChangeAccessSecret}
                disabled={modalToShow === ModalType.EDIT}
              />
            </Grid.Col>
          </Grid>

          <TextArea
            minRows={2}
            ariaLabel="Description"
            id="credential_description"
            label="Description"
            placeholder="Add any description to help remember the credential"
            value={credentialDescription}
            onChange={handleChangeCredentialDescription}
          />

          <Divider color={theme.colors.gray[2]} style={{ marginBlock: '32px 16px' }} />

          <Horizontal position="right">
            <Button onClick={onCloseModal} variant="outline" color="gray">
              Cancel
            </Button>
            <Button
              onClick={handleOpenConfirmCredentials}
              variant="primary"
              disabled={isConfirmCredentialsDisabled}
            >
              Submit
            </Button>
          </Horizontal>
        </Modal>
      )}
    </>
  );
};
