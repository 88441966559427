import { captureEvent, captureException, Event, withScope } from '@sentry/react';
import { isLocal } from '../lib/env.util';

export const logger = {
  error: (err: any) => {
    captureException(err);
  },
  warn: (err: any) => {
    withScope(scope => {
      scope.setLevel('warning');
      captureException(err);
    });
  },
  info: (event: Event) => {
    if (isLocal()) {
      // default to logging in console for local env
      // eslint-disable-next-line no-console
      console['log'](event.message, event.extra);
      return;
    }
    withScope(scope => {
      scope.setLevel('info');
      captureEvent(event);
    });
  },
};
