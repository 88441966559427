import {
  ColDef,
  FilterChangedEvent,
  RowClickedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useMemo, useRef, useState } from 'react';
import { Alert, Center, Loader, closeAllModals } from '../../../design-system/v2';
import { List } from '../../../design-system/v2/core/data-display/table';
import { DEFAULT_PAGE_SIZE } from '../../../design-system/v2/core/data-display/table/util';
import { ArtifactFilterState, DataOperationType } from '../../../generated/api';
import { getDatasetFiltersFromFilterModel } from '../../../lib/list';
import {
  Dataset,
  useGetDatasetFilters,
  useGetDatasetsV2Query,
} from '../../../queries/datasets/list';
import { useManualRelabelingModal } from '../../dataset-details/manual-relabeling/ManualRelabelingModal';
import { useRuleBasedRelabelingModal } from '../../dataset-details/rule-relabeling/RuleBasedRelabelingModal';
import { useSubsetRelabelingInfoModal } from '../../dataset-details/subset-relabeling/modal/SubsetRelabelingInfoModal';
import { getDatasetColumns } from '../../datasets-table/v2/columns';
import { isDatasetUploading } from '../../datasets-table/v2/util';
import { allDatasetSelectionColumns } from './columns';

interface DatasetSelectionContainerProps {
  dataLabelingType?: DataOperationType;
}

export const DatasetSelectionContainer = ({
  dataLabelingType,
}: DatasetSelectionContainerProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const { open: openRuleBasedRelabelingModal } = useRuleBasedRelabelingModal();
  const { open: openManualRelabelingModal } = useManualRelabelingModal();
  const { open: openSubsetRelabelingInfoModal } = useSubsetRelabelingInfoModal();
  const [sortKey, setSortKey] = useState<string | undefined>('createDate');
  const [sortOrder, setSortOrder] = useState<string | undefined>('desc');
  const [filterModel, setFilterModel] = useState<Record<string, Record<string, unknown>>>();
  const gridRef = useRef<AgGridReact<Dataset>>(null);

  const {
    isLoading: filtersLoading,
    isError: filtersError,
    data: configuration,
  } = useGetDatasetFilters();
  const { data, isLoading, isError } = useGetDatasetsV2Query(
    (currentPage - 1) * DEFAULT_PAGE_SIZE,
    getDatasetFiltersFromFilterModel(filterModel),
    sortKey,
    sortOrder,
    ArtifactFilterState.Active,
  );

  const columns = useMemo<ColDef<Dataset>[]>(() => {
    if (filtersLoading || filtersError) {
      return allDatasetSelectionColumns;
    }
    return getDatasetColumns(ArtifactFilterState.Active, configuration, allDatasetSelectionColumns);
  }, [configuration, filtersLoading, filtersError]);

  const handleRowClick = ({ data, event }: RowClickedEvent<Dataset>) => {
    if (!data?.datasetId || isDatasetUploading(data) || event?.defaultPrevented) {
      return;
    }
    closeAllModals();
    switch (dataLabelingType) {
      case DataOperationType.ManualRelabeling:
        openManualRelabelingModal(data.datasetId);
        return;
      case DataOperationType.RuleBasedRelabeling:
        openRuleBasedRelabelingModal(data.datasetId);
        return;
      case DataOperationType.SubsetRelabeling:
        openSubsetRelabelingInfoModal(data.datasetId);
        return;
      default:
        return;
    }
  };

  const handlePageNumberChange = (pageNumber: number) => {
    gridRef.current?.api.showLoadingOverlay();
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (e: SortChangedEvent<Dataset>) => {
    const sortCol = e.columnApi.getColumnState().find(col => !!col.sort);

    if (sortCol?.colId === sortKey && sortCol?.sort === sortOrder) {
      return;
    }

    gridRef.current?.api.showLoadingOverlay();
    setSortKey(sortCol?.colId);
    setSortOrder(sortCol?.sort ?? undefined);
  };

  const handleFilterChanged = (e: FilterChangedEvent<Dataset>) => {
    const filterModel = e.api.getFilterModel();

    gridRef?.current?.api?.showLoadingOverlay();
    setFilterModel(filterModel);
    setCurrentPage(1);
  };

  if (filtersLoading || isLoading) {
    return (
      <Center h="100%">
        <Loader text="Loading datasets" />
      </Center>
    );
  }

  if (filtersError || isError) {
    <Alert color="red">There was an error fetching your datasets. Please try again</Alert>;
  }

  return (
    <List<Dataset>
      gridRef={gridRef}
      totalRows={data?.numRecords}
      pagination
      currentPage={currentPage}
      columns={columns}
      onRowClicked={handleRowClick}
      leftActionsContent={<></>}
      rowData={data?.list}
      onPageNumberChange={handlePageNumberChange}
      onSortChanged={handleSortChange}
      onFilterChanged={handleFilterChanged}
    />
  );
};
