import { Column } from '@ag-grid-community/core';
import { Loader } from '../../feedback';
import { Center } from '../../layout';

interface LoadingProps {
  columns?: Column[];
}

export const Loading = ({ columns }: LoadingProps) => (
  <Center h="100%" w="100%">
    <Loader variant="dots" size={72} />
  </Center>
);
