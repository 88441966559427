import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Experiments = lazyWithRetry(() => import('../pages/Experiments/Experiments'));
const ExperimentComparisonPage = lazyWithRetry(
  () => import('../pages/ExperimentComparison/ExperimentComparison'),
);

const experimentRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Experiments />,
  },
  {
    path: getRoute(AppRoutes.COMPARE_EXPERIMENTS),
    element: <ExperimentComparisonPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const ExperimentsRouter = (): JSX.Element => (
  <Routes>{experimentRoutes.map(renderAuthRoute)}</Routes>
);
