import { useInfiniteQuery } from 'react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { Activity, ActivityList, BasicUserDetails, StudioIdType } from '../../generated/api';
import { userManagementApi } from '../../lib/api';

const activityKeys = {
  all: ['activity'] as const,
  listWorkspace: (workspaceId: string) =>
    [...activityKeys.all, 'list-workspace', workspaceId] as const,
  listStudio: (workspaceId: string, studioId: StudioIdType) =>
    [...activityKeys.all, 'list-studio', workspaceId, studioId] as const,
};

export interface WorkspaceActivity extends Activity {
  userInfo: BasicUserDetails;
}

const mergeUserInfoToActivity = (data: ActivityList): WorkspaceActivity[] => {
  const { activities, users } = data;
  return (
    activities
      .map(activity => {
        const userInfo = users[activity.userId];
        const activityWithUserInfo: WorkspaceActivity = {
          ...activity,
          userInfo,
        };
        return activityWithUserInfo;
      })
      // Filter out activity missing user info (temp fix)
      .filter(item => item.userInfo)
  );
};

const ACTIVITY_API_PAGE_SIZE = 10;

export const useInfiniteGetWorkspaceActivitiesQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    activityKeys.listWorkspace(workspaceId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0 } = pageParam;
      const start = pageNumber * ACTIVITY_API_PAGE_SIZE,
        end = start + ACTIVITY_API_PAGE_SIZE;
      return userManagementApi.getWorkspaceActivityV1(workspaceId, start, end);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const response = lastPage.data;
        const noMorePages = response.activities.length < ACTIVITY_API_PAGE_SIZE;
        return !noMorePages ? { pageNumber: pages.length } : undefined;
      },
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => mergeUserInfoToActivity(page.data)),
      }),
      enabled: !!workspaceId,
    },
  );
};

export const useGetStudioActivityInfiniteQuery = (studioId: StudioIdType) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    activityKeys.listStudio(workspaceId, studioId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0 } = pageParam;
      const start = pageNumber * ACTIVITY_API_PAGE_SIZE,
        end = start + ACTIVITY_API_PAGE_SIZE;
      return userManagementApi.getWorkspaceActivityV2(workspaceId, studioId, start, end);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const response = lastPage.data;
        const noMorePages = response.activities.length < ACTIVITY_API_PAGE_SIZE;
        return !noMorePages ? { pageNumber: pages.length } : undefined;
      },
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => mergeUserInfoToActivity(page.data)),
      }),
      enabled: Boolean(workspaceId && studioId),
    },
  );
};
