import { Alert, Button, closeAllModals, Horizontal } from '../../../design-system/v2';

interface ArchiveUnarchiveModalProps {
  onClick: () => void;
  isError: boolean;
  isLoading: boolean;
}

export const ArchiveUnarchiveModal = ({
  onClick,
  isError,
  isLoading,
}: ArchiveUnarchiveModalProps) => {
  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <>
      {isError && (
        <Alert color="red">Error saving changes, please refresh or contact support</Alert>
      )}
      <Horizontal mt="xl" position="right">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClick} loading={isLoading}>
          {isError ? 'Retry' : 'Confirm'}
        </Button>
      </Horizontal>
    </>
  );
};
