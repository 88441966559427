import {
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps,
} from '@mantine/core';

type SegmentedControlProps = MantineSegmentedControlProps & React.RefAttributes<HTMLDivElement>;

export const SegmentedControl = (props: SegmentedControlProps) => (
  // We don't want to have any color, but the primary color only
  <MantineSegmentedControl {...props} color="blue.9" radius="xs" />
);
