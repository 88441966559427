import startCase from 'lodash/startCase';
import { FunctionComponent, memo as ReactMemo } from 'react';
import { AnalyserVisualizationContainer } from '../../../../../../../components/analyser/analyser-visualization/AnalyserVisualization.container';
import { CustomMetricsGroupContainer } from '../../../../../../../components/custom-metrics-group/CustomMetricsGroup.container';
import { CorrelationContainer } from '../../../../../../../components/data-profiling/visualization/Correlation.container';
import { MissingValuesContainer } from '../../../../../../../components/data-profiling/visualization/MissingValues.container';
import { DataSummaryType } from '../../../../../../../components/dataset-details/data-profiling/DataInsightsOverview';
import { DataInsightsOverviewContainer } from '../../../../../../../components/dataset-details/data-profiling/DataInsightsOverview.container';
import { BaselineModelStatsContainer } from '../../../../../../../components/dataset-details/data-profiling/data-quality/BaselineModelStats.container';
import { ClassQualityScoreContainer } from '../../../../../../../components/dataset-details/data-profiling/data-quality/ClassQualityScore.container';
import { OverlappingClassMetricsContainer } from '../../../../../../../components/dataset-details/data-profiling/data-quality/OverlappingClassMetrics.container';
import { TopKFeaturesChartContainer } from '../../../../../../../components/dataset-details/data-profiling/data-quality/TopKFeaturesChart.container';
import { VariableTableContainer } from '../../../../../../../components/dataset-details/data-profiling/variables/VariableTable.container';
import { VariablesCharts } from '../../../../../../../components/dataset-details/data-profiling/variables/VariablesCharts';
import { isSplitSegmentType } from '../../../../../../../components/dataset-registration/util';
import { ClassDistributionContainer } from '../../../../../../../components/dataset-summary/class-distribution/ClassDistribution.container';
import { DisagreementTableContainer } from '../../../../../../../components/disagreement-table/DisagreementTable.container';
import { ClassDistributionContainer as EvaluationClassDistributionContainer } from '../../../../../../../components/evaluation-comparison/multimodel/class-distribution/ClassDistribution.container';
import { ROCAndPRCurveContainer } from '../../../../../../../components/evaluation-comparison/multimodel/roc-pr-curve/ROCAndPRCurve.container';
import { SplineChartVisualizationContainer } from '../../../../../../../components/experiments/spline-visualization/SplineChartVisualization.container';
import { ClassMetricsContainer } from '../../../../../../../components/recording-details/class-metrics/ClassMetrics.container';
import { ConfusionMatrixContainer } from '../../../../../../../components/recording-details/conf-matrix/ConfusionMatrix.container';
import { CHART_BAR_COLOR } from '../../../../../../../components/recording-details/sections/DistributionsSection';
import { VIZ_NAMES } from '../../../../../../../components/snippets/detail/add-charts/hooks/util';
import { useSnippetDetail } from '../../../../../../../components/snippets/detail/context/SnippetContext';
import { DatasetVariableStatsVisualization, SegmentType } from '../../../../../../../generated/api';
import { capitalize } from '../../../../../../../lib/ui';
import {
  AnalysisIllustration,
  ClassDistributionIllustration,
  DataProfileIllustration,
} from '../../../../../../Icons';
import { Box, Card, Center, Horizontal, ScrollArea, Text } from '../../../../../index';
import {
  AnalyserParameters,
  BaselineModelStatsParameters,
  ClassDistributionParameters,
  ClassQualityScoreParameters,
  DataProfileParameters,
  DataProfileVariableParameters,
  EvaluationParameters,
  ExperimentParameters,
  OverlappingClassMetricsParameters,
  TopKFeaturesParameters,
  VisualizationModel,
  VisualizationType,
} from './util';

export type VisualizationFactoryProps = VisualizationModel & {
  showTitle: boolean;
  canComment?: boolean;
  onClickComment?: () => void;
};

// Memoizing all the components, as its data will never be changed
const memo = <T,>(component: FunctionComponent<T>) => ReactMemo(component, () => true);

const AnalyserVisualizationContainerMemoized = memo(AnalyserVisualizationContainer);
const ClassQualityScoreContainerMemoized = memo(ClassQualityScoreContainer);
const TopKFeaturesChartContainerMemoized = memo(TopKFeaturesChartContainer);
const BaselineModelStatsContainerMemoized = memo(BaselineModelStatsContainer);
const OverlappingClassMetricsContainerMemoized = memo(OverlappingClassMetricsContainer);
const ClassDistributionContainerMemoized = memo(ClassDistributionContainer);
const DataInsightsOverviewContainerMemoized = memo(DataInsightsOverviewContainer);
const CorrelationContainerMemoized = memo(CorrelationContainer);
const MissingValuesContainerMemoized = memo(MissingValuesContainer);
const VariableTableContainerMemoized = memo(VariableTableContainer);
const VariablesChartsMemoized = memo(VariablesCharts);
const SplineChartVisualizationContainerMemoized = memo(SplineChartVisualizationContainer);
const ROCAndPRCurveContainerMemoized = memo(ROCAndPRCurveContainer);
const ClassMetricsContainerMemoized = memo(ClassMetricsContainer);
const EvaluationClassDistributionContainerMemoized = memo(EvaluationClassDistributionContainer);
const CustomMetricsGroupContainerMemoized = memo(CustomMetricsGroupContainer);
const ConfusionMatrixContainerMemoized = memo(ConfusionMatrixContainer);
const DisagreementTableContainerMemoized = memo(DisagreementTableContainer);

// FIXME:: Need to fix types in this file, get rid of `as` while using `visualizationParameters`.

const ChartIllustrationCard = ({
  visualizationType,
  visualizationParameters,
}: VisualizationModel) => {
  let bgColor = '';
  let title = '';
  let subTitle = '';
  let chart = null;

  if (visualizationType === VisualizationType.DATASET_ANALYSER) {
    bgColor = 'purple.0';
    title = (visualizationParameters as AnalyserParameters).title;
    subTitle = (visualizationParameters as AnalyserParameters).segmentType;
    chart = <AnalysisIllustration />;
  } else if (visualizationType === VisualizationType.DATASET_CLASS_DISTRIBUTION) {
    bgColor = 'gray.1';
    title = VIZ_NAMES[VisualizationType.DATASET_CLASS_DISTRIBUTION];
    subTitle = `${(visualizationParameters as ClassDistributionParameters).segmentType} - (${
      (visualizationParameters as ClassDistributionParameters).datasetId
    })`;
    chart = <ClassDistributionIllustration />;
  } else if (
    visualizationType === VisualizationType.DATASET_DATA_PROFILE_SUMMARY ||
    visualizationType === VisualizationType.DATASET_DATA_PROFILE_INSIGHTS ||
    visualizationType === VisualizationType.DATASET_DATA_PROFILE_CORRELATION ||
    visualizationType === VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE
  ) {
    bgColor = 'blue.1';
    title = VIZ_NAMES[visualizationType];
    subTitle = `${(visualizationParameters as DataProfileParameters).segmentType} - (${
      (visualizationParameters as DataProfileParameters).datasetId
    })`;
    chart = <DataProfileIllustration />;
  } else if (visualizationType === VisualizationType.DATASET_DATA_PROFILE_VARIABLE) {
    bgColor = 'blue.1';
    const vizParams = visualizationParameters as DataProfileVariableParameters;
    title = `${vizParams.title} - ${vizParams.variableName}`;
    subTitle = `${vizParams.visualizationId} - (${vizParams.datasetId})`;
    chart = <DataProfileIllustration />;
  } else if (visualizationType === VisualizationType.EXPERIMENT_CHART) {
    bgColor = 'green.1';
    title = startCase(
      (visualizationParameters as ExperimentParameters).metricKey.split('_').join(' '),
    );
    subTitle = `${(visualizationParameters as ExperimentParameters).experimentId}`;
    chart = <DataProfileIllustration />;
  } else if (
    visualizationType === VisualizationType.EVALUATION_ROC_PR_CURVE ||
    visualizationType === VisualizationType.EVALUATION_CLASS_LEVEL_METRICS ||
    visualizationType === VisualizationType.EVALUATION_CLASS_DISTRIBUTION ||
    visualizationType === VisualizationType.EVALUATION_CUSTOM_METRICS ||
    visualizationType === VisualizationType.EVALUATION_CONFUSION_MATRIX ||
    visualizationType === VisualizationType.EVALUATION_MODEL_DISAGREEMENTS
  ) {
    bgColor = 'blue.0';
    title = VIZ_NAMES[visualizationType];
    subTitle = `${(visualizationParameters as EvaluationParameters).evaluationId}`;
    chart = <DataProfileIllustration />;
  }

  return (
    <Card h="100%" w="100%" p={0} shadow="md" withBorder bg={bgColor}>
      <Box
        h={60}
        bg="gray.0"
        sx={theme => ({
          borderTop: `1px solid ${theme.colors.gray[3]}`,
        })}
      >
        <Text pt={6} px="sm" variant="subTitle03" color="gray.7">
          {title}
        </Text>
        <Text pb={6} px="sm" variant="subTitle04" color="gray.5">
          {subTitle}
        </Text>
      </Box>
      <Center h={`calc(100% - 60px)`} p="sm">
        {chart}
      </Center>
    </Card>
  );
};

export const VisualizationFactory = ({
  visualizationType,
  visualizationParameters,
  ...analyserProps
}: VisualizationFactoryProps) => {
  const { showVersions } = useSnippetDetail();

  if (showVersions) {
    return (
      // FIXME:: Fix this type error, passed proper types
      // but showing error due to some complex types of VisualizationModel.
      // Ignoring this for now
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ChartIllustrationCard
        visualizationType={visualizationType}
        visualizationParameters={visualizationParameters}
      />
    );
  }

  switch (visualizationType) {
    case VisualizationType.DATASET_ANALYSER:
      return (
        <AnalyserVisualizationContainerMemoized
          analysis={visualizationParameters as AnalyserParameters}
          {...analyserProps}
        />
      );

    case VisualizationType.DATASET_CLASS_QUALITY_SCORE:
      return (
        <ScrollArea bg="white.0" h="100%">
          <ClassQualityScoreContainerMemoized
            datasetId={(visualizationParameters as ClassQualityScoreParameters).datasetId}
            dataQualityScore={
              (visualizationParameters as ClassQualityScoreParameters).dataQualityScore
            }
          />
        </ScrollArea>
      );

    case VisualizationType.DATASET_TOP_K_FEATURES:
      return (
        <ScrollArea bg="white.0" h="100%">
          <TopKFeaturesChartContainerMemoized
            datasetId={(visualizationParameters as TopKFeaturesParameters).datasetId}
          />
        </ScrollArea>
      );

    case VisualizationType.DATASET_BASELINE_MODEL_STATS:
      return (
        <ScrollArea bg="white.0" h="100%">
          <BaselineModelStatsContainerMemoized
            datasetId={(visualizationParameters as BaselineModelStatsParameters).datasetId}
          />
        </ScrollArea>
      );

    case VisualizationType.DATASET_OVERLAPPING_CLASS_METRICS:
      return (
        <ScrollArea bg="white.0" h="100%">
          <OverlappingClassMetricsContainerMemoized
            datasetId={(visualizationParameters as OverlappingClassMetricsParameters).datasetId}
          />
        </ScrollArea>
      );

    case VisualizationType.DATASET_CLASS_DISTRIBUTION:
      return (
        <Box bg="white.0" h="100%">
          <ClassDistributionContainerMemoized
            datasetId={(visualizationParameters as ClassDistributionParameters).datasetId}
            filteredSegment={(visualizationParameters as ClassDistributionParameters).segmentType}
            showBorders={false}
          />
        </Box>
      );

    case VisualizationType.DATASET_DATA_PROFILE_SUMMARY:
      const segmentType = (visualizationParameters as DataProfileParameters).segmentType;
      return (
        <Box bg="white.0" h="100%" sx={{ overflow: 'auto' }}>
          <Horizontal
            p="lg"
            noWrap
            position="apart"
            sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[4]}` })}
          >
            <Text color="dark.7" variant="subTitle03">
              Overview
            </Text>
            {isSplitSegmentType(segmentType) && (
              <Text color="gray.6" variant="subTitle04">
                {capitalize(segmentType)}
              </Text>
            )}
          </Horizontal>
          <DataInsightsOverviewContainerMemoized
            datasetId={(visualizationParameters as DataProfileParameters).datasetId}
            segmentType={segmentType}
            filterSummaryType={DataSummaryType.OVERVIEW}
            hideHeader
          />
        </Box>
      );

    case VisualizationType.DATASET_DATA_PROFILE_INSIGHTS:
      return (
        <Box bg="white.0" h="100%" sx={{ overflow: 'auto' }}>
          <Horizontal
            p="lg"
            noWrap
            position="apart"
            sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[4]}` })}
          >
            <Text color="dark.7" variant="subTitle03">
              Insights
            </Text>
            <Text color="gray.6" variant="subTitle04">
              {capitalize((visualizationParameters as DataProfileParameters).segmentType ?? '')}
            </Text>
          </Horizontal>
          <DataInsightsOverviewContainerMemoized
            datasetId={(visualizationParameters as DataProfileParameters).datasetId}
            segmentType={(visualizationParameters as DataProfileParameters).segmentType}
            filterSummaryType={DataSummaryType.INSIGHTS}
            hideHeader
          />
        </Box>
      );

    case VisualizationType.DATASET_DATA_PROFILE_CORRELATION:
      return (
        <Box bg="white.0" h="100%" pos="relative" sx={{ overflow: 'hidden' }}>
          <Text
            color="gray.6"
            variant="subTitle04"
            pos="absolute"
            top={16}
            left={24}
            sx={{ zIndex: 10 }}
          >
            {capitalize((visualizationParameters as DataProfileParameters).segmentType ?? '')}
          </Text>
          <CorrelationContainerMemoized
            datasetId={(visualizationParameters as DataProfileParameters).datasetId}
            segmentType={(visualizationParameters as DataProfileParameters).segmentType}
            showBorders={false}
          />
        </Box>
      );

    case VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE:
      return (
        <Box bg="white.0" h="100%" pos="relative">
          <Text
            color="gray.6"
            variant="subTitle04"
            pos="absolute"
            top={16}
            left={24}
            sx={{ zIndex: 10 }}
          >
            {capitalize((visualizationParameters as DataProfileParameters).segmentType ?? '')}
          </Text>
          <MissingValuesContainerMemoized
            datasetId={(visualizationParameters as DataProfileParameters).datasetId}
            segment={(visualizationParameters as DataProfileParameters).segmentType as SegmentType}
            showBorders={false}
          />
        </Box>
      );

    case VisualizationType.DATASET_DATA_PROFILE_VARIABLE: {
      const vizParams = visualizationParameters as DataProfileVariableParameters;
      if (vizParams.chartType === 'table') {
        return (
          <Box bg="white.0" h="100%" sx={{ overflow: 'auto' }}>
            <VariableTableContainerMemoized
              datasetId={vizParams.datasetId}
              segmentType={vizParams.segmentType}
              variableName={vizParams.variableName}
              title={vizParams.title}
              visualizationId={vizParams.visualizationId}
              showBorder={false}
              height="100%"
            />
          </Box>
        );
      }

      return (
        <Box bg="white.0" h="100%">
          <VariablesChartsMemoized
            datasetId={vizParams.datasetId}
            segmentType={vizParams.segmentType}
            variable={vizParams.variableName}
            chart={
              {
                ...vizParams,
                visualizationType: 'chart',
              } as DatasetVariableStatsVisualization
            }
            groupByTarget={vizParams.groupByTarget}
          />
        </Box>
      );
    }

    case VisualizationType.EXPERIMENT_CHART:
      return (
        <SplineChartVisualizationContainerMemoized
          experimentIds={[(visualizationParameters as ExperimentParameters).experimentId]}
          metricName={(visualizationParameters as ExperimentParameters).metricKey}
          fullHeight
        />
      );

    case VisualizationType.EVALUATION_ROC_PR_CURVE:
      return (
        <Box bg="white.0" h="100%">
          <ROCAndPRCurveContainerMemoized
            evaluationIds={[(visualizationParameters as EvaluationParameters).evaluationId]}
            showBorders={false}
          />
        </Box>
      );

    case VisualizationType.EVALUATION_CLASS_LEVEL_METRICS:
      return (
        <Box bg="white.0" h="100%">
          <ClassMetricsContainerMemoized
            evaluationId={(visualizationParameters as EvaluationParameters).evaluationId}
          />
        </Box>
      );

    case VisualizationType.EVALUATION_CLASS_DISTRIBUTION:
      return (
        <EvaluationClassDistributionContainerMemoized
          evaluationIds={[(visualizationParameters as EvaluationParameters).evaluationId]}
          chartColors={[CHART_BAR_COLOR]}
        />
      );

    case VisualizationType.EVALUATION_CUSTOM_METRICS:
      return (
        <Box bg="white.0" h="100%">
          <CustomMetricsGroupContainerMemoized
            recordingId={(visualizationParameters as EvaluationParameters).evaluationId}
          />
        </Box>
      );

    case VisualizationType.EVALUATION_CONFUSION_MATRIX:
      return (
        <ConfusionMatrixContainerMemoized
          evaluationId={(visualizationParameters as EvaluationParameters).evaluationId}
        />
      );

    case VisualizationType.EVALUATION_MODEL_DISAGREEMENTS:
      return (
        <Box h="100%" sx={{ overflow: 'hidden' }}>
          <DisagreementTableContainerMemoized
            recordingId={(visualizationParameters as EvaluationParameters).evaluationId}
          />
        </Box>
      );

    default:
      return <Box />;
  }
};
