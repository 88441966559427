import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Horizontal,
  Text,
  Vertical,
  closeAllModals,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { toPlural } from '../../../../../lib/ui';
import { PaginatedColumnsTable } from '../../../../paginated-columns-table/PaginatedColumnsTable';

interface FeatureSelectionProps {
  datasetFeatures: string[];
  fileResult: string[][];
  uploadNewFile: () => void;
  submitFile: (featuresSelected: string[]) => void;
}

export const HEIGHT = 512;

export const FeatureSelection = ({
  datasetFeatures,
  fileResult,
  uploadNewFile,
  submitFile,
}: FeatureSelectionProps) => {
  const theme = useMarkovTheme();
  const [columnSelected, setColumnSelected] = useState<Record<string, boolean>>({});

  const [headers, ...rows] = fileResult ?? [];
  const commonFeaturesColumns = useMemo(
    () => intersection(datasetFeatures, headers) ?? [],
    [datasetFeatures, headers],
  );
  const selectedFeaturesList = Object.keys(columnSelected).filter(col => columnSelected[col]);
  const areSameFeaturesSelected = isEqual(
    sortBy(selectedFeaturesList),
    sortBy(commonFeaturesColumns),
  );

  const getColumnColor = (columnId: string) => {
    if (commonFeaturesColumns.includes(columnId)) {
      return theme.colors.seaGreen[0];
    }
    return theme.colors.white[0];
  };

  const getIsColumnChecked = (columnId: string) => columnSelected[columnId] ?? false;

  const onColumnCheckedChange = (columnId: string, value: boolean) => {
    setColumnSelected(prevVal => ({ ...prevVal, [columnId]: value }));
  };

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleSubmitFile = () => {
    submitFile(selectedFeaturesList);
  };

  useEffect(() => {
    const selectedColumns: Record<string, boolean> = {};
    commonFeaturesColumns.forEach(col => {
      selectedColumns[col] = true;
    });
    setColumnSelected(selectedColumns);
  }, [commonFeaturesColumns]);

  return (
    <Vertical spacing="xxl">
      <Horizontal>
        {datasetFeatures && !areSameFeaturesSelected && (
          <Alert color="red">
            The chosen feature columns differ from those utilized during registration, potentially
            impacting the accuracy of the model`s predictions.
          </Alert>
        )}
        {commonFeaturesColumns.length > 0 && (
          <Text ml="auto" p="md" w="fit-content" variant="textLink" bg="seaGreen.0">
            {toPlural(commonFeaturesColumns.length, 'column')} identified as features for the
            prompts
          </Text>
        )}
      </Horizontal>

      <PaginatedColumnsTable
        columns={headers}
        rows={rows}
        height={HEIGHT}
        onColumnCheckedChange={onColumnCheckedChange}
        getIsColumnChecked={getIsColumnChecked}
        getColumnColor={getColumnColor}
        getIsPageHighlighted={() => false}
      />

      <Horizontal noWrap position="apart">
        <Button variant="subtle" onClick={uploadNewFile}>
          Upload a different file
        </Button>

        <Horizontal>
          <Button w={160} variant="light" bg="gray.1" color="gray.7" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            w={160}
            variant="primary"
            color="blue.6"
            disabled={!selectedFeaturesList.length}
            onClick={handleSubmitFile}
          >
            Proceed
          </Button>
        </Horizontal>
      </Horizontal>
    </Vertical>
  );
};
