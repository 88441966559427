import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Card,
  Center,
  closeAllModals,
  Loader,
  notifications,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { TemplateModel } from '../../../../generated/api';
import { formatDateTime } from '../../../../lib/ui';
import { useCreateWorkflowMutation } from '../../../../queries/workflows/builder';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '../../../../queries/workflows/operators';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { useTemplateFlowModal } from '../../../workflows/create/templates/useTemplateFlowModal';
import { CardGrid } from '../../home-v2/CardGrid';
import { TemplateCard } from './TemplateCard';

const useGetTemplatesToDisplay = (templates: TemplateModel[]) => {
  const theme = useMarkovTheme();

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  if (isXs) {
    return templates.slice(0, 1);
  } else if (isSm) {
    return templates.slice(0, 2);
  }

  return templates.slice(0, 3);
};

interface TemplateContainerProps {
  searchText: string;
  isHomePage?: boolean;
}

export const TemplateContainer = ({ searchText, isHomePage }: TemplateContainerProps) => {
  const navigate = useNavigate();
  const { getWorkflowAddRoute } = useAbsoluteRoutes();
  const {
    mutateAsync: createWorkflow,
    isLoading: creatingWorkflow,
    isError: errorCreatingWorkflow,
  } = useCreateWorkflowMutation();
  const { isLoading, isError, data } = useGetTemplatesListQuery();
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorListQuery();
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  const filteredTemplates = data?.filter(
    datum => datum.name.includes(searchText) || datum.description.includes(searchText),
  );

  const homepageTemplates = useGetTemplatesToDisplay(filteredTemplates ?? []);

  if (isLoading) {
    return (
      <Center h="100%" w="100%">
        <Loader text="Loading templates..." />
      </Center>
    );
  }

  if (isError || !data) {
    return <Alert color="red">Error occured fetching templates</Alert>;
  }

  const handleAddWorkflow = async (templateId?: string, workflowName?: string) => {
    try {
      const data = await createWorkflow({ workflowName, templateId });
      if (data?.data?.workflowId) {
        closeAllModals();

        navigate(getWorkflowAddRoute(data.data.workflowId));
      }
    } catch (e) {
      notifications.error('Error while creating workflow. Please try again and contact support');
    }
  };

  const createBlankWorkflow = () => handleAddWorkflow('', `Untitled-${formatDateTime(new Date())}`);

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? [], handleAddWorkflow);
  };

  return (
    <CardGrid>
      <Card
        radius="sm"
        bg="blue.6"
        onClick={createBlankWorkflow}
        data-testid="button-create-new-workflow"
      >
        <Center w="100%" h="100%">
          <IconPlus color="#fff" size={48} />
        </Center>
      </Card>
      {(isHomePage ? homepageTemplates : filteredTemplates)?.map(template => (
        <TemplateCard
          key={template.templateId}
          templateData={template}
          handleFlowButtonClick={handleFlowButtonClick}
        />
      ))}
    </CardGrid>
  );
};
