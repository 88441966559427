import {
  NotificationProps as MantineNotificationProps,
  Notifications as MantineNotifications,
  NotificationsProps as MantineNotificationsProps,
  notifications as mantineNotifications,
} from '@mantine/notifications';
import { IconAlertTriangleFilled, IconCheck, IconInfoSmall, IconX } from '@tabler/icons-react';

export type NotificationVariants = 'error' | 'success' | 'warning' | 'info' | 'loading';

type VariantDetails = {
  icon?: JSX.Element;
  title: React.ReactNode;
  color?: string;
  loading?: boolean;
};

interface NotificationsProps extends MantineNotificationProps {
  variant: NotificationVariants;
}

const getVariantDetails = (
  variant: NotificationVariants,
  title?: React.ReactNode,
): VariantDetails => {
  switch (variant) {
    case 'error':
      return { icon: <IconX size="1.1rem" />, title: title ?? 'Error', color: 'red' };
    case 'success':
      return { icon: <IconCheck size="1.1rem" />, title: title ?? 'Success', color: 'green' };
    case 'warning':
      return {
        icon: <IconAlertTriangleFilled size="1.1rem" />,
        title: title ?? 'Alert',
        color: 'yellow.6',
      };
    case 'info':
      return { icon: <IconInfoSmall size="1.8rem" />, title: title ?? 'Info' };
    case 'loading':
      return { loading: true, title: title ?? 'Loading' };
    default:
      return { title: '' };
  }
};

export const notifications = {
  ...mantineNotifications,
  show: (props: NotificationsProps) => {
    const variantDetails = getVariantDetails(props.variant, props.title);
    mantineNotifications.show({
      ...props,
      ...variantDetails,
    });
  },
  error: (message: string, props?: Partial<NotificationsProps>) => {
    const variant = 'error';
    const variantDetails = getVariantDetails(variant, props?.title);
    mantineNotifications.show({
      ...variantDetails,
      ...props,
      variant,
      message,
    });
  },
  success: (message: string, props?: Partial<NotificationsProps>) => {
    const variant = 'success';
    const variantDetails = getVariantDetails(variant, props?.title);
    mantineNotifications.show({
      ...variantDetails,
      ...props,
      variant,
      message,
    });
  },
};
export const Notifications = (props: MantineNotificationsProps) => (
  <MantineNotifications position="top-center" {...props} />
);
