import {
  Button,
  Card,
  Center,
  Grid,
  Horizontal,
  ScrollArea,
  Text,
  UnstyledButton,
  Vertical,
} from '../../../../design-system/v2';
import { OperatorModel, TemplateModel } from '../../../../generated/api';
import { formatDateTime } from '../../../../lib/ui';
import { useTemplateFlowModal } from './useTemplateFlowModal';

interface TemplateListProps {
  templates: TemplateModel[];
  operatorList: OperatorModel[];
  handleAddWorkflow: (templateId?: string, workflowName?: string) => void;
}

export const TemplateList = ({ templates, handleAddWorkflow, operatorList }: TemplateListProps) => {
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? []);
  };

  const buttonText = 'Clone template';

  return (
    <ScrollArea.Autosize p="sm" h="calc(100% - 60px)">
      <Vertical spacing="lg">
        {templates.map(template => (
          <Card withBorder p="lg" shadow="md" key={template.templateId}>
            <Grid>
              <Grid.Col span="auto">
                <Vertical align="left" spacing="sm">
                  <Horizontal spacing="xs">
                    <Text variant="subTitle02" color="gray.8">
                      {template.name}
                    </Text>
                    <Text variant="small01" color="gray.7">
                      by {template.author}
                    </Text>
                  </Horizontal>
                  <Text h={48} variant="bodyShort03" color="gray.6">
                    {template.description}
                  </Text>
                </Vertical>
              </Grid.Col>
              <Grid.Col span="content">
                <Center h="100%">
                  <Horizontal spacing="xl" noWrap>
                    <UnstyledButton
                      onClick={() => handleFlowButtonClick(template)}
                      variant="subtle"
                    >
                      <Text variant="textLink" color="gray.6">
                        View Flow
                      </Text>
                    </UnstyledButton>
                    <Button
                      onClick={() =>
                        handleAddWorkflow(
                          template.templateId,
                          `${template.name}-${formatDateTime(new Date())}`,
                        )
                      }
                      variant="outline"
                      size="xs"
                    >
                      {buttonText}
                    </Button>
                  </Horizontal>
                </Center>
              </Grid.Col>
            </Grid>
          </Card>
        ))}
      </Vertical>
    </ScrollArea.Autosize>
  );
};
