import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { ReactElement } from 'react';
import { Layout, Responsive } from 'react-grid-layout';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { Box } from '../../../design-system/v2';
import { useElementSize } from '../../../design-system/v2/hooks';
import { GridItem } from './GridItem';
import { layoutStaticProps } from './util';

interface GridLayoutProps {
  layout: Layout[];
  children: ReactElement[];
  className?: string;
  readOnlyMode?: boolean;
  onLayoutChange?: (layout: Layout[]) => void;
  onClick?: () => void;
}

export const GridLayout = ({
  children,
  layout,
  className,
  readOnlyMode,
  onLayoutChange = noop,
  onClick,
}: GridLayoutProps) => {
  const { ref, width } = useElementSize();

  const handleLayoutChange = debounce((currentLayout: Layout[]) => {
    onLayoutChange(currentLayout);
  }, 200);

  let defaultLayoutProps = {
    ...layoutStaticProps,
  };

  if (readOnlyMode) {
    defaultLayoutProps = {
      ...layoutStaticProps,
      ...{
        isDraggable: false,
        isResizable: false,
      },
    };
  }

  const layoutProps = layout.map(l => ({
    ...l,
    ...defaultLayoutProps,
  }));

  return (
    <Box ref={ref} className={className} onClick={onClick}>
      <Responsive
        className="layout"
        layouts={{ lg: layoutProps, md: layoutProps, sm: layoutProps }}
        autoSize
        cols={{ xxs: 8, xs: 8, lg: 24, md: 24, sm: 16 }}
        rowHeight={24}
        margin={[12, 12]}
        compactType="vertical"
        width={width}
        onLayoutChange={handleLayoutChange}
      >
        {children}
      </Responsive>
    </Box>
  );
};

GridLayout.Item = GridItem;
