import { SerializedStyles } from '@emotion/react';
import { IconEye } from '@tabler/icons-react';
import { PropsWithChildren, useMemo, useState } from 'react';
import {
  Handle,
  HandleType,
  NodeProps,
  Position,
  ReactFlowState,
  getConnectedEdges,
  useNodeId,
  useStore,
} from 'reactflow';
import { useCreateWorkflow } from '../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../contexts/workflows/WorkflowRunContext';
import { IconDelete, IconEdit } from '../../../design-system';
import {
  ActionIcon,
  Box,
  Center,
  Flex,
  Horizontal,
  Text,
  Vertical,
} from '../../../design-system/v2';
import {
  OperatorCategory,
  OperatorIODescription,
  WorkflowRunOperatorStatus,
} from '../../../generated/api';
import { useDebugRunStatusQuery } from '../../../queries/workflows/debug';
import { useGetOperatorDetailsQuery } from '../../../queries/workflows/operators';
import {
  useFormModal,
  useReadOnlyForm,
} from '../create/workflow-builder/operator-parameter-form/useFormModal';
import { getOperatorIcon } from '../util';
import { MAX_ALLOWED_CONNECTIONS_PER_HANDLE, NodeData, getColorsFromStatus } from './utils';

interface InputOutputHandleProps {
  position: Position;
  handleType: HandleType;
}

interface NodeActionIconProps {
  onClickAction: () => void;
  icon: JSX.Element;
}

interface InputOutputHandleProps {
  position: Position;
  handleType: HandleType;
  io: OperatorIODescription[];
}

interface NodeViewerProps extends NodeProps<NodeData> {
  renderNodeActions: boolean;
}

const NODE_HEIGHT = 132;

const selector = (s: ReactFlowState) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const InputOutputHandle = ({ position, handleType, io }: InputOutputHandleProps) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    if (!nodeId) {
      return false;
    }

    const node = nodeInternals.get(nodeId);

    if (!node) {
      return false;
    }

    const connectedEdges = getConnectedEdges([node], edges);

    return connectedEdges.length < MAX_ALLOWED_CONNECTIONS_PER_HANDLE;
  }, [nodeInternals, edges, nodeId]);

  const nodeHeight = NODE_HEIGHT; // Assuming a fixed node height
  const handleHeight = 12; // Height of each handle
  const spacing = 20; // Spacing between each handle
  const count = io.length;

  const handles = Array.from({ length: count }, (_, index) => {
    /*
     * This code calculate the vertical position (topPosition) for each handle in a symmetric distribution around the center of the node.
     *
     * The middle index determines the middle point in the distribution, with other handles spaced evenly around it.
     *
     * number of Handles -> Distribution
     * 1 -> 0
     * 2 -> -0.5, 0.5
     * 3 -> -1, 0, 1
     * 4 -> -1.5, -0.5, 0.5, 1.5
     */

    const middleIndex = (count - 1) / 2;
    const offset = (index - middleIndex) * (handleHeight + spacing);
    const topPosition = nodeHeight / 2 + offset;

    return (
      <Handle
        id={io[index].id}
        key={index}
        type={handleType}
        position={position}
        isConnectable={isHandleConnectable}
        style={{
          left: position === Position.Left ? '-6px' : 'none',
          right: position === Position.Right ? '-6px' : 'none',
          backgroundColor: '#ADB5BD',
          height: `${handleHeight}px`,
          width: `${handleHeight}px`,
          top: `${topPosition}px`,
        }}
      />
    );
  });

  return <>{handles}</>;
};

const InputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Left} handleType="target" io={io} />
);

const OutputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Right} handleType="source" io={io} />
);

const NodeContainer = ({
  bgColor,
  borderColor,
  revolvingBorder,
  children,
}: PropsWithChildren<{
  bgColor: string;
  borderColor: string;
  revolvingBorder?: SerializedStyles;
}>) => (
  <Center sx={{ ...revolvingBorder }}>
    <Center
      p="xl"
      pos="relative"
      bg={bgColor}
      w="132px"
      h="132px"
      sx={{
        border: revolvingBorder ? 'none' : `2px solid ${borderColor}`,
        borderRadius: '50%',
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        ':hover': {
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.25), 0px 10px 15px -5px rgba(0, 0, 0, 0.1), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        },
      }}
    >
      {children}
    </Center>
  </Center>
);

const ProcessContainer = ({
  bgColor,
  borderColor,
  revolvingBorder,
  children,
}: PropsWithChildren<{
  bgColor: string;
  borderColor: string;
  revolvingBorder?: SerializedStyles;
}>) => (
  <Center sx={{ ...revolvingBorder }}>
    <Center
      p="xl"
      pos="relative"
      bg={bgColor}
      w={NODE_HEIGHT}
      h={NODE_HEIGHT}
      sx={{
        border: revolvingBorder ? `none` : `2px solid ${borderColor}`,
        borderRadius: '12px',
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        ':hover': {
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.25), 0px 10px 15px -5px rgba(0, 0, 0, 0.1), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        },
      }}
    >
      {children}
    </Center>
  </Center>
);

const NodeActionIcon = ({ onClickAction, icon }: NodeActionIconProps) => (
  <ActionIcon onClick={onClickAction}>
    <Box p="sm" bg="white.0" sx={{ borderRadius: '50%' }}>
      {icon}
    </Box>
  </ActionIcon>
);

export const ProcessView = ({ id, data }: NodeProps<NodeData>) => {
  const [isHovering, setIsHovering] = useState(false);
  const { workflowId, onNodeDelete } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: debugRunStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useFormModal(id, operator, data.configuration);

  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    debugRunStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Process,
  );

  const handleEditClick = () => {
    openFormModal();
  };

  const handleDelete = () => {
    onNodeDelete(id);
  };

  return (
    <Vertical
      align="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132} title={operator?.name}>
        {operator?.name}
      </Text>
      <Box onClick={handleEditClick}>
        <ProcessContainer
          bgColor={bgColor}
          borderColor={borderColor}
          revolvingBorder={revolvingBorder}
        >
          {operator ? getOperatorIcon(operator, 52) : null}
          <InputHandle io={operator?.inputs || []} />
          <OutputHandle io={operator?.outputs || []} />
        </ProcessContainer>
      </Box>
      {isHovering && (
        <Horizontal>
          <NodeActionIcon
            onClickAction={handleEditClick}
            icon={<IconEdit width={16} height={16} />}
          />
          <NodeActionIcon
            onClickAction={handleDelete}
            icon={<IconDelete width={20} height={20} />}
          />
        </Horizontal>
      )}
    </Vertical>
  );
};

// Builder Container
export const SourceOperator = ({ id, data }: NodeProps<NodeData>) => {
  const [isHovering, setIsHovering] = useState(false);
  const { workflowId, onNodeDelete } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: debugRunStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useFormModal(id, operator, data.configuration);

  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    debugRunStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Source,
  );

  const handleEditClick = () => {
    openFormModal();
  };

  const handleDelete = () => {
    onNodeDelete(id);
  };

  return (
    <Vertical
      align="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Text align="center" variant="subTitle04" color="gray.6" maw={132}>
        {operator?.name}
      </Text>
      <Box onClick={handleEditClick}>
        <NodeContainer
          bgColor={bgColor}
          borderColor={borderColor}
          revolvingBorder={revolvingBorder}
        >
          {operator ? getOperatorIcon(operator, 52) : null}
          <OutputHandle io={operator?.outputs || []} />
        </NodeContainer>
      </Box>
      {isHovering && (
        <Horizontal>
          <NodeActionIcon
            onClickAction={handleEditClick}
            icon={<IconEdit width={16} height={16} />}
          />
          <NodeActionIcon
            onClickAction={handleDelete}
            icon={<IconDelete width={20} height={20} />}
          />
        </Horizontal>
      )}
    </Vertical>
  );
};

export const DestinationOperator = ({ id, data }: NodeProps<NodeData>) => {
  const [isHovering, setIsHovering] = useState(false);
  const { workflowId, onNodeDelete } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: debugRunStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useFormModal(id, operator, data.configuration);

  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    debugRunStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Sink,
  );

  const handleEditClick = () => {
    openFormModal();
  };

  const handleDelete = () => {
    onNodeDelete(id);
  };

  return (
    <Vertical
      align="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Text variant="subTitle04" align="center" color="gray.6" truncate maw={132}>
        {operator?.name}
      </Text>
      <NodeContainer bgColor={bgColor} borderColor={borderColor} revolvingBorder={revolvingBorder}>
        <InputHandle io={operator?.inputs || []} />
        {operator ? getOperatorIcon(operator, 52) : null}
      </NodeContainer>
      {isHovering && (
        <Horizontal>
          <NodeActionIcon
            onClickAction={handleEditClick}
            icon={<IconEdit width={16} height={16} />}
          />
          <NodeActionIcon
            onClickAction={handleDelete}
            icon={<IconDelete width={20} height={20} />}
          />
        </Horizontal>
      )}
    </Vertical>
  );
};

export const ProcessViewViewer = ({ id, data, renderNodeActions }: NodeViewerProps) => {
  const { workflowId, runId } = useWorkflowRunContext();
  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useReadOnlyForm(id, workflowId, operator, data.configuration);
  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    runStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Process,
  );

  const handleIconClick = () => {
    if (!renderNodeActions) {
      openFormModal();
    }
  };

  return (
    <Vertical
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
        },
      }}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132} title={operator?.name}>
        {operator?.name}
      </Text>

      <ProcessContainer
        bgColor={bgColor}
        borderColor={borderColor}
        revolvingBorder={revolvingBorder}
      >
        <InputHandle io={operator?.inputs || []} />
        {operator ? getOperatorIcon(operator, 52) : null}
        <OutputHandle io={operator?.outputs || []} />
      </ProcessContainer>
      {!renderNodeActions && (
        <Flex justify="center" className="action-icon" sx={{ visibility: 'hidden' }}>
          <NodeActionIcon
            onClickAction={handleIconClick}
            icon={
              <Center h={24} w={24}>
                <IconEye />
              </Center>
            }
          />
        </Flex>
      )}
    </Vertical>
  );
};

export const SourceOperatorViewer = ({ id, data, renderNodeActions }: NodeViewerProps) => {
  const { workflowId, runId } = useWorkflowRunContext();
  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useReadOnlyForm(id, workflowId, operator, data.configuration);
  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    runStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Source,
  );

  const handleIconClick = () => {
    if (!renderNodeActions) {
      openFormModal();
    }
  };

  return (
    <Vertical
      justify="center"
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
        },
      }}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132}>
        {operator?.name}
      </Text>
      <NodeContainer bgColor={bgColor} borderColor={borderColor} revolvingBorder={revolvingBorder}>
        {operator ? getOperatorIcon(operator, 52) : null}
        <OutputHandle io={operator?.outputs || []} />
      </NodeContainer>
      {!renderNodeActions && (
        <Flex justify="center" className="action-icon" sx={{ visibility: 'hidden' }}>
          <NodeActionIcon
            onClickAction={handleIconClick}
            icon={
              <Center h={24} w={24}>
                <IconEye />
              </Center>
            }
          />
        </Flex>
      )}
    </Vertical>
  );
};

export const DestinationOperatorViewer = ({ id, data, renderNodeActions }: NodeViewerProps) => {
  const { workflowId, runId } = useWorkflowRunContext();
  const { data: operator } = useGetOperatorDetailsQuery(data.id);
  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { open: openFormModal } = useReadOnlyForm(id, workflowId, operator, data.configuration);
  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    runStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    OperatorCategory.Sink,
  );

  const handleIconClick = () => {
    if (!renderNodeActions) {
      openFormModal();
    }
  };

  return (
    <Vertical
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
        },
      }}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132}>
        {operator?.name}
      </Text>
      <NodeContainer bgColor={bgColor} borderColor={borderColor} revolvingBorder={revolvingBorder}>
        <InputHandle io={operator?.inputs || []} />
        {operator ? getOperatorIcon(operator, 52) : null}
      </NodeContainer>
      {!renderNodeActions && (
        <Flex justify="center" className="action-icon" sx={{ visibility: 'hidden' }}>
          <NodeActionIcon
            onClickAction={handleIconClick}
            icon={
              <Center h={24} w={24}>
                <IconEye />
              </Center>
            }
          />
        </Flex>
      )}
    </Vertical>
  );
};

export const nodeTypes = {
  [OperatorCategory.Source]: SourceOperator,
  [OperatorCategory.Sink]: DestinationOperator,
  [OperatorCategory.Process]: ProcessView,
};

export const getNodeViewerTypes = (renderNodeActions: boolean) => ({
  [OperatorCategory.Source]: (props: NodeProps<NodeData>) => (
    <SourceOperatorViewer {...props} renderNodeActions={renderNodeActions} />
  ),
  [OperatorCategory.Sink]: (props: NodeProps<NodeData>) => (
    <DestinationOperatorViewer {...props} renderNodeActions={renderNodeActions} />
  ),
  [OperatorCategory.Process]: (props: NodeProps<NodeData>) => (
    <ProcessViewViewer {...props} renderNodeActions={renderNodeActions} />
  ),
});
