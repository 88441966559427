import { IconPlus, IconSearch } from '@tabler/icons-react';
import {
  Button,
  Horizontal,
  Text,
  TextInput,
  Vertical,
  useDebouncedValue,
  useInputState,
} from '../../../../../design-system/v2';
import { AppProjects } from './AppProjects';
import { useAppProjectStyle } from './AppProjects.style';
import { useNewAppProjectModal } from './CreateNewProjectModal';

interface AppProjectsWrapperProps {
  appId: string;
}

export const AppProjectsWrapper = ({ appId }: AppProjectsWrapperProps): JSX.Element => {
  const { classes } = useAppProjectStyle();

  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 200);

  const { openNewAppProjectModal } = useNewAppProjectModal(appId);

  return (
    <Vertical className={classes.mainPanel}>
      <Text variant="subTitle01">Projects</Text>
      <Horizontal position="apart" mb={32}>
        <TextInput
          value={searchFilter}
          onChange={setSearchFilter}
          ariaLabel="Search projects"
          placeholder="Search..."
          icon={<IconSearch size={18} />}
        />
        <Button
          variant="outline"
          color="blue"
          leftIcon={<IconPlus />}
          onClick={openNewAppProjectModal}
        >
          New Project
        </Button>
      </Horizontal>
      <AppProjects appId={appId} searchFilter={debouncedSearchFilter.toLocaleLowerCase()} />
    </Vertical>
  );
};
