import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import {
  Box,
  Flex,
  Text,
  useFocusWithin,
  useInputState,
  Vertical,
} from '../../../design-system/v2';
import { useMentionInputStyles } from './FeatureColumnsMentionsInput.style';

const SUGGESTION_WIDTH = 250;

interface FeatureColumnsMentionsInputProps {
  value: string;
  onChange: (value: string) => void;
  suggestions: SuggestionDataItem[];
  disabled?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
}

export const FeatureColumnsMentionsInput = ({
  value,
  onChange,
  suggestions,
  disabled,
  isLoading,
  readOnly,
  label,
  description,
  placeholder,
  required,
}: FeatureColumnsMentionsInputProps): JSX.Element => {
  const { ref, focused } = useFocusWithin();
  const styles = useMentionInputStyles(focused);

  const [currentQueryMarkup, setCurrentQueryMarkup] = useInputState(value);

  return (
    <Vertical>
      <Box>
        <Flex>
          <Text variant="subTitle02">{label}</Text>
          {required && <Text color="red">&nbsp;*</Text>}
        </Flex>
        <Text variant="small01" color="gray.6">
          {description}
        </Text>
      </Box>
      <MentionsInput
        inputRef={ref}
        aria-label="Input text"
        placeholder={placeholder}
        allowSpaceInQuery
        forceSuggestionsAboveCursor
        disabled={disabled}
        readOnly={readOnly}
        value={currentQueryMarkup}
        onChange={(_event, newValue, newPlainTextValue) => {
          setCurrentQueryMarkup(newValue);
          onChange(newPlainTextValue);
        }}
        style={styles.input}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd
          data={suggestions}
          renderSuggestion={(entry, _search, _highlightedDisplay, _index, focused) => (
            <Box
              p="sm"
              w={SUGGESTION_WIDTH}
              sx={theme => ({
                border: `1px solid ${theme.colors.gray[1]}`,
                backgroundColor: focused ? theme.colors.blue[0] : theme.white,
              })}
            >
              <Text variant="small01" truncate title={entry.display}>
                {entry.display}
              </Text>
            </Box>
          )}
          style={styles.mention}
        />
      </MentionsInput>
    </Vertical>
  );
};
