import { CellProps } from 'react-table';
import { Text, TextVariants } from '../../../design-system/v2';
import { ExperimentWithTags } from '../../../generated/api';
import { formatDateLong, formatDateTime } from '../../../lib/ui';

interface ExperimentsDateCellProps extends CellProps<ExperimentWithTags> {
  textVariant: TextVariants;
  dateVariant: string;
}

export const ExperimentsDateCell: React.FC<ExperimentsDateCellProps> = ({
  value: createDate,
  textVariant,
  dateVariant,
}) => {
  const date = dateVariant === 'short' ? formatDateLong(createDate) : formatDateTime(createDate);
  return (
    <Text variant={textVariant} color="dark.4">
      {createDate ? date : ''}
    </Text>
  );
};
