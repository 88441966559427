import {
  BoxPlotIllustration,
  BubbleChartIllustration,
  ClassDistributionIllustrationV2,
  DataProfileIllustration,
  LineChartIllustration,
  TableIllustration,
} from '../../../../../design-system';
import { Center } from '../../../../../design-system/v2';
import {
  VisualizationModel,
  VisualizationType,
} from '../../../../../design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { UnionToIntersection } from '../../../../../types/util';
import { ChartsDetails } from '../analysers/ChartsList';

type ChartsData = Omit<ChartsDetails, 'id' | 'metadata'> & VisualizationModel;

export const VIZ_NAMES = {
  [VisualizationType.DATASET_CLASS_DISTRIBUTION]: 'Class Distribution',
  [VisualizationType.DATASET_CLASS_QUALITY_SCORE]: 'Overall Quality Score',
  [VisualizationType.DATASET_TOP_K_FEATURES]: 'Top K Features',
  [VisualizationType.DATASET_BASELINE_MODEL_STATS]: 'Baseline Model Stats',
  [VisualizationType.DATASET_OVERLAPPING_CLASS_METRICS]: 'Overlapping Class Metrics',

  [VisualizationType.DATASET_DATA_PROFILE_INSIGHTS]: 'Overview',
  [VisualizationType.DATASET_DATA_PROFILE_SUMMARY]: 'Overview',
  [VisualizationType.DATASET_DATA_PROFILE_CORRELATION]: 'Correlations',
  [VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE]: 'Missing Values',
  [VisualizationType.DATASET_DATA_PROFILE_VARIABLE]: 'Variables',

  [VisualizationType.DATASET_EMBEDDINGS]: 'Embeddings',

  [VisualizationType.EVALUATION_ROC_PR_CURVE]: 'ROC & PR Curve',
  [VisualizationType.EVALUATION_CLASS_LEVEL_METRICS]: 'Class Level Metrics',
  [VisualizationType.EVALUATION_CLASS_DISTRIBUTION]: 'Ground Truth vs. Model Inference',
  [VisualizationType.EVALUATION_CUSTOM_METRICS]: 'Custom Metrics',
  [VisualizationType.EVALUATION_CONFUSION_MATRIX]: 'Confusion Matrix',
  [VisualizationType.EVALUATION_MODEL_DISAGREEMENTS]: 'Model Disagreements',
};

export const getIllustration = (chartType: string) => {
  switch (chartType) {
    case 'pie':
      return <ClassDistributionIllustrationV2 />;
    case 'packed_bubble':
      return <BubbleChartIllustration />;
    case 'table':
      return <TableIllustration />;
    case 'line':
      return <LineChartIllustration />;
    case 'box_plot':
      return <BoxPlotIllustration />;
    default:
      return <DataProfileIllustration />;
  }
};

const SEPARATOR = '.';
export const getVizIdFromVizModel = ({
  visualizationType: vizType,
  visualizationParameters: vizParams,
}: VisualizationModel): string => {
  const {
    datasetId = '',
    analysisId = '',
    segmentType = '',
    title = '',
    experimentId = '',
    metricKey = '',
    visualizationId = '',
    variableName = '',
    chartType = '',
    evaluationId = '',
    subsetId = '',
    modelId = '',
    dataQualityScore = '',
  } = vizParams as UnionToIntersection<VisualizationModel['visualizationParameters']>;

  return (
    [
      vizType,
      datasetId,
      analysisId,
      segmentType,
      title,
      experimentId,
      metricKey,
      visualizationId,
      chartType,
      variableName,
      evaluationId,
      subsetId,
      modelId,
      dataQualityScore,
    ]
      .filter(Boolean)
      .join(SEPARATOR)
      // Remove all types of quotes ('', "", ``) from id
      .replace(/['"`]+/g, '')
  );
};

export const getChartsData = ({
  visualizationType,
  title,
  subTitle,
  chart,
  bgColor = 'gray.0',
  visualizationParameters,
}: ChartsData): ChartsDetails => ({
  id: getVizIdFromVizModel({
    visualizationType,
    visualizationParameters,
  } as VisualizationModel),
  title,
  subTitle,
  chart: <Center pt="md">{chart}</Center>,
  bgColor,
  metadata: {
    visualizationType,
    visualizationParameters,
  } as VisualizationModel,
});
