import {
  useEvaluationColor,
  useEvaluationComparisonContext,
} from '../../../../contexts/evaluation-comparison/EvaluationComparison';
import { Box, Center, Loader } from '../../../../design-system/v2';
import { SectionWrapper } from '../../common/SectionWrapper';
import { ClassDistributionContainer } from './ClassDistribution.container';

export const SECTION_TITLE = 'Ground truth vs. model inference';
const SECTION_DESCRIPTION =
  'Compare the frequency of models predictions against frequency of the actual labels';

const CHART_HEIGHT = 496;

export const ClassDistributionSection = () => {
  const { isLoading, evaluationIds } = useEvaluationComparisonContext();
  const { getColor } = useEvaluationColor();

  const chartColors = evaluationIds.map(getColor);

  const content = isLoading ? (
    <Center h="100%">
      <Loader />
    </Center>
  ) : (
    <ClassDistributionContainer evaluationIds={evaluationIds} chartColors={chartColors} />
  );

  return (
    <SectionWrapper title={SECTION_TITLE} description={SECTION_DESCRIPTION}>
      <Box h={CHART_HEIGHT}>{content}</Box>
    </SectionWrapper>
  );
};
