import { IconArrowLeft } from '@tabler/icons-react';
import { Box, Button, Horizontal } from '../../../design-system/v2';

interface CreateConnectorModalActionsProps {
  onCreate: () => void;
  onClose: () => void;
  isCreating: boolean;
  onClickBack?: () => void;
}

export const CreateConnectorModalActions = ({
  onCreate,
  onClose,
  isCreating,
  onClickBack,
}: CreateConnectorModalActionsProps): JSX.Element => {
  const showBackButton = !!onClickBack;

  return (
    <Horizontal position="apart">
      {showBackButton ? (
        <Button onClick={onClickBack} leftIcon={<IconArrowLeft />}>
          Back
        </Button>
      ) : (
        <Box />
      )}
      <Horizontal>
        <Button onClick={onClose} variant="light" color="gray.7" bg="gray.1">
          Cancel
        </Button>
        <Button onClick={onCreate} loading={isCreating} variant="primary" color="blue.6">
          Create
        </Button>
      </Horizontal>
    </Horizontal>
  );
};
