import styled from 'styled-components';
import {
  color,
  ColorProps,
  compose,
  LayoutProps,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import { Box } from '..';

const styleProps = compose(color, typography);

export type TextVariant =
  | 'pageHeading1'
  | 'pageHeading2'
  | 'subHeading1'
  | 'subHeading2'
  | 'subHeading3'
  | 'subHeading4'
  | 'subHeading5'
  | 'big1'
  | 'standard1'
  | 'standard2'
  | 'small1'
  | 'small2'
  | 'small3'
  | 'label1'
  | 'extraSmall';

interface VariantProp {
  variant?: TextVariant;
}

type TextProps = VariantProp & TypographyProps & LayoutProps & SpaceProps & ColorProps;

// rem values are derived from https://type-scale.com/ (Scale: 1.125 - Major Second)
const Text = styled(Box)<TextProps>(
  {
    lineHeight: 1.5,
  },
  variant({
    variants: {
      pageHeading1: {
        fontSize: '1.5625rem',
        fontWeight: 'bold',
        lineHeight: '32px',
      },
      pageHeading2: {
        fontSize: '1.375rem',
        fontWeight: 'bold',
        lineHeight: '32px',
      },
      subHeading1: {
        fontSize: '1.25rem',
        fontWeight: 'semiBold',
        lineHeight: '28px',
      },
      subHeading2: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        lineHeight: '24px',
      },
      subHeading3: {
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '24px',
      },
      subHeading4: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
      subHeading5: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        lineHeight: '16px',
      },
      big1: {
        fontSize: '1rem',
        fontWeight: 'normal',
        lineHeight: '24px',
      },
      standard1: {
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '20px',
      },
      standard2: {
        fontSize: '0.875rem',
        fontWeight: 'semiBold',
        lineHeight: '20px',
      },
      small1: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: '16px',
      },
      small2: {
        fontSize: '0.75rem',
        fontWeight: 'semiBold',
        lineHeight: '16px',
      },
      small3: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        lineHeight: '16px',
      },
      label1: {
        fontSize: '0.75rem',
        fontWeight: 'semiBold',
        lineHeight: '16px',
      },
      extraSmall: {
        fontSize: '0.6875rem',
        fontWeight: 'semiBold',
        lineHeight: '16px',
      },
    },
  }),
  styleProps,
);

Text.defaultProps = {
  variant: 'standard1',
};

export default Text;
