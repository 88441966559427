import { dashboardEvents, datasetEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { Center, LinkButton, Vertical } from '../../../../../design-system/v2';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { EmptyState } from '../../../../common/EmptyState';

export const EmptyDatasetPreview = (): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const { getDatasetRegistrationRoute, getDatasetsRoute } = useAbsoluteRoutes();

  const handleOnClickDatasetRegister = () => {
    sendAnalytics(
      datasetEvents.register.addClicked({
        workspaceId,
        sourcePage: 'DASHBOARD_DATASET_EMPTY_PREVIEW',
      }),
    );
  };

  const handleOnClickSampleDataset = () => {
    sendAnalytics(
      dashboardEvents.datasetSampleClicked({
        workspaceId,
      }),
    );
  };

  return (
    <Center h="100%">
      <EmptyState
        compact
        iconName="dataset"
        title="Organize and analyze your datasets. Get exploratory insights"
        subtitle="Start by looking at a sample analysis or add your own dataset"
        button={
          <Vertical spacing="xs">
            <LinkButton
              variant="primary"
              shape="rounded"
              to={getDatasetsRoute()}
              onClick={handleOnClickSampleDataset}
            >
              View sample analysis
            </LinkButton>
            <LinkButton
              variant="subtle"
              shape="rounded"
              size="xs"
              to={getDatasetRegistrationRoute()}
              onClick={handleOnClickDatasetRegister}
            >
              Add your dataset
            </LinkButton>
          </Vertical>
        }
      />
    </Center>
  );
};
