import noop from 'lodash/noop';
import { FormEvent, useState } from 'react';
import { Info } from '../../design-system';
import { Button, Grid, Horizontal, MultiSelect, Space, Text } from '../../design-system/v2';
import { validateEmail } from '../../lib/util';

interface UserInvitationProps {
  isSending?: boolean;
  onSend: (emails: string[]) => void;
}

const validateEmails = (emails: string[]) => emails.every(email => validateEmail(email));

export const UserInvitation = ({ isSending = false, onSend }: UserInvitationProps) => {
  const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendInviteClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmails(emailsToInvite)) {
      setErrorMessage('1 or more emails seem invalid');
      return;
    }
    setErrorMessage('');
    onSend(emailsToInvite);
  };

  /** This function is needed to take care of clear scenarios */
  const handleSelectChange = (emails: string[]) => {
    const trimmedEmails = emails.map(email => email.trim());
    setEmailsToInvite(trimmedEmails);
    if (!validateEmails(trimmedEmails)) {
      setErrorMessage('1 or more emails seem invalid');
    } else {
      setErrorMessage('');
    }
  };

  const addEmailToInvite = (email: string) => {
    setEmailsToInvite([...emailsToInvite, email.trim()]);
  };

  return (
    <form onSubmit={handleSendInviteClick}>
      <Grid columns={12} align="center">
        <Grid.Col span={12}>
          {/* 
          Replace with TagInput component once released by mantine in v6.1
          https://github.com/mantinedev/mantine/pull/3674
          */}
          <MultiSelect
            data-focus
            label="Enter one or more email addresses"
            data={[]}
            searchable
            creatable
            clearable
            clearSearchOnBlur
            error={errorMessage}
            getCreateLabel={query => `Invite ${query}`}
            onChange={handleSelectChange}
            onCreate={query => {
              addEmailToInvite(query);
              return { label: query, value: query };
            }}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Horizontal spacing="xs">
            <Info />
            <Text variant="bodyShort03" color="dark.2">
              Users will be invited with &ldquo;Member&rdquo; role.
            </Text>
          </Horizontal>
        </Grid.Col>
      </Grid>

      <Space h={24} />
      <Horizontal position="right">
        <Button
          variant="primary"
          shape="rounded"
          type="submit"
          loading={isSending}
          disabled={!emailsToInvite.length}
          onClick={noop}
        >
          Send invite(s)
        </Button>
      </Horizontal>
    </form>
  );
};
