import { createStyles, MantineTheme } from '@mantine/styles';

export default createStyles((theme: MantineTheme) => ({
  actionBar: {},
  dropdownContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    minWidth: '700px',
  },
  verticalDropdown: {
    minWidth: '300px',
  },
}));
