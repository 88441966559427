import { IconWand } from '@tabler/icons-react';
import { debounce, first } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Horizontal,
  Overlay,
  Panel,
  PanelGroup,
  PanelResizeHandle,
  Text,
  TextArea,
  useMarkovTheme,
  Vertical,
} from '../../../design-system/v2';
import { AppBuilder } from '../../../generated/api';
import { APP_BUILDER_DETAILS_HEADER_HEIGHT } from './AppBuilderDetailsHeader';
import { AppBuilderViews } from './AppBuilderViewsControl';
import { CustomAppChatPreview } from './CustomAppChatPreview';
import { useGeneratePromptModal } from './generate-prompt/useGeneratePromptModal';
import { InputInfoTooltip } from './InputInfoTooltip';

const PROMPT_PLACEHOLDER = `<Specify your Persona.>
Example:
You are a helpful instructor.
---
<Provide more instructions>
Example:
You will be given the title of the video as Title and Outline of the Video as Outline. Use outlines to compose the transcript.
DO NOT explain the scene just give the script. Do not use any common AI-generated words.
[The video opens with a friendly instructor standing in a well-lit, cozy classroom environment.]
Maintain a conversational yet professional tone at or below a 10th-grade reading level.
`;

const EXAMPLE_PLACEHOLDER = `<Provide an example query>
Example: 
Generate a script using the following title and outline
Title: Laws of motion
Outline: 
1. Give an introduction about Newton & his contributions to science. 
2. Highlight Three Laws of Motion.`;

const PANEL_DEFAULT_WIDTH = 50;
const PANEL_MIN_WIDTH = 30;

interface AppBuilderDetailsProps {
  prompt: string;
  example: string;
  onPromptChange: (prompt: string) => void;
  onExampleChange: (example: string) => void;
  appDetails: AppBuilder;
  onFormValuesChange: (prompt: string, example: string) => void;
  activeView: AppBuilderViews;
}

export const AppBuilderDetailsForm = ({
  activeView,
  appDetails,
  prompt,
  example,
  onPromptChange,
  onExampleChange,
  onFormValuesChange,
}: AppBuilderDetailsProps): JSX.Element => {
  const theme = useMarkovTheme();

  const { appId } = appDetails ?? {};

  const { open: openGeneratePromptModal } = useGeneratePromptModal();

  const debouncedOnFormValuesChange = useCallback(debounce(onFormValuesChange, 500), []);

  const initialValuesSyncDone = useRef<boolean>(false);

  useEffect(() => {
    if (appDetails && !initialValuesSyncDone.current) {
      initialValuesSyncDone.current = true;
      const { appProperties } = appDetails;
      onPromptChange(appProperties.appPrompt);
      onExampleChange(first(appProperties.examples) ?? '');
    }
  }, [appDetails]);

  const handlePromptChange = (prompt: string) => {
    onPromptChange(prompt);
    debouncedOnFormValuesChange(prompt, example);
  };

  const handleExampleChange = (example: string) => {
    onExampleChange(example);
    debouncedOnFormValuesChange(prompt, example);
  };

  const handleGeneratedPromptSelect = (response: string) => {
    handlePromptChange(response);
  };

  const handleGeneratePromptClick = () =>
    openGeneratePromptModal(appId, handleGeneratedPromptSelect);

  const OverlayComponent = <Overlay sx={{ backgroundColor: 'rgba(200,200,200,0.1)' }} />;

  return (
    <Box h={`calc(100% - ${APP_BUILDER_DETAILS_HEADER_HEIGHT}px)`} bg="white.0">
      <Container h="100%" size="xl">
        <PanelGroup direction="horizontal">
          <Panel
            defaultSize={PANEL_DEFAULT_WIDTH}
            minSize={PANEL_MIN_WIDTH}
            style={{ position: 'relative' }}
          >
            {activeView === AppBuilderViews.TEST && OverlayComponent}
            <Vertical p="md" spacing={32} h="100%" justify="stretch">
              <Text variant="heading03">Setup your app</Text>

              {/* TODO: Add required validation */}
              <TextArea
                minRows={10}
                maxRows={15}
                ariaLabel="Provide instruction prompt"
                labelProps={{ style: { width: '100%' } }}
                label={
                  <Horizontal position="apart" pb="sm">
                    <Horizontal align="center">
                      <Text variant="subTitle02">Provide instruction prompt *</Text>
                      <InputInfoTooltip>{PROMPT_PLACEHOLDER}</InputInfoTooltip>
                    </Horizontal>
                    <Button
                      td="underline"
                      onClick={handleGeneratePromptClick}
                      leftIcon={<IconWand />}
                      bg="white"
                      sx={{
                        ':disabled': { background: 'transparent' },
                      }}
                    >
                      Generate a prompt
                    </Button>
                  </Horizontal>
                }
                placeholder="Type instruction prompt or generate a prompt by clicking the Generate a prompt button "
                value={prompt}
                onChange={handlePromptChange}
              />
              <Vertical spacing={0}>
                <TextArea
                  label={
                    <Horizontal align="center" pb="sm">
                      <Text variant="subTitle02">Add a placeholder help text</Text>
                      <InputInfoTooltip>{EXAMPLE_PLACEHOLDER}</InputInfoTooltip>
                    </Horizontal>
                  }
                  ariaLabel="Add a placeholder help text"
                  placeholder="Add a placeholder help text"
                  value={example}
                  onChange={handleExampleChange}
                  minRows={7}
                  maxRows={15}
                />
              </Vertical>
            </Vertical>
          </Panel>
          <PanelResizeHandle style={{ width: 1, background: theme.colors.gray[2] }} />
          <Panel
            defaultSize={PANEL_DEFAULT_WIDTH}
            minSize={PANEL_MIN_WIDTH}
            style={{ position: 'relative' }}
          >
            {activeView === AppBuilderViews.BUILD && OverlayComponent}
            <CustomAppChatPreview appId={appId} />
          </Panel>
        </PanelGroup>
      </Container>
    </Box>
  );
};
