import { IconInfoCircle, IconPlaylistAdd } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { FullPageLoader } from '../../../../../../../design-system';
import {
  Alert,
  Box,
  Button,
  Flex,
  Text,
  TextArea,
  TextInput,
  Vertical,
  closeAllModals,
  openModal,
  useInputState,
} from '../../../../../../../design-system/v2';
import { AddStyleGuideRuleRequest } from '../../../../../../../generated/api';
import { HTTPError } from '../../../../../../../lib/api/api';
import { useAddEditStyleGuideCustomRuleMutation } from '../../../../../../../queries/document-ai/copyEdit';
import { ReplaceByText } from './ReplaceByText';

interface AddCustomRuleProps {
  ruleSetId: string;
  ruleId?: string;
  data?: AddStyleGuideRuleRequest;
}

export const AddEditCustomRuleModal = ({ ruleSetId, ruleId, data }: AddCustomRuleProps) => {
  const [originalText, setOriginalText] = useInputState(data?.rule?.match ?? '');
  const [replaceByText, setReplaceByText] = useInputState(data?.rule?.replaceBy ?? ['']);
  const [description, setDescription] = useInputState(data?.description ?? '');

  const { isLoading, error, mutate } = useAddEditStyleGuideCustomRuleMutation(ruleSetId, ruleId);

  const handleAddRule = () => {
    const addRuleReq: AddStyleGuideRuleRequest = {
      description,
      rule: {
        replaceBy: replaceByText.map(text => text.trim()).filter(text => Boolean(text)),
        match: originalText,
      },
    };
    mutate(addRuleReq, {
      onSuccess: () => {
        closeAllModals();
      },
    });
  };

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleAddMoreReplaceByOptions = () => {
    setReplaceByText([...replaceByText, '']);
  };

  const isEditMode = !!ruleId;
  const showError = !isLoading && Boolean(error);

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}
      <Vertical spacing="xl">
        {showError ? (
          <Alert color="red" icon={<IconInfoCircle size={14} />} title="Unable to add a new rule">
            {(error as AxiosError<HTTPError>)?.response?.data.detail ?? 'Please try again'}
          </Alert>
        ) : (
          <Alert icon={<IconInfoCircle size={20} />}>
            Rules let you replace or remove specific text in your documents.
          </Alert>
        )}
        <TextInput
          required
          ariaLabel="Original Text"
          label="Original Text"
          placeholder="Word or phrase to be replaced"
          value={originalText}
          onChange={setOriginalText}
        />
        <Box>
          <Vertical spacing="xs" mb="xs">
            <Text variant="subTitle04" color="gray.9">
              Replace By Text
            </Text>
            {replaceByText.map((_, index) => (
              <ReplaceByText
                key={`replace-text-${index}`}
                index={index}
                replaceText={replaceByText}
                onChangeReplaceText={setReplaceByText}
              />
            ))}
          </Vertical>

          <Button variant="subtle" compact onClick={handleAddMoreReplaceByOptions}>
            + Add more
          </Button>
        </Box>
        <TextArea
          ariaLabel="Rule Description"
          label="Rule Description"
          placeholder="Rule description"
          minRows={2}
          maxRows={4}
          value={description}
          onChange={setDescription}
        />
        <Flex justify="right" gap="sm">
          <Button variant="outline" color="gray.6" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!originalText} onClick={handleAddRule}>
            {isEditMode ? 'Edit Rule' : 'Add Rule'}
          </Button>
        </Flex>
      </Vertical>
    </>
  );
};

export const AddCustomRule = ({ ruleSetId }: { ruleSetId: string }) => {
  const handleAddNewRule = () => {
    openModal({
      size: 'xl',
      title: <Text variant="heading03">Add Rule</Text>,
      children: <AddEditCustomRuleModal ruleSetId={ruleSetId} />,
    });
  };

  return (
    <Button onClick={handleAddNewRule} leftIcon={<IconPlaylistAdd />} variant="primary">
      Add Rule
    </Button>
  );
};
