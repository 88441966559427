import { Code, CopyButton } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { useState } from 'react';
import {
  Button,
  Horizontal,
  ScrollArea,
  Text,
  Vertical,
  notifications,
} from '../../../../design-system/v2';
import { TemplateListResponse } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { useExportAsJsonMutation } from '../../../../queries/workflows/operators';

export interface ExportWorkflowInputModalProps {
  workflowId: string;
}
const HEADING = 'Exporting the workflow';
const SUBHEADING = `You can export the workflow to create an template and save this json in SF.`;

const ExportWorkflowAsJsonModal = ({ workflowId }: ExportWorkflowInputModalProps): JSX.Element => {
  const { mutateAsync: exportWorkflowAsJson, isLoading: isExporting } = useExportAsJsonMutation();
  const displayState = 'Export';
  const displayName = 'workflow';
  const [data, setData] = useState<TemplateListResponse>();

  const handleConfirmExporting = async () => {
    try {
      const result = await exportWorkflowAsJson({ workflowIds: [workflowId] });
      setData(result.data);
      notifications.success(`${displayState}d selected ${displayName}`);
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };
  const handleCancel = () => {
    closeAllModals();
  };
  const jsonData = JSON.stringify(data, null, 2);
  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>

      {data ? (
        <ScrollArea h="50vh">
          <Code block sx={{ overflowY: 'auto' }}>
            {jsonData}
          </Code>
        </ScrollArea>
      ) : (
        <Text>Click export to generate the JSON for the workflow.</Text>
      )}

      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="outline">
          Cancel
        </Button>
        {data ? (
          <CopyButton value={jsonData}>
            {({ copied, copy }) => (
              <Button variant="primary" color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied' : 'Copy JSON'}
              </Button>
            )}
          </CopyButton>
        ) : (
          <Button variant="primary" onClick={handleConfirmExporting} disabled={isExporting}>
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        )}
      </Horizontal>
    </Vertical>
  );
};

export const useWorkflowAsJsonExport = (workflowId: string) => {
  const openExportWorkflowAsJsonModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      size: 'xl',
      children: <ExportWorkflowAsJsonModal workflowId={workflowId} />,
    });

  return { openExportWorkflowAsJsonModal };
};
