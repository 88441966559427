import { ThreadsItemProvider } from '../../../contexts/chat-with-data/ThreadsItemContext';
import { QuestionTypeProvider } from '../../../contexts/data-analytics/QuestionTypeContext';
import { Box } from '../../../design-system/v2';
import { QuestionTypes } from '../../../generated/api';
import { CustomAppChat } from '../../app-store/my-apps/CustomAppChat';

export interface CustomAppChatPreviewProps {
  appId: string;
}

export const CustomAppChatPreview = ({ appId }: CustomAppChatPreviewProps) => (
  <Box h="100%" pos="relative">
    <QuestionTypeProvider questionType={QuestionTypes.Text}>
      {/* Initial value of undefined is passed to start a new thread every time the app builder interface is loaded */}
      <ThreadsItemProvider thread={undefined}>
        <CustomAppChat appId={appId} />
      </ThreadsItemProvider>
    </QuestionTypeProvider>
  </Box>
);
