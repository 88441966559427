import capitalize from 'lodash/capitalize';
import keyBy from 'lodash/keyBy';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Tabs, Text } from '../../design-system/v2';
import { TabComponents, TabComponentsType } from './Settings';
import { useSettingContainerStyles } from './Settings.style';

interface TabListProps {
  selected: TabComponentsType;
  panels: TabComponents[];
}

interface VerticalSettingsTabProps {
  tabComponents: TabComponents[];
}

const TabList = ({ selected, panels }: TabListProps): JSX.Element => {
  const { classes } = useSettingContainerStyles();
  return (
    <Tabs.List className={classes.tabListContainer}>
      {panels.map(({ id, name }) => (
        <Tabs.Tab key={id} value={String(id)} className={classes.tabContainer}>
          <Text
            variant="subTitle02"
            sx={theme => ({
              color:
                selected === id
                  ? theme.colors.blue[8]
                  : theme.colorScheme === 'dark'
                  ? theme.colors.white[0]
                  : theme.colors.dark[5],
              padding: '0 8px',
            })}
          >
            {capitalize(name)}
          </Text>
        </Tabs.Tab>
      ))}
    </Tabs.List>
  );
};

export const VerticalSettingsTab = ({ tabComponents }: VerticalSettingsTabProps): JSX.Element => {
  const { classes } = useSettingContainerStyles();
  const [tabParams, setTabParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabComponentsType>(
    (tabParams.get('tab') as TabComponentsType) ?? TabComponentsType.ABOUT,
  );

  const panels = keyBy(tabComponents, 'id');

  const onTabChange = (id: TabComponentsType) => {
    setTabParams({ tab: id });
    setSelectedTab(id);
  };

  return (
    <Tabs variant="pills" color="white" orientation="vertical" onTabChange={onTabChange}>
      <TabList selected={selectedTab} panels={tabComponents} />
      <Box className={classes.panelPageContainer}>{panels[selectedTab].component}</Box>
    </Tabs>
  );
};
