import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { Vertical } from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import {
  ArtifactStateType,
  ExecutionType,
  Workflow,
  WorkflowStatus,
} from '../../../../generated/api';
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_TABLE_MENU_WIDTH,
} from '../../../datasets-table/v2/cell-renderers/util';
import { TableActionMenu } from '../../../table-action-menu/TableActionMenu';
import { useWorkflowClone } from '../workflow-actions/useCloneWorkflow';
import { useWorkflowAsJsonExport } from '../workflow-actions/useExportJsonTemplate';
import { useWorkflowExport } from '../workflow-actions/useExportWorkflow';
import { useUpdateScheduleStatusModal } from '../workflow-actions/useUpdateScheduleStatusModal';
import { useWorkflowArchiveUnarchive } from '../workflow-actions/useWorkflowArchiveUnarchive';
import { useWorkflowDelete } from '../workflow-actions/useWorkflowDelete';
import { useWorkflowScheduleDelete } from '../workflow-actions/useWorkflowScheduleDelete';

interface WorkflowActionsRendererProps extends ICellRendererParams<Workflow> {
  state: ArtifactStateType;
}
interface WorkflowAction {
  id: string;
  label: string;
  handler: () => void;
}
const DEV_WORKSPACE = 'wsp-542amm25m7';

export const WorkflowActionsRenderer = ({ state, data }: WorkflowActionsRendererProps) => {
  const workflowId = data?.workflowId ?? '';
  const scheduleType = data?.executionType ?? ExecutionType.OneTime;
  const endDate = data?.workflowScheduleDetails?.endDate;

  const { openArchiveUnarchiveModal } = useWorkflowArchiveUnarchive(
    [workflowId],
    state ?? ArtifactStateType.Active,
  );
  const { openExportWorkflowAsJsonModal } = useWorkflowAsJsonExport(workflowId);
  const { openDeleteModal } = useWorkflowDelete([workflowId]);
  const { openCloneModal } = useWorkflowClone(workflowId, data?.name ?? '');
  const { open: openUpdateScheduleStatus } = useUpdateScheduleStatusModal(
    workflowId,
    data?.status ?? WorkflowStatus.Disabled,
  );
  const { openDeleteScheduleModal } = useWorkflowScheduleDelete(workflowId);
  const { openExportModal } = useWorkflowExport(workflowId);
  if (!data) {
    return null;
  }

  const archiveAction = {
    id: 'archive-workflow',
    label: state === ArtifactStateType.Active ? 'Archive' : 'Unarchive',
    handler: openArchiveUnarchiveModal,
  };

  const deleteAction = {
    id: 'delete-workflow',
    label: 'Delete',
    handler: openDeleteModal,
  };

  const cloneAction = {
    id: 'clone-workflow',
    label: 'Clone',
    handler: openCloneModal,
  };
  // Check if the endDate is greater than the current date
  const currentDate = new Date();
  const isEndDateInFuture = endDate ? new Date(endDate) > currentDate : false;

  const updateScheduleStateAction = {
    id: 'update-schedule-state',
    label: data.status === WorkflowStatus.Enabled ? 'Pause schedule' : 'Resume schedule',
    handler: openUpdateScheduleStatus,
  };

  const deleteScheduleAction = {
    id: 'delete-workflow-schedule',
    label: 'Delete schedule',
    handler: openDeleteScheduleModal,
  };
  const exportAsTemplateAction = {
    id: 'export-workflow',
    label: 'Save As Template',
    handler: openExportModal,
  };
  const exportAsJsonTemplateAction = {
    id: 'export-json-workflow',
    label: 'Devs Only Export',
    handler: openExportWorkflowAsJsonModal,
  };

  const { workspaceId } = useAppMetadata();

  const workflowActions: Record<ArtifactStateType, WorkflowAction[]> = {
    [ArtifactStateType.Draft]: [cloneAction, deleteAction],
    [ArtifactStateType.Active]: [
      cloneAction,
      archiveAction,
      exportAsTemplateAction,
      ...(workspaceId === DEV_WORKSPACE ? [exportAsJsonTemplateAction] : []),
      ...(scheduleType === ExecutionType.Scheduled && isEndDateInFuture
        ? [updateScheduleStateAction, deleteScheduleAction]
        : []),
    ],
    [ArtifactStateType.Archived]: [archiveAction],
    [ArtifactStateType.PendingDelete]: [],
  };

  const actions = workflowActions[state];

  return (
    <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
      <TableActionMenu actions={actions} width={DEFAULT_TABLE_MENU_WIDTH} />
    </Vertical>
  );
};
