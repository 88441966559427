import { CellProps } from 'react-table';
import { TextColumnFilter } from '../../../design-system/Table/components/data-table/filters/TextColumnFilter';
import { TextVariants } from '../../../design-system/v2';
import { EvaluationWithTags } from '../../../generated/api';
import { EvaluationsNameCell } from './EvaluationsNameCell';

export const getEvaluationsNameColumn = (width: number, textVariant: TextVariants) => ({
  Header: 'Evaluation Name',
  accessor: 'name',
  id: 'name',
  width: width,
  Filter: () => <TextColumnFilter columnId="name" />,
  Cell: (props: CellProps<EvaluationWithTags>) => (
    <EvaluationsNameCell {...props} textVariant={textVariant} />
  ),
});
