import { AppShell } from '@mantine/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet } from 'react-router-dom';
import { APP_HEADER_HEIGHT, AppHeader } from '../components/app-header/AppHeader';
import { SidebarV2 } from '../components/sidebar/sidebar-v2/Sidebar';
import { SidebarV3 } from '../components/sidebar/sidebar-v3/Sidebar';
import { useIsMobileScreen, useSidebarWidth } from '../components/sidebar/util';
import { useMarkovTheme } from '../design-system/v2';

export const PageLayout = (): JSX.Element => {
  const theme = useMarkovTheme();
  const { featureProductRestructuring } = useFlags();

  const isMobileScreen = useIsMobileScreen();
  const sidebarWidth = useSidebarWidth();

  return (
    <AppShell
      styles={{
        root: {
          height: '100%',
        },
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
          minHeight: `calc(100% - ${APP_HEADER_HEIGHT}px)`,
          paddingLeft: isMobileScreen ? 0 : sidebarWidth,
        },
        body: {
          height: '100%',
        },
      }}
      padding={0}
      navbar={featureProductRestructuring ? <SidebarV3 /> : <SidebarV2 />}
      navbarOffsetBreakpoint="sm"
      footer={<></>}
      header={<AppHeader />}
    >
      <Outlet />
    </AppShell>
  );
};
