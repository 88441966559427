import { useEffect } from 'react';
import userflow from 'userflow.js';
import { logger } from './logging';

export const TOUR_FLOW = '0b6afec9-93bf-4f24-815e-84b41f94c5bc';
export const WHATS_NEW_FLOW = '4054634a-9209-4a20-8b34-23b20ea80714';
const GET_STARTED_CHECKLIST = '710d3352-7804-4fc3-a0d0-3cc33f31642d';

export enum UserflowTrackingEvents {
  REGISTER_DATASET_SUCCESS = 'register_dataset_success',
  INVITE_FRIEND_SUCCESS = 'invite_friend_success',
}

let isUserflowInit = false;
let isUserflowRegistered = false;

const isUserflowInitialized = (callingFunctionName: string): boolean => {
  if (!userflow) {
    logger.error(`Userflow was called before it was initialized from ${callingFunctionName}`);
    return false;
  }
  return true;
};

export const startFlow = (flowId = GET_STARTED_CHECKLIST) => {
  if (isUserflowInitialized('startTour')) userflow.start(flowId);
};

export const initUserflow = () => {
  if (isUserflowInit) {
    return;
  }

  try {
    // Temporarily fallback to the token for our dev environment in Userflow
    // TODO: Remove this fallback value when we have dev/prod tokens in GitHub deployment script
    userflow.init(import.meta.env.VITE_APP_USERFLOW_TOKEN || 'ct_7oenen2qzrf5tlp3p5nkgvsgdy');
    userflow.setResourceCenterLauncherHidden(true);
    userflow.closeResourceCenter();

    isUserflowInit = true;
  } catch (error) {
    logger.error(error);
  }
};

export const registerUserflow = (userId: string, name: string, email: string) => {
  if (isUserflowRegistered) {
    return;
  }

  isUserflowRegistered = true;

  userflow.identify(userId, {
    name: name,
    email: email,
    signed_up_at: new Date().toISOString(),
  });
};

export const hideResourceCenterLauncher = () => {
  if (isUserflowInitialized('hideResourceCenterLauncher'))
    userflow.setResourceCenterLauncherHidden(true);
};

export const showResourceCenterLauncher = () => {
  if (isUserflowInitialized('showResourceCenterLauncher'))
    userflow.setResourceCenterLauncherHidden(false);
};

export const openResourceCenter = () => {
  if (isUserflowInitialized('openResourceCenter')) userflow.openResourceCenter();
};

export const closeResourceCenter = () => {
  if (isUserflowInitialized('closeResourceCenter')) userflow.closeResourceCenter();
};

export const trackUserflowEvent = (eventName: string) => {
  if (isUserflowInitialized('trackUserflowEvent')) userflow.track(eventName);
};

export interface UserflowEvent {
  name: string;
  url?: string;
  data?: any;
}

export const useUserflowJSExecutor = (onUserflowEvent: (evt: UserflowEvent) => void) => {
  useEffect(() => {
    (window as any).useUserflowJSExecutor = onUserflowEvent;
  }, [onUserflowEvent]);
};
