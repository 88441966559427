import { CellProps } from 'react-table';
import { TextVariants } from '../../../design-system/v2';
import { EvaluationWithTags } from '../../../generated/api';
import { EvaluationsDateCell } from './EvaluationsDateCell';

export const getEvaluationsDateColumn = (width: number, textVariant: TextVariants) => ({
  Header: 'Created on',
  accessor: 'createDate',
  id: 'createDate',
  width: width,
  disableFilters: true,
  Cell: (props: CellProps<EvaluationWithTags>) => (
    <EvaluationsDateCell {...props} textVariant={textVariant} />
  ),
});
