import { ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { Instance } from 'tippy.js';

interface GetMentionsOptions extends Pick<SuggestionOptions, 'items' | 'command'> {
  suggestionComponent: any;
}

export const getSuggestionOptions = ({
  items,
  command,
  suggestionComponent,
}: GetMentionsOptions): Omit<SuggestionOptions, 'editor'> => ({
  items,
  command,
  render: () => {
    let component: ReactRenderer<any> | undefined;
    let popup: Instance[];

    return {
      onStart: props => {
        component = new ReactRenderer(suggestionComponent, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        // TODO:: Remove tippy and use floating ui/mantine component
        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as () => DOMRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);
        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect as () => DOMRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }
        return !!component?.ref?.onKeyDown({ event: props.event });
      },

      onExit() {
        popup && popup[0] && popup[0].destroy();
        component?.destroy();
      },
    };
  },
});
