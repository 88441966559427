import { Popover as MantinePopover, PopoverProps as MantinePopoverProps } from '@mantine/core';
import { PopoverTarget } from './PopoverTarget';

type PopoverProps = MantinePopoverProps;

// Making withinPortal default value to be true. As it helps to not have stacking issues.
// If popover is to be rendered in a stacking context, should use withinPortal=false
export const Popover = ({ withinPortal = true, ...restProps }: PopoverProps) => (
  <MantinePopover withinPortal={withinPortal} {...restProps}></MantinePopover>
);

Popover.Target = PopoverTarget;
Popover.Dropdown = MantinePopover.Dropdown;

Popover.displayName = 'Popover';
