import css from '@styled-system/css';
import styled from 'styled-components';
import Box from '../Box';
import Text from '../Text';
import theme from '../theme';

interface StyledRadioButtonGroupContainerProps {
  layout?: 'VERTICAL' | 'HORIZONTAL';
}

export const StyledRadioButtonItemContainer = styled(Box)<StyledRadioButtonGroupContainerProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const StyledRadioButtonGroupContainer = styled(Box)<StyledRadioButtonGroupContainerProps>(
  css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
  ({ layout }) => {
    const isVertical = layout === 'VERTICAL';

    return {
      flexDirection: isVertical ? 'column' : 'row',
      alignItems: 'flex-start',

      [StyledRadioButtonItemContainer]: {
        marginLeft: isVertical ? 0 : '12px',
        marginBottom: isVertical ? '8px' : 0,
      },
    };
  },
);

export const StyledRadioLabel = styled(Text)(
  css({
    display: 'flex',
    cursor: 'pointer',

    span: {
      ml: '8px',
    },
  }),
);

export const StyledRadioInput = styled.input(
  css({
    appearance: 'none',
    /* For iOS < 15 to remove gradient background */
    backgroundColor: 'transparent',
    /* Not removed via appearance */
    margin: '0',

    font: 'inherit',
    color: 'primary.base',
    width: '1.43em',
    height: '1.43em',
    border: 'thickSolid',
    borderColor: 'primary.base',
    borderRadius: '50%',
    cursor: 'pointer',

    display: 'grid',
    placeContent: 'center',

    '::before': {
      content: "''",
      width: '0.857em',
      height: '0.857em',
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: `inset 1em 1em ${theme.colors.primary.base}`,
    },

    ':checked': {
      '::before': {
        transform: 'scale(1)',
      },
    },
  }),
);
