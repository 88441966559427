import { SeriesBarOptions } from 'highcharts';
import React from 'react';
import { formatNumber } from '../lib/ui';
import { ChartTooltipProps } from './components/tooltip/ChartTooltip';
import { BoxPlotTooltip } from './components/tooltip/boxplot/boxplot';
import { PackedBubbleTooltip } from './components/tooltip/bubble/packed-bubble';
import { ColumnTooltip } from './components/tooltip/column/column';
import { StackedColumnTooltip } from './components/tooltip/column/stacked-column';
import { HeatmapTooltip } from './components/tooltip/heatmap/heatmap';
import { PieTooltip } from './components/tooltip/pie/pie';
import { WordCloudTooltip } from './components/tooltip/word-cloud/wordcloud';

export enum ChartTypes {
  COLUMN = 'COLUMN',
  BAR = 'BAR',
  BOX_PLOT = 'BOX_PLOT',
  LINE = 'LINE',
  SPLINE = 'SPLINE',
  PIE = 'PIE',
  SCATTER = 'SCATTER',
  BUBBLE = 'BUBBLE',
  WORD_CLOUD = 'WORD_CLOUD',
  HEATMAP = 'HEATMAP',
  TREEMAP = 'TREEMAP',
  PACKED_BUBBLE = 'PACKED_BUBBLE',
  GAUGE = 'GAUGE',

  // FIXME:: @Lavish Remove these two chart types. We should apply data formatting logic per column
  GROUPED_COLUMN = 'GROUPED_COLUMN',
  STACKED_COLUMN = 'STACKED_COLUMN',
}

interface ConfigOptions {
  colorAxis?: boolean;
}

export interface ChartWiseSpecification {
  configOptions?: ConfigOptions;
  highchartSeriesOptions?: Highcharts.SeriesOptionsRegistry[keyof Highcharts.SeriesOptionsRegistry];
  highchartsChartOptions?: Highcharts.ChartOptions;
  highchartsXAxisOptions?: Highcharts.XAxisOptions;
  highchartsYAxisOptions?: Highcharts.YAxisOptions;
  highchartsColorAxisOptions?: Highcharts.ColorAxisOptions;
  highchartsLegendOptions?: Highcharts.LegendOptions;
  highchartsPaneOptions?: Highcharts.PaneOptions;
  TooltipComponent?: React.FC<ChartTooltipProps>;
}

type ChartTypeSpecificationType = {
  [key in ChartTypes]?: ChartWiseSpecification;
};

export const chartTypeSpecification: ChartTypeSpecificationType = {
  [ChartTypes.COLUMN]: {
    highchartsChartOptions: {
      type: 'column',
      spacing: [5, 20, 20, 0],
    },
    highchartSeriesOptions: {
      type: 'column',
    },
    TooltipComponent: ColumnTooltip,
  },
  [ChartTypes.STACKED_COLUMN]: {
    highchartsChartOptions: {
      spacing: [5, 20, 20, 20],
    },
    highchartSeriesOptions: {
      type: 'column',
      stacking: 'percent',
    },
    highchartsXAxisOptions: {
      labels: {
        autoRotation: [90],
        style: {
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#7D8DA1',
        },
      },
    },
    highchartsYAxisOptions: {
      labels: {
        style: {
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
    },

    TooltipComponent: StackedColumnTooltip,
  },
  [ChartTypes.GROUPED_COLUMN]: {
    highchartsChartOptions: {
      spacing: [5, 20, 20, 20],
    },
    highchartSeriesOptions: {
      type: 'column',
    },
    highchartsXAxisOptions: {
      labels: {
        autoRotation: [90],
      },
      type: 'category',
    },
  },
  [ChartTypes.BAR]: {
    highchartsChartOptions: {
      type: 'bar',
      spacing: [0, 0, 0, 0],
    },
    highchartSeriesOptions: {
      type: 'bar',
    },
    highchartsXAxisOptions: {
      lineWidth: 0,
      labels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          const userOptions = this.chart.series[0]?.userOptions as SeriesBarOptions;
          if (userOptions?.data && userOptions?.data[this.pos]) {
            let formattedValue;
            if (
              userOptions?.data[this.pos] &&
              typeof (userOptions?.data[this.pos] as number[])[0] === 'number'
            ) {
              formattedValue = formatNumber((userOptions?.data[this.pos] as number[])[0], 2);
            } else {
              if ((userOptions?.data[this.pos] as { x: number })?.x) {
                formattedValue = formatNumber((userOptions?.data[this.pos] as { x: number })?.x, 2);
              }
            }

            return `<b>${formattedValue}</b>`;
          }
          return '';
        },
      },
    },
    highchartsYAxisOptions: {
      lineWidth: 0,
      labels: {
        enabled: false,
      },
      tickWidth: 0,
    },
  },
  [ChartTypes.BOX_PLOT]: {
    highchartsChartOptions: {
      inverted: true,
      spacingLeft: 1,
      spacingRight: 1,
      spacingBottom: 6,
      spacingTop: 0,
    },
    highchartSeriesOptions: {
      type: 'boxplot',
    },
    highchartsXAxisOptions: {
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
    },
    highchartsYAxisOptions: {
      lineWidth: 1,
      tickLength: 7,
      tickWidth: 1,
      labels: {
        format: '{value:.2f}',
      },
    },
    TooltipComponent: BoxPlotTooltip,
  },
  [ChartTypes.LINE]: {
    highchartSeriesOptions: {
      type: 'line',
    },
    highchartsYAxisOptions: {
      lineWidth: 1,
      tickLength: 7,
      tickWidth: 1,
      labels: {
        format: '{value:.2f}',
      },
    },
  },
  [ChartTypes.SPLINE]: {
    highchartSeriesOptions: {
      type: 'spline',
      marker: {
        radius: 3,
      },
    },
    highchartsYAxisOptions: {
      labels: {
        format: '{value:.3f}',
      },
    },
  },
  [ChartTypes.PIE]: {
    highchartsChartOptions: {
      marginTop: 16,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 124,
    },
    highchartSeriesOptions: {
      type: 'pie',
    },
    TooltipComponent: PieTooltip,
  },
  [ChartTypes.SCATTER]: {
    highchartSeriesOptions: {
      type: 'scatter',
    },
  },
  [ChartTypes.BUBBLE]: {
    highchartSeriesOptions: {
      type: 'bubble',
    },
  },
  [ChartTypes.PACKED_BUBBLE]: {
    highchartSeriesOptions: {
      type: 'packedbubble',
    },
    TooltipComponent: PackedBubbleTooltip,
  },
  [ChartTypes.WORD_CLOUD]: {
    highchartsChartOptions: {
      spacing: [0, 0, 0, 0],
      margin: 0,
    },
    highchartSeriesOptions: {
      type: 'wordcloud',
    },
    TooltipComponent: WordCloudTooltip,
  },
  [ChartTypes.HEATMAP]: {
    configOptions: {
      colorAxis: true,
    },
    highchartsChartOptions: {
      type: 'heatmap',
      spacing: [5, 0, 5, 5],
    },
    highchartsColorAxisOptions: {
      layout: 'vertical',
      reversed: false,
    },
    highchartsLegendOptions: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'middle',

      symbolHeight: 300,
      symbolWidth: 8,
    },
    TooltipComponent: HeatmapTooltip,
  },
  [ChartTypes.TREEMAP]: {
    highchartsChartOptions: {
      type: 'treemap',
    },
    highchartSeriesOptions: {
      layoutAlgorithm: 'squarified',
      clip: false,
      type: 'treemap',
    },
  },
  [ChartTypes.GAUGE]: {
    highchartsChartOptions: {
      type: 'gauge',
      plotBorderWidth: 0,
      plotShadow: false,
      height: 240,
    },
    highchartsPaneOptions: {
      startAngle: -90,
      endAngle: 89.9,
      center: ['50%', '75%'],
      size: '100%',
      background: [
        {
          innerRadius: '100%',
          outerRadius: '100%',
          shape: 'arc',
        },
      ],
    },
    highchartsYAxisOptions: {
      min: 0,
      max: 100,
      labels: {
        distance: 20,
        style: {
          fontSize: '14px',
        },
      },
      lineWidth: 0,
      minorTickInterval: null,
      tickWidth: 0,
    },
  },
};
