import { Horizontal, Text, Tooltip, Vertical } from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { Workflow, WorkflowRun } from '../../../../generated/api';
import { formatDateTime } from '../../../../lib/ui';
import { WorkflowRunStatusBadge } from '../../detail/runs/WorkflowRunStatusBadge';

export const WorkflowLastRunStatusRenderer = ({
  value: lastRunDetails,
}: ICellRendererParams<Workflow, WorkflowRun>) => {
  const { status, createDate, endDate, totalDuration } = lastRunDetails ?? {};
  return (
    <Horizontal align="center" w="100%" h="100%">
      <Tooltip
        openDelay={200}
        withinPortal
        label={
          <Vertical>
            <Text>Started at: {createDate ? formatDateTime(createDate) : '--'}</Text>
            <Text>Completed at: {endDate ? formatDateTime(endDate) : '--'}</Text>
            <Text>Total duration: {totalDuration ? totalDuration : `--`}</Text>
          </Vertical>
        }
        position="top"
      >
        <WorkflowRunStatusBadge status={status} />
      </Tooltip>
    </Horizontal>
  );
};
