import { IconInfoCircle } from '@tabler/icons-react';
import first from 'lodash/first';
import { useState } from 'react';
import { Alert, Center, Loader, useInputState } from '../../../design-system/v2';
import {
  useGetAppBuilderDetailsQuery,
  useGetAvailableModelsForAppBuilderQuery,
} from '../../../queries/app-builder/app-builder';
import { AppBuilderDetailsFormContainer } from './AppBuilderDetailsForm.container';
import { AppBuilderDetailsHeaderContainer } from './AppBuilderDetailsHeader.container';
import { AppBuilderViews } from './AppBuilderViewsControl';

interface AppBuilderDetailsContainerProps {
  appId: string;
}

export const AppBuilderDetailsContainer = ({
  appId,
}: AppBuilderDetailsContainerProps): JSX.Element => {
  // const [selectedModel, setSelectedModel] = useState<LLMModel | undefined>();
  const [activeView, setActiveView] = useState(AppBuilderViews.BUILD);

  // TODO: Use a single state variable formState
  const [prompt, setPrompt] = useInputState('');
  const [example, setExample] = useInputState('');

  const {
    isLoading: isLoadingAppBuilderDetails,
    error,
    data: appBuilderDetails,
  } = useGetAppBuilderDetailsQuery(appId);

  const { isLoading: isLoadingModels, data: availableModels } =
    useGetAvailableModelsForAppBuilderQuery();
  const selectedModel = first(availableModels);

  // useEffect(() => {
  //   if (availableModels) {
  //     setSelectedModel(first(availableModels));
  //   }
  // }, [availableModels]);

  // const handleSelectedModelChange = (value: string | null) => {
  //   const model = availableModels?.find(model => model.modelId === value);
  //   setSelectedModel(model);
  // };

  const isPromptEmpty = !appBuilderDetails?.appProperties?.appPrompt;
  const hasPromptChanged =
    (appBuilderDetails?.appProperties?.appPrompt ?? '') !== prompt.trim() ||
    (first(appBuilderDetails?.appProperties?.examples) ?? '') !== example.trim();

  if (isLoadingAppBuilderDetails || isLoadingModels) {
    return (
      <Center h="100%">
        <Loader text="Loading details for the app builder..." />
      </Center>
    );
  }

  if (error || !selectedModel || !appBuilderDetails) {
    return (
      <Center h="100%">
        <Alert icon={<IconInfoCircle size={14} />} color="red">
          Unable to load details of the app. Please try again after sometime or contact support!
        </Alert>
      </Center>
    );
  }

  return (
    <>
      <AppBuilderDetailsHeaderContainer
        appId={appId}
        isLoadingAppBuilderDetails={isLoadingAppBuilderDetails}
        appBuilderDetails={appBuilderDetails}
        isPromptEmpty={isPromptEmpty}
        // We want to show a loader when the values are being synced with the backend.
        // FIXME: This logic has a flaw. When the update API fails,
        // the loader will go on until we get the next success response from the same API.
        // We need to handle this better
        isSavingChanges={hasPromptChanged}
        activeView={activeView}
        onActiveViewChange={setActiveView}
      />
      <AppBuilderDetailsFormContainer
        activeView={activeView}
        prompt={prompt}
        example={example}
        onPromptChange={setPrompt}
        onExampleChange={setExample}
        appId={appId}
        selectedModel={selectedModel!}
        appBuilderDetails={appBuilderDetails}
      />
    </>
  );
};
