import { SlackLogo } from '../../../design-system';
import { SettingsSection } from '../SettingsSection';
import { IntegrationsCard } from './IntegrationsCard';
import { SlackAuthenticationContainer } from './SlackAuthentication/SlackAuthentication';

const INTEGRATIONS = [
  {
    id: 'slack',
    name: 'Slack',
    description: 'Add the MarkovML bot to your slack workspace for receiving notifications',
    icon: <SlackLogo height="80px" width="80px" />,
    buttonContainer: <SlackAuthenticationContainer />,
  },
];

export const Integrations = () => (
  <SettingsSection title="Integrations">
    {INTEGRATIONS.map(({ id, name, icon, description, buttonContainer }) => (
      <IntegrationsCard key={id} name={name} icon={icon} description={description}>
        {buttonContainer}
      </IntegrationsCard>
    ))}
  </SettingsSection>
);
