import { mergeAttributes } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ParagraphForTableView } from './ParagraphForTableView';

export interface ParagraphForTableOptions {
  HTMLAttributes: Record<string, any>;
}

export const ParagraphForTable = Paragraph.extend({
  name: 'paragraphForTable',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphForTableView);
  },
});
