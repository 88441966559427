import { useForm } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  Alert,
  Box,
  Horizontal,
  PasswordInput,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
} from '../../../design-system/v2';
import { CreateConnectorRequest, StorageType } from '../../../generated/api';
import { useCreateConnectorMutation } from '../../../queries/connectors';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../util';
import { AwsRegionSelect } from './AwsRegionSelect';
import { CreateConnectorModalActions } from './CreateConnectorModalActions';

const useS3ConnectorForm = () =>
  useForm({
    initialValues: {
      connectorName: '',
      region: '',
      accessKey: '',
      accessSecret: '',
    },
    validate: {
      connectorName: value => {
        if (!value.trim()) {
          return 'Connector name cannot be empty';
        }
        return null;
      },
      region: value => {
        if (!value.trim()) {
          return 'Region cannot be empty';
        }
        return null;
      },
      accessKey: value => {
        if (!value.trim()) {
          return 'Access key cannot be empty';
        }
        return null;
      },
      accessSecret: value => {
        if (!value.trim()) {
          return 'Access secret cannot be empty';
        }
        return null;
      },
    },
    transformValues: values => ({
      connectorName: values.connectorName.trim(),
      region: values.region,
      accessKey: values.accessKey.trim(),
      accessSecret: values.accessSecret.trim(),
    }),
  });

interface S3ConnectorModalProps {
  onClickBack?: () => void;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const S3ConnectorModal = ({
  onClickBack,
  onCreate,
  onClose = closeAllModals,
}: S3ConnectorModalProps) => {
  const form = useS3ConnectorForm();

  const { mutate: createConnector, isLoading } = useCreateConnectorMutation();

  const handleCreateConnector = () => {
    if (form.validate().hasErrors) {
      return;
    }

    const { connectorName, accessKey, accessSecret, region } = form.getTransformedValues();

    // TODO: Include AWS region in request once supported by API
    const createReq: CreateConnectorRequest = {
      name: connectorName,
      connectorType: StorageType.S3,
      credData: {
        accessKey,
        accessSecret,
        regionName: region,
      },
      connectorMetadata: {},
    };

    createConnector(createReq, {
      onError: () => {
        notifications.error('Unable to create connector');
      },
      onSuccess: res => {
        notifications.success('Connector created successfully');
        const connectorId = res.data.response?.connectorId ?? '';
        onCreate?.(connectorId);
        onClose();
      },
    });
  };

  const icon = getConnectorTypeIcon(StorageType.S3, 28);
  const connectorName = getConnectorTypeDisplayName(StorageType.S3);

  return (
    <Vertical spacing="xl">
      <Box>
        <Alert color="yellow" icon={<IconInfoCircle />}>
          Access credentials will be encrypted and stored securely.
        </Alert>
      </Box>
      <Horizontal>
        <Text variant="subTitle03">Service:</Text>
        <Horizontal spacing="sm">
          {icon}
          <Text variant="subTitle03">{connectorName}</Text>
        </Horizontal>
      </Horizontal>
      <Vertical spacing="sm">
        <TextInput
          required
          ariaLabel="Connector name"
          label="Connector name"
          {...form.getInputProps('connectorName')}
        />
        <AwsRegionSelect
          required
          withinPortal
          clearable
          ariaLabel="AWS Region"
          placeholder="Select region"
          {...form.getInputProps('region')}
        />
        <TextInput
          required
          ariaLabel="Access key"
          label="Access Key"
          {...form.getInputProps('accessKey')}
        />
        <PasswordInput
          required
          aria-label="Access secret"
          label="Access secret"
          {...form.getInputProps('accessSecret')}
        />
      </Vertical>

      <CreateConnectorModalActions
        onClickBack={onClickBack}
        onCreate={handleCreateConnector}
        onClose={onClose}
        isCreating={isLoading}
      />
    </Vertical>
  );
};
