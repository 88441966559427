import { PropsWithChildren } from 'react';
import { Tooltip } from '../../design-system/v2';

interface AppHeaderElementProps {
  label: string;
  tooltipOffset?: number;
}
export const AppHeaderElement = ({
  label,
  tooltipOffset = 12,
  children,
  ...rest
}: PropsWithChildren<AppHeaderElementProps>): JSX.Element => (
  <Tooltip label={label} aria-label={label} offset={tooltipOffset} {...rest}>
    {children}
  </Tooltip>
);
