import { useRef } from 'react';
import { Box } from '../../../../design-system/v2';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { WorkspaceActivity } from '../../../../queries/account/activity';
import { Loading } from '../../../common/Loading';
import { ActivityItem } from './ActivityItem';

interface ActivitiesProps {
  activities: WorkspaceActivity[];
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

export const Activities = ({
  activities,
  fetchNextPage,
  hasNextPage,
  isFetching,
}: ActivitiesProps) => {
  const listEndRef = useRef(null);

  useIntersectionObserver({
    target: listEndRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <Box mah={1078} sx={{ overflowY: 'scroll' }}>
      {activities.map((activity, index) => (
        <ActivityItem key={`${activity.createDate}${index}`} activity={activity} />
      ))}
      {hasNextPage && (
        <Box ref={listEndRef} sx={{ height: '50px', position: 'relative' }}>
          {isFetching && <Loading />}
        </Box>
      )}
    </Box>
  );
};
