import { Box, Button, Horizontal, Skeleton } from '../../../../design-system/v2';

export interface SuggestionListProps {
  suggestionsList: Array<{ topic: string; description: string }>;
  onSuggestionSelect: (description: string) => void;
  isLoading: boolean;
}

const SuggestionsList = ({ isLoading, suggestionsList, onSuggestionSelect }: SuggestionListProps) =>
  isLoading ? (
    <Skeleton h={36} />
  ) : (
    <Horizontal role="list">
      {suggestionsList.map(({ topic, description }) => (
        <Box role="listitem" key={topic}>
          <Button
            fw="normal"
            onClick={() => onSuggestionSelect(description)}
            variant="secondary"
            color="gray.7"
          >
            {topic}
          </Button>
        </Box>
      ))}
    </Horizontal>
  );

export default SuggestionsList;
