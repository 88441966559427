import { useDatasetClusterContext } from '../../../../contexts/datasets/DatasetClusterContext';
import { Badge, Select, Text } from '../../../../design-system/v2';

const SELECT_WIDTH = 190;

enum MislabeledFilterValue {
  MISLABELED = 'MISLABELED',
  NOT_MISLABELED = 'NOT_MISLABELED',
}

const options = [
  {
    value: MislabeledFilterValue.MISLABELED,
    label: 'Only mislabeled points',
  },
  {
    value: MislabeledFilterValue.NOT_MISLABELED,
    label: 'No mislabeled points',
  },
];

interface MislabelledSelectionProps {
  totalCount?: number;
}

export const MislabelledSelection = ({ totalCount }: MislabelledSelectionProps) => {
  const { showMislabelledData, setShowMislabelledData } = useDatasetClusterContext();

  if (totalCount === 0) {
    return (
      <Badge p="md" variant="outline" color="gray.4" style={{ cursor: 'not-allowed' }}>
        <Text variant="small01" color="gray.6" tt="capitalize">
          No mislabeled data 🎉
        </Text>
      </Badge>
    );
  }

  // Context value is boolean or undefined; Select value is null or MislabeledFilterValue
  const handleChange = (value: MislabeledFilterValue) => {
    const contextValue = value === null ? undefined : value === MislabeledFilterValue.MISLABELED;
    setShowMislabelledData(contextValue);
  };

  const value =
    showMislabelledData === undefined
      ? null
      : showMislabelledData
      ? MislabeledFilterValue.MISLABELED
      : MislabeledFilterValue.NOT_MISLABELED;

  return (
    <Select
      clearable
      w={SELECT_WIDTH}
      ariaLabel="Mislabeled filter"
      hideLabel
      placeholder="Filter mislabeled"
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
};
