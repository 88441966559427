import { useMediaQuery } from '@mantine/hooks';
import {
  Box,
  Grid,
  ScrollArea,
  TableOfContents,
  TextEditor,
  useMarkovTheme,
} from '../../../design-system/v2';
import { GetSnippetContentResponse } from '../../../generated/api';
import { useSnippetDetail } from '../detail/context/SnippetContext';
import { Title } from '../detail/editor/input/Title';
import { usePublicSnippetEditorStyles } from './PublicSnippet.style';

interface PublicSnippetEditorProps {
  snippet: GetSnippetContentResponse;
}

export const PublicSnippetEditor = ({ snippet }: PublicSnippetEditorProps) => {
  const theme = useMarkovTheme();
  const { classes } = usePublicSnippetEditorStyles();
  const { editor } = useSnippetDetail();

  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box w="100%" pt="xxl" px={!isMobileScreen ? '6%' : '0px'}>
      <Grid columns={24} m={0} p={0}>
        <Grid.Col xs={24} sm={4}>
          <ScrollArea
            h={isMobileScreen ? '50vh' : 'calc(100vh - 112px)'}
            onClick={e => e.stopPropagation()}
          >
            {editor && <TableOfContents editor={editor} />}
          </ScrollArea>
        </Grid.Col>
        <Grid.Col xs={24} sm={20}>
          <ScrollArea h="calc(100vh - 112px)">
            <Title
              w="100%"
              px={isMobileScreen ? 0 : 56}
              ariaLabel="Snippet Title"
              value={snippet.title ?? ''}
              placeholder="Untitled"
              disabled
            />
            <TextEditor classNames={classes} editor={editor} />
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
