import { IconReload } from '@tabler/icons-react';
import { ActionIcon, Checkbox, Horizontal } from '../../../../design-system/v2';
import { ArtifactStateType } from '../../../../generated/api';
import CollapsibleSearch from './CollapsibleSearch';
import { useStyles } from './ToolbarActionIcon.styles';

export interface ActionsToolbarProps {
  isLoading: boolean;
  artifactState: ArtifactStateType;
  searchText: string;
  onSearchTextChange: (value: string) => void;
  showOnlyScheduled: boolean;
  onShowOnlyScheduledCheckboxToggle: () => void;
  onReload: () => void;
}

export const ActionsToolbar = ({
  artifactState: state,
  searchText,
  onSearchTextChange,
  showOnlyScheduled,
  onShowOnlyScheduledCheckboxToggle,
  onReload,
  isLoading,
}: ActionsToolbarProps) => {
  const { classes } = useStyles();

  return (
    <Horizontal spacing={16}>
      <CollapsibleSearch
        ariaLabel="Search Workflows"
        value={searchText}
        onChange={onSearchTextChange}
      />
      <ActionIcon className={classes.icon} disabled={isLoading} onClick={onReload}>
        <IconReload width={16} height={16} />
      </ActionIcon>
      {state === ArtifactStateType.Active && (
        <Checkbox
          label="Show only scheduled"
          checked={showOnlyScheduled}
          onChange={onShowOnlyScheduledCheckboxToggle}
        />
      )}
    </Horizontal>
  );
};
