import { Image } from '../../../../design-system/v2';

export interface TemplateAction {
  id: string;
  label: string;
  handler: () => void;
}

export const getTemplateIcon = (url: string, size = 32) => (
  <Image src={url} width={size} height={size} />
);
