import { forwardRef, useEffect } from 'react';
import { Box, Horizontal, Select, SelectItem, Text } from '../../../../design-system/v2';
import { StorageType } from '../../../../generated/api';
import { formatDateLong } from '../../../../lib/ui';
import { useGetConnectorsListV2Query } from '../../../../queries/connectors';
import { getConnectorTypeIcon } from '../../util';

interface ConnectorItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  connectorType: StorageType;
  createDate: string;
}

const ConnectorItem = forwardRef<HTMLDivElement, ConnectorItemProps>(
  ({ label, connectorType, createDate, ...rest }: ConnectorItemProps, ref): JSX.Element => (
    <div ref={ref} {...rest}>
      <Horizontal noWrap spacing="lg">
        <Box sx={{ flexShrink: 0 }}>{getConnectorTypeIcon(connectorType)}</Box>
        <Box>
          <Text variant="subTitle04">{label}</Text>
          <Text variant="subTitle05" color="gray.5">
            Added on {formatDateLong(createDate)}
          </Text>
        </Box>
      </Horizontal>
    </div>
  ),
);

ConnectorItem.displayName = 'ConnectorItem';

interface ConnectorSelectContainerProps {
  value: string | null;
  onChange: (connectorId: string) => void;
  connectorTypes?: StorageType[];
}

export const ConnectorSelectContainer = ({
  connectorTypes,
  value,
  onChange,
}: ConnectorSelectContainerProps): JSX.Element => {
  const connectorsQuery = useGetConnectorsListV2Query(connectorTypes);

  const isLoading = connectorsQuery.isLoading || connectorsQuery.isIdle;
  const connectors = connectorsQuery.data?.response ?? [];

  // Set value when data loads
  useEffect(() => {
    if (!value && connectors[0]) {
      onChange(connectors[0].connectorId);
    }
  }, [connectors, value]);

  const options: SelectItem[] = connectors.map(
    ({ connectorId, name, connectorType, createDate }) => ({
      label: name,
      value: connectorId,
      connectorType,
      createDate,
    }),
  );

  return (
    <Select
      ariaLabel="Select existing connector"
      searchable
      itemComponent={ConnectorItem}
      nothingFound="No connectors found"
      options={options}
      value={value}
      onChange={onChange}
      loading={isLoading}
    />
  );
};
