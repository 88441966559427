import { Center, Loader } from '../../../design-system/v2';
import { useGetWorkspaceMembersQuery } from '../../../queries/account/workspace';
import { InfoMessage } from '../../common/InfoMessage';
import { MembersTable } from './MembersTable';

export const MembersContainer = (): JSX.Element => {
  const { isLoading, data, error } = useGetWorkspaceMembersQuery();

  if (isLoading) {
    return (
      <Center h={200}>
        <Loader text="Loading member list" />
      </Center>
    );
  }

  if (error || !data) {
    return <InfoMessage text="Unable to load member list" />;
  }

  return <MembersTable members={data.workspaceMembers} />;
};
