import { Mark, mergeAttributes } from '@tiptap/core';
import { v4 as uuid } from 'uuid';

import { Plugin } from 'prosemirror-state';

export const SPLITTING_MARK_META = 'splittingMarks';

const splittingMarksPlugin = new Plugin({
  appendTransaction(transactions, oldState, newState) {
    const tr = newState.tr;
    let modified = false;

    // Check if any transaction contains the 'splittingMarks' meta flag
    // Right now we are just checking for duplicates if this meta is present
    // TODO: Make this plugin more generic, to check for duplicates for every transaction
    const hasSplittingMarks = transactions.some(transaction =>
      transaction.getMeta(SPLITTING_MARK_META),
    );

    if (hasSplittingMarks) {
      const markIds = new Map(); // To track 'data-id' values
      const { doc, schema } = newState;

      doc.descendants((node, pos) => {
        if (!node.marks || node.marks.length === 0) return;

        node.marks.forEach(mark => {
          if (mark.type.name === 'textStyle' && mark.attrs['data-id']) {
            const dataId = mark.attrs['data-id'];

            if (markIds.has(dataId)) {
              // Create new data-id if it has duplicate data-id
              const newMark = schema.marks.textStyle.create({
                ...mark.attrs,
                'data-id': uuid(),
              });
              tr.addMark(pos, pos + node.nodeSize, newMark);
              modified = true;
            } else {
              markIds.set(dataId, true);
            }
          }
        });
      });
    }

    return modified ? tr : null;
  },
});

export interface TextStyleOptions {
  HTMLAttributes: Record<string, any>;
}

export function sanitizeStyleAttr(styleAttr: string) {
  // Define a regular expression to match potentially harmful CSS code
  const regex = /expression|javascript|behaviour|vbscript|data:/gi;

  // Remove any potentially harmful code from the style attribute
  const sanitizedStyleAttr = styleAttr.replace(regex, '');

  // Return the sanitized style attribute
  return sanitizedStyleAttr;
}

export const TextStyle = Mark.create<TextStyleOptions>({
  name: 'textStyle',

  // We want to keep the formatting preserved even if the mark is split
  keepOnSplit: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      'data-id': {
        default: uuid(),
      },
      class: {
        default: null,
      },
      style: {
        default: '',
        parseHTML: element => element.getAttribute('style'),
        renderHTML: attributes => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: sanitizeStyleAttr(attributes.style),
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addProseMirrorPlugins() {
    return [splittingMarksPlugin];
  },
});
