import { EditorView } from '@tiptap/pm/view';
import { useCallback, useEffect, useState } from 'react';
import { MEMORY_UNIT_LIMITS } from '../../../../../lib/resource-usage';
import { notifications } from '../../../core';

let lastClientX: number;

export const useResizeMedia = (
  mediaType: string,
  resizableRef: React.MutableRefObject<any>,
  onResize: ({ width, height }: { width: number; height: number }) => void,
) => {
  const [aspectRatio, setAspectRatio] = useState(0);

  const onHorizontalResize = useCallback(
    (directionOfMouseMove: 'right' | 'left', diff: number) => {
      if (!resizableRef.current) {
        return;
      }

      const currentMediaDimensions = {
        width: Number(resizableRef.current?.width),
        height: Number(resizableRef.current?.height),
      };

      const newMediaDimensions = {
        width: -1,
        height: -1,
      };

      if (directionOfMouseMove === 'left') {
        newMediaDimensions.width = currentMediaDimensions.width - Math.abs(diff);
      } else {
        newMediaDimensions.width = currentMediaDimensions.width + Math.abs(diff);
      }
      newMediaDimensions.height = newMediaDimensions.width / aspectRatio;

      if (
        newMediaDimensions.width < 150 ||
        newMediaDimensions.height < 150 ||
        newMediaDimensions.width > window.innerWidth * 0.7
      ) {
        return;
      }

      onResize(newMediaDimensions);
    },
    [aspectRatio, resizableRef, onResize],
  );

  const mediaSetupOnLoad = useCallback(() => {
    if (!resizableRef.current) return;

    if (mediaType === 'video') {
      const video = resizableRef.current as HTMLVideoElement;

      video.addEventListener('loadeddata', function () {
        // Aspect Ratio from its original size
        setAspectRatio(video.videoWidth / video.videoHeight);

        // for the first time when video is added with custom width and height
        // and we have to adjust the video height according to it's width
        onHorizontalResize('left', 0);
      });
    } else {
      resizableRef.current.onload = () => {
        const ref = resizableRef.current as HTMLImageElement;
        // Aspect Ratio from its original size

        setAspectRatio((ref?.naturalWidth ?? ref?.width) / (ref?.naturalHeight ?? ref?.height));
      };
    }
  }, [mediaType, onHorizontalResize, resizableRef]);

  const setLastClientX = (x: number) => {
    lastClientX = x;
  };

  const onHorizontalMouseMove = (e: MouseEvent) => {
    if (lastClientX === -1) return;

    const { clientX } = e;

    const diff = lastClientX - clientX;

    if (diff === 0) return;

    const directionOfMouseMove: 'left' | 'right' = diff > 0 ? 'left' : 'right';

    setTimeout(() => {
      onHorizontalResize(directionOfMouseMove, Math.abs(diff));
      lastClientX = clientX;
    });
  };

  const documentHorizontalMouseMove = (e: MouseEvent) => {
    setTimeout(() => onHorizontalMouseMove(e));
  };

  const stopHorizontalResize = () => {
    lastClientX = -1;
    if (resizableRef.current) {
      resizableRef.current.style = 'pointer-events: auto';
    }

    document.removeEventListener('mousemove', documentHorizontalMouseMove);
    document.removeEventListener('mouseup', stopHorizontalResize);
  };

  const startHorizontalResize = (e: { clientX: number }) => {
    lastClientX = e.clientX;
    if (resizableRef.current) {
      resizableRef.current.style = 'pointer-events: none';
    }

    setTimeout(() => {
      document.addEventListener('mousemove', documentHorizontalMouseMove);
      document.addEventListener('mouseup', stopHorizontalResize);
    });
  };

  useEffect(() => {
    mediaSetupOnLoad();
  }, [mediaSetupOnLoad]);

  return {
    onClick: ({ clientX }: any) => setLastClientX(clientX),
    onMouseDown: startHorizontalResize,
    onMouseUp: stopHorizontalResize,
  };
};

const FILE_SIZE_ALLOWED = 100; // In MB
const FILE_TYPES_ALLOWED = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp',
  'video/quicktime',
  'video/mp4',
];

export const insertFileInEditor = (file: File, view: EditorView, pos?: number) => {
  const fileSize = Number((file.size / MEMORY_UNIT_LIMITS.MB).toFixed(4));
  if (!FILE_TYPES_ALLOWED.includes(file.type)) {
    notifications.show({
      variant: 'error',
      message: 'The file format is not permitted.',
    });
    return true;
  }
  if (fileSize > FILE_SIZE_ALLOWED) {
    notifications.show({
      variant: 'error',
      message: `The file size exceeds ${FILE_SIZE_ALLOWED}mb.`,
    });
    return true;
  }
  const { schema } = view.state;

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    const serializedFile = JSON.stringify({
      name: file.name,
      type: file.type,
      data: reader.result,
    });

    const node = schema.nodes.resizableMedia.create({
      'media-type': file.type.includes('image') ? 'img' : 'video',
      // TODO: Avoid storing file in attributes
      file: serializedFile,
    });

    const transaction = view.state.tr.insert(pos ?? view.state.selection.head, node);
    view.dispatch(transaction);
  };
};

export const handleInsertFile = (view: EditorView, fileType?: 'image' | 'video', pos?: number) => {
  const fileUploadContainer = document.createElement('input');
  fileUploadContainer.setAttribute('type', 'file');
  fileUploadContainer.setAttribute(
    'accept',
    FILE_TYPES_ALLOWED.filter(type => !fileType || type.includes(fileType)).join(','),
  );
  fileUploadContainer.addEventListener('change', function () {
    const files = this.files ?? [];
    if (files[0]) {
      insertFileInEditor(files[0], view, pos);
    }
  });
  fileUploadContainer.click();
};
