import { ReactNode } from 'react';
import { Box, Text } from '../../design-system/v2';

interface MetadataFieldGroupProps {
  label: string;
  value: ReactNode;
}

export const MetadataFieldGroup = ({ label, value }: MetadataFieldGroupProps): JSX.Element => (
  <Box>
    <Text variant="subTitle02">{label}</Text>
    <Text variant="bodyLong02" maw="300px">
      {value}
    </Text>
  </Box>
);
