import { MantineSize, MantineTheme } from '@mantine/core';

const sizes: { [key in MantineSize]: number } = {
  xs: 32,
  sm: 36,
  md: 40,
  lg: 44,
  xl: 48,
};

export const inputTheme = {
  styles: (theme: MantineTheme, params: Record<string, any>) => ({
    input: {
      border: params.variant === 'default' ? '1.5px solid #C1CDE0' : '',
      backgroundColor: theme.fn.themeColor(params.variant === 'filled' ? 'white.1' : 'white.0'),
    },
  }),
};

export const fileInputTheme = {
  styles: (theme: MantineTheme, params: Record<string, any>) => ({
    input: {
      color: theme.fn.primaryColor(),
    },
  }),
};
