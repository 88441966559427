import { Box, LinkAnchor, Text } from '../../../../../../../../design-system/v2';

import { CitationStyleGuideType, CopyEditReference } from '../../../../../../../../generated/api';
import { getReferenceStyleDetails } from '../../../../../commons/citations/util';

interface ReferenceSectionProps {
  references: CopyEditReference[];
  referenceStyle: CitationStyleGuideType;
}

export interface ListReferenceProps {
  referenceObj: CopyEditReference;
  referenceStyle: CitationStyleGuideType;
}

export const ReferenceComponent = ({
  referenceObj,
  referenceStyle,
}: ListReferenceProps): JSX.Element => {
  const reference = referenceObj.reference;
  const { getListReferenceText } = getReferenceStyleDetails(referenceStyle);

  const refSummary = getListReferenceText(referenceObj.reference);
  const { identifier } = reference;
  const referenceUrl = identifier.doi ?? identifier.url ?? '';

  if (refSummary) {
    return (
      <Box p="sm">
        <Text variant="small01">{refSummary}</Text>
        <LinkAnchor external href={referenceUrl}>
          <Text variant="textLink" color="blue.6">
            {referenceUrl}
          </Text>
        </LinkAnchor>
      </Box>
    );
  }
  return <></>;
};

export const ReferenceSection = ({
  references,
  referenceStyle,
}: ReferenceSectionProps): JSX.Element => (
  <Box w={800} m="lg">
    <Box p="xs" bg="gray.2" h="60px">
      <Text variant="heading04" p="sm">
        References
      </Text>
    </Box>
    {references.map((reference: CopyEditReference, index: number) => (
      <ReferenceComponent key={index} referenceObj={reference} referenceStyle={referenceStyle} />
    ))}
  </Box>
);
