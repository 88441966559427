import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { Box } from '../design-system/v2';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const CopyEditPage = lazyWithRetry(() => import('../pages/AppStore/DocumentAI/CopyEdit/CopyEdit'));
const CopyEditDetailsPage = lazyWithRetry(
  () => import('../pages/AppStore/DocumentAI/CopyEdit/CopyEditDetails'),
);

const copyEditRoutes: RouteProps[] = [
  {
    path: `${getRoute(AppRoutes.APP_STORE_PROJECT)}/${getRoute(
      AppRoutes.APP_STORE_DOCUMENT_DETAILS,
    )}/*`,
    element: <CopyEditDetailsPage />,
  },
  {
    path: '/*',
    element: <CopyEditPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const CopyEditRouter = (): JSX.Element => (
  // Adding background gray.1 to the whole of copy-edit as per design request
  <Box h="100%" w="100%" bg="gray.1">
    <Routes>{copyEditRoutes.map(renderAuthRoute)}</Routes>
  </Box>
);
