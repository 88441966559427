import { useAuth } from '../../../contexts/auth-provider/Auth';
import { Button } from '../../../design-system/v2';
import { resetChecksum } from '../../../initializers/checksum';
import { isDevelopment } from '../../../lib/env.util';

interface LogoutButtonProps {
  text?: string;
}

export const useLogout = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    if (isDevelopment()) {
      resetChecksum();
    }

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return handleLogout;
};

export const LogoutButton = ({ text = 'Logout' }: LogoutButtonProps): JSX.Element => {
  const handleOnClickLogout = useLogout();

  return (
    <Button variant="subtle" onClick={handleOnClickLogout} aria-label="Logout button">
      {text}
    </Button>
  );
};
