import { Extension } from '@tiptap/react';

// Define an interface for the extension options
export interface SupportAttributesOptions {
  types: string[];
  attributes: { [key: string]: string | null };
}

export const SupportAttributes = Extension.create<SupportAttributesOptions>({
  name: 'attributes',

  priority: 100,

  addOptions() {
    return {
      types: [],
      attributes: {},
    };
  },

  addGlobalAttributes() {
    const globalAttributes = [];

    for (const [attributeName, defaultValue] of Object.entries(this.options.attributes)) {
      const attributeConfig = {
        types: this.options.types,
        attributes: {
          [attributeName]: {
            default: defaultValue,
            parseHTML: (element: HTMLElement) => element.getAttribute(attributeName),
            renderHTML: (attributes: { [key: string]: string | null }) => {
              if (!attributes[attributeName]) {
                return {};
              }

              return {
                [attributeName]: attributes[attributeName],
              };
            },
          },
        },
      };

      globalAttributes.push(attributeConfig);
    }

    return globalAttributes;
  },
});
