import { Node, mergeAttributes } from '@tiptap/core';
import { sanitizeStyleAttr } from '../text-style/extention';

export interface TextStyleOptions {
  HTMLAttributes: Record<string, any>;
}

export const PageContainerStyle = Node.create<TextStyleOptions>({
  name: 'pageContainer',

  content: 'block+',

  // This will cause issue once we introduce page breaks
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      'data-id': {
        default: null,
      },
      class: {
        default: null,
      },
      style: {
        default: null,
        parseHTML: element => element.getAttribute('style'),
        renderHTML: attributes => {
          if (!attributes.style) {
            return null;
          }
          return {
            style: sanitizeStyleAttr(attributes.style),
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
