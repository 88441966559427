import {
  AccountInfo,
  AccountOwnerType,
  DeploymentInfo,
  DeploymentMode,
  WorkspaceMember,
  WorkspaceMembershipRoles,
} from '../../generated/api';

export const getWorkspaceOwnerAndAdmins = (
  accountDetails: AccountInfo,
  workspaceMembers: WorkspaceMember[],
) => {
  const { accountType, enterpriseInfo, userAccountInfo } = accountDetails;
  const {
    enterpriseName = '',
    enterpriseDomain = '',
    enterpriseAvatar = '',
    accountId = '',
  } = enterpriseInfo ?? {};
  const { userDetails } = userAccountInfo ?? {};

  const workspaceOwner =
    accountType === AccountOwnerType.Enterprise
      ? {
          name: enterpriseName,
          domain: enterpriseDomain,
          avatar: enterpriseAvatar,
          userId: accountId,
        }
      : {
          name: userDetails?.name ?? '',
          domain: userDetails?.email ?? '',
          avatar: userDetails?.avatar ?? '',
          userId: userDetails?.userId ?? '',
        };

  const workspaceAdmins = workspaceMembers.filter(
    member =>
      member.workspaceMembershipRole === WorkspaceMembershipRoles.Owner &&
      member?.userId !== workspaceOwner?.userId,
  );

  return { workspaceOwner, workspaceAdmins };
};

export const checkHybridDeployment = (deploymentInfo: DeploymentInfo) =>
  deploymentInfo?.deploymentMode === DeploymentMode.HybridDeployment;
