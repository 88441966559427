import { createStyles, getStylesRef } from '@mantine/styles';
import { ClusterCounts } from '../../../../queries/datasets/clustering/clusterSelector';
import { PointState, getLabelColor } from '../util';

const LABEL_MAX_WIDTH = 240;

interface LabelChipParams {
  label: string;
  labelsCount: ClusterCounts[];
  labelColor: string;
}

export const useStyles = createStyles(
  (theme, { label, labelsCount, labelColor }: LabelChipParams) => {
    const color = labelColor ?? getLabelColor(String(label), labelsCount, PointState.ACTIVE);
    return {
      label: {
        fontWeight: 600,
        backgroundColor: theme.white,
        border: `1px solid #E4E5E7`,
        color,
        /* maxWidth & textOverflow needed for user-generated labels */
        maxWidth: LABEL_MAX_WIDTH,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',

        '&[data-checked]:not([data-disabled])': {
          backgroundColor: color,
          color: theme.white,

          '&:hover': {
            backgroundColor: color,
            color: theme.fn.themeColor('dark.3'),

            [`& .${getStylesRef('iconWrapper')}`]: {
              color: theme.fn.themeColor('dark.3'),
            },
          },

          [`& .${getStylesRef('iconWrapper')}`]: {
            color: theme.white,
          },
        },
      },

      iconWrapper: {
        ref: getStylesRef('iconWrapper'),
      },
    };
  },
);
