import { getVizIdFromVizModel } from '../../../../../../../components/snippets/detail/add-charts/hooks/util';
import { PublicSnippetContextProvider } from '../../../../../../../components/snippets/detail/context/PublicSnippetContext';
import { VisualizationFactory, VisualizationFactoryProps } from './VisualizationFactory';

export const PublicSnippetVizFactory = (props: VisualizationFactoryProps) => {
  const { visualizationType, visualizationParameters } = props;
  const chartId = getVizIdFromVizModel({ visualizationType, visualizationParameters } as any);

  return (
    <PublicSnippetContextProvider chartId={chartId}>
      <VisualizationFactory {...props} />
    </PublicSnippetContextProvider>
  );
};
