import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  Checkbox,
  Horizontal,
  ScrollArea,
  Select,
  SelectItem,
  Text,
  TextInput,
  Vertical,
} from '../../../../design-system/v2';
import {
  AppIdType,
  GetCustomDictionaryResponse,
  StyleGuideRuleSetResponse,
  StyleGuideType,
} from '../../../../generated/api';
import { StyleGuideMap } from '../copy-edit/settings/constants';

interface DocumentUserInputsProps {
  variant: AppIdType;

  customStyleRules: StyleGuideRuleSetResponse[];
  selectedCustomStyleRule?: string[];
  setSelectedCustomStyleRule?: (styleRules: string[]) => void;

  dictionaryRules: GetCustomDictionaryResponse[];
  selectedDictionaryRules?: string[];
  setSelectedDictionaryRules?: (dictionaryRules: string[]) => void;

  selectedStyleGuide?: StyleGuideType;
  setSelectedStyleGuide?: (styleGuide: StyleGuideType) => void;

  documentName: string;
  setDocumentName: (value: string | React.ChangeEvent<any> | null | undefined) => void;

  selectSupportedLlmTypesOptions: (string | SelectItem)[];
  selectedLlm: string;
  setSelectedLlm: (value: string | React.ChangeEvent<any> | null | undefined) => void;

  handleButtonClick: () => void;
  disableButtonClick: boolean;
  buttonText: string;
}

export const DocumentUserInputs = ({
  variant,
  customStyleRules,
  selectedCustomStyleRule,
  setSelectedCustomStyleRule,
  dictionaryRules,
  selectedDictionaryRules,
  setSelectedDictionaryRules,
  selectedStyleGuide,
  setSelectedStyleGuide,
  documentName,
  setDocumentName,
  selectSupportedLlmTypesOptions,
  selectedLlm,
  setSelectedLlm,
  handleButtonClick,
  disableButtonClick,
  buttonText,
}: DocumentUserInputsProps): JSX.Element => {
  const { featureStoreModelSelection } = useFlags();

  const styleGuideList = Object.keys(StyleGuideMap).map(item => ({
    label: StyleGuideMap[item as keyof typeof StyleGuideMap],
    value: item,
  }));

  const documentNameError = !/^[a-zA-Z0-9\-\_\.\ ]*$/.test(documentName) // Allow only a-z, A-Z, 0-9, -, ., _,
    ? 'Special characters are not allowed'
    : '';

  return (
    <>
      <ScrollArea.Autosize mah={500} mih={220}>
        <TextInput
          ariaLabel="Document Name="
          label="Document Name"
          placeholder="Title"
          value={documentName}
          onChange={setDocumentName}
          error={documentNameError}
          required
        />
        {variant === AppIdType.CopyEdit && (
          <Vertical>
            {featureStoreModelSelection && (
              <Select
                ariaLabel="Select model"
                label="Select model"
                placeholder="Select model"
                options={selectSupportedLlmTypesOptions}
                value={selectedLlm}
                onChange={setSelectedLlm}
                withinPortal
                dropdownPosition="bottom"
              />
            )}
            <Select
              ariaLabel="Select style guide"
              label="Select style guide"
              options={styleGuideList}
              value={selectedStyleGuide}
              onChange={setSelectedStyleGuide}
              withinPortal
              dropdownPosition="bottom"
            />
            {customStyleRules.length ? (
              <Checkbox.Group
                value={selectedCustomStyleRule}
                onChange={setSelectedCustomStyleRule}
                label={
                  <Text variant="subTitle04" py="sm">
                    Add custom style rules
                  </Text>
                }
                aria-label="Add custom style rules"
              >
                <Vertical spacing="sm">
                  {customStyleRules.map(rule => (
                    <Checkbox
                      key={rule.ruleSetId}
                      value={rule.ruleSetId}
                      label={rule.ruleSetName}
                    />
                  ))}
                </Vertical>
              </Checkbox.Group>
            ) : null}
            {dictionaryRules.length ? (
              <Checkbox.Group
                value={selectedDictionaryRules}
                onChange={setSelectedDictionaryRules}
                label={
                  <Text variant="subTitle04" py="sm">
                    Attach custom dictionary
                  </Text>
                }
                aria-label=" Attach custom dictionary"
              >
                <Vertical spacing="sm">
                  {dictionaryRules.map(rule => (
                    <Checkbox
                      key={rule.dictionaryId}
                      value={rule.dictionaryId}
                      label={rule.dictionaryName}
                    />
                  ))}
                </Vertical>
              </Checkbox.Group>
            ) : null}
          </Vertical>
        )}
      </ScrollArea.Autosize>
      <Horizontal pt={32} position="right">
        <Button
          onClick={handleButtonClick}
          variant="primary"
          disabled={disableButtonClick || !!documentNameError}
          aria-disabled={disableButtonClick}
        >
          {buttonText}
        </Button>
      </Horizontal>
    </>
  );
};
