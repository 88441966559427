import { rem } from '@mantine/styles';
import { PropsWithChildren } from 'react';
import { Center, Grid, Image, ImageProps, Text } from '../../design-system/v2';
import { S3_STORAGE_BASE } from '../../lib/env.util';
import { S3_ILLUSTRATIONS_MAP } from '../../lib/resource-map';

interface FeatureVisualsProps {
  text: string;
  image: string;
  imageProps?: ImageProps;
}

/**
 * This component is used to show the screens when user first lands
 * on the app, so that there is an illustrative description of what
 * the feature is
 */
export const FeatureVisuals = ({
  text,
  image,
  imageProps,
}: PropsWithChildren<FeatureVisualsProps>): JSX.Element => {
  const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/${S3_ILLUSTRATIONS_MAP.FEATURE_VISUALS}/${image}.svg`;

  return (
    <Grid bg="blue.0">
      <Grid.Col xs={10}>
        <Center h="100%" p="lg">
          <Text variant="heading03" py="lg">
            {text}
          </Text>
        </Center>
      </Grid.Col>
      <Grid.Col xs={10}>
        <Image
          src={ILLUSTRATION_URL}
          withPlaceholder
          placeholder={<></>}
          maw={rem(692)}
          mah={rem(335)}
          radius="md"
          {...imageProps}
        />
      </Grid.Col>
    </Grid>
  );
};
