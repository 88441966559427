import { IconDotsVertical } from '@tabler/icons-react';
import { FullPageLoader } from '../../../../../../../design-system';
import {
  ActionIcon,
  Center,
  Menu,
  Text,
  notifications,
  openModal,
} from '../../../../../../../design-system/v2';
import { AddStyleGuideRuleRequest } from '../../../../../../../generated/api';
import { useDeleteStyleGuideCustomRuleMutation } from '../../../../../../../queries/document-ai/copyEdit';
import { AddEditCustomRuleModal } from './AddEditCustomRule';

interface CustomRulesActionsProps {
  ruleId: string;
  ruleSetId: string;
  data: AddStyleGuideRuleRequest;
}

export const CustomRulesActions = ({ ruleId, ruleSetId, data }: CustomRulesActionsProps) => {
  const { mutate: deleteRule, isLoading } = useDeleteStyleGuideCustomRuleMutation(ruleSetId);

  const handleDeleteRule = () => {
    deleteRule(ruleId, {
      onSuccess: () => {
        notifications.success('Rule Deleted Successfully!');
      },
      onError: () => {
        notifications.error('Not able to delete rule');
      },
    });
  };

  const handleEditRule = () => {
    openModal({
      size: 'xl',
      title: <Text variant="heading03">Edit Rule</Text>,
      children: <AddEditCustomRuleModal ruleSetId={ruleSetId} ruleId={ruleId} data={data} />,
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}
      <Menu shadow="md" withinPortal>
        <Menu.Target>
          <Center h="100%">
            <ActionIcon>
              <IconDotsVertical />
            </ActionIcon>
          </Center>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={handleDeleteRule}>
            <Text variant="bodyShort03" align="center">
              Delete
            </Text>
          </Menu.Item>
          <Menu.Item onClick={handleEditRule}>
            <Text variant="bodyShort03" align="center">
              Edit
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
