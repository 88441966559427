import { Badge, Text, useMarkovTheme } from '../../../../design-system/v2/';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { WorkflowRun, WorkflowScheduleStatus } from '../../../../generated/api';

const useBadgeColor = (status: WorkflowScheduleStatus) => {
  const theme = useMarkovTheme();

  switch (status) {
    case WorkflowScheduleStatus.Scheduled:
      return theme.colors.blue;
    case WorkflowScheduleStatus.Paused:
      return theme.colors.yellow;
    default:
      return theme.colors.gray;
  }
};

export const WorkflowRunTypeRenderer = ({
  value: status,
}: ICellRendererParams<WorkflowRun, WorkflowScheduleStatus>) => {
  const color = useBadgeColor(status);
  if (status === WorkflowScheduleStatus.NoScheduleSet)
    return (
      <Text variant="small03" color="gray.6" pt="lg">
        No schedule set
      </Text>
    );

  return (
    <Badge
      variant="outline"
      sx={{ color: color[7], borderColor: color[3], backgroundColor: color[1] }}
    >
      <Text variant="small03">{status}</Text>
    </Badge>
  );
};
