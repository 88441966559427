import { PropsWithChildren } from 'react';
import { Center, Text, Vertical } from '../../../../design-system/v2';

interface EmptyPreviewVerticalProps {
  title: string;
  description: string;
  illustration: JSX.Element;
}

export const EmptyPreviewVertical = ({
  title,
  description,
  illustration,
  children,
}: PropsWithChildren<EmptyPreviewVerticalProps>): JSX.Element => (
  <Vertical p={0}>
    <Center h="100%" p="xl" bg="#EDF3FD">
      {illustration}
    </Center>
    <Vertical px="lg" pb="md">
      <Text w="100%" variant="subTitle02" tt="capitalize" align="left">
        {title}
      </Text>
      <Text variant="bodyShort01" color="dark.3">
        {description}
      </Text>
      {children}
    </Vertical>
  </Vertical>
);
