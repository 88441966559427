import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppMetadata } from '../../../../../../contexts/app-metadata/AppMetadata';
import { OperatorCategory } from '../../../../../../generated/api';
import { NodeSchemaPreviewContainer } from './node-schema-preview/NodeSchemaPreview.container';
import { WorkflowDagNodeSchemaContainer } from './node-schema-preview/WorkflowDagNodeSchema.container';

interface OperatorDataPreviewContainerProps {
  workflowId: string;
  runId?: string;
  nodeId: string;
  prevNodeId?: string;
  operatorCategory?: OperatorCategory;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  operatorId: string;
  isFormDirty?: boolean;
}

export const OperatorDataPreviewContainer = ({
  workflowId,
  runId,
  nodeId,
  prevNodeId,
  operatorCategory,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  operatorId,
  isFormDirty,
}: OperatorDataPreviewContainerProps) => {
  const { featureDagNodeSchemaV2 } = useFlags();
  const { workspaceId } = useAppMetadata();
  const currentRunId = runId || `${workspaceId}-${workflowId}-debug-run`;

  if (featureDagNodeSchemaV2) {
    return (
      <WorkflowDagNodeSchemaContainer
        workflowId={workflowId}
        nodeId={nodeId}
        runId={runId}
        prevNodeId={prevNodeId}
        operatorCategory={operatorCategory}
        selectedColumns={selectedColumns ?? []}
        setSelectedColumns={setSelectedColumns}
        allowColumnSelection={allowColumnSelection}
        isFormDirty={isFormDirty}
      />
    );
  }

  return (
    <NodeSchemaPreviewContainer
      workflowId={workflowId}
      nodeId={nodeId}
      runId={currentRunId}
      prevNodeId={prevNodeId}
      operatorCategory={operatorCategory}
      selectedColumns={selectedColumns ?? []}
      setSelectedColumns={setSelectedColumns}
      allowColumnSelection={allowColumnSelection}
      operatorId={operatorId}
      isFormDirty={isFormDirty}
    />
  );
};
