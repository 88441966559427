import { RouteProps, Routes } from 'react-router-dom';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const DataSourcesPage = lazyWithRetry(() => import('../pages/DataSources/DataSources'));
const ConnectorDetailsPage = lazyWithRetry(
  () => import('../pages/DataSources/Connectors/ConnectorDetails'),
);

const dataSourcesRoutes: RouteProps[] = [
  {
    path: '/',
    element: <DataSourcesPage />,
  },
  {
    // TODO: Simplify this
    path: `${getRoute(AppRoutes.CONNECTORS)}/${getRoute(AppRoutes.CONNECTOR_DETAILS)}`,
    element: <ConnectorDetailsPage />,
  },
];

export const DataSourcesRouter = (): JSX.Element => (
  <Routes>{dataSourcesRoutes.map(renderAuthRoute)}</Routes>
);
