import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import { AudioPlay } from '../extensions/controls/audio/Audio';
import { Comment } from '../extensions/controls/comment/Comment';
import { Paragraph } from '../extensions/controls/paragraph/Paragraph';
import { Translate } from '../extensions/controls/translate/Translate';

interface SelectionBubbleMenuProps {
  onClickCommentControl: (conversationId: string) => void;
  onClickAudioControl: (text: string) => void;
  onClickAudioStopControl: () => void;
  isLoadingAudio: boolean;
  isPlayingAudio: boolean;
  onClickTranslateControl: (text: string) => void;
  showHeadingControls: boolean;
  showCommentControls: boolean;
  showLinkControls: boolean;
  showFormattingControls: boolean;
  showAudioControls: boolean;
  showTranslateControls: boolean;
}

export const SelectionBubbleMenu = ({
  onClickCommentControl,
  onClickAudioControl,
  onClickAudioStopControl,
  isLoadingAudio,
  isPlayingAudio,
  onClickTranslateControl,
  showHeadingControls,
  showCommentControls,
  showLinkControls,
  showFormattingControls,
  showAudioControls,
  showTranslateControls,
}: SelectionBubbleMenuProps) => {
  const { editor } = useRichTextEditorContext();
  const [visible, setVisible] = useState(true);
  const bubbleMenuRef = useRef<HTMLDivElement>(null);

  // Focus the first interactive element when the bubble menu becomes visible
  useEffect(() => {
    if (editor?.isFocused && bubbleMenuRef.current) {
      const firstButton = bubbleMenuRef.current.querySelector('button');
      if (firstButton) {
        firstButton.focus();
      }
    }
  }, [editor?.isFocused]);

  const shouldShow = ({
    state,
    from,
    to,
    view,
  }: {
    editor: Editor;
    view: EditorView;
    state: EditorState;
    oldState?: EditorState;
    from: number;
    to: number;
  }) => {
    const { doc, selection } = state;
    const { empty, $head } = selection;

    const isChartGridBlock = editor.isActive('draggableBlock', {
      'data-blocktype': 'chart-grid',
    });

    const isEmbeddingChartBlock = editor.isActive('draggableBlock', {
      'data-blocktype': 'embedding-chart',
    });

    if (isChartGridBlock || isEmbeddingChartBlock) {
      return false;
    }

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const isEmptyTextBlock = !doc.textBetween(from, to).length;

    // // When clicking on a element inside the bubble menu the editor "blur" event
    // // is called and the bubble menu item is focussed. In this case we should
    // // consider the menu as part of the editor and keep showing the menu
    // const isChildOfMenu = this.element.contains(document.activeElement);

    const hasEditorFocus = view.hasFocus(); /*|| isChildOfMenu*/

    if (!hasEditorFocus || empty || isEmptyTextBlock || !editor.isEditable) {
      return false;
    }

    return true;
  };

  return (
    <BubbleMenu editor={editor} tippyOptions={{ maxWidth: 900 }} shouldShow={shouldShow}>
      <div ref={bubbleMenuRef} role="menu" aria-label="Editor options">
        <RichTextEditor.Toolbar>
          {showHeadingControls && (
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <Paragraph />
            </RichTextEditor.ControlsGroup>
          )}

          {showFormattingControls && (
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
              <RichTextEditor.ClearFormatting />
            </RichTextEditor.ControlsGroup>
          )}

          {showLinkControls && (
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          )}

          {showCommentControls && (
            <RichTextEditor.ControlsGroup>
              <Comment onClickCommentControl={onClickCommentControl} />
            </RichTextEditor.ControlsGroup>
          )}
          {showAudioControls && (
            <RichTextEditor.ControlsGroup>
              <AudioPlay
                onClickAudioControl={onClickAudioControl}
                onClickAudioStopControl={onClickAudioStopControl}
                isLoadingAudio={isLoadingAudio}
                isPlayingAudio={isPlayingAudio}
              />
            </RichTextEditor.ControlsGroup>
          )}
          {showTranslateControls && (
            <RichTextEditor.ControlsGroup>
              <Translate
                onClickTranslateControl={onClickTranslateControl}
                setVisible={setVisible}
              />
            </RichTextEditor.ControlsGroup>
          )}
        </RichTextEditor.Toolbar>
      </div>
    </BubbleMenu>
  );
};
