import { TypographyStylesProvider, TypographyStylesProviderProps } from '@mantine/core';
import { useStyles } from './HTMLPreview.styles';

export interface HTMLPreviewProps extends Omit<TypographyStylesProviderProps, 'children'> {
  /**
   * The HTML content to be rendered.
   */
  children: string | TrustedHTML;
}

/**
 * HTMLPreview Component
 *
 * This component wraps the Mantine TypographyStylesProvider component
 * and safely renders HTML content using `dangerouslySetInnerHTML`.
 *
 */
export const HTMLPreview = ({ children, ...rest }: HTMLPreviewProps): JSX.Element => {
  const { classes } = useStyles();
  return (
    <TypographyStylesProvider className={classes.provider} {...rest}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </TypographyStylesProvider>
  );
};
