import {
  ControlProps,
  OwnPropsOfEnum,
  RankedTester,
  isOneOfControl,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsOneOfEnumProps } from '@jsonforms/react';
import { Checkbox, Text, Vertical } from '../../../../../design-system/v2';

const CheckboxGroupControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  schema,
  options = [],
  config,
  enabled,
}: ControlProps & OwnPropsOfEnum) => {
  const schemaValues = options.map(obj => obj.value);

  const isViewOnlyForm = config.viewOnly;

  const onChange = (values: string[]) => {
    handleChange(path, values);
  };

  return (
    <Checkbox.Group
      label={
        <Text span variant="subTitle02">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      placeholder={uischema.options?.placeholder}
      withAsterisk={required}
      value={data}
      onChange={onChange}
    >
      <Vertical spacing="lg" pt="md">
        {options.map((enumItem, idx) => (
          <Checkbox
            key={idx}
            value={enumItem.value}
            label={enumItem.label}
            // Mantine does not forward readonly prop to Checkbox, so have to use disabled state here.
            // One improvement can be to style the checkbox here itself
            disabled={!enabled}
          />
        ))}
      </Vertical>
    </Checkbox.Group>
  );
};

export const checkboxGroupControlTester: RankedTester = rankWith(5, isOneOfControl);
export const CheckboxGroupControl = withJsonFormsOneOfEnumProps(CheckboxGroupControlComponent);
