import { MantineSize, MantineTheme } from '@mantine/core';
import { ThemeComponent } from '../../../utils/types';
import { fontWeights, lineHeight, textVariantToStyle } from '../../typography/constants';
import { dropdown, getItemStyles, itemsWrapper } from '../base-inputs/BaseSelect.style';

export const multiSelectTheme: ThemeComponent = {
  styles: (theme: MantineTheme, _params: any) => ({
    dropdown,

    value: {
      borderRadius: '28px',
      fontSize: `${theme.fontSizes[textVariantToStyle.bodyShort03.fontSize as MantineSize]}`,
      fontWeight: fontWeights[textVariantToStyle.bodyShort03.fontWeight],
      lineHeight: `${lineHeight[textVariantToStyle.bodyShort03.lineHeight]}`,
    },
    itemsWrapper,
    item: getItemStyles(theme),

    // FIXME:: @DESIGN_SYSTEM Added temporary sizes. fix with size prop
    input: {
      fontSize: '16px',
    },
  }),
};
