import { PropsWithChildren } from 'react';
import { Box, Center, Grid, Text, Vertical } from '../../../../design-system/v2';

interface EmptyPreviewHorizontalProps {
  title: string;
  description: string;
  illustration: JSX.Element;
}

export const EmptyPreviewHorizontal = ({
  title,
  description,
  illustration,
  children,
}: PropsWithChildren<EmptyPreviewHorizontalProps>): JSX.Element => (
  <Grid>
    <Grid.Col xs={8} sm={6} xl={5}>
      <Center h="100%" p="xl" bg="#EDF3FD">
        {illustration}
      </Center>
    </Grid.Col>
    <Grid.Col span="auto" py="lg">
      <Vertical spacing="sm" p="xl">
        <Text variant="subTitle01" tt="capitalize">
          {title}
        </Text>
        <Text variant="bodyShort01" color="gray.6">
          {description}
        </Text>
        <Box pt="lg">{children}</Box>
      </Vertical>
    </Grid.Col>
  </Grid>
);
