import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { EmbeddingChart } from './EmbeddingChart';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embeddingChartBlock: {
      setNewConversationIdInEmbeddingBlock: (conversationId: string) => ReturnType;
      removeConversationIdFromEmbeddingBlock: () => ReturnType;
    };
  }
}

export const EmbeddingChartBlock = Node.create({
  name: 'embeddingChartBlock',

  group: 'chartBlock',

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => ({
          'data-blocktype': 'embedding-chart',
          'data-dataset-id': (el as HTMLElement).getAttribute('data-dataset-id'),
          'data-conversation-id': (el as HTMLElement).getAttribute('data-conversation-id') ?? '',
          'data-embedding-subset-id':
            (el as HTMLElement).getAttribute('data-embedding-subset-id') ?? '',
        }),
      },
    ];
  },

  addAttributes() {
    return {
      'data-blocktype': {
        default: 'embedding-chart',
      },
      'data-embedding-id': {
        default: '',
      },
      'data-dataset-id': {
        default: '',
      },
      'data-conversation-id': {
        default: '',
      },
      'data-embedding-subset-id': {
        default: '',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbeddingChart);
  },

  addCommands() {
    return {
      setNewConversationIdInEmbeddingBlock:
        (conversationId: string) =>
        ({ chain }) =>
          chain()
            .selectParentNode()
            .updateAttributes(this.name, {
              'data-conversation-id': conversationId,
            })
            .run(),

      removeConversationIdFromEmbeddingBlock:
        () =>
        ({ chain }) =>
          chain()
            .selectParentNode()
            .updateAttributes(this.name, {
              'data-conversation-id': '',
            })
            .run(),
    };
  },
});
