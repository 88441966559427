import first from 'lodash/first';
import { useEffect, useState } from 'react';
import {
  AnalysisFilter,
  Mode,
  useAnalysisComparison,
} from '../../../../../contexts/datasets/comparison/AnalysisComparison';
import { Vertical } from '../../../../../design-system/v2';
import { Draggable } from '../../../../../design-system/v2/drag-n-drop/Draggable';
import { SortableList } from '../../../../../design-system/v2/drag-n-drop/sortable-list';
import { useListState } from '../../../../../design-system/v2/hooks';
import { AnalysisGroupedByMetricModel } from '../../../../../generated/api';
import { AnalyserVisualizationContainer } from '../../../../analyser/analyser-visualization/AnalyserVisualization.container';
import { getAnalysersFromGroupedMetrics } from '../../../../analyser/analyser.util';
import { AnalysisExpandModal } from '../../../../dataset-details/analysis-summary-v2/analysis-grid-panel/expand-modal/AnalysisExpandModal';

export const getFlatAnalysisList = (
  analysisGroupByMetric: AnalysisGroupedByMetricModel[],
  selectedAnalyser: string,
) => {
  const analysers = getAnalysersFromGroupedMetrics(analysisGroupByMetric);
  const segments =
    analysers.find(analyser => analyser.metricName === selectedAnalyser)?.segments ?? [];
  const flatAnalysisList = segments.flatMap(segment => segment.metadata);

  return flatAnalysisList;
};

interface AnalysisColumnProps {
  datasetId: string;
  analysisGroupByMetric: AnalysisGroupedByMetricModel[];
  datasetFilter: AnalysisFilter[];
}

export const AnalysisColumn = ({
  datasetId,
  analysisGroupByMetric,
  datasetFilter,
}: AnalysisColumnProps) => {
  const { mode, selectedAnalyser } = useAnalysisComparison();
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | undefined>();

  const flatAnalysisList = getFlatAnalysisList(analysisGroupByMetric, selectedAnalyser);

  const [analysisList, handlers] = useListState(flatAnalysisList);

  useEffect(() => {
    handlers.setState(getFlatAnalysisList(analysisGroupByMetric, selectedAnalyser));
  }, [selectedAnalyser]);

  const handleDragEnd = (from: number, to: number) => {
    handlers.reorder({ from, to });
  };

  const handleCarouselClose = () => {
    setSelectedItemIdx(undefined);
  };

  const vizs = analysisList.map((mtData, idx) => {
    const datasetFilterSegment = datasetFilter.find(
      datasetFilterSegment => datasetFilterSegment.segment === mtData.segmentType,
    );
    const mtDataClass = first(mtData.classes);
    const datasetFilterSegmentClass = datasetFilterSegment?.classNames.find(
      className => className.label === mtDataClass,
    );

    const handleExpand = () => {
      setSelectedItemIdx(idx);
    };

    const vizContainer = (
      <AnalyserVisualizationContainer
        key={mtData.analysisId}
        analysis={mtData}
        showTitle
        collapsible
        mode={mode === Mode.VIEW ? 'open' : 'collapsed'}
        onClickExpand={handleExpand}
        expandable
      />
    );

    if (mode === Mode.RE_ORDER) {
      return (
        <Draggable index={idx} id={mtData.analysisId} key={mtData.analysisId}>
          {vizContainer}
        </Draggable>
      );
    }

    if (datasetFilterSegmentClass?.checked) {
      return vizContainer;
    }
  });

  return (
    <Vertical>
      {mode === Mode.RE_ORDER ? (
        <SortableList droppableId={`dnd-list-${datasetId}`} onDragEnd={handleDragEnd}>
          {vizs}
        </SortableList>
      ) : (
        vizs
      )}
      <AnalysisExpandModal
        list={flatAnalysisList}
        currentIndex={selectedItemIdx}
        onCloseCarousel={handleCarouselClose}
      />
    </Vertical>
  );
};
