import { Title, TitleProps } from '@mantine/core';
import { FontWeight, HeadingVariants, fontWeights, headingVariantToStyle } from '../constants';

interface HeadingProps extends Omit<TitleProps, 'variant'> {
  variant: HeadingVariants;
}

export const Heading = ({ variant, children, ...rest }: HeadingProps) => {
  const variantStyle = headingVariantToStyle[variant];

  return (
    <Title
      order={variantStyle?.order}
      weight={fontWeights[variantStyle?.fontWeight ?? FontWeight.REGULAR]}
      lts={variantStyle?.letterSpacing}
      {...rest}
    >
      {children}
    </Title>
  );
};
