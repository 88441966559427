import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { notifications } from '../../design-system/v2';
import { logger } from '../../initializers/logging';
import { snippetsApi } from '../../lib/api';
import { axiosS3 } from '../../lib/api/api';

interface MediaUploadProps {
  snippetId: string;
  onSuccess: ({ src, file }: { src: string; file: File | null }) => void;
  file: File | null;
}

export const S3_URL = import.meta.env.VITE_APP_S3_ASSETS_URL;
export const CDN_URL = import.meta.env.VITE_APP_CDN_URL;
const MAX_RETRIES = 0;

const getUniqueFileName = () => uuid();

export const getPathParams = (snippetId: string, fileName: string) => {
  let path = `snippets/:snippetId/:fileName`;

  path = path.replace(':snippetId', snippetId);
  path = path.replace(':fileName', fileName);

  return path;
};

export const getReadUrl = (path: string) => `${CDN_URL}/${path}`;

const uploadFileToS3 = async (url: string, file: File) => {
  try {
    const response = await axiosS3.put(url, file, {
      headers: { 'Content-Type': file.type },
    });
    if (response.status !== 200) {
      throw new Error('Failed to upload asset on S3');
    }
  } catch (err) {
    logger.error(`Error uploading snippet asset on CDN: ${JSON.stringify(err)}`);
  }
};

export const useMediaUpload = ({ snippetId, onSuccess, file }: MediaUploadProps) => {
  const retryOnError = useRef(MAX_RETRIES);
  const { workspaceId } = useAppMetadata();

  const uploadFile = useCallback(
    async (fileName: string) => {
      if (!file) {
        return;
      }

      try {
        const objectPath = getPathParams(snippetId, fileName);
        const {
          data: { uploadUrl = '' },
        } = await snippetsApi.createSnippetsAssetsUploadUrlV1(workspaceId, {
          objectPath,
        });

        if (!uploadUrl) {
          throw new Error(`Failed to fetch presigned URL: SnippetId: ${snippetId}`);
        }

        await uploadFileToS3(uploadUrl, file);

        if (file?.type?.includes('image')) {
          const img = new Image();
          const cdnUrl = getReadUrl(objectPath);
          img.src = cdnUrl;

          img.onload = () => {
            onSuccess({ src: fileName, file: null });
          };

          img.onerror = () => {
            if (retryOnError.current > 0) {
              retryOnError.current--;
              setTimeout(() => uploadFile(fileName), 1000);
            } else {
              onSuccess({ src: fileName, file: null });
              notifications.show({
                variant: 'error',
                message: 'Error loading snippet asset',
              });
            }
          };
        } else {
          onSuccess({ src: fileName, file: null });
        }
      } catch (err) {
        logger.error(`Error uploading snippet asset: ${JSON.stringify(err)}`);
      }
    },
    [file, onSuccess, snippetId, workspaceId],
  );

  useEffect(() => {
    const fileName = getUniqueFileName();
    uploadFile(fileName);
  }, [snippetId, uploadFile]);
};
