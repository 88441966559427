import { toLower } from 'lodash';
import {
  Button,
  Horizontal,
  Text,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
} from '../../../../design-system/v2';
import { WorkflowStatus } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { useUpdateScheduledWorkflowStateMutation } from '../../../../queries/workflows/debug';

interface UpdateScheduleStatusModalProps {
  workflowId: string;
  workflowStatus: WorkflowStatus;
}

const UpdateScheduleStatusModal = ({
  workflowId,
  workflowStatus,
}: UpdateScheduleStatusModalProps): JSX.Element => {
  const displayText = workflowStatus === WorkflowStatus.Enabled ? 'Pause' : 'Resume';

  const {
    mutateAsync: updateScheduleStatus,
    isError,
    isLoading: isSaving,
  } = useUpdateScheduledWorkflowStateMutation(workflowId);

  const onClick = async () => {
    try {
      await updateScheduleStatus(
        workflowStatus === WorkflowStatus.Enabled
          ? WorkflowStatus.Disabled
          : WorkflowStatus.Enabled,
      );
      notifications.success(`Workflow schedule successfully ${toLower(displayText)}`);
      closeAllModals();
    } catch (error) {
      notifications.error(
        `Unable to ${toLower(displayText)} scheduling workflow runs. Please try again`,
      );
      logger.error(error);
    }
  };

  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <Vertical>
      <Text variant="bodyShort02">{`Are you sure you want to ${toLower(
        displayText,
      )} workflow scheduled runs?`}</Text>
      <Horizontal mt="xl" position="right">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClick} loading={isSaving}>
          {isError ? 'Retry' : 'Confirm'}
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useUpdateScheduleStatusModal = (
  workflowId: string,
  workflowStatus: WorkflowStatus,
) => {
  const open = () =>
    openModal({
      title: (
        <Text variant="heading03">
          {workflowStatus === WorkflowStatus.Enabled ? 'Pause' : 'Resume'} schedule
        </Text>
      ),
      children: (
        <UpdateScheduleStatusModal workflowId={workflowId} workflowStatus={workflowStatus} />
      ),
    });

  return { open };
};
