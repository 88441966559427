import { createStyles } from '@mantine/styles';

const DEFAULT_TABLE_HEIGHT = 320;

export const useStyles = createStyles(
  (theme, { height = DEFAULT_TABLE_HEIGHT }: { height?: number | string }) => ({
    wrapper: {
      overflow: 'auto',
      height: height,
    },
    table: {
      display: 'table',
      width: '100%',
      // bg color shown when impromper delimiter causes table columns to break
      background: theme.colors.gray[2],
      fontSize: '0.875rem',
      lineHeight: 1.33333,
      // border-collapse and border-spacing needed to fix issue with sticky header borders:
      // https://stackoverflow.com/questions/57166162/table-headers-positionsticky-and-border-issue
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    header: {
      padding: 12,
      minWidth: 100,
      background: theme.colors.white[0],
      border: `1px solid ${theme.colors.gray[3]}`,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    cell: {
      padding: 12,
      minWidth: 100,
      background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white[0],
      border: `1px solid ${theme.colors.gray[3]}`,
    },
  }),
);
