import { IconClockHour3 } from '@tabler/icons-react';
import { CellProps, Row as ReactTableRow } from 'react-table';
import { DataTable } from '../../design-system';
import { Badge, Flex, Horizontal, Text, Vertical } from '../../design-system/v2';
import { ModelWithAppMetadata } from '../../generated/api';
import { formatDateLong, formatDateTime } from '../../lib/ui';
import { CopyToClipboardTooltip } from '../common/CopyToClipboardTooltip';
import { TableRowName } from '../common/TableRowName';
import { ModelAppActions } from './ModelAppActions';
import { ModelAppsContainerProps } from './ModelApps.container';
import { UserInfoContainer } from './UserInfo.container';
import { getModelAppStatus } from './utils';

export const MODEL_APPS_TABLE_COLS = [
  {
    Header: ' ',
    accessor: 'modelId',
    id: 'selected',
    width: 44,
    canResize: false,
    disableFilters: true,
    Cell: () => <></>,
  },
  {
    Header: 'Status',
    accessor: 'modelAppStatus',
    id: 'modelAppStatus',
    width: 160,
    Cell: ({ value: status }: CellProps<ModelWithAppMetadata>) => (
      <Badge variant="dot" color={getModelAppStatus(status).color} px="sm">
        {getModelAppStatus(status).status}
      </Badge>
    ),
  },
  {
    Header: 'App Name',
    accessor: 'modelAppName',
    id: 'modelAppName',
    width: 240,
    Cell: ({ value: name, row }: CellProps<ModelWithAppMetadata>) => (
      <Vertical spacing="xs">
        <TableRowName isSampleRecord={row.original.demoArtifact}>
          <Text variant="subTitle03" title={name} lineClamp={1}>
            {name}
          </Text>
        </TableRowName>
        <Horizontal noWrap spacing="xs" onClick={e => e.stopPropagation()}>
          <Text variant="small01" color="gray.6">
            ID: {row.original.modelId}
          </Text>
          <CopyToClipboardTooltip valueToCopy={row.original.modelId} />
        </Horizontal>
      </Vertical>
    ),
  },
  {
    Header: 'Type',
    accessor: 'modelClass',
    id: 'modelClass',
    width: 170,
    canResize: false,
    Cell: ({ value: modelClass }: CellProps<ModelWithAppMetadata>) => (
      <Badge radius="xs" color={modelClass === 'classification' ? 'purple.6' : 'seaGreen.6'}>
        <Text variant="overline">{modelClass}</Text>
      </Badge>
    ),
  },
  {
    Header: 'Duration',
    accessor: 'durationInMins',
    id: 'durationInMins',
    width: 170,
    canResize: false,
    disableFilters: true,
    Cell: ({ value: duration }: CellProps<ModelWithAppMetadata>) => (
      <Horizontal spacing="xs">
        <IconClockHour3 color="#868E96" size={16} />
        <Text pb="xs" variant="small01" color="gray.6" title={duration}>
          {duration ? `${duration} Mins` : 'N/A'}
        </Text>
      </Horizontal>
    ),
  },
  {
    Header: 'Launched on',
    accessor: 'modelAppLaunchDate',
    id: 'modelAppLaunchDate',
    width: 170,
    canResize: false,
    disableFilters: true,
    Cell: ({ value: modelAppLaunchDate }: CellProps<ModelWithAppMetadata>) => (
      <Text variant="small01" color="gray.8" title={formatDateTime(modelAppLaunchDate)}>
        {modelAppLaunchDate ? formatDateLong(modelAppLaunchDate) : 'N/A'}
      </Text>
    ),
  },
  {
    Header: 'Created by',
    accessor: 'userId',
    id: 'createdBy',
    width: 190,
    canResize: false,
    disableFilters: true,
    Cell: ({ value: userId }: CellProps<ModelWithAppMetadata>) => (
      <UserInfoContainer userId={userId} />
    ),
  },
  {
    Header: '',
    accessor: 'modelAppStatus',
    id: 'modelAppActions',
    width: 250,
    canResize: false,
    disableFilters: true,
    Cell: ({ value: status, row }: CellProps<ModelWithAppMetadata>) => (
      <Flex w="100%" justify="end">
        <ModelAppActions modelId={row.original.modelId} status={status} />
      </Flex>
    ),
  },
];

interface ModelsProps extends ModelAppsContainerProps {
  modelApps: ModelWithAppMetadata[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}

export const ModelApps = ({
  modelApps,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  height,
  columns,
  onRowClick,
}: ModelsProps) => {
  const getTableRowProps = (row: ReactTableRow<Record<string, unknown>>) => ({
    isClickable: !!onRowClick,
    onClick: () => {
      onRowClick(row);
    },
  });

  const data = modelApps.map(app => ({ ...app }));

  return (
    <DataTable
      dataTestId="model-apps-table"
      data={data}
      columns={columns}
      height={height}
      getRowProps={getTableRowProps}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
