import { useState } from 'react';
import { Box, closeAllModals, openModal } from '../../../design-system/v2';
import { AsyncDropDownResources } from '../../../generated/api';
import { useInvalidateDataSourcesList } from '../../../queries/data-sources';
import { useInvalidateResourceLookup } from '../../../queries/resource-lookup';
import { FileUploader, Step } from '../../common/file-uploader/FileUploader';

export const DataSourcesFileModal = () => {
  const invalidateDataSources = useInvalidateDataSourcesList();
  const invalidateResources = useInvalidateResourceLookup(AsyncDropDownResources.DataResources);
  const [width, setWidth] = useState('30vw');

  const onUploadQueued = () => {
    closeAllModals();
  };

  const onUploadComplete = () => {
    invalidateDataSources();
    invalidateResources();
  };

  const handleStepChange = (step: Step) => {
    step === 'preview' ? setWidth('75vw') : setWidth('30vw');
  };

  return (
    <Box w={width}>
      <FileUploader
        onUploadQueued={onUploadQueued}
        onUploadComplete={onUploadComplete}
        onStepChange={handleStepChange}
      />
    </Box>
  );
};

export const useDataSourcesFileUploadModal = () => () =>
  openModal({
    title: 'Upload file',
    size: 'auto',
    children: <DataSourcesFileModal />,
  });
