import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const SummarizationPage = lazyWithRetry(
  () => import('../pages/AppStore/DocumentAI/Summarization/Summarization'),
);
const SummarizationDetailsPage = lazyWithRetry(
  () => import('../pages/AppStore/DocumentAI/Summarization/SummarizationDetails'),
);

const summarizationRoutes: RouteProps[] = [
  {
    path: '/',
    element: <SummarizationPage />,
  },
  {
    path: getRoute(AppRoutes.SUMMARIZATION_DETAIL),
    element: <SummarizationDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const SummarizationRouter = () => (
  <Routes>{summarizationRoutes.map(renderAuthRoute)}</Routes>
);
