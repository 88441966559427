import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Horizontal,
  Text,
  TextArea,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
  useInputState,
} from '../../../design-system/v2';
import { DataOperationType } from '../../../generated/api';
import { useCreateDataOperationMutation } from '../../../queries/datasets/analysis/data-workflows';
import { useDatasetDetailsQuery } from '../../../queries/datasets/list';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { isTargetUnlabeled } from '../util';

interface ManualRelabelingModalContentProps {
  datasetId: string;
}

const ManualRelabelingModalContent = ({
  datasetId,
}: ManualRelabelingModalContentProps): JSX.Element => {
  const navigate = useNavigate();
  const { getManualRelabelingRoute } = useAbsoluteRoutes();

  const datasetDetailsQuery = useDatasetDetailsQuery(datasetId);
  const { mutate: createDataOperation, isLoading: isCreating } =
    useCreateDataOperationMutation(datasetId);

  const currTarget = datasetDetailsQuery.data?.yColName ?? '';
  const isUnlabeled = isTargetUnlabeled(currTarget);
  const [newTargetName, setNewTargetName] = useInputState(isUnlabeled ? '' : currTarget);
  const [labelsString, setLabelsString] = useInputState('');

  const handleClose = () => {
    closeAllModals();
  };

  const handleCreateOperation = () => {
    createDataOperation(
      {
        dataOperationType: DataOperationType.ManualRelabeling,
        details: {
          newTargetName,
          labels: isEmpty(labelsString)
            ? undefined
            : labelsString.split(',').map(label => label.trim()),
        },
      },
      {
        onError: () => {
          notifications.error('Error creating new operation');
        },
        onSuccess: res => {
          const operationId = res.data.dataOperationId;
          const route = getManualRelabelingRoute(datasetId, operationId);
          navigate(route);
          closeAllModals();
        },
      },
    );
  };

  const infoText =
    "Assign labels to your unlabeled data, or improve data quality by auditing and correcting your dataset's current labels.";

  return (
    <Vertical>
      <Text variant="bodyShort02" color="gray.7">
        {infoText}
      </Text>
      <Vertical spacing="sm">
        <TextInput
          autoFocus
          ariaLabel="Target"
          label="Target Name"
          placeholder="Enter a name for the new target column"
          value={newTargetName}
          onChange={setNewTargetName}
        />
        {isUnlabeled && (
          <TextArea
            ariaLabel="Labels"
            label="Labels"
            value={labelsString}
            onChange={setLabelsString}
            placeholder="Enter a list of comma-separated labels, e.g. Positive, Negative, Neutral"
            minRows={2}
            maxRows={3}
          />
        )}
      </Vertical>
      <Horizontal position="right" mt="sm">
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleCreateOperation}
          disabled={isEmpty(newTargetName)}
          loading={isCreating}
        >
          Begin
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useManualRelabelingModal = () => {
  const open = (datasetId: string) =>
    openModal({
      title: (
        <Box>
          <Text variant="subTitle01">Manual Labeling</Text>
        </Box>
      ),
      size: 'lg',
      children: <ManualRelabelingModalContent datasetId={datasetId} />,
    });

  return { open };
};
