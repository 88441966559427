import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import './App.css';
import { ErrorBoundary, ErrorFallback } from './ErrorBoundary';
import { AppMetadataContextProvider } from './contexts/app-metadata/AppMetadata';
import { AuthProvider } from './contexts/auth-provider/Auth';
import { DataUploadProvider } from './contexts/data-upload/DataUpload';
import { theme } from './design-system';
import { ModalsProvider, ThemeProvider } from './design-system/v2';
import { addFlagProvider } from './initializers/flag-provider';
import { AppRouter } from './router/AppRouter';

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Wait 10 mins (delibrately increasing time as we don't have data updating issues) to invalidate cache
      staleTime: 10 * 60 * 1000,
      // Don't refetch in local.
      refetchOnWindowFocus: import.meta.env.VITE_APP_ENV !== 'local',
      // retry max 3 times
      retry: (failureCount, error) => {
        if (import.meta.env.VITE_APP_ENV === 'local') {
          return false;
        }
        if (failureCount > MAX_RETRIES) {
          return false;
        }
        if (HTTP_STATUS_TO_NOT_RETRY.includes((error as AxiosError).response?.status ?? 0)) {
          return false;
        }

        return true;
      },
    },
  },
});

const MarkovMLApp = () => (
  <BrowserRouter>
    <ErrorBoundary fallback={ErrorFallback}>
      <AuthProvider>
        <AppMetadataContextProvider>
          <QueryClientProvider client={queryClient}>
            <DataUploadProvider>
              <ThemeProvider>
                <StyledThemeProvider theme={theme}>
                  <ModalsProvider modalProps={{ centered: true, padding: 24 }}>
                    <AppRouter />
                  </ModalsProvider>
                </StyledThemeProvider>
              </ThemeProvider>
            </DataUploadProvider>
            {import.meta.env.VITE_APP_MODE === 'debug' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </AppMetadataContextProvider>
      </AuthProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

export const App = addFlagProvider(MarkovMLApp);
