import { FormEvent } from 'react';
import {
  Button,
  Horizontal,
  notifications,
  TextInput,
  useInputState,
} from '../../../../../../../design-system/v2';
import { AddWordsToCustomDictionaryRequest } from '../../../../../../../generated/api';
import { useAddCustomDictionaryWordMutation } from '../../../../../../../queries/document-ai/copyEdit';

interface AddWordProps {
  isFetchingWordList?: boolean;
  dictionaryId: string;
  existingWordList?: string[];
}

export const AddWord = ({
  isFetchingWordList,
  dictionaryId,
  existingWordList = [],
}: AddWordProps): JSX.Element => {
  const [word, setWord] = useInputState('');

  const { isLoading, mutateAsync } = useAddCustomDictionaryWordMutation(dictionaryId);

  const handleAddButtonClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const req: AddWordsToCustomDictionaryRequest = {
      dictionaryId: dictionaryId,
      customWords: [...existingWordList, word],
    };
    mutateAsync(req)
      .then(() => {
        setWord('');
      })
      .catch(() => {
        notifications.error('Unable to add the word');
      });
  };

  return (
    <form onSubmit={handleAddButtonClick}>
      <Horizontal>
        <TextInput
          w={260}
          value={word}
          onChange={setWord}
          ariaLabel="Add word to dictionary"
          placeholder="Add word to dictionary"
          disabled={isLoading || isFetchingWordList}
        />
        <Button w={100} type="submit" variant="primary" loading={isLoading || isFetchingWordList}>
          Add
        </Button>
      </Horizontal>
    </form>
  );
};
