import { CredentialModel, EditCredentialRequest, StorageType } from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { DEFAULT_ERROR_MESSAGE } from '../../../queries/constants';
import { useEditDataSourceCredential } from '../../../queries/datasets/registration';
import {
  AddEditCredentialModal,
  CredentialRequest,
} from '../../dataset-registration/add-credentials/AddEditCredentials';

interface EditCredentialContainerProps {
  credentialData: CredentialModel;
  onClose: (selectedCredential?: string, datastore?: string) => void;
}

export const EditCredentialsContainer = ({
  credentialData,
  onClose,
}: EditCredentialContainerProps): JSX.Element => {
  const {
    mutateAsync: onEditCredential,
    isLoading,
    data: dataOnSuccess,
    isSuccess,
    error,
    reset,
  } = useEditDataSourceCredential(credentialData.credentialId ?? '');

  const handleCloseModal = () => {
    const credentialId = dataOnSuccess?.data.response.credentialId;
    const datastore = dataOnSuccess?.data.response.provider;
    reset();
    onClose(credentialId, datastore);
  };

  const handleEditCredential = ({ name, description, provider }: CredentialRequest) => {
    if (!name) {
      logger.error('Save new credentials called without a name, access key/access secret');
      return;
    }
    const request: EditCredentialRequest = {
      ...credentialData,
      name: name ?? '',
      description: description ?? '',
      provider: (provider as StorageType) ?? StorageType.S3,
    };
    onEditCredential(request);
  };

  const errorMessage = error
    ? error.response?.data.message?.description ?? DEFAULT_ERROR_MESSAGE
    : '';

  return (
    <AddEditCredentialModal
      isSavingCredentials={isLoading}
      isSavingCredentialsSuccess={isSuccess}
      errorMessage={errorMessage}
      data={credentialData}
      onSaveCredentials={handleEditCredential}
      onCloseModal={handleCloseModal}
    />
  );
};
