import { ICellRendererParams } from '@ag-grid-community/core';
import { IconEye } from '@tabler/icons-react';
import { Button, Horizontal } from '../../../design-system/v2';
import { ResourceModel } from '../../../generated/api';
import { useDataSourcePreviewModal } from '../modals/DataSourcePreviewModal';

export const PreviewRenderer = ({
  value: resourceId,
  data,
}: ICellRendererParams<ResourceModel, string>) => {
  const isDataSink = data?.isDataSink ?? true;
  const { open: openPreviewModal } = useDataSourcePreviewModal(resourceId);

  return (
    <Horizontal h="100%" align="center">
      {isDataSink ? (
        <></>
      ) : (
        <Button leftIcon={<IconEye />} onClick={openPreviewModal}>
          Preview
        </Button>
      )}
    </Horizontal>
  );
};
