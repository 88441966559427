import { Anchor, AnchorProps, clsx } from '@mantine/core';
import { Link, LinkProps, To } from 'react-router-dom';
import { TextVariants } from '../../typography';
import { useTextStyles } from '../../typography/Text.style';
import { useLinkAnchorStyles } from './LinkAnchor.style';

interface LinkAnchorProps extends Omit<AnchorProps, 'variant'>, Omit<LinkProps, 'color' | 'to'> {
  external?: boolean;
  variant?: TextVariants;
  href?: string;
  to?: To;
}

export const LinkAnchor = ({
  external = false,
  variant = 'bodyShort03',
  children,
  ...rest
}: LinkAnchorProps) => {
  const textStyles = useTextStyles({ variant: variant as TextVariants });
  const styles = useLinkAnchorStyles();

  return (
    <Anchor<HTMLAnchorElement>
      component={external ? 'a' : Link}
      className={clsx(textStyles.classes.root, styles.classes.root)}
      {...rest}
    >
      {children}
    </Anchor>
  );
};
