import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { MarkovReferenceItem, ReferenceText } from '../../../../../design-system/v2';
import {
  AuthorInformationModel,
  CitationStyleGuideType,
  CopyEditReference,
  JournalInformationModel,
  OthersInformationModel,
  Reference,
  ReferenceType,
  WebsiteInformationModel,
} from '../../../../../generated/api';
import { getYearFromDate, toCommaSeparatedList } from '../../../../../lib/ui';
import { interleaveComponent } from '../../../../../lib/util-react';
import { MISSING_DATE_TEXT } from './constants';

const MAX_DISPLAYED_AUTHORS_APA_INLINE = 2;
const MAX_DISPLAYED_AUTHORS_APA_LIST = 20;

export const getInlineReferenceComponentAPA = (references: CopyEditReference[]): JSX.Element => {
  const prefix = '(';
  const suffix = ')';
  const delimiter = '; ';

  const referenceJSX = references.map(reference => (
    <MarkovReferenceItem
      key={reference.referenceId}
      reference={reference}
      referenceStyles={CitationStyleGuideType.Apa}
    />
  ));

  return (
    <span>
      <ReferenceText value={prefix ?? ' '} />
      {references &&
        interleaveComponent(referenceJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
      <ReferenceText value={suffix ?? ' '} />
    </span>
  );
};

const getInlineAuthorsTextAPA = (authors: AuthorInformationModel[]) => {
  if (authors.length === 0) {
    return '';
  }
  // If more than 2 authors, show first author last name + et al.
  if (authors.length > MAX_DISPLAYED_AUTHORS_APA_INLINE) {
    const firstAuthor = first(authors) as AuthorInformationModel;
    return `${firstAuthor.lastName} et al.`;
  }
  // If 1 author, show last name
  // If 2 authors, show last names joined by '&'
  return authors.map(author => author.lastName).join(' & ');
};

const getListAuthorsTextAPA = (authors: AuthorInformationModel[]) => {
  if (authors.length === 0) {
    return '';
  }
  // Format: <surname>, <first-initial>.
  const authorNames = authors.map(({ firstName, lastName }, index) => {
    // Handle missing author name
    if (index === 0 && (lastName === 'N/A' || lastName === '')) {
      return 'Unknown';
    }
    return `${lastName}, ${firstName.charAt(0)}.`;
  });
  // For >20 authors, display first 19, then "...", then final author
  if (authors.length > MAX_DISPLAYED_AUTHORS_APA_LIST) {
    const initialNames = authorNames.slice(0, MAX_DISPLAYED_AUTHORS_APA_LIST - 1);
    const finalName = last(authorNames) as string;
    return toCommaSeparatedList([...initialNames, finalName], '...');
  }
  // Else display all authors with '&' before final author name
  return toCommaSeparatedList(authorNames, '&');
};

export const getInlineReferenceTextAPA = (reference: Reference): string => {
  const authors = reference.authors;
  const referenceDetails =
    reference.referenceType === ReferenceType.JournalArticle
      ? reference.journalReference
      : reference.referenceType === ReferenceType.Website
      ? reference.websiteReference
      : reference.othersReference;

  const authorsText = !isEmpty(authors)
    ? getInlineAuthorsTextAPA(authors)
    : referenceDetails?.title ?? 'Unknown';
  const dateText =
    referenceDetails?.publishedDate && referenceDetails?.publishedDate !== MISSING_DATE_TEXT
      ? `${getYearFromDate(referenceDetails.publishedDate)}.`
      : MISSING_DATE_TEXT;
  return `${authorsText}, ${dateText}`;
};

export const getListReferenceTextAPA = (reference: Reference): string => {
  let text = '';
  const authorsText = getListAuthorsTextAPA(reference.authors);

  const referenceDetails =
    reference.referenceType === ReferenceType.JournalArticle
      ? reference.journalReference
      : reference.referenceType === ReferenceType.Website
      ? reference.websiteReference
      : reference.othersReference;
  const dateText = referenceDetails?.publishedDate
    ? getYearFromDate(referenceDetails.publishedDate)
    : MISSING_DATE_TEXT;
  if (reference.referenceType === ReferenceType.JournalArticle) {
    text += `${authorsText} (${dateText}).`;
    text += ` ${reference.title}.`;
    const { title, publicationVolume, publicationIssue, totalPages } =
      reference.journalReference as JournalInformationModel;
    const displayedIssue = publicationIssue ? `(${publicationIssue})` : '';
    const volumeAndIssueInfo = `${publicationVolume ?? ''}${displayedIssue}`;
    // Handle possible missing info
    const journalInfo = [title, volumeAndIssueInfo, totalPages].filter(item => !!item).join(', ');
    text += ` ${journalInfo}.`;
  } else if (reference.referenceType === ReferenceType.Website) {
    const { title } = reference.websiteReference as WebsiteInformationModel;
    if (authorsText) {
      text += `${authorsText} (${dateText}).`;
    } else {
      text += `${title} (${dateText}).`;
    }
    text += ` ${reference.title}.`;
  } else {
    const { title } = reference.othersReference as OthersInformationModel;
    if (authorsText) {
      text += `${authorsText} (${dateText}).`;
    } else {
      text += `${title} (${dateText}).`;
    }
    text += ` ${reference.title}.`;
  }

  return text;
};
