import { Horizontal } from '../../../../design-system/v2';
import { AddResourceButtonContainer } from './AddResourceButton.container';
import { ResourcePreviewButton } from './ResourcePreviewButton';
import { ResourceActionsProps } from './util';

// For 'leaf' resources, allow data preview and add as data source
export const LeafResourceActions = ({
  connectorId,
  resourcePath,
  isAdded,
  onResourceAdd,
}: ResourceActionsProps): JSX.Element => {
  const addSourceButtonLabel = isAdded ? 'Added' : '+ Add Resource';

  return (
    <Horizontal>
      <ResourcePreviewButton connectorId={connectorId} resourcePath={resourcePath} />
      <AddResourceButtonContainer
        connectorId={connectorId}
        resourcePath={resourcePath}
        onResourceAdd={onResourceAdd}
        disabled={isAdded}
      >
        {addSourceButtonLabel}
      </AddResourceButtonContainer>
    </Horizontal>
  );
};
