import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const ProjectDetailsPage = lazyWithRetry(() => import('../pages/Projects/ProjectDetails'));
const ModelDetailsPage = lazyWithRetry(() => import('../pages/Models/ModelDetails'));
const ExperimentDetailsPage = lazyWithRetry(() => import('../pages/Experiments/ExperimentDetails'));
const EvaluationDetailsPage = lazyWithRetry(() => import('../pages/Recording/RecordingDetails'));

const projectRoutes: RouteProps[] = [
  {
    path: '/',
    element: <ProjectDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.MODELS),
    element: <ProjectDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.EXPERIMENTS),
    element: <ProjectDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.EVALUATIONS),
    element: <ProjectDetailsPage />,
  },
  {
    path: `${getRoute(AppRoutes.MODELS)}/${getRoute(AppRoutes.MODEL_DETAILS)}`,
    element: <ModelDetailsPage />,
  },
  {
    path: `${getRoute(AppRoutes.EXPERIMENTS)}/${getRoute(AppRoutes.EXPERIMENT_DETAILS)}`,
    element: <ExperimentDetailsPage />,
  },
  {
    path: `${getRoute(AppRoutes.EVALUATIONS)}/${getRoute(AppRoutes.EVALUATION_DETAILS)}`,
    element: <EvaluationDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const ProjectDetailsRouter = (): JSX.Element => (
  <Routes>{projectRoutes.map(renderAuthRoute)}</Routes>
);
