import { Box } from '../../../../design-system';
import { Skeleton } from '../../../../design-system/v2';

const SnapShotItemLoading = ({ compact }: SnapshotLoadingProps): JSX.Element => (
  <Box p={16} borderBottom="thinSolid" borderColor="muted.shade1">
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Skeleton height={16} width={compact ? 140 : 220} />

      <Skeleton height={16} width={compact ? 90 : 120} />

      <Skeleton height={16} width={compact ? 90 : 120} />
    </Box>
  </Box>
);

const SNAPSHOT_NUMBER = 3;

interface SnapshotLoadingProps {
  compact?: boolean;
}
export const SnapshotLoading = ({ compact = false }: SnapshotLoadingProps): JSX.Element => (
  <>
    {Array.from({ length: SNAPSHOT_NUMBER }).map((_, index) => (
      <SnapShotItemLoading key={`snapshot-loading-${index}`} compact={compact} />
    ))}
  </>
);
