import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { Box, Center, SimpleGrid, Text, Vertical, openModal } from '../../../design-system/v2';
import { StorageType } from '../../../generated/api';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../util';
import { AzureBlobConnectorModal } from './AzureBlobConnectorModal';
import { GoogleDriveModal } from './GoogleDriveModal';
import { GoogleSheetModal } from './GoogleSheetConnector';
import { PostgresConnectorModal } from './PostgresConnectorModal';
import { S3ConnectorModal } from './S3ConnectorModal';
import { SnowflakeConnectorModal } from './SnowflakeConnectorModal';

const MAX_GRID_COLS = 3;

interface CreateConnectorModalProps {
  availableConnectorTypes?: StorageType[];
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const CreateConnectorModal = ({
  availableConnectorTypes,
  onCreate,
  onClose,
}: CreateConnectorModalProps): JSX.Element => {
  const { featureGoogleSheetConnector } = useFlags();
  const [connectorType, setConnectorType] = useState<StorageType | 'GoogleDrive' | undefined>(
    undefined,
  );

  const resetConnectorType = () => {
    setConnectorType(undefined);
  };

  if (connectorType === StorageType.Snowflake) {
    return (
      <SnowflakeConnectorModal
        onClickBack={resetConnectorType}
        onCreate={onCreate}
        onClose={onClose}
      />
    );
  }

  if (connectorType === StorageType.Postgresql) {
    return (
      <PostgresConnectorModal
        onClickBack={resetConnectorType}
        onCreate={onCreate}
        onClose={onClose}
      />
    );
  }

  if (connectorType === StorageType.S3) {
    return (
      <S3ConnectorModal onClickBack={resetConnectorType} onCreate={onCreate} onClose={onClose} />
    );
  }

  if (connectorType === StorageType.AzureBlob) {
    return (
      <AzureBlobConnectorModal
        onClickBack={resetConnectorType}
        onCreate={onCreate}
        onClose={onClose}
      />
    );
  }

  if (connectorType === StorageType.GoogleSheet) {
    return (
      <GoogleSheetModal onClickBack={resetConnectorType} onCreate={onCreate} onClose={onClose} />
    );
  }

  if (connectorType === StorageType.GoogleDrive) {
    return (
      <GoogleDriveModal onClickBack={resetConnectorType} onCreate={onCreate} onClose={onClose} />
    );
  }

  const connectorOptions = [
    {
      name: getConnectorTypeDisplayName(StorageType.S3),
      value: StorageType.S3,
      icon: getConnectorTypeIcon(StorageType.S3, 56),
      enabled: true,
    },
    {
      name: getConnectorTypeDisplayName(StorageType.Snowflake),
      value: StorageType.Snowflake,
      icon: getConnectorTypeIcon(StorageType.Snowflake, 48),
      enabled: true,
    },
    {
      name: getConnectorTypeDisplayName(StorageType.Postgresql),
      value: StorageType.Postgresql,
      icon: getConnectorTypeIcon(StorageType.Postgresql, 56),
      enabled: true,
    },
    {
      name: getConnectorTypeDisplayName(StorageType.GoogleDrive),
      value: StorageType.GoogleDrive,
      icon: getConnectorTypeIcon(StorageType.GoogleDrive, 56),
      enabled: true,
    },
    {
      name: getConnectorTypeDisplayName(StorageType.GoogleSheet),
      value: StorageType.GoogleSheet,
      icon: getConnectorTypeIcon(StorageType.GoogleSheet, 56),
      enabled: featureGoogleSheetConnector,
    },
  ];

  const filteredOptions = connectorOptions.filter(
    connector => connector.enabled && (availableConnectorTypes?.includes(connector.value) ?? true),
  );
  const numCols = Math.min(filteredOptions.length, MAX_GRID_COLS);

  return (
    <SimpleGrid cols={numCols} spacing="xl" px="md">
      {filteredOptions.map(({ name, value, icon }) => {
        const handleClick = () => setConnectorType(value);
        return (
          <Center
            key={value}
            h={200}
            sx={theme => ({
              border: `1px solid ${theme.colors.gray[2]}`,
              boxShadow: theme.shadows.sm,
              borderRadius: theme.radius.xs,
              cursor: 'pointer',
              ':hover': {
                background: theme.colors.gray[0],
              },
            })}
            onClick={handleClick}
          >
            <Vertical align="center" spacing="xs">
              <Box>{icon}</Box>
              <Text variant="subTitle03" color="gray.7">
                {name}
              </Text>
            </Vertical>
          </Center>
        );
      })}
    </SimpleGrid>
  );
};

export const useCreateConnectorModal = (connectorTypes?: StorageType[]) => {
  const open = () =>
    openModal({
      size: 'xl',
      title: <Text variant="subTitle01">Create new connector</Text>,
      children: <CreateConnectorModal availableConnectorTypes={connectorTypes} />,
    });

  return { open };
};
