import { Center, Loader } from '../../../design-system/v2';
import {
  useGetModelAppStatus,
  useInvalidateModelApps,
  useModelAppDetailsQuery,
  useSubmitModelInput,
} from '../../../queries/model-apps/model-apps';
import { ErrorState } from '../../common/ErrorState';
import {
  useModelAppInfer,
  useModelAppStatus,
} from '../../model-apps/model-app-details/use-model-app';
import { ModelAppsBlock, OUTPUT_BOX_HEIGHT } from './ModelAppsBlock';

interface ModelAppsBlockContainerProps {
  modelId: string;
}

export const ModelAppsBlockContainer = ({ modelId }: ModelAppsBlockContainerProps) => {
  const { data, isLoading, isError } = useModelAppDetailsQuery({ modelId });

  const { isAppLoading } = useModelAppStatus({
    modelAppStatus: data?.modelAppStatus,
  });
  useGetModelAppStatus(modelId, isAppLoading);

  const invalidateModelApps = useInvalidateModelApps(modelId);

  const { mutateAsync: getInference } = useSubmitModelInput(data?.modelId ?? '');
  const { modelOutputList = [], submitInput } = useModelAppInfer({ modelId, getInference });

  if (isLoading) {
    return (
      <Center h={2 * OUTPUT_BOX_HEIGHT}>
        <Loader variant="bars" text="Fetching Model App..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={2 * OUTPUT_BOX_HEIGHT}>
        <ErrorState title="" subtitle="Something went wrong, not able to fetch model app" />
      </Center>
    );
  }

  return (
    <ModelAppsBlock
      modelAppMetadata={data}
      getInferenceResult={submitInput}
      modelOutputList={modelOutputList}
      invalidateModelApps={invalidateModelApps}
    />
  );
};
