import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  ArtifactFilterState,
  ArtifactStateType,
  Evaluation,
  EvaluationWithTags,
  GetAllEvaluationsListWithTagsResponse,
  GetRecordingsListResponse,
  GetRecordingsSummaryResponse,
  RecordState,
  RecordingsCountInModelClass,
  RecordingsSummary,
  UpdateEvaluationArtifactState,
} from '../generated/api';
import { evaluationsApi, recordingsApi, taggedResourcesApi } from '../lib/api';
import { API_PAGE_SIZE } from './constants';
import { EVALUATION } from './queryConstants';
import { getNextPageParamHandler } from './util';

interface EvaluationsListQueryFilters {
  name?: string;
  projectId?: string;
  modelId?: string;
  datasetId?: string;
  userIds?: string[];
  excludeUserIds?: string[];
  recordIds?: string[];
  tagIds?: string[];
  state?: ArtifactFilterState;
}

export const useGetRecordingsList = (userId?: string) => {
  const { workspaceId } = useAppMetadata();

  const PAGINATION_START_INDEX = 0;
  const PAGINATION_END_INDEX = 5;

  return useQuery(
    [EVALUATION.LIST_WORKSPACE_EVALS, workspaceId, userId],
    () =>
      taggedResourcesApi.getAllEvaluationsListWithTagsV1(
        workspaceId,
        PAGINATION_START_INDEX,
        PAGINATION_END_INDEX,
        true,
        undefined,
        undefined,
        userId ? [userId] : undefined,
      ),
    {
      select: data => data.data.response,
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
    },
  );
};

export const useDeleteRecordingsMutation = (modelClass: string) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();
  const recordingsKey = [EVALUATION.LIST_MODEL_CLASS_EVALS, workspaceId, userId, modelClass];

  const { mutate, isError, isLoading } = useMutation(
    (recordingIds: string[]) =>
      recordingsApi.workspaceDeleteRecordingsV1(workspaceId, {
        memberId: userId,
        recordingIds: recordingIds,
      }),
    {
      onMutate: async (deletedRecordingIds: string[]) => {
        // TODO: Add Toast/Snackbar acknowledgement
        await queryClient.cancelQueries(recordingsKey);
        const previousRecordingsData =
          queryClient.getQueryData<AxiosResponse<GetRecordingsListResponse>>(recordingsKey);
        if (previousRecordingsData) {
          const previousRecordings = previousRecordingsData.data.response.map(recording => {
            if (deletedRecordingIds.includes(recording.recordingId))
              recording.state.name = RecordState.SoftDelete;
            return recording;
          });
          previousRecordingsData.data.response = previousRecordings;
          queryClient.setQueryData<AxiosResponse<GetRecordingsListResponse>>(
            recordingsKey,
            previousRecordingsData,
          );
        }

        return { previousRecordingsData, deletedRecordingIds };
      },
      onError: (
        _err: string,
        _data,
        context: { previousRecordingsData?: AxiosResponse<GetRecordingsListResponse> } | undefined,
      ) => {
        // TODO: Add Toast/Snackbar acknowledgement
        if (context?.previousRecordingsData) {
          queryClient.setQueryData(recordingsKey, context.previousRecordingsData);
        }
      },
      onSettled: () => {
        // TODO: Add Toast/Snackbar acknowledgement?
        queryClient.invalidateQueries(recordingsKey);
      },
    },
  );

  return {
    isError,
    isLoading,
    deleteRecordings: mutate,
  };
};

export const useModelClassRecordingsQuery = (
  modelClass: string,
  filters: EvaluationsListQueryFilters = {},
) => {
  const { workspaceId, userId } = useAppMetadata();

  return useInfiniteQuery<
    AxiosResponse<GetAllEvaluationsListWithTagsResponse>,
    AxiosError,
    EvaluationWithTags[]
  >(
    [EVALUATION.LIST_MODEL_CLASS_EVALS, workspaceId, userId, modelClass, filters],
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return taggedResourcesApi.getAllEvaluationsListForModelClassWithTagsV1(
        workspaceId,
        modelClass,
        start,
        end,
        lastTimestamp,
        filters.name,
        filters.datasetId,
        filters.modelId,
        filters.projectId,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
        false,
        filters.state,
      );
    },
    {
      enabled: Boolean(workspaceId && modelClass),
      refetchInterval: 30 * 1000,
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useEvaluationStateMutation = (
  evaluationArtifactState: UpdateEvaluationArtifactState,
  modelClass: string,
  resetEvaluationSelection: () => void,
) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    () => evaluationsApi.updateArtifactStateEvaluationsV1(workspaceId, evaluationArtifactState),
    {
      onSuccess: () => {
        resetEvaluationSelection();
        queryClient.invalidateQueries([EVALUATION.GET_EVALS_SUMMARY, workspaceId]);
        queryClient.invalidateQueries([
          EVALUATION.LIST_MODEL_CLASS_EVALS,
          workspaceId,
          userId,
          modelClass,
        ]);
      },
    },
  );
};

export const useRecordingsSummaryQuery = (state = ArtifactFilterState.Active) => {
  const { workspaceId } = useAppMetadata();
  const artifactState =
    state === ArtifactFilterState.Active ? ArtifactStateType.Active : ArtifactStateType.Archived;
  return useQuery<
    AxiosResponse<GetRecordingsSummaryResponse>,
    AxiosError,
    RecordingsCountInModelClass[]
  >(
    [EVALUATION.GET_EVALS_SUMMARY, workspaceId, state],
    () => recordingsApi.workspaceGetRecordingsSummaryV1(workspaceId, artifactState),
    {
      enabled: !!workspaceId,
      select: data =>
        (data.data.response as RecordingsSummary).modelClasses.sort((classA, classB) =>
          classA.name.localeCompare(classB.name, 'en'),
        ),
    },
  );
};

export const useDeletedModelClassRecordingsQuery = (modelClass: string) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery<AxiosResponse<GetRecordingsListResponse>, AxiosError, Evaluation[]>(
    [EVALUATION.LIST_DELETED_MODEL_CLASS_EVALS, workspaceId, modelClass],
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return recordingsApi.workspaceGetDeletedRecordingsListForModelClassV1(
        workspaceId,
        modelClass,
        start,
        end,
        lastTimestamp,
      );
    },
    {
      enabled: Boolean(workspaceId && modelClass),
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useDeletedRecordingsSummaryQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetRecordingsSummaryResponse>,
    AxiosError,
    RecordingsCountInModelClass[]
  >(
    [EVALUATION.GET_DELETED_EVALS_SUMMARY, workspaceId],
    () => recordingsApi.workspaceGetDeletedRecordingsSummaryV1(workspaceId),
    {
      enabled: !!workspaceId,
      select: data =>
        (data.data.response as RecordingsSummary).modelClasses.sort((classA, classB) =>
          classA.name.localeCompare(classB.name, 'en'),
        ),
    },
  );
};
