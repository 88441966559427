import { Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { ChartTypes } from '../../../../../charts';
import {
  Carousel,
  Center,
  Loader,
  Modal,
  SimpleGrid,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { AnalysisChartTypes, AnalysisMetadata } from '../../../../../generated/api';
import { AnalyserVisualizationContainer } from '../../../../analyser/analyser-visualization/AnalyserVisualization.container';
import { getSegmentText } from '../../../../analyser/analyser.util';
import { isSplitSegmentType } from '../../../../dataset-registration/util';
import { replaceSeriesName } from '../../../util';
import { TopicModalExplanation } from './TopicDistributionExplaination';

interface AnalysisExpandModalProps {
  list: AnalysisMetadata[];
  currentIndex?: number;
  onCloseCarousel: () => void;
}

export const AnalysisExpandModal = ({
  list,
  currentIndex,
  onCloseCarousel,
}: AnalysisExpandModalProps) => {
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [showCarousel, setShowCarousel] = useState(false);
  // We are opening carousel in a modal. So, this case applies
  // https://mantine.dev/others/carousel/#carousel-container-animation-offset
  useAnimationOffsetEffect(embla, 200);
  useEffect(() => {
    if (!isUndefined(currentIndex)) {
      setTimeout(() => {
        setShowCarousel(true);
      }, 200);
    }
  }, [currentIndex, embla]);

  const handleCarouselClose = () => {
    setShowCarousel(false);
    onCloseCarousel();
  };

  return (
    <Modal
      opened={!isUndefined(currentIndex)}
      onClose={handleCarouselClose}
      size="90vw"
      keepMounted
      withinPortal
      transitionProps={{ duration: 200 }}
      centered
    >
      {showCarousel ? (
        <Carousel
          height="70vh"
          slideSize="100%"
          slidesToScroll={1}
          align="center"
          getEmblaApi={setEmbla}
          controlSize={42}
          initialSlide={currentIndex}
        >
          {list.map(item => (
            <Carousel.Slide key={item.analysisId}>
              <Vertical spacing="sm" px="xl">
                <Vertical maw={300}>
                  <Text variant="subTitle01" truncate>
                    {replaceSeriesName(item.title ?? '')}
                  </Text>
                  {isSplitSegmentType(item.segmentType) && (
                    <Text variant="subTitle03" color="gray">
                      {getSegmentText(item.segmentType)}
                    </Text>
                  )}
                </Vertical>
                {item.preferredChartType === AnalysisChartTypes.TreeMap ? (
                  <SimpleGrid h="100%" cols={2} mih="70vh">
                    <AnalyserVisualizationContainer analysis={item} showTitle={false} />
                    <TopicModalExplanation analysis={item} />
                  </SimpleGrid>
                ) : item.preferredChartType === AnalysisChartTypes.HorizontalHistogram ? (
                  <AnalyserVisualizationContainer
                    analysis={item}
                    showTitle={false}
                    type={ChartTypes.COLUMN}
                  />
                ) : (
                  <SimpleGrid h="100%" cols={2} mih="70vh">
                    <AnalyserVisualizationContainer analysis={item} showTitle={false} />
                    <AnalyserVisualizationContainer
                      analysis={item}
                      showTitle={false}
                      type={ChartTypes.WORD_CLOUD}
                    />
                  </SimpleGrid>
                )}
              </Vertical>
            </Carousel.Slide>
          ))}
        </Carousel>
      ) : (
        <Center mih="70vh">
          <Loader variant="dots" />
        </Center>
      )}
    </Modal>
  );
};
