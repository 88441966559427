import { Loader } from '../../../design-system';
import { useUserWorkspaceTokenQuery } from '../../../queries/settings/token';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { Token } from './Token';

export const TokenContainer = (): JSX.Element => {
  const { isLoading, error, data } = useUserWorkspaceTokenQuery();

  if (isLoading) {
    return <Loader text="Loading your token..." />;
  }

  if (error) {
    return (
      <ErrorState
        compact
        title="Error loading your token"
        subtitle="Please try again after sometime"
      />
    );
  }

  if (!data?.apiToken) {
    return (
      <EmptyState
        compact
        title="No API token available"
        subtitle="This is unexpected. Please contact support!"
      />
    );
  }

  return <Token token={data} />;
};
