import { MarkovColor } from '../design-system/v2';
import { SubscriptionType } from '../generated/api';

export enum MarkovResource {
  DATASETS = 'DATASETS',
  ANALYZERS = 'ANALYZERS',
  EVALUATIONS = 'EVALUATIONS',
  EXPERIMENTS = 'EXPERIMENTS',
  PROJECTS = 'PROJECTS',
  NOTEBOOKS = 'NOTEBOOKS',
  OTHERS = 'OTHERS',
}

export const markovResourceColorMapping = {
  [MarkovResource.DATASETS]: 'yellow.7',
  [MarkovResource.ANALYZERS]: 'seaGreen.7',
  [MarkovResource.EVALUATIONS]: 'purple.7',
  [MarkovResource.EXPERIMENTS]: 'green.7',
  [MarkovResource.NOTEBOOKS]: 'pink.7',
  [MarkovResource.PROJECTS]: 'magenta.7',
  [MarkovResource.OTHERS]: 'blue.7',
};

interface SubscriptionDetails {
  title: string;
  subTitle: string;
  color: MarkovColor;
}

/**
 * TODO: Remove once subscriptions are introduced
 */
export enum TempSubscriptionType {
  TRIAL = 'TRIAL',
}
/**
 * TODO: Update title and subtitle later to match the correct subscription content
 */
export const markovSubscriptionMapping: Record<string, SubscriptionDetails> = {
  // Keeping a TRIAL (Beta) subscription in the list until backend starts sending correct subscription data
  [TempSubscriptionType.TRIAL]: {
    title: 'Beta',
    subTitle: 'You will start seeing your subscription plan here soon!',
    color: 'yellow.1',
  },
  [SubscriptionType.Starter]: {
    title: 'Starter',
    subTitle: 'Early plan to get you started.',
    color: 'yellow.1',
  },
  // Need to add a subscription type called `Teams` so community users can upgrade into that
  [SubscriptionType.EnterpriseLite]: {
    title: 'Enterprise',
    subTitle: 'MarkovML values your business.',
    color: 'green.1',
  },
  [SubscriptionType.EnterprisePlus]: {
    title: 'Enterprise Plus',
    subTitle: 'Your team is on the best journey of your life.',
    color: 'seaGreen.1',
  },
};

export const S3_ILLUSTRATIONS_MAP = {
  HOMEPAGE_BANNER: 'illustrations/homepage-banner',
  EMPTY_ERROR_STATES: 'illustrations/empty-error-states',
  FEATURE_VISUALS: 'illustrations/feature-visuals',
};
