import {
  CopyeditAddDocument,
  CopyeditAddDocumentProperties,
  CopyeditDocAiContentDetectionClicked,
  CopyeditDocAiContentDetectionClickedProperties,
  CopyeditDocCheckAiContentClicked,
  CopyeditDocCheckAiContentClickedProperties,
  CopyeditDocCheckPlagiarismClicked,
  CopyeditDocCheckPlagiarismClickedProperties,
  CopyeditDocExportOriginalClicked,
  CopyeditDocExportOriginalClickedProperties,
  CopyeditDocExportRevisedClicked,
  CopyeditDocExportRevisedClickedProperties,
  CopyeditDocHistoryClicked,
  CopyeditDocHistoryClickedProperties,
  CopyeditDocImprovementsTabClicked,
  CopyeditDocImprovementsTabClickedProperties,
  CopyeditDocPageNumberClicked,
  CopyeditDocPageNumberClickedProperties,
  CopyeditDocPlagiarismTabClicked,
  CopyeditDocPlagiarismTabClickedProperties,
  CopyeditDocReadbilityWordcountClicked,
  CopyeditDocReadbilityWordcountClickedProperties,
  CopyeditDocReadbilityWordcountClosed,
  CopyeditDocReadbilityWordcountClosedProperties,
  CopyeditDocStyleGuidesTabClicked,
  CopyeditDocStyleGuidesTabClickedProperties,
  CopyeditDocSuggestionAcceptClicked,
  CopyeditDocSuggestionAcceptClickedProperties,
  CopyeditDocSuggestionDismissClicked,
  CopyeditDocSuggestionDismissClickedProperties,
  CopyeditDocumentCreationCancelled,
  CopyeditDocumentCreationCancelledProperties,
  CopyeditDocumentDeleteCancel,
  CopyeditDocumentDeleteCancelProperties,
  CopyeditDocumentDeleted,
  CopyeditDocumentDeletedProperties,
  CopyeditDocumentInviteClicked,
  CopyeditDocumentInviteClickedProperties,
  CopyeditDocumentOpened,
  CopyeditDocumentOpenedProperties,
  CopyeditDocVersionNumberClicked,
  CopyeditDocVersionNumberClickedProperties,
  CopyeditInvitedMembersListClicked,
  CopyeditInvitedMembersListClickedProperties,
  CopyeditProjectCancel,
  CopyeditProjectCancelProperties,
  CopyeditProjectClick,
  CopyeditProjectClickProperties,
  CopyeditProjectCreate,
  CopyeditProjectCreateProperties,
  CopyeditProjectsBackButtonClicked,
  CopyeditProjectsBackButtonClickedProperties,
  CopyeditRemoveInviteClicked,
  CopyeditRemoveInviteClickedProperties,
  CopyeditShareButtonClicked,
  CopyeditShareButtonClickedProperties,
} from '../ampli';

export const copyEditEvents = {
  project: {
    cancel: (data: CopyeditProjectCancelProperties) => new CopyeditProjectCancel(data),
    click: (data: CopyeditProjectClickProperties) => new CopyeditProjectClick(data),
    create: (data: CopyeditProjectCreateProperties) => new CopyeditProjectCreate(data),
  },
  documents: {
    addDocument: (data: CopyeditAddDocumentProperties) => new CopyeditAddDocument(data),
    creationCancelled: (data: CopyeditDocumentCreationCancelledProperties) =>
      new CopyeditDocumentCreationCancelled(data),
    inviteClicked: (data: CopyeditDocumentInviteClickedProperties) =>
      new CopyeditDocumentInviteClicked(data),
    removeInviteClicked: (data: CopyeditRemoveInviteClickedProperties) =>
      new CopyeditRemoveInviteClicked(data),
    shareButtonClicked: (data: CopyeditShareButtonClickedProperties) =>
      new CopyeditShareButtonClicked(data),
    deleteCancel: (data: CopyeditDocumentDeleteCancelProperties) =>
      new CopyeditDocumentDeleteCancel(data),
    deleted: (data: CopyeditDocumentDeletedProperties) => new CopyeditDocumentDeleted(data),
    opened: (data: CopyeditDocumentOpenedProperties) => new CopyeditDocumentOpened(data),
    invitedMembersListClicked: (data: CopyeditInvitedMembersListClickedProperties) =>
      new CopyeditInvitedMembersListClicked(data),
    backButtonClicked: (data: CopyeditProjectsBackButtonClickedProperties) =>
      new CopyeditProjectsBackButtonClicked(data),
  },
  document: {
    docExportOriginalClicked: (data: CopyeditDocExportOriginalClickedProperties) =>
      new CopyeditDocExportOriginalClicked(data),
    docExportRevisedClicked: (data: CopyeditDocExportRevisedClickedProperties) =>
      new CopyeditDocExportRevisedClicked(data),
    docHistoryClicked: (data: CopyeditDocHistoryClickedProperties) =>
      new CopyeditDocHistoryClicked(data),
    docPageNumberClicked: (data: CopyeditDocPageNumberClickedProperties) =>
      new CopyeditDocPageNumberClicked(data),
    docReadabilityWordcountClicked: (data: CopyeditDocReadbilityWordcountClickedProperties) =>
      new CopyeditDocReadbilityWordcountClicked(data),
    docReadabilityWordcountClosed: (data: CopyeditDocReadbilityWordcountClosedProperties) =>
      new CopyeditDocReadbilityWordcountClosed(data),
    docVersionNumberClicked: (data: CopyeditDocVersionNumberClickedProperties) =>
      new CopyeditDocVersionNumberClicked(data),
    docAiContentDetectionClicked: (data: CopyeditDocAiContentDetectionClickedProperties) =>
      new CopyeditDocAiContentDetectionClicked(data),
    docCheckAiContentClicked: (data: CopyeditDocCheckAiContentClickedProperties) =>
      new CopyeditDocCheckAiContentClicked(data),
    docPlagiarismTabClicked: (data: CopyeditDocPlagiarismTabClickedProperties) =>
      new CopyeditDocPlagiarismTabClicked(data),
    docCheckPlagiarismClicked: (data: CopyeditDocCheckPlagiarismClickedProperties) =>
      new CopyeditDocCheckPlagiarismClicked(data),
    docImprovementsTabClicked: (data: CopyeditDocImprovementsTabClickedProperties) =>
      new CopyeditDocImprovementsTabClicked(data),
    docStyleGuidesTabClicked: (data: CopyeditDocStyleGuidesTabClickedProperties) =>
      new CopyeditDocStyleGuidesTabClicked(data),
    docSuggestionAcceptClicked: (data: CopyeditDocSuggestionAcceptClickedProperties) =>
      new CopyeditDocSuggestionAcceptClicked(data),
    docSuggestionDismissClicked: (data: CopyeditDocSuggestionDismissClickedProperties) =>
      new CopyeditDocSuggestionDismissClicked(data),
  },
};
