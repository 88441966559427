import { IconLongArrowRight, Info } from '../../../../design-system';
import { ActionIcon, Badge, Box, Horizontal, Text, Tooltip } from '../../../../design-system/v2';
import { List } from '../../../../design-system/v2/core/data-display/table';
import {
  AgGridReact,
  ICellRendererParams,
} from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { ClassOverlapInfo } from '../../../../generated/api';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';

const OVERLAPPING_CLASS_METRICS_DESCRIPTION =
  'Percentage of points in one class that are likely to be misclassified as another. If the number is higher, you might want to merge the two classes into one.';

interface OverlappingClassMetricsProps {
  overlappingClassMetrics: ClassOverlapInfo[];
  gridRef: React.RefObject<AgGridReact<ClassOverlapInfo>>;
  pageNumber: number;
  handlePageNumberChange: (page: number) => void;
  totalRows: number;
}

const columns = [
  {
    colId: 'primaryClass',
    field: 'primaryClass',
    headerName: 'Classes',
    sortable: false,
    minWidth: 160,
    flex: 1,
    cellRenderer: ({
      value: primaryClass,
      data,
    }: ICellRendererParams<ClassOverlapInfo, string>) => (
      <Box>
        <Badge variant="outline" color="gray.4" mr="xs">
          <Text variant="small01" color="gray.8" tt="capitalize">
            {primaryClass}
          </Text>
        </Badge>
        <IconLongArrowRight />
        <Badge variant="outline" color="gray.4" ml="xs">
          <Text variant="small01" color="gray.8" tt="capitalize">
            {data?.secondaryClass}
          </Text>
        </Badge>
      </Box>
    ),
  },
  {
    colId: 'overlapCount',
    field: 'overlapCount',
    headerName: 'Data Points',
    width: 116,
    cellRenderer: ({ value: overlapCount }: ICellRendererParams<ClassOverlapInfo, number>) => (
      <Text variant="small01" color="gray.8" pt="lg">
        {overlapCount}
      </Text>
    ),
  },
  {
    colId: 'overlapProb',
    field: 'overlapProb',
    headerName: 'Metrics',
    width: 100,
    cellRenderer: ({ value: overlapProb }: ICellRendererParams<ClassOverlapInfo, number>) => (
      <Text variant="small01" color="gray.8" pt="lg">
        {overlapProb.toFixed(3)}
      </Text>
    ),
  },
];

export const OverlappingClassMetrics = ({
  overlappingClassMetrics,
  gridRef,
  pageNumber,
  handlePageNumberChange,
  totalRows,
}: OverlappingClassMetricsProps): JSX.Element => (
  <VisualizationComponent
    cardProps={{
      shadow: '',
      p: '0px',
    }}
  >
    <Horizontal spacing="xs">
      <VisualizationComponent.Header.Title
        title="Overlapping classes"
        titleProps={{ variant: 'subTitle03', color: 'gray.8', py: '24px', pl: '24px' }}
      />
      <Tooltip
        label={OVERLAPPING_CLASS_METRICS_DESCRIPTION}
        withArrow
        withinPortal
        multiline
        maw={325}
      >
        <ActionIcon variant="transparent">
          <Info />
        </ActionIcon>
      </Tooltip>
    </Horizontal>
    <Box h="348px">
      <List<ClassOverlapInfo>
        gridRef={gridRef}
        columns={columns}
        rowData={overlappingClassMetrics}
        totalRows={totalRows}
        pagination
        currentPage={pageNumber + 1}
        leftActionsContent={undefined}
        onPageNumberChange={handlePageNumberChange}
      />
    </Box>
  </VisualizationComponent>
);
