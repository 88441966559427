import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface useIntersectionObserverParams extends IntersectionObserverInit {
  target: RefObject<Element | undefined>;
  onIntersect: () => void;
  enabled?: boolean;
  rootRef?: RefObject<Element>;
  rootMargin?: string;
  threshold?: number;
}

export const useIntersectionObserver = ({
  target,
  onIntersect,
  enabled = true,
  rootRef,
  rootMargin = '0px',
  // Note: If specifying a custom threshold, ensure you choose a threshold value < 1
  // (otherwise intersection won't trigger)
  threshold = 0.1,
}: useIntersectionObserverParams) => {
  useEffect(() => {
    if (!enabled) return;

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          if (entry.isIntersecting) onIntersect();
        }),
      {
        root: rootRef?.current ?? null,
        rootMargin,
        threshold,
      },
    );

    const el = target && target.current;
    if (!el) return;

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
};

/**
 * Above hook for using Intersection observer is based on callback
 * This implementation exposes entry (modifies code from mantine and exposes observer and element)
 * so that component can have more granular control over intersection
 */
export function useIntersection<T extends HTMLElement = any>(
  options?: ConstructorParameters<typeof IntersectionObserver>[1],
) {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const observer = useRef<IntersectionObserver | null>();
  const el = useRef<T | null>(null);

  const ref = useCallback(
    (element: T | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (element === null) {
        setEntry(null);
        return;
      }

      observer.current = new IntersectionObserver(([_entry]) => {
        setEntry(_entry);
        el.current = element;
      }, options);

      observer.current.observe(element);
    },
    [options?.rootMargin, options?.root, options?.threshold],
  );

  return { ref, entry, observer, el };
}
