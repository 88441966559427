import { lazy, LazyExoticComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RETRY_STORAGE_KEY = 'page-has-been-force-refreshed';

// This custom lazy-load fn is intended to mitigate chunk load errors. Inspired by:
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazyWithRetry = (
  componentImport: () => Promise<{
    default: () => JSX.Element;
  }>,
): LazyExoticComponent<() => JSX.Element> =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(RETRY_STORAGE_KEY) || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(RETRY_STORAGE_KEY, 'false');

      return component;
    } catch (error) {
      // Assuming that the user is not on the latest version of the app, do a refresh
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(RETRY_STORAGE_KEY, 'true');
        window.location.reload();
        // Needed for type checking
        return { default: () => <></> };
      }

      // The page has already been reloaded. Assuming that user is already using the
      // latest version of the app, let the application crash and raise the error.
      throw error;
    }
  });

export const usePreserveQueryParamsNavigate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (path: string) => {
    navigate(path + search);
  };
};
