import { IconArrowNarrowRight, IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { dashboardEvents, datasetEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import {
  Box,
  Button,
  Center,
  Horizontal,
  Image,
  LinkButton,
  Text,
  Vertical,
  useMarkovTheme,
} from '../../../../../design-system/v2';
import { MarkovArtifacts } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { S3_STORAGE_BASE } from '../../../../../lib/env.util';
import { useGetArtifactCountQuery } from '../../../../../queries/account/workspace';
import { useGetDatasetsListPreviewQuery } from '../../../../../queries/datasets/list';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { ErrorState } from '../../../../common/ErrorState';
import { TabStates, WorkspaceCard } from '../../../home/WorkspaceCard';
import { useDashboardSnapshotContext } from '../../../home/snapshot/DashboardSnapshotContext';
import { SnapshotLoading } from '../../../home/snapshot/SnapshotLoading';
import { EmptyDatasetPreview } from '../../../home/snapshot/datasets/EmptyDatasetPreview';
import { EmptyPreviewHorizontal } from '../EmptyPreviewHorizontal';
import { DatasetsPreview } from './DatasetsPreview';

// TODO: Look for consolodation of these two containers (DatasetsTable.container and DatasetsList.container)
// Because we are showing uploading status of workflows in DatasetsTable

const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/illustrations/dashboard/dataset-illustration.svg`;

const datasetsTitle = 'Analyze your datasets';
const datasetsDescription =
  'Organize and analyze your datasets to obtain exploratory insights that reveal patterns, trends, and invaluable information, empowering informed decision-making.';
const datasetsIllustration = <Image maw={200} src={ILLUSTRATION_URL} />;

export const DatasetsPreviewContainer = () => {
  const theme = useMarkovTheme();
  const { userId, workspaceId } = useAppMetadata();

  const navigate = useNavigate();
  const { getDatasetDetailsRoute, getDatasetRegistrationRoute } = useAbsoluteRoutes();
  const { selectedTab, handleTabChange } = useDashboardSnapshotContext();
  const { isError, isLoading, data } = useGetDatasetsListPreviewQuery(
    selectedTab === TabStates.MINE ? userId : undefined,
  );
  const {
    isLoading: isLoadingCount,
    isError: isErrorCount,
    data: dataCount,
  } = useGetArtifactCountQuery(MarkovArtifacts.Datasets);

  const handleNavigateDataset = (datasetId: string) => {
    sendAnalytics(
      dashboardEvents.datasetSampleClicked({
        workspaceId,
      }),
    );
    const route = getDatasetDetailsRoute(datasetId);
    navigate(route);
  };

  const handleClickNewDataset = () => {
    sendAnalytics(
      datasetEvents.register.addClicked({
        workspaceId,
        sourcePage: 'DASHBOARD_DATASET_PREVIEW_LIST',
      }),
    );
  };

  if (isLoading || isLoadingCount) {
    return (
      <WorkspaceCard
        title="Datasets"
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        isSample
      >
        <SnapshotLoading compact />
      </WorkspaceCard>
    );
  }

  if (isError || isErrorCount) {
    return (
      <WorkspaceCard title="Datasets" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <Center h={280} bg="white.0">
          <ErrorState
            compact
            iconName="dataset"
            title="Error loading datasets"
            subtitle="Please try refreshing the page or contact support"
          />
        </Center>
      </WorkspaceCard>
    );
  }

  if (!dataCount || !data || !data.length) {
    return (
      <WorkspaceCard title="Datasets" selectedTab={selectedTab} onChangeTab={handleTabChange}>
        <EmptyDatasetPreview />
      </WorkspaceCard>
    );
  }

  if (dataCount.demoArtifacts === dataCount.totalArtifacts) {
    return (
      <WorkspaceCard title="Datasets" isSample>
        <EmptyPreviewHorizontal
          title={datasetsTitle}
          description={datasetsDescription}
          illustration={datasetsIllustration}
        >
          <Vertical spacing={0}>
            <Horizontal pt="48px">
              {data.slice(0, 2).map((dataset, idx) => (
                <Button
                  key={idx}
                  variant="secondary"
                  onClick={() => handleNavigateDataset(dataset.datasetId)}
                  rightIcon={<IconArrowNarrowRight color={theme.colors.blue[6]} stroke={1.5} />}
                >
                  <Text variant="subTitle03" color="blue.6">
                    Sample dataset 0{idx + 1}
                  </Text>
                </Button>
              ))}
            </Horizontal>
            <Horizontal position="right" px="sm" pt="lg">
              <LinkButton
                variant="primary"
                shape="sharp"
                to={getDatasetRegistrationRoute()}
                w="100%"
                size="sm"
                leftIcon={<IconPlus size={20} stroke={1.5} />}
                onClick={handleClickNewDataset}
              >
                <Text variant="subTitle03">New dataset</Text>
              </LinkButton>
            </Horizontal>
          </Vertical>
        </EmptyPreviewHorizontal>
      </WorkspaceCard>
    );
  }

  return (
    <WorkspaceCard title="Datasets" selectedTab={selectedTab} onChangeTab={handleTabChange}>
      <Box>
        <DatasetsPreview datasets={data} />
      </Box>
    </WorkspaceCard>
  );
};
