import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';

export const Paragraph = () => {
  const { editor } = useRichTextEditorContext();

  const handleParagraphClick = () => {
    editor?.chain().focus().setParagraph().run();
  };

  return (
    <RichTextEditor.Control active={editor.isActive('paragraph')} onClick={handleParagraphClick}>
      P
    </RichTextEditor.Control>
  );
};
