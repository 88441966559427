import { AnalyzingSpinner } from '../../../../design-system';
import { Button, Center, Flex, Loader } from '../../../../design-system/v2';
import { AnalysisStateNames } from '../../../../generated/api';
import { getIsTaskPending } from '../../../../lib/analysis';
import { useRerunAnalysisMutation } from '../../../../queries/datasets/analysis/tasks';
import { useClassDataQualityQuery } from '../../../../queries/datasets/statistics/data-quality';
import { EmptyState } from '../../../common/EmptyState';
import { ErrorState } from '../../../common/ErrorState';
import { ClassQualityScore } from './ClassQualityScore';
import { ClassQualityScoreHeader } from './ClassQualityScoreHeader';
import { handleReRunDataQualityScore } from './RerunDataQualityScore';

interface ClassQualityScoreContainerProps {
  datasetId: string;
  dataQualityScore: number;
}

export const ClassQualityScoreContainer = ({
  datasetId,
  dataQualityScore,
}: ClassQualityScoreContainerProps): JSX.Element => {
  const { isLoading, isError, data, isIdle } = useClassDataQualityQuery(datasetId);
  const { mutate: retriggerDataQualityAnalysis, isLoading: isRetriggering } =
    useRerunAnalysisMutation(datasetId);

  const handleRetrigger = () => {
    handleReRunDataQualityScore(retriggerDataQualityAnalysis);
  };

  if (isLoading || isIdle) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" size="lg" text="Loading feature statistics" />
      </Center>
    );
  }

  if (isError || !data) {
    const errorMsg = 'There was an error retrieving Markov quality score by class';
    return (
      <ErrorState
        compact
        title=""
        subtitle={errorMsg}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  const analysisState = data.analysisState;

  // Quality score may be negative/unavailable if manually stopped (distinct from error case)
  if (analysisState === AnalysisStateNames.Error || dataQualityScore < 0) {
    const message =
      dataQualityScore < 0
        ? 'Markov quality score not available'
        : 'There was an error while computing Markov quality score results';
    return (
      <ErrorState
        compact
        title=""
        subtitle={message}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  // Handle case when analyzer results aren't available yet
  if (getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Markov quality score by class analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        icon={<AnalyzingSpinner />}
      />
    );
  }

  const classDataQualityData = data?.response;

  return (
    <Flex direction="column">
      <ClassQualityScoreHeader dataQualityScore={dataQualityScore} />
      <ClassQualityScore classDataQualityData={classDataQualityData} />
    </Flex>
  );
};
