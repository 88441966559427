import { get } from 'lodash';
import {
  Alert,
  Button,
  CopyButton,
  Flex,
  Mark,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useForm,
} from '../../../design-system/v2';
import { CreateConnectorRequest, StorageType } from '../../../generated/api';
import { useCreateConnectorMutation } from '../../../queries/connectors';
import { CreateConnectorModalActions } from './CreateConnectorModalActions';

const GOOGLE_SPREADSHEET_URL_REGEX =
  /https?:\/\/(docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+)/;

const getGoogleSheetConnectorEmail = () => import.meta.env.VITE_APP_MARKOV_GOOGLESHEET_EMAIL ?? '';

interface GoogleSheetModalProps {
  onClickBack?: any;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const GoogleSheetModal = ({
  onClickBack,
  onCreate,
  onClose = closeAllModals,
}: GoogleSheetModalProps) => {
  const form = useForm({
    initialValues: {
      name: '',
      sheetUrl: '',
    },
    validate: {
      name: value => {
        if (!value.trim()) {
          return 'Name cannot be empty';
        }
        return null;
      },
      sheetUrl: value => {
        if (!value.trim()) {
          return 'Sheet URL cannot be empty';
        }

        if (!value.match(GOOGLE_SPREADSHEET_URL_REGEX)) {
          return 'Supplied URL does not seem to be a valid URL';
        }

        return null;
      },
    },
    transformValues: values => ({
      name: values.name.trim(),
      sheetUrl: values.sheetUrl.trim(),
    }),
  });

  const { mutate: createConnector, isLoading } = useCreateConnectorMutation();

  const handleCreateConnector = () => {
    if (form.validate().hasErrors) {
      return;
    }

    const createReq: CreateConnectorRequest = {
      name: form.getTransformedValues().name,
      connectorType: StorageType.GoogleSheet,
      credData: {},
      connectorMetadata: {
        spreadsheetUrl: form.getTransformedValues().sheetUrl,
      },
    };

    createConnector(createReq, {
      onError: err => {
        const displayError = get(
          err,
          'response.data.message.description',
          'Unable to create connector',
        );
        notifications.error(displayError);
      },
      onSuccess: res => {
        notifications.success('Connector created successfully');
        const connectorId = res.data.response?.connectorId ?? '';
        onCreate?.(connectorId);
        onClose();
      },
    });
  };

  const googleSheetConnectorEmail = getGoogleSheetConnectorEmail();

  return (
    <Vertical>
      <Alert>
        <Flex>
          <Text sx={{ flexGrow: 1 }}>
            Please share the required Google Sheet with <br />
            <Mark color="green.4">{googleSheetConnectorEmail}</Mark>&nbsp; to allow access to sheet
            contents. <br />
            Then copy over the URL for sheet and paste it here.
          </Text>

          <CopyButton value={googleSheetConnectorEmail}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied URL' : 'Copy URL'}
              </Button>
            )}
          </CopyButton>
        </Flex>
      </Alert>
      <TextInput
        ariaLabel="Connector name"
        label="Connector name"
        placeholder="Enter name of the connector"
        {...form.getInputProps('name')}
        autoFocus
        maw={300}
        required
      />
      <TextInput
        ariaLabel="Google Sheet URL"
        label="Google Sheet URL"
        placeholder="Enter the Google Sheet URL you want to share"
        {...form.getInputProps('sheetUrl')}
        autoFocus
        required
      />

      <CreateConnectorModalActions
        onClickBack={onClickBack}
        onCreate={handleCreateConnector}
        onClose={onClose}
        isCreating={isLoading}
      />
    </Vertical>
  );
};
