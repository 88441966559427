import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { inAppApi } from '../../lib/api';
import { NOTIFICATIONS, notificationKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

const NOTIFICATION_API_PAGE_SIZE = 7;

export const useGetNotificationsQuery = () => {
  const { userId, workspaceId } = useAppMetadata();
  return useInfiniteQuery(
    notificationKeys.list(workspaceId, userId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * NOTIFICATION_API_PAGE_SIZE,
        end = start + NOTIFICATION_API_PAGE_SIZE;
      return inAppApi.getAllInAppNotificationsV1(workspaceId, userId, start, end, lastTimestamp);
    },
    {
      getNextPageParam: getNextPageParamHandler(NOTIFICATION_API_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(userId && workspaceId),
      refetchInterval: 30 * 1000,
    },
  );
};

export const useGetUnreadNotificationsCountQuery = () => {
  const { userId, workspaceId } = useAppMetadata();
  return useQuery(
    [NOTIFICATIONS.NOTIFICATIONS_COUNT, workspaceId, userId],
    () => inAppApi.getInAppNotificationRecordCountV1(workspaceId, userId, false),
    {
      select: data => data.data.count,
      enabled: Boolean(userId && workspaceId),
      refetchInterval: 30 * 1000,
    },
  );
};

export const useMarkNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();
  const { userId, workspaceId } = useAppMetadata();
  return useMutation(
    (vars: { recordIds: string[]; read: boolean }) => inAppApi.updateInAppNotificationsV1(vars),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(notificationKeys.list(workspaceId, userId));
        queryClient.invalidateQueries([NOTIFICATIONS.NOTIFICATIONS_COUNT, workspaceId, userId]);
      },
    },
  );
};

export const useMarkAllNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();
  const { userId, workspaceId } = useAppMetadata();
  return useMutation(() => inAppApi.updateAllInAppNotificationAsReadV1({ workspaceId, userId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKeys.list(workspaceId, userId));
      queryClient.invalidateQueries([NOTIFICATIONS.NOTIFICATIONS_COUNT, workspaceId, userId]);
    },
  });
};
