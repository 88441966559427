import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer, nodePasteRule } from '@tiptap/react';
import { IframeView } from './IframeView';

export interface IframeOptions {
  allowFullscreen: boolean;
  height: number;
  width: number;
  HTMLAttributes: {
    [key: string]: any;
  };
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string }) => ReturnType;
    };
  }
}

const YOUTUBE_REGEX =
  /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/g;

const LOOM_REGEX = /^https:\/\/www\.loom\.com\/.*/g;

const VIMEO_REGEX =
  /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/g;

const DRIVE_REGEX = /^https:\/\/drive\.google\.com\/.*/g;

export const Iframe = Node.create<IframeOptions>({
  name: 'iframe',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      allowFullscreen: true,
      height: 480,
      width: 640,
      HTMLAttributes: {
        class: 'iframe-wrapper',
      },
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 2,
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
      dataAlign: {
        default: 'left', // 'start' | 'center' | 'end'
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]];
  },

  addCommands() {
    return {
      setIframe:
        (options: { src: string }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create(options);

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: YOUTUBE_REGEX,
        type: this.type,
        getAttributes: match => ({ src: match.input }),
      }),
      nodePasteRule({
        find: LOOM_REGEX,
        type: this.type,
        getAttributes: match => ({ src: match.input }),
      }),
      nodePasteRule({
        find: VIMEO_REGEX,
        type: this.type,
        getAttributes: match => ({ src: match.input }),
      }),
      nodePasteRule({
        find: DRIVE_REGEX,
        type: this.type,
        getAttributes: match => ({ src: match.input }),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeView);
  },
});
