import { ModelAppStatus } from '../../generated/api';

export const getModelAppStatus = (status?: ModelAppStatus) => {
  switch (status) {
    case ModelAppStatus.Running:
      return {
        status: 'LIVE',
        color: 'green.6',
      };
    case ModelAppStatus.Stopped:
      return {
        status: 'STOPPED',
        color: 'red.6',
      };
    default:
      return {
        status: 'IN-PROGRESS',
        color: 'yellow.6',
      };
  }
};
