import { loadScript } from './script-loader';

declare global {
  interface Window {
    gapi: any;
    google: any;
  }
}

//TODO:: Move to env var after testing is complete
const API_KEY = 'AIzaSyClaAC17selXEmI6E1m1NiyWjjoPlvPlNo';
const APP_ID = '843266417719';

export const loadGoogleIdentityServiceApi = () =>
  new Promise<void>((resolve, reject) => {
    loadScript('https://accounts.google.com/gsi/client', resolve);
  });

export const loadPickerApi = (onApiLoad: () => void) =>
  loadScript('https://apis.google.com/js/api.js', () => {
    window.gapi.load('picker', onApiLoad);
  });

export const authorizeGoogleAccount = async (url: string) => {
  window.location.href = url;
};

export const loadAndShowPicker = (accessToken: string, onPickerLoad: (data: any) => void) => {
  const docsView = new window.google.picker.DocsView()
    .setIncludeFolders(true)
    .setMimeTypes('application/vnd.google-apps.folder')
    .setSelectFolderEnabled(true);

  const picker = new window.google.picker.PickerBuilder()
    .addView(docsView)
    .setOAuthToken(accessToken)
    .setDeveloperKey(API_KEY)
    .setCallback(onPickerLoad)
    .setAppId(APP_ID)
    .build();

  picker.setVisible(true);

  return picker;
};
