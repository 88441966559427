import { CellProps } from 'react-table';
import { Text, TextVariants } from '../../../design-system/v2';
import { EvaluationWithTags } from '../../../generated/api';
import { formatDateLong, formatDateTime } from '../../../lib/ui';

interface EvaluationsDateCellProps extends CellProps<EvaluationWithTags> {
  textVariant: TextVariants;
}

export const EvaluationsDateCell: React.FC<EvaluationsDateCellProps> = ({
  value: createDate,
  textVariant,
}) => (
  <Text variant={textVariant} color="dark.4" title={formatDateTime(createDate)}>
    {createDate ? formatDateLong(createDate) : ''}
  </Text>
);
