import { useInfiniteQuery } from 'react-query';
import { usePublicSnippetDetail } from '../components/snippets/detail/context/PublicSnippetContext';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import { evaluationsApi, recordingsApi } from '../lib/api';
import { publicApis } from '../lib/api/vienna';
import { API_PAGE_SIZE } from './constants';
import { EVALUATION } from './queryConstants';

export const useComparisonDisagreementsInfiniteQuery = (recordingIds: string[]) => {
  const { workspaceId } = useAppMetadata();
  // This is needed so react-query will dedupe queries where the set of IDs is the same
  // but the order is different
  const recordingIdsMap = recordingIds.reduce(
    (map, recId) => ({ ...map, [recId]: true }),
    {} as Record<string, boolean>,
  );

  return useInfiniteQuery(
    ['infinite-comparison-disagreements', workspaceId, { recordingIdsMap }],
    ({ pageParam: pageNumber = 0 }) => {
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE - 1;
      return recordingsApi.workspaceGetComparisonDisagreementV1(
        workspaceId,
        start,
        end,
        recordingIds,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const response = lastPage.data.response;
        const totalPages = Math.ceil(response.numRecords / API_PAGE_SIZE);
        return pages.length < totalPages ? pages.length : undefined;
      },
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(res => res.data.response),
      }),
    },
  );
};

export const useEvalComparisonDisagreementsInfiniteQuery = (
  evaluationIds: string[],
  showOnlyAllIncorrect = false,
) => {
  const { workspaceId } = useAppMetadata();
  const { chartId, snippetId } = usePublicSnippetDetail();

  return useInfiniteQuery(
    [
      EVALUATION.LIST_EVAL_COMPARISON_DISGREEMENTS_INFINITE,
      workspaceId,
      { evaluationIds, showOnlyAllIncorrect },
    ],
    chartId
      ? async ({ pageParam = {} }) => {
          const { pageNumber = 0 } = pageParam;
          const start = pageNumber * API_PAGE_SIZE,
            end = start + API_PAGE_SIZE;
          const response = await publicApis.getChartPublicSnippetV1(snippetId, chartId, start, end);
          return response.data;
        }
      : ({ pageParam = {} }) => {
          const { pageNumber = 0 } = pageParam;
          const start = pageNumber * API_PAGE_SIZE,
            end = start + API_PAGE_SIZE;
          return evaluationsApi.getDisagreementsForEvaluationsV1(
            workspaceId,
            evaluationIds,
            showOnlyAllIncorrect,
            start,
            end,
          );
        },
    {
      enabled: chartId ? !!chartId : Boolean(workspaceId && evaluationIds.length),
      getNextPageParam: (lastPage, pages) => {
        const { numRecords } = lastPage.data;
        const totalPages = Math.ceil(numRecords / API_PAGE_SIZE);
        return pages.length < totalPages ? { pageNumber: pages.length } : undefined;
      },
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(res => res.data.response),
      }),
    },
  );
};

export const useDisagreementsInfiniteQuery = (evaluationId: string) => {
  const evaluationIds = [evaluationId];
  const allDisagreements = false;

  return useEvalComparisonDisagreementsInfiniteQuery(evaluationIds, allDisagreements);
};
