import noop from 'lodash/noop';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThreadProps } from '../../components/common/chat-with-data/menu/panels/threads-panel/ThreadsItem';

export interface ThreadsItemContextProps {
  activeThread: ThreadProps | undefined;
  setActiveThread: Dispatch<SetStateAction<ThreadProps | undefined>>;
}

export const defaultThreadValues: ThreadsItemContextProps = {
  activeThread: undefined,
  setActiveThread: noop,
};

export interface ThreadsItemProviderProps {
  thread?: ThreadProps;
}

const ThreadsItemContext = createContext<ThreadsItemContextProps>(defaultThreadValues);

export const useThreadsItemContext = () => {
  const context = useContext(ThreadsItemContext);
  if (!context) {
    throw new Error('useThreadsItemContext should only be used inside the ThreadsItemProvider');
  }
  return context;
};

export const ThreadsItemProvider = ({
  thread,
  children,
}: PropsWithChildren<ThreadsItemProviderProps>) => {
  const [activeThread, setActiveThread] = useState<ThreadProps | undefined>(
    thread ?? defaultThreadValues.activeThread,
  );

  /**
   * When ThreadsItemProvider re-renders, but it is not unmounted, we still want the value of
   * activetThread to update. Hence, the useEffect. This was observed with GenAI apps inside
   * Copy-edit
   */
  useEffect(() => {
    if (thread) {
      setActiveThread(thread);
    }
  }, [thread]);

  return (
    <ThreadsItemContext.Provider value={{ activeThread, setActiveThread }}>
      {children}
    </ThreadsItemContext.Provider>
  );
};
