import css from '@styled-system/css';
import styled from 'styled-components';
import Box from '../Box';

// The gap property lets the flex parent easily define spacing for its children.
// Provide two values to define distinct row-gap and column-gap sizes.
interface RowProps {
  gap?: string | number;
}

// Note: Currently Row extends Box, which already supports flex-related props.
// In the future, we may want to reserve flex props for Row and Col components.
export const Row = styled(Box)<RowProps>(props =>
  css({
    display: 'flex',
    gap: props.gap || 0,
  }),
);
