import { StopIllustration } from '../../../design-system';
import { Button, Center, Text, Vertical } from '../../../design-system/v2';
import { useGenerateAppModal } from '../generate-model-app/useGenerateAppModal';

interface StoppedModelAppProps {
  modelId: string;
  hideRestartBtn?: boolean;
  height?: string | number;
}

export const StoppedModelApp = ({
  modelId,
  hideRestartBtn,
  height = 500,
}: StoppedModelAppProps) => {
  const { open: openGenerateAppModal } = useGenerateAppModal({ modelId });

  const handleRegenerateApp = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    openGenerateAppModal({ isRestart: true });
  };
  return (
    <Center w="100%" h={height}>
      <Vertical spacing="lg" h="100%" align="center" justify="center">
        <StopIllustration />
        <Text variant="heading03" color="gray.8">
          Your app is stopped
        </Text>
        <Text variant="bodyShort02" color="gray.6" w={400} align="center">
          The app has been stopped as it ran past its selected runtime.
        </Text>
        {!hideRestartBtn && (
          <Button variant="outline" onClick={handleRegenerateApp}>
            Restart the app
          </Button>
        )}
      </Vertical>
    </Center>
  );
};
