import { Box } from '../../../../design-system';
import { Skeleton } from '../../../../design-system/v2';

const ActivityItemLoading = (): JSX.Element => (
  <Box p={12} borderBottom="thinSolid" borderColor="muted.shade1">
    <Box display="flex" alignItems="center">
      <Skeleton circle height={32} />

      <Skeleton height={16} width={320} ml={8} />
    </Box>
  </Box>
);

const ACTIVITY_NUMBER = 5;

export const ActivityLoading = (): JSX.Element => (
  <>
    {Array.from({ length: ACTIVITY_NUMBER }).map((_, index) => (
      <ActivityItemLoading key={`activity-loading-${index}`} />
    ))}
  </>
);
