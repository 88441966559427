import {
  AnalysisGroupedByMetricModel,
  AnalysisMetricModel,
  SegmentType,
} from '../../generated/api';
import { capitalize } from '../../lib/ui';

export const getAnalysersFromGroupedMetrics = (
  analysisGroupedByMetricModels: AnalysisGroupedByMetricModel[],
) => analysisGroupedByMetricModels.flatMap(analyserMetricModel => analyserMetricModel.metrics);

export const resolveCurrentClassesAvailable = (analysers: AnalysisMetricModel[]) =>
  analysers
    .flatMap(metric =>
      metric.segments.flatMap(segment => segment.metadata.flatMap(mtdata => mtdata.classes)),
    )
    .filter((classType, idx, self) => !!classType && self.indexOf(classType) === idx) as string[];

/**
 * The function returns the UI formatted text for the segment that backend sends
 */
export const getSegmentText = (segment: SegmentType | string): string =>
  capitalize(segment === SegmentType.Unknown ? 'Unsplit' : segment.toString());
