import { IconFileText, IconTrashX } from '@tabler/icons-react';
import { first } from 'lodash';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Dropzone,
  FileWithPath,
  Grid,
  Horizontal,
  MIME_TYPES,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../design-system/v2';

interface FileUploadInputProps {
  supportedFilesTypes?: string[];
  file?: FileWithPath;
  onFileDrop: (file: FileWithPath) => void;
  onFileDelete: () => void;
}

export const FileUploadInput = ({
  file,
  supportedFilesTypes,
  onFileDrop,
  onFileDelete,
}: FileUploadInputProps) => {
  const theme = useMarkovTheme();

  const handleFileOnDrop = (files: FileWithPath[]) => {
    const file = first(files);

    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileDelete = () => {
    onFileDelete();
  };

  return (
    <Grid>
      <Grid.Col span="auto">
        <Dropzone
          accept={{
            [MIME_TYPES.csv]: ['.csv'],
            'text/plain': ['.txt'],
            'text/tab-separated-values': ['.tsv'],
          }}
          onDrop={handleFileOnDrop}
          py={32}
        >
          {file ? (
            <Box bg="blue.0" p={12}>
              <Horizontal>
                <IconFileText color={theme.colors.gray[6]} />
                <Text variant="textLink">{file.name}</Text>
              </Horizontal>
            </Box>
          ) : (
            <Center>
              <Vertical align="center" spacing="sm">
                {supportedFilesTypes && (
                  <Text variant="bodyShort03">
                    Supported file formats: {supportedFilesTypes.join(', ')}
                  </Text>
                )}
                <Vertical spacing={0} align="center">
                  <Text variant="subTitle04" color="gray.7">
                    Drag and drop to upload your file
                  </Text>
                  <Text variant="subTitle04" color="gray.7">
                    or
                  </Text>
                </Vertical>
                <Button variant="outline">Choose a file</Button>
              </Vertical>
            </Center>
          )}
        </Dropzone>
      </Grid.Col>
      {file && (
        <Grid.Col span="content">
          <Center h="100%">
            <ActionIcon onClick={handleFileDelete}>
              <IconTrashX />
            </ActionIcon>
          </Center>
        </Grid.Col>
      )}
    </Grid>
  );
};
