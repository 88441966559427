import { useMemo } from 'react';
import { Alert, Center, Loader } from '../../design-system/v2';
import { useDisagreementsInfiniteQuery } from '../../queries/disagreements';
import { EmptyState } from '../common/EmptyState';
import { DisagreementTable } from './DisagreementTable';

interface DisagreementTableContainerProps {
  recordingId: string;
}

export const DisagreementTableContainer = ({
  recordingId,
}: DisagreementTableContainerProps): JSX.Element => {
  const { isLoading, isFetchingNextPage, isError, data, fetchNextPage, hasNextPage } =
    useDisagreementsInfiniteQuery(recordingId);

  const disagreements = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  if (isLoading) {
    return (
      <Center h={340}>
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return <Alert color="red">There was an error fetching disagreements</Alert>;
  }

  if (!disagreements.length) {
    return (
      <EmptyState
        title="No disagreements found"
        subtitle="No model disagreements were found for this evaluation"
        iconName="evaluation"
      />
    );
  }

  return (
    <DisagreementTable
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      disagreements={disagreements}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
