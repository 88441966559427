import { Alert, Center, Image, Loader } from '../../../../../../design-system/v2';
import { useGetS3PresignedUrlForFileQuery } from '../../../../../../queries/workflows/s3-preview';

interface ImagePreviewProps {
  url: string;
  workflowId: string;
}

export const ImagePreview = ({ url, workflowId }: ImagePreviewProps) => {
  const { isLoading, isError, data: blob } = useGetS3PresignedUrlForFileQuery(workflowId, url);

  if (isLoading) {
    return (
      <Center w={400} h={600}>
        <Loader />
      </Center>
    );
  }

  if (isError || !blob) {
    return (
      <Center w={400} h={600}>
        <Alert color="red">Not able to load file</Alert>
      </Center>
    );
  }

  return <Image src={URL.createObjectURL(blob)} />;
};
