import { Navigate, RouteProps, Routes } from 'react-router-dom';
import AppBuilderDetailsPage from '../components/app-builder/details/AppBuilderDetailsPage';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AppRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const AppBuilderHomePage = lazyWithRetry(() => import('../pages/AppBuilder/GenAIAppBuilderPage'));

const appBuilderRoutes: RouteProps[] = [
  {
    path: '/',
    element: <AppBuilderHomePage />,
  },
  {
    path: `${getRoute(AppRoutes.APP_BUILDER_DETAILS)}`,
    element: <AppBuilderDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const AppBuilderRouter = (): JSX.Element => (
  <Routes>{appBuilderRoutes.map(renderAuthRoute)}</Routes>
);
