import { useEffect } from 'react';
import { useAuthLogin } from '../contexts/auth-provider/Auth';
import { FullPageLoader } from '../design-system';

export const SignupPage = (): JSX.Element => {
  const { handleSignupRedirect } = useAuthLogin();

  useEffect(() => {
    handleSignupRedirect();
  }, [handleSignupRedirect]);

  return <FullPageLoader text="Loading..." />;
};
