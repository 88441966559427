import { Alert, Center, Grid, IconAlertCircle, Loader, ScrollArea } from '../../design-system/v2';
import { useCustomMetricsQuery } from '../../queries/customMetrics';
import { CustomMetricCard } from './CustomMetricCard';

interface CustomMetricsGroupContainerProps {
  recordingId: string;
}

export const CustomMetricsGroupContainer = ({
  recordingId,
}: CustomMetricsGroupContainerProps): JSX.Element => {
  const { isLoading, isError, data: customMetrics } = useCustomMetricsQuery(recordingId);

  if (isLoading) {
    return (
      <Center h={200}>
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert icon={<IconAlertCircle size={20} />} color="red">
        Error retrieving custom metrics for this evaluation.
      </Alert>
    );
  }

  if (!customMetrics?.length) {
    return <Alert>There are no custom metrics associated with this evaluation.</Alert>;
  }

  return (
    <ScrollArea.Autosize mah={500} p="xl">
      <Grid columns={12} m={0}>
        {customMetrics?.map(({ name, statistics }) => (
          <Grid.Col key={name} span={6} md={4}>
            <CustomMetricCard name={name} stats={statistics} />
          </Grid.Col>
        ))}
      </Grid>
    </ScrollArea.Autosize>
  );
};
