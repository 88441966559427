import { DataLabelingListWrapper } from '../../components/data-labeling/DataLabelingList.wrapper';
import { useShowSideBar } from '../home/WorkspaceShell';

const DataLabeling = () => {
  useShowSideBar(true);

  return <DataLabelingListWrapper />;
};

export default DataLabeling;
