import { AnalyzingSpinner } from '../../design-system';
import { Horizontal, Text } from '../../design-system/v2';
import { RunStatus } from '../../generated/api';
import { StyledBubbles } from '../common/TableWrapper';

interface ExperimentStatusProps {
  runStatus?: string;
}

export const ExperimentStatus = ({ runStatus }: ExperimentStatusProps): JSX.Element => {
  switch (runStatus) {
    case RunStatus.Start:
      return (
        <Horizontal spacing="xs" noWrap>
          <AnalyzingSpinner />
          <Text variant="small01" color="dark.5" ml="8px">
            Running...
          </Text>
        </Horizontal>
      );

    case RunStatus.Finished:
      return (
        <Horizontal spacing="xs" noWrap>
          <StyledBubbles />
          <Text variant="small01" color="dark.5" ml="8px">
            Finished
          </Text>
        </Horizontal>
      );

    default:
      return (
        <Text variant="small01" color="dark.5" ml="8px">
          Unknown
        </Text>
      );
  }
};
