import { createStyles } from '@mantine/styles';

export const usePublicSnippetEditorStyles = createStyles(theme => ({
  root: {
    border: 'none',
    marginTop: '12px',
    '.main-editor': {
      // Hiding comments in public snippets.
      'mark[data-conversation]': {
        backgroundColor: 'transparent',
      },
    },
  },

  content: {
    backgroundColor: 'transparent',
    color: theme.colors.gray[8],
    p: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBlock: '4px',
    },
  },
}));
