import { MantineTheme } from '@mantine/core';

export const tooltipTheme = {
  styles: (theme: MantineTheme) => ({
    tooltip: {
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      lineHeight: 1.4,
    },
  }),
};
