import { DataCategory } from '../generated/api';

export const API_PAGE_SIZE = 20;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Please try again later.';

// IDs for when reference to specific tasklets is needed
export enum AnalyzerTaskletEnum {
  CLASS_DISTRIBUTION = 'taCF1',
  SEGMENT_SIMILARITY = 'taSM01',
  DATA_PROFILING = 'taProf01',
  VECTOR_ANALYSIS_TEXT = 'taCL12',
  VECTOR_ANALYSIS_MIXED_CAT = 'tabCL1',
  DATA_QUALITY_SCORE = 'taQU01',
  BASELINE_MODEL = 'BSML01',
  TOPOGRAPHY = 'GRAML01',
  NGRAM = 'taWH1',
  NOUN_VERB = 'taWH3',
  SKIP_GRAM = 'taWH6',
  PROFANITY = 'taWH4',
  SENTIMENT = 'taWH5',
  SENTIMENT_COMPOUND_VALENCE = 'taWH16',
  ASPECT_SENTIMENT = 'taWH20',
  NER = 'taWH12',
  TFIDF_KEYWORD = 'taWH7',
  RAKE_KEYWORD = 'taWH8',
  YAKE_KEYWORD = 'taWH9',
  TEXT_RANK_KEYWORD = 'taWH13',
  TOPIC_RANK_KEYWORD = 'taWH14',
  PATTERN_RANK_KEYWORD = 'taWH17',
  NGRAM_LDA_TOPIC_MODEL = 'taTopM1',
  LDA_TOPIC_MODEL = 'taTopM3',
  LSI_TOPIC_MODEL = 'taTopM7',
  BERT_TOPIC_MODEL = 'taTopM9',
  TOPIC_MODEL_LDA_ALL = 'taTopM11',
}

export const getVectorAnalysisTaskletId = (
  dataCategory: DataCategory,
): AnalyzerTaskletEnum | null =>
  dataCategory === DataCategory.Text
    ? AnalyzerTaskletEnum.VECTOR_ANALYSIS_TEXT
    : dataCategory === DataCategory.Numerical ||
      dataCategory === DataCategory.MixedOrCategorical ||
      dataCategory === DataCategory.MixedCatUnlabeledTarget
    ? AnalyzerTaskletEnum.VECTOR_ANALYSIS_MIXED_CAT
    : null;

export const UNLABELED_DATA_TARGET = '__markov_unlabelled__';
