import { useNavigate as useNavigateReact } from 'react-router-dom';

// Wrapper around react-router's useNavigate hook to support opening a route in a
// new browser tab using Cmd + click (or Ctrl + click on Windows).
export const useMarkovNavigate = () => {
  const navigate = useNavigateReact();
  const getNavigateClickHandler = (route: string) => (e: React.MouseEvent) => {
    if (e.metaKey) {
      window.open(window.location.origin + route);
    } else {
      navigate(route);
    }
  };
  return getNavigateClickHandler;
};
