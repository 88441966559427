import { IconCheck } from '@tabler/icons-react';
import {
  Button,
  Center,
  Loader,
  Text,
  Vertical,
  notifications,
  useMarkovTheme,
} from '../../../../design-system/v2';
import {
  useGetSlackChannelQuery,
  useIntegrationStatusQuery,
  useSlackBotInstallMutation,
} from '../../../../queries/notifications/slack-notifications';
import { SlackConfigureModal } from './SlackConfigureModal';
import { getSlackUrl } from './slackIntegration.util';

export const SlackAuthenticationContainer = (): JSX.Element => {
  const theme = useMarkovTheme();

  const { mutateAsync: installSlack } = useSlackBotInstallMutation();
  const { data, isLoading, isError } = useIntegrationStatusQuery();
  const {
    data: slackChannelDetails,
    isLoading: slackChannelLoading,
    isFetching: slackChannelFetching,
  } = useGetSlackChannelQuery();

  const slackChannelId = slackChannelDetails?.channelId || '';
  const slackTeamId = slackChannelDetails?.teamId || '';
  const isIntegrated: boolean = data?.slack ?? false;

  if (isLoading || slackChannelLoading || slackChannelFetching) {
    return (
      <Center>
        <Loader size="sm" mt="xl" />
      </Center>
    );
  }

  const buttonText = isIntegrated ? 'Open Slack' : 'Add Slack';
  const getSlackLink = getSlackUrl({ team: slackTeamId ?? '', id: slackChannelId ?? '' });

  const handleButtonClick = async () => {
    if (isIntegrated) {
      window.open(getSlackLink, '_self');
      return;
    }
    try {
      const result = await installSlack();
      window.open(result.data.slackBotInstallUrl, '_self');
    } catch (error) {
      notifications.show({
        variant: 'error',
        message: 'Error occurred while initiating Slack installation.',
      });
    }
  };

  return (
    <Vertical spacing="md" align="center">
      <Button
        variant={isIntegrated ? 'subtle' : 'outline'}
        sx={{ backgroundColor: isIntegrated ? theme.colors.green[0] : theme.colors.white[0] }}
        fullWidth
        mt="xxl"
        shape="sharp"
        onClick={handleButtonClick}
        disabled={isError}
        leftIcon={isIntegrated && <IconCheck size={30} color={theme.colors.green[6]} />}
      >
        <Text
          variant="subTitle03"
          color={isIntegrated ? theme.colors.green[6] : theme.colors.blue[6]}
        >
          {buttonText}
        </Text>
      </Button>
      {isIntegrated && <SlackConfigureModal slackChannelId={slackChannelId} />}
    </Vertical>
  );
};
