import { IconInfoCircle } from '@tabler/icons-react';
import { copyEditEvents } from '../../../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { FullPageLoader } from '../../../../../design-system';
import {
  Alert,
  Button,
  closeAllModals,
  Horizontal,
  notifications,
  openModal,
  TextArea,
  TextInput,
  useInputState,
  Vertical,
} from '../../../../../design-system/v2';
import { CreateGenAIProjectRequest } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useCreateAppProjectMutation } from '../../../../../queries/document-ai/document';

interface CreateNewProjectModalProps {
  appId: string;
}

const CreateNewProjectModal = ({ appId }: CreateNewProjectModalProps) => {
  const [projectName, setProjectName] = useInputState('');
  const [projectDescription, setProjectDescription] = useInputState('');
  const { workspaceId } = useAppMetadata();

  const { mutateAsync, isLoading } = useCreateAppProjectMutation(appId);

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleSubmitNewModal = async () => {
    const req: CreateGenAIProjectRequest = {
      projectName: projectName.trim(),
      projectDescription: projectDescription.trim(),
    };
    mutateAsync(req)
      .then(() => {
        notifications.success('New project created successfully');
        closeAllModals();
      })
      .catch(() => {
        notifications.error('There was an issue creating new project');
      });
  };
  const handleCancelButtonClick = () => {
    sendAnalytics(copyEditEvents.project.cancel({ workspaceId }));
    handleCloseModal();
  };

  const handleCreateButtonClick = () => {
    sendAnalytics(copyEditEvents.project.create({ workspaceId }));
    handleSubmitNewModal();
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Creating a new project..." />}
      <Vertical spacing="xl">
        <Alert icon={<IconInfoCircle size={14} />} title="Projects help organize your documents">
          Add a suitable name and description so it is easy to find your documents later.
        </Alert>
        <TextInput
          required
          ariaLabel="Project Name"
          label="Project Name"
          placeholder="Project Name"
          value={projectName}
          onChange={setProjectName}
        />

        <TextArea
          ariaLabel="Project Description"
          label="Project Description"
          placeholder="Project description"
          minRows={2}
          maxRows={4}
          value={projectDescription}
          onChange={setProjectDescription}
        />

        <Horizontal position="right">
          <Button variant="secondary" onClick={handleCancelButtonClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateButtonClick}>
            Create
          </Button>
        </Horizontal>
      </Vertical>
    </>
  );
};

export const useNewAppProjectModal = (appId: string) => ({
  openNewAppProjectModal: () =>
    openModal({
      size: 'lg',
      title: 'Create New Project',
      children: <CreateNewProjectModal appId={appId} />,
    }),
});
