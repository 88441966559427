import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ModelAppsBlockContainer } from '../../../../../components/snippets/model-apps/ModelAppsBlock.container';
import { ActionIcon, Box, Card, useHover } from '../../../../v2';
import { IconTrash } from '@tabler/icons-react';
import { useSnippetDetail } from '../../../../../components/snippets/detail/context/SnippetContext';
import { ModelAppsPublicBlockContainer } from '../../../../../components/snippets/model-apps/ModelAppsPublicBlock.container';

export const ModelAppNodeView = ({ node, deleteNode }: NodeViewProps) => {
  const { hovered, ref } = useHover();
  const { modelId = '' } = node.attrs ?? {};

  const { isPublicSnippet } = useSnippetDetail();

  return (
    <NodeViewWrapper as={Box} maw={1000} pos="relative" ref={ref}>
      <Card m="sm" withBorder shadow="md">
        {isPublicSnippet ? (
          <ModelAppsPublicBlockContainer modelId={modelId} />
        ) : (
          <ModelAppsBlockContainer modelId={modelId} />
        )}
      </Card>

      {hovered && !isPublicSnippet && (
        <Card withBorder p="xs" pos="absolute" top={-37} right={12}>
          <ActionIcon title="Delete" onClick={deleteNode}>
            <IconTrash />
          </ActionIcon>
        </Card>
      )}
    </NodeViewWrapper>
  );
};
