import first from 'lodash/first';
import { Link } from 'react-router-dom';
import {
  ActionIcon,
  Avatar,
  Card,
  Horizontal,
  Indicator,
  Menu,
  Text,
  Tooltip,
} from '../../../../design-system/v2';
import { DEVELOPER_DOCS } from '../../../../developer-docs/links';
import { BaseUser } from '../../../../generated/api';
import { useMarkovNavigate } from '../../../../hooks/useMarkovNavigate';
import { getUserProfilePath } from '../../../../router/constants';
import { useLogout } from '../LogoutButton';
import { WorkspaceSwitcherWrapper } from './WorkspaceSwitcherWrapper';

const MENU_WIDTH = 350;

interface AccountMenuProps {
  userData: BaseUser;
  workspaceName: string;
  showIndicator: boolean;
}

export const AccountMenu = ({
  userData,
  workspaceName,
  showIndicator,
}: AccountMenuProps): JSX.Element => {
  const handleLogout = useLogout();
  const getNavigateClickHandler = useMarkovNavigate();

  const { userId = '', name: userName, email, avatar } = userData;
  const navigateToProfile = getNavigateClickHandler(getUserProfilePath(userId));

  return (
    <Menu offset={12} shadow="md" width={MENU_WIDTH}>
      <Menu.Target>
        <Indicator
          color="yellow.7"
          processing
          disabled={!showIndicator}
          aria-label={`${userName}'s Account Information`}
        >
          <Tooltip offset={12} label={workspaceName}>
            <Card bg="dark.5" py="xs" radius="8px">
              <Horizontal spacing="sm" w="126px" noWrap sx={{ cursor: 'pointer' }}>
                <Text
                  w="100%"
                  variant="small02"
                  color="gray.0"
                  sx={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {workspaceName}
                </Text>
                <ActionIcon variant="transparent" aria-label="Open account menu">
                  <Avatar size="sm" src={avatar} alt={userName ?? 'M'}>
                    {first(userName)}
                  </Avatar>
                </ActionIcon>
              </Horizontal>
            </Card>
          </Tooltip>
        </Indicator>
      </Menu.Target>

      <Menu.Dropdown>
        <WorkspaceSwitcherWrapper
          workspaceName={workspaceName}
          userEmail={email}
          showIndicator={showIndicator}
        />
        <Menu.Item
          py="md"
          component={Link}
          to={DEVELOPER_DOCS}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open help docs"
        >
          Help docs
        </Menu.Item>
        <Menu.Item py="md" onClick={navigateToProfile} aria-label="View profile">
          Profile
        </Menu.Item>
        <Menu.Item py="md" onClick={handleLogout} aria-label="Logout">
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
