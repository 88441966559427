import { Flex } from '@mantine/core';
import { Skeleton, Tag } from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { TagMetadata } from '../../../../generated/api';
import { Dataset } from '../../../../queries/datasets/list';
import { DEFAULT_ROW_HEIGHT } from './util';

export const TagsRenderer = ({ value, data }: ICellRendererParams<Dataset, TagMetadata[]>) => {
  if (!data) {
    return (
      <Flex align="center" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={100} height={30} />
      </Flex>
    );
  }

  return (
    <Flex align="center" h={DEFAULT_ROW_HEIGHT}>
      {value?.map(val => (
        <Tag key={val.tagId} name={val.name} />
      ))}
    </Flex>
  );
};
