import {
  ChatterRoutesApiFactory,
  ContentAddonsRoutesApiFactory,
  DataAnalyticsRoutesApiFactory,
} from '../../generated/api';
import { apiConfiguration } from './api';

export const chatterApi = ChatterRoutesApiFactory(apiConfiguration);

export const contentAddOnApi = ContentAddonsRoutesApiFactory(apiConfiguration);

export const dataAnalyticsApi = DataAnalyticsRoutesApiFactory(apiConfiguration);
