import { LayoutProps, RankedTester, VerticalLayout, rankWith, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Vertical } from '../../../../../design-system/v2';

export const VerticalLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  direction,
  label,
}: LayoutProps) => {
  if (!visible) {
    return null;
  }

  const verticalLayout = uischema as VerticalLayout;
  const uiSchemaOptions = uischema.options;

  return (
    <Vertical spacing={uiSchemaOptions?.spacing ?? 24}>
      {verticalLayout.elements.map((element, idx) => (
        <JsonFormsDispatch
          key={`${path}_${idx}`}
          uischema={element}
          schema={schema}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      ))}
    </Vertical>
  );
};

export const VerticalLayoutControl = withJsonFormsLayoutProps(VerticalLayoutComponent);

export const verticalControlTester: RankedTester = rankWith(1, uiTypeIs('VerticalLayout'));
