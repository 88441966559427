import { IconInfoCircle } from '@tabler/icons-react';
import {
  Badge,
  Grid,
  Horizontal,
  Progress,
  Text,
  Tooltip,
  Vertical,
} from '../../../../design-system/v2';
import { Subscription } from '../../../../generated/api';

interface UsageItemProps {
  title: string;
  usageNumber: number;
  usageLimit: number;
  usageText: string;
}

const UsageItem = ({ title, usageNumber, usageLimit, usageText }: UsageItemProps): JSX.Element => {
  const roundedUsageNumber = Math.round(usageNumber * 100) / 100;

  return (
    <Vertical spacing={0}>
      <Text variant="overline" color="gray.6" tt="uppercase">
        {title}
      </Text>
      <Horizontal position="apart" noWrap>
        <Horizontal spacing="xs" noWrap>
          <Text variant="small02">
            {roundedUsageNumber} / {usageLimit} units
          </Text>
          <Tooltip label={usageText}>
            <IconInfoCircle size={14} />
          </Tooltip>
        </Horizontal>
        <Tooltip label="You will be able to buy more units in the future" multiline>
          <Badge style={{ cursor: 'not-allowed' }} color="gray">
            Buy more
          </Badge>
          {/* Add button back when onClick is implemented */}
          {/* <Button size="xs" onClick={noop}>
            Buy more
          </Button> */}
        </Tooltip>
      </Horizontal>
      <Progress size="sm" mt="sm" radius="sm" value={(roundedUsageNumber / usageLimit) * 100} />
    </Vertical>
  );
};

interface UsageProps {
  subscription: Subscription;
}

export const Usages = ({ subscription }: UsageProps) => {
  const creditsUsed = subscription.usedUnits as any;
  const creditsLimit = subscription.usableUnits as any;

  return (
    <Grid>
      <Grid.Col xs={10}>
        <UsageItem
          title="COMPUTE"
          usageNumber={creditsUsed['compute']}
          usageLimit={creditsLimit['compute']}
          usageText="1 unit = 1 hour of compute usage"
        />
      </Grid.Col>
      <Grid.Col xs={10}>
        <UsageItem
          title="Storage"
          usageNumber={creditsUsed['storage']}
          usageLimit={creditsLimit['storage']}
          usageText="1 unit = 1 GB of storage usage"
        />
      </Grid.Col>
      <Grid.Col xs={10}>
        <UsageItem
          title="Tracking"
          usageNumber={creditsUsed['tracking']}
          usageLimit={creditsLimit['tracking']}
          usageText="1 unit = 1000 rows of tracking"
        />
      </Grid.Col>
      <Grid.Col xs={10}>
        <UsageItem
          title="Notebook"
          usageNumber={creditsUsed['notebook_compute']}
          usageLimit={creditsLimit['notebook_compute']}
          usageText="1 unit = 1 hour of notebook usage"
        />
      </Grid.Col>
    </Grid>
  );
};
