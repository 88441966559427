import { ChartMode, ScatterChartType } from '../../../../charts/providers/deck-gl/layers.util';
import { useDatasetClusterContext } from '../../../../contexts/datasets/DatasetClusterContext';
import { useViewStateContext } from '../../../../contexts/datasets/embeddings/ViewStateContext';
import { Switch } from '../../../../design-system/v2';

export const ViewModeSwitch = (): JSX.Element => {
  const { setChartMode, setScatterViewMode, setPointSize } = useDatasetClusterContext();
  const { setViewState } = useViewStateContext();

  const handleSelectScatterViewMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartMode(ChartMode.PAN);

    const is3DSelected = event.currentTarget.checked;

    setScatterViewMode(is3DSelected ? ScatterChartType.SCATTER_3D : ScatterChartType.SCATTER_2D);
    setViewState(undefined);

    setPointSize(is3DSelected ? 15 : 6);
  };

  return (
    <Switch
      size="xl"
      onLabel="3D"
      offLabel="2D"
      onChange={handleSelectScatterViewMode}
      sx={{ cursor: 'pointer' }}
    />
  );
};
