import noop from 'lodash/noop';
import { ReactNode } from 'react';
import { Box, Horizontal, Text, Tooltip } from '../../../design-system/v2';
import { ResourceRequest, ResourceResponse } from '../../../generated/api';
import { useResourceIcon } from './useResourceIcon';

interface ResourceItemProps {
  resource: ResourceResponse;
  onExploreResource: (request: ResourceRequest) => void;
  actions?: ReactNode;
  tooltipText?: string;
}

export const ResourceItem = ({
  resource: { key, value, isFinal },
  onExploreResource,
  actions,
  tooltipText,
}: ResourceItemProps): JSX.Element => {
  const getIcon = useResourceIcon();

  const handleExploreResource = () => {
    onExploreResource({ key, value });
  };

  const icon = getIcon(key);
  const clickable = !isFinal;

  return (
    <Tooltip withArrow label={tooltipText} openDelay={100} disabled={!tooltipText}>
      <Horizontal
        key={value}
        position="apart"
        py="md"
        px="lg"
        bg="white.0"
        mih={62}
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[2]}`,
          cursor: clickable ? 'pointer' : 'auto',
          ':hover': {
            background: theme.colors.blue[0],
          },
        })}
        onClick={clickable ? handleExploreResource : noop}
      >
        <Horizontal spacing="sm">
          {icon}
          <Text variant="subTitle04" color="gray.8">
            {value}
          </Text>
        </Horizontal>
        <Box
          // Needed to prevent triggering row click handler when clicking action
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
          }}
        >
          {actions}
        </Box>
      </Horizontal>
    </Tooltip>
  );
};
