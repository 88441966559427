import { IconInfoCircle, IconPlaylistAdd } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { FullPageLoader } from '../../../../../../design-system';
import {
  Alert,
  Button,
  Flex,
  Text,
  TextArea,
  TextInput,
  Vertical,
  closeAllModals,
  openModal,
  useInputState,
} from '../../../../../../design-system/v2';
import { CreateOrUpdateCustomDictionaryRequest } from '../../../../../../generated/api';
import { HTTPError } from '../../../../../../lib/api/api';
import { useAddEditCustomDictionaryMutation } from '../../../../../../queries/document-ai/copyEdit';

interface AddEditDictionarySetModalProps {
  dictionaryId?: string;
  initialData?: CreateOrUpdateCustomDictionaryRequest;
}

export const AddEditDictionarySetModal = ({
  dictionaryId,
  initialData,
}: AddEditDictionarySetModalProps) => {
  const [name, setName] = useInputState(initialData?.dictionaryName ?? '');
  const [description, setDescription] = useInputState(initialData?.dictionaryDescription ?? '');

  const { isLoading, mutate, error } = useAddEditCustomDictionaryMutation(dictionaryId);

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleAddDictionarySet = () => {
    const addDictionaryReq: CreateOrUpdateCustomDictionaryRequest = {
      dictionaryName: name.trim(),
      dictionaryDescription: description.trim(),
    };
    mutate(addDictionaryReq, {
      onSuccess() {
        closeAllModals();
      },
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Saving dictionary..." />}

      <Vertical>
        {error ? (
          <Alert icon={<IconInfoCircle size={14} />} title="Unable to add a new dictionary">
            {(error as AxiosError<HTTPError>)?.response?.data.detail ?? 'Please try again'}
          </Alert>
        ) : null}
        <TextInput
          required
          ariaLabel="Dictionary Name"
          label="Dictionary Name"
          placeholder="Dictionary Name"
          value={name}
          onChange={setName}
        />
        <TextArea
          ariaLabel="Dictionary Description"
          label="Dictionary Description"
          placeholder="Dictionary description"
          minRows={2}
          maxRows={4}
          value={description}
          onChange={setDescription}
        />
        <Flex justify="right" gap="sm">
          <Button variant="outline" color="gray.6" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!name} onClick={handleAddDictionarySet}>
            Confirm
          </Button>
        </Flex>
      </Vertical>
    </>
  );
};

export const AddDictionarySetButton = () => {
  const handleAddNewDictionary = () => {
    openModal({
      size: 'lg',
      title: <Text variant="heading03">Add Dictionary</Text>,
      children: <AddEditDictionarySetModal />,
    });
  };

  return (
    <Button onClick={handleAddNewDictionary} leftIcon={<IconPlaylistAdd />} variant="outline">
      Add Dictionary
    </Button>
  );
};
