import { useState } from 'react';
import { Box, Horizontal, ScrollArea, Tabs, Text } from '../../../design-system/v2';
import { DatasetOverview } from '../../../generated/api';
import { DataInsightsTable } from '../data-profiling/DataInsightsTable';
import { DataSummaryTable } from '../data-profiling/DataSummaryTable';

export enum DataSummaryType {
  OVERVIEW = 'OVERVIEW',
  INSIGHTS = 'INSIGHTS',
}

interface TabDetails {
  id: DataSummaryType;
  label: string;
}

const TABS: TabDetails[] = [
  {
    id: DataSummaryType.INSIGHTS,
    label: 'Insights',
  },
  {
    id: DataSummaryType.OVERVIEW,
    label: 'Overview',
  },
];

interface DataInsightsOverviewProps {
  summaryData: DatasetOverview;
  filterSummaryType?: DataSummaryType;
  hideHeader?: boolean;
}

export const DataInsightsOverview = ({
  summaryData,
  filterSummaryType,
  hideHeader = false,
}: DataInsightsOverviewProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<DataSummaryType>(
    filterSummaryType ?? DataSummaryType.INSIGHTS,
  );

  const insights = summaryData.insights ?? [];

  return (
    <Box>
      {!hideHeader && (
        <Horizontal
          sx={theme => ({
            borderBottom: `2px solid ${theme.fn.themeColor('gray.2')}`,
          })}
        >
          <Tabs value={activeTab} onTabChange={(tab: DataSummaryType) => setActiveTab(tab)}>
            <Horizontal noWrap spacing={0}>
              {TABS.map(({ id: tabId, label }) => {
                if (filterSummaryType && tabId !== filterSummaryType) {
                  return null;
                }
                return (
                  <Tabs.Tab p="16px 24px" key={tabId} size="md" value={tabId}>
                    <Text variant="subTitle03">{label}</Text>
                  </Tabs.Tab>
                );
              })}
            </Horizontal>
          </Tabs>
        </Horizontal>
      )}
      <ScrollArea p="24px">
        {(!filterSummaryType || filterSummaryType === DataSummaryType.OVERVIEW) &&
          activeTab === DataSummaryType.OVERVIEW && (
            <DataSummaryTable summary={summaryData} oneColumn={hideHeader} />
          )}
        {(!filterSummaryType || filterSummaryType === DataSummaryType.INSIGHTS) &&
          activeTab === DataSummaryType.INSIGHTS && (
            <DataInsightsTable insights={insights} oneColumn={hideHeader} />
          )}
      </ScrollArea>
    </Box>
  );
};
