/**
 * The type of unit used for reporting memory usage.
 */
export type MemoryUnit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';

/**
 * The number of bytes in each memory unit.
 */
export const MEMORY_UNIT_LIMITS: {
  readonly [U in MemoryUnit]: number;
} = {
  B: 1,
  KB: 1024,
  MB: 1048576,
  GB: 1073741824,
  TB: 1099511627776,
  PB: 1125899906842624,
};

/**
 * Given bumber of bytes, convert to GB
 * @param numBytes
 * @returns
 */
export const convertBytesToGB = (numBytes: number): number => numBytes / MEMORY_UNIT_LIMITS.GB;

/**
 * Given a number of bytes, convert to the most human-readable
 * format, (GB, TB, etc).
 *
 * @param numBytes The number of bytes.
 */
export const convertToLargestUnit = (numBytes: number): [number, MemoryUnit] => {
  if (numBytes < MEMORY_UNIT_LIMITS.KB) {
    return [numBytes, 'B'];
  } else if (MEMORY_UNIT_LIMITS.KB === numBytes || numBytes < MEMORY_UNIT_LIMITS.MB) {
    return [numBytes / MEMORY_UNIT_LIMITS.KB, 'KB'];
  } else if (MEMORY_UNIT_LIMITS.MB === numBytes || numBytes < MEMORY_UNIT_LIMITS.GB) {
    return [numBytes / MEMORY_UNIT_LIMITS.MB, 'MB'];
  } else if (MEMORY_UNIT_LIMITS.GB === numBytes || numBytes < MEMORY_UNIT_LIMITS.TB) {
    return [convertBytesToGB(numBytes), 'GB'];
  } else if (MEMORY_UNIT_LIMITS.TB === numBytes || numBytes < MEMORY_UNIT_LIMITS.PB) {
    return [numBytes / MEMORY_UNIT_LIMITS.TB, 'TB'];
  } else {
    return [numBytes / MEMORY_UNIT_LIMITS.PB, 'PB'];
  }
};
