import { AnalysisStateNames, DatasetTasklet, SegmentType } from '../generated/api';

// Determines if an analysis task-like object matches a given (tasklet, segmentType) pair
export const doTasksMatch = (
  task: { taskletId: string; segment: string },
  taskletId: string,
  segmentType: SegmentType,
): boolean => task.taskletId === taskletId && task.segment === segmentType;

// Filter tasklets by name (case-insensitive)
export const taskletsFilter = (tasklet: DatasetTasklet, filter: string): boolean =>
  tasklet.displayName.toLowerCase().includes(filter.toLowerCase());

export const getIsTaskPending = (status: AnalysisStateNames): boolean =>
  status === AnalysisStateNames.InProgress || status === AnalysisStateNames.Created;

// Determine if task selection should be disabled
export const getIsTaskDisabled = (status: AnalysisStateNames): boolean =>
  status === AnalysisStateNames.Completed ||
  status === AnalysisStateNames.InProgress ||
  status === AnalysisStateNames.Created;
