import { useParams } from 'react-router-dom';
import { AppBuilderDetailsContainer } from './AppBuilderDetails.container';

const AppBuilderDetailsPage = (): JSX.Element => {
  const { appId } = useParams();

  return <AppBuilderDetailsContainer appId={appId || ''} />;
};

export default AppBuilderDetailsPage;
