import { useMemo } from 'react';
import { Alert, Center, Loader } from '../../../../../../../design-system/v2';
import { useGetCustomDictionaryWordsListQuery } from '../../../../../../../queries/document-ai/copyEdit';
import { ErrorState } from '../../../../../../common/ErrorState';
import { DictionaryList } from './DictionaryList';

interface DictionaryListContainerProps {
  searchFilter: string;
  dictionaryId: string;
}

export const DictionaryListContainer = ({
  dictionaryId,
  searchFilter,
}: DictionaryListContainerProps): JSX.Element => {
  const { isLoading, isFetching, isError, data } =
    useGetCustomDictionaryWordsListQuery(dictionaryId);

  const filteredData = useMemo(
    () =>
      data?.customWords.filter(datum => datum.toLowerCase().includes(searchFilter.toLowerCase())) ??
      [],
    [data, searchFilter],
  );

  if (isLoading || isFetching) {
    return (
      <Center h={400}>
        <Loader text="Loading dictionary words..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={400}>
        <ErrorState title="Error fetching dictionary" />
      </Center>
    );
  }

  if (!data.customWords || !data.customWords.length) {
    return (
      <Center h={400}>
        <Alert>No words have been added to this dictionary</Alert>
      </Center>
    );
  }

  return <DictionaryList dictionaryId={dictionaryId} dictionaryWords={filteredData} />;
};
