import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Box,
  Horizontal,
  Text,
  TextInput,
  useDebouncedValue,
  useInputState,
  Vertical,
} from '../../../../../../design-system/v2';
import { AddDictionarySetButton } from './AddDictionarySet';
import { DictionaryListWrapper } from './DictionaryList/DictionaryList.wrapper';
import { DictionarySetContainer } from './DictionarySet.container';

export const DictionarySetWrapper = (): JSX.Element => {
  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 200);

  const [selectedDictionary, setSelectedDictionary] = useState({
    id: '',
    name: '',
  });

  if (!!selectedDictionary.id) {
    const resetSelectedDictionary = () => {
      setSelectedDictionary({ id: '', name: '' });
    };
    return (
      <DictionaryListWrapper
        dictionaryId={selectedDictionary.id}
        onParentBreadcrumbClick={resetSelectedDictionary}
      />
    );
  }

  const handleRowClick = (id: string, dictionaryName: string) => {
    if (id) {
      setSelectedDictionary({ id, name: dictionaryName });
    }
  };

  return (
    <Box p="xl">
      <Text variant="subTitle01">Custom Dictionary</Text>
      <Vertical mt="xl" spacing="lg">
        <Horizontal position="apart">
          <TextInput
            value={searchFilter}
            onChange={setSearchFilter}
            ariaLabel="Search Dictionary"
            placeholder="Search Dictionary..."
            icon={<IconSearch size={18} />}
          />
          <AddDictionarySetButton />
        </Horizontal>
        <DictionarySetContainer
          searchFilter={debouncedSearchFilter}
          onRowClicked={handleRowClick}
        />
      </Vertical>
    </Box>
  );
};
