import { CellProps } from 'react-table';
import { TextVariants } from '../../../design-system/v2';
import { ExperimentWithTags } from '../../../generated/api';
import { ExperimentsDateCell } from './ExperimentsDateCell';

export const getExperimentsDateColumn = (
  width: number,
  textVariant: TextVariants,
  dateVariant: string,
) => ({
  Header: 'Started at',
  accessor: 'createDate',
  width: width,
  disableFilters: true,
  Cell: (props: CellProps<ExperimentWithTags>) => (
    <ExperimentsDateCell {...props} dateVariant={dateVariant} textVariant={textVariant} />
  ),
});
