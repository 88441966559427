import { isUndefined, noop } from 'lodash';
import { CSSProperties } from 'react';
import { Horizontal } from '../../layout';
import { Pagination, PaginationLabel } from '../../navigation';
import { DEFAULT_PAGE_SIZE } from './util';

export interface ActionsHeaderProps {
  totalRows?: number;
  currentPage?: number;
  onPageNumberChange?: (page: number) => void;
  labelRowsPerPage?: string;
  showPaginationLabel?: boolean;
  style?: CSSProperties;
  leftActionContent?: React.ReactChild;
}

export const ActionsHeader = ({
  style = {},
  totalRows,
  currentPage,
  onPageNumberChange = noop,
  showPaginationLabel = false,
  labelRowsPerPage,
  leftActionContent,
}: ActionsHeaderProps): JSX.Element => {
  const handlePageChange = (pageNumber: number) => {
    if (currentPage === pageNumber) {
      return;
    }

    onPageNumberChange(pageNumber);
  };

  return (
    <Horizontal style={style} px="xxl" position="apart" bg="white.0" py="md">
      {leftActionContent && <Horizontal position="left">{leftActionContent}</Horizontal>}
      {!isUndefined(totalRows) && (
        <Horizontal>
          {showPaginationLabel ? (
            <PaginationLabel
              labelRowsPerPage={labelRowsPerPage}
              currentPage={currentPage ?? 1}
              rowsPerPage={DEFAULT_PAGE_SIZE}
              totalRows={totalRows}
            />
          ) : null}
          <Pagination
            value={currentPage}
            total={Math.ceil(totalRows / DEFAULT_PAGE_SIZE)}
            onChange={handlePageChange}
          />
        </Horizontal>
      )}
    </Horizontal>
  );
};
