import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { ResourceType, TagMetadata } from '../../generated/api';
import { datasetKeys } from '../../queries/queryConstants';
import { useAddEditTags } from '../tags/use-add-edit-tags';

export const useDatasetAddEditTags = (datasetId: string, tags?: TagMetadata[]) => {
  const { workspaceId } = useAppMetadata();

  return useAddEditTags(datasetId, ResourceType.Dataset, tags, [
    datasetKeys.list(workspaceId),
    datasetKeys.detail(workspaceId, datasetId),
  ]);
};
