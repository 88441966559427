import { MantineSize } from '@mantine/styles';
import { Loader } from '../../../design-system/v2';
import { WorkspaceMember } from '../../../generated/api';
import { UserAvatarGroup } from '../UserAvatarGroup';

const MAX_USERS_TO_SHOW = 5;

interface SharedWithUsersProps {
  isLoading: boolean;
  allUsers?: WorkspaceMember[];
  userIdsWithAccess?: string[];
  currentActiveUserIds?: string[];
  size?: MantineSize | number;
}

export const SharedWithUsers = ({
  isLoading,
  allUsers = [],
  userIdsWithAccess,
  currentActiveUserIds = [],
  size,
}: SharedWithUsersProps) => {
  if (isLoading) {
    return <Loader variant="dots" />;
  }

  return (
    <UserAvatarGroup
      users={allUsers
        .filter(user => !userIdsWithAccess?.length || userIdsWithAccess.includes(user.userId))
        .sort(user => (currentActiveUserIds.includes(user.userId) ? -1 : 1))}
      maxShown={MAX_USERS_TO_SHOW}
      showActive
      activeUserIds={currentActiveUserIds}
      size={size}
    />
  );
};
