import { Divider } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { Box, Card, Horizontal, Tabs, Text, Vertical } from '../../../design-system/v2';

export enum TabStates {
  MINE = 'MINE',
  ALL = 'ALL',
}

const TABS = [
  {
    value: TabStates.MINE,
    label: 'Mine',
  },
  {
    value: TabStates.ALL,
    label: 'All',
  },
];

interface WorkspaceCardProps {
  title: string;
  selectedTab?: TabStates;
  onChangeTab?: (tab: TabStates) => void;
  isSample?: boolean;
  hideTabs?: boolean;
}

export const WorkspaceCard = ({
  title,
  selectedTab,
  onChangeTab,
  isSample = false,
  hideTabs = false,
  children,
}: PropsWithChildren<WorkspaceCardProps>): JSX.Element => {
  if (isSample) {
    return (
      <Card withBorder p={0}>
        <Vertical h="100%" spacing={0}>
          {children}
        </Vertical>
      </Card>
    );
  }

  return (
    <Card withBorder p={0}>
      <Horizontal px="md" position="apart" align="end">
        <Text variant="subTitle02" py="md">
          {title}
        </Text>
        {!hideTabs && (
          <Horizontal>
            {selectedTab && onChangeTab && (
              <Box mb={-2}>
                <Tabs value={selectedTab} onTabChange={onChangeTab}>
                  <Tabs.List position="right">
                    {TABS.map(({ value, label }) => (
                      <Tabs.Tab key={value} size="sm" value={value}>
                        {label}
                      </Tabs.Tab>
                    ))}
                  </Tabs.List>
                </Tabs>
              </Box>
            )}
          </Horizontal>
        )}
      </Horizontal>

      <Divider size="sm" color="gray.2" />
      {children}
    </Card>
  );
};
