import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import first from 'lodash/first';
import { Avatar } from '../../../core';

export const MentionLabel = ({ node }: NodeViewProps) => {
  const nodeAttrs = node.attrs;
  const id = nodeAttrs['id'];
  const label = nodeAttrs['label'];
  const src = nodeAttrs['src'];

  return (
    <NodeViewWrapper as="span" className="user-mention" data-id={id}>
      <Avatar component="span" size="xs" src={src} alt={label} sx={{ display: 'inline' }}>
        {first(label)}
      </Avatar>
      <span>{label}</span>
    </NodeViewWrapper>
  );
};
