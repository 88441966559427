import { SimpleGridProps } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { SimpleGrid } from '../../../design-system/v2';

export const CardGrid = ({
  children,
  ...restProps
}: PropsWithChildren<SimpleGridProps>): JSX.Element => (
  <SimpleGrid
    cols={4}
    spacing={24}
    breakpoints={[
      { maxWidth: 'xs', cols: 1 },
      { maxWidth: 'sm', cols: 2 },
      { maxWidth: 'md', cols: 3 },
    ]}
    {...restProps}
  >
    {children}
  </SimpleGrid>
);
