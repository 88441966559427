import { Popover as MantinePopover, PopoverTargetProps } from '@mantine/core';
import { clsx } from '@mantine/styles';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { useStyles } from './popover.styles';

export const POPOVER_ERRORS = {
  context: 'Popover component was not found in the tree',
  children:
    'Popover.Target component children should be an element or a component that accepts ref, fragments, strings, numbers and other primitive values are not supported',
};

// Note: Popover target only supports children which supports ref as a prop.
// If the component have reference to HTMLElement in any other key in prop,
// same can be defined via refProp
// Unfortunately, a lot of React defined types have to be defined again to make
// this typesafe. Hence, we rely on documentation for this
// https://mantine.dev/core/popover/#required-ref-prop

export const PopoverTarget = forwardRef<HTMLElement, PopoverTargetProps>(
  ({ children, ...restProps }, ref) => {
    const styles = useStyles();
    if (!isValidElement(children)) {
      throw new Error(POPOVER_ERRORS.children);
    }

    // This is done to add cursor: pointer property for target children.
    const targetChildren = cloneElement(children as any, {
      className: clsx(styles.classes.target, children.props.className),
    });

    return (
      <MantinePopover.Target ref={ref} {...restProps}>
        {targetChildren}
      </MantinePopover.Target>
    );
  },
);

PopoverTarget.displayName = 'PopoverTarget';
