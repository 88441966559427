import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  createPolymorphicComponent,
} from '@mantine/core';
import { MouseEvent, forwardRef } from 'react';
import { ButtonType, ButtonVariant, typeRadius, variantProperties } from './constants';

export interface ButtonProps extends Omit<MantineButtonProps, 'variant' | 'radius' | 'gradient'> {
  variant?: ButtonVariant;
  shape?: ButtonType;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const _Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'subtle', shape = 'sharp', children, onClick, ...rest }: ButtonProps, ref) => {
    const variantProperty = variantProperties[variant];
    const radius = typeRadius[shape];

    return (
      <MantineButton
        variant={variantProperty.mantineVariant}
        radius={radius}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {children}
      </MantineButton>
    );
  },
) as any;

_Button.displayName = 'Button';
_Button.Group = MantineButton.Group;

export const Button = createPolymorphicComponent<
  'button',
  ButtonProps,
  { Group: typeof MantineButton.Group }
>(_Button);
