import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Center } from '../../../../../../../design-system/v2';
import { useDeleteCustomDictionaryWordMutation } from '../../../../../../../queries/document-ai/copyEdit';

interface ListActionProps {
  dictionaryId: string;
  wordToRemove: string;
}

export const ListAction = ({ dictionaryId, wordToRemove }: ListActionProps): JSX.Element => {
  const { isLoading, mutate } = useDeleteCustomDictionaryWordMutation(dictionaryId);

  const handleDeleteWord = () => {
    mutate({ words: [wordToRemove] });
  };

  return (
    <Center h="100%">
      <ActionIcon loading={isLoading} onClick={handleDeleteWord}>
        <IconTrash />
      </ActionIcon>
    </Center>
  );
};
