import { List, ListProps } from './List';
import { ListV2, ListV2Props } from './ListV2';

export enum ListVersions {
  v1 = 'v1',
  v2 = 'v2',
}

export type ListVersionSwitchProps<T> =
  | ({ version?: ListVersions.v1 } & ListProps<T>) // When version is not specified, use ListProps
  | ({ version: ListVersions.v2 } & ListV2Props<T>);

export const ListVersionSwitch = <T,>({
  version = ListVersions.v1,
  ...rest
}: ListVersionSwitchProps<T>) => {
  if (version === ListVersions.v2) {
    return <ListV2<T> {...(rest as ListV2Props<T>)} />;
  } else {
    return <List<T> {...(rest as ListProps<T>)} />;
  }
};
