import { memoize } from 'lodash';
import { Edge, Node } from 'vis-network';
import { getColors } from '../../../charts/utils/colors';

/**
    {
        "source": 8017.0,
        "destination": 8792.0,
        "value": 70.48,
        "dest_country": "CN",
        "source_country": "PH",
        "group": 934,
        "dest_entity": "solo",
        "is_outlier": 0,
        "title": "from: 8017.0\n to: 8792.0\n num_trans: 8.0\namount:70.48\n is_outlier:0.0\n",
        "transaction_count": 8,
        "source_degree": 1,
        "source_entity": "corp",
        "labels": 0
    },
 */

const joinTitleData = (items: { label: string; value: string }[]) =>
  items.map(({ label, value }) => `${label}: ${value}`).join('\n');

const getEdgeTitle = (datum: any) =>
  joinTitleData([
    {
      label: 'from',
      value: datum.source,
    },
    {
      label: 'to',
      value: datum.destination,
    },
    {
      label: 'amount',
      value: datum.value,
    },
    {
      label: 'Is Outlier',
      value: Boolean(datum.is_outlier),
    },
    {
      label: 'Group',
      value: datum.group,
    },
  ]);

const getSourceTitle = (datum: any) =>
  joinTitleData([
    {
      label: 'acc_id',
      value: datum.source,
    },
    {
      label: 'country',
      value: datum.source_country,
    },
    {
      label: 'entity',
      value: datum.source_entity,
    },
    {
      label: 'transaction_count',
      value: datum.transaction_count,
    },
    {
      label: 'degree',
      value: datum.source_degree,
    },
  ]);

const getDestTitle = (datum: any) =>
  joinTitleData([
    {
      label: 'acc_id',
      value: datum.destination,
    },
    {
      label: 'country',
      value: datum.dest_country,
    },
    {
      label: 'entity',
      value: datum.dest_entity,
    },
  ]);

const MAX_COLORS = 20;

export interface TopographyData {
  nodes: Node[];
  edges: Edge[];
  groups: number[];
  rawData: any[];
}

export const getGroupToColorMap = memoize(
  (datasetId: string, groups: number[]) => {
    const groupsToConsiderForColor = Math.min(groups.length, MAX_COLORS);
    const colorsList = getColors(groupsToConsiderForColor, 0.9);

    return groups?.reduce<Record<string, string>>((acc, val) => {
      acc[val] = colorsList[val % groupsToConsiderForColor];
      return acc;
    }, {});
  },
  (datasetId: string) => datasetId,
);

export const transformTopographyData = (datasetId: string, data: Array<any>): TopographyData => {
  const groups = [...new Set(data.map((datum: any) => datum.group as number))];
  const colorMap = getGroupToColorMap(datasetId, groups);

  const edges = data.map((datum: any) => ({
    ...datum,
    isOutlier: datum.is_outlier,
    group: datum.group,
    title: getEdgeTitle(datum),
    from: datum.source,
    to: datum.destination,
    id: `${datum.source}_${datum.destination}_${datum.group}_${datum.isOutlier}`,
    color: colorMap[datum.group],
    value: datum.value,
  }));

  // Create a set of unique node IDs from the edges
  const nodeIds = new Set(data.flatMap(datum => [datum.source, datum.destination]));

  // Map over the data once to create nodes
  const nodes: Node[] = Array.from(nodeIds).map(id => {
    const datum = data.find(d => d.source === id || d.destination === id);
    const title = datum.source === id ? getSourceTitle(datum) : getDestTitle(datum);
    // Determine if the node is an outlier by checking if any connected edge is an outlier
    const isOutlier = data.some(
      edge => (edge.source === id || edge.destination === id) && edge.is_outlier,
    );
    return {
      title,
      id,
      label: id,
      color: colorMap[datum.group],
      isOutlier,
      value: datum.source === id ? datum.source_degree : 1,
      group: datum.group,
    };
  });

  return {
    edges,
    nodes,
    groups,
    rawData: data,
  };
};

const OUTLIER_COLOR = '#FB8B23';
const UNSELECTED_GRAY = 'rgba(200,200,200,0.5)';

const getColor = (isOutlier: boolean, originalColor: string, showOutliers?: boolean) => {
  if (showOutliers) {
    return isOutlier ? OUTLIER_COLOR : UNSELECTED_GRAY;
  }
  if (showOutliers === false) {
    return isOutlier ? UNSELECTED_GRAY : originalColor;
  }
  return originalColor;
};

export const getUpdatedOutlierPoints = (edges: any[], nodes: any[], showOutliers?: boolean) => {
  const outlierEdges = edges.map(edge => {
    const edgeColor = getColor(edge.isOutlier, edge.color, showOutliers);
    return {
      ...edge,
      color: edgeColor,
      label:
        edgeColor === OUTLIER_COLOR
          ? `Count: ${edge.transaction_count}\nAmount: ${edge.value}`
          : ' ',
    };
  });

  const outlierNodeIds: string[] = [];
  outlierEdges.forEach(edge => {
    if (edge.color === OUTLIER_COLOR) {
      outlierNodeIds.push(edge.from);
      outlierNodeIds.push(edge.to);
    }
  });

  const outlierNodes = nodes.map(node => ({
    ...node,
    color: getColor(outlierNodeIds.includes(node.id), node.color, showOutliers),
  }));

  return {
    edges: outlierEdges,
    nodes: outlierNodes,
  };
};
