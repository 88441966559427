export const whites = {
  white0: '#FFFFFF',
  white1: '#F5F7FA',
  white2: '#D8E0EC',
  white3: '#C1CDE0',
};

/**
 * Below are the mantine default grays
 */
export const grays = {
  gray0: '#F8F9FA',
  gray1: '#F1F3F5',
  gray2: '#E9ECEF',
  gray3: '#DEE2E6',
  gray4: '#CED4DA',
  gray5: '#ADB5BD',
  gray6: '#868E96',
  gray7: '#495057',
  gray8: '#343A40',
  gray9: '#212529',
};

/**
 * Below are the mantine default darks
 */
export const darks = {
  dark0: '#C1C2C5',
  dark1: '#A6A7AB',
  dark2: '#909296',
  dark3: '#5C5F66',
  dark4: '#373A40',
  dark5: '#2C2E33',
  dark6: '#25262B',
  dark7: '#1A1B1E',
  dark8: '#141517',
  dark9: '#101113',
};

export const blues = {
  blue0: '#E9EFFF',
  blue1: '#E1E9FF',
  blue2: '#BDCFFF',
  blue3: '#A7BFFF',
  blue4: '#7AA0FF',
  blue5: '#4E80FF',
  blue6: '#2260FF',
  blue7: '#1F56E6',
  blue8: '#1B4DCC',
  blue9: '#1843B3',
};

export const pinks = {
  pink0: '#FEE9F5',
  pink1: '#FCD4EA',
  pink2: '#FAA9D5',
  pink3: '#F993CB',
  pink4: '#F668B6',
  pink5: '#F552AC',
  pink6: '#F22797',
  pink7: '#DA2388',
  pink8: '#C21F79',
  pink9: '#A91B6A',
};

export const greens = {
  green0: '#E6F6EE',
  green1: '#CCEDDC',
  green2: '#99DBB9',
  green3: '#80D2A8',
  green4: '#4DC085',
  green5: '#33B773',
  green6: '#00A550',
  green7: '#009548',
  green8: '#008440',
  green9: '#007338',
};

export const reds = {
  red0: '#FBEDEE',
  red1: '#F8DADE',
  red2: '#F1B5BD',
  red3: '#EDA3AC',
  red4: '#E67E8B',
  red5: '#E26B7A',
  red6: '#DB4659',
  red7: '#C53F50',
  red8: '#AF3847',
  red9: '#99313E',
};

export const yellows = {
  yellow0: '#FEF6E7',
  yellow1: '#FDEDCF',
  yellow2: '#FBDC9F',
  yellow3: '#FBD388',
  yellow4: '#F9C158',
  yellow5: '#F8B940',
  yellow6: '#F6A710',
  yellow7: '#E09600',
  yellow8: '#B87A00',
  yellow9: '#8F5F00',
};

export const purples = {
  purple0: '#F4EDFB',
  purple1: '#EADBF7',
  purple2: '#D5B7EE',
  purple3: '#CAA6EA',
  purple4: '#B582E2',
  purple5: '#AA70DD',
  purple6: '#954CD5',
  purple7: '#734B95',
  purple8: '#5E3E7A',
  purple9: '#49305F',
};

export const seaGreens = {
  seaGreen0: '#E6F7F7',
  seaGreen1: '#CCEFEF',
  seaGreen2: '#99E0DF',
  seaGreen3: '#80D8D8',
  seaGreen4: '#64CFCF',
  seaGreen5: '#4DC8C8',
  seaGreen6: '#00B1B0',
  seaGreen7: '#009F9E',
  seaGreen8: '#008E8D',
  seaGreen9: '#007C7B',
};

export const magenta = {
  magenta0: '#F5F2F5',
  magenta1: '#F3EDF4',
  magenta2: '#E8DBEB',
  magenta3: '#DDBAE3',
  magenta4: '#CF9CD8',
  magenta5: '#C27ECE',
  magenta6: '#B860C7',
  magenta7: '#AE3DC2',
  magenta8: '#9331A5',
  magenta9: '#7C238C',
};

export const orange = {
  orange0: '#FCF1E8',
  orange1: '#FAE2D1',
  orange2: '#F5C5A4',
  orange3: '#F2B78D',
  orange4: '#ED9A5F',
  orange5: '#EA8C49',
  orange6: '#E56F1B',
  orange7: '#CE6418',
  orange8: '#B75916',
  orange9: '#A04E13',
};

export const skyBlue = {
  skyBlue0: '#E6F5FE',
  skyBlue1: '#CDEAFC',
  skyBlue2: '#9BD5FA',
  skyBlue3: '#82CBF9',
  skyBlue4: '#50B6F6',
  skyBlue5: '#37ACF5',
  skyBlue6: '#0597F2',
  skyBlue7: '#0588DA',
  skyBlue8: '#0479C2',
  skyBlue9: '#046AA9',
};
