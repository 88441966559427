import { IconInfoCircle, IconPlaylistAdd } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { FullPageLoader } from '../../../../../../design-system';
import {
  Alert,
  Button,
  Flex,
  Text,
  TextArea,
  TextInput,
  Vertical,
  closeAllModals,
  openModal,
  useInputState,
} from '../../../../../../design-system/v2';
import { AddUpdateStyleGuideRuleSetRequest } from '../../../../../../generated/api';
import { HTTPError } from '../../../../../../lib/api/api';
import { useAddEditStyleGuideRulesetMutation } from '../../../../../../queries/document-ai/copyEdit';

interface AddRuleSetModalProps {
  ruleSetId?: string;
  data?: AddUpdateStyleGuideRuleSetRequest;
}

export const AddRuleSetModal = ({ ruleSetId, data }: AddRuleSetModalProps) => {
  const [name, setName] = useInputState(data?.ruleSetName ?? '');
  const [description, setDescription] = useInputState(data?.ruleSetDescription ?? '');

  const { isLoading, mutate, error } = useAddEditStyleGuideRulesetMutation(ruleSetId);

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleAddRuleSet = () => {
    const addRulesetReq: AddUpdateStyleGuideRuleSetRequest = {
      ruleSetName: name.trim(),
      ruleSetDescription: description.trim(),
    };
    mutate(addRulesetReq, {
      onSuccess(data) {
        if (data) {
          closeAllModals();
        }
      },
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Saving ruleset" />}

      <Vertical>
        {error ? (
          <Alert icon={<IconInfoCircle size={14} />} title="Unable to add a new ruleset">
            {(error as AxiosError<HTTPError>)?.response?.data.detail ?? 'Please try again'}
          </Alert>
        ) : null}
        <TextInput
          required
          ariaLabel="Rule Set Name"
          label="Rule Set Name"
          placeholder="Rule set name"
          value={name}
          onChange={setName}
        />
        <TextArea
          ariaLabel="Rule Set Description"
          label="Rule Set Description"
          placeholder="Rule set description"
          minRows={2}
          maxRows={4}
          value={description}
          onChange={setDescription}
        />
        <Flex justify="right" gap="sm">
          <Button variant="outline" color="gray.6" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!name} onClick={handleAddRuleSet}>
            Confirm
          </Button>
        </Flex>
      </Vertical>
    </>
  );
};

export const AddRuleSet = () => {
  const handleAddNewRule = () => {
    openModal({
      size: 'lg',
      title: <Text variant="heading03">Add Rule Set</Text>,
      children: <AddRuleSetModal />,
    });
  };

  return (
    <Button onClick={handleAddNewRule} leftIcon={<IconPlaylistAdd />} variant="outline">
      Add Rule Set
    </Button>
  );
};
