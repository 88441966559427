import { useEffect, useMemo, useRef } from 'react';
import { Alert, Center, Loader } from '../../../../../design-system/v2';
import {
  ChatResourceTypes,
  useChatWithDataSourceThreadConversationsQuery,
  useIsNewConversationMutating,
} from '../../../../../queries/chat-with-data/chat';
import { InfiniteListWrapper } from '../../../../common/InfiniteListWrapper';
import { EmptyChatContent } from '../../../../common/chat-with-data/chat/content/EmptyChatContent';
import { ThreadProps } from '../../menu/panels/threads-panel/ThreadsItem';
import { ConversationView } from './ConversationView';

interface ConversationViewContainerProps {
  resourceId: string;
  scrollToBottom: () => void;
  activeThread?: ThreadProps;
  handleSqlExecution?: (query: string) => void;
  resourceType: ChatResourceTypes;
  titleText?: string;
  subHeadingText?: string;
  descriptionText?: string;
}

export const ConversationViewContainer = ({
  resourceId,
  activeThread,
  scrollToBottom,
  handleSqlExecution,
  resourceType,
  titleText,
  subHeadingText,
  descriptionText,
}: ConversationViewContainerProps) => {
  const isNewConversationLoading = useIsNewConversationMutating(
    resourceId,
    activeThread?.threadId || '',
    resourceType,
  );

  const { isLoading, isError, isFetching, data, fetchNextPage, hasNextPage } =
    useChatWithDataSourceThreadConversationsQuery(
      resourceId,
      activeThread?.threadId || '',
      resourceType,
    );

  const conversations = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  const wasFetchingRef = useRef<boolean>(isFetching);
  const wasLoadingRef = useRef<boolean>(isLoading);

  useEffect(() => {
    // We want to trigger scrollToBottom in the following two scenarios:
    // 1. When the first time conversations are loaded.
    // 2. Whenever a new question is asked by the user.
    //
    // Condition 1. is handled by `loadingWasTriggered` boolean; it is
    // true the first time data is loaded and becomes false subsequently.
    //
    // Condition 2. is handled by `fetchingWasNotTriggered` boolean; it remains
    // true when data is updated using `setQueryData` (on prompt input) as in
    // that case neither isLoading or isFetching states would change.
    //
    // We could have passed the `scrollToBottom` to the `ChatPromptInputContainer` to
    // execute it whenever a question is sent by the user, but there `scrollToBottom`
    // does not properly scrolls to bottom because by then `conversations` are not updated.
    const fetchingWasNotTriggered = wasFetchingRef.current === isFetching;
    wasFetchingRef.current = isFetching;
    const loadingWasTriggered = wasLoadingRef.current !== isLoading;
    wasLoadingRef.current = isLoading;

    if (loadingWasTriggered || fetchingWasNotTriggered) {
      scrollToBottom();
    }
  }, [conversations, isFetching, isLoading]);

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" text="Loading chats" />
      </Center>
    );
  }

  if (isError) {
    return <Alert color="red">There was an error loading your chats. Please try again</Alert>;
  }

  if (conversations.length === 0) {
    return (
      <EmptyChatContent
        titleText={titleText}
        subHeadingText={subHeadingText}
        descriptionText={descriptionText}
      />
    );
  }

  return (
    <InfiniteListWrapper
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetching}
      fetchNextPage={fetchNextPage}
    >
      {conversations.map((conversation, index) => (
        <ConversationView
          key={conversation.threadId + conversation.conversationId + index}
          conversation={conversation}
          onExecuteSql={handleSqlExecution}
          isExecuteDisabled={!!isNewConversationLoading}
        />
      ))}
    </InfiniteListWrapper>
  );
};
