import { Loader } from '../../../design-system';
import { Text } from '../../../design-system/v2';
import { useWorkspaceListWithAccountAndMemberDetailsQuery } from '../../../queries/account/workspace';
import { WorkspaceSwitcher } from './WorkspaceSwitcher';

interface WorkspaceSwitcherContainerProps {
  onClose: () => void;
}

export const WorkspaceSwitcherContainer = ({
  onClose,
}: WorkspaceSwitcherContainerProps): JSX.Element => {
  const { isLoading, data, error } = useWorkspaceListWithAccountAndMemberDetailsQuery();

  if (isLoading) {
    return <Loader text="Loading your workspaces..." />;
  }

  if (error) {
    return (
      <Text variant="subTitle03">
        Oops. There&apos;s a system error. Please refresh page or contact Support!
      </Text>
    );
  }

  if (!data || !data.length) {
    return (
      <Text variant="subTitle03">
        Looks like you don&apos;t have any workspace assigned to you!
      </Text>
    );
  }

  if (data.length === 1) {
    return (
      <Text variant="subTitle03">Looks like you only have one workspace assigned to you.</Text>
    );
  }

  return <WorkspaceSwitcher onClose={onClose} workspaceList={data ?? []} />;
};
