import { IconArrowFork } from '@tabler/icons-react';
import first from 'lodash/first';
import startCase from 'lodash/startCase';
import toNumber from 'lodash/toNumber';
import { CellProps } from 'react-table';
import { TextColumnFilter } from '../../design-system/Table/components/data-table/filters/TextColumnFilter';
import {
  Badge,
  Box,
  Checkbox,
  Horizontal,
  Text,
  Vertical,
  openModal,
  useMarkovTheme,
} from '../../design-system/v2';
import { ArtifactFilterState, DatasetWithTags } from '../../generated/api';
import { formatDateLong, formatDateTime } from '../../lib/ui';
import { abbreviateNumber } from '../../lib/util';
import { AnalysisStatusData, Dataset } from '../../queries/datasets/list';
import { getSegmentText } from '../analyser/analyser.util';
import { TableRowName } from '../common/TableRowName';
import { TAGS_COLUMN_ID, TagsColumn } from '../common/table/Columns';
import { DatasetAnalysisPanelLink } from '../dataset-analysis-status/DatasetAnalysisPanelLink';
import { StyledClickableWrapper } from './DatasetsTable.style';
import { DatasetActionCell } from './components/DatasetActions';
import { DatasetRelationContainer } from './components/DatasetRelation.container';
import { DatasetState } from './components/DatasetState';

export const DEFAULT_TABLE_COLS = [
  'datasetId',
  'name',
  'segment',
  'dataFamilyName',
  TAGS_COLUMN_ID,
  'createDate',
  'analysis',
  'view',
  'actions',
  'dataQualityScore',
];

const DatasetNameCell = ({ value: name, row }: CellProps<Dataset>) => {
  const theme = useMarkovTheme();

  const { datasetId, parentDatasetId, childrenDatasetIds } = row.original;

  const childDatasetId = first(childrenDatasetIds);
  const showRelation = Boolean(parentDatasetId || childDatasetId);
  const relationMessage = parentDatasetId ? 'Derived version' : 'Original version';

  const openRelationModal = () =>
    openModal({
      size: 'lg',
      title: <Text variant="subTitle01">Related Datasets</Text>,
      children: (
        <DatasetRelationContainer
          datasetId={datasetId}
          parentDatasetId={parentDatasetId ?? ''}
          childDatasetId={childDatasetId ?? ''}
        />
      ),
    });

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    openRelationModal();
  };

  return (
    <Vertical spacing="xs">
      <Horizontal noWrap>
        <TableRowName isSampleRecord={row.original.demoArtifact}>
          <Text
            data-testid="datasets-table-datasetName"
            variant="subTitle04"
            color="gray.7"
            lineClamp={1}
            title={name}
            style={{ wordBreak: 'break-all' }}
          >
            {name}
          </Text>
        </TableRowName>
        {showRelation && (
          <Box onClick={handleIconClick} sx={{ flexShrink: 0 }} title={relationMessage}>
            <IconArrowFork size={16} color={theme.colors.blue[6]} />
          </Box>
        )}
      </Horizontal>
      <Text variant="small03" color="gray.6">
        {row.original.target}
      </Text>
    </Vertical>
  );
};

export const getTableCols = (
  isDatasetSelected: (datasetId: string) => boolean,
  toggleDatasetSelection: (datasetId: string, datasetName: string, className: string[]) => void,
  columnsToShow: string[] = DEFAULT_TABLE_COLS,
  artifactState?: ArtifactFilterState,
) => {
  const columns = [
    {
      Header: ' ',
      accessor: 'datasetId',
      id: 'selected',
      width: 44,
      canResize: false,
      disableFilters: true,
      Cell: ({ value: datasetId, row }: CellProps<Dataset, string>) => (
        // TODO: Make reusable component for checkbox table cell
        <StyledClickableWrapper onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={isDatasetSelected(datasetId)}
            onChange={() => {
              toggleDatasetSelection(datasetId, row.original.name, []);
            }}
          />
        </StyledClickableWrapper>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 240,
      Filter: () => <TextColumnFilter columnId="name" />,
      Cell: DatasetNameCell,
    },
    {
      Header: 'Type',
      accessor: 'segment',
      width: 160,
      disableFilters: true,
      Cell: ({ row }: CellProps<Dataset>) => {
        const displayedDataType = startCase(row.original.dataType);
        return (
          <Vertical spacing="xs">
            <Text variant="subTitle04" color="gray.7" lineClamp={1} title={displayedDataType}>
              {displayedDataType || '--'}
            </Text>
            <Horizontal spacing="xs">
              {row.original.segments?.map((segment, index) => (
                <Text key={`${segment}-${index}`} variant="small03" color="gray.6">
                  {getSegmentText(segment)}
                </Text>
              ))}
            </Horizontal>
          </Vertical>
        );
      },
    },
    {
      Header: 'Label Score',
      id: 'labelScore',
      accessor: 'dataQualityScore',
      width: 160,
      canResize: false,
      disableFilters: true,
      Cell: ({ value: dataQualityScore, row }: CellProps<Dataset>) => {
        const displayDataQualityScore = dataQualityScore === -100 ? 'N/A' : `${dataQualityScore}%`;
        const displayRows = row.original.numRecords === 1 ? 'Row' : 'Rows';
        const displayCols = row.original.noOfColumns === 1 ? 'Column' : 'Columns';
        const numRecords = abbreviateNumber(toNumber(row.original.numRecords));
        const numCols = abbreviateNumber(toNumber(row.original.noOfColumns));
        const displayRowAndCols = dataQualityScore
          ? `${numRecords} ${displayRows}, ${numCols} ${displayCols}`
          : '';
        return (
          <Vertical spacing="xs">
            <Text variant="subTitle04" color="gray.7">
              {dataQualityScore ? displayDataQualityScore : '--'}
            </Text>
            <Text variant="small03" color="gray.6">
              {displayRowAndCols}
            </Text>
          </Vertical>
        );
      },
    },
    {
      Header: 'Registered on',
      accessor: 'createDate',
      width: 110,
      disableFilters: true,
      Cell: ({ value: createDate }: CellProps<Dataset>) => (
        <Text variant="small01" color="gray.7" title={formatDateTime(createDate)}>
          {createDate ? formatDateLong(createDate) : ''}
        </Text>
      ),
    },
    {
      Header: 'Data Family',
      accessor: 'dataFamilyName',
      width: 180,
      Filter: () => <TextColumnFilter columnId="dataFamilyName" />,
      Cell: ({ value: dataFamilyName, row }: CellProps<Dataset>) => {
        const { analysis } = row.original;
        const { isUploading } = analysis as AnalysisStatusData;

        if (isUploading) {
          return null;
        }

        return (
          <Badge
            variant="light"
            color="gray.0"
            radius="xs"
            data-testid="datasets-table-datasetFamliy"
          >
            <Text variant="small01" color="gray.7" tt="capitalize">
              {dataFamilyName || '--'}
            </Text>
          </Badge>
        );
      },
    },
    TagsColumn,
    {
      Header: 'No. of records',
      accessor: 'numRecords',
      width: 80,
      Cell: ({ value: numOfRecords }: CellProps<Dataset>) => (
        <Text
          span
          variant="small01"
          lineClamp={1}
          title={numOfRecords}
          data-testid="datasets-table-numOfRecords"
        >
          {numOfRecords} records
        </Text>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 240,
      Cell: ({ value: desc }: { value: string }) => (
        <Text variant="small01" color="dark.4" lineClamp={1} title={desc}>
          {desc}
        </Text>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'analysis',
      width: 185,
      canResize: false,
      disableFilters: true,
      Cell: ({ value }: CellProps<Dataset>) => (
        <DatasetState analysisStatus={value as AnalysisStatusData} />
      ),
    },
    {
      Header: ' ',
      width: 90,
      id: 'view',
      accessor: 'view',
      canResize: false,
      disableFilters: true,
      Cell: ({ row }: CellProps<Dataset>) => {
        const { datasetId, analysis, name } = row.original;
        const {
          isUploading,
          analysis: { tasksStateSummary },
        } = analysis as AnalysisStatusData;

        if (isUploading) {
          return null;
        }

        const { numError = 0, numTasks = 0 } = tasksStateSummary ?? {};

        if (numError === numTasks) {
          return (
            <DatasetAnalysisPanelLink datasetName={name} datasetId={datasetId} showErrors>
              View Errors
            </DatasetAnalysisPanelLink>
          );
        }

        return (
          <DatasetAnalysisPanelLink datasetName={name} datasetId={datasetId}>
            View Tasks
          </DatasetAnalysisPanelLink>
        );
      },
    },
    {
      Header: ' ',
      accessor: 'actions',
      id: 'actions',
      width: 56,
      canResize: false,
      disableFilters: true,
      Cell: (props: CellProps<DatasetWithTags>) => (
        <DatasetActionCell {...props} artifactState={artifactState} />
      ),
    },
  ];

  // TODO: Remove this (not used, causes issues with functional accessor)
  return columns.filter(column => columnsToShow.includes(column.accessor));
};
