import { ErrorBoundary } from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';
import { ErrorFallback } from '../../ErrorBoundary';
import { GlobalHome } from '../../components/workspace/home-v2/global-home/GlobalHome';
import { useAbsoluteRoutes } from '../../router/hooks';

export const GlobalHomePage = (): JSX.Element => {
  const { featureGlobalHomepage } = useFlags();
  const { getDataStudioHomeRoute } = useAbsoluteRoutes();

  if (!featureGlobalHomepage) {
    const dataStudioRoute = getDataStudioHomeRoute();
    return <Navigate to={dataStudioRoute} />;
  }

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <GlobalHome />
    </ErrorBoundary>
  );
};
