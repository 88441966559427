import {
  AppBuilderApiFactory,
  AppStoreApiFactory,
  ModelAppsAPIsApiFactory,
  ModelMonitoringApiFactory,
  ModelRegistryAPIsApiFactory,
  RetailAppApiFactory,
  WorkspaceEvaluationsApiFactory,
  WorkspaceExperimentsApiFactory,
  WorkspaceModelsApiFactory,
  WorkspaceProjectsApiFactory,
  WorkspaceRecordingsApiFactory,
} from '../../generated/api';
import { apiConfiguration } from './api';

export const projectsApi = WorkspaceProjectsApiFactory(apiConfiguration);

export const evaluationsApi = WorkspaceEvaluationsApiFactory(apiConfiguration);

export const recordingsApi = WorkspaceRecordingsApiFactory(apiConfiguration);

export const modelsApi = WorkspaceModelsApiFactory(apiConfiguration);

export const experimentsApi = WorkspaceExperimentsApiFactory(apiConfiguration);

export const modelAppsApi = ModelAppsAPIsApiFactory(apiConfiguration);

export const modelRegistryApi = ModelRegistryAPIsApiFactory(apiConfiguration);

export const appStoreApi = AppStoreApiFactory(apiConfiguration);

export const modelMonitoringApi = ModelMonitoringApiFactory(apiConfiguration);

export const appBuilderApi = AppBuilderApiFactory(apiConfiguration);

export const retailAppApi = RetailAppApiFactory(apiConfiguration);
