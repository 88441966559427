import { PropsWithChildren } from 'react';
import { Card } from '../../../../design-system/v2';
import { AppDetailsHeaderLayout, AppDetailsHeaderLayoutProps } from './AppDetailsHeaderLayout';

export interface AppDetailsHeaderProps extends AppDetailsHeaderLayoutProps {
  height?: number;
  noBorder?: boolean;
}

export const AppDetailsHeader = ({
  height,
  noBorder = false,
  ...rest
}: PropsWithChildren<AppDetailsHeaderProps>): JSX.Element => (
  <Card
    shadow="xs"
    radius={0}
    h={height}
    role="banner"
    hoverEffect={false}
    sx={theme => ({ borderBottom: noBorder ? 'none' : `1px solid ${theme.colors.gray[2]}` })}
  >
    <AppDetailsHeaderLayout {...rest} />
  </Card>
);
