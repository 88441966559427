import {
  CitationStyleGuideType,
  CopyEditReference,
  Reference,
  ReferenceType,
} from '../../../../../generated/api';
import {
  Author,
  BaseReference,
  Identifier,
  JournalInformation,
  LLMAnswerAndReferences,
  MarkovPanelReference,
  OthersInformation,
  PublishingInformation,
  WebsiteInformation,
} from '../../../../common/chat-with-data/chat/answers/factory';
import {
  getInlineReferenceComponentAPA,
  getInlineReferenceTextAPA,
  getListReferenceTextAPA,
} from './apa';
import {
  formatDateCMOS,
  getAuthorsTextCMOS,
  getInlineReferenceComponentCMOS,
  getInlineReferenceTextCMOS,
  getListReferenceTextCMOS,
} from './cmos';
import { DEFAULT_CITATION_STYLE, MISSING_DATE_TEXT } from './constants';

const doiRegex = /^https:\/\/doi\.org\/10\.\d{4,9}\/[-._;()/:A-Z0-9]+$/i;
export const isValidDOI = (url: string): boolean => {
  if (!url) {
    return true;
  }
  return doiRegex.test(url);
};

interface CitationStyleGuideDetails {
  getInlineReferenceComponent: (reference: CopyEditReference[]) => JSX.Element;
  getInlineReferenceText: (reference: Reference) => string;
  getListReferenceText: (reference: Reference) => string;
}

// Extend this type and map below as we add support for other citation styles
type SupportedCitationStyleGuide = CitationStyleGuideType.Cmos | CitationStyleGuideType.Apa;

const citationStyleGuideDetailsMap: Record<SupportedCitationStyleGuide, CitationStyleGuideDetails> =
  {
    [CitationStyleGuideType.Cmos]: {
      getInlineReferenceComponent: getInlineReferenceComponentCMOS,
      getInlineReferenceText: getInlineReferenceTextCMOS,
      getListReferenceText: getListReferenceTextCMOS,
    },
    [CitationStyleGuideType.Apa]: {
      getInlineReferenceComponent: getInlineReferenceComponentAPA,
      getInlineReferenceText: getInlineReferenceTextAPA,
      getListReferenceText: getListReferenceTextAPA,
    },
  };

export const getReferenceStyleDetails = (refStyle: CitationStyleGuideType) => {
  // Use CMOS by default if None is specified
  const appliedRefStyle =
    refStyle === CitationStyleGuideType.None ? DEFAULT_CITATION_STYLE : refStyle;
  if (!Object.hasOwn(citationStyleGuideDetailsMap, appliedRefStyle)) {
    throw new Error('Configuration not found for citation style guide ' + appliedRefStyle);
  }
  return citationStyleGuideDetailsMap[appliedRefStyle as SupportedCitationStyleGuide];
};

const referenceLabelMap = {
  [ReferenceType.JournalArticle]: 'Journal Article',
  [ReferenceType.Website]: 'Website',
  [ReferenceType.Others]: 'Other',
};

export const getCitationPanelText = (
  referenceObj: CopyEditReference,
  referenceStyle: CitationStyleGuideType,
): string => {
  const { getInlineReferenceText } = getReferenceStyleDetails(referenceStyle);
  const reference = referenceObj.reference;
  const label = referenceLabelMap[reference.referenceType] ?? 'Unknown';
  return `${label}: ${getInlineReferenceText(reference)}`;
};

const isMissingDate = (date?: string) => date === undefined || MISSING_DATE_TEXT.includes(date);

export const getMarkovPanelText = (reference: Reference): string => {
  if (reference.referenceType === ReferenceType.JournalArticle) {
    const { authors, journalReference } = reference;
    const formattedPublishedDate = isMissingDate(journalReference?.publishedDate)
      ? MISSING_DATE_TEXT
      : `(${formatDateCMOS(journalReference?.publishedDate as string)}):`;
    const formattedTotalPages =
      journalReference?.totalPages === undefined ? '' : `[${journalReference?.totalPages}]`;
    return `${getAuthorsTextCMOS(authors)} ${formattedPublishedDate} ${formattedTotalPages}`;
  } else if (reference.referenceType === ReferenceType.Website) {
    const { websiteReference } = reference;
    const formattedPublishedDate = isMissingDate(websiteReference?.publishedDate)
      ? MISSING_DATE_TEXT
      : `(${formatDateCMOS(websiteReference?.publishedDate as string)})`;
    return `${formattedPublishedDate}`;
  } else {
    const { authors, othersReference } = reference;
    const formattedPublishedDate = isMissingDate(othersReference?.publishedDate)
      ? MISSING_DATE_TEXT
      : `(${formatDateCMOS(othersReference?.publishedDate as string)})`;
    return `${getAuthorsTextCMOS(authors)} ${formattedPublishedDate}`;
  }
};

const isAuthor = (obj: any): obj is Author =>
  (typeof obj.first_name === 'string' || obj.first_name === undefined) &&
  (typeof obj.last_name === 'string' || obj.last_name === undefined);

const isIdentifier = (obj: any): obj is Identifier =>
  typeof obj.url === 'string' && (typeof obj.doi === 'string' || obj.doi === null);

const isPublishingInformation = (obj: any): obj is PublishingInformation =>
  typeof obj.title === 'string' && typeof obj.published_date === 'string';

const isJournalInformation = (obj: any): obj is JournalInformation =>
  typeof obj.publication_volume === 'string' &&
  typeof obj.publication_issue === 'string' &&
  typeof obj.total_pages === 'string' &&
  isPublishingInformation(obj);

const isWebsiteInformation = (obj: any): obj is WebsiteInformation => isPublishingInformation(obj);

const isOthersInformation = (obj: any): obj is OthersInformation => isPublishingInformation(obj);

const isBaseReference = (obj: any): obj is BaseReference =>
  typeof obj.title === 'string' &&
  typeof obj.accessed_date === 'string' &&
  Array.isArray(obj.authors) &&
  obj.authors.every(isAuthor) &&
  isIdentifier(obj.identifier);

const isMarkovPanelReference = (obj: any): obj is MarkovPanelReference =>
  typeof obj.reference_type === 'string' &&
  (obj.journal_reference === null || isJournalInformation(obj.journal_reference)) &&
  (obj.website_reference === null || isWebsiteInformation(obj.website_reference)) &&
  (obj.others_reference === null || isOthersInformation(obj.others_reference)) &&
  isBaseReference(obj);

export const isLLMAnswerAndReferences = (obj: any): obj is LLMAnswerAndReferences =>
  typeof obj.answer === 'string' &&
  Array.isArray(obj.references) &&
  obj.references.every(isMarkovPanelReference);
