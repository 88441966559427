import { useMemo } from 'react';
import { Alert, Center, Loader } from '../../../../../../../design-system/v2';
import { useFetchCustomRulesListQuery } from '../../../../../../../queries/document-ai/copyEdit';
import { ErrorState } from '../../../../../../common/ErrorState';
import { CustomRulesList } from './CustomRulesList';

interface CustomRulesListContainerProps {
  searchFilter: string;
  ruleSetId: string;
}

export const CustomRulesListContainer = ({
  searchFilter,
  ruleSetId,
}: CustomRulesListContainerProps) => {
  const { data = [], isLoading, isError } = useFetchCustomRulesListQuery(ruleSetId);

  const filteredData = useMemo(
    () =>
      data.filter(
        ({ match, replaceBy }) =>
          match.toLowerCase().includes(searchFilter) ||
          replaceBy.map(item => item.toLowerCase()).includes(searchFilter),
      ),
    [data, searchFilter],
  );

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading Rules..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Center h={400}>
        <ErrorState title="Error fetching rules" />
      </Center>
    );
  }

  if (!data.length) {
    return (
      <Center h={400}>
        <Alert>No rules have been added to this ruleset</Alert>
      </Center>
    );
  }

  return <CustomRulesList ruleSetId={ruleSetId} rules={filteredData} />;
};
