import { MantineTheme } from '@mantine/core';
import { ThemeComponent } from '../../../utils/types';
import { dropdown, getItemStyles, itemsWrapper } from '../base-inputs/BaseSelect.style';

export const selectTheme: ThemeComponent = {
  styles: (theme: MantineTheme) => ({
    dropdown,
    itemsWrapper,
    item: getItemStyles(theme),
  }),
};
