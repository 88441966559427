import { AxiosResponse } from 'axios';
import first from 'lodash/first';
import { useState } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { v4 as uuid } from 'uuid';
import { modelAppEvents } from '../../../analytics/modelAppEvents';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { notifications } from '../../../design-system/v2';
import {
  ModelAppStatus,
  ModelInferenceRequest,
  ParsedInferenceResponse,
} from '../../../generated/api';
import { sendAnalytics } from '../../../initializers/analytics';

export interface ModelAppOutput {
  id: string;
  userInput: string;
  response?: string;
  confidence?: number;
  explain?: Array<[string, number]>;
  error?: string;
  loading?: boolean;
  aspects?: any[];
}

interface ModelAppStatusProps {
  modelAppStatus?: ModelAppStatus;
}

export const useModelAppStatus = ({ modelAppStatus }: ModelAppStatusProps) => {
  const isAppReady = modelAppStatus === ModelAppStatus.Running;
  const isAppStopped = modelAppStatus === ModelAppStatus.Stopped;
  const isAppLoading = !isAppReady && !isAppStopped;

  return { isAppReady, isAppStopped, isAppLoading };
};

interface ModelAppInferProps {
  modelId: string;
  getInference: UseMutateAsyncFunction<
    AxiosResponse<ParsedInferenceResponse>,
    unknown,
    ModelInferenceRequest,
    unknown
  >;
}

export const useModelAppInfer = ({ getInference, modelId }: ModelAppInferProps) => {
  const [modelOutputList, setModelOutputList] = useState<ModelAppOutput[]>();
  const { workspaceId } = useAppMetadata();

  const submitInput = (userInput: string) => {
    sendAnalytics(
      modelAppEvents.inferApp({
        modelAppId: modelId,
        modelAppInferType: 'TEXT_INPUT',
        models: ['BASELINE'],
        workspaceId: workspaceId ?? 'PUBLIC',
      }),
    );

    const inputId = uuid();

    setModelOutputList((prevList = []) => [
      {
        id: inputId,
        userInput,
        loading: true,
      },
      ...prevList,
    ]);

    getInference({ inputs: [userInput], delimiter: ',' })
      .then(({ data: response }) => {
        const { id = userInput, outputs } = response;
        // This is to make this API backward compatible.
        // API for older baseline give different response
        const { prediction = first(outputs), explain, aspects } = first(outputs) ?? {};

        setModelOutputList((prevList = []) =>
          prevList.map(outputList =>
            outputList.id === inputId
              ? { id, userInput, response: prediction, explain, aspects }
              : outputList,
          ),
        );
      })
      .catch(err => {
        const errorMessage =
          err.response.status === 400
            ? 'Please wrap your feature column inside double quotes("")'
            : 'Something went wrong!';
        const errorTitle = err.response.status === 400 ? 'Invalid Input' : 'Error';

        notifications.show({ variant: 'error', message: errorMessage, title: errorTitle });
        setModelOutputList((prevList = []) =>
          prevList.map(outputList =>
            outputList.id === inputId
              ? {
                  ...outputList,
                  loading: false,
                  error: err.response.status === 400 ? 'Invalid Input' : 'Something Went Wrong',
                }
              : outputList,
          ),
        );
      });
  };

  return { submitInput, modelOutputList };
};
