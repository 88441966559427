import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
} from '@mantine/core';

interface NumberInputProps extends Omit<MantineNumberInputProps, 'type'> {
  /**
   * This flag controls the validation of field when
   */
  required?: boolean;

  disabled?: boolean;

  ariaLabel: string;

  onChange: (val: number) => void;
}

export const NumberInput = ({ ariaLabel, required, ...rest }: NumberInputProps) => {
  if (!ariaLabel) {
    throw 'ariaLabel prop is a mandatory prop for input';
  }

  return <MantineNumberInput aria-label={ariaLabel} {...rest} />;
};
