import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { Box, Menu } from '../../../design-system/v2';
import { FeatureFamily } from '../../../router/constants';
import { useNewSidebarMenuStyles } from '../sidebar-v2/Sidebar.style';
import { SideBarOptions } from '../sidebar-v2/SidebarLinks';
import { PrimaryNavbarLink } from './PrimaryNavbarLink';

interface SidebarMenuProps {
  id: SideBarOptions | FeatureFamily;
  label: string;
  icon: JSX.Element;
  menuItems: {
    id: SideBarOptions | FeatureFamily;
    label: string;
    onClick: () => void;
    icon: JSX.Element;
  }[];
}

export const SidebarMenu = ({ id, label, icon, menuItems }: SidebarMenuProps): JSX.Element => {
  const { activeSection } = useAppMetadata();
  const { classes, cx } = useNewSidebarMenuStyles();

  return (
    <Menu position="right-start" withinPortal closeOnItemClick classNames={classes}>
      <Menu.Target>
        <Box>
          <PrimaryNavbarLink id={id} label={label} icon={icon} />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map(item => (
          <Menu.Item
            key={item.id}
            icon={item.icon}
            p="lg"
            onClick={item.onClick}
            className={cx(classes.item, {
              [classes.active]: activeSection === item.id,
            })}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
