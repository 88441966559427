import styled from 'styled-components';
import { Box, Text, theme } from '../../../design-system';

// Rethink on using design-system for chart components.
export const TooltipContainer = styled(Box)`
  background-color: ${theme.colors.darkBlue};
  border-radius: 5px;
  color: #fff;
  padding: 9px;
`;

// TODO:: remove the styles and create variants on text
export const TooltipHeader = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const TooltipItemRow = styled(Box)`
  margin-bottom: 4px;
  span {
    color: ${theme.colors.textLight};
  }
`;
