import { useNavigate } from 'react-router-dom';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { HotelIcon } from '../../../design-system';
import { Box, Horizontal, Text } from '../../../design-system/v2';
import {
  WorkspaceMember,
  WorkspaceMembershipRoles,
  WorkspaceWithMemberDetails,
} from '../../../generated/api';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { useWorkspaceSwitchItem } from './WorkspaceSwitcher.style';

interface WorkspaceItemProps {
  workspaceName: string;
  enterpriseAccountName?: string;
  ownerUser?: WorkspaceMember;
  onClick: () => void;
  currentWorkspace?: boolean;
}

const WorkspaceItem = ({
  workspaceName,
  enterpriseAccountName = '',
  ownerUser,
  currentWorkspace = false,
  onClick,
}: WorkspaceItemProps): JSX.Element => {
  const { classes } = useWorkspaceSwitchItem();
  return (
    <Horizontal className={classes.container} onClick={onClick}>
      <HotelIcon width={24} height={24} />
      <Box mx="12px">
        <Text variant="subTitle03" color="8">
          {workspaceName}
        </Text>
        {ownerUser?.userDetails?.email && (
          <Text variant="small02" color="dark.2">
            Admin: {ownerUser?.userDetails?.email}
          </Text>
        )}
      </Box>
      {enterpriseAccountName && (
        <Text variant="subTitle03" color="3">
          @ {enterpriseAccountName}
        </Text>
      )}
      {currentWorkspace && (
        <Box className={classes.viewingContainer}>
          <Text variant="small02" color="pink.8">
            Viewing
          </Text>
        </Box>
      )}
    </Horizontal>
  );
};

interface WorkspaceSwitcherProps {
  workspaceList: WorkspaceWithMemberDetails[];
  onClose: () => void;
}

export const WorkspaceSwitcher = ({
  workspaceList,
  onClose,
}: WorkspaceSwitcherProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();

  const navigate = useNavigate();
  const { getWorkspaceRoute } = useAbsoluteRoutes();

  const handleWorkspaceNavigation = (itemWorkspaceId: string) => (): void => {
    const workspaceRoute = getWorkspaceRoute(itemWorkspaceId);
    navigate(workspaceRoute);
    onClose();
  };

  return (
    <>
      {workspaceList.map(item => (
        <WorkspaceItem
          key={item.workspaceId}
          currentWorkspace={item.workspaceId === workspaceId}
          workspaceName={item.workspaceName}
          ownerUser={item.members?.workspaceMembers.find(
            member => member.workspaceMembershipRole === WorkspaceMembershipRoles.Owner,
          )}
          onClick={handleWorkspaceNavigation(item.workspaceId)}
        />
      ))}
    </>
  );
};
