import sortBy from 'lodash/sortBy';
import { TagMetadata } from '../../../../../../generated/api';
import { useGetWorkspaceTagsQuery } from '../../../../../../queries/tagging';
import {
  Alert,
  Center,
  IconAlertCircle,
  Loader,
  TextInput,
  Vertical,
  useInputState,
} from '../../../../../v2';
import { TagsGroup } from './TagsGroup';

interface TagsGroupContainerProps {
  selectedTagIds: string[];
  onClickTag: (tag: TagMetadata) => void;
}

export const TagsGroupContainer = ({ selectedTagIds, onClickTag }: TagsGroupContainerProps) => {
  const { isLoading, isError, data } = useGetWorkspaceTagsQuery();
  const [filterQuery, setFilterQuery] = useInputState('');

  if (isLoading) {
    return (
      <Center h={80}>
        <Loader size="sm" m="auto" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert
        variant="outline"
        title="Error loading tags"
        icon={<IconAlertCircle size={16} />}
        color="red"
      >
        Try refreshing
      </Alert>
    );
  }

  const tags = data as TagMetadata[];

  if (!tags.length) {
    return <Alert>No tags exist in this workspace.</Alert>;
  }

  const isFilterMatch = (name: string) => name.toLowerCase().includes(filterQuery.toLowerCase());
  const filteredTags = tags.filter(t => !filterQuery || isFilterMatch(t.name));

  const displayedTagsSection = !filteredTags.length ? (
    <Alert>No matching tags found</Alert>
  ) : (
    <TagsGroup
      tags={sortBy(filteredTags, 'name')}
      selectedTagIds={selectedTagIds}
      onClickTag={onClickTag}
    />
  );

  return (
    <Vertical spacing="sm">
      <TextInput
        ariaLabel="Filter"
        value={filterQuery}
        onChange={setFilterQuery}
        placeholder="Search for tags..."
      />
      {displayedTagsSection}
    </Vertical>
  );
};
