import first from 'lodash/first';
import { useEffect, useState } from 'react';
import { ChartTypes } from '../../../charts';
import { ChartData } from '../../../charts/config/build';
import { ChartsProvider } from '../../../charts/context/Context';
import { getBaseColors } from '../../../charts/utils/colors';
import { Expand, Loader } from '../../../design-system';
import {
  ActionIcon,
  Card,
  Center,
  Horizontal,
  Modal,
  SegmentedControl,
  useMarkovTheme,
} from '../../../design-system/v2';
import { SegmentType } from '../../../generated/api';
import { getIsTaskPending } from '../../../lib/analysis';
import {
  useGetCorrelationDataQuery,
  useGetCorrelationTypesQuery,
} from '../../../queries/datasets/statistics/correlation';
import { ModalVizHeader } from '../../analyser/visualization/ModalVizHeader';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../analyser/visualization/actions/DownloadVisualization';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';

const MAX_CATEGORIES_FOR_LABELS = 12;
const MAX_CATEGORIES_FOR_LABELS_EXPANDED = 20;

const colors = getBaseColors();
const maxColor = colors[0];
const minColor = colors[1];

interface CorrelationContainerProps {
  datasetId: string;
  segmentType: SegmentType;
  datasetName?: string;
  showBorders?: boolean;
  expandable?: boolean;
}

export const CorrelationContainer = ({
  datasetId,
  segmentType,
  datasetName = '',
  showBorders = true,
  expandable = true,
}: CorrelationContainerProps) => {
  const theme = useMarkovTheme();
  const [selectedCorrelation, setSelectedCorrelation] = useState('');
  const {
    isError: isCorrelationTypeError,
    isLoading: isCorrelationTypeLoading,
    data: correlationTypes,
  } = useGetCorrelationTypesQuery(datasetId, segmentType);
  const {
    isError: isCorrelationDataError,
    isLoading: isCorrelationDataLoading,
    data: correlationData,
  } = useGetCorrelationDataQuery(datasetId, segmentType, selectedCorrelation);

  useEffect(() => {
    if (correlationTypes?.analyzedCorrelations?.length) {
      setSelectedCorrelation(first(correlationTypes?.analyzedCorrelations) ?? '');
    }
  }, [correlationTypes?.analyzedCorrelations, segmentType]);
  const [expanded, showExpanded] = useState(false);

  const analysisState = correlationTypes?.analysisState;
  if (analysisState && getIsTaskPending(analysisState)) {
    return (
      <Card py={180}>
        <EmptyState
          compact
          title="Correlation analysis in progress"
          subtitle="Results will become available once the analysis is complete"
          iconName="correlation"
        />
      </Card>
    );
  }

  // TODO: Verify if this message is still correct/appropriate
  if ((correlationTypes?.analyzedCorrelations?.length || 0) === 0) {
    return (
      <Card py={180}>
        <EmptyState
          compact
          iconName="correlation"
          title="Correlation not available"
          subtitle="Dataset has categorical or text features. Correlation does not apply for the dataset category"
        />
      </Card>
    );
  }

  if (isCorrelationTypeError || isCorrelationDataError) {
    return (
      <Card py={180}>
        <ErrorState
          compact
          iconName="correlation"
          title="Error retrieving correlation data"
          subtitle="Please retry after sometime by refreshing the page. If the issue persists, contact support@markovml.com"
        />
      </Card>
    );
  }

  if (!isCorrelationDataLoading && !correlationData) {
    return (
      <Card py={180}>
        <EmptyState compact title="No data found for correlation" iconName="correlation" />
      </Card>
    );
  }

  const handleCorrelationChange = (value: string) => {
    setSelectedCorrelation(value);
  };

  const handleExpandViz = () => {
    showExpanded(true);
  };

  const handleExpandClose = () => {
    showExpanded(false);
  };

  const header = (
    <>
      <VisualizationComponent.Header
        actions={
          !!correlationData && !expanded ? (
            <Horizontal>
              <DownloadVisualization
                // filename spaces are replaced with underscore, as it is much more effective when referencing via CLI
                fileName={'correlation_chart'}
                isDownloadDisabled={isCorrelationTypeLoading || isCorrelationDataLoading}
              />
              {expandable ? (
                <ActionIcon
                  color="gray.6"
                  onClick={handleExpandViz}
                  disabled={isCorrelationTypeLoading || isCorrelationDataLoading}
                >
                  <Expand />
                </ActionIcon>
              ) : null}
            </Horizontal>
          ) : null
        }
      >
        {!expanded && (
          <Center>
            <VisualizationComponent.Header.Title
              title="Correlation"
              titleProps={{ mt: 'xs', ml: 'xs', variant: 'subTitle03', color: 'gray.8' }}
            />
          </Center>
        )}
        <div />
      </VisualizationComponent.Header>
      <Center pt="xl" pb="sm">
        {correlationTypes?.analyzedCorrelations ? (
          <SegmentedControl
            data={correlationTypes.analyzedCorrelations}
            onChange={handleCorrelationChange}
            value={selectedCorrelation}
            color={theme.colors.blue[3]}
          />
        ) : null}
      </Center>
    </>
  );

  let content = null;

  if (isCorrelationTypeLoading || isCorrelationDataLoading) {
    content = (
      <Center h={400}>
        <Loader text="Loading correlation data" />
      </Center>
    );
  } else {
    const xCategories = correlationData?.data?.xCategories;

    /**
     * In expanded view, show the values for upto MAX_CATEGORIES_FOR_LABELS_EXPANDED
     * In normal view, show the values upto MAX_CATEGORIES_FOR_LABELS
     * Otherwise, show value only on hover
     */
    const areAxesVisible = expanded || (xCategories?.length ?? 0) <= MAX_CATEGORIES_FOR_LABELS;
    const showDataLabels = (xCategories?.length ?? 0) <= MAX_CATEGORIES_FOR_LABELS;

    content = (
      <VisualizationComponent.Chart
        type={ChartTypes.HEATMAP}
        data={correlationData?.data as ChartData}
        colors={[theme.fn.themeColor('white.0'), maxColor]}
        enableLegends
        xAxisOptions={{
          visible: areAxesVisible,
          labels: {
            style: { width: 125, textOverflow: 'ellipsis' },
          },
        }}
        yAxisOptions={{
          // On expanded view, always show the labels
          // On non-expanded view, show based on hasManyCategories
          visible: areAxesVisible,
          labels: {
            style: { width: 120, textOverflow: 'ellipsis' },
          },
        }}
        enableDataLabels={showDataLabels}
        colorAxisOptions={{
          min: -1,
          max: 1,
          stops: [
            [0, maxColor],
            [0.5, '#FFFFFF'],
            [1, minColor],
          ],
        }}
      />
    );
  }

  const vizContent = (
    <VisualizationComponent cardProps={{ shadow: '' }} headerHeight={80}>
      {header}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleExpandClose}
        size="90vw"
        centered
        dataTestId="correlation-value"
      >
        <ModalVizHeader title="Correlation" datasetName={datasetName} segmentType={segmentType} />
        {vizContent}
      </Modal>
    </>
  );
};
