import constant from 'lodash/constant';
import first from 'lodash/first';
import noop from 'lodash/noop';
import { useMemo } from 'react';
import { Center, Loader, Modal, Text } from '../../../design-system/v2';
import { FilePreview, ResourceRequest, SegmentType } from '../../../generated/api';
import { useCloudDatasetPreviewParser } from '../../../hooks/useDatasetPreviewParser';
import { useGetConnectorResourcePreviewQuery } from '../../../queries/connectors';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { DEFAULT_DELIMITER } from '../../common/constants';
import { PaginatedColumnsTable } from '../../paginated-columns-table/PaginatedColumnsTable';

interface ResourcePreviewParserProps {
  preview: FilePreview;
  delimiter: string;
  height?: string;
}

export const ResourcePreviewParser = ({
  preview,
  delimiter,
  height = '500px',
}: ResourcePreviewParserProps) => {
  // Define dummy segment to use dataset preview parser
  const unparsedData = useMemo(
    () => ({
      segments: [SegmentType.Unknown],
      preview: { [SegmentType.Unknown]: preview },
      delimiter,
    }),
    [],
  );
  const cloudData = useCloudDatasetPreviewParser({ unParsedData: unparsedData, delimiter });

  if (cloudData.isParsing) {
    return (
      <Center h={400}>
        <Loader />
      </Center>
    );
  }
  if (cloudData.isParsingError) {
    return <ErrorState title="Error parsing preview data" />;
  }
  if (cloudData.isParsingNotAvailable) {
    return <EmptyState title="No data preview available" />;
  }

  const columns = first(Object.values(cloudData.columns)) ?? [];
  const rows = first(Object.values(cloudData.rows)) ?? [];

  return (
    <PaginatedColumnsTable
      hideCheckbox
      showSearch={false}
      columns={columns}
      height={height}
      rows={rows}
      onColumnCheckedChange={noop}
      getIsColumnChecked={constant(false)}
      getIsPageHighlighted={constant(false)}
    />
  );
};

interface ResourcePreviewContainerProps {
  connectorId: string;
  resourcePath: ResourceRequest[];
}

const ResourcePreviewContainer = ({
  connectorId,
  resourcePath,
}: ResourcePreviewContainerProps): JSX.Element => {
  const previewQuery = useGetConnectorResourcePreviewQuery(connectorId, resourcePath);

  if (previewQuery.isLoading || previewQuery.isIdle) {
    return (
      <Center h={400}>
        <Loader />
      </Center>
    );
  }

  if (previewQuery.isError) {
    return <ErrorState title="Error loading preview" />;
  }

  const { preview, delimiter = DEFAULT_DELIMITER } = previewQuery.data;

  if (!preview.data.length) {
    return <EmptyState title="No data preview available" />;
  }

  return <ResourcePreviewParser preview={preview} delimiter={delimiter} />;
};

interface ResourcePreviewModalProps {
  opened: boolean;
  onClose: () => void;
  connectorId: string;
  resourcePath: ResourceRequest[];
}

export const ResourcePreviewModal = ({
  opened,
  onClose,
  connectorId,
  resourcePath,
}: ResourcePreviewModalProps) => {
  const resourceValue = resourcePath.length > 0 ? resourcePath[resourcePath.length - 1].value : '';

  return (
    <Modal
      centered
      size="90vw"
      title={
        <Text truncate variant="subTitle01">
          Preview of {resourceValue}
        </Text>
      }
      opened={opened}
      onClose={onClose}
      zIndex={1000}
    >
      <ResourcePreviewContainer connectorId={connectorId} resourcePath={resourcePath} />
    </Modal>
  );
};
