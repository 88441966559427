import numeral from 'numeral';

/**
 * Clamps the value n to the range [min, max].
 *
 * @param {Number} n - The value to clamp
 * @param {Number} min - The min of the output range
 * @param {Number} max - The max of the output range
 * @returns {Number} The clamped value
 */
export const clamp = (n: number, min: number, max: number): number =>
  Math.min(Math.max(n, min), max);

/**
 * Returns a new array comprised of the unique elements of the input array.
 *
 * @param {Array} arr - The input array
 * @returns {Array} The output array with unique values
 */
export const unique = <T>(arr: Array<T>): Array<T> => Array.from(new Set(arr));

/**
 * Returns a new array which is created by inserting the interleaved value between each element
 * of the input array.
 *
 * @param {Array} arr - The input array
 * @param x - The value to interleave
 * @returns {Array} The output array with interleaved values
 */
export const interleave = <T, U>(arr: T[], x: U) => arr.flatMap(e => [e, x]).slice(0, -1);

/**
 * @param n : number
 * @param cb : cb fn to be called and return the value like map
 * @returns
 */
export const times = (length: number, cb: (x?: unknown, i?: number) => unknown) =>
  Array.from({ length }, cb);

/**
 * Returns a new array comprised of elements after split using delimiter
 * @param data : data to be split
 * @param delimiter
 * @returns
 */
export const splitWithDelimiter = (data: string, delimiter = ''): string[] => data.split(delimiter);

/**
 * Returns duration in hours,minutes,seconds format
 * @param durationInSeconds
 * @returns {String} "12h 3m 5s"
 */
export const getCorrectTimeFormat = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor((durationInSeconds % 3600) % 60);
  const hDisplay = hours > 0 ? hours + 'h ' : '';
  const mDisplay = minutes > 0 ? minutes + 'm ' : '';
  const sDisplay = seconds > 0 ? seconds + 's' : '';
  return hDisplay + mDisplay + sDisplay;
};

export const validateEmail = (email: string): boolean => /^\S+@\S+\.\S+$/.test(email);

/**
 *
 * @param value 12000,1200000
 * @returns {String} "12k , 1.2M"
 */
export const abbreviateNumber = (value: number): string =>
  numeral(value).format('0a').toLocaleUpperCase();

export const downloadFromUrl = (url: string, fileName: string) => {
  const tmpDownloadEl = document.createElement('a');
  tmpDownloadEl.setAttribute('href', url);
  tmpDownloadEl.setAttribute('download', fileName);
  tmpDownloadEl.click();
};
