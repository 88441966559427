import { useMediaQuery } from '@mantine/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ampli } from '../../../ampli';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../../contexts/layout/LayoutContext';
import { IconDocs, IconDocsAndSupport, IconSettings, IconWhatsNew } from '../../../design-system';
import { Box, Menu, useMarkovTheme } from '../../../design-system/v2';
import { DEVELOPER_DOCS } from '../../../developer-docs/links';
import { WHATS_NEW_FLOW, startFlow } from '../../../initializers/userflow';
import { AppRoutes } from '../../../router/constants';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { useSidebarMenuStyles } from './Sidebar.style';
import { SideBarOptions, SidebarLinks } from './SidebarLinks';

export const SidebarMenu = () => {
  const theme = useMarkovTheme();
  const [menuOpened, setMenuOpened] = useState(false);
  const { featureWhatsNew } = useFlags();
  const { sidebarExpanded, setSidebarExpanded, sidebarMouseEnter } = useLayoutContext();

  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const { classes: menuClasses } = useSidebarMenuStyles();

  const { getSettingsRoute } = useAbsoluteRoutes();

  const navigateTo = (link: string) => {
    if (isMobileScreen) {
      setSidebarExpanded(false);
    }

    navigate(link);
  };

  const { workspaceId } = useAppMetadata();

  const menuButton = {
    id: SideBarOptions.DOCS_AND_SUPPORT,
    label: 'Docs & Settings',
    icon: <IconDocsAndSupport />,
    onClick: () => setMenuOpened(!menuOpened),
    menuLinks: [
      ...(featureWhatsNew
        ? [
            {
              id: SideBarOptions.WHATS_NEW,
              label: "What's new",
              onClick: () => {
                ampli.navbarWhatsnewClicked({ workspaceId });
                startFlow(WHATS_NEW_FLOW);
              },
              icon: <IconWhatsNew />,
            },
          ]
        : []),
      {
        id: SideBarOptions.HELP_DOCS,
        label: 'Document center',
        onClick: () => {
          navigateTo(DEVELOPER_DOCS);
        },
        icon: <IconDocs />,
      },
      {
        id: SideBarOptions.SETTINGS,
        path: AppRoutes.SETTINGS,
        label: 'Settings',
        onClick: () => {
          ampli.navbarSettingsClicked();
          navigateTo(getSettingsRoute());
        },
        icon: <IconSettings />,
      },
    ],
  };

  useEffect(() => {
    if (!sidebarExpanded && !sidebarMouseEnter) {
      setMenuOpened(false);
    }
  }, [sidebarExpanded, sidebarMouseEnter]);

  return (
    <Menu opened={menuOpened} position="bottom" classNames={menuClasses} withinPortal>
      <Menu.Target>
        <Box>
          <SidebarLinks {...menuButton} />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        {menuButton.menuLinks.map(menuLink => (
          <SidebarLinks key={menuLink.id} {...menuLink} />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
