import {
  IconArrowsShuffle,
  IconCheck,
  IconClockStop,
  IconPlayerStopFilled,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isUndefined from 'lodash/isUndefined';
import { Box, Button, Horizontal, Text, notifications } from '../../design-system/v2';
import { AnalysisStateNames } from '../../generated/api';
import { getIsTaskPending } from '../../lib/analysis';
import {
  calculateTimeStampsInterval,
  getCurrentTimestamp,
  getDateFromTimeStamp,
  humaniseTimeDuration,
} from '../../lib/time-format';
import { useStopRunningAnalysisTaskMutation } from '../../queries/datasets/analysis/analysis';
import { useRerunAnalysisTaskMutation } from '../../queries/datasets/analysis/tasks';
import { StyledProgressCircle, StyledWarning } from './DatasetAnalysisStatusPanel.style';

const displayRerunTrigger = (state?: AnalysisStateNames, allowReRunCompletedAnalyzers?: boolean) =>
  !!state &&
  (state === AnalysisStateNames.Error ||
    state === AnalysisStateNames.Stopped ||
    (state === AnalysisStateNames.Completed && allowReRunCompletedAnalyzers));

const getIconForAnalysisState = (
  analysisState?: AnalysisStateNames,
  errorMessage?: string,
): JSX.Element => {
  switch (analysisState) {
    case AnalysisStateNames.Created:
    // TODO: need this icon from design
    case AnalysisStateNames.InProgress:
    case AnalysisStateNames.ResultsAvailable:
      return <StyledProgressCircle title="Processing" />;
    case AnalysisStateNames.Error:
      return <StyledWarning width="24px" height="24px" title={errorMessage || 'Error'} />;
    case AnalysisStateNames.Completed:
      return <IconCheck size={24} />;
    case AnalysisStateNames.Stopped:
      return <IconClockStop size={24} />;
    case AnalysisStateNames.Disabled:
    case AnalysisStateNames.NotStarted:
    case AnalysisStateNames.NotSupported:
      return <Box w="24px" />;
    default:
      throw new Error('Invalid analysis state encountered.');
  }
};

const getTaskTimeSummary = ({
  state,
  actualRuntime,
  estimatedRuntime,
  startTime,
  endTime,
}: {
  state: AnalysisStateNames;
  actualRuntime?: number;
  estimatedRuntime?: number;
  startTime: string;
  endTime: string;
}) => {
  const isActualRuntimeValid = actualRuntime && actualRuntime !== -1;
  const isEstimatedRuntimeValid = !isUndefined(estimatedRuntime);
  const endTimeMessage =
    state === AnalysisStateNames.Stopped
      ? ''
      : endTime && `Finished at: ${getDateFromTimeStamp(endTime, 'MMM DD YYYY, hh:mm A')}`;

  let startTimeMessage = '';
  let runtimeMessage = '';
  if (isActualRuntimeValid && state === AnalysisStateNames.Completed) {
    runtimeMessage = `Analyzer Runtime: ${humaniseTimeDuration(actualRuntime, 'seconds')}`;
  } else if (isEstimatedRuntimeValid && state === AnalysisStateNames.Error) {
    runtimeMessage = `Estimated Runtime: ${humaniseTimeDuration(estimatedRuntime, 'seconds')}`;
  } else if (state === AnalysisStateNames.Stopped) {
    runtimeMessage = 'Stopped';
  } else if (state === AnalysisStateNames.Created || state === AnalysisStateNames.InProgress) {
    startTimeMessage = startTime
      ? `Started at: ${getDateFromTimeStamp(startTime, 'MMM DD YYYY, hh:mm A')}`
      : '';

    const currentDateString = getCurrentTimestamp();
    const elapsedRuntime = calculateTimeStampsInterval(startTime, currentDateString);

    if (!isEstimatedRuntimeValid) {
      runtimeMessage =
        elapsedRuntime > 0
          ? `Elapsed Runtime: ${humaniseTimeDuration(elapsedRuntime, 'seconds')}`
          : '';
    } else if (elapsedRuntime !== -1 && elapsedRuntime > estimatedRuntime) {
      runtimeMessage = 'Taking longer than expected';
    } else {
      runtimeMessage = `Estimated Runtime: ${humaniseTimeDuration(estimatedRuntime, 'seconds')}`;
    }
  }

  return { startTimeMessage, endTimeMessage, runtimeMessage };
};

interface SegmentTaskListItemProps {
  datasetId: string;
  taskId: string;
  analysisName: string;
  state: AnalysisStateNames;
  actualRuntime?: number;
  estimatedRuntime?: number;
  startTime?: string;
  endTime?: string;
  error?: string;
}

export const SegmentTaskListItem = ({
  datasetId,
  taskId,
  analysisName,
  state,
  actualRuntime,
  estimatedRuntime,
  startTime = '',
  endTime = '',
  error,
}: SegmentTaskListItemProps): JSX.Element => {
  const { featureRerunSuccessAnalysers } = useFlags();
  const { isLoading: isRerunning, mutate: rerunTask } = useRerunAnalysisTaskMutation(
    datasetId,
    taskId,
  );
  const { isLoading: isStopping, mutate: stopTask } = useStopRunningAnalysisTaskMutation(
    datasetId,
    taskId,
  );

  const handleRerunClick = () => {
    rerunTask(undefined, {
      onError: () => {
        notifications.show({
          variant: 'error',
          message: 'Failed to re-run task',
        });
      },
    });
  };

  const handleStopClick = () => {
    stopTask(undefined, {
      onError: () => {
        notifications.show({
          variant: 'error',
          message: 'Failed to stop task',
        });
      },
    });
  };

  const { startTimeMessage, endTimeMessage, runtimeMessage } = getTaskTimeSummary({
    state,
    actualRuntime,
    estimatedRuntime,
    startTime,
    endTime,
  });

  return (
    <Horizontal position="apart" key={taskId} px="lg" py="md" noWrap>
      <Horizontal noWrap>
        {getIconForAnalysisState(state, error)}
        <Box>
          <Text variant="small01" color="dark.7">
            {analysisName}
          </Text>
          {[startTimeMessage, runtimeMessage, endTimeMessage].map((message, i) =>
            message ? (
              <Text key={i} variant="small01" color="dark.2">
                {message}
              </Text>
            ) : null,
          )}
        </Box>
      </Horizontal>
      {displayRerunTrigger(state, featureRerunSuccessAnalysers) ? (
        <Button
          variant="subtle"
          color="blue"
          onClick={handleRerunClick}
          leftIcon={<IconArrowsShuffle size={16} />}
          loading={isRerunning || isStopping}
        >
          Re-run
        </Button>
      ) : getIsTaskPending(state) ? (
        <Button
          variant="subtle"
          color="red"
          onClick={handleStopClick}
          leftIcon={<IconPlayerStopFilled size={16} />}
          loading={isRerunning || isStopping}
        >
          Stop
        </Button>
      ) : null}
    </Horizontal>
  );
};
