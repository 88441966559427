import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import merge from 'lodash/merge';
import { Layout } from 'react-grid-layout';
import { ChartGrid } from './ChartGrid';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    chartGridBlock: {
      setNewConversationIdInChartBlock: (conversationId: string, vizId: string) => ReturnType;

      removeConversationIdFromLayout: (conversationId: string) => ReturnType;
    };
  }
}

interface Grid {
  l: Layout;
}

const defaultGridLayout = [
  {
    l: {
      i: 'a',
      x: 0,
      y: 0,
      w: 8,
      h: 12,
    },
    metadata: {},
  },
];

export const ChartGridBlock = Node.create({
  name: 'chartGridBlock',

  group: 'chartBlock',

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (el: HTMLElement | string) => {
          const grid = (el as HTMLElement).dataset.grid;

          return {
            'data-blocktype': 'chart-grid',
            'data-grid': grid,
          };
        },
      },
    ];
  },

  addAttributes() {
    return {
      'data-blocktype': {
        default: 'chart-grid',
      },
      'data-grid': {
        default: JSON.stringify(defaultGridLayout),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ChartGrid);
  },

  addCommands() {
    return {
      setNewConversationIdInChartBlock:
        (conversationId: string, vizId: string) =>
        ({ state, editor }) => {
          const { $head } = state.selection;
          const gridLayout = JSON.parse($head.parent.child(0).attrs['data-grid']);
          const updatedGridLayout = gridLayout.map((gl: any) => {
            if (gl.layout.i === vizId) {
              return merge(gl, {
                layout: {
                  conversationId,
                },
              });
            }
            return gl;
          });

          const result = editor
            .chain()
            .selectParentNode()
            .updateAttributes(this.name, {
              'data-grid': JSON.stringify(updatedGridLayout),
            })
            .run();

          return result;
        },

      removeConversationIdFromLayout:
        (conversationId: string) =>
        ({ state, editor, tr, view, dispatch }) => {
          const { $head, $anchor } = state.selection;
          const gridLayout = JSON.parse($head.parent.child(0).attrs['data-grid']);

          const updatedGridLayout = gridLayout.map((gl: any) => {
            if (gl.layout.conversationId === conversationId) {
              delete gl.layout.conversationId;
            }

            return gl;
          });

          return editor
            .chain()
            .selectParentNode()
            .updateAttributes(this.name, {
              'data-grid': JSON.stringify(updatedGridLayout),
            })
            .run();
        },
    };
  },
});
