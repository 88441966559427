import { IconEdit } from '@tabler/icons-react';
import { useState } from 'react';
import { CellProps } from 'react-table';
import { DataTable } from '../../../design-system';
import { ActionIcon, Box, Text } from '../../../design-system/v2';
import { CredentialModel } from '../../../generated/api';
import { getDateFromTimeStamp } from '../../../lib/time-format';
import { capitalize } from '../../../lib/ui';
import { CredentialInfo } from './CredentialInfo';
import { EditCredentialsContainer } from './EditCredentials.container';

interface CredentialListProps {
  data: CredentialModel[];
}

const getTableColumns = (onEdit: (index: number) => void) => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 200,
    disableFilters: true,
    Cell: ({ value, row }: CellProps<CredentialModel>) => (
      <CredentialInfo credentialName={value} credentialId={row.original.credentialId ?? ''} />
    ),
  },
  {
    Header: 'Created Date',
    accessor: 'createDate',
    width: 100,
    disableFilters: true,
    Cell: ({ value }: { value: string }) => {
      const date = getDateFromTimeStamp(value);
      return (
        <Text variant="subTitle04" color="dark.6">
          {date}
        </Text>
      );
    },
  },
  {
    Header: 'Cloud provider',
    accessor: 'provider',
    width: 100,
    disableFilters: true,
    Cell: ({ value }: { value: string }) => (
      <Text variant="subTitle04" color="dark.6">
        {capitalize(value)}
      </Text>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 220,
    disableFilters: true,
    Cell: ({ value }: CellProps<CredentialModel>) => (
      <Text variant="subTitle04" color="dark.6" lineClamp={2} title={value}>
        {value}
      </Text>
    ),
  },
  {
    Header: 'Actions',
    accessor: '',
    width: 80,
    canResize: false,
    disableFilters: true,
    Cell: ({ row }: CellProps<CredentialModel>) => (
      <ActionIcon onClick={() => onEdit(row.index)} mx="auto">
        <IconEdit size={20} />
      </ActionIcon>
    ),
  },
];

export const CredentialsTable = ({ data: credentialData }: CredentialListProps): JSX.Element => {
  const credentialRows = credentialData.map(credential => ({
    name: credential.name,
    provider: credential.provider,
    description: credential.description,
    createDate: credential.createDate,
    credentialId: credential.credentialId ?? '',
  }));

  const [selectedCredentialIndex, setSelectedCredentialIndex] = useState<number | null>(null);

  const handleOpenCredentialsModal = (index: number): void => {
    setSelectedCredentialIndex(index);
  };

  const handleCloseCredentialsModal = (): void => {
    setSelectedCredentialIndex(null);
  };

  const columns = getTableColumns(handleOpenCredentialsModal);

  return (
    <>
      {/* selectedCredentialIndex can be 0 as well, so checking explicitly for null */}
      {selectedCredentialIndex !== null && (
        <EditCredentialsContainer
          credentialData={credentialData[selectedCredentialIndex]}
          onClose={handleCloseCredentialsModal}
        />
      )}
      <Box
        h="calc(100vh - 294px)"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <DataTable
          dataTestId="table-settings_credentials_list"
          data={credentialRows}
          columns={columns}
          height="100%"
        />
      </Box>
    </>
  );
};
