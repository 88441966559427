import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Flex, TextInput } from '../../../../../../../design-system/v2';

interface ReplaceByTextProps {
  index: number;
  replaceText: string[];
  onChangeReplaceText: (textArr: string[]) => void;
}

export const ReplaceByText = ({
  index,
  replaceText,
  onChangeReplaceText,
}: ReplaceByTextProps): JSX.Element => {
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = [...replaceText];
    newText[index] = e.target.value;
    onChangeReplaceText(newText);
  };

  const handleRemoveOption = () => {
    const newText = [...replaceText];
    newText.splice(index, 1);
    onChangeReplaceText(newText);
  };

  const label = `Option ${index + 1}`;

  return (
    <Flex align="flex-end" justify="center" w="100%">
      <TextInput
        w="100%"
        ariaLabel={label}
        label={replaceText.length > 1 ? label : ''}
        placeholder="Replacement for original text (leave blank to remove original text)"
        value={replaceText[index]}
        onChange={handleChangeText}
      />
      {index !== 0 && (
        <ActionIcon size="xl" onClick={handleRemoveOption}>
          <IconTrash />
        </ActionIcon>
      )}
    </Flex>
  );
};
