import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Container, Vertical } from '../../../design-system/v2';
import { useAbsoluteRoutes } from '../../../router/hooks';

const DEFAULT_ERROR_MSG = `An unknown error occurred while fetching details for the evaluations. 
Please try rereshing the page, or contact support if the problem persists.`;

interface EvaluationComparisonErrorScreenProps {
  message?: string;
}

export const EvaluationComparisonErrorScreen = ({
  message = DEFAULT_ERROR_MSG,
}: EvaluationComparisonErrorScreenProps) => {
  const { getEvaluationsRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();

  const handleClickBack = () => {
    const route = getEvaluationsRoute();
    navigate(route);
  };

  return (
    <Container py="lg">
      <Vertical h={300}>
        <Alert color="red">{message}</Alert>
        <Box sx={{ alignSelf: 'center' }}>
          <Button variant="primary" onClick={handleClickBack}>
            Back to Evaluations
          </Button>
        </Box>
      </Vertical>
    </Container>
  );
};
