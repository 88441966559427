import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Box, Text, Tooltip, Vertical } from '../../../../../../../design-system/v2';
import { Table } from '../../../../../../../design-system/v2/core/data-display/table';
import { StyleGuideRuleResponse } from '../../../../../../../generated/api';
import { CustomRulesActions } from './CustomRulesActions';

const getColumns = (ruleSetId: string): ColDef<StyleGuideRuleResponse>[] => [
  {
    colId: 'match',
    field: 'match',
    headerName: 'Original Text',
    sortable: false,
    cellRenderer: ({
      value: originalText,
    }: ICellRendererParams<StyleGuideRuleResponse, StyleGuideRuleResponse['match']>) => (
      <Vertical h="100%" justify="center">
        <Text variant="bodyShort02">{originalText}</Text>
      </Vertical>
    ),
    minWidth: 300,
    flex: 1,
  },
  {
    colId: 'replaceBy',
    field: 'replaceBy',
    headerName: 'Replace By Text',
    sortable: false,
    cellRenderer: ({
      value: replaceByText,
    }: ICellRendererParams<StyleGuideRuleResponse, StyleGuideRuleResponse['replaceBy']>) => {
      const text = replaceByText.join(', ');
      return (
        <Vertical h="100%" justify="center">
          <Tooltip label={text} withinPortal multiline maw={400}>
            <Text truncate variant="small02" color="gray.6">
              → {text}
            </Text>
          </Tooltip>
        </Vertical>
      );
    },
    minWidth: 300,
    flex: 1,
  },
  {
    colId: 'ruleDescription',
    field: 'ruleDescription',
    headerName: 'Rule Description',
    sortable: false,
    cellRenderer: ({
      value: ruleDescription,
    }: ICellRendererParams<StyleGuideRuleResponse, StyleGuideRuleResponse['ruleDescription']>) => (
      <Vertical h="100%" justify="center">
        <Tooltip label={ruleDescription} withinPortal multiline maw={400}>
          <Text truncate variant="small02" color="gray.6">
            {ruleDescription}
          </Text>
        </Tooltip>
      </Vertical>
    ),
    minWidth: 300,
    flex: 1,
  },
  {
    colId: 'ruleId',
    field: 'ruleId',
    headerName: ' ',
    sortable: false,
    cellRenderer: ({
      value: ruleId,
      data,
    }: ICellRendererParams<StyleGuideRuleResponse, StyleGuideRuleResponse['ruleId']>) => (
      <CustomRulesActions
        ruleId={ruleId}
        ruleSetId={ruleSetId}
        data={{
          description: data?.ruleDescription ?? '',
          rule: {
            match: data?.match ?? '',
            replaceBy: data?.replaceBy ?? [''],
          },
        }}
      />
    ),
    width: 100,
  },
];

interface CustomRulesListProps {
  ruleSetId: string;
  rules: StyleGuideRuleResponse[];
}

const ROW_HEIGHT = 55;

export const CustomRulesList = ({ rules, ruleSetId }: CustomRulesListProps) => {
  const columns = getColumns(ruleSetId);

  return (
    <Box mah={700} h={(rules.length + 1) * ROW_HEIGHT} w="100%">
      <Table suppressScrollOnNewData columns={columns} rowData={rules} />
    </Box>
  );
};
