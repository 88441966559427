import { ICellRendererParams } from '@ag-grid-community/core';
import { Horizontal, Text } from '../../../design-system/v2';
import { ResourceModel, StorageType } from '../../../generated/api';
import { storageConfigMap } from './config';

export const TypeRenderer = ({
  value: connectorType,
}: ICellRendererParams<ResourceModel, StorageType>): JSX.Element => {
  const config =
    connectorType !== undefined && connectorType in storageConfigMap
      ? storageConfigMap[connectorType]
      : undefined;

  const connectorName = config?.connectorName ?? '--';
  const icon = config?.connectorIcon ?? null;

  return (
    <Horizontal spacing="xs" h="100%">
      {icon}
      <Text variant="bodyShort03">{connectorName}</Text>
    </Horizontal>
  );
};
