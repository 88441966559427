import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Center, Loader } from '../../../';
import { useSnippetDetail } from '../../../../../components/snippets/detail/context/SnippetContext';
import {
  getPathParams,
  getReadUrl,
  useMediaUpload,
} from '../../../../../queries/snippets/media-upload';
import { ActionsMenu } from './ActionsMenu';
import { useResizableMediaStyles } from './ResizableMedia.style';
import { useResizeMedia } from './resizableMediaMenu.util';

export const ResizableMediaNodeView = ({
  node,
  updateAttributes,
  deleteNode,
  selected,
}: NodeViewProps) => {
  const {
    'media-type': mediaType,
    file: serializedFile,
    src,
    alt,
    height,
    width,
    dataAlign,
  } = node.attrs ?? {};
  const { editing, snippetId } = useSnippetDetail();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const resizableImgRef = useRef<HTMLImageElement | HTMLVideoElement | null>(null);

  const resizeHandlers = useResizeMedia(mediaType, resizableImgRef, updateAttributes);
  const { classes, cx } = useResizableMediaStyles({ align: dataAlign });

  const file = useMemo(() => {
    if (!serializedFile) {
      return null;
    }
    const { name, type, data } = JSON.parse(serializedFile);
    const byteCharacters = atob(data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const deserializedFile = new File([byteArray], name, { type });

    return deserializedFile;
  }, [serializedFile]);

  useMediaUpload({ snippetId, onSuccess: updateAttributes, file });

  const canShowMedia = !isError && !isLoading && src && !file;
  const path = getPathParams(snippetId, src);
  const srcUrl = getReadUrl(path);

  let content = null;
  if (isError) {
    content = (
      <Center h={height ?? 300} w={width ?? 400} bg="red.1">
        <Alert color="red">Error loading media</Alert>
      </Center>
    );
  } else if (isLoading) {
    content = (
      <Center h={height ?? 300} w={width ?? 400} bg="gray.2">
        <Loader variant="dots" />
      </Center>
    );
  } else if (canShowMedia) {
    if (mediaType === 'img') {
      content = (
        <img
          src={srcUrl}
          ref={resizableImgRef as any}
          alt={alt ?? src}
          width={width ?? '100%'}
          height={height ?? 'auto'}
        />
      );
    } else if (mediaType === 'video') {
      content = (
        <video ref={resizableImgRef as any} controls width={width ?? 650} height={height ?? 'auto'}>
          <source src={srcUrl} />
        </video>
      );
    }
  }

  useEffect(() => {
    if (src) {
      if (mediaType === 'img') {
        const img = new Image();
        img.src = srcUrl;

        img.onload = () => {
          setIsLoading(false);
        };

        img.onerror = () => {
          setIsError(true);
          setIsLoading(false);
        };
      } else if (mediaType === 'video') {
        setIsLoading(false);
      }
    }
  }, [mediaType, src, srcUrl]);

  return (
    <NodeViewWrapper as="article" className={classes.mediaNodeView}>
      <Box
        className={cx(classes.mediaContainer, {
          [classes.selected]: selected,
        })}
      >
        {content}

        {canShowMedia && editing && (
          <Box className={classes.resizeHandle} title="Resize" {...resizeHandlers} />
        )}

        {editing && <ActionsMenu deleteNode={deleteNode} updateAttributes={updateAttributes} />}
      </Box>
    </NodeViewWrapper>
  );
};
