import { useRef } from 'react';
import {
  Box,
  Divider,
  Indicator,
  ScrollArea,
  Text,
  useMarkovTheme,
} from '../../../design-system/v2';
import { InAppNotificationModel } from '../../../generated/api';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { Loading } from '../../common/Loading';
import { segregateNotifications } from './NotificationDrawer.util';
import { NotificationItem } from './NotificationItem';

export interface NotificationDrawerProps {
  notifications: InAppNotificationModel[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  onMarkAsRead: (recordIds: string[]) => void;
}

export const NotificationDrawer = ({
  notifications,
  fetchNextPage = () => undefined,
  hasNextPage = false,
  onMarkAsRead,
}: NotificationDrawerProps) => {
  const listEndRef = useRef(null);
  const theme = useMarkovTheme();

  useIntersectionObserver({
    target: listEndRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  const notificationsCollection = segregateNotifications(notifications);

  return (
    <ScrollArea h={600} scrollbarSize={10} offsetScrollbars py="sm">
      {notificationsCollection.map((notificationItem, index) => (
        <Box key={index}>
          {notificationItem.notifications.length > 0 && (
            <Text align="left" color={theme.colors.gray[5]} variant="subTitle05" pt={6} px="md">
              {notificationItem.title}
            </Text>
          )}
          {notificationItem.notifications.map(datum => (
            <Box key={datum.recordId}>
              <Indicator
                py="xs"
                offset={16}
                disabled={datum.read}
                color={theme.colors.orange[6]}
                size={6}
                position="top-end"
              >
                <NotificationItem notification={datum} onMarkRead={onMarkAsRead} />
              </Indicator>
              <Divider ml={40} size={1} color={theme.colors.gray[1]} />
            </Box>
          ))}
        </Box>
      ))}
      {hasNextPage && (
        <Box ref={listEndRef} sx={{ height: '32px', position: 'relative' }}>
          <Loading />
        </Box>
      )}
    </ScrollArea>
  );
};
