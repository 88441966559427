import { CellProps } from 'react-table';
import { ArtifactFilterState, DatasetWithTags } from '../../../generated/api';
import { TableActionMenu } from '../../table-action-menu/TableActionMenu';
import { useDatasetAddEditTags } from '../use-dataset-add-edit-tags';
import { useDatasetArchiveUnarchive } from '../use-dataset-archive-unarchive';

interface DatasetActionProps extends CellProps<DatasetWithTags> {
  artifactState?: ArtifactFilterState;
}

export const DatasetActionCell = ({ row, artifactState }: DatasetActionProps) => {
  const resourceId = row.original.datasetId;
  const tags = row.original.tags;

  const { openAddEditTagsModal } = useDatasetAddEditTags(resourceId, tags);
  const { openArchiveUnarchiveModal } = useDatasetArchiveUnarchive(
    [resourceId],
    artifactState ?? ArtifactFilterState.Active,
  );

  return (
    <TableActionMenu
      actions={[
        {
          id: 'tag-action',
          label: 'Edit Tags',
          handler: openAddEditTagsModal,
        },
        ...(artifactState
          ? [
              {
                id: 'archive-dataset',
                label: artifactState === ArtifactFilterState.Active ? 'Archive' : 'Unarchive',
                handler: openArchiveUnarchiveModal,
              },
            ]
          : []),
      ]}
    />
  );
};
