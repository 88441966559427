import { Skeleton, Text, Vertical } from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { formatDateLong } from '../../../../lib/ui';
import { DEFAULT_ROW_HEIGHT } from '../../../datasets-table/v2/cell-renderers/util';

export const CreateDateRenderer = <T,>({ value, data }: ICellRendererParams<T, string>) => {
  if (!data) {
    return (
      <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={80} height={30} />
      </Vertical>
    );
  }

  const userName = (data as any)?.user?.name;

  return (
    <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
      <Text variant="small01" color="gray.7">
        {value ? formatDateLong(value) : '--'}
      </Text>
      {userName && (
        <Text variant="small03" color="gray.6">
          by {userName}
        </Text>
      )}
    </Vertical>
  );
};
