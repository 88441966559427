import { notifications } from '../../../design-system/v2';
import { AppBuilder, LLMModel, UpdateAppBuilderRequest } from '../../../generated/api';
import { useUpdateAppBuilderMutation } from '../../../queries/app-builder/app-builder';
import { AppBuilderDetailsForm } from './AppBuilderDetailsForm';
import { AppBuilderViews } from './AppBuilderViewsControl';

export interface AppBuilderDetailsFormContainerProps {
  appId: string;
  prompt: string;
  example: string;
  onPromptChange: (prompt: string) => void;
  onExampleChange: (example: string) => void;
  appBuilderDetails: AppBuilder;
  selectedModel: LLMModel;
  activeView: AppBuilderViews;
}

export const AppBuilderDetailsFormContainer = ({
  appId,
  prompt,
  example,
  activeView,
  onPromptChange,
  onExampleChange,
  appBuilderDetails,
  selectedModel,
}: AppBuilderDetailsFormContainerProps) => {
  const { mutateAsync: updateAppBuilder, isLoading: isUpdatingAppBuilder } =
    useUpdateAppBuilderMutation(appId);

  const handleAppMetadataUpdate = async (prompt: string, example: string) => {
    const request: UpdateAppBuilderRequest = {
      appProperties: {
        appContext: '',
        appPrompt: prompt.trim(),
        examples: [example.trim()],
        models: selectedModel!,
      },
    };
    await updateAppBuilder(request).catch(() => {
      notifications.error('Unable to save data');
    });
  };

  return (
    <AppBuilderDetailsForm
      activeView={activeView}
      prompt={prompt}
      example={example}
      onPromptChange={onPromptChange}
      onExampleChange={onExampleChange}
      appDetails={appBuilderDetails}
      onFormValuesChange={handleAppMetadataUpdate}
    />
  );
};
