import { IconArrowUpRight } from '@tabler/icons-react';
import { AnalyzingSpinner } from '../../design-system';
import { Button, Horizontal, Text, useMarkovTheme } from '../../design-system/v2';
import { ModelAppStatus } from '../../generated/api';
import { useGenerateAppModal } from './generate-model-app/useGenerateAppModal';

interface ModelAppActionsProps {
  modelId: string;
  status: ModelAppStatus;
}

export const ModelAppActions = ({ modelId, status }: ModelAppActionsProps) => {
  const theme = useMarkovTheme();
  const { open: openGenerateAppModal } = useGenerateAppModal({ modelId });

  const handleRegenerateApp = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    openGenerateAppModal({ isRestart: true });
  };

  let actionBtn = (
    <Horizontal spacing="xs">
      <Text variant="textLink" color="blue.6">
        View Model App
      </Text>
      <IconArrowUpRight color={theme.colors.blue[6]} size={16} />
    </Horizontal>
  );

  if (status === ModelAppStatus.Initialized || status === ModelAppStatus.Pending) {
    actionBtn = (
      <Horizontal noWrap>
        <AnalyzingSpinner />
        <Text variant="subTitle04" color="gray.5">
          Generating App...
        </Text>
      </Horizontal>
    );
  }

  if (status === ModelAppStatus.Stopped) {
    actionBtn = (
      <Button color="blue.6" variant="outline" onClick={handleRegenerateApp}>
        Restart App
      </Button>
    );
  }

  return (
    <Horizontal position="right">
      {actionBtn}
      {/* TODO: Enable this once stop app functionality is completed from BE */}
      {/* <Box onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
        <Menu withArrow position="bottom-end">
          <Menu.Target>
            <ActionIcon size="lg" sx={theme => ({ border: `1px solid ${theme.colors.gray[5]}` })}>
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Button onClick={stopCurrentApp} disabled={status === ModelAppStatus.Stopped}>
              Stop App
            </Button>
          </Menu.Dropdown>
        </Menu>
      </Box> */}
    </Horizontal>
  );
};
