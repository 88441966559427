import { IconSearch } from '@tabler/icons-react';
import {
  Box,
  Horizontal,
  Text,
  TextInput,
  Vertical,
  useDebouncedValue,
  useInputState,
} from '../../../../../../../design-system/v2';
import { AddBulkCustomRules } from './AddBulkRules';
import { AddCustomRule } from './AddEditCustomRule';
import { CustomRulesListContainer } from './CustomRulesList.container';

interface CustomRulesWrapperProps {
  ruleSetId: string;
  ruleSetName: string;
}

export const CustomRulesWrapper = ({
  ruleSetId,
  ruleSetName = 'Rules',
}: CustomRulesWrapperProps) => {
  const [searchFilter, setSearchFilter] = useInputState('');
  const [debouncedSearchFilter] = useDebouncedValue(searchFilter, 300);

  return (
    <Box p="xl">
      <Text variant="heading03">{ruleSetName}</Text>

      <Vertical mt="xl" spacing="xl">
        <Horizontal position="apart">
          <TextInput
            value={searchFilter}
            onChange={setSearchFilter}
            ariaLabel="Search rules"
            placeholder="Search rules..."
            icon={<IconSearch size={18} />}
          />
          <Horizontal>
            <AddBulkCustomRules ruleSetId={ruleSetId} />
            <AddCustomRule ruleSetId={ruleSetId} />
          </Horizontal>
        </Horizontal>

        <CustomRulesListContainer
          searchFilter={debouncedSearchFilter.toLowerCase()}
          ruleSetId={ruleSetId}
        />
      </Vertical>
    </Box>
  );
};
