import { IconDotsVertical } from '@tabler/icons-react';
import { FullPageLoader } from '../../../../../../design-system';
import {
  ActionIcon,
  Center,
  Menu,
  Text,
  notifications,
  openModal,
} from '../../../../../../design-system/v2';
import { CreateOrUpdateCustomDictionaryRequest } from '../../../../../../generated/api';
import { useDeleteCustomDictionaryMutation } from '../../../../../../queries/document-ai/copyEdit';
import { AddEditDictionarySetModal } from './AddDictionarySet';

interface DictionarySetActionProps {
  dictionaryId: string;
  data: CreateOrUpdateCustomDictionaryRequest;
}

export const DictionarySetAction = ({ dictionaryId, data }: DictionarySetActionProps) => {
  const { mutate, isLoading } = useDeleteCustomDictionaryMutation(dictionaryId);

  const handleDeleteDictionarySet = () => {
    mutate(undefined, {
      onSuccess: () => {
        notifications.success('Dictionary deleted successfully');
      },
      onError: () => {
        notifications.error('Not able to delete dictionary');
      },
    });
  };

  const handleEditDictionarySet = () => {
    openModal({
      size: 'xl',
      title: <Text variant="heading03">Edit Dictionary</Text>,
      children: <AddEditDictionarySetModal dictionaryId={dictionaryId} initialData={data} />,
    });
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Please wait..." />}
      <Menu shadow="md" withinPortal>
        <Menu.Target>
          <Center h="100%">
            <ActionIcon>
              <IconDotsVertical />
            </ActionIcon>
          </Center>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={handleDeleteDictionarySet}>
            <Text variant="bodyShort03" align="center">
              Delete
            </Text>
          </Menu.Item>
          <Menu.Item onClick={handleEditDictionarySet}>
            <Text variant="bodyShort03" align="center">
              Edit
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
