import first from 'lodash/first';
import { useEffect } from 'react';
import { MarkovSize, Select } from '../../../design-system/v2';
import {
  useDataSourceList,
  useUpdateDatasetWorkflowMutation,
} from '../../../queries/datasets/registration';
import { useDatasetRegistrationContext } from '../context/DatasetRegistrationContext';

interface DataStoreProps {
  formLoading?: boolean;
  selectedDataStore: string;
  onSelectDataStore: (dataStoreId: string) => void;
  size?: MarkovSize;
}

const DATASTORE_LABEL = 'Cloud Storage Type';
const DATASTORE_FETCH_ERROR = 'Unable to fetch list';

export const DataStoreSelect = ({
  formLoading,
  selectedDataStore,
  onSelectDataStore,
  size = 'sm',
}: DataStoreProps): JSX.Element => {
  const { workflowId } = useDatasetRegistrationContext();
  const { mutate: updateWorkflow } = useUpdateDatasetWorkflowMutation(workflowId);
  const { data, isLoading, isError } = useDataSourceList();

  const dataStoreList = data
    ? data.map(dataStore => ({
        value: dataStore.dataStoreId,
        label: dataStore.name,
      }))
    : [];

  // If data is present and data store is not yet selected, select the first entry by default
  useEffect(() => {
    if (data) {
      if (!selectedDataStore && data.length) {
        const firstDataStore = first(data)?.dataStoreId ?? '';
        updateWorkflow({
          datastoreId: firstDataStore,
        });
        // onSelectDataStore(firstDataStore);
      }
    }
  }, [data]);

  const handleSelectedDataStore = (value: string | null): void => {
    if (value) {
      updateWorkflow({
        datastoreId: value,
      });
      // onSelectDataStore(value);
    }
  };

  return (
    <Select
      label={DATASTORE_LABEL}
      ariaLabel={DATASTORE_LABEL}
      required
      options={dataStoreList}
      onChange={handleSelectedDataStore}
      value={selectedDataStore}
      disabled={formLoading || isLoading}
      error={isError ? DATASTORE_FETCH_ERROR : ''}
      size={size}
    />
  );
};
