import { IconChartHistogram, IconHome, IconSettings } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppSidePanelOptions,
  useDocumentAiAppContext,
} from '../../../../contexts/document-ai/DocumentAiAppContext';
import {
  Box,
  Card,
  Horizontal,
  Text,
  UnstyledButton,
  Vertical,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { AppIdType } from '../../../../generated/api';
import { AppRoutes, getRoute } from '../../../../router/constants';
import { useAbsoluteRoutes } from '../../../../router/hooks';

interface AppSidePanelProps {
  variant: AppIdType;
  isSupportProjects?: boolean;
}

export const AppSidePanel = ({ variant, isSupportProjects = false }: AppSidePanelProps) => {
  const { activeTab } = useDocumentAiAppContext();
  const theme = useMarkovTheme();

  const navigate = useNavigate();
  const { getCopyEditRoute, getSummarizationRoute, getDocQnARoute } = useAbsoluteRoutes();

  const getHomeRoute = useMemo(() => {
    switch (variant) {
      case AppIdType.CopyEdit:
        return getCopyEditRoute;
      case AppIdType.Summarization:
        return getSummarizationRoute;
      case AppIdType.QnaWithDoc:
        return getDocQnARoute;
      default:
        return getCopyEditRoute;
    }
  }, [variant]);

  const elements: {
    id: AppSidePanelOptions;
    label: string;
    onClick: () => void;
    icon: (color: string) => JSX.Element;
    enabledVariants?: AppIdType[];
  }[] = [
    {
      id: AppSidePanelOptions.ALL_PROJECTS,
      label: isSupportProjects ? 'All Projects' : 'All files',
      onClick: () => navigate(getHomeRoute()),
      icon: (color: string) => <IconHome color={color} />,
      enabledVariants: undefined,
    },
    {
      id: AppSidePanelOptions.SETTINGS,
      enabledVariants: [AppIdType.CopyEdit],
      label: 'Settings',
      onClick: () => navigate(`${getHomeRoute()}/${getRoute(AppRoutes.COPY_EDIT_SETTINGS)}`),
      icon: (color: string) => <IconSettings color={color} />,
    },
    {
      id: AppSidePanelOptions.ANALYTICS,
      enabledVariants: [AppIdType.CopyEdit],
      label: 'Analytics',
      onClick: () => navigate(`${getHomeRoute()}/${getRoute(AppRoutes.COPY_EDIT_ANALYTICS)}`),
      icon: (color: string) => <IconChartHistogram color={color} />,
    },
  ].filter(
    ({
      enabledVariants,
    }: {
      id: AppSidePanelOptions;
      label: string;
      onClick: () => void;
      icon: (color: string) => JSX.Element;
      enabledVariants?: AppIdType[];
    }) => !enabledVariants || enabledVariants?.includes(variant),
  );

  return (
    <Card w={200} h="100%" aria-label="App Side Panel">
      <Vertical spacing="sm" pt={24}>
        {elements.map(element => (
          <UnstyledButton
            bg={activeTab === element.id ? 'blue.0' : undefined}
            p="md"
            role="button"
            tabIndex={0}
            variant="rounded"
            component={Horizontal}
            noWrap
            key={element.id}
            onClick={element.onClick}
          >
            <Box w={24} h={24}>
              {element.icon(activeTab === element.id ? theme.colors.blue[7] : theme.colors.gray[8])}
            </Box>

            <Text
              variant="subTitle03"
              color={activeTab === element.id ? 'blue.7' : 'gray.8'}
              truncate
            >
              {element.label}
            </Text>
          </UnstyledButton>
        ))}
      </Vertical>
    </Card>
  );
};
