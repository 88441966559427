import { Loader } from '@mantine/core';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { copyEditEvents } from '../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { Alert } from '../../../design-system/v2';
import { sendAnalytics } from '../../../initializers/analytics';
import { useGetWorkspaceMembersQuery } from '../../../queries/account/workspace';
import { SharedUsersContainer } from './SharedUsers.container';
import { UserSelect } from './UserSelect';

interface UserSelectContainerProps {
  onShare: (userId: string[]) => Promise<AxiosResponse<unknown>>;
  sharedWithUsers: string[];
  onRemoveUser?: (userId: string) => Promise<AxiosResponse<unknown>>;
  projectId: string;
}

export const UserSelectContainer = ({
  onShare,
  sharedWithUsers,
  onRemoveUser,
  projectId,
}: UserSelectContainerProps) => {
  const { userId, workspaceId } = useAppMetadata();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isSharing, setIsSharing] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { isLoading, isError, data } = useGetWorkspaceMembersQuery();

  const handleShareClick = () => {
    sendAnalytics(
      copyEditEvents.documents.shareButtonClicked({
        workspaceId: workspaceId,
        projectId: projectId,
        userIds: selectedUsers,
      }),
    );
    setIsSharing(true);
    onShare(selectedUsers ?? []).finally(() => {
      setIsSharing(false);
      setSelectedUsers([]);
    });
  };

  const handleUserSelect = (userIds: string[]) => {
    setSelectedUsers(userIds);
  };

  const handleRemoveUser = (userId: string) => {
    if (!onRemoveUser) return;
    sendAnalytics(
      copyEditEvents.documents.removeInviteClicked({
        workspaceId: workspaceId,
        projectId: projectId,
        userId: userId,
      }),
    );
    setIsRemoving(true);
    onRemoveUser(userId).finally(() => {
      setIsRemoving(false);
    });
  };

  if (isLoading) {
    return <Loader variant="dots" m="auto" />;
  }

  if (isError) {
    return <Alert color="red">Error fetching workspace users. </Alert>;
  }

  if (!data?.workspaceMembers) {
    return <Alert>No workspace members found. Please invite members to share snippet</Alert>;
  }

  return (
    <>
      <UserSelect
        users={data?.workspaceMembers}
        currentUserId={userId}
        onShare={handleShareClick}
        selectedUsers={selectedUsers}
        sharedWithUsers={sharedWithUsers}
        onUserSelect={handleUserSelect}
        isLoading={isSharing}
      />
      <SharedUsersContainer
        projectId={projectId}
        users={data?.workspaceMembers}
        sharedWithUsers={sharedWithUsers}
        onRemoveUser={handleRemoveUser}
        isLoading={isRemoving || isSharing}
      />
    </>
  );
};
