import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { DataTable } from '../../../../../design-system';
import { Horizontal, LinkButton, Text, Vertical } from '../../../../../design-system/v2';
import { ExperimentRecording } from '../../../../../generated/api';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { getExperimentsDateColumn } from '../../../../experiments/experiment-table-columns/getExperimentsDateColumn';
import { getExperimentsNameColumn } from '../../../../experiments/experiment-table-columns/getExperimentsNameColumn';
import { getExperimentStatusColumn } from '../../../../experiments/experiment-table-columns/getExperimentsStatusColumn';

interface ExperimentsPreviewProps {
  experiments: ExperimentRecording[];
}

const getTableColumns = () => {
  const NameColumn = getExperimentsNameColumn(150, 'subTitle03');
  const DateColumn = getExperimentsDateColumn(100, 'bodyShort03', 'short');
  const StatusColumn = getExperimentStatusColumn(180);
  return [NameColumn, DateColumn, StatusColumn];
};

export const ExperimentsPreview = ({ experiments }: ExperimentsPreviewProps): JSX.Element => {
  const navigate = useNavigate();
  const { getExperimentDetailsRoute, getExperimentsRoute } = useAbsoluteRoutes();

  const columns = useMemo(() => getTableColumns(), []);

  const handleNavigateExperiment = (projectId: string, recordId: string) => {
    const route = getExperimentDetailsRoute(projectId, recordId);
    navigate(route);
  };

  const getTableRowProps = (row: Row<Record<string, unknown>>) => ({
    isClickable: true,
    onClick: () => {
      handleNavigateExperiment(row.original.projectId as string, row.original.recordId as string);
    },
  });

  return (
    <Vertical h="100%" spacing={0} justify="space-between" p="lg">
      <DataTable
        dataTestId="experiments-table"
        // TODO: fix typing so that mapping isn't needed here
        // (details: https://bobbyhadz.com/blog/typescript-index-signature-for-type-is-missing-in-type)
        data={experiments?.map(item => ({ ...item }))}
        columns={columns}
        getRowProps={getTableRowProps}
        displayConfig={{ hideHeaders: true }}
      />
      <Horizontal position="right" px="sm" pt="lg">
        <LinkButton
          variant="subtle"
          to={getExperimentsRoute()}
          rightIcon={<IconArrowNarrowRight stroke={1.5} />}
          w="100%"
        >
          <Text variant="textLink" color="blue.7">
            View all experiments
          </Text>
        </LinkButton>
      </Horizontal>
    </Vertical>
  );
};
