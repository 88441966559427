import { Box, Text, TextProps } from '../../../design-system/v2';

interface TitleProps {
  title: string;
  subTitle?: string;
  titleProps?: TextProps;
}

export const Title = ({ title, subTitle, titleProps }: TitleProps) => (
  <Box>
    <Text variant="subTitle01" color="dark.7" truncate w="100%" {...titleProps}>
      {title}
    </Text>
    {subTitle && (
      <Text variant="small02" color="dark.4">
        {subTitle}
      </Text>
    )}
  </Box>
);
