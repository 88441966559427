import { dashboardEvents } from '../../../../../analytics';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { Center, LinkButton } from '../../../../../design-system/v2';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useAbsoluteRoutes } from '../../../../../router/hooks';
import { EmptyState } from '../../../../common/EmptyState';

export const EmptyEvaluationPreview = (): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const { getEvaluationsRoute } = useAbsoluteRoutes();

  const handleClickViewSampleEvaluation = () => {
    sendAnalytics(dashboardEvents.evaluationSampleClicked({ workspaceId }));
  };

  return (
    <Center h={360}>
      <EmptyState
        compact
        iconName="evaluation"
        title="No model evaluations yet"
        subtitle="Evaluate your trained model to test deployment readiness"
        button={
          <LinkButton
            variant="outline"
            shape="rounded"
            to={getEvaluationsRoute()}
            onClick={handleClickViewSampleEvaluation}
          >
            View sample evaluations
          </LinkButton>
        }
      />
    </Center>
  );
};
