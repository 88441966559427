import noop from 'lodash/noop';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { TabStates } from '../WorkspaceCard';

interface DashboardSnapshot {
  selectedTab: TabStates;
  handleTabChange: (tabValue: string) => void;
}

const initialContext: DashboardSnapshot = {
  selectedTab: TabStates.ALL,
  handleTabChange: noop,
};

const DashboardSnapshotContext = createContext<DashboardSnapshot>(initialContext);

export const useDashboardSnapshotContext = () => useContext(DashboardSnapshotContext);

export const DashboardSnapshotContextProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabStates>(TabStates.ALL);

  const handleTabChange = (value: string) => {
    setSelectedTab(value as TabStates);
  };

  const value: DashboardSnapshot = {
    selectedTab,
    handleTabChange,
  };

  return (
    <DashboardSnapshotContext.Provider value={value}>{children}</DashboardSnapshotContext.Provider>
  );
};
