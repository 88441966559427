import Mention from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { MentionLabel } from './MentionLabel';

export const CustomMention = Mention.extend({
  addAttributes() {
    const parentAttributes = this.parent?.();

    return {
      ...parentAttributes,

      src: {
        default: null,
        parseHTML: element => element.getAttribute('data-src'),
        renderHTML: attributes => {
          if (!attributes.src) {
            return {};
          }

          return {
            'data-src': attributes.src,
          };
        },
      },

      mentionId: {
        default: null,
        parseHTML: element => element.getAttribute('data-mention-id'),
        renderHTML: attributes => {
          if (!attributes.mentionId) {
            return {};
          }

          return {
            'data-mention-id': attributes.mentionId,
          };
        },
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionLabel);
  },
});
