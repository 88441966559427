import { Center } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { Box, Card, Text } from '../../../design-system/v2';

export interface IntegrationsProps {
  name: string;
  icon: JSX.Element;
  description: string;
}

export const IntegrationsCard = ({
  name,
  icon,
  description,
  children,
}: PropsWithChildren<IntegrationsProps>) => (
  <Card shadow="sm" w="214px" mt="lg" radius="xs" withBorder color="gray.2">
    <Card.Section bg="gray.0">
      <Center p="lg">
        <Box p="sm" bg="white.0">
          {icon}
        </Box>
      </Center>
    </Card.Section>

    <Text py="md" variant="subTitle03" color="gray.9">
      {name}
    </Text>

    <Text variant="small02" size="sm" color="gray.6">
      {description}
    </Text>
    {children}
  </Card>
);
