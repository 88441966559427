import { IconSearch } from '@tabler/icons-react';
import { CloseButton, TextInput, useInputState, useMarkovTheme } from '../../../design-system/v2';

interface SearchboxProps {
  initialQuery?: string;
  onSearch: (query: string) => void;
  padding?: string | number;
  margin?: string | number;
  width?: string | number;
  textColor?: string;
}

export const Searchbox = ({
  initialQuery = '',
  onSearch,
  padding,
  margin = 'sm',
  width,
  textColor = 'black',
}: SearchboxProps) => {
  const theme = useMarkovTheme();
  const [searchQuery, setSearchQuery] = useInputState(initialQuery);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(e);
    onSearch(newQuery);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      onSearch(searchQuery);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    onSearch('');
  };

  return (
    <TextInput
      ariaLabel="Search operators"
      icon={<IconSearch size={16} />}
      value={searchQuery}
      onChange={handleSearch}
      onKeyUp={handleKeyUp}
      rightSection={<CloseButton onClick={handleClearSearch} />}
      placeholder="Type here to search"
      p={padding}
      w={width}
      m={margin}
      styles={{
        input: {
          color: textColor,
        },
      }}
    />
  );
};
