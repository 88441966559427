import React, { forwardRef } from 'react';
import { Box } from '../../../../../misc/box';
import { Text } from '../../../../../typography';

export interface SelectItemProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'value'> {
  allSelected?: boolean;
  onClickSelectAll: () => void;
}

export const SelectAllItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ allSelected, onClickSelectAll, ...restProps }: SelectItemProps, ref) => (
    <Box
      ref={ref}
      onClick={onClickSelectAll}
      sx={theme => ({ cursor: 'pointer', color: theme.fn.primaryColor() })}
      px="lg"
      py="sm"
      {...restProps}
    >
      <span>
        <Text py="sm" variant="subTitle04" color="blue.8">
          {allSelected ? 'Clear all' : 'Select all'}
        </Text>
      </span>
    </Box>
  ),
);

SelectAllItem.displayName = 'MKVSelectAllItem';
