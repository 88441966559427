import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Badge,
  Card,
  Center,
  Horizontal,
  Loader,
  SimpleGrid,
  Text,
  Vertical,
} from '../../../../design-system/v2';
import { ArtifactStateType, Operator } from '../../../../generated/api';
import { formatDateLong } from '../../../../lib/ui';
import { useGetWorkflowsQuery } from '../../../../queries/workflows/list/list';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { UserInfoContainer } from '../../../model-apps/UserInfo.container';

export const RecentDraftWorkflowsContainer = () => {
  const navigate = useNavigate();
  const { getWorkflowDetailsRoute } = useAbsoluteRoutes();

  const { data, isError, isLoading } = useGetWorkflowsQuery({
    filters: [
      {
        field: 'artifactState',
        operator: Operator.In,
        value: ArtifactStateType.Draft,
      },
    ],
    limit: 10,
  });

  const handleCardClick = (workflowId: string) => {
    navigate(getWorkflowDetailsRoute(workflowId));
  };

  if (isLoading) {
    return (
      <Center h={200}>
        <Loader color="yellow.6" text="Loading recent draft workflows..." />
      </Center>
    );
  }

  if (isError || !data) {
    <Alert color="red">Error fetching draft workflows. Please try again</Alert>;
  }

  return (
    <SimpleGrid
      cols={4}
      w="100%"
      py="24px"
      spacing={32}
      breakpoints={[
        { maxWidth: 'lg', cols: 3 },
        { maxWidth: 'md', cols: 2 },
        { maxWidth: 'xs', cols: 1 },
      ]}
    >
      {data?.response.map(datum => (
        <Card
          h={160}
          w="100%"
          key={datum.workflowId}
          onClick={() => handleCardClick(datum.workflowId ?? '')}
          sx={{ cursor: 'pointer' }}
          withBorder
        >
          <Vertical h="100%" justify="space-between">
            <Vertical spacing="xs">
              <Text
                variant="subTitle03"
                color="gray.8"
                sx={{
                  whitespace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                lineClamp={1}
              >
                {datum.name}
              </Text>
              <Text variant="small03" color="gray.6">
                Last edited on {formatDateLong(datum.updateDate)}
              </Text>
              <Text variant="small02" color="gray.7" lineClamp={2}>
                {datum.description}
              </Text>
            </Vertical>
            <Horizontal position="apart">
              <UserInfoContainer userId={datum.createdBy ?? ''} />
              {datum.artifactState.state && (
                <Badge color="blue.1" radius="sm">
                  <Text variant="small01" color="blue.9">
                    {datum.artifactState.state}
                  </Text>
                </Badge>
              )}
            </Horizontal>
          </Vertical>
        </Card>
      ))}
    </SimpleGrid>
  );
};
