import { closeAllModals, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Horizontal,
  Text,
  TextInput,
  Vertical,
  notifications,
  useInputState,
} from '../../../../design-system/v2';
import { logger } from '../../../../initializers/logging';
import { useWorkflowCloneMutation } from '../../../../queries/workflows/list/list';
import { useAbsoluteRoutes } from '../../../../router/hooks';

export interface CloneWorkflowInputModalProps {
  currentWorkflowName: string;
  workflowId: string;
}
const HEADING = 'Cloning the workflow';
const SUBHEADING = `You can clone the workflow to create an exact replica, including the source file, operator configurations, and sink destination.`;

const CloneWorkflowInputModal = ({
  currentWorkflowName,
  workflowId,
}: CloneWorkflowInputModalProps): JSX.Element => {
  const navigate = useNavigate();
  const { getWorkflowAddRoute } = useAbsoluteRoutes();
  const { mutateAsync: cloneWorkflow, isLoading: isCloning } = useWorkflowCloneMutation();
  const displayState = 'Clone';
  const displayName = 'workflow';
  const [workflowName, setWorkflowName] = useInputState(`${currentWorkflowName}` + ' (Copy)');

  const onConfirmCloning = async () => {
    try {
      const data = await cloneWorkflow({ workflowId, workflowName });
      navigate(getWorkflowAddRoute(data.data.workflowId));
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };
  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>
      <TextInput
        autoFocus
        ariaLabel="Name the new workflow"
        label="Name the new workflow"
        placeholder="New workflow name"
        value={workflowName}
        onChange={setWorkflowName}
      />
      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="light" color="gray.7" bg="gray.1">
          Cancel
        </Button>
        <Button onClick={onConfirmCloning} loading={isCloning} variant="primary" color="blue">
          Clone
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useWorkflowClone = (workflowId: string, currentWorkflowName: string) => {
  const openCloneModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      children: (
        <CloneWorkflowInputModal
          currentWorkflowName={currentWorkflowName}
          workflowId={workflowId}
        />
      ),
    });

  return { openCloneModal };
};
