import { useDatasetsSelection } from '../../contexts/datasets/Datasets';
import { ArtifactFilterState, ArtifactStateType, ResourceType } from '../../generated/api';
import { useDatasetStateMutation } from '../../queries/datasets/list';
import { useArchiveUnarchive } from '../archive/use-archive-unarchive';

export const useDatasetArchiveUnarchive = (
  datasetIds: string[],
  artifactState: ArtifactFilterState,
) => {
  const count = datasetIds.length;
  const { resetSelection } = useDatasetsSelection();

  const {
    mutateAsync: archiveUnarchiveDataset,
    isError,
    isLoading,
  } = useDatasetStateMutation(
    {
      datasetIds,
      artifactState:
        artifactState === ArtifactFilterState.Active
          ? ArtifactStateType.Archived
          : ArtifactStateType.Active,
    },
    resetSelection,
  );

  return useArchiveUnarchive(
    count,
    ResourceType.Dataset,
    artifactState,
    archiveUnarchiveDataset,
    isError,
    isLoading,
  );
};
