import {
  DatasetRegisterCancel,
  DatasetRegisterCancelProperties,
  DatasetRegisterStepConfirmColumnsBack,
  DatasetRegisterStepConfirmColumnsBackProperties,
  DatasetRegisterStepConfirmColumnsNext,
  DatasetRegisterStepConfirmColumnsNextProperties,
  DatasetRegisterStepDetailsFormBack,
  DatasetRegisterStepDetailsFormBackProperties,
  DatasetRegisterStepDetailsFormNext,
  DatasetRegisterStepDetailsFormNextProperties,
  DatasetRegisterStepImportDataset,
  DatasetRegisterStepImportDatasetProperties,
  DatasetRegisterStepSelectAnalyzersBack,
  DatasetRegisterStepSelectAnalyzersBackProperties,
  DatasetRegisterStepSelectAnalyzersNext,
  DatasetRegisterStepSelectAnalyzersNextProperties,
  DatasetRegisterStepSimpleUpload,
  DatasetRegisterStepSimpleUploadProperties,
  DatasetsAddClicked,
  DatasetsAddClickedProperties,
  DatasetsDetailsAppsTabClicked,
  DatasetsDetailsAppsTabClickedProperties,
  DatasetsListItemClicked,
  DatasetsListItemClickedProperties,
  DatasetsListViewed,
  DatasetsListViewedProperties,
  DatasetsRelabelingGenerateNewClicked,
  DatasetsRelabelingGenerateNewClickedProperties,
  DatasetsRelabelingGenerateNewClosed,
  DatasetsRelabelingGenerateNewClosedProperties,
  DatasetsRelabelingPrepareClicked,
  DatasetsRelabelingPrepareClickedProperties,
} from '../ampli';

export const datasetEvents = {
  register: {
    addClicked: (data: DatasetsAddClickedProperties) => new DatasetsAddClicked(data),
    cancelRegistration: (data: DatasetRegisterCancelProperties) => new DatasetRegisterCancel(data),
    simpleUpload: (data: DatasetRegisterStepSimpleUploadProperties) =>
      new DatasetRegisterStepSimpleUpload(data),
    importDataset: (data: DatasetRegisterStepImportDatasetProperties) =>
      new DatasetRegisterStepImportDataset(data),
    confirmColumnsNext: (data: DatasetRegisterStepConfirmColumnsNextProperties) =>
      new DatasetRegisterStepConfirmColumnsNext(data),
    confirmColumnsBack: (data: DatasetRegisterStepConfirmColumnsBackProperties) =>
      new DatasetRegisterStepConfirmColumnsBack(data),
    selectAnalyzersNext: (data: DatasetRegisterStepSelectAnalyzersNextProperties) =>
      new DatasetRegisterStepSelectAnalyzersNext(data),
    selectAnalyzersBack: (data: DatasetRegisterStepSelectAnalyzersBackProperties) =>
      new DatasetRegisterStepSelectAnalyzersBack(data),
    fillDatasetDetailsFormNext: (data: DatasetRegisterStepDetailsFormNextProperties) =>
      new DatasetRegisterStepDetailsFormNext(data),
    fillDatasetDetailsFormBack: (data: DatasetRegisterStepDetailsFormBackProperties) =>
      new DatasetRegisterStepDetailsFormBack(data),
  },
  list: {
    listViewed: (data: DatasetsListViewedProperties) => new DatasetsListViewed(data),
    listItemClicked: (data: DatasetsListItemClickedProperties) => new DatasetsListItemClicked(data),
  },
  details: {
    openAppsTab: (data: DatasetsDetailsAppsTabClickedProperties) =>
      new DatasetsDetailsAppsTabClicked(data),
  },
  relabeling: {
    prepareClicked: (data: DatasetsRelabelingPrepareClickedProperties) =>
      new DatasetsRelabelingPrepareClicked(data),
    generateNewClicked: (data: DatasetsRelabelingGenerateNewClickedProperties) =>
      new DatasetsRelabelingGenerateNewClicked(data),
    generateNewClosed: (data: DatasetsRelabelingGenerateNewClosedProperties) =>
      new DatasetsRelabelingGenerateNewClosed(data),
  },
};
