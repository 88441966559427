import { ReactNode } from 'react';
import { Box, Center, Image, MarkovSize, Text, Vertical } from '../../design-system/v2';
import { S3_STORAGE_BASE } from '../../lib/env.util';
import { S3_ILLUSTRATIONS_MAP } from '../../lib/resource-map';

type IconSize = Omit<MarkovSize, 'number'>;

const getIconSize = (iconSize: IconSize) => {
  if (iconSize === 'lg') {
    return { width: 184, height: 150 };
  }
  /**
   * Add other size variations if needed
   */
  return { width: 120, height: 80 };
};

export interface EmptyStateProps {
  title: string;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  iconName?: string;
  iconSize?: IconSize;
  iconWrapperSize?: number;
  button?: ReactNode;
  compact?: boolean;
  type?: 'error' | 'empty' | 'notSupported';
}

export const EmptyState = ({
  /**
   * Use icon only in case we want to provide react node as Icons
   * that we do not want to use from Amazon S3
   */
  icon,
  /**
   * iconName is name of the file in Illustration bucket of S3
   * Filename in S3 would follow the format `empty-runs`
   * In this case, iconName='runs'
   */
  iconName = '',
  iconSize = 'md',
  title,
  subtitle = null,
  button,
  compact = false,
  iconWrapperSize = compact ? 32 : 64,

  type = 'empty',
}: EmptyStateProps): JSX.Element => {
  if (icon && iconName) {
    throw new Error(`Either send icon or iconName only for ${iconName}`);
  }

  if (typeof icon === 'string') {
    throw new Error(`Icon ${icon} cannot be a string. It should be a ReactNode`);
  }

  const ILLUSTRATION_URL = `${S3_STORAGE_BASE}/${S3_ILLUSTRATIONS_MAP.EMPTY_ERROR_STATES}/${
    type === 'empty' ? 'empty' : 'error'
  }-${iconName}.svg`;

  return (
    <Center h="100%" p="xl">
      <Vertical align="center" spacing={compact ? 12 : 24} ta="center">
        {icon && (
          <Center h={iconWrapperSize} w={iconWrapperSize}>
            {icon}
          </Center>
        )}
        {iconName && (
          <Image
            src={ILLUSTRATION_URL}
            alt={iconName || 'Illustration'}
            withPlaceholder
            placeholder={<></>}
            {...getIconSize(iconSize)}
          />
        )}
        <Vertical align="center" spacing={compact ? 4 : 12}>
          <Text variant={compact ? 'subTitle03' : 'subTitle01'} color="gray.7">
            {title}
          </Text>
          {typeof subtitle === 'string' ? (
            <Box maw="400px">
              <Text
                variant={compact ? 'bodyShort03' : 'bodyLong01'}
                color={type === 'notSupported' ? 'gray.7' : 'gray.6'}
              >
                {subtitle}
              </Text>
            </Box>
          ) : (
            subtitle
          )}
        </Vertical>
        {button ? button : null}
      </Vertical>
    </Center>
  );
};

export const PageEmptyState = (props: EmptyStateProps) => (
  <Center bg="white.0" h={520} role="alert">
    <EmptyState {...props} />
  </Center>
);
