import { colors } from '../../../charts/config/colors';
import { Box, Horizontal } from '../../../design-system/v2';
import { DataInfoBox } from '../../common/DataInfoBox';
import { ClassDistributionContainer } from '../../evaluation-comparison/multimodel/class-distribution/ClassDistribution.container';

const CLASS_DIST_TITLE = 'Ground truth vs. model inference';
const CLASS_DIST_DESC =
  'Compare the frequency of models predictions against frequency of the actual labels';
const CHART_HEIGHT = 396;
export const CHART_BAR_COLOR = colors[0];

interface DistributionsSectionProps {
  evaluationId: string;
}

export const DistributionsSection = ({ evaluationId }: DistributionsSectionProps) => (
  <Horizontal grow>
    <DataInfoBox title={CLASS_DIST_TITLE} description={CLASS_DIST_DESC}>
      <Box h={CHART_HEIGHT}>
        <ClassDistributionContainer
          evaluationIds={[evaluationId]}
          chartColors={[CHART_BAR_COLOR]}
        />
      </Box>
    </DataInfoBox>
  </Horizontal>
);
