import startCase from 'lodash/startCase';
import { ChartData } from '../../../charts/config/build';
import { Card, Center, Loader } from '../../../design-system/v2';
import { useExperimentsMetricData } from '../../../queries/experiments/experimentComparison';
import { ErrorState } from '../../common/ErrorState';
import { SplineChartVisualization } from './SplineChartVisualization';

interface SplineChartVisualizationContainerProps {
  experimentIds: string[];
  metricName: string;
  fullHeight?: boolean;
  enableLegends?: boolean;
  colors?: string[];
}

export const SplineChartVisualizationContainer = ({
  experimentIds,
  metricName,
  fullHeight = false,
  enableLegends = false,
  colors,
}: SplineChartVisualizationContainerProps) => {
  const {
    isLoading,
    isError,
    data: metricData,
  } = useExperimentsMetricData(metricName, experimentIds);

  let content = null;

  if (isLoading) {
    content = (
      <Center h="100%">
        <Loader />
      </Center>
    );
  } else if (isError) {
    content = <ErrorState title="" subtitle="Error retrieving metric data" compact />;
  } else if (!metricData?.data) {
    content = <ErrorState title="" subtitle="Metric data not found" compact />;
  } else {
    const { data: chartData, metadata } = metricData;
    const yLabel = metadata.yLabel || metricName;
    const xLabel = metadata.xLabel || 'Epoch';

    content = (
      <SplineChartVisualization
        data={chartData as ChartData}
        xLabel={xLabel}
        yLabel={yLabel}
        colors={colors}
        enableLegends={enableLegends}
        title={startCase(metricName)}
      />
    );
  }

  return (
    <Card h={fullHeight ? '100%' : '350px'} p={0} withBorder>
      {content}
    </Card>
  );
};
