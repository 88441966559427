import { useState } from 'react';
import { Box, Popover } from '../../../design-system/v2';
import { NotificationBell } from './NotificationBell';
import { NotificationDrawerWrapper } from './NotificationsDrawer.wrapper';

export const Notifications = () => {
  const [opened, setOpened] = useState(false);

  const toggleBellClick = () => {
    setOpened(prev => !prev);
  };

  return (
    <Popover
      width={400}
      trapFocus
      withinPortal
      position="bottom-end"
      shadow="xl"
      offset={12}
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Box onClick={toggleBellClick}>
          <NotificationBell />
        </Box>
      </Popover.Target>
      <Popover.Dropdown
        pb={16}
        sx={theme => ({
          borderColor: theme.colors.gray[3],
        })}
      >
        <NotificationDrawerWrapper onClosePopover={setOpened} />
      </Popover.Dropdown>
    </Popover>
  );
};
